import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from 'reducers';

const traceMiddleware = store => next => action => {
  if (typeof action === 'function') {
    return next(action);
  }
  const error = new Error();
  const stack = error.stack.split('\n').slice(2).join('\n');
  console.groupCollapsed(action.type);
  console.log('Action:', action);
  console.log('Stack trace:', stack);
  console.groupEnd();
  return next(action);
};
const middlewares = [
  // traceMiddleware,
  thunk,
];

// if (process.env.NODE_ENV === "development") {
//   middlewares.push(logger);
// }

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['account', 'page'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store;
let persistor;

const configureStore = () => {
  if (!store || !persistor) {
    const middlewaresStoreEnhancer = applyMiddleware(...middlewares);
    const storeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line
      ? compose(middlewaresStoreEnhancer, window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })) // eslint-disable-line
      : middlewaresStoreEnhancer;
    store = createStore(persistedReducer, storeEnhancer);
    persistor = persistStore(store);
  }

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return { store, persistor };
};

export { configureStore };
