import React, { useCallback, useEffect, useState } from 'react';
import * as leadMagnetActions from 'actions/leadMagnets';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import isEmpty from 'lodash/isEmpty';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import { mdiHelpCircle } from '@mdi/js';
import * as R from 'ramda';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';

import {
  StyledHeaderContainer,
  HeaderIcon,
  StyledUserSectionContainer,
  AvatarComponent,
  StyledIcon,
  StyledHeaderMainContainer,
  StyledHeaderText,
  StyledShareIcon,
  StyledEditIcon,
  StyledSecondHeaderContainer,
  StyledSecondHeaderMainContainer,
} from './StyledComponent';
import useScreenView from 'hooks/useScreenView';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import { useRouter } from 'hooks';
import useAccount from 'hooks/useAccount';
import { UserRoles, SIGNUP_TYPES, LeadMagnetStatus } from 'helpers/constants';
import CohereIcon from 'assets/icon.png';
import ClickDropDown, { StyledDropDownItem } from '../ClickDropDown';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { ROUTES, TOOLTIP } from '../../../constants';
import Button from 'components/FormUI/Button';
import { lightOrDark } from 'utils/utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Modal from 'components/UI/Modal';
import { TextField } from '@material-ui/core';
import copyToClipboard from 'utils/copyToClipboard';
import styled, { css } from 'styled-components';
import { addhttp } from 'utils/utils';
import { smoothScrollingToElement } from 'utils/dom';
import { getProfileLinkNameByLeadMagnetId } from 'services/leadMagnets.service';
import { getCustomTemplateByLeadMagnetId } from 'services/leadMagnets.service';
import {
  DarkEnabledPrimaryFilledButton,
  DarkEnabledPrimaryOutlineButton,
} from 'pages/ContributionView/ContributionSessions/component/styled';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';
import { ConversionMetricsModal } from 'components/Modals/ConversionMetricsModal';

export const getInitialSymbol = R.compose(R.toUpper, R.head);
const isPathForContribution = path => path === '/contribution-view/:id' || path === '/apply-contribution-view/:id';

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    padding: 11.5px 14px;
  }
  .MuiOutlinedInput-root {
    margin-top: 15px;
    ${({ darkMode }) =>
      darkMode &&
      css`
        color: white;
        .MuiOutlinedInput-notchedOutline {
          /* border-color: white; */
          border: 1px solid #4a4a4a;
        }
      `}
  }
`;

const StyledButton = styled(Button)`
  /* color: var(--Cohere-Primary-Blue, #215C73); */
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 137.5% */
`;

const LeadMagnetHeader = () => {
  const [run, setRun] = useState(false);
  const [supportPopup, setSupportPopup] = useState(false);
  const { mobileView } = useScreenView();
  const { path } = useRouteMatch();
  const { user } = useSelector(state => state.account);
  const leadMagnet = useLeadMagnet();
  const { currentRole, user: signupType, loggedInUser } = useAccount();
  const { history } = useHistory();
  const [leadMagnetShareableLink, setLeadMagnetShareableLink] = useState('');
  const [generatingShareableLink, setGeneratingShareableLink] = useState(false);
  const isEmailEditable = useSelector(state => state.leadMagnets.welcomeEmailEnabled);
  const isCoach = currentRole === UserRoles.cohealer;
  const [leadMagnetShareableThankYouLink, setLeadMagnetShareableThankYouLink] = useState('');
  const { themedColors, colorToUse, textColor } = useLeadMagnetTheme();
  let { verifiedSendingAddresses } = useEmailMarketing();
  const dispatch = useDispatch();
  const [analyticsModalOpened, setAnalyticsModalOpened] = useState(false);
  // const textColor =
  //   colorToUse?.TextColorCode === 'Auto'
  //     ? lightOrDark(colorToUse?.PrimaryColorCode)
  //     : colorToUse?.TextColorCode === '#000000'
  //     ? '#000000'
  //     : '#FFFFFF';
  const { pathname, domain, parentDomain } = useRouter();
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const { origin } = window.location;
  const clientPreviewMode = pathname?.includes('clientpreview');
  const islandingPage = pathname?.includes('landingpage');
  const {
    themedCardBackgroundColor,
    themedColor,
    newThemedTextColor,
    newThemedBackgroundColor,
    themedBackgroundColor,
  } = themedColors;

  const shouldBeDisabled =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_A;
  const { isDarkModeEnabled, userId } = leadMagnet;
  const isLMOwner = userId === user?.id;
  const isArchived = leadMagnet?.status === LeadMagnetStatus.completed;
  const btnBackgroundColor = leadMagnet?.isDarkModeEnabled ? 'transparent' : null;
  const handleAnalyticsModalClose = () => {
    setAnalyticsModalOpened(false);
  };
  const generateShareableLink = useCallback(() => {
    if (leadMagnet?.id) {
      setGeneratingShareableLink(true);
      // return `${origin}/lead-magnet-view/${leadMagnet.id}/landingpage`;
      const urlPath = `/lead-magnet-view/${leadMagnet?.id}/landingpage`;
      const urlPathThankYouPage = `/lead-magnet-view/${leadMagnet?.id}/thankyoupage`;
      getProfileLinkNameByLeadMagnetId(leadMagnet?.id)
        .then(res => {
          if (res) {
            setLeadMagnetShareableLink(addhttp(`${res}.${parentDomain}${urlPath}`));
            setLeadMagnetShareableThankYouLink(addhttp(`${res}.${parentDomain}${urlPathThankYouPage}`));
          } else {
            setLeadMagnetShareableLink(`${origin}${urlPath}`);
            setLeadMagnetShareableThankYouLink(`${origin}${urlPathThankYouPage}`);
          }
        })
        .catch(() => {})
        .finally(() => {
          setGeneratingShareableLink(false);
        });
    }
  }, [leadMagnet?.id]);
  useEffect(() => {
    if (shareModalVisible) {
      generateShareableLink();
    }
  }, [shareModalVisible]);

  useEffect(() => {
    if (leadMagnet?.id && verifiedSendingAddresses.length > 0) {
    getCustomTemplateByLeadMagnetId(leadMagnet.id)
    .then(res => {
      dispatch(leadMagnetActions.setWelcomeEmailEnabled(res?.customTemplates[0]?.sendEmailAllowed === 'true'));
    })
    .catch(console.dir);
    }
  }, [leadMagnet, verifiedSendingAddresses]);

  const onBackClick = () => {
    if (window.location.href.includes('lead-magnet-view')) {
      redirectToAppIfIsCoachDomain(domain, '/dashboard');
    } else {
      history.goBack();
    }
  };
  const clientPreviewBack = () => {
    if (window.location.href.includes('clientpreview/landingpage')) {
      redirectToAppIfIsCoachDomain(domain, `/edit-lead-magnet/${leadMagnet.id}/review`);
    } else {
      redirectToAppIfIsCoachDomain(domain, `/edit-lead-magnet/${leadMagnet.id}/content`);
    }
  };
  const handleCloseModal = () => {
    setShareModalVisible(false);
  };
  const handleCopyClick = useCallback(() => copyToClipboard(leadMagnetShareableLink), [leadMagnetShareableLink]);
  const handleCopyThankYouClick = useCallback(() => copyToClipboard(leadMagnetShareableThankYouLink), [
    leadMagnetShareableThankYouLink,
  ]);
  //const handleCopyClick = () => copyToClipboard(user.profileLinkName ? addhttp(user.profileLinkName.concat('.', `${parentDomain}/lead-magnet-view/${leadMagnet.id}/landingpage`)): `${origin}/lead-magnet-view/${leadMagnet.id}/landingpage`);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [thankYouTooltipOpen, setThankYouTooltipOpen] = useState(false);

  const setScrollingElementId = useCallback(
    () => smoothScrollingToElement(islandingPage ? 'get-free-access-form' : 'lead-magnet-content-container'),
    [islandingPage],
  );
  return (
    <>
      {
        !mobileView ? (
          isLMOwner && clientPreviewMode ? (
            <StyledHeaderMainContainer
              themedCardBackgroundColor={mobileView ? themedCardBackgroundColor : themedCardBackgroundColor}
            >
              <StyledHeaderContainer>
                <div className="d-flex align-items-center">
                  {!mobileView && !isEmpty(user) && (
                    <>
                      <ArrowBackIosIcon
                        fontSize="small"
                        style={{ color: isCoach ? themedColor : newThemedTextColor }}
                        onClick={clientPreviewBack}
                      />
                      <HeaderIcon
                        mobileView={mobileView}
                        src={leadMagnet?.customLogo === null ? CohereIcon : leadMagnet?.customLogo}
                      />
                    </>
                  )}
                </div>
                <StyledUserSectionContainer>
                  {islandingPage ? (
                    <Link
                      // onClick={() => {
                      //   redirectToAppIfIsCoachDomain(
                      //     domain,
                      //     `/lead-magnet-view/${leadMagnet.id}/clientpreview/thankyoupage`,
                      //   );
                      // }}
                      to={`/lead-magnet-view/${leadMagnet.id}/clientpreview/thankyoupage`}
                    >
                      <DarkEnabledPrimaryOutlineButton
                        textColor={colorToUse.AccentColorCode}
                        borderColor={colorToUse.AccentColorCode}
                        variant="outline"
                        autoWidth
                        {...{ isDarkModeEnabled, backgroundColor: btnBackgroundColor }}
                      >
                        Preview Thank You Page
                      </DarkEnabledPrimaryOutlineButton>
                    </Link>
                  ) : (
                    <Link
                      // onClick={() => {
                      //   redirectToAppIfIsCoachDomain(
                      //     domain,
                      //     `/lead-magnet-view/${leadMagnet.id}/clientpreview/landingpage`,
                      //   );
                      // }}
                      to={`/lead-magnet-view/${leadMagnet.id}/clientpreview/landingpage`}
                    >
                      <DarkEnabledPrimaryOutlineButton
                        textColor={colorToUse.AccentColorCode}
                        borderColor={colorToUse.AccentColorCode}
                        variant="outline"
                        autoWidth
                        {...{ isDarkModeEnabled, backgroundColor: btnBackgroundColor }}
                      >
                        Preview Landing Page
                      </DarkEnabledPrimaryOutlineButton>
                    </Link>
                  )}
                  <Link onClick={setScrollingElementId}>
                    <DarkEnabledPrimaryFilledButton
                      backgroundColor={colorToUse?.PrimaryColorCode}
                      textColor={textColor}
                      autoWidth
                      {...{ isDarkModeEnabled }}
                    >
                      Download Now
                    </DarkEnabledPrimaryFilledButton>
                  </Link>
                </StyledUserSectionContainer>
              </StyledHeaderContainer>
            </StyledHeaderMainContainer>
          ) : isCoach && isLMOwner ? (
            <StyledHeaderMainContainer
              themedCardBackgroundColor={mobileView ? themedCardBackgroundColor : themedCardBackgroundColor}
            >
              <StyledHeaderContainer>
                <div className="d-flex align-items-center">
                  {!mobileView && !isEmpty(user) && (
                    <>
                      <div
                        style={{ display: 'flex', flexDirection: 'row' }}
                        onClick={onBackClick}
                        onKeyPress={null}
                        role="button"
                        tabIndex={0}
                      >
                        <ArrowBackIosIcon
                          fontSize="small"
                          style={{ color: isCoach ? themedColor : newThemedTextColor }}
                        />
                        <div
                          className="h6 mr-3 cursor-pointer"
                          style={{ color: isCoach ? themedColor : newThemedTextColor }}
                        >
                          Back
                        </div>
                      </div>
                      <HeaderIcon
                        mobileView={mobileView}
                        src={leadMagnet?.customLogo === null ? CohereIcon : leadMagnet?.customLogo}
                      />
                    </>
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.31rem' }}>
                  <StyledHeaderText>Only you can see these buttons</StyledHeaderText>
                  <StyledUserSectionContainer>
                  <DarkEnabledPrimaryOutlineButton
                      onClick={() => {
                        setAnalyticsModalOpened(true);
                      }}
                      textColor={colorToUse.AccentColorCode}
                      borderColor={colorToUse.AccentColorCode}
                      variant="outline"
                      autoWidth
                      backgroundColor={btnBackgroundColor}
                      {...{ isDarkModeEnabled, disabled: leadMagnet?.status !== LeadMagnetStatus.approved }}
                    >
                      View Analytics
                    </DarkEnabledPrimaryOutlineButton>
                    <Tooltip
                      className="cursor-pointer text-grey"
                      disableHoverListener={shouldBeDisabled}
                      disableClickListener={shouldBeDisabled}
                      title={verifiedSendingAddresses.length === 0 ? "Connect your domain and sending address to enable it." : ""}
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}>
                      <Link to={`/lead-magnet-view/${leadMagnet?.id}/thankyouemail`}>
                        <DarkEnabledPrimaryOutlineButton
                          textColor={colorToUse.AccentColorCode}
                          borderColor={colorToUse.AccentColorCode}
                          variant="outline"
                          autoWidth
                          {...{
                            isDarkModeEnabled,
                            backgroundColor: btnBackgroundColor,
                            disabled: verifiedSendingAddresses.length === 0,
                          }}
                        >
                          {isEmailEditable ? 'Edit Welcome Email' : 'Enable Welcome Email'}
                        </DarkEnabledPrimaryOutlineButton>
                      </Link>
                    </Tooltip>
                    {islandingPage ? (
                      <Link
                        // onClick={() => {
                        //   redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet.id}/thankyoupage`);
                        // }}
                        to={`/lead-magnet-view/${leadMagnet.id}/thankyoupage`}
                      >
                        <DarkEnabledPrimaryOutlineButton
                          textColor={colorToUse.AccentColorCode}
                          borderColor={colorToUse.AccentColorCode}
                          variant="outline"
                          autoWidth
                          backgroundColor={btnBackgroundColor}
                          {...{ isDarkModeEnabled }}
                        >
                          View Thank You Page
                        </DarkEnabledPrimaryOutlineButton>
                      </Link>
                    ) : (
                      <Link
                        // onClick={() => {
                        //   redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet.id}/landingpage`);
                        // }}
                        to={`/lead-magnet-view/${leadMagnet.id}/landingpage`}
                      >
                        <DarkEnabledPrimaryOutlineButton
                          textColor={colorToUse.AccentColorCode}
                          borderColor={colorToUse.AccentColorCode}
                          variant="outline"
                          autoWidth
                          backgroundColor={btnBackgroundColor}
                          {...{ isDarkModeEnabled }}
                        >
                          View Landing Page
                        </DarkEnabledPrimaryOutlineButton>
                      </Link>
                    )}
                    {colorToUse?.AccentColorCode && textColor && (
                      <Link
                        onClick={() => {
                          redirectToAppIfIsCoachDomain(domain, `/edit-lead-magnet/${leadMagnet.id}/basic`);
                        }}
                      >
                        <DarkEnabledPrimaryFilledButton
                          backgroundColor={colorToUse?.PrimaryColorCode}
                          textColor={textColor}
                          autoWidth
                          style={{ padding: '.75rem 1.25rem' }}
                          {...{ isDarkModeEnabled }}
                        >
                          <StyledEditIcon style={{ marginTop: '-2px' }} />
                          Edit
                        </DarkEnabledPrimaryFilledButton>
                      </Link>
                    )}
                    <DarkEnabledPrimaryFilledButton
                      onClick={() => {
                        setShareModalVisible(true);
                      }}
                      backgroundColor={colorToUse?.PrimaryColorCode}
                      textColor={textColor}
                      autoWidth
                      style={{
                        padding: '.75rem 1.25rem',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '0.625rem',
                      }}
                      {...{ isDarkModeEnabled, disabled: isArchived }}
                    >
                      <StyledShareIcon style={{ margin: 0 }} />
                      Share
                    </DarkEnabledPrimaryFilledButton>
                    <Tooltip
                      className="cursor-pointer text-grey"
                      disableHoverListener={shouldBeDisabled}
                      disableClickListener={shouldBeDisabled}
                      title="Help"
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <StyledIcon
                        path={mdiHelpCircle}
                        // size={mobileView ? 0.7 : 1.25}
                        color="#215C73"
                        onClick={() => {
                          if (!shouldBeDisabled) {
                            setSupportPopup(true);
                          }
                        }}
                      />
                    </Tooltip>
                    <StylesProvider injectFirst>
                      {!clientPreviewMode ? (
                        <AvatarComponent
                          // className="mx-2"
                          alt={`${user?.firstName} ${user?.lastName}`}
                          src={user?.avatarUrl}
                          mobileView={mobileView}
                        >
                          {`${user.firstName && getInitialSymbol(user.firstName)}${
                            user.lastName && getInitialSymbol(user.lastName)
                          }`}
                        </AvatarComponent>
                      ) : (
                        <AccountCircleIcon style={{ color: '#C7C7C7', fontSize: '50px' }} />
                      )}
                    </StylesProvider>

                    <ClickDropDown
                      id="header-user-dropdown"
                      title={''}
                      mobileView={mobileView}
                      layover={run}
                      style={{ color: isCoach ? themedColor : newThemedTextColor }}
                      isCoach={isCoach}
                      // isDisable={isLaunchPlan === false}
                      clientPreviewMode={clientPreviewMode}
                      isPathForContribution={isPathForContribution(path)}
                    >
                      {shouldBeDisabled && currentRole === UserRoles.cohealer ? (
                        <StyledDropDownItem
                          style={{
                            backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                            color: isCoach ? themedColor : newThemedTextColor,
                          }}
                          className={'disableMe'}
                          to={'#'}
                        >
                          Account
                        </StyledDropDownItem>
                      ) : null}
                      {!shouldBeDisabled || (shouldBeDisabled && currentRole != UserRoles.cohealer) ? (
                        <StyledDropDownItem
                          style={{
                            backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                            color: isCoach ? themedColor : newThemedTextColor,
                          }}
                          className={
                            ((shouldBeDisabled && currentRole === UserRoles.cohealer) ||
                              (currentRole === UserRoles.cohealer && user.isPartnerCoach === false)) &&
                            'disableMe'
                          }
                          onClick={() => {
                            redirectToAppIfIsCoachDomain(domain, '/account/profile');
                          }}
                        >
                          Account
                        </StyledDropDownItem>
                      ) : null}
                      {/* {(user?.connectedStripeAccountId || showCoachDashboard) && ( */}
                      <StyledDropDownItem
                        style={{
                          backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                          color: isCoach ? themedColor : newThemedTextColor,
                        }}
                        onClick={() => {
                          redirectToAppIfIsCoachDomain(domain, ROUTES.ROLE_SWITCH);
                        }}
                      >
                        {currentRole === UserRoles.cohealer ? 'Client' : 'Coach'} Dashboard
                      </StyledDropDownItem>
                      {/* )} */}
                      <StyledDropDownItem
                        style={{
                          backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                          color: isCoach ? themedColor : newThemedTextColor,
                        }}
                        onClick={() => {
                          redirectToAppIfIsCoachDomain(domain, '/logout');
                        }}
                      >
                        Logout
                      </StyledDropDownItem>
                    </ClickDropDown>
                  </StyledUserSectionContainer>
                </div>
              </StyledHeaderContainer>
            </StyledHeaderMainContainer>
          ) : (
            <StyledHeaderMainContainer themedCardBackgroundColor={themedCardBackgroundColor} {...{ mobileView }}>
              <StyledHeaderContainer>
                <HeaderIcon
                  mobileView={mobileView}
                  src={leadMagnet?.customLogo === null ? CohereIcon : leadMagnet?.customLogo}
                />
              </StyledHeaderContainer>
            </StyledHeaderMainContainer>
          )
        ) : (
          <>
            {isCoach && isLMOwner ? (
              <>
                <StyledHeaderMainContainer themedCardBackgroundColor={themedCardBackgroundColor} {...{ mobileView }}>
                  <StyledHeaderContainer>
                    <div className="d-flex align-items-center">
                      {!isEmpty(user) && (
                        <>
                          <ArrowBackIosIcon
                            fontSize="small"
                            style={{ color: isCoach ? themedColor : newThemedTextColor }}
                            onClick={onBackClick}
                          />
                          <HeaderIcon
                            mobileView={mobileView}
                            src={leadMagnet?.customLogo === null ? CohereIcon : leadMagnet?.customLogo}
                          />
                        </>
                      )}
                    </div>
                    <StyledUserSectionContainer {...{ mobileView }}>
                      {colorToUse?.PrimaryColorCode && textColor && (
                        <Link onClick={setScrollingElementId}>
                          <DarkEnabledPrimaryFilledButton
                            backgroundColor={colorToUse?.PrimaryColorCode}
                            textColor={textColor}
                            autoWidth
                            {...{ mobileView, isDarkModeEnabled }}
                          >
                            Download Now
                          </DarkEnabledPrimaryFilledButton>
                        </Link>
                      )}
                      <StylesProvider injectFirst>
                        {!clientPreviewMode ? (
                          <AvatarComponent
                            // className="mx-2"
                            alt={`${user?.firstName} ${user?.lastName}`}
                            src={user?.avatarUrl}
                            mobileView={mobileView}
                          >
                            {`${user?.firstName && getInitialSymbol(user?.firstName)}${
                              user?.lastName && getInitialSymbol(user?.lastName)
                            }`}
                          </AvatarComponent>
                        ) : (
                          <AccountCircleIcon style={{ color: '#C7C7C7', fontSize: '1.5rem' }} />
                        )}
                      </StylesProvider>

                      <ClickDropDown
                        id="header-user-dropdown"
                        title={''}
                        mobileView={mobileView}
                        layover={run}
                        style={{ color: isCoach ? themedColor : newThemedTextColor }}
                        isCoach={isCoach}
                        // isDisable={isLaunchPlan === false}
                        clientPreviewMode={clientPreviewMode}
                        isPathForContribution={isPathForContribution(path)}
                      >
                        {shouldBeDisabled && currentRole === UserRoles.cohealer ? (
                          <StyledDropDownItem
                            style={{
                              backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                              color: isCoach ? themedColor : newThemedTextColor,
                            }}
                            className={'disableMe'}
                            to={'#'}
                          >
                            Account
                          </StyledDropDownItem>
                        ) : null}
                        {!shouldBeDisabled || (shouldBeDisabled && currentRole != UserRoles.cohealer) ? (
                          <StyledDropDownItem
                            style={{
                              backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                              color: isCoach ? themedColor : newThemedTextColor,
                            }}
                            className={
                              ((shouldBeDisabled && currentRole === UserRoles.cohealer) ||
                                (currentRole === UserRoles.cohealer && user.isPartnerCoach === false)) &&
                              'disableMe'
                            }
                            onClick={() => {
                              redirectToAppIfIsCoachDomain(domain, '/account/profile');
                            }}
                          >
                            Account
                          </StyledDropDownItem>
                        ) : null}
                        {/* {(user?.connectedStripeAccountId || showCoachDashboard) && ( */}
                        <StyledDropDownItem
                          style={{
                            backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                            color: isCoach ? themedColor : newThemedTextColor,
                          }}
                          onClick={() => {
                            redirectToAppIfIsCoachDomain(domain, ROUTES.ROLE_SWITCH);
                          }}
                        >
                          {currentRole === UserRoles.cohealer ? 'Client' : 'Coach'} Dashboard
                        </StyledDropDownItem>
                        {/* )} */}
                        <StyledDropDownItem
                          style={{
                            backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                            color: isCoach ? themedColor : newThemedTextColor,
                          }}
                          onClick={() => {
                            redirectToAppIfIsCoachDomain(domain, '/logout');
                          }}
                        >
                          Logout
                        </StyledDropDownItem>
                      </ClickDropDown>
                    </StyledUserSectionContainer>
                  </StyledHeaderContainer>
                </StyledHeaderMainContainer>
                <StyledSecondHeaderMainContainer>
                  {clientPreviewMode ? (
                    <StyledHeaderContainer>
                      <div className="d-flex align-items-center">
                        {!mobileView && !isEmpty(user) && (
                          <>
                            <ArrowBackIosIcon
                              fontSize="small"
                              style={{ color: isCoach ? themedColor : newThemedTextColor }}
                              onClick={clientPreviewBack}
                            />
                            <HeaderIcon
                              mobileView={mobileView}
                              src={leadMagnet?.customLogo === null ? CohereIcon : leadMagnet?.customLogo}
                            />
                          </>
                        )}
                      </div>
                      <StyledUserSectionContainer>
                        {islandingPage ? (
                          <Link
                            // onClick={() => {
                            //   redirectToAppIfIsCoachDomain(
                            //     domain,
                            //     `/lead-magnet-view/${leadMagnet.id}/clientpreview/thankyoupage`,
                            //   );
                            // }}
                            to={`/lead-magnet-view/${leadMagnet.id}/clientpreview/thankyoupage`}
                          >
                            <DarkEnabledPrimaryOutlineButton
                              textColor={colorToUse.AccentColorCode}
                              borderColor={colorToUse.AccentColorCode}
                              variant="outline"
                              autoWidth
                              {...{ isDarkModeEnabled, backgroundColor: btnBackgroundColor }}
                            >
                              Preview Thank You Page
                            </DarkEnabledPrimaryOutlineButton>
                          </Link>
                        ) : (
                          <Link
                            // onClick={() => {
                            //   redirectToAppIfIsCoachDomain(
                            //     domain,
                            //     `/lead-magnet-view/${leadMagnet.id}/clientpreview/landingpage`,
                            //   );
                            // }}
                            to={`/lead-magnet-view/${leadMagnet.id}/clientpreview/landingpage`}
                          >
                            <DarkEnabledPrimaryOutlineButton
                              textColor={colorToUse.AccentColorCode}
                              borderColor={colorToUse.AccentColorCode}
                              variant="outline"
                              autoWidth
                              {...{ isDarkModeEnabled, backgroundColor: btnBackgroundColor }}
                            >
                              Preview Landing Page
                            </DarkEnabledPrimaryOutlineButton>
                          </Link>
                        )}
                      </StyledUserSectionContainer>
                    </StyledHeaderContainer>
                  ) : (
                    <>
                      <StyledHeaderText {...{ mobileView }}>Only you can see these buttons</StyledHeaderText>
                      <StyledSecondHeaderContainer>
                        <Tooltip 
                          className="cursor-pointer text-grey"
                          disableHoverListener={shouldBeDisabled}
                          disableClickListener={shouldBeDisabled}
                          title={verifiedSendingAddresses.length === 0 ? "Connect your domain and sending address to enable it." : ""}
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}>
                          <Link to={`/lead-magnet-view/${leadMagnet?.id}/thankyouemail`}>
                            <DarkEnabledPrimaryOutlineButton
                              textColor={colorToUse.AccentColorCode}
                              borderColor={colorToUse.AccentColorCode}
                              variant="outline"
                              autoWidth
                              {...{
                                isDarkModeEnabled,
                                backgroundColor: btnBackgroundColor,
                                disabled: verifiedSendingAddresses.length === 0,
                              }}
                            >
                              {isEmailEditable ? 'Edit Welcome Email' : 'Enable Welcome Email'}
                            </DarkEnabledPrimaryOutlineButton>
                          </Link>
                        </Tooltip>
                        {islandingPage ? (
                          <Link
                            // onClick={() => {
                            //   redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet?.id}/thankyoupage`);
                            // }}
                            // to={redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet?.id}/landingpage`)}
                            to={`/lead-magnet-view/${leadMagnet?.id}/thankyoupage`}
                          >
                            <DarkEnabledPrimaryOutlineButton
                              textColor={colorToUse.AccentColorCode}
                              borderColor={colorToUse.AccentColorCode}
                              variant="outline"
                              autoWidth
                              {...{ isDarkModeEnabled, backgroundColor: btnBackgroundColor }}
                            >
                              View Thank You Page
                            </DarkEnabledPrimaryOutlineButton>
                          </Link>
                        ) : (
                          <Link
                            // onClick={() => {
                            //   redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet?.id}/landingpage`);
                            // }}
                            // to={redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet?.id}/thankyoupage`)}
                            to={`/lead-magnet-view/${leadMagnet?.id}/landingpage`}
                          >
                            <DarkEnabledPrimaryOutlineButton
                              textColor={colorToUse.AccentColorCode}
                              borderColor={colorToUse.AccentColorCode}
                              variant="outline"
                              autoWidth
                              {...{ isDarkModeEnabled, backgroundColor: btnBackgroundColor }}
                            >
                              View Landing Page
                            </DarkEnabledPrimaryOutlineButton>
                          </Link>
                        )}
                      </StyledSecondHeaderContainer>
                      <StyledSecondHeaderContainer>
                        {colorToUse?.AccentColorCode && textColor && (
                          <Link
                            onClick={() => {
                              redirectToAppIfIsCoachDomain(domain, `/edit-lead-magnet/${leadMagnet.id}/basic`);
                            }}
                            // to={`/edit-lead-magnet/${leadMagnet?.id}/basic`}
                          >
                            <DarkEnabledPrimaryFilledButton
                            backgroundColor={colorToUse?.PrimaryColorCode}
                            textColor={textColor}
                            autoWidth
                            style={{ padding: '.75rem 1.25rem' }}
                                {...{ isDarkModeEnabled }}
                              >
                              <StyledEditIcon style={{ marginTop: '-2px' }} />
                              Edit
                            </DarkEnabledPrimaryFilledButton>
                          </Link>
                        )}
                        <DarkEnabledPrimaryFilledButton
                          onClick={() => {
                            setShareModalVisible(true);
                          }}
                          backgroundColor={colorToUse?.PrimaryColorCode}
                          textColor={textColor}
                          autoWidth
                          style={{ padding: '.75rem 1.25rem' }}
                          {...{ isDarkModeEnabled, disabled: isArchived }}
                        >
                          <StyledShareIcon style={{ marginTop: '-2px' }} />
                          Share
                        </DarkEnabledPrimaryFilledButton>
                      </StyledSecondHeaderContainer>
                    </>
                  )}
                </StyledSecondHeaderMainContainer>
              </>
            ) : (
              <StyledHeaderMainContainer themedCardBackgroundColor={themedCardBackgroundColor} {...{ mobileView }}>
                <StyledHeaderContainer>
                  <HeaderIcon
                    mobileView={mobileView}
                    src={leadMagnet?.customLogo === null ? CohereIcon : leadMagnet?.customLogo}
                  />
                </StyledHeaderContainer>
              </StyledHeaderMainContainer>
            )}
          </>
        )

        // isCoach ? (
        //   <>
        //     <StyledHeaderMainContainer themedCardBackgroundColor={themedCardBackgroundColor} {...{ mobileView }}>
        //       <StyledHeaderContainer>
        //         <div className="d-flex align-items-center">
        //           {!isEmpty(user) && (
        //             <>
        //               <ArrowBackIosIcon fontSize="small" style={{ color: isCoach ? themedColor : newThemedTextColor }} />
        //               {/* <div
        //             className="h6 mr-3 cursor-pointer"
        //             // onClick={onBackClick}
        //             onKeyPress={null}
        //             role="button"
        //             tabIndex={0}
        //             style={{ color: isCoach ? themedColor : newThemedTextColor }}
        //           >
        //             Back
        //           </div> */}
        //               <HeaderIcon
        //                 mobileView={mobileView}
        //                 src={leadMagnet?.customLogo === null ? CohereIcon : leadMagnet?.customLogo}
        //               />
        //             </>
        //           )}
        //         </div>
        //         <StyledUserSectionContainer>
        //           <Link
        //             onClick={() => {
        //               // redirectToAppIfIsCoachDomain(domain, `/edit-contribution/${leadMagnet.id}/basic`);
        //             }}
        //           >
        //             <Button
        //               disabled
        //               backgroundColor={colorToUse?.PrimaryColorCode}
        //               textColor={textColor}
        //               autoWidth
        //               {...{ mobileView }}
        //             >
        //               Download Now
        //             </Button>
        //           </Link>
        //           <StylesProvider injectFirst>
        //             {!clientPreviewMode ? (
        //               <AvatarComponent
        //                 // className="mx-2"
        //                 alt={`${user?.firstName} ${user?.lastName}`}
        //                 src={user?.avatarUrl}
        //                 mobileView={mobileView}
        //               >
        //                 {`${user?.firstName && getInitialSymbol(user?.firstName)}${
        //                   user?.lastName && getInitialSymbol(user?.lastName)
        //                 }`}
        //               </AvatarComponent>
        //             ) : (
        //               <AccountCircleIcon style={{ color: '#C7C7C7', fontSize: '50px' }} />
        //             )}
        //           </StylesProvider>

        //           <ClickDropDown
        //             id="header-user-dropdown"
        //             title={''}
        //             mobileView={mobileView}
        //             layover={run}
        //             style={{ color: isCoach ? themedColor : newThemedTextColor }}
        //             isCoach={isCoach}
        //             // isDisable={isLaunchPlan === false}
        //             clientPreviewMode={clientPreviewMode}
        //             isPathForContribution={isPathForContribution(path)}
        //           >
        //             {shouldBeDisabled && currentRole === UserRoles.cohealer ? (
        //               <StyledDropDownItem
        //                 style={{
        //                   backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
        //                   color: isCoach ? themedColor : newThemedTextColor,
        //                 }}
        //                 className={'disableMe'}
        //                 to={'#'}
        //               >
        //                 Account
        //               </StyledDropDownItem>
        //             ) : null}
        //             {!shouldBeDisabled || (shouldBeDisabled && currentRole != UserRoles.cohealer) ? (
        //               <StyledDropDownItem
        //                 style={{
        //                   backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
        //                   color: isCoach ? themedColor : newThemedTextColor,
        //                 }}
        //                 className={
        //                   ((shouldBeDisabled && currentRole === UserRoles.cohealer) ||
        //                     (currentRole === UserRoles.cohealer && user.isPartnerCoach === false)) &&
        //                   'disableMe'
        //                 }
        //                 onClick={() => {
        //                   redirectToAppIfIsCoachDomain(domain, '/account/profile');
        //                 }}
        //               >
        //                 Account
        //               </StyledDropDownItem>
        //             ) : null}
        //             {/* {(user?.connectedStripeAccountId || showCoachDashboard) && ( */}
        //             <StyledDropDownItem
        //               style={{
        //                 backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
        //                 color: isCoach ? themedColor : newThemedTextColor,
        //               }}
        //               onClick={() => {
        //                 redirectToAppIfIsCoachDomain(domain, ROUTES.ROLE_SWITCH);
        //               }}
        //             >
        //               {currentRole === UserRoles.cohealer ? 'Client' : 'Coach'} Dashboard
        //             </StyledDropDownItem>
        //             {/* )} */}
        //             <StyledDropDownItem
        //               style={{
        //                 backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
        //                 color: isCoach ? themedColor : newThemedTextColor,
        //               }}
        //               onClick={() => {
        //                 redirectToAppIfIsCoachDomain(domain, '/logout');
        //               }}
        //             >
        //               Logout
        //             </StyledDropDownItem>
        //           </ClickDropDown>
        //         </StyledUserSectionContainer>
        //       </StyledHeaderContainer>
        //     </StyledHeaderMainContainer>
        //     <StyledSecondHeaderMainContainer>
        //       {clientPreviewMode ? (
        //         <StyledHeaderContainer>
        //           <div className="d-flex align-items-center">
        //             {!mobileView && !isEmpty(user) && (
        //               <>
        //                 <ArrowBackIosIcon
        //                   fontSize="small"
        //                   style={{ color: isCoach ? themedColor : newThemedTextColor }}
        //                   onClick={clientPreviewBack}
        //                 />
        //                 <HeaderIcon
        //                   mobileView={mobileView}
        //                   src={leadMagnet?.customLogo === null ? CohereIcon : leadMagnet?.customLogo}
        //                 />
        //               </>
        //             )}
        //           </div>
        //           <StyledUserSectionContainer>
        //             {islandingPage ? (
        //               <Link
        //                 onClick={() => {
        //                   redirectToAppIfIsCoachDomain(
        //                     domain,
        //                     `/lead-magnet-view/${leadMagnet.id}/clientpreview/thankyoupage`,
        //                   );
        //                 }}
        //               >
        //                 <Button
        //                   textColor={colorToUse.AccentColorCode}
        //                   borderColor={colorToUse.AccentColorCode}
        //                   variant="outline"
        //                   autoWidth
        //                 >
        //                   Preview Thank You Page
        //                 </Button>
        //               </Link>
        //             ) : (
        //               <Link
        //                 onClick={() => {
        //                   redirectToAppIfIsCoachDomain(
        //                     domain,
        //                     `/lead-magnet-view/${leadMagnet.id}/clientpreview/landingpage`,
        //                   );
        //                 }}
        //               >
        //                 <Button
        //                   textColor={colorToUse.AccentColorCode}
        //                   borderColor={colorToUse.AccentColorCode}
        //                   variant="outline"
        //                   autoWidth
        //                 >
        //                   Preview Landing Page
        //                 </Button>
        //               </Link>
        //             )}
        //             <Link
        //               onClick={() => {
        //                 // redirectToAppIfIsCoachDomain(domain, `/edit-contribution/${leadMagnet.id}/basic`);
        //               }}
        //             >
        //               <Button backgroundColor={colorToUse?.PrimaryColorCode} textColor={textColor} autoWidth>
        //                 Download Now
        //               </Button>
        //             </Link>
        //           </StyledUserSectionContainer>
        //         </StyledHeaderContainer>
        //       ) : (
        //         <>
        //           <StyledHeaderText {...{ mobileView }}>Only you can see these buttons</StyledHeaderText>
        //           <StyledSecondHeaderContainer>
        //             {islandingPage ? (
        //               <Link
        //                 onClick={() => {
        //                   redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet?.id}/landingpage`);
        //                 }}
        //                 // to={redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet?.id}/landingpage`)}
        //               >
        //                 <Button
        //                   textColor={colorToUse.AccentColorCode}
        //                   borderColor={colorToUse.AccentColorCode}
        //                   variant="outline"
        //                   autoWidth
        //                 >
        //                   View Thank You Page
        //                 </Button>
        //               </Link>
        //             ) : (
        //               <Link
        //                 onClick={() => {
        //                   redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet?.id}/thankyoupage`);
        //                 }}
        //                 // to={redirectToAppIfIsCoachDomain(domain, `/lead-magnet-view/${leadMagnet?.id}/thankyoupage`)}
        //               >
        //                 <Button
        //                   textColor={colorToUse.AccentColorCode}
        //                   borderColor={colorToUse.AccentColorCode}
        //                   variant="outline"
        //                   autoWidth
        //                 >
        //                   View Landing Page
        //                 </Button>
        //               </Link>
        //             )}
        //             <Link
        //               onClick={() => {
        //                 redirectToAppIfIsCoachDomain(domain, `/edit-lead-magnet/${leadMagnet.id}/basic`);
        //               }}
        //               // to={`/edit-lead-magnet/${leadMagnet?.id}/basic`}
        //             >
        //               <Button backgroundColor={colorToUse?.PrimaryColorCode} textColor={textColor} autoWidth>
        //                 Edit Lead Magnet
        //               </Button>
        //             </Link>
        //           </StyledSecondHeaderContainer>
        //           <StyledSecondHeaderContainer>
        //             <Link
        //               onClick={() => {
        //                 setModalVisible(true);
        //               }}
        //             >
        //               <Button
        //                 textColor={colorToUse.AccentColorCode}
        //                 borderColor={colorToUse.AccentColorCode}
        //                 variant="outline"
        //                 autoWidth
        //                 style={{ padding: '.75rem 1.25rem' }}
        //               >
        //                 <StyledShareIcon />
        //                 Share
        //               </Button>
        //             </Link>
        //           </StyledSecondHeaderContainer>
        //         </>
        //       )}
        //     </StyledSecondHeaderMainContainer>
        //   </>
        // ) : (
        //   <StyledHeaderMainContainer themedCardBackgroundColor={themedCardBackgroundColor} {...{ mobileView }}>
        //     <StyledHeaderContainer>
        //       <HeaderIcon
        //         mobileView={mobileView}
        //         src={leadMagnet?.customLogo === null ? CohereIcon : leadMagnet?.customLogo}
        //       />
        //       <StyledUserSectionContainer>
        //         <Link
        //           onClick={() => {
        //             // redirectToAppIfIsCoachDomain(domain, `/edit-contribution/${leadMagnet.id}/basic`);
        //           }}
        //         >
        //           <Button
        //             disabled
        //             backgroundColor={colorToUse?.PrimaryColorCode}
        //             textColor={textColor}
        //             autoWidth
        //             {...{ mobileView }}
        //           >
        //             Download Now
        //           </Button>
        //         </Link>
        //       </StyledUserSectionContainer>
        //     </StyledHeaderContainer>
        //   </StyledHeaderMainContainer>
        // )}
      }
      <Modal
        isOpen={shareModalVisible}
        minHeight={'280px'}
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '0.625rem',
              ...(leadMagnet?.isDarkModeEnabled ? { color: 'white' } : {}),
            }}
          >
            <StyledShareIcon style={leadMagnet?.isDarkModeEnabled ? { color: 'white', margin: 0 } : { margin: 0 }} />
            <div>Share Lead Magnet</div>
          </div>
        }
        hiddenCancel
        onCancel={handleCloseModal}
        className={`lead-magnet-form-modal ${leadMagnet?.isDarkModeEnabled ? 'dark-mode' : ''}`}
        disableFooter={true}
        style={leadMagnet?.isDarkModeEnabled ? { backgroundColor: 'rgb(45, 47, 49)' } : {}}
      >
        <div className="lead-magnet-heading">
          <div style={{ color: themedColor, marginTop: '12px' }}> Copy Opt-In Page Link - Share This With Leads </div>
        </div>
        <div style={{ display: 'flex' }}>
          <StyledTextField
            darkMode={leadMagnet?.isDarkModeEnabled}
            placeholder={
              generatingShareableLink && !leadMagnetShareableLink ? 'Generating link...' : 'Copy Opt-In Page Link - Share This With Leads'
            }
            // disabled={generatingShareableLink && !leadMagnetShareableLink}
            value={leadMagnetShareableLink}
            //value={user.profileLinkName ? addhttp(user.profileLinkName.concat('.', `${parentDomain}/lead-magnet-view/${leadMagnet.id}/landingpage`)): `${origin}/lead-magnet-view/${leadMagnet.id}/landingpage` }
            fullWidth
            variant="outlined"
            id="margin-none"
          />
          <Link
            disabled={generatingShareableLink && !leadMagnetShareableLink}
            onClick={
              generatingShareableLink && !leadMagnetShareableLink
                ? null
                : () => {
                    handleCopyClick();
                    setTooltipOpen(true);
                    handleCopyClick();
                    setTimeout(() => {
                      setTooltipOpen(false);
                    }, 2000);
                  }
            }
          >
            <Tooltip className="cursor-pointer" title="Link Copied" open={tooltipOpen} onClose={() => {}} arrow>
              <DarkEnabledPrimaryFilledButton
                backgroundColor={colorToUse?.PrimaryColorCode}
                textColor={textColor}
                autoWidth
                disabled={generatingShareableLink && !leadMagnetShareableLink}
                style={{
                  margin: '16px 0 0 12px',
                  width: 'auto',
                  height: '40px',
                  padding: '11px 30px',
                  gap: '0px',
                  opacity: '1',
                }}
                {...{ isDarkModeEnabled }}
              >
                Copy
              </DarkEnabledPrimaryFilledButton>
            </Tooltip>
          </Link>
        </div>

        <div className="lead-magnet-sub-heading">
          <div style={{ color: themedColor, marginTop: '12px' }}> Copy Thank You Page Link </div>
        </div>
        <div style={{ display: 'flex' }}>
          <StyledTextField
            darkMode={leadMagnet?.isDarkModeEnabled}
            placeholder={
              generatingShareableLink && !leadMagnetShareableThankYouLink
                ? 'Generating link...'
                : 'Copy Thank You Page Link'
            }
            value={leadMagnetShareableThankYouLink}
            fullWidth
            variant="outlined"
            id="margin-none"
          />
          <Link
            disabled={generatingShareableLink && !leadMagnetShareableThankYouLink}
            onClick={
              generatingShareableLink && !leadMagnetShareableThankYouLink
                ? null
                : () => {
                    handleCopyThankYouClick();
                    setThankYouTooltipOpen(true);
                    setTimeout(() => {
                      setThankYouTooltipOpen(false);
                    }, 2000);
                  }
            }
          >
            <Tooltip className="cursor-pointer" title="Link Copied" open={thankYouTooltipOpen} onClose={() => {}} arrow>
              <DarkEnabledPrimaryFilledButton
                backgroundColor={colorToUse?.PrimaryColorCode}
                textColor={textColor}
                autoWidth
                disabled={generatingShareableLink && !leadMagnetShareableThankYouLink}
                style={{
                  margin: '16px 0 0 12px',
                  width: 'auto',
                  height: '40px',
                  padding: '11px 30px',
                  gap: '0px',
                  opacity: '1',
                }}
                {...{ isDarkModeEnabled }}
              >
                Copy
              </DarkEnabledPrimaryFilledButton>
            </Tooltip>
          </Link>
        </div>
      </Modal>
      {analyticsModalOpened && (
        <ConversionMetricsModal
          isOpen={analyticsModalOpened}
          handleClose={handleAnalyticsModalClose}
          leadMagnetId={leadMagnet.id}
          isDarkModeEnabled={isDarkModeEnabled}
        />
      )}
    </>
  );
};

export default LeadMagnetHeader;
