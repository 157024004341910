import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './PopupOptions.scss';
import styled from 'styled-components';

const StyledList = styled.ol`
  list-style-type: none;
  padding: 0;
  cursor: pointer;

  li {
    margin-bottom: 5px;
  }

  li:hover {
    text-decoration: underline;
  }
`;

const SelectItem = ({ text, value, onSelectOption }) => (
  <li onClick={() => onSelectOption(value)} role="presentation">
    {text}
  </li>
);

const PopupOptions = ({ title, options, onSelectOption, open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: 'dialog-container',
      }}
    >
      <DialogTitle className="chat-participants-modal-header" id="alert-dialog-title">
        <IconButton
          style={{ right: 10, position: 'absolute', top: 10 }}
          className="chat-participants-modal-header__close"
          onClick={onClose}
        >
          <CloseIcon style={{ color: 'black' }} />
        </IconButton>
        <p className="chat-participants-modal-header__title">{title}</p>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <StyledList>
            {options.map(option => (
              <SelectItem text={option.text} value={option.value} onSelectOption={onSelectOption} />
            ))}
          </StyledList>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

PopupOptions.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectOption: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

PopupOptions.defaultProps = {
  title: '',
  open: false,
  onSelectOption: () => {},
  options: [],
  onClose: undefined,
};

SelectItem.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  onSelectOption: PropTypes.func.isRequired,
};
export default PopupOptions;
