import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import * as R from 'ramda';
import { useTheme, useMediaQuery } from '@material-ui/core';
import instagramIcon from 'assets/instagram.svg';
import linkedinIcon from 'assets/linkedin.svg';
import youtubeIcon from 'assets/youtube.svg';
import webIcon from 'assets/web.svg';
import facebookIcon from 'assets/facebook.svg';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import tiktokIcon from 'assets/tiktok29.svg';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';

import {
  setCohealerInfoForClient,
  clearCohealerInfoForClient,
  setCohealerIcon,
  clearCohealerIcon,
} from 'actions/cohealerInfo';
import { useHttp, useShallowEqualSelector, useAccount } from 'hooks';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond, LabelText } from 'components/UI/Text/TextStyles';
import { CoachItem } from './CoachItem';
// import bannerImg from '../../../../assets/Main-Man.png';
import fbImg from '../../../../assets/Facebook.png';
import linkedImg from '../../../../assets/LinkedIn.png';
import instaImg from '../../../../assets/Instagram.png';
import breathIcon from '../../../../assets/icon-breathwork.png';
import heartIcon from '../../../../assets/heart-icon.png';
import PurchaseBlock from '../PurchaseBlock/PurchaseBlock';
import { lightOrDark } from 'utils/utils';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  max-height: 360px;
  object-fit: contain;
  display: block;
`;

const StyledName = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
`;
const StyledLabelText = styled(LabelText)`
  font-weight: 500;
`;
const StyledImg = styled.img`
  margin-right: 5px;
  margin-bottom: 5px;
`;
const SocialGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  border-top: 1px solid #e7e7e7;
  margin-top: 20px !important;
`;
const TagsDiv = styled.div`
  margin: 0px 10px 0px 0px;
`;
// const MainImg = styled.image`
//   background-image: url(${MainImgBanner});
//   padding: 18rem;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// `;
const StyledCardBody = styled(CardBody)`
  padding: 16px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ mobileView }) => mobileView && `padding: 8px;`}
`;

const StyledBannerImg = styled.div`
  background-image: url(${({ bannerImg }) => bannerImg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 10%;
  padding-bottom: 10%;
  border-radius: 12px;
  ${({ bannerImg }) => bannerImg[0] === null && `background-color: #f8f8f8;`}
  ${({ mobileView }) => (mobileView ? `height: 160px;` : `height: 400px;`)}
  width: 100%;
`;
const SocialLinkMainContainer = styled.div`
  display: flex;
  justify-content: center;
  // padding: 1rem 0px;
  // padding: 0px;
  padding: 0px 0px 12px 0px;
`;
const LeftBtnMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
const TagsBtn1 = styled.button`
  background-color: '#CDBA8F';
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  padding: 5px 10px;
  color: #fff;
`;
const TagsBtn2 = styled.button`
  background: #ce6467;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  padding: 5px 10px;
  color: #fff;
`;
const TagsBtn3 = styled.button`
  background: #f0f3f3;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  padding: 5px 10px;
  color: #000;
`;
const RightLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 14%;
`;
const FbImg = styled.img``;
const LinkImg = styled.img``;
const InstaImg = styled.img``;
const MainHeading = styled.div`
  padding: 0px 0px 8px 0px;
  margin-top: ${({ mobileView, isLeadMagnet }) => 
      mobileView ? '0px' : (isLeadMagnet ? '0px' : '50px')};`;
const StyledTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.02em;
  color: #282c2c;
  margin: 0px;
  widht: 1216px;
  padding: ${({ mobileView }) => (mobileView ? '0rem 1rem' : '')};
  line-height: ${({ mobileView }) => (mobileView ? '120%' : '')};
  ${({ mobileViewTem2 }) => (mobileViewTem2 ? 'font-size: 28px' : '')}
`;

const StyledTagline = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #656d6d;
  margin: 0px;
  padding: ${({ mobileView }) => (mobileView ? '0rem 1rem' : '')};
  line-height: 30px;
  // width: 1216px;
  // height: 30px;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ isLeadMagnet, leadMagnetDarkModeEnabled }) =>
    isLeadMagnet &&
    css`
      color: ${leadMagnetDarkModeEnabled ? 'white' : '#656d6d'};
      text-align: center;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    `}
`;
const MainHeaderPackegs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: #6999ca;
  padding: 14px 0px;
`;
const SimpleSesstionP = styled.p`
  margin: 0px;
`;
const PackegeP = styled.p`
  margin: 0px;
`;
const SubscriptionP = styled.p`
  margin: 0px;
`;
const StyledButton = styled.button`
  position: relative;
  padding: 0.5rem 1rem;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  color: ${props => props.color};
  fill: #fff; /* for svg */
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 6rem;
  transition: background 0.2s;
  font-family: 'Poppins';
  background: ${props => props.background};
  font-style: normal;
  font-weight: 400;
  margin: ${props => props.margin};
  max-width: ${props => props.maxWidth};
`;

const AboutMainInfoBlock = ({
  id,
  userId,
  previewContentUrls,
  socialUrls: { instagramUrl, linkedInUrl, youtubeUrl, facebookUrl, websiteUrl, tiktokUrl },
  categories,
  contributionTagline,
  title,
  brandingColors,
  isLeadMagnet = false,
}) => {
  const dispatch = useDispatch();
  const contribution = useContribution();
  const leadMagnet = useLeadMagnet();
  const { themedColors } = useLeadMagnetTheme();
  const { currentRole } = useAccount();
  const { request } = useHttp();
  const avatarUrl = useShallowEqualSelector(state => state?.cohealerInfo?.avatarUrl);
  const isCoach = currentRole == 'Cohealer';
  const { themedColor, themedCardBackgroundColor, newThemedTextColor } = getThemedColors(contribution, isCoach);

  useEffect(() => {
    request(`/Contribution/GetCohealerInfoForClient/${userId}`, 'GET')
      .then(R.compose(dispatch, setCohealerInfoForClient))
      .catch(console.dir);

    return R.compose(dispatch, clearCohealerInfoForClient);
  }, [userId, dispatch, request]);

  useEffect(() => {
    request(`/User/GetCohealerIcon/${id}`, 'GET').then(R.compose(dispatch, setCohealerIcon)).catch(console.dir);

    return R.compose(dispatch, clearCohealerIcon);
  }, [id, dispatch, request]);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileViewTem2 = useMediaQuery(theme.breakpoints.down('sm'));

  const socialLinks = [
    {
      title: 'Website',
      url: websiteUrl,
      icon: webIcon,
    },
    {
      title: 'Instagram',
      url: instagramUrl,
      icon: instagramIcon,
    },
    {
      title: 'LinkedIn',
      url: linkedInUrl,
      icon: linkedinIcon,
    },
    {
      title: 'Facebook',
      url: facebookUrl,
      icon: facebookIcon,
    },
    {
      title: 'Youtube',
      url: youtubeUrl,
      icon: youtubeIcon,
    },
    {
      title: 'Tiktok',
      url: tiktokUrl,
      icon: tiktokIcon,
    },
  ];
  const isAnyLinkExist = !!socialLinks.filter(sl => !!sl.url).length;
  let colorToUse = determineColorToUse(contribution);
  const textColorToUse =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  var colorsStyle2 = [
    brandingColors.PrimaryColorCode,
    brandingColors.AccentColorCode,
    brandingColors.TertiaryColorCode,
  ];

  return (
    <>
      <Grid md={12} sm={12} xs={12} style={{ padding: isLeadMagnet && !mobileView ? '0 2rem' : '' }}>
        <MainHeading {...{ mobileView, isLeadMagnet }}>
          <StyledTitle
            style={{ color: isLeadMagnet ? themedColors.themedColor : themedColor, wordBreak: 'break-word', fontSize: isLeadMagnet ? '40px' : '44px' }}
            mobileViewTem2={mobileViewTem2}
            {...{ mobileView }}
          >
            {title}
          </StyledTitle>
          {contributionTagline && (
            <StyledTagline
              color={
                isLeadMagnet
                  ? leadMagnet?.isDarkModeEnabled
                    ? themedColors.newThemedTextColor
                    : null
                  : newThemedTextColor
              }
              {...{ mobileView, isLeadMagnet, leadMagnetDarkModeEnabled: leadMagnet?.isDarkModeEnabled }}
            >
              {contributionTagline}
            </StyledTagline>
          )}
          {!isLeadMagnet && (
            <SocialLinkMainContainer>
              <LeftBtnMain>
                {categories.map((item, index) => {
                  var color2 = colorToUse?.AccentColorCode;
                  return (
                    <StyledButton
                      background={color2}
                      color={textColorToUse}
                      margin="5px 3px"
                      style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        padding: '5px 10px',
                        borderRadius: '5px',
                      }}
                    >
                      <StyledName>{item}</StyledName>
                    </StyledButton>
                  );
                })}
                {/* <TagsDiv>
                <TagsBtn1>Life Coaching</TagsBtn1>
              </TagsDiv> */}
              </LeftBtnMain>
            </SocialLinkMainContainer>
          )}
        </MainHeading>
        <Grid
          item
          className="coverImageTempTwo"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={isLeadMagnet ? { marginTop: !mobileView ? '10px' : '18px'} : {}}
        >
          {JSON.stringify(previewContentUrls).includes('coherepublic') && (
            <img
              src={previewContentUrls}
              className={mobileView ? 'templateTwoImageMobile' : 'templateTwoImage'}
              style={isLeadMagnet ? { width: '90%' } : {}}
              alt={title}
            />
          )}
          {!JSON.stringify(previewContentUrls).includes('coherepublic') && (
            <div className="noImageBackground" style={{ background: 'rgb(250 250 250)', height: 400 }} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

AboutMainInfoBlock.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  previewContentUrls: PropTypes.arrayOf(PropTypes.string),
  serviceProviderName: PropTypes.string,
  contributionPartners: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ).isRequired,
  socialUrls: PropTypes.shape({
    instagramUrl: PropTypes.string,
    linkedInUrl: PropTypes.string,
    youtubeUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
};

AboutMainInfoBlock.defaultProps = {
  previewContentUrls: [],
  serviceProviderName: 'No name',
  socialUrls: {},
};

export default AboutMainInfoBlock;
