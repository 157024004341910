import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/UI/Modal';
import { useHttp } from 'hooks';
import { getThemedColors } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';

export const ModalTermsAndConditions = ({ showTerms, onCancel, applyTheming }) => {
  const [documents, setDocuments] = useState([]);
  const { request } = useHttp();
  const contribution = useContribution();
  useEffect(() => {
    request('/ReferenceData/GetAgreements').then(setDocuments);
  }, [request]);
  const { newThemedTextColor } = getThemedColors(contribution);
  const themedColor = newThemedTextColor;
  return (
    <Modal
      applyTheming={applyTheming}
      isOpen={showTerms}
      onCancel={onCancel}
      title="Terms and Conditions"
      disableConfirm
    >
      <div className="d-flex flex-column">
        {documents.map(({ id, fileNameWithExtension, fileUrl }) => (
          <a
            style={{ color: themedColor }}
            key={id}
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="mb-1"
          >
            {fileNameWithExtension}
          </a>
        ))}
      </div>
    </Modal>
  );
};

ModalTermsAndConditions.propTypes = {
  showTerms: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  applyTheming: PropTypes.bool,
};
ModalTermsAndConditions.defaultProps = {
  applyTheming: false,
};
