import React, { useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Formik, Form } from 'formik';
import CoachImage from 'assets/chatlogo.png';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep, isNil, sortBy } from 'lodash';
import BrowseIconImage from 'assets/browse.png';
import { useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTheme, useMediaQuery, Box } from '@material-ui/core';
import TemplateOneCongrats from 'assets/congratulations.png';
import { isEmpty } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ImageCropper from '../CreateContribution/components/Cropper/ImageCropper';
import EasyCropper from '../CreateContribution/components/Cropper/EasyCropper';
import CohereIcon from 'assets/icon.png';
import TemplateOneIcon from 'assets/templateone1.png';
import TemplateTwoIcon from 'assets/templatetwo2.png';
import TemplateThreeIcon from 'assets/templatethree3.png';
import TemplateOne from 'assets/lead-magnet-landing-page-sample-template-1.jpg';
import { LeadMagnetTemplateType } from 'helpers/constants';
import CrossIcon from '@material-ui/icons/Clear';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import { DialogActions, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { ImageCompressor } from 'image-compressor';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { StyledMainSection } from 'components/Containers/CreateLeadMagnetContainer';
import MainContainer from 'components/Containers/MainContainer';
import * as leadMagnetActions from 'actions/leadMagnets';
import * as pageActions from 'actions/page';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import Button from 'components/FormUI/Button';
import MUIButton from '@mui/material/Button';
import Loader from 'components/UI/Loader';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { useSaveLeadMagnet } from 'hooks';
import { PageTitleSecond, BodyText } from 'components/UI/Text/TextStyles';
import { useHttp, usePreferences, useRouter, useAccount } from 'hooks';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';
import { toRem, colors } from 'utils/styles';
import { dataURLtoFile } from 'utils/utils';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { LeadMagnetType } from 'helpers/constants';
import { Switch } from '../../components/switch/style';
import Buttons from '../CreateContribution/components/Buttons';
import { CONTRIBUTION_COLORS, EN_MESSAGES, TOOLTIP } from '../../constants';
import ImageDropzone from '../../components/FormUI/ImageDropone';
import Modal from 'components/UI/Modal';
import ImageViewer from 'react-simple-image-viewer';
import '../CreateContribution/style.css';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { Diversity1 } from '@mui/icons-material';
import { getLeadMagnetsCounts, getArchivedLeadMagnetsCount } from 'services/leadMagnets.service';
import pdfFile from 'assets/Lead Magnet landing Page.pdf';

const StyledTitle = styled.div`
  font-family: Avenir;
  font-size: 18px;
  // font-size: 1.2rem;
  font-weight: 500;
  line-height: 21.41px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  ${({ mobileView }) =>
    mobileView &&
    css`
      font-size: 16px;
      margin-top: 20px;
    `};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.51rem;
`;

const StyledExplainText = styled.div`
  font-size: ${toRem(16)};
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledImgForTour = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledImg = styled.div`
  // width: 100%;
  // height: 100%;
  // border: 1px dotted ${colors.darkOceanBlue};
  // object-fit: contain;
  // object-position: left;

  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  border: 1px solid #00000021;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 4.05844rem;
  height: 4.05844rem;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledButtonClicked = styled.button`
  background-color: white;
  padding: 16px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  border: solid 1px #dadada;
  border-radius: 4px;
  line-height: 23.94px;
  letter-spacing: 0.12px;
  width: 100%;
  color: black;
  cursor: pointer;
  outline: none;

  &:disabled {
    background-color: #ededed;
    color: #9b9b9b;
    border: none;
    cursor: initial;
  }

  ${({ active }) =>
    active &&
    css`
      border: solid 1px ${colors.darkOceanBlue};
      background: ${colors.darkOceanBlue};
      color: white;
      outline: none !important;
      font-weight: 900;
    `}
`;

const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  // object-fit: contain;
  /* border: solid 1px #dadada;
  border-radius: 10px; */
`;

const ImagePlaceholder = styled.div`
  position: relative;
  background-color: #f5f5f5;
  width: 100%;
  min-height: 200px;

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${colors.fadedPurple};
    `}
`;

const FlexImagePlaceholder = styled(ImagePlaceholder)`
  display: flex;
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 0px 24px 10px 24px;
  background-color: ${({ color }) => color};
`;

const useStyles = makeStyles({
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  subTitle: {
    fontFamily: 'Avenir',
    fontSize: '0.875rem',
    fontWeight: '350',
    lineHeight: '22px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
  row: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  categoriesRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  radioGroupRoot: {
    flexDirection: 'row',
  },
  // detailsHeader: {
  //   marginRight: '20px',
  //   whiteSpace: 'nowrap',
  // },
  header: ({ mobileView }) =>
    mobileView
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingBottom: '10px',
        }
      : {},
});

const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
`;
const StyledSwitchWrap = styled(Box)`
  display: flex;
  align-items: center;
`;
const StyledSwitch = styled(Switch)`
  margin: 0 10px 0 0;
`;

const StyledIcons = styled.div`
  display: flex;
  flex-direction: 'row';
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  flex-wrap: wrap;
`;

const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const TemplateIcon = styled.img`
  margin: 5px;
  vertical-align: middle;
  width: 150px;
  height: 130px;
  border-radius: 5px;
  object-fit: fill;
  object-position: center;
  border: ${({ active }) => (active ? `1px solid ${colors.darkOceanBlue}` : '1px solid #dadada')};
  cursor: pointer;
`;
const BrowseIcon = styled.img`
  margin: 5px;
  vertical-align: middle;
  width: 10px;
  height: 15px;
  object-fit: fill;
  object-position: center;
`;
const StyledDisabledButton = styled(MUIButton)`
  display: flex;
  /* width: 9.44206rem; */
  height: 2.25rem;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;

  &.MuiButtonBase-root.Mui-disabled {
    border-radius: 0.25rem;
    border: 1px solid #215c73;
    opacity: 0.4;
    background: #fff;
  }
`;
const StyledChooseTemplateButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.625rem;
`;
const StyledComingSoonLabel = styled.div`
  display: flex;
  /* width: 5.75rem; */
  height: 1.375rem;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;

  border-radius: 0.25rem;
  border: 1px solid #215c73;
  background: var(--Cohere-Primary-Blue, #215c73);

  .text {
    color: var(--Cohere-Primary-White, #fff);
    text-align: center;
    font-family: Avenir;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.64063rem; /* 218.75% */
  }
`;
const StyledText = styled.div`
  color: #215c73;
  text-align: center;
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.64063rem; /* 187.5% */
  letter-spacing: 0.02681rem;

  ${({ mobileView }) =>
    mobileView
      ? css`
          text-transform: capitalize;
        `
      : css``};
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ mobileView }) => mobileView && `flex-direction:column`}
`;

const initialValues = {
  category: [],
};
const BasicInformation = ({ onDuplicate }) => {
  const dispatch = useDispatch();
  const { user } = useAccount();
  const { state } = useLocation();
  const refreshStep = state?.from === 'refreshLeadMagnet';
  const [isStyleModalOpen, setIsStyleModalOpen] = useState(false);
  const imgRef = useRef();
  const serviceAgreementType = user?.serviceAgreementType;
  const isBetaUser = user?.isBetaUser;
  const {
    user: { transfersEnabled, isStandardAccount, defaultPaymentMethod, standardAccountTransfersEnabled, avatarUrl },
  } = useAccount();
  const showOnlyFreeOption = serviceAgreementType && isBetaUser ? true : false;
  const showFreeOption =
    (user?.defaultPaymentMethod === 'Simple' && transfersEnabled === false) ||
    (user?.defaultPaymentMethod === 'Advance' && standardAccountTransfersEnabled === false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('0');
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(3);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [categoryArray, setcategoryArray] = useState([]);
  const [customCategory, setCustomCategory] = useState('');
  const errorOccured = useSelector(state => state?.leadMagnets.showError);
  useEffect(() => {
    if (
      mobileView &&
      (!user?.userProgressbarData?.FirstContributionDone ||
        (user?.userProgressbarData?.FirstContributionDone === true && refreshStep))
    ) {
      setStepIndex(4);
      setRun(true);
    } else if (
      mobileView === false &&
      (!user?.userProgressbarData?.FirstContributionDone ||
        (user?.userProgressbarData?.FirstContributionDone === true && refreshStep))
    ) {
      setStepIndex(3);
      setRun(true);
    }
  }, [mobileView]);
  const [errorState, setErrorState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);

  const [isFirstTime, setIsFirstTime] = useState(false);

  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const toggleCategoriesPopup = () => {
    setErrorState(false);
    setShowCategoriesModal(!showCategoriesModal);
    setCategoriesOpen(false);
    setcategoryArray([]);
    setCustomCategory('');
    refetch();
  };
  let joyRideHelpers;
  useEffect(() => {
    if (isEditing === true) {
      setRun(false);
    }
  }, []);
  const [contibId, setContibId] = useState(null);
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);

  const updateUserF = async () => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      dispatch(updateUser(u));
      if (res.userProgressbarData?.IsPlanPurchased === false) {
        history.push('/account/payment', { from: 'verify' });
      } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
        history.push('/account/payment', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IntegrationsDone === false) {
        history.push('/account/integrations', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
        history.push('/account/profile');
      } else if (res?.userProgressbarData?.FirstContributionDone === false) {
        dispatch(leadMagnetActions.setPopUp(true));
        history.push('/dashboard');
      } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
        history.push('/account/profile', { from: 'Manage Your Website Information' });
      } else if (res?.userProgressbarData?.InviteLink === false) {
        contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
      }
    });
    joyRideHelpers.skip();
  };
  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: CONTRIBUTION_COLORS.PrimaryColorCode,
              borderWidth: '5px',
            }}
          >
            <StyledImg style={{ borderRadius: '100px' }} previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Setup Your Landing Page & Opt-In
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            In the following 5 simple steps, you are creating a landing page with built in paid or free opt-in that will
            bring your customers into your services on Cohere!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {user?.userProgressbarData?.FirstContributionDon === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}
            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'right',
      target: '#create-contribution-basic-info-link-test',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImgForTour previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Choose a Service Type
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Each contribution has unique payment and scheduling features on Steps 3 & 4! You can edit this any time
            before submitting your contribution on Step 5.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {refreshStep === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#createContribution',
    },
  ]);
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const saveLeadMagnet = useCallback((...args) => dispatch(leadMagnetActions.saveLeadMagnet(...args)), [dispatch]);
  const saveLeadMagnetToLS = useCallback((...args) => dispatch(leadMagnetActions.saveDraftLeadMagnet(...args)), [
    dispatch,
  ]);
  const changeHeader = useCallback((...args) => dispatch(pageActions.changeHeader(...args)), [dispatch]);
  const leadMagnetFormRef = useRef();
  const [redirectTo, setRedirectTo] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageToCrop, setImageToCrop] = useState('');
  const [errorsState, setErrors] = useState([]);

  const { leadMagnet, loading } = useSelector(({ leadMagnets }) => ({
    leadMagnet: leadMagnets.activeLeadMagnet,
    loading: leadMagnets.loading,
  }));

  const { request, loading: hookLoading } = useHttp();
  const { preferences, refetch } = usePreferences();
  const { history, query } = useRouter();
  const classes = useStyles();
  const { type: prevLeadMagnetType } = useLeadMagnet();
  const fileInput = React.createRef();
  // Image size in bytes
  const imagelimitinbytes = 10485760;
  //
  const isEditing = !!query.id;
  const saveHandler = isEditing ? saveLeadMagnet : saveLeadMagnetToLS;
  const { onSave: onSaveLeadMagnet } = useSaveLeadMagnet(isEditing);
  const defaultTemplate = isEditing ? LeadMagnetTemplateType.LMTemplateOne : LeadMagnetTemplateType.LMTemplateOne;

  const leadMagnetClickHandler = useCallback(
    (type, setFieldValue) => () => {
      setFieldValue('type', type);
    },
    [],
  );
  const uploadFileHandler = (setFieldValue, setImageLoading, droppedFiles = []) => e => {
    // setImageLoading(true);
    const imageCompressor = new ImageCompressor();
    const compressorSettings = {
      toWidth: 640,
      toHeight: 350,
      mimeType: 'image/png',
      mode: 'strict',
      quality: 0.6,
    };
    const formData = new FormData();

    let files;
    if (droppedFiles.length > 0) {
      files = droppedFiles;
    } else {
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
    }
    if (!files || !files[0]) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageToCrop(reader.result?.toString() || '');
      setIsOpen(true);
      // const imageSrc = reader.result;
      // if (files[0].size <= imagelimitinbytes) {
      //   formData.append('file', dataURLtoFile(imageSrc));
      //   request('/content/addpublicimage', 'POST', formData, {
      //     'Content-Type': 'multipart/form-data',
      //   })
      //     .then(image => {
      //       setFieldValue('file', image);
      //     })
      //     .catch(console.dir)
      //     .finally(() => setImageLoading(false));
      // } else {
      //   imageCompressor.run(imageSrc, compressorSettings, compressedSrc => {
      //     formData.append('file', dataURLtoFile(compressedSrc));
      //     request('/content/addpublicimage', 'POST', formData, {
      //       'Content-Type': 'multipart/form-data',
      //     })
      //       .then(image => {
      //         setFieldValue('file', image);
      //       })
      //       .catch(console.dir)
      //       .finally(() => setImageLoading(false));
      //   });
      // }
    };
    reader.readAsDataURL(files[0]);
  };

  const uploadFileHandlerwithoutevent = (setFieldValue, setImageLoading, droppedFiles = []) => {
    // setImageLoading(true);
    const imageCompressor = new ImageCompressor();
    const compressorSettings = {
      toWidth: 640,
      toHeight: 350,
      mimeType: 'image/png',
      mode: 'strict',
      quality: 0.6,
    };
    const formData = new FormData();

    let files;
    if (droppedFiles.length > 0) {
      files = droppedFiles;
    }

    if (!files || !files[0]) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageToCrop(reader.result?.toString() || '');
      setIsOpen(true);
      const imageSrc = reader.result;
      // imageCompressor.run(imageSrc, compressorSettings, compressedSrc => {
      //   formData.append('file', dataURLtoFile(compressedSrc));
      //   request('/content/addpublicimage', 'POST', formData, {
      //     'Content-Type': 'multipart/form-data',
      //   })
      //     .then(image => {
      //       setFieldValue('file', image);
      //     })
      //     .catch(console.dir)
      //     .finally(() => setImageLoading(false));
      // });
    };
    reader.readAsDataURL(files[0]);
  };

  const submitHandler = useCallback(
    values => {
      console.info('values:', values);
      setErrors([]);
      const data = {
        ...values,
        coachSelectedBrandingColors: {
          PrimaryColorCode: user.brandingColors?.PrimaryColorCode || '#CDBA8F',
          AccentColorCode: user.brandingColors?.AccentColorCode || '#116582',
          TertiaryColorCode: user.brandingColors?.TertiaryColorCode || '#F6E8BO',
          TextColorCode: user.brandingColors?.TextColorCode || '#FFFFFF',
        },
        brandingColors: {
          PrimaryColorCode: leadMagnet?.brandingColors?.PrimaryColorCode || '#CDBA8F',
          AccentColorCode: leadMagnet?.brandingColors?.AccentColorCode || '#116582',
          TertiaryColorCode: leadMagnet?.brandingColors?.TertiaryColorCode || '#F6E8BO',
          TextColorCode: leadMagnet?.brandingColors?.TextColorCode || '#FFFFFF',
        },
        // isThankyouPageAdded: values?.isThankyouPageAdded,
        // thankyouPage: values?.thankyouPage,
        // isLive: values.isLive === 'live', // && values.type === ContributionType.contributionCourse,
        // categories: values.categories,
        previewContentUrls: [values.file],
        userId: user.id,
      };
      console.info('values-data:', cloneDeep(data));

      // if ([ContributionType.contributionOneToOne, ContributionType.contributionLocalEvent].includes(values.type)) {
      //   data.isLive = true;
      // }

      delete data.file;

      changeHeader(data.title);

      const newLeadMagnet = {
        ...leadMagnet,
        ...data,
        // isThankyouPageAdded: leadMagnet?.isThankyouPageAdded || data?.isThankyouPageAdded,
        // thankyouPage: leadMagnet?.thankyouPage || data?.thankyouPage,
      };

      console.info('values-newLeadMagnet:', newLeadMagnet);

      // if (!isEditing && newLeadMagnet.sessions && prevLeadMagnetType !== newLeadMagnet.type) {
      //   delete newLeadMagnet.sessions;
      // }
      onSaveLeadMagnet(newLeadMagnet);
      console.info('values-isEditing:', isEditing);
      // return;
      if (isEditing) {
        setTimeout(() => {
          history.push(redirectTo);
        }, 500);
        return;
      }

      saveHandler({
        ...newLeadMagnet,
        // showOnlyFreeOption,
        // showFreeOption,
        // isThankyouPageAdded: leadMagnet?.isThankyouPageAdded || data?.isThankyouPageAdded,
        // thankyouPage: leadMagnet?.thankyouPage || data?.thankyouPage,
      })
        .then(res => {
          console.info('res:', res);
          setTimeout(() => {
            history.push(redirectTo);
          }, 500);
        })
        .catch(err => {
          console.info('res-err:', err);
          setErrorPopUp(true);
          setErrors(err?.response?.data?.errors);
        });
    },
    [
      leadMagnet,
      changeHeader,
      saveHandler,
      onSaveLeadMagnet,
      redirectTo,
      history,
      isEditing,
      prevLeadMagnetType,
      setErrors,
    ],
  );

  const giveImg = template => {
    if (template === LeadMagnetTemplateType.LMTemplateOne) {
      return TemplateOne;
    } else {
      return TemplateOne;
    }
  };

  const columnView = useMediaQuery(theme.breakpoints.down(1373));
  const [previewTemplate, setPreviewTemplate] = useState(leadMagnet?.activeTemplate || defaultTemplate);
  useEffect(() => {
    if (!!leadMagnet?.activeTemplate) {
      setPreviewTemplate(leadMagnet?.activeTemplate);
    }
  }, [leadMagnet?.activeTemplate]);
  let array = [];
  const handleCategoryChange = newSelected => {
    if (leadMagnetFormRef.current.values.categories.length > 0) {
      array = leadMagnetFormRef.current.values.categories;
    }
    if (newSelected.includes('Create Custom Category')) {
      toggleCategoriesPopup();
    } else {
      if (array.includes(newSelected)) {
        // Remove the selected value if it's already present
        const index = array.indexOf(newSelected);
        if (index !== -1) {
          array.splice(index, 1);
        }
      } else {
        // Add the selected value if it's not present
        array.push(newSelected);
      }
      setTimeout(() => {
        leadMagnetFormRef.current.setFieldValue('categories', array);
      }, 300);
    }
  };
  let arraytoDeleteFrom = [];
  const deleteCategory = itemValue => {
    arraytoDeleteFrom = leadMagnetFormRef.current.values.categories;

    if (arraytoDeleteFrom.includes(itemValue[0])) {
      const index = arraytoDeleteFrom.indexOf(itemValue[0]);
      if (index !== -1) {
        arraytoDeleteFrom.splice(index, 1);
      }
    }
    request('/ReferenceData/RemoveCustomCategories', 'PUT', itemValue).then(res => {
      leadMagnetFormRef.current.setFieldValue('categories', arraytoDeleteFrom);
      refetch();
    });
  };
  const handleKeyPress = event => {
    if (event.key === 'Escape') {
      // Handle the "Esc" key press event here
      setCategoriesOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  const browseBtnStyle = {
    backgroundColor: '#ffffff',
    padding: '8px 20px',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '26.25px',
    letterSpacing: '0.43px',
    color: '#215C73',
    minWidth: 'auto',
    borderRadius: '4px',
    border: '1px solid #215C73',
  };
  const imagBtnStyle = {
    backgroundColor: '#ffffff',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '16px',
    letterSpacing: '1.25px',
    color: '#215C73',
    minWidth: 'auto',
    borderRadius: '4px',
    border: '1px solid #215C73',
  };
  useLayoutEffect(() => {
    Promise.allSettled([getLeadMagnetsCounts(), getArchivedLeadMagnetsCount()]).then(
      ([leadMagnetCount, archivedCount]) => {
        if (leadMagnetCount.value <= 0 && archivedCount.value <= 0) {
          setIsFirstTime(true);
        }
      },
    );
  }, []);

  return (
    <>
      <Formik
        innerRef={leadMagnetFormRef}
        enableReinitialize
        initialValues={{
          title: leadMagnet?.title || '',
          contributionTagline: leadMagnet?.contributionTagline || '',
          // isLive: isNil(leadMagnet?.isLive) ? 'live' : leadMagnet.isLive === false ? 'self-paced' : 'live',
          type: leadMagnet?.type || LeadMagnetType.LeadMagnetPDF,
          file: (leadMagnet?.previewContentUrls && leadMagnet?.previewContentUrls[0]) || null,
          activeTemplate: leadMagnet?.activeTemplate || defaultTemplate,
          // isWorkshop: leadMagnet?.isWorkshop || false,
          // isThankyouPageAdded: leadMagnet?.isThankyouPageAdded || false,
          // thankyouPage: leadMagnet?.thankyouPage || null,
          whoIAmLabel:
            leadMagnet?.whoIAmLabel == 'Bio' ? 'Who this is for' : leadMagnet?.whoIAmLabel || 'Who this is for',
          whatWeDoLabel: leadMagnet?.whatWeDoLabel || 'What to expect',
          purposeLabel:
            leadMagnet?.purposeLabel == 'The Purpose'
              ? 'Why this was created'
              : leadMagnet?.purposeLabel || 'Why this was created',
          preparationLabel:
            leadMagnet?.preparationLabel == 'How to Prepare'
              ? 'Additional details'
              : leadMagnet?.preparationLabel || 'Additional details',
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().max(50, 'Title can be a max of 50 characters').required('This is a required field'),
          contributionTagline: Yup.string().max(200, 'Tagline can be a max of 200 characters').optional(),
          type: Yup.mixed().oneOf(Object.values(LeadMagnetType)).required('This is a required field'),
          // isLive: Yup.mixed().oneOf(['live', 'self-paced']),
        })}
        onSubmit={submitHandler}
      >
        {({ values, setFieldValue, handleSubmit, errors }) => {
          return (
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  console.info('redirect-to:', to);
                  setRedirectTo(to);
                  if (isEditing) {
                    // if (values.isThankyouPageAdded === false) {
                    handleSubmit(values);
                    // }
                  } else {
                    handleSubmit(values);
                  }
                },
                errors,
              }}
            >
              <Joyride
                // tooltipComponent={Tooltip}
                callback={handleJoyrideCallback}
                continuous={true}
                getHelpers={getHelpers}
                run={run}
                stepIndex={stepIndex}
                showProgress={false}
                showSkipButton={false}
                steps={steps}
                styles={{
                  options: {
                    primaryColor: '#CDBA8F',
                    zIndex: 10000000,
                  },
                }}
              />
              <StyledMainSection mobileView={mobileView}>
                {errorOccured && (
                  <div
                    style={{
                      width: '45%',
                      backgroundColor: 'white',
                      position: 'absolute',
                      marginInline: '9%',
                      border: '1px solid #DBE7F2',
                      borderRadius: '4px',
                      zIndex: 1,
                    }}
                  >
                    <div style={{ padding: '15px 10px 10px 15px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '700',
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#1E1E1E',
                              marginInline: '10px',
                            }}
                          >
                            Empty Fields
                          </div>
                        </div>
                        <StyledCloseIcon
                          onClick={() => {
                            dispatch(leadMagnetActions.setErrorPopUp(false));
                          }}
                          fontSize="10px"
                          style={{ color: 'black' }}
                        />
                      </div>
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontWeight: '400',
                          fontSize: '16px',
                          lineHeight: '22px',
                          color: '#1E1E1E',
                          marginInline: '35px',
                        }}
                      >
                        Please fill out all fields in order to continue
                      </div>

                      {/* <div style={{
                    fontFamily: 'Avenir',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#1E1E1E',
                    marginTop: '10px',
                    marginInline: "35px"
                  }}>Please fill out missing field in order to continue</div> */}
                    </div>
                  </div>
                )}

                <div>
                  <Card id="createLeadMagnet" mobileView={mobileView}>
                    <CardHeader mobileView={mobileView}>
                      <StyledHeader {...{ mobileView }}>
                        <PageTitleSecond mobileView={mobileView}>1. Basic Information</PageTitleSecond>
                        <Button
                          autoWidth
                          clientPreviewBtn
                          onClick={() => {
                            window.open(pdfFile, '_blank');
                          }}
                          style={{
                            margin: '0.67em 0.5em',
                          }}
                        >
                          Example
                        </Button>
                      </StyledHeader>
                    </CardHeader>
                    <CardBody mobileView={mobileView}>
                      <Form id="create-lead-magnet-form">
                        <Grid container alignItems="flex-start" spacing={6}>
                          <Grid item container md={12} xs={12} style={{ paddingBottom: '0px' }}>
                            <Grid item container md={6} xs={12} style={{ paddingRight: mobileView ? '' : '24px' }}>
                              <Grid item xs={12} className={classes.row} style={{ marginBottom: '0px' }}>
                                <StyledTitle mobileView={mobileView} style={mobileView ? { fontWeight: 800 } : {}}>
                                  Title
                                </StyledTitle>
                              </Grid>
                              <Grid style={{ marginTop: '15px' }} item xs={12}>
                                <Input
                                  id="lead-magnet-title-input"
                                  label="Lead Magnet Title"
                                  name="title"
                                  counter={50}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  id="contribution-tagline-input"
                                  label="Tagline (Optional)"
                                  name="contributionTagline"
                                  counter={200}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <Grid item container md={6} xs={12}>
                              <Grid item xs={12} className={classes.categoriesRow}>
                                <StyledTitle mobileView={mobileView} style={mobileView ? { fontWeight: 800 } : {}}>
                                  <div>Lead Magnet Type</div>
                                  <Tooltip
                                    title="Cohere makes it easy to create lead magnets - use them to collect emails. This will help you nurture opportunities to drive more sales. On Cohere, you can create PDF, Video, Audio, and Quiz lead magnets."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                    style={{ margin: 0 }}
                                  >
                                    <InfoIcon htmlColor={colors.lightBrown} />
                                  </Tooltip>
                                </StyledTitle>
                              </Grid>

                              <Grid item container spacing={2} style={{ marginBottom: '15px' }}>
                                <Grid style={{ marginTop: '1rem' }} item sm={6} xs={12}>
                                  <StyledButtonClicked
                                    type="button"
                                    disabled={isEditing}
                                    active={values.type === LeadMagnetType.LeadMagnetPDF}
                                    onClick={leadMagnetClickHandler(LeadMagnetType.LeadMagnetPDF, setFieldValue)}
                                  >
                                    PDF
                                  </StyledButtonClicked>
                                </Grid>
                                <Grid style={mobileView ? {} : { marginTop: '1rem' }} item sm={6} xs={12}>
                                  <StyledButtonClicked
                                    type="button"
                                    disabled={isEditing}
                                    active={values.type === LeadMagnetType.LeadMagnetAudioVideo}
                                    onClick={leadMagnetClickHandler(LeadMagnetType.LeadMagnetAudioVideo, setFieldValue)}
                                    id="one-to-one-type-radio"
                                  >
                                    Video/Audio
                                  </StyledButtonClicked>
                                </Grid>

                                <Grid style={mobileView ? {} : { marginTop: '0rem' }} item sm={6} xs={12}>
                                  <Tooltip
                                    title="In step 3, you have the option to either create a new quiz or choose from quizzes you've previously created."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                    // style={{ marginLeft: '8px' }}
                                  >
                                    <StyledButtonClicked
                                      type="button"
                                      disabled={true || isEditing}
                                      active={values.type === LeadMagnetType.LeadMagnetQuiz}
                                      onClick={() => {
                                        // setFieldValue('type', LeadMagnetType.LeadMagnetQuiz);
                                      }}
                                      id="live-course-type-radio"
                                    >
                                      Quiz (Coming Soon)
                                    </StyledButtonClicked>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <StyledLine color="#e7e7e7" />
                          <Grid item container md={12} xs={12}>
                            <Grid item container md={6} xs={12} style={{ paddingRight: mobileView ? '' : '24px' }}>
                              <Grid container item xs={12} className={classes.row} style={{ marginBottom: '0px' }}>
                                <Grid item xs={12} className={classes.row}>
                                  <StyledTitle mobileView={mobileView} style={mobileView ? { fontWeight: 800 } : {}}>
                                    <div>Landing Page Design</div>
                                    {/* <Tooltip
                                      title="You can select a template for this lead magnet"
                                      arrow
                                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      style={{ margin: 0 }}
                                    >
                                      <InfoIcon htmlColor={colors.lightBrown} />
                                    </Tooltip> */}
                                  </StyledTitle>
                                </Grid>
                                <Grid item xs={12} className={classes.row}>
                                  {/* <div className={classes.subTitle}>Choose a landing page for your Lead Magnet</div> */}
                                  <div className={classes.subTitle}>
                                    Cohere provides a ready-to-go design that can be customized with your logo and
                                    branding colors. Additional templates coming soon.
                                  </div>
                                </Grid>
                                <Grid item xs={12} className={classes.row}>
                                  {/* <div className={classes.btnContainer}>
                                  <MUIButton
                                    variant="contained"
                                    disableElevation="true"
                                    style={browseBtnStyle}
                                    onClick={() => {
                                      setIsStyleModalOpen(true);
                                    }}
                                  >
                                    <BrowseIcon
                                      mobileView={mobileView}
                                      active={previewTemplate == LeadMagnetTemplateType.LMTemplateOne}
                                      src={BrowseIconImage}
                                    />
                                    Browse
                                  </MUIButton>
                                </div> */}
                                  <StyledChooseTemplateButtonContainer>
                                    {/* <StyledDisabledButton
                                      variant="outlined"
                                      disableElevation="true"
                                      disabled
                                      // style={browseBtnStyle}
                                      // onClick={() => {
                                      //   setIsStyleModalOpen(true);
                                      // }}
                                    >
                                      <StyledText {...{ mobileView }}>Select Template</StyledText>
                                    </StyledDisabledButton> */}
                                    <MUIButton
                                      variant="contained"
                                      disableElevation="true"
                                      style={imagBtnStyle}
                                      onClick={() => {
                                        window.open(pdfFile, '_blank');
                                      }}
                                    >
                                      Design Preview
                                    </MUIButton>
                                    {/* <StyledComingSoonLabel>
                                      <div className="text">Coming Soon</div>
                                    </StyledComingSoonLabel> */}
                                  </StyledChooseTemplateButtonContainer>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} className={classes.row} style={{ marginBottom: 0 }}>
                                <Grid item md={12} sm={12} xs={12}>
                                  <div style={{ width: '100%', height: '289px' }}>
                                    <StyledImg previewImg={giveImg(values.activeTemplate)}></StyledImg>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item container md={6} xs={12}>
                              <Grid item xs={12} className={classes.row}>
                                <StyledTitle mobileView={mobileView} style={mobileView ? { fontWeight: 800 } : {}}>
                                  <div>Landing Page Image</div>
                                  <Tooltip
                                    title="We recommended adding either a picture of yourself, creating an image with the title of your contribution using a free platform such as canva.com or simply adding your logo! We recommend Lead Magnet image dimensions of 1230 x 468 px. When exporting from websites like Canva, export at 1.5x or higher from the default resolution."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                    style={{ margin: 0 }}
                                  >
                                    <InfoIcon htmlColor={colors.lightBrown} />
                                  </Tooltip>
                                </StyledTitle>
                              </Grid>
                              <Grid item xs={12} className={classes.row}>
                                <div className={classes.subTitle}>
                                  Your clients will see this image on the lead magnet landing page
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.row}
                                style={{
                                  justifyContent: mobileView ? 'flex-start' : 'flex-end',
                                  ...(mobileView ? {} : { marginBottom: '39px' }),
                                }}
                              >
                                <MUIButton
                                  variant="contained"
                                  disableElevation="true"
                                  style={imagBtnStyle}
                                  onClick={() => {
                                    if (fileInput) {
                                      fileInput.current.click();
                                    }
                                  }}
                                >
                                  Attach an Image
                                </MUIButton>
                                {/* <Button
                                invert
                                attachImage={true}
                                mobileView={mobileView}
                                backgroundColor="white"
                                style={{
                                  border: '1px solid #215C73',
                                  color: '#215C73',
                                }}
                                type="button"
                                onClick={() => {
                                  if (fileInput) {
                                    fileInput.current.click();
                                  }
                                }}
                              >
                                Attach an Image
                              </Button> */}
                                <HiddenFileInput
                                  onChange={uploadFileHandler(setFieldValue, setImageLoading)}
                                  ref={fileInput}
                                  accept="image/png,image/gif,image/jpeg"
                                />
                              </Grid>
                              {!imageLoading && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    dispaly: 'flex',
                                    // alignItems: 'flex-end',
                                    height: '289px',
                                    border: '1px solid rgb(218, 218, 218)',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // marginTop: '1rem',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {values.file ? (
                                    <Grid container item xs={12} className={classes.row} style={{ marginBottom: 0 }}>
                                      <Grid item md={12} sm={12} xs={12}>
                                        <div
                                          style={{
                                            width: '100%',
                                            // height: 'auto', maxHeight: '289px'
                                            height: '100%',
                                          }}
                                        >
                                          <Image crossorigin="anonymous" ref={imgRef} src={values.file} />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <ImageDropzone
                                      action={droppedFiles => {
                                        uploadFileHandlerwithoutevent(setFieldValue, setImageLoading, droppedFiles);
                                      }}
                                      isDisabled={imageLoading}
                                      style={{
                                        borderRadius: '10px',
                                        height: '289px',
                                        margin: 0,
                                        // ...(!mobileView && { marginTop: '20px' }),
                                      }}
                                      placeholder="Drag and Drop Your Image Here"
                                      type="image/jpeg, image/png"
                                      stopPropagation
                                    />
                                  )}
                                </Grid>
                              )}
                              {imageLoading && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    border: '1px solid rgb(218, 218, 218)',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <FlexImagePlaceholder>
                                    <Loader relative withoutTopOffset flex={1} />
                                  </FlexImagePlaceholder>
                                </Grid>
                              )}
                              <EasyCropper
                                title="Crop Your Lead Magnet Image"
                                submitTitle="Set Image"
                                imageToCrop={imageToCrop}
                                isOpen={isOpen}
                                onClose={() => {
                                  setIsOpen(false);
                                  setImageToCrop('');
                                }}
                                setFieldValue={setFieldValue}
                                field="file"
                                shape="rectangle"
                              />
                              <Modal
                                title="Select a Theme"
                                isOpen={isStyleModalOpen}
                                submitTitle="Select"
                                brandingColor={false}
                                hiddenCancel
                                isCreatingContribution
                                onSubmit={() => {
                                  setFieldValue('activeTemplate', previewTemplate);
                                  setIsStyleModalOpen(false);
                                }}
                                onCancel={() => {
                                  setIsStyleModalOpen(false);
                                }}
                                widthRequired
                              >
                                <div
                                  // onClick={() => openImageViewer(giveImg(previewTemplate))}
                                  className="container"
                                >
                                  <StyledImg previewImg={giveImg(previewTemplate)} className="image"></StyledImg>
                                  <div className="overlay">
                                    <button
                                      onClick={() => openImageViewer(giveImg(previewTemplate))}
                                      className="button"
                                    >
                                      Click to view
                                    </button>
                                  </div>

                                  {isViewerOpen && (
                                    <ImageViewer
                                      src={[currentImage]}
                                      disableScroll={false}
                                      closeOnClickOutside={true}
                                      onClose={() => {
                                        setIsViewerOpen(false);
                                      }}
                                      closeComponent={() => {
                                        return <></>;
                                      }}
                                    />
                                  )}
                                </div>
                                <StyledIcons>
                                  <TemplateIcon
                                    mobileView={mobileView}
                                    active={previewTemplate === LeadMagnetTemplateType.LMTemplateOne}
                                    src={TemplateOneIcon}
                                    onClick={() => {
                                      setPreviewTemplate(LeadMagnetTemplateType.LMTemplateOne);
                                    }}
                                  />
                                  {/* <TemplateIcon
                                  mobileView={mobileView}
                                  active={previewTemplate === LeadMagnetTemplateType.LMTemplateTwo}
                                  src={TemplateTwoIcon}
                                  onClick={() => {
                                    setPreviewTemplate(LeadMagnetTemplateType.LMTemplateTwo);
                                  }}
                                />
                                <TemplateIcon
                                  mobileView={mobileView}
                                  active={previewTemplate === LeadMagnetTemplateType.LMTemplateThree}
                                  src={TemplateThreeIcon}
                                  onClick={() => {
                                    setPreviewTemplate(LeadMagnetTemplateType.LMTemplateThree);
                                  }}
                                /> */}
                                </StyledIcons>
                              </Modal>
                            </Grid>

                            {/* <ImageCropper
                          title="Crop Your Contribution Image"
                          submitTitle="Set Image"
                          imageToCrop={imageToCrop}
                          isOpen={isOpen}
                          onClose={() => {
                            setIsOpen(false);
                            setImageToCrop('');
                          }}
                          setFieldValue={setFieldValue}
                          field="file"
                          shape="rectangle"
                        /> */}
                          </Grid>
                        </Grid>
                      </Form>

                      {errorsState?.map(({ errorMessage }) => (
                        <CommonErrorMessage key={errorMessage} message={errorMessage} />
                      ))}
                    </CardBody>
                  </Card>
                </div>
                <Buttons
                  nextButtonTitle="Save and Next"
                  formId="create-lead-magnet-form"
                  onNextClickHandler={event => {
                    setRedirectTo(query.id ? `/edit-lead-magnet/${query.id}/details` : '/create-lead-magnet/details');
                    if (isEmpty(errors) === false) {
                      dispatch(leadMagnetActions.setErrorPopUp(true));
                    } else {
                      handleSubmit(event);
                    }
                  }}
                />
              </StyledMainSection>
              <Modal
                title=""
                isOpen={showModal}
                onCloseText="OK"
                hiddenCancel
                onSubmit={togglePopup}
                onCancel={togglePopup}
                disableFooter
              >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <StyledImgCongrats previewImg={TemplateOneCongrats}></StyledImgCongrats>
                  <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
                  <div
                    style={{
                      color: '#A58B62',
                      fontSize: '16px',
                      marginTop: '13px',
                      fontWeight: 450,
                      fontFamily: 'Avenir',
                    }}
                  >
                    You are fully set up & ready to transform lives!
                  </div>
                  <Button
                    autoWidth
                    backgroundColor={'#C9B382'}
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      marginBottom: '10px',
                    }}
                    onClick={togglePopup}
                  >
                    Let’s do this!
                  </Button>
                </div>
              </Modal>
            </MainContainer>
          );
        }}
      </Formik>
      {isFirstTime && (
        <Modal
          title=""
          isOpen={isFirstTime}
          onCloseText="OK"
          hiddenCancel
          onSubmit={() => {
            setIsFirstTime(false);
          }}
          onCancel={() => {
            setIsFirstTime(false);
          }}
          disableFooter
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '0rem 0rem 2rem 0rem',
              gap: '1.5rem',
            }}
          >
            <StyledImgCongrats previewImg={TemplateOneCongrats}></StyledImgCongrats>
            <div
              style={{
                color: 'var(--Cohere-Primary-Blue, #215C73)',
                textAlign: 'center',
                fontFamily: 'Avenir',
                fontSize: '1.375rem',
                fontStyle: 'normal',
                fontWeight: '900',
                lineHeight: '1.225rem',
              }}
            >
              Welcome to Create a Lead Magnet!
            </div>
            <div
              style={{
                color: 'rgba(0, 0, 0, 0.87)',
                textAlign: 'center',
                fontFamily: 'Avenir',
                fontSize: '1rem',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '1.375rem',
              }}
            >
              Lead magnets are one of the most effective ways to grow your email list. On Cohere, you can create a lead
              magnet that offers your potential clients FREE videos, audio, PDFs, E-books, and Quizzes! In just 4 steps,
              you’ll have a lead magnet opt-in page link ready to share!
            </div>
            <Button
              autoWidth
              backgroundColor={'#C9B382'}
              // style={{
              //   marginTop: '40px',
              //   paddingTop: '10px',
              //   paddingBottom: '10px',
              //   marginBottom: '10px',
              // }}
              onClick={() => {
                setIsFirstTime(false);
              }}
            >
              Get Started!
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

BasicInformation.propTypes = {};

BasicInformation.defaultProps = {};

export default BasicInformation;
