import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AnalyticsIcon from '@mui/icons-material/Analytics';

export const StyledInviteOneToOneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 160px; */
  width: fit-content;
  float: right;
  padding: 6px 8px;
  margin-top: 5px;
  font-family: Avenir;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  color: ${({ themedColor }) => themedColor};

  &:hover {
    background-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '' : '#f0f0f0')};
  }
`;
export const StyledShareButton = styled(Button)`
  width: ${({ width }) => width || '180px'};
  float: right;
  margin-top: 5px !important;
  text-transform: none !important;
  cursor: pointer;
  color: ${props => props.themedColor} !important;
  ${({ disabled, isDarkModeEnabled }) =>
    disabled && isDarkModeEnabled
      ? `
    color: white !improtant;
    opacity: 50%;
    `
      : disabled &&
        ` color: rgba(0, 0, 0, 0.26) !important;
    `}
`;

export const StyledShareIcon = styled(ShareIcon)`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;

export const StyledPreviewIcon = styled(VisibilityIcon)`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;

export const StyledAnalyticsIcon = styled(AnalyticsIcon)`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;
