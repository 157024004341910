import { makeStyles } from '@material-ui/core';

const stylesSessionTime = theme => ({
  datePickerWrapper: {
    maxWidth: '100%',
    flexBasis: '100%',
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12,
    },
  },
  deleteButtonWrapper: {
    [theme.breakpoints.down('md')]: {
      order: 3,
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      justifyContent: 'flex-end',
      marginBottom: 12,
      maxWidth: '100%',
    },
  },
  gridhandle: {
    [theme.breakpoints.down('1200')]: {
      display: 'block',
    },
  },
  durationWrapper: {
    marginRight: 10,
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 4,
  },
  infoValue: {
    marginBottom: 12,
  },
  infoWrapper: {
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
  statusWrapper: {
    marginRight: 10,
  },
  videoWrapper: {
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      flexGrow: 1,
      maxWidth: '100%',
      order: 1,
    },
  },
});

export const useStyles = makeStyles(stylesSessionTime);
