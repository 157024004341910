import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import Button from 'components/FormUI/Button';
import { toRem, devices } from 'utils/styles';

import * as cohealerIncomeActions from 'actions/cohealerIncome';
import { formatMoney } from 'utils/datesAndMoney';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { downloadFile } from 'utils/utils';

import './SalesSummaryCard.scss';
import '../../Payment.scss';
import { colors } from 'utils/styles';
import { useHttp } from 'hooks';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TOOLTIP } from '../../../../../constants.js';

const renderName = (title, tooltipText) => (
  <div>
    <span>{title} </span>
    <Tooltip title={tooltipText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY} leaveTouchDelay={TOOLTIP.LEAVE_DELAY}>
      <InfoIcon htmlColor={colors.lightBrown} />
    </Tooltip>
  </div>
);

const tableList = (incomeList, mobileView) => {
  const list = [
    {
      name: renderName(
        'Gross Sales',
        'This number is the total amount of sales you have made on Cohere. If you are a partner coach, sales related information appears on the primary coach account.',
      ),
      value: incomeList?.map((item, index) => {
        const cur = mobileView ? ` (${item.currency})` : '';
        return (
          <Grid
            container
            item
            md={2}
            xs={index !== 0 ? 4 : 8}
            style={index === 0 && !mobileView ? { marginLeft: '-25px' } : {}}
          >
            {item?.symbol + formatMoney(item?.grossIncomeAmount) + cur}
          </Grid>
        );
      }),
    },
    {
      name: renderName(
        ' Earned Revenue',
        'This is your gross sales minus Cohere fees. If you are a partner coach, sales related information appears on the primary coach account.',
      ),
      value: incomeList?.map((item, index) => {
        const cur = mobileView ? ` (${item.currency})` : '';
        return (
          <Grid
            container
            item
            md={2}
            xs={index !== 0 ? 4 : 8}
            style={index === 0 && !mobileView ? { marginLeft: '-25px' } : {}}
          >
            {item?.symbol + formatMoney(item?.netIncomeAmount) + cur}
          </Grid>
        );
      }),

      // incomeList?.map((item, index) => (
      //   <Grid container item md={2} xs={index !== 0 ? 4 : 8} style={index === 0 && !mobileView ? { marginLeft: '-25px' } : {}}>
      //     {item?.symbol + formatMoney(item?.netIncomeAmount)}
      //   </Grid>
      // )),
    },
    // {
    //   name: renderName(
    //     'Advanced Earned Revenue',
    //     'This is your gross sales minus Cohere fees. If you are a partner coach, sales related information appears on the primary coach account.',
    //   ),
    //   value: incomeList?.map((item, index) => {
    //     const cur = mobileView ? ` (${item.currency})` : '';
    //     return (
    //       <Grid
    //         container
    //         item
    //         md={2}
    //         xs={index !== 0 ? 4 : 8}
    //         style={index === 0 && !mobileView ? { marginLeft: '-25px' } : {}}
    //       >
    //         {item?.symbol + formatMoney(item?.netIncomeAmountWithTaxIncluded) + cur}
    //       </Grid>
    //     );
    //   }),
    // },
    {
      name: renderName(
        'In Escrow',
        'This is revenue earned but still in escrow. For invite only contributions, funds are made available as soon as the initial payment processing is completed. For Contributions open to the public, funds are usually available for bank transfers four business days after a completed 1:1 session or after the first session of a group course.',
      ),
      value: incomeList?.map((item, index) => {
        const cur = mobileView ? ` (${item.currency})` : '';
        return (
          <Grid
            container
            item
            md={2}
            xs={index !== 0 ? 4 : 8}
            style={index === 0 && !mobileView ? { marginLeft: '-25px' } : {}}
          >
            {item?.symbol + formatMoney(item?.escrowIncomeAmount) + cur}
          </Grid>
        );
      }),

      // incomeList?.map((item, index) => (
      //   <Grid container item md={2} xs={index !== 0 ? 4 : 8} style={index === 0 && !mobileView ? { marginLeft: '-25px' } : {}}>
      //     {item?.symbol + formatMoney(item?.escrowIncomeAmount)}
      //   </Grid>
      // )),
    },
    {
      name: renderName(
        'Outstanding',
        'If your Clients are using split payments to pay you, this is the amount of revenue contractually due to you but has not been charged yet due to split payments.',
      ),
      value: incomeList?.map((item, index) => {
        const cur = mobileView ? ` (${item.currency})` : '';
        return (
          <Grid
            container
            item
            md={2}
            xs={index !== 0 ? 4 : 8}
            style={index === 0 && !mobileView ? { marginLeft: '-25px' } : {}}
          >
            {item?.symbol + formatMoney(item?.escrowIncomeAmount) + cur}
          </Grid>
        );
      }),

      // incomeList?.map((item, index) => (
      //   <Grid container item md={2} xs={index !== 0 ? 4 : 8} style={index === 0 && !mobileView ? { marginLeft: '-25px' } : {}}>
      //     {item?.symbol + formatMoney(item?.pendingIncomeAmount)}
      //   </Grid>
      // )),
    },
  ];

  return list;
};

const SalesSummaryCard = ({ incomes, loading, getIncomes }) => {
  const { request } = useHttp();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    getIncomes();
  }, [getIncomes]);

  const onClick = () => {
    request('/api/cohealer-income/sales-history', 'GET', null, {}, 'blob')
      .then(res => downloadFile(res, 'Cohere financial activity.csv'))
      .catch(console.dir);
  };
  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      <Card maxHeight>
        <CardHeader>
          <Grid container item md={12} xs={12} classes={{ root: 'rows' }}>
            <Grid container item md={6} xs={12}>
              <PageTitleSecond>Sales Summary</PageTitleSecond>
            </Grid>
            <Grid
              container
              item
              md={6}
              xs={12}
              justify={mobileView ? '' : 'flex-end'}
              alignContent={mobileView ? '' : 'center'}
            >
              <Button onClick={onClick} autoWidth variant="primary">
                Download Sales History
              </Button>
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody className="card-body-container">
          {!isEmpty(incomes) && !loading && (
            <Grid container alignItems="center">
              {!mobileView && (
                <Grid container item md={12} xs={12} classes={{ root: 'rows' }}>
                  <Grid container item md={5} xs={4}>
                    {'     '}
                  </Grid>
                  {incomes?.purchaseIncomeList && Array.isArray(incomes.purchaseIncomeList)
                    ? incomes.purchaseIncomeList.map(curr => (
                        <Grid container item md={2} xs={4}>
                          {curr.currency}
                        </Grid>
                      ))
                    : null}
                </Grid>
              )}
              {tableList(incomes?.purchaseIncomeList, mobileView).map(item => (
                <Grid container item md={12} xs={12} classes={{ root: 'rows' }}>
                  <Grid container item md={5} xs={12}>
                    {item.name}
                  </Grid>
                  {item.value}
                </Grid>
              ))}
            </Grid>
          )}
        </CardBody>
      </Card>
    </Grid>
  );
};

SalesSummaryCard.propTypes = {
  incomes: PropTypes.shape({
    grossIncomeAmount: PropTypes.number,
    netIncomeAmount: PropTypes.number,
    escrowIncomeAmount: PropTypes.number,
    pendingIncomeAmount: PropTypes.number,
  }),
  loading: PropTypes.bool,
  getIncomes: PropTypes.func.isRequired,
};

SalesSummaryCard.defaultProps = {
  incomes: [],
  loading: false,
};

const mapStateToProps = ({ cohealerIncome }) => ({
  incomes: cohealerIncome?.incomes,
  loading: cohealerIncome?.loading,
});

const actions = {
  getIncomes: cohealerIncomeActions.fetchCohealerIncome,
};

export default connect(mapStateToProps, actions)(SalesSummaryCard);
