import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as R from 'ramda';
import { NavLink } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ProgressBar from '@ramonak/react-progress-bar';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TemplateType } from 'helpers/constants/templateType';
import Modal from 'components/UI/Modal';
import { BodyText } from 'components/UI/Text/TextStyles';
import SessionTime from './SessionTime';
import { BottomPanel } from './BottomPanel/BottomPanel';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import './Session.scss';
import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { useSelector, useDispatch } from 'react-redux';
import useAccount from 'hooks/useAccount';
import styled from 'styled-components';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { toRem, colors } from 'utils/styles';
import { TIMEZONES, TOOLTIP } from 'constants.js';
import { deleteOneToOneSession, determineColorToUse } from 'services/contributions.service';
import { UserRoles } from 'helpers/constants';
import { lightOrDark } from 'utils/utils';
import { fetchTimeZones } from 'actions/timeZone';
import { useShallowEqualSelector } from 'hooks';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getThemedColors } from 'services/contributions.service';
import { fetchCohealerContribution, setRescheduleModal } from 'actions/contributions';
import { useRouter } from 'hooks';
import TextArea from 'components/FormUI/TextArea';

const Row = styled.div`
  display: flex;
`;

const StyledSessionRemaining = styled.div`
  color: ${({ clr }) => clr};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AvatarComponentForSession = styled(Avatar)`
  width: 55px;
  height: 55px;
  border-radius: ${({ mobileView }) => mobileView && '10px'};
`;

const StyledTimeZoneLabel = styled.a`
  display: inline-block;
  font-size: ${({ mobileView }) => (mobileView ? '10px' : toRem(13))};
  background-color: ${({ color }) => color};
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  margin-right: ${({ mobileView }) => mobileView && '4px'};
  cursor: pointer;
  color: white;
  font-weight: bold;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  font-family: Avenir;
  border: 1px solid #dfe3e4;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0.15px;
  resize: none;

  &:focus-visible {
    outline: none;
  }
`;

const useStyles = makeStyles(theme => ({
  round: {
    position: 'inherit',
  },
  iconButton: {
    '& .MuiIconButton-root': {
      padding: '0px',
      position: 'absolute',
      right: '22px',
      top: '14px',
    },
  },
}));

const getInitialSymbol = R.compose(R.toUpper, R.head);
const getTimeZoneLabel = ({ contributionTimeZoneId, timeZones = [] }) => {
  const timeZone = timeZones.find(item => item?.countryName === contributionTimeZoneId);

  if (timeZone) {
    return timeZone.name;
  }

  return TIMEZONES[contributionTimeZoneId]; // fallback value
};
const Session = ({
  number,
  session,
  timeDetails,
  session: { id, isCompleted, startTime, endTime, attachments, participantId },
  packagePurchases,
  contributionId,
  renderActions,
  executeCalendarScroll,
  setRescheduleMode,
  setCurrentRescheduleSession,
  serviceProviderName,
  contributionTimeZoneId,
}) => {
  const participantPackage = packagePurchases?.find(pkg => pkg?.userId === participantId);
  const { sessionNumbers, freeSessionNumbers, bookedSessionNumbers } = participantPackage || {};
  const percentageCompleted = (bookedSessionNumbers / sessionNumbers) * 100;
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const participantInfo = useShallowEqualSelector(
    state =>
      (state?.contributions?.activeContribution?.participants || []).find(client => client?.id === participantId) || {},
  );
  const { protocol, domain, history } = useRouter();
  const userProfileUrl = new URL('/account/profile', `${protocol}//${domain}`).toString();
  const { error, timeZones, loading } = useSelector(state => state.timeZone);
  const isTodaySession = moment(session.startTime).startOf('day').diff(moment().startOf('day'), 'days') === 0;
  const isPastSession = moment(session.startTime).startOf('day').diff(moment().startOf('day'), 'days') < 0;
  console.log('isPastSession<>', isPastSession);
  const contribution = useContribution();
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  let colorToUse = determineColorToUse(contribution);
  const dispatch = useDispatch();
  const { user, currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const isClient = currentRole === UserRoles.client;
  const shouldChangeColor = !isCoach && activeTemplate != TemplateType.TemplateThree;
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const theme = useTheme();

  const { themedColor, themedCardBackgroundColor, themedBackgroundColor } = getThemedColors(contribution);
  const [expanded, setExpanded] = useState(isTodaySession);

  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeletedSuccessfullyModalOpen, setIsDeletedSuccessfullyModalOpen] = useState(false);
  const [deleteSession, setDeleteSession] = useState(false);
  const [reasonForDeletion, setReasonForDeletion] = useState('');
  const classes = useStyles();
  const handleReschedule = e => {
    e.stopPropagation();
    setRescheduleModalState(true);
  };

  const handleDelete = e => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };
  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if (!timeZones?.length && !loading && !error) {
      dispatch(fetchTimeZones());
    }
  }, [dispatch, error, loading, timeZones]);

  const handleSubmit = async () => {
    dispatch(setRescheduleModal(true));
    setRescheduleMode(true);
    if (!shouldChangeColor) {
      executeCalendarScroll();
    }
    setRescheduleModalState(false);
    setCurrentRescheduleSession(session);
  };

  const handleDeleteSession = async () => {
    const data = {
      contributionId: contributionId,
      availabilityTimeId: session.timeId,
      isDeleted: true,
      reason: reasonForDeletion,
      clientId: session.participantId,
      oldAvailabilityTimeId: session.timeId,
      startTime: session.startTime,
      endTime: session.endTime,
    };
    await deleteOneToOneSession(data).then(() => {
      // dispatch(fetchCohealerContribution(contributionId));
      setIsDeleteModalOpen(false);
      setIsDeletedSuccessfullyModalOpen(true);
    });
    // if (deleteSession) {
    //   console.log('delete session');
    //   setIsDeleteModalOpen(false);
    // }
  };

  const clientDisable = !moment(startTime).isAfter(moment().add(24, 'h')) || isCompleted;
  const isRescheduleDisabled = serviceProviderName ? clientDisable : isCompleted;

  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const height = mobileView ? '130px' : '77px';
  const timeZoneLabel = getTimeZoneLabel({ contributionTimeZoneId, timeZones });
  const Styledspan = styled.span`
    background-color: ${props => (props.color ? props.color : '')};
  `;

  const shouldBeDisabled = session => {
    let isDisabled = false;
    const isPastSession = moment(session.startTime).startOf('day').diff(moment().startOf('day'), 'days') < 0;
    // if (isPastSession) {
    if (session?.isCompleted) {
      isDisabled = false;
    } else {
      isDisabled = true;
    }
    // } else {
    //   if (session?.isFree) {
    //     isDisabled = false;
    //   } else {
    //     isDisabled = true;
    //   }
    // }

    return isDisabled;
  };

  const isDeleteDisabled = shouldBeDisabled(session);
  console.log('Session--------->', session);
  return (
    <>
      <ExpansionPanel
        className="one-to-one-session"
        classes={activeTemplate === TemplateType.TemplateThree || isCoach ? {} : { root: classes.round }}
        expanded={expanded}
        onChange={(_, newExpanded) => setExpanded(newExpanded)}
        TransitionProps={{ unmountOnExit: true }}
        style={
          activeTemplate === TemplateType.TemplateThree || isCoach
            ? {}
            : {
                borderRadius: '15px',
                border: `2px solid ${themedCardBackgroundColor}`,
                marginBottom: '10px',
                boxShadow: 'none',
              }
        }
      >
        <ExpansionPanelSummary
          style={{
            height: activeTemplate === TemplateType.TemplateThree || isCoach ? '100px' : height,
            backgroundColor: themedCardBackgroundColor,
            color: themedColor,
          }}
          expandIcon={<ExpandMoreIcon style={{ color: themedColor }} />}
          className={mobileView && classes.iconButton}
        >
          {mobileView && activeTemplate != TemplateType.TemplateThree && !isCoach ? (
            <>
              <Row>
                <Column>
                  {number && (
                    <Styledspan
                      color={themedBackgroundColor}
                      className="one-to-one-session-summary__number-badge"
                      style={
                        activeTemplate === TemplateType.TemplateThree || isCoach
                          ? {}
                          : {
                              fontSize: mobileView ? '13px' : '15px',
                              borderRadius: '20px',
                              padding: '4px 10px',
                              backgroundColor: btnColor,
                            }
                      }
                    >
                      {number}
                    </Styledspan>
                  )}
                </Column>
                <Column>
                  <Row>
                    {activeTemplate != TemplateType.TemplateThree && !isCoach && startTime && (
                      <>
                        {/* <StyledTimeZoneLabel>{moment().tz(TIMEZONES[user.timeZoneId]).zoneAbbr()}</StyledTimeZoneLabel> */}
                        <StyledTimeZoneLabel
                          style={{ backgroundColor: btnColor, color: textColor }}
                          mobileView={mobileView}
                        >
                          {moment(startTime).format('MMM Do h:mma')}
                        </StyledTimeZoneLabel>
                      </>
                    )}
                    {activeTemplate != TemplateType.TemplateThree && !isCoach && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                          }}
                        >
                          {/* <div style={{ fontSize: '20px', fontWeight: '800', marginTop: '5px' }}>Session</div> */}
                          <div style={{ fontSize: mobileView ? '12px' : '16px', color: 'gray' }}>
                            {isCompleted ? 'Completed'.toUpperCase() : 'Upcoming'.toUpperCase()}
                          </div>
                        </div>
                      </>
                    )}
                  </Row>
                  <Row>
                    <div
                      style={{
                        fontSize: mobileView ? '16px' : '20px',
                        fontWeight: '800',
                        marginTop: '5px',
                        marginLeft: '10px',
                        color: themedColor,
                      }}
                    >
                      {session.sessionName ? session?.sessionName : 'Session'}
                    </div>
                  </Row>
                  <Row>
                    {activeTemplate === TemplateType.TemplateThree || isCoach ? (
                      <>
                        {session?.id && (
                          <button
                            style={{ backgroundColor: btnColor }}
                            type="button"
                            onClick={handleReschedule}
                            disabled={isRescheduleDisabled}
                            className="course-session-summary__reschedule"
                          >
                            Reschedule
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {session?.id && (
                          <div className="one-to-one-session-time__actions" style={{ marginLeft: '10px' }}>
                            {renderActions(session)}
                          </div>
                        )}
                      </>
                    )}
                  </Row>
                </Column>
                <Column>
                  {activeTemplate != TemplateType.TemplateThree && serviceProviderName && !isCoach && (
                    <>
                      <StylesProvider injectFirst>
                        <AvatarComponentForSession
                          className="mx-2"
                          alt={`${user.firstName} ${user.lastName}`}
                          src={user.avatarUrl}
                          mobileView={mobileView}
                        >
                          {`${serviceProviderName && getInitialSymbol(serviceProviderName)}`}
                        </AvatarComponentForSession>
                      </StylesProvider>
                    </>
                  )}
                </Column>
              </Row>
            </>
          ) : (
            <>
              <div
                className="course-session-summary-container"
                style={
                  activeTemplate === TemplateType.TemplateThree || isCoach
                    ? {}
                    : { width: isIpad ? '80%' : '100%', justifyContent: 'space-between' }
                }
              >
                <div className="course-session-summary">
                  <h3
                    style={{ marginRight: '10px', color: themedColor }}
                    className="one-to-one-session-summary__header"
                  >
                    {(activeTemplate === TemplateType.TemplateThree || isCoach) && (
                      <> {session.sessionName ? session?.sessionName : 'Session'} &nbsp;</>
                    )}
                    {number && (
                      <Styledspan
                        color={themedBackgroundColor}
                        className="one-to-one-session-summary__number-badge"
                        style={
                          activeTemplate === TemplateType.TemplateThree || isCoach
                            ? { color: textColor, backgroundColor: btnColor }
                            : {
                                fontSize: '15px',
                                marginLeft: '15px',
                                borderRadius: '20px',
                                padding: '4px 10px',
                                color: textColor,
                                backgroundColor: btnColor,
                              }
                        }
                      >
                        {number}
                      </Styledspan>
                    )}
                  </h3>
                  {activeTemplate != TemplateType.TemplateThree && serviceProviderName && !isCoach && (
                    <>
                      <StylesProvider injectFirst>
                        <AvatarComponentForSession
                          className="mx-2"
                          alt={`${user.firstName} ${user.lastName}`}
                          src={user.avatarUrl}
                          mobileView={mobileView}
                        >
                          {`${serviceProviderName && getInitialSymbol(serviceProviderName)}`}
                        </AvatarComponentForSession>
                      </StylesProvider>
                    </>
                  )}
                  {id && isCoach && (
                    <p
                      style={{
                        marginLeft: mobileView ? '0px' : '15px',
                        marginTop: mobileView ? '32px' : '0px',
                        width: mobileView ? '125px' : '200px',
                        marginBottom: '0px',
                        fontSize: mobileView ? '15px' : '1rem',
                      }}
                    >
                      {`${participantInfo?.firstName === undefined ? '' : participantInfo?.firstName} ${
                        participantInfo?.lastName === undefined ? '' : participantInfo?.lastName
                      }`}
                    </p>
                  )}
                  {id && isCoach && !mobileView && !expanded && (
                    <>
                      <p style={{ marginLeft: '20px', width: '150px', marginBottom: '0px' }}>
                        {moment(startTime).format('MMMM Do YYYY')}
                      </p>
                      <p style={{ marginLeft: '15px', width: '150px', marginBottom: '0px' }}>
                        {moment(startTime).format('h:mm a')} - {moment(endTime).format('h:mm a')}
                      </p>
                    </>
                  )}
                  {activeTemplate != TemplateType.TemplateThree && !isCoach && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div style={{ fontSize: '20px', fontWeight: '800', marginTop: '5px', color: themedColor }}>
                          {session.sessionName ? session?.sessionName : 'Session'}
                        </div>
                        <div style={{ fontSize: '16px', color: 'gray' }}>{isCompleted ? 'Completed' : 'Upcoming'}</div>
                      </div>
                    </>
                  )}
                  {activeTemplate != TemplateType.TemplateThree && !isCoach && startTime && (
                    <>
                      {/* <StyledTimeZoneLabel>{moment().tz(TIMEZONES[user.timeZoneId]).zoneAbbr()}</StyledTimeZoneLabel> */}
                      <StyledTimeZoneLabel
                        color={themedBackgroundColor}
                        style={{ backgroundColor: btnColor, color: textColor }}
                      >
                        {moment(startTime).format('MMMM Do h:mma')}
                      </StyledTimeZoneLabel>
                    </>
                  )}
                </div>
                {id && isCoach && mobileView && !expanded && (
                  <>
                    <span style={{ display: 'flex', left: '145px', position: 'absolute', top: '64px' }}>
                      <p>{moment(startTime).format('MMMM Do')}</p>
                    </span>
                  </>
                )}
                {id &&
                  (activeTemplate === TemplateType.TemplateThree || isCoach) &&
                  !isEmpty(participantPackage) &&
                  expanded && (
                    <Tooltip
                      title="The progress bar charts the sessions booked by the client in relation to the count. It's a helpful visual tool to easily track the booked sessions for both coach and clients.  The bar will  continue to show the total booked sessions by the client irrespective of the deleted sessions by the coach."
                      arrow
                      placement="right"
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          left: mobileView ? '37%' : '30%',
                          width: mobileView ? '120px' : '540px',
                          marginBottom: mobileView ? '0px' : '8px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          flexDirection: mobileView ? 'row-reverse' : 'row',
                          marginTop: mobileView ? '55px' : '0px',
                        }}
                      >
                        <StyledSessionRemaining clr={colorToUse?.PrimaryColorCode}>
                          {!mobileView && <span>Sessions Booked</span>}
                          <span style={{ marginLeft: '5px' }}>
                            {`${bookedSessionNumbers > sessionNumbers ? sessionNumbers : bookedSessionNumbers}`}/
                            {`${sessionNumbers}`}
                          </span>
                        </StyledSessionRemaining>

                        <ProgressBar
                          completed={percentageCompleted}
                          bgColor={colorToUse?.PrimaryColorCode}
                          height="13px"
                          isLabelVisible={false}
                          width={mobileView ? '120px' : '350px'}
                        />
                      </div>
                    </Tooltip>
                  )}
                {activeTemplate === TemplateType.TemplateThree || isCoach ? (
                  <>
                    {id && (
                      <p className="one-to-one-session-summary__status">{isCompleted ? 'Completed' : 'Upcoming'}</p>
                    )}
                    {session?.id && isCoach && (
                      <button
                        type="button"
                        title={isDeleteDisabled ? 'Please mark this session complete prior to deleting it' : ''}
                        onClick={handleDelete}
                        disabled={isDeleteDisabled}
                        className="course-session-summary__delete"
                        style={
                          isDeleteDisabled
                            ? {
                                borderRadius: '4px',
                                backgroundColor: textColor,
                                color: btnColor,
                                border: `1px solid ${btnColor}`,
                                opacity: 0.3,
                              }
                            : {
                                backgroundColor: textColor,
                                color: btnColor,
                                border: `1px solid ${btnColor}`,
                                borderRadius: '4px',
                              }
                        }
                      >
                        Delete
                      </button>
                    )}
                    {session?.id && (
                      <button
                        type="button"
                        onClick={handleReschedule}
                        disabled={isRescheduleDisabled}
                        className="course-session-summary__reschedule"
                        style={
                          isRescheduleDisabled ? {} : { backgroundColor: btnColor, color: textColor, border: '0px' }
                        }
                      >
                        Reschedule
                      </button>
                    )}
                  </>
                ) : (
                  <>{session?.id && <div className="one-to-one-session-time__actions">{renderActions(session)}</div>}</>
                )}
              </div>
            </>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
          <div className="one-to-one-session-details">
            <h4 style={{ color: themedColor }} className="one-to-one-session-details__header">
              {id ? 'Session Time' : `Confirm a time for this session by clicking 'Book Session Time'`}
              {id && timeZoneLabel && (
                <StyledTimeZoneLabel href={userProfileUrl} style={{ backgroundColor: btnColor, color: textColor }}>
                  {timeZoneLabel}{' '}
                </StyledTimeZoneLabel>
              )}
            </h4>
            <div>
              <div className="one-to-one-session-details-session-times">
                <SessionTime
                  className="one-to-one-session-details-session-times__item"
                  startTime={startTime}
                  endTime={endTime}
                  participantId={participantId}
                  session={session}
                  timeDetails={timeDetails}
                  renderActions={renderActions}
                  activeTemplate={activeTemplate}
                  isRescheduleDisabled={isRescheduleDisabled}
                  handleReschedule={handleReschedule}
                  btnColor={btnColor}
                  textColor={textColor}
                  mobileView={mobileView}
                />
              </div>
              {id && <BottomPanel contributionId={contributionId} sessionId={id} attachments={attachments} />}
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Modal
        isOpen={isRescheduleModalOpen}
        onCancel={() => {
          setRescheduleModalState(false);
        }}
        PrimaryColor={btnColor}
        textColor={textColor}
        onSubmit={handleSubmit}
        title="Reschedule your session"
        hiddenCancel
        submitTitle="Reschedule Session"
        disableSubmitClick={isRescheduleDisabled}
      >
        {serviceProviderName ? (
          <BodyText color={themedColor}>
            You can reschedule sessions 24 hours in advance. If you need to reschedule and it’s 24 hours or less prior
            to your session, please message your coach {serviceProviderName} directly. Per terms and conditions, your
            coach is not obligated to allow a reschedule with less than 24hrs notice.
            <br />
          </BodyText>
        ) : (
          <BodyText color={themedColor}>
            To reschedule, please select a new time from your calendar. Also, please confirm with your client that the
            new time works for them prior to making the change. It will automatically update the time for all parties
            once you confirm.
            <br />
            {/* <br/>
          If you need to cancel with a client, your Client will need to cancel on their end.
          At this time, Cohere does not automate refunds and thus requires approval from your Client.
          If you think your client needs a refund, please email us at <a href="mailto:support@cohere.live">support@cohere.live</a>. */}
          </BodyText>
        )}
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        headerBorder
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        PrimaryColor={btnColor}
        textColor={textColor}
        onSubmit={handleDeleteSession}
        title="Are you sure you want to delete?"
        submitTitle="Yes, Delete For Me And My Client"
        cancelTitle="Never mind"
        disableSubmitClick={!deleteSession || (deleteSession === true && reasonForDeletion.length === 0)}
        reduceCancelButton
      >
        <BodyText color={themedColor}>
          {`By deleting ${
            session?.sessionName === null ? `Session ${number}` : session?.sessionName
          }, your client will also lose access and history of this session and you will be
          responsible for any possible refunds that may need to be issued.`}
          <br />
          <br />
          Are you sure you wish to continue?
          <br />
          <div style={{ marginTop: '15px' }}>
            <FormControlLabel
              checked={deleteSession}
              onChange={() => {
                setDeleteSession(!deleteSession);
              }}
              control={<Checkbox color="primary" />}
              label={<Typography style={{ fontWeight: '350' }}>Yes, I understand</Typography>}
              name="deleteSession"
              style={{ fontWeight: '350' }}
            />
          </div>
          {/* <br /> */}
          {deleteSession && (
            <>
              <div style={{ fontWeight: '350', fontSize: '14px' }}>
                <strong style={{ fontWeight: '500', fontSize: '16px' }}>Reason</strong> (Your client will be notified
                with this)
              </div>
              <StyledTextArea
                name="reasonForDeletion"
                rows="4"
                value={reasonForDeletion}
                onChange={e => {
                  setReasonForDeletion(e.target.value);
                }}
              />
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div>
                  {reasonForDeletion.length > 200 && (
                    <div style={{ color: 'red' }}>Maximum characters allowed: 200</div>
                  )}
                </div>
                <div
                  style={{
                    color: 'rgba(0,0,0,0.87)',
                    fontSize: '0.75rem',
                    fontWeight: 'normal',
                    lineHeight: '2rem',
                    fontFamily: 'Avenir',
                    letterSpacing: '0.08px',
                  }}
                >
                  {reasonForDeletion.length || 0}/200
                </div>
              </div>
            </>
          )}
        </BodyText>
      </Modal>
      <Modal
        isOpen={isDeletedSuccessfullyModalOpen}
        title="Deleted Successfully"
        submitTitle="Ok"
        onSubmit={() => {
          dispatch(fetchCohealerContribution(contributionId));
          setIsDeletedSuccessfullyModalOpen(false);
        }}
        onCancel={() => {
          dispatch(fetchCohealerContribution(contributionId));
          setIsDeletedSuccessfullyModalOpen(false);
        }}
        reduceCancelButton
        hiddenCancel
      >
        <p>Your session has been deleted successfully.</p>
      </Modal>
    </>
  );
};

Session.defaultProps = {
  session: {},
  renderActions: null,
  serviceProviderName: null,
};

Session.propTypes = {
  number: PropTypes.number.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    isCompleted: PropTypes.bool,
    participantId: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  contributionId: PropTypes.string.isRequired,
  renderActions: PropTypes.func,
  executeCalendarScroll: PropTypes.func.isRequired,
  setRescheduleMode: PropTypes.func.isRequired,
  setCurrentRescheduleSession: PropTypes.func.isRequired,
  serviceProviderName: PropTypes.string,
};

export default Session;
