import SearchBox from 'pages/EmailCampaigns/Components/SearchBox/SearchBox';
import React, { useEffect, useState } from 'react';
import classes from './PreDesignModal.module.scss';
import HoverableImage from 'pages/EmailCampaigns/Components/HoverableImage/HoverableImage';
import { useDebounce } from 'pages/CreateCampaign/Components/helper/helper';

import { Tooltip } from '@material-ui/core';
export const TOOLTIP = {
  ENTER_DELAY: 0,
  LEAVE_DELAY: 10000,
};
const PreDesignModal = ({ items = [], selectedItem, setSelectedItem, onPreview, handleShowScratch }) => {
  console.log('itemsinside=>', items);

  const [searchedKeyWord, setSearchedKeyWord] = useState('');

  const handleSearch = useDebounce(value => {
    console.log('debounce called....');
    setSearchedKeyWord(value);
  }, 500);

  const getSearchedData = (items, keyword) => {
    if (!keyword) return items;

    return items?.filter(
      item =>
        item?.title?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
        item?.emailSubject?.toLowerCase()?.includes(keyword?.toLowerCase()),
    );
  };

  const handleSelect = id => {
    // if (selectedItem === id) {
    //   setSelectedItem(null);
    // } else {
    setSelectedItem(id);
    // }
  };

  return (
    <>
      <div className={classes.container}>
        <SearchBox
          onChange={event => {
            handleSearch(event?.target?.value);
          }}
          containerClassName={classes.search_box_container}
          placeholder="Search by campaign name"
        />
        <div className={classes.item_container}>
          <div className={classes.list_items_container}>
            {getSearchedData(items, searchedKeyWord).length === 0 && searchedKeyWord.length === 0 ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                  No past campaigns.
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontWeight: '400',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      marginLeft: '3px',
                    }}
                    onClick={handleShowScratch}
                  >
                    Create from scratch
                  </span>
                </div>
              </>
            ) : getSearchedData(items, searchedKeyWord).length === 0 && searchedKeyWord.length > 0 ? (
              <>
                {' '}
                <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>No search results</div>
              </>
            ) : (
              getSearchedData(items, searchedKeyWord)?.map((item, index) => {
                return (
                  <>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        handleSelect(item?.id);
                      }}
                      key={index}
                      className={classes.list_item}
                    >
                      <HoverableImage
                        content={item?.emailContent}
                        // onSelect={() => handleSelect(item?.id)}
                        imageContainerClassName={selectedItem === item?.id ? `${classes.selected}` : ''}
                        template={item?.src}
                        onPreview={() => {
                          onPreview(item?.id);
                        }}
                      />
                      <Tooltip
                        title={item?.title}
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <p className={classes.title}>
                          {item?.title.length > 40 ? item?.title.substring(0, 30) + '...' : item?.title}
                        </p>
                      </Tooltip>
                      <Tooltip
                        title={item?.emailSubject}
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <p className={classes.email_subject}>
                          {item?.emailSubject.length > 40
                            ? item?.emailSubject.substring(0, 30) + '...'
                            : item?.emailSubject}
                        </p>
                      </Tooltip>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PreDesignModal;
