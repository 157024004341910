import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import * as userActions from 'actions/user';
import * as Yup from 'yup';
import { useAccount, useHttp } from 'hooks';
import { getThemedColors, saveCustomAvailablilityTime } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import ContributionForm from 'pages/ContributionView/components/CalendarContributionView/ContributionForm';
import { Formik } from 'formik';
import { FormControl, FormControlLabel, Grid, InputLabel, Radio, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import TimePickerField from 'components/FormUI/TimePickerField';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import { fetchTimeZones } from 'actions/timeZone';
import { Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MenuItem, OutlinedInput } from '@material-ui/core';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP } from '../../../constants';
import { colors } from 'utils/styles';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import CustomSelect from 'components/FormUI/Select';
import moment from 'moment';
const StyledFormControlLabel = styled(FormControlLabel)`
  text-align: right;
`;
const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledCustomSelect = styled(CustomSelect)`
  width: calc(100% - 33px);
`;
const useStyles = makeStyles({
  checkboxLabel: {
    fontSize: 14,
    fontWeight: 900,
    fontFamily: 'Avenir',
    padding: '0 9px',
    letterSpacing: '1.25px',
  },
  root: {
    marginLeft: '-30px',
  },
});
export const AvailabilityModal = ({
  showTerms,
  onCancel,
  applyTheming,
  setshowAvailabilityPopUp,
  selectedDate = null,
  selectedContribution = null,
  startTime = null,
  incrementNewSlots,
}) => {
  const [overlappingAllowed, setOverlappingAllowed] = useState(false);
  const [parameters, setParameters] = useState(null);
  const classes = useStyles();
  const contribution = useContribution();
  const { user } = useAccount();
  const dispatch = useDispatch();
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedTimeZone, setselectedTimeZone] = useState(user?.timeZoneId);
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const datetimeRef = useRef(null);
  const datetimeRefNew = useRef(null);

  const formRef = useRef(null);
  const WeekValues = [
    { title: 'Monday', value: 'Monday' },
    { title: 'Tuesday', value: 'Tuesday' },
    { title: 'Wednesday', value: 'Wednesday' },
    { title: 'Thursday', value: 'Thursday' },
    { title: 'Friday', value: 'Friday' },
    { title: 'Saturday', value: 'Saturday' },
    { title: 'Sunday', value: 'Sunday' },
  ];

  const findCountOf = date => {
    const c = date / 7;
    if (c > 4) {
      return 5;
    } else if (c > 3 && (c < 4 || c === 4)) {
      return 4;
    } else if (c > 2 && (c < 3 || c === 3)) {
      return 3;
    } else if (c > 1 && (c < 2 || c === 2)) {
      return 2;
    } else {
      return 1;
    }
  };
  function isLastWeekOfMonth(year, month, day) {
    // Create a new date object with the selected date
    var selectedDate = new Date(year, month - 1, day);
    // Get the total number of days in the month
    var lastDay = new Date(year, month, 0).getDate();
    // Calculate the day of the week for the last day of the month
    var lastDayOfWeek = new Date(year, month - 1, lastDay).getDay();
    // Calculate the day of the week for the selected date
    var selectedDayOfWeek = selectedDate.getDay();
    // Determine if the selected date falls in the last week
    return lastDay - day <= 6 && selectedDayOfWeek >= lastDayOfWeek;
  }
  const todayDay = moment().format('dddd');
  const todayDate = moment().format('D');
  const handleClick = () => {
    if (datetimeRef.current) {
      datetimeRef.current.click();
    }
  };
  const handleClickNew = () => {
    if (datetimeRefNew.current) {
      datetimeRefNew.current.click();
    }
  };
  useEffect(() => {
    if (selectedDate != null && startTime === null) {
      setTimeout(() => {
        handleDatePick(moment(selectedDate));
      }, [2000]);
    }
    if (selectedDate != null && startTime != null) {
      setTimeout(() => {
        handleDatePick(moment(selectedDate));
        handleStartTimePick(startTime);
      }, [2000]);
    }
    if (selectedContribution != null) {
      setTimeout(() => {
        handleSelectedContribution();
      }, [2000]);
    }
  }, [selectedDate, formRef.current, startTime]);

  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
  }, [timeZones, timeZoneLoading]);
  const { newThemedTextColor } = getThemedColors(contribution);
  const themedColor = newThemedTextColor;

  const handleDatePick = selectedMomentDate => {
    if (formRef.current) {
      const selectedDate = selectedMomentDate.format();
      formRef.current.setFieldValue('date', selectedDate);
      const selectedMoment = moment(selectedDate);

      const newDate = selectedMoment.add(1, 'year');
      formRef.current.setFieldValue('endDate', newDate);
    }
  };

  const handleStartTimePick = selectedMomentDate => {
    if (formRef.current) {
      const selectedTime = moment(selectedMomentDate);
      formRef.current.setFieldValue('startTime', selectedTime);
    }
  };
  const handleSelectedContribution = () => {
    if (formRef.current) {
      formRef.current.setFieldValue('contributions', [
        { id: selectedContribution.id, title: selectedContribution.title },
      ]);
    }
  };
  const handleDatePickRecurring = selectedMomentDate => {
    const selectedDate = selectedMomentDate.format();
    formRef.current.setFieldValue('endDate', selectedDate);
  };

  const saveHandler = () => {
    const startTime = moment(formRef.current.values.startTime);
    const endTime = moment(formRef.current.values.endTime);
    const startTimeString = startTime.format('HH:mm:ss');
    const endTimeString = endTime.format('HH:mm:ss');

    if (startTimeString > endTimeString) {
      formRef.current.setErrors({ endTime: 'End time must be greater than Start time' });
    } else {
      // Reset the error if comparison is successful
      formRef.current.setErrors({});
      // Add your form submission logic here

      const arrayOfIds = formRef.current.values.contributions.map(obj => obj.id);
      const currentDate = moment(formRef.current.values.date).format('YYYY-MM-DD');
      const currentTimeEnd = moment(`${currentDate} ${formRef.current.values.endTime.format('HH:mm:00')}`).format(
        'YYYY-MM-DDTHH:mm:00',
      );
      const currentTimeStart = moment(`${currentDate} ${formRef.current.values.startTime.format('HH:mm:00')}`).format(
        'YYYY-MM-DDTHH:mm:00',
      );
      const params = formRef.current.values.isResubmissionVal
        ? {
            overRideConflictingSlots: false,
            userId: user.id,
            makeCustomAvailabilityRecurring: false,
            endTime: currentTimeEnd,
            startTime: currentTimeStart,
            customDate: currentDate,
            timeZone: formRef.current.values?.timezone,
            contributionIds: arrayOfIds,
            makeCustomAvailabilityRecurring: formRef.current.values.isResubmissionVal,
            recurringSessions: {
              interval: formRef.current.values?.interval,
              recurrencePattern: formRef.current.values?.recurrencePattern,
              repeatOn: formRef.current.values?.repeatOn,
              repeatOnData: formRef.current.values?.repeatOnData,
              endson: formRef.current.values?.endson,
              endDate: formRef.current.values?.endDate,
              occurrences: formRef.current.values?.occurrences,
              occurrence: formRef.current.values?.occurrence,
              daysOfWeek: formRef.current.values?.daysOfWeek,
              isDayOfWeek: formRef.current.values?.isDayOfWeek,
              dayOfMonth: formRef.current.values?.dayOfMonth,
              weekOfMonth: formRef.current.values?.weekOfMonth,
              dayOfWeek: formRef.current.values?.dayOfWeek,
            },
          }
        : {
            overRideConflictingSlots: false,
            userId: user.id,
            makeCustomAvailabilityRecurring: false,
            endTime: currentTimeEnd,
            startTime: currentTimeStart,
            customDate: currentDate,
            timeZone: formRef.current.values?.timezone,
            contributionIds: arrayOfIds,
          };
      setParameters(params);
      saveCustomAvailablilityTime(params)
        .then(res => {
          if (res.includes('Overlapping slots found')) {
            setOverlappingAllowed(true);
          }
          if (res.includes('Overlapping slots found') === false) {
            dispatch(userActions.getProfile(user.id));
            incrementNewSlots();
            setshowAvailabilityPopUp(false);
          }
        })
        .catch(err => {
          setshowAvailabilityPopUp(false);
        });
    }
  };

  return (
    <>
      <Modal
        applyTheming={false}
        isDarkModeSelected={false}
        isCreatingContribution
        isOpen={showTerms}
        onCancel={() => {
          onCancel();
          setselectedTimeZone(user?.timeZoneId);
        }}
        title="Add 1:1 Availability"
        cancelTitle={'Cancel'}
        submitTitle={'Done'}
        isCreatingContrinbution
        widthRequired
        brandingColor={false}
        onSubmit={() => {
          formRef.current.handleSubmit();
        }}
      >
        <Formik
          innerRef={formRef}
          // enableReinitialize
          initialValues={{
            contributions: activeContribution?.id
              ? [{ value: activeContribution?.id, title: activeContribution.title }]
              : [],
            date: null,
            startTime: null,
            endTime: null,
            timezone: user.timeZoneId,
            interval: 1,
            recurrencePattern: 'daily',
            repeatOn: [],
            repeatOnData: [],
            endson: true,
            occurrences: null,
            occurrence: false,
            daysOfWeek: [],
            isDayOfWeek: false,
            dayOfMonth: 1,
            weekOfMonth: 1,
            dayOfWeek: 'Sunday',
            isResubmissionVal: false,
            endDate: moment().add(1, 'year'),
          }}
          validationSchema={Yup.object().shape({
            interval: Yup.number().min(1, 'it must be 1 or more').required('This is a required field').nullable(),
            recurrencePattern: Yup.string().required('This is a required field'),
            contributions: Yup.array().required('This is a required field'),
            date: Yup.string().required('This is a required field').nullable(),
            startTime: Yup.string().required('This is a required field').nullable(),
            endTime: Yup.string().required('This is a required field').nullable(),
            daysOfWeek: Yup.array().when('recurrencePattern', {
              is: v => v === 'weekly',
              then: Yup.array().required('This is a required field'),
              otherwise: Yup.array(),
            }),
            repeatOn: Yup.array().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.array().required('This is a required field'),
              otherwise: Yup.array(),
            }),
            dayOfMonth: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            dayOfWeek: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            weekOfMonth: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            // endDate: Yup.date().when('endson', {
            //   is: true,
            //   then: Yup.date().required('This is a required field'),
            //   // otherwise: Yup.date().nullable(),
            // }),
            occurrences: Yup.number().when('occurrence', {
              is: true,
              then: Yup.number()
                .integer()
                .required('This is a required field')
                .min(1, 'it must be 1 or more')
                .nullable(),
              otherwise: Yup.number().nullable(),
            }),
          })}
          onSubmit={saveHandler}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            const changeTimeZone = e => {
              if (e.target.value != values.timezone) {
                setselectedTimeZone(e.target.value);
                // setFieldValue('timezone', e.target.value);
                setShowPopUp(true);
                return;
              } else {
                // setselectedTimeZone(e.target.value);
                // setFieldValue('timezone', e.target.value);
                return;
              }
            };
            const changeRepeatOnValues = e => {
              if (e?.target?.value === 'daily') {
                setFieldValue('recurrencePattern', e?.target?.value);
              } else if (e?.target?.value === 'weekly') {
                setFieldValue('repeatOnData', WeekValues);
                setFieldValue('daysOfWeek', []);
                setFieldValue('recurrencePattern', e?.target?.value);
              } else if (e?.target?.value === 'monthly') {
                const todayDate = moment(moment()).format('D');
                const count = findCountOf(todayDate);
                var year = new Date(moment()).getFullYear();
                var month = new Date(moment()).getMonth();
                var day = todayDate;
                let lastWeek;
                if (isLastWeekOfMonth(year, month, day)) {
                  lastWeek = true;
                  console.log('Selected date is in the last week of the month.');
                } else {
                  lastWeek = false;
                  console.log('Selected date is not in the last week of the month.');
                }
                setFieldValue('repeatOnData', [
                  { name: `Monthly on day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                  {
                    name: `Monthly on ${
                      count === 1
                        ? '1st'
                        : count === 2
                        ? '2nd'
                        : count === 3
                        ? '3rd'
                        : count === 4 && lastWeek
                        ? 'Last'
                        : count === 5
                        ? 'Last'
                        : 'Last'
                    } ${todayDay}`,
                    value: `Monthly on ${count} ${todayDay}`,
                  },
                ]);
                setFieldValue('repeatOn', []);
                setFieldValue('dayOfWeek', todayDay);
                setFieldValue('weekOfMonth', count);
                setFieldValue('recurrencePattern', e?.target?.value);
              } else {
                setFieldValue('repeatOnData', null);
                setFieldValue('repeatOn', []);
                setFieldValue('recurrencePattern', e?.target?.value);
              }
            };

            return (
              <>
                <div style={{ marginTop: '15px' }}>
                  <ContributionForm
                    formValues={values}
                    setFieldValue={setFieldValue}
                    availabilityModal={true}
                    touched={touched}
                    formErrors={errors}
                    selectedContribution={selectedContribution}
                  />
                </div>
                <Grid style={{ flexDirection: 'row', display: 'flex' }} lg={12}>
                  <Grid style={{ marginTop: '10px' }} lg={6}>
                    <Grid style={{ maxWidth: '80%' }} lg={6}>
                      <span style={{ fontWeight: '500', fontSize: '14px', fontFamily: 'Avenir', color: '#213649' }}>
                        Date
                      </span>
                      <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                        <DatePicker
                          inputRef={datetimeRefNew}
                          fullWidth
                          disablePast
                          onChange={momentDate => handleDatePick(momentDate)}
                          value={values.date}
                          format={'MMM DD, YYYY'}
                        />
                        <InsertInvitationIcon
                          htmlColor="#757575"
                          style={{ position: 'absolute', right: '0px' }}
                          onClick={handleClickNew}
                        />
                      </div>
                      {formRef.current != null && errors?.date && touched?.date && (
                        <CommonErrorMessage className="default-font-family" message={errors?.date} align="left" />
                      )}
                    </Grid>
                    <Grid style={{ maxWidth: '80%', marginTop: '10px' }} lg={6}>
                      <span style={{ fontWeight: '500', fontSize: '14px', fontFamily: 'Avenir', color: '#213649' }}>
                        Start Time
                      </span>
                      <TimePickerField
                        availabilityModal={true}
                        onChange={value => setFieldValue('startTime', moment(value))}
                        showSecond={false}
                        value={values.startTime}
                        format="h:mm a"
                        use12Hours
                        minuteStep={15}
                      />
                      {formRef.current != null && touched.startTime && errors?.startTime && (
                        <CommonErrorMessage className="default-font-family" message={errors?.startTime} align="left" />
                      )}
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '10px' }} lg={6}>
                    <Grid style={{ maxWidth: '100%' }} lg={6}>
                      <span style={{ fontWeight: '500', fontSize: '14px', fontFamily: 'Avenir', color: '#213649' }}>
                        Time Zone
                      </span>
                      <Select
                        style={{ width: '100%' }}
                        required
                        native
                        labelId="timezones"
                        name={'timezone'}
                        value={selectedTimeZone}
                        onChange={changeTimeZone}
                        InputLabelProps={{
                          style: { color: 'white' },
                        }}
                      >
                        {/* <option aria-label="None" value={null} /> */}
                        {timeZones?.length > 0 &&
                          timeZones.map(timeZone => <option value={timeZone.countryName}>{timeZone.name}</option>)}
                      </Select>
                    </Grid>
                    <Grid style={{ maxWidth: '100%', marginTop: '10px' }} lg={6}>
                      <span style={{ fontWeight: '500', fontSize: '14px', fontFamily: 'Avenir', color: '#213649' }}>
                        End Time
                      </span>
                      <TimePickerField
                        availabilityModal={true}
                        onChange={value => setFieldValue('endTime', moment(value))}
                        showSecond={false}
                        value={values.endTime}
                        format="h:mm a"
                        use12Hours
                        minuteStep={15}
                      />
                      {formRef.current != null && errors?.endTime && touched.endTime && (
                        <CommonErrorMessage className="default-font-family" message={errors?.endTime} align="left" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: '20px', marginLeft: '20px' }} lg={12}>
                  <FormControlLabel
                    classes={{
                      label: classes.checkboxLabel,
                      root: classes.root,
                    }}
                    checked={values.isResubmissionVal}
                    onChange={() => {
                      setFieldValue('isResubmissionVal', !values.isResubmissionVal);
                      setFieldValue('occurrences', null);
                      const todayDay = moment(moment()).format('dddd');
                      const todayDate = moment(moment()).format('D');
                      const count = findCountOf(todayDate);
                      var year = new Date(moment()).getFullYear();
                      var month = new Date(moment()).getMonth() + 1;
                      var day = todayDate;
                      let lastWeek;
                      if (isLastWeekOfMonth(year, month, day)) {
                        lastWeek = true;
                        console.log('Selected date is in the last week of the month.');
                      } else {
                        lastWeek = false;
                        console.log('Selected date is not in the last week of the month.');
                      }
                      if (values.recurrencePattern === 'monthly') {
                        setFieldValue('repeatOnData', [
                          { name: `Monthly on day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                          {
                            name: `Monthly on ${
                              count === 1
                                ? '1st'
                                : count === 2
                                ? '2nd'
                                : count === 3
                                ? '3rd'
                                : count === 4 && lastWeek
                                ? 'Last'
                                : count === 5
                                ? 'Last'
                                : '4th'
                            } ${todayDay}`,
                            value: `Monthly on ${count} ${todayDay}`,
                          },
                        ]);
                      } else {
                        setFieldValue('repeatOnData', WeekValues);
                      }
                    }}
                    control={<Checkbox color="primary" />}
                    label="Make Availability Recurring"
                    name="isResubmissionVal"
                  />
                </Grid>
                {values.isResubmissionVal && (
                  <>
                    <Grid style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }} lg={12}>
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          maxWidth: '40%',
                        }}
                        lg={6}
                      >
                        <Grid lg={5}>
                          <Grid
                            className="default-font-family"
                            style={{
                              flexWrap: 'wrap',
                              display: 'flex',
                              fontSize: 14,
                              fontWeight: '500',
                              fontFamily: 'Avenir',
                              alignItems: 'center',
                              color: '#213649',
                            }}
                          >
                            Repeat every
                          </Grid>

                          <TextField
                            id="outlined-basic"
                            variant="standard"
                            style={{ fontFamily: 'Brandon Text', border: 'none' }}
                            fullWidth
                            name="interval"
                            value={values?.interval}
                            onChange={e => {
                              const re = /^[0-9\b]+$/;
                              console.log('e', e.target.value, 're::', re.test(e?.target?.value));
                              if (e.target.value.toString() === '') {
                                setFieldValue('interval', null);
                              } else if (re.test(e?.target?.value)) {
                                setFieldValue('interval', parseInt(e?.target?.value));
                              }
                            }}
                          />
                          {errors && errors?.interval && (
                            <CommonErrorMessage
                              className="default-font-family"
                              message={errors?.interval}
                              align="left"
                            />
                          )}
                        </Grid>
                        <Grid lg={6}>
                          <Grid
                            style={{
                              marginTop: '20px',
                            }}
                          />
                          <Select
                            style={{ fontFamily: 'Brandon Text' }}
                            variant="standard"
                            name="recurrencePattern"
                            defaultValue={values?.recurrencePattern}
                            fullWidth
                            // input={<OutlinedInput margin="dense" />}
                            onChange={changeRepeatOnValues}
                          >
                            {[
                              { value: 'daily', name: values?.interval > 1 ? 'Days' : 'Day' },
                              { value: 'weekly', name: values?.interval > 1 ? 'Weeks' : 'Week' },
                              { value: 'monthly', name: values?.interval > 1 ? 'Months' : 'Month' },
                              { value: 'yearly', name: values?.interval > 1 ? 'Years' : 'Year' },
                            ].map(timeZone => {
                              return <MenuItem value={timeZone?.value}>{timeZone?.name}</MenuItem>;
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                      {(values?.recurrencePattern === 'weekly' || values?.recurrencePattern === 'monthly') && (
                        <Grid lg={6}>
                          <Grid
                            className="default-font-family"
                            style={{
                              flexWrap: 'wrap',
                              display: 'flex',
                              fontSize: 14,
                              fontWeight: '500',
                              fontFamily: 'Avenir',
                              alignItems: 'center',
                              color: '#213649',
                            }}
                            item
                          >
                            Repeat on
                          </Grid>
                          <Grid item xs={12}>
                            {values.recurrencePattern === 'weekly' ? (
                              <StyledCustomSelect
                                name="daysOfWeek"
                                multiple
                                variant="standard"
                                className="default-font-family"
                                items={WeekValues}
                                fullWidth
                                style={{ marginTop: '0px' }}
                              />
                            ) : (
                              <>
                                <Select
                                  label="Repeat oooon"
                                  name="repeatOn"
                                  variant="standard"
                                  fullWidth
                                  value={values?.repeatOn[0]}
                                  // input={<OutlinedInput margin="dense" />}
                                  onChange={e => {
                                    if (e?.target?.value === `Monthly on day ${todayDate}`) {
                                      setFieldValue('isDayOfWeek', false);
                                      setFieldValue('dayOfMonth', parseInt(todayDate));
                                    } else {
                                      const count = findCountOf(todayDate);
                                      setFieldValue('isDayOfWeek', true);
                                      setFieldValue('dayOfWeek', todayDay);
                                      setFieldValue('weekOfMonth', parseInt(count));
                                    }
                                    setFieldValue('repeatOn', [e?.target?.value]);
                                  }}
                                >
                                  {values?.repeatOnData?.map(item => {
                                    return (
                                      <MenuItem className="default-font-family" value={item?.value}>
                                        {item?.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                {errors && errors?.repeatOn && (
                                  <CommonErrorMessage
                                    className="default-font-family"
                                    message={errors?.repeatOn}
                                    align="left"
                                  />
                                )}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid style={{ flexDirection: 'row', display: 'flex', marginTop: '10px' }} lg={5}>
                      <Grid
                        style={{ flexWrap: 'wrap', alignContent: 'center', display: 'flex', alignItems: 'center' }}
                        className="default-font-family"
                        item
                        xs={4}
                      >
                        <Grid
                          className="default-font-family"
                          style={{
                            flexWrap: 'wrap',
                            display: 'flex',
                            fontSize: 14,
                            fontWeight: '500',
                            fontFamily: 'Avenir',
                            alignItems: 'center',
                            color: '#213649',
                          }}
                          item
                        >
                          End date
                        </Grid>
                        {/* <FormControlLabel
                          style={{
                            // marginTop: '-8px',
                            color: '#4A4A4A',
                            fontFamily: 'Brandon Text',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            fontSize: '14px',
                            marginLeft: 0,
                          }}
                          className="default-font-family"
                          value="endson"
                          control={
                            <Radio
                              className="default-font-family"
                              style={{ color: colors.darkOceanBlue, padding: '0px' }}
                              onClick={() => {
                                if (values.endson === false) {
                                  setFieldValue('endson', true);
                                  setFieldValue('occurrence', false);
                                  setFieldValue('occurrences', null);
                                }
                                setFieldValue('occurrences', null);
                              }}
                              checked={values?.endson}
                            />
                          }
                          label={<div style={{ marginLeft: 5, fontSize: '16px' }}>On</div>}
                        />
                        <Tooltip
                          title="You can do only for one year"
                          arrow
                          style={{ marginBottom: 8 }}
                          className="default-font-family"
                          // style={{ marginBottom: '18px' }}
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor={colors.lightBrown} />
                        </Tooltip> */}
                      </Grid>
                      <Grid style={{ marginLeft: '45px' }} lg={6}>
                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                          <DatePicker
                            inputRef={datetimeRef}
                            fullWidth
                            disablePast
                            onChange={momentDate => handleDatePickRecurring(momentDate)}
                            value={values.endDate}
                            format={'MMM DD, YYYY'}
                          />
                          <InsertInvitationIcon
                            htmlColor="#757575"
                            style={{ position: 'absolute', right: '0px' }}
                            onClick={handleClick}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            );
          }}
        </Formik>
      </Modal>
      {showPopUp && (
        <Modal
          isCreatingContribution={true}
          isOpen={true}
          title="Are you sure?"
          onCancel={() => {
            formRef.current.setFieldValue('timezone', user?.timeZoneId);
            setselectedTimeZone(user?.timeZoneId);
            setShowPopUp(false);
          }}
          onSubmit={() => {
            setShowPopUp(false);
            formRef.current.setFieldValue('timezone', selectedTimeZone);
            if (activeContribution?.id) {
              formRef.current.setFieldValue('contributions', [
                { value: activeContribution?.id, title: activeContribution.title },
              ]);
            } else if (formRef.current.values.contributions.length > 0) {
              formRef.current.setFieldValue('contributions', formRef.current.values.contributions);
            }
          }}
        >
          <div className="d-flex flex-column">
            You are changing your default time zone inside Cohere. Do you wish to proceed?
          </div>
        </Modal>
      )}
      {overlappingAllowed && (
        <Modal
          isCreatingContribution={true}
          isOpen={true}
          title="Slots Overlapped"
          submitTitle={'Ok'}
          // cancelTitle={'No'}
          onCancel={() => {
            setOverlappingAllowed(false);
          }}
          hiddenCancel={true}
          onSubmit={() => {
            setOverlappingAllowed(false);
            // saveCustomAvailablilityTime({
            //   ...parameters,
            //   overRideConflictingSlots: true,
            // }).then(() => {
            //   dispatch(userActions.getProfile(user.id));
            //   incrementNewSlots();
            //   setOverlappingAllowed(false);
            //   setshowAvailabilityPopUp(false);
            // });
          }}
        >
          <div className="d-flex flex-column">
            Time slot overlapped with your current schedule, Kindly select an alternative time.
          </div>
        </Modal>
      )}
    </>
  );
};

AvailabilityModal.propTypes = {
  showTerms: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  applyTheming: PropTypes.bool,
};
AvailabilityModal.defaultProps = {
  applyTheming: false,
};
