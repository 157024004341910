import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../../../constants';
import InfoIcon from '@material-ui/icons/Info';
import * as paidTier from 'selectors/paidTier';
import { StyledUl } from './List';
import { useSelector } from 'react-redux';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import { useAccount, useShallowEqualSelector } from 'hooks/index';
import { PAID_TIER_TITLES } from '../../../../constants';
import usePaidTier from 'hooks/usePaidTier';
import { colors } from 'utils/styles';
import { useDispatch } from 'react-redux';
import { setCohereAcademyStatus } from 'actions/update-user';
const StyledIcon = styled(Icon)`
  color: #8a8a8a;
`;
const StyledLi = styled.li`
  padding: ${({ subItem }) => (subItem ? '10px 10px 10px 60px' : '10px 10px 10px 30px')};
  // border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  ${({ isList }) => isList && `cursor: pointer;`}
  ${({ isActive, subItem }) =>
    isActive &&
    `
  padding: ${subItem ? '10px 10px 10px 56px' : '10px 10px 10px 26px'};
  border-left: 4px solid #215C73;
  background-color: #EFF3F5;
  `}
`;

const StyledLink = styled(Link)`
  font-family: Avenir;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24.03px;
  letter-spacing: 0.15px;
  color: ${props => props.color};

  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  &:hover {
    text-decoration: underline;
  }

  ${StyledUl} > ${StyledUl} & {
    font-weight: normal;
  }
`;

const StyledEndAdornment = styled.div`
  margin: 0 auto 0 10px;
`;

const NewTag = styled.span`
  padding: 2px 6px;
  background-color: #215c73;
  border-radius: 4px;
  color: white;
  font-family: Avenir;
  font-style: italic;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  margin-left: 4px;
  position: relative;
  bottom: 4px;

  &:hover {
    text-decoration: none !important;
  }
`;

function Item({
  children,
  endAdornment,
  isList,
  to,
  title,
  linkColor = 'rgba(0, 0, 0, 0.87)',
  defaultExpanded = false,
  tooltipText,
  isActive,
  subItem,
  isNew,
}) {
  const { signupType } = useSelector(state => state.account);
  const { currentRole, user: loggedInUser, isPartnerCoach } = useAccount();
  const user = useAccount();
  const [show, setShow] = useState(defaultExpanded);
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const dispatch = useDispatch();
  // const { isLaunchPlan, activePaidTierPlan } = usePaidTier();
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  // const { isLaunchPlan } = usePaidTier();
  const linkProps = to ? { to } : { to: {} };
  const shouldBeDisabled =
    currentRole === UserRoles.cohealer &&
    (signupType === SIGNUP_TYPES.TYPE_A ||
      signupType === SIGNUP_TYPES.TYPE_B ||
      loggedInUser.signupType === SIGNUP_TYPES.TYPE_B ||
      loggedInUser.signupType === SIGNUP_TYPES.TYPE_A ||
      isLaunchPlan);
  return (
    <>
      <StyledLi
        className={
          user?.user?.isPartnerCoach === false && shouldBeDisabled === false
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : null
        }
        onClick={() => setShow(prev => !prev)}
        isList={isList}
        isActive={isActive}
        subItem={subItem}
      >
        <div>
          <StyledLink {...linkProps} color={linkColor}>
            {title}
          </StyledLink>
          {isNew && <NewTag>New</NewTag>}
        </div>
        {endAdornment && <StyledEndAdornment>{endAdornment}</StyledEndAdornment>}
        {isList && <StyledIcon size={1} path={show ? mdiChevronUp : mdiChevronDown} />}
        {!!tooltipText && (
          <Tooltip
            title={tooltipText}
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
          </Tooltip>
        )}
      </StyledLi>
      {isList && show && children}
    </>
  );
}

Item.propTypes = {
  children: PropTypes.element,
  endAdornment: PropTypes.node,
  isList: PropTypes.bool,
  to: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

Item.defaultProps = {
  children: null,
  endAdornment: null,
  isList: false,
  to: null,
};

export default Item;
