import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { ContributionType, UserRoles } from 'helpers/constants';
import { useAccount } from 'hooks';
import useContribution from '../hooks/useContribution';
import CourseClients from './CourseClients';
import Modal from 'components/UI/Modal';
import { redirectTo } from 'services/links';

const ContributionClientsContainer = () => {
  const { user, currentRole } = useAccount();
  const contribution = useContribution();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (contribution?.isMembersHiddenInCommunity && currentRole != UserRoles.cohealer) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, []);

  const togglePopup = () => {
    setShowModal(!showModal);
    redirectTo(`/contribution-view/${contribution.id}/about`);
  };
  return contribution.type === ContributionType.contributionOneToOne ? (
    <Redirect to={`/contribution-view/${contribution.id}`} />
  ) : (
    <>
      <CourseClients user={user} currentRole={currentRole} contribution={contribution} />
      <Modal
        title="Members Inaccessible"
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
      >
        You can not access members of this contribution.
      </Modal>
    </>
  );
};

export default ContributionClientsContainer;
