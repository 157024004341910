import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';
import { FaFacebookF, FaLinkedinIn, FaTiktok } from 'react-icons/fa';
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import { HiGlobeAlt } from 'react-icons/hi';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useAccount, useEndVideoChat, useRouter, useShallowEqualSelector } from 'hooks';
import * as contributionActions from 'actions/contributions';
import { PAYMENTS_STATUS } from 'pages/ContributionView/components/PurchaseMembershipModal/PurchaseModal.constants';
import { determineColorToUse } from 'services/contributions.service';
import Banner from 'components/Banner/Banner';
import { ContributionType, UserRoles } from 'helpers/constants';
import TestimonialTemTwo from '../components/TestimonialTemTwo';
import MeetYourCoach from '../components/AboutMainInfoBlock/MeetYourCoachTemTwo';
import { fetchTestimonials } from 'actions/testimonials';
import { isTestimonialsVisible } from 'selectors/testimonials';
import { isNil } from 'ramda';
import AboutMainInfoBlock from '../components/AboutMainInfoBlock/AboutMainInfoTemTwo';
import AboutGeneralInfoBlock from '../components/AboutGeneralInfoBlockTemTwo';
import AboutDetailsBlock from '../components/AboutDetailsBlockTemTwo';
import styled from 'styled-components';
import CustomPurchaseBlockOneToOne from '../components/PurchaseBlock/CustomPurchaseBlockOneToOne';
import fbImg from '../../../assets/facebook2.png';
import linkedImg from '../../../assets/linkedin2.png';
import instaImg from '../../../assets/instagram2.png';
import youtube from '../../../assets/youtube2.png';
import tiktok from '../../../assets/tiktok2.png';
import website from '../../../assets/website2.png';
import CustomPurchaseBlock from '../components/PurchaseBlock/CustomPurchaseBlock';
import { ROUTES } from '../../../constants';
import OptinPageMetaPixelContrib from 'components/AdsComponents/OptinPageContrib';
import GoogleAnalytics from 'components/AdsComponents/GoogleTag';
import ClarityScript from 'components/AdsComponents/ClarityScript';

const RightLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledFaFacebookF = styled(FaFacebookF)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;
const StyledFaLinkedinIn = styled(FaLinkedinIn)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;
const StyledAiFillInstagram = styled(AiFillInstagram)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;
const StyledAiFillYoutube = styled(AiFillYoutube)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;

const StyledHiGlobeAlt = styled(HiGlobeAlt)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;

const StyledFaTiktok = styled(FaTiktok)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;

const HyperLink = styled.a.attrs({ target: '_blank' })``;

function AboutContribution({ contribution, getClientContribution, getCohealerContribution, currentRole }) {
  const {
    id,
    previewContentUrls,
    categories,
    serviceProviderName,
    gender,
    minAge,
    languageCodes,
    whoIAm,
    whatWeDo,
    purpose,
    preparation,
    earnedRevenue,
    paymentInfo,
    enrollment,
    bio,
    coachCountry,
    closestClassForBanner,
    contributionPartners,
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    tiktokUrl,
    facebookUrl,
    websiteUrl,
    whoIAmLabel,
    whatWeDoLabel,
    purposeLabel,
    preparationLabel,
    isPurchased,
    type,
    viewPreparation,
    viewPurpose,
    viewWhatWeDo,
    viewWhoIAm,
    whoIAmIcon,
    whatWeDoIcon,
    purposeIcon,
    preparationIcon,
    title,
    customTitleForMeetYourCoach,
    contributionTagline,
    testimonials,
    isCustomBrandingColorsActive,
    brandingColors,
  } = contribution;

  let colorToUse = determineColorToUse(contribution);

  const urls = {
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    facebookUrl,
    websiteUrl,
    tiktokUrl,
  };
  const { history, query, location } = useRouter();
  const { userId } = useShallowEqualSelector(state => state?.contributions?.activeContribution || '');
  const { user } = useAccount();
  const isTestimonialsEnabled = useSelector(isTestimonialsVisible);
  const dispatch = useDispatch();
  const hasAnyValue =
    (!viewWhoIAm && !!whoIAm) ||
    (!viewWhatWeDo && !!whatWeDo) ||
    (!viewPurpose && !!purpose) ||
    (!viewPreparation && !!preparation);
  const refreshContribution = useCallback(() => {
    if (currentRole === UserRoles.client) {
      getClientContribution(id);
    } else if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
      getCohealerContribution(id);
    }
  }, [id, currentRole, getClientContribution, getCohealerContribution]);

  const { Popup } = useEndVideoChat(refreshContribution);
  const isEnrollmentVisible = enrollment && !enrollment.anyTime;
  const isPurchaseBlock = [
    ContributionType.contributionCourse,
    ContributionType.contributionMembership,
    ContributionType.contributionCommunity,
  ].includes(type);

  const [shouldRedirect, setShouldRedirect] = useState(false);
  // redirect after joining
  useEffect(() => {
    if (query?.isPurchased) {
      if (currentRole === UserRoles?.client) {
        const anss = contribution?.contributionPartners?.filter(x => x?.userId === user?.id);
        if (anss?.length > 0) {
          history.push(ROUTES.ROLE_SWITCH, { path: location.pathname });
        }
      }
    }
    if (isPurchased) {
      if (query.payment === PAYMENTS_STATUS.SUCCESS) {
        if (contribution?.thankYouPageRedirectUrl?.length > 0) {
          // commented since it can be blocked on mobile that treats it like a pop up
          // window.open(contribution.thankYouPageRedirectUrl);
          window.location.href = contribution.thankYouPageRedirectUrl;
        } else {
          history.push(`/contribution-view/${id}/sessions`);
        }
      }

      if (shouldRedirect) {
        if (contribution?.thankYouPageRedirectUrl?.length > 0) {
          // commented since it can be blocked on mobile that treats it like a pop up
          // window.open(contribution.thankYouPageRedirectUrl);
          window.location.href = contribution.thankYouPageRedirectUrl;
        } else {
          dispatch(contributionActions.setInviteCode(null));
          history.push(`/contribution-view/${id}/sessions`);
          setTimeout(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }, 1000);
        }
      }
    }
  }, [dispatch, history, id, isPurchased, query.payment, shouldRedirect, query.code]);

  // useEffect(() => {
  //   if (!isNil(query?.code)) {
  //     dispatch(contributionActions.setInviteCode(query?.code));
  //   }
  //   // else {
  //   //   dispatch(contributionActions.setInviteCode(null));
  //   // }
  // }, [dispatch, query?.code]);

  useEffect(() => {
    dispatch(fetchTestimonials(id));
  }, [dispatch, id]);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    !isEmpty(contribution) && (
      <>
        {id === '66466c79ee9e6c514da9d7f0' && <GoogleAnalytics />}
        {id === '66466c79ee9e6c514da9d7f0' && <OptinPageMetaPixelContrib />}
        {id === '66466c79ee9e6c514da9d7f0' && <ClarityScript />}
        <Banner type="closestSession" closestClassForBanner={closestClassForBanner} />
        <Grid container spacing={4} style={{ width: '100%', margin: '0' }}>
          <Grid item xs={12} style={{ padding: '0px', marginTop: '50' }}>
            <AboutMainInfoBlock
              id={id}
              user={user}
              userId={userId}
              previewContentUrls={previewContentUrls}
              socialUrls={urls}
              categories={categories}
              contributionTagline={contributionTagline}
              title={title}
              contribution={contribution}
              earnedRevenue={earnedRevenue}
              paymentInfo={paymentInfo}
              brandingColors={colorToUse}
            />
          </Grid>
          {id != '655fb39e50bbf52ab67aeaf8' && (
            <Grid item xs={12} style={{ marginTop: '15px' }}>
              <AboutGeneralInfoBlock
                contribution={contribution}
                categories={categories}
                gender={gender}
                minAge={minAge}
                languageCodes={languageCodes}
                brandingColors={colorToUse}
              />
            </Grid>
          )}
          {isMobileSidebarNeeded &&
            (facebookUrl || linkedInUrl || instagramUrl || youtubeUrl || websiteUrl || tiktokUrl) && (
              <></>
              // <Grid item xs={12}>
              //   <RightLinks>
              //     {facebookUrl && (
              //       <HyperLink href={facebookUrl}>
              //         {/* <img style={{ padding: '0px 5px' }} src={fbImg}></img> */}
              //         <StyledFaFacebookF />
              //       </HyperLink>
              //     )}
              //     {linkedInUrl && (
              //       <HyperLink href={linkedInUrl}>
              //         {/* <img style={{ padding: '0px 5px' }} src={linkedImg}></img> */}
              //         <StyledFaLinkedinIn />
              //       </HyperLink>
              //     )}
              //     {instagramUrl && (
              //       <HyperLink href={instagramUrl}>
              //         {/* <img style={{ padding: '0px 5px', width: '40px', height: '30px' }} src={instaImg}></img> */}
              //         <StyledAiFillInstagram />
              //       </HyperLink>
              //     )}
              //     {youtubeUrl && (
              //       <HyperLink href={youtubeUrl}>
              //         {/* <img style={{ padding: '0px 5px' }} src={youtube}></img> */}
              //         <StyledAiFillYoutube />
              //       </HyperLink>
              //     )}
              //     {websiteUrl && (
              //       <HyperLink href={websiteUrl}>
              //         {/* <img style={{ padding: '0px 5px' }} src={website}></img> */}
              //         <StyledHiGlobeAlt />
              //       </HyperLink>
              //     )}
              //     {tiktokUrl && (
              //       <HyperLink href={tiktokUrl}>
              //         {/* <img style={{ padding: '0px 5px' }} src={tiktok}></img> */}
              //         <StyledFaTiktok />
              //       </HyperLink>
              //     )}
              //   </RightLinks>
              // </Grid>
            )}
          <Grid item md={12} sm={12} xs={12} style={{ paddingTop: '0rem' }}>
            {
              <Grid item xs={12}>
                <AboutDetailsBlock
                  whoIAm={whoIAm}
                  whatWeDo={whatWeDo}
                  purpose={purpose}
                  preparation={preparation}
                  whoIAmLabel={whoIAmLabel}
                  whatWeDoLabel={whatWeDoLabel}
                  purposeLabel={purposeLabel}
                  preparationLabel={preparationLabel}
                  viewPreparation={viewPreparation}
                  viewPurpose={viewPurpose}
                  viewWhatWeDo={viewWhatWeDo}
                  viewWhoIAm={viewWhoIAm}
                  whoIAmIcon={whoIAmIcon}
                  whatWeDoIcon={whatWeDoIcon}
                  purposeIcon={purposeIcon}
                  preparationIcon={preparationIcon}
                  setShouldRedirect={setShouldRedirect}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={colorToUse}
                />
              </Grid>
            }
            {!mobileView && (
              <Grid item xs={12}>
                <MeetYourCoach
                  id={id}
                  user={user}
                  userId={userId}
                  bio={bio}
                  coachCountry={coachCountry}
                  customTitleForMeetYourCoach={customTitleForMeetYourCoach}
                  previewContentUrls={previewContentUrls}
                  serviceProviderName={serviceProviderName}
                  contributionPartners={contributionPartners}
                  socialUrls={urls}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={colorToUse}
                />
              </Grid>
            )}

            {mobileView && (
              <Grid item xs={12}>
                <MeetYourCoach
                  mobileView={mobileView}
                  id={id}
                  user={user}
                  userId={userId}
                  bio={bio}
                  coachCountry={coachCountry}
                  customTitleForMeetYourCoach={customTitleForMeetYourCoach}
                  previewContentUrls={previewContentUrls}
                  serviceProviderName={serviceProviderName}
                  contributionPartners={contributionPartners}
                  socialUrls={urls}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={brandingColors}
                />
              </Grid>
            )}
            {!mobileView && (
              <Grid item xs={12} md={12}>
                <TestimonialTemTwo testimonials={testimonials} />
              </Grid>
            )}
            {mobileView && (
              <Grid item xs={12} sm={12} md={12}>
                <TestimonialTemTwo testimonials={testimonials} />
              </Grid>
            )}
          </Grid>
          {/* {!isPurchased && !isMobileSidebarNeeded && (
            <Grid item md={4} sm={12} xs={12} style={{ paddingTop: '1rem' }}>
              <CustomPurchaseBlock
                isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                brandingColors={brandingColors}
                onJoin={setShouldRedirect}
              />
            </Grid>
          )}{' '} */}
          {/* {!!isTestimonialsEnabled && (
            <Grid item xs={12}>
              <TestimonialsBlock />
            </Grid>
          )}
          {isEnrollmentVisible && (
            <Grid item xs={12}>
              <EnrollmentBlock enrollment={enrollment} />
            </Grid>
          )}
          {isPurchaseBlock && currentRole !== UserRoles.cohealer && !isPurchased && (
            <Grid item xs={12}>
              <PurchaseBlock onJoin={setShouldRedirect} />
            </Grid>
          )}
          {!isPurchaseBlock &&
            !(paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREE) && paymentInfo.paymentOptions.length === 1) && (
              <Grid item xs={12}>
                <AboutPaymentBlock
                  contribution={contribution}
                  earnedRevenue={earnedRevenue}
                  paymentInfo={paymentInfo}
                />
              </Grid>
            )} */}
        </Grid>
        {currentRole === UserRoles.cohealer && <Popup applyTheming />}
      </>
    )
  );
}

const mapStateToProps = ({ contributions: { activeContribution, loading }, account }) => ({
  contribution: activeContribution || {},
  loading,
  currentRole: account?.currentRole,
});

const actions = {
  getClientContribution: contributionActions.fetchClientContribution,
  getCohealerContribution: contributionActions.fetchCohealerContribution,
};

AboutContribution.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
  contribution: PropTypes.objectOf(PropTypes.any),
  getClientContribution: PropTypes.func.isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  currentRole: PropTypes.string.isRequired,
};

AboutContribution.defaultProps = {
  contribution: {},
};

export default connect(mapStateToProps, actions)(AboutContribution);
