import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'components/UI/Loader';

import { BasicInformationPage, ContributionDetailsPage, ReviewAndSubmitPage, SessionsPage, PaymentPage } from 'pages';
import { useHeader, useRouter } from 'hooks';
import * as contributionActions from 'actions/contributions';
import ThankYouPreviewPage from './ThankYouPreviewPage';

export const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 16px' : '30px 35px')};
`;

function CreateContributionContainer({ match: { path }, resetContribution, updating }) {
  const { location } = useRouter();
  useHeader(location.pathname.includes('/thankyoupreviewpage') ? '' : 'Create a Contribution');

  useEffect(() => {
    resetContribution();
  }, [resetContribution]);

  return (
    <>
      {updating && <Loader />}
      <Switch>
        <Route path={`${path}/basic`} exact component={BasicInformationPage} />
        <Route path={`${path}/basic/:id`} exact component={BasicInformationPage} />
        <Route path={`${path}/details`} exact component={ContributionDetailsPage} />
        <Route path={`${path}/sessions`} exact component={SessionsPage} />
        <Route path={`${path}/payment`} exact component={PaymentPage} />
        <Route path={`${path}/review`} exact component={ReviewAndSubmitPage} />
        <Route path={`${path}/:id?/thankyoupreviewpage`} exact component={ThankYouPreviewPage} />
        <Redirect to={`${path}/basic`} />
      </Switch>
    </>
  );
}

CreateContributionContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
  getContributionFromLS: PropTypes.func.isRequired,
  updating: PropTypes.bool,
};

CreateContributionContainer.defaultProps = {
  updating: false,
};

const actions = {
  getContributionFromLS: contributionActions.getLastDraftContribution,
  resetContribution: contributionActions.resetContribution,
};

const mapStateToProps = ({ contributions }) => ({
  updating: contributions.updating,
});

export default connect(mapStateToProps, actions)(CreateContributionContainer);
