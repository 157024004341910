import React, { useEffect, useRef, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { colors } from 'utils/styles';
// import { useStyles } from '../../../../../hooks/useStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Input from 'components/FormUI/Input';
import MaskedInput from 'components/FormUI/MaskedInput';
import { LabelText } from 'components/UI/Text/TextStyles';
import { StyledLink, StyledText } from 'pages/Auth/SignInPage';
import * as countryActions from 'actions/country';
import Select from 'components/FormUI/Select';
import {
  ACCOUNT_FORM_HEADERS,
  ACCOUNT_FORM_FIELDS,
  RESTORE_PASS,
  PURCHASE_MODAL_STEPS,
} from '../PurchaseModal.constants';
import * as timeZoneActions from 'actions/timeZone';

import './AccountForm.scss';
import { theme } from 'styles/theme';
import { getThemedColors } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import useRouter from 'hooks/useRouter';

export const useStyles = makeStyles(theme => ({
  signCanva: {
    width: '100%',
    height: '200px',
  },
}));

function AccountForm({
  mobileView,
  handleChange,
  touched,
  errors,
  step,
  timeZoneLoading,
  timeZones,
  fetchTimeZones,
  isElectronicSignatureActive,
  isMonthlySessionSubscription,
  setDisableValue,
  setFieldValue,
  colorToUse,
  formRef,
  customWaiverTemplateName,
  customWaiverId,
}) {
  const { domain } = useRouter();
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const { countries, loading: countriesLoading } = useSelector(state => state?.country);
  const { states } = useSelector(state => state?.states);
  const dispatch = useDispatch();
  const [timeZoneArray, setTimeZoneArray] = useState([]);
  const [statesArray, setStatesArray] = useState([]);
  const isShowInput = (step, input) => {
    const visible = {
      init: [ACCOUNT_FORM_FIELDS.email],
      join: [ACCOUNT_FORM_FIELDS.email],
      loggedIn: [ACCOUNT_FORM_FIELDS.sessionReminder],
      joinLogin: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.signature,
        RESTORE_PASS,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.sessionReminder,
      ],
      login: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.signature,
        RESTORE_PASS,
        ACCOUNT_FORM_FIELDS.timeZoneId,
      ],
      joinCreate: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.sessionReminder,
        ACCOUNT_FORM_FIELDS.phone,
      ],
      create: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.sessionReminder,
        ACCOUNT_FORM_FIELDS.phone,
      ],
      joinEnrollmentForm: [],
      enrollmentForm: [],
    };
    return visible[step].includes(input);
  };
  // const classNames = useStyles();
  const confirmEmailRef = useRef(null);
  const emailRef = useRef(null);
  const canvasRef = useRef();
  const classNames = useStyles();
  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);

  useEffect(() => {
    const disablePaste = e => {
      e.preventDefault();
    };

    if ((!timeZones || timeZones.length === 0) && !timeZoneLoading) {
      fetchTimeZones();
    }
    if (!countries || (!countries?.length && !countriesLoading)) {
      dispatch(countryActions.fetchCountries());
    }
    if (!states || !states.length) {
      dispatch(countryActions.fetchStates());
    }
    const confirmEmailInput = confirmEmailRef.current;
    if (!confirmEmailInput) return;

    confirmEmailInput.addEventListener('paste', disablePaste);
    return () => {
      confirmEmailInput.removeEventListener('paste', disablePaste);
    };
  }, [step]);

  useEffect(() => {
    if (emailRef.current && step !== PURCHASE_MODAL_STEPS.init) {
      emailRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [step, timeZones, fetchTimeZones]);

  const clearSign = () => {
    if (canvasRef?.current) {
      canvasRef.current.clear();
      setDisableValue(true);
      setFieldValue(ACCOUNT_FORM_FIELDS.signature, null);
    }
  };

  return (
    <Grid item container xs={12} className="my-3">
      <Grid item xs={12}>
        <LabelText>{ACCOUNT_FORM_HEADERS[step]}</LabelText>
        <br />
        <br />
      </Grid>
      <div className="top-note">
        <p>
          Login or create an account to complete your booking. You’ll receive a calendar invite & have access to the
          upcoming session.
        </p>
      </div>
      <Grid item container xs={12} justify="space-between">
        <Grid item container spacing={2} sm={6} xs={12} style={{ display: 'block' }}>
          <Grid item xs={12}>
            <Input
              fullWidth
              name={ACCOUNT_FORM_FIELDS.email}
              type="text"
              label="Email"
              error={touched.Email && errors.Email}
              ref={emailRef}
            />
          </Grid>
          {isShowInput(step, ACCOUNT_FORM_FIELDS.confirmEmail) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.confirmEmail}
                type="email"
                label="Confirm email"
                onChange={handleChange}
                error={touched.ConfirmEmail && errors.ConfirmEmail}
                ref={confirmEmailRef}
              />
            </Grid>
          )}
          {isShowInput(step, ACCOUNT_FORM_FIELDS.password) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.password}
                type="password"
                label={step === 'create' ? 'Create a Password' : 'Password'}
                onChange={handleChange}
                error={touched.Password && errors.Password}
              />
            </Grid>
          )}
        </Grid>
        {isShowInput(step, RESTORE_PASS) && (
          <Grid item xs={12}>
            <StyledLink onClick={() => redirectToAppIfIsCoachDomain(domain, '/auth/restore-pass')}>
              I forgot my password
            </StyledLink>
          </Grid>
        )}
        <Grid item container spacing={2} sm={6} xs={12}>
          {isShowInput(step, ACCOUNT_FORM_FIELDS.firstName) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.firstName}
                type="text"
                label="First name"
                onChange={handleChange}
                error={touched.FirstName && errors.FirstName}
              />
            </Grid>
          )}
          {isShowInput(step, ACCOUNT_FORM_FIELDS.lastName) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.lastName}
                type="text"
                label="Last name"
                onChange={handleChange}
                error={touched.LastName && errors.LastName}
              />
            </Grid>
          )}
          {contribution.paymentType === 'Simple' &&
            contribution.taxType != 'No' &&
            isInviteToJoin === null &&
            isShowInput(step, ACCOUNT_FORM_FIELDS.country) &&
            !isMonthlySessionSubscription && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontSize: '20px', color: themedColor }}>
                    Where are you located?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    label="Country of Residence"
                    name={ACCOUNT_FORM_FIELDS.country}
                    labelId="country"
                    value={formRef?.current?.values?.countryId}
                    onChange={e => {
                      const filteredObjects = states.filter(obj => obj.countryId === e.target.value);
                      const filteredCountry = countries.find(obj => obj.id === e.target.value);
                      const filteredTimezone = timeZones.filter(obj => obj.countryId === e.target.value);
                      if (filteredObjects.length >= 1) {
                        setStatesArray(filteredObjects);
                        if (e.target.value != '60b8ddb57205057e7ce2b861') {
                          setTimeZoneArray(filteredTimezone);
                        }
                      } else if (filteredObjects.length === 0) {
                        setStatesArray(filteredObjects);
                        if (filteredTimezone.length === 0) {
                          setTimeZoneArray(timeZones);
                        } else if (filteredTimezone.length === 1 && filteredCountry.id != '60b8ddb57205057e7ce2b861') {
                          setTimeZoneArray(filteredTimezone);
                          setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
                        } else {
                          setTimeZoneArray(filteredTimezone);
                        }
                      }
                      if (filteredCountry.id === '60b8ddb57205057e7ce2b861') {
                        setTimeZoneArray(filteredTimezone);
                        setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
                        setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                      }
                      setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                    }}
                    fullWidth
                    MenuProps={{
                      PaperProps: {
                        style: {
                          color: themedColor,
                          backgroundColor: themedCardBackgroundColor,
                        },
                      },
                    }}
                    items={
                      countries?.length > 0
                        ? countries.map(timeZone => {
                            return { value: timeZone.id, title: timeZone.name };
                          })
                        : []
                    }
                  />
                </Grid>
              </>
            )}
          {contribution.taxType != 'No' &&
            isInviteToJoin === null &&
            formRef?.current?.values?.countryId === '60b8ddb57205057e7ce2b861' &&
            statesArray.length > 1 &&
            !isMonthlySessionSubscription && (
              <Grid item xs={12}>
                <Select
                  label="State"
                  name={ACCOUNT_FORM_FIELDS.state}
                  labelId="stateCode"
                  value={formRef?.current?.values?.stateCode}
                  onChange={e => {
                    const filteredState = states.find(obj => obj.alpha2Code === e.target.value);
                    const filteredObjects = timeZones.filter(obj => obj.statesIds.includes(filteredState.id));
                    if (filteredObjects.length >= 1) {
                      if (filteredObjects.length === 1) {
                        setTimeZoneArray(filteredObjects);
                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                      } else {
                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                        setTimeZoneArray(filteredObjects);
                      }
                    } else {
                      setTimeZoneArray(timeZones);
                    }
                    setFieldValue(ACCOUNT_FORM_FIELDS.state, filteredState.alpha2Code);
                  }}
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        color: themedColor,
                        backgroundColor: themedCardBackgroundColor,
                      },
                    },
                  }}
                  items={
                    statesArray?.length > 0
                      ? statesArray.map(timeZone => {
                          return { value: timeZone.alpha2Code, title: timeZone.name };
                        })
                      : []
                  }
                />
              </Grid>
            )}
          {contribution.paymentType === 'Simple' &&
            contribution.taxType != 'No' &&
            isShowInput(step, ACCOUNT_FORM_FIELDS.country) &&
            isInviteToJoin === null &&
            timeZoneArray.length > 1 &&
            formRef?.current?.values?.countryId != null && (
              <>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    MenuProps={{
                      PaperProps: {
                        style: {
                          color: themedColor,
                          backgroundColor: themedCardBackgroundColor,
                        },
                      },
                    }}
                    itemTextColor={themedColor}
                    name={ACCOUNT_FORM_FIELDS.timeZoneId}
                    onChange={e => {
                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                      const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                      // setColorForSelect(color);
                    }}
                    value={formRef?.current?.values?.TimeZoneId}
                    items={
                      timeZoneArray?.length > 0
                        ? timeZoneArray.map(timeZone => {
                            return { value: timeZone.countryName, title: timeZone.name };
                          })
                        : []
                    }
                  />
                </Grid>
              </>
            )}
          {((contribution.paymentType === 'Simple' && contribution.taxType === 'No') ||
            contribution.paymentType === 'Advance' ||
            isInviteToJoin != null) &&
            isShowInput(step, ACCOUNT_FORM_FIELDS.timeZoneId) && (
              <Grid item xs={12}>
                <Select
                  label="Timezone"
                  name={ACCOUNT_FORM_FIELDS.timeZoneId}
                  fullWidth
                  onChange={handleChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        color: themedColor,
                        backgroundColor: themedCardBackgroundColor,
                      },
                    },
                  }}
                  itemTextColor={themedColor}
                  items={
                    timeZones?.length > 0
                      ? timeZones.map(timeZone => {
                          return { value: timeZone.countryName, title: timeZone.name };
                        })
                      : []
                  }
                />
              </Grid>
            )}
        </Grid>
        {isElectronicSignatureActive && customWaiverId === null && isShowInput(step, ACCOUNT_FORM_FIELDS.signature) && (
          <Grid item xs={12}>
            <div
              style={{
                position: 'relative',
                width: mobileView ? '100%' : '48%',
                border: '1px solid darkgray',
                marginTop: '50px',
              }}
            >
              <SignatureCanvas
                ref={canvasRef}
                penColor="black"
                canvasProps={{ className: classNames.signCanva }}
                onEnd={() => {
                  setDisableValue(false);
                  if (canvasRef?.current) {
                    const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                    setFieldValue(ACCOUNT_FORM_FIELDS.signature, val);
                  }
                }}
              />
              <div
                type="button"
                onClick={clearSign}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '5px',
                  right: '10px',
                  // color: colorToUse?.PrimaryColorCode,
                  fontWeight: 'bold',
                }}
              >
                Clear
              </div>
            </div>
            <p
              style={{
                marginBottom: '0px',
                fontSize: '12px',
                marginLeft: '5px',
                color: colorToUse?.PrimaryColorCode,
              }}
            >
              Sign Here To Join
            </p>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

AccountForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  step: PropTypes.string.isRequired,
  timeZoneId: PropTypes.string,
  timeZone: PropTypes.shape({
    timeZones: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  timeZoneLoading: PropTypes.bool.isRequired,
  fetchTimeZones: PropTypes.func.isRequired,
};

const mapStateToProps = ({ timeZone }) => ({
  timeZones: timeZone?.timeZones,
  timeZoneLoading: timeZone?.loading,
});

const actions = {
  fetchTimeZones: timeZoneActions.fetchTimeZones,
};

export default connect(mapStateToProps, actions)(AccountForm);
