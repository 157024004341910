import React, { useEffect, useMemo, useState } from 'react';
import { useHeader } from 'hooks/usePageActions';
import classes from './AllCampaigns.module.scss';
import { Grid, Tooltip, useMediaQuery } from '@material-ui/core';
import IntroCard from '../Components/IntroCrad/IntroCrad';
import Silder from '../Components/ImageSilder/Silder';
import CrousalItem1 from 'assets/CrousalItem1.png';
import CrousalItem2 from 'assets/carousel2.png';
import CrousalItem3 from 'assets/carousel3.png';

import AddLogoImage from 'assets/addlogoimg.png';
import CreateSegmentImage from 'assets/createsegmentimg.png';
import AddTagImage from 'assets/addtagimg.png';

import PrevCampaigns from '../Campaigns/PrevCampaigns';
import useRouter from 'hooks/useRouter';
import PaginationBar from '../Components/PaginationBar/PaginationBar';
import useAccount from 'hooks/useAccount';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import Loader from 'components/UI/Loader';
import { PER_PAGE_CAMPAIGNS_COUNT } from '../Styles/Constants/constants';
import Modal from 'components/UI/Modal';
import { isEmpty } from 'lodash';
import moment from 'moment';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';
import { fetchDomains } from 'actions/emailMarketing.actions';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';
import { localeAwareNumberFormatting } from 'utils/utils';
import Analytics from '../Components/Analytics/Analytics';
import { getTotalSentEmails } from 'pages/CreateCampaign/Services/CreateCampaign.service';

const StyledBetaTag = styled.span`
  font-family: Avenir;
  font-size: 13px;
  font-style: italic;
  font-weight: 800;
  line-height: 16px;
  border-radius: 4px;
  padding: 2px 6px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#215c73')};
  color: white;
`;

const CROUSAL_ITEMS = [
  {
    src: AddLogoImage,
  },
  {
    src: CreateSegmentImage,
  },
  {
    src: AddTagImage,
  },
];

const StyledMainTitleContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
`;
const StyledSubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  .subtitle-text {
    color: var(--Cohere-Greys-Text, #4a4a4a);
    font-family: Avenir;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 171.429% */
  }
  .subtitle-values {
    color: var(--Cohere-Primary-Blue, #215c73);
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 900;
    line-height: 1.5rem; /* 150% */
  }
`;
const StyledDivider = styled.div`
  height: 0.0625rem;
  width: 100%;
  margin: 1.5rem 0;
  background: #dfe3e4;
`;

const AllCampaigns = ({ createCampaignBtnClassName }) => {
  useHeader('Emails');
  const createCampaignBtnClass = `${classes.createCampaignBtn} ${createCampaignBtnClassName}`;
  const { history } = useRouter();
  const { campaignsCollection: campaigns, isLoading, campaignNotification } = useCampaign();
  const { verifiedSendingAddresses, isLoading: domainsLoading } = useEmailMarketing();
  const { user } = useAccount();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [showList, setShowList] = useState(false);
  const [searchedKeyword, setSearchedKeyword] = useState('');
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [emailSubscription, setEmailSubscription] = useState({
    loading: false,
  });
  const isSendGridConnected = user?.isSendGridConnected;
  const indicatorIconButtonProps = { style: { color: '#d9d9d9' } };
  const activeIndicatorIconButtonProps = { style: { color: '#215c73' } };
  const indicatorContainerProps = { style: { marginTop: '0px' } };
  const showIntroPage = !isSendGridConnected || verifiedSendingAddresses.length === 0;

  const mobileView = useMediaQuery('(max-width: 1200px)');

  const handleCreateCampaignClick = () => {
    history.push('/create-campaign/title');
  };

  const getFilteredCampaigns = (campaigns = [], filters = {}) => {
    const fromDate = moment(filters?.fromDate);
    const toDate = moment(filters?.toDate);
    let fetchedToDate = toDate.set({
      year: toDate.year(),
      month: toDate.month(),
      date: toDate.date(),
      hour: 23,
      minute: 59,
      second: 0,
    });
    let fetchedFromDate = fromDate.set({
      year: fromDate.year(),
      month: fromDate.month(),
      date: fromDate.date(),
      hour: 0,
      minute: 0,
      second: 0,
    });
    if (filters?.status != null && filters?.toDate === null && filters?.fromDate === null) {
      return campaigns.filter(campaign => filters?.status?.includes(campaign?.status));
    }
    if (
      filters?.status[0] === null &&
      filters?.status.length === 1 &&
      filters?.toDate === null &&
      filters?.fromDate != null
    ) {
      return campaigns.filter(campaign => {
        const campaignCreateTime =
          campaign.isSechedule === true ? moment(campaign?.scheduleDateTime) : moment(campaign?.createTime);
        const isAfterFromDate = campaignCreateTime.isSameOrAfter(fetchedFromDate);
        return isAfterFromDate;
      });
    }
    if (
      filters?.status[0] === null &&
      filters?.status.length === 1 &&
      filters?.toDate != null &&
      filters?.fromDate === null
    ) {
      return campaigns.filter(campaign => {
        const campaignCreateTime =
          campaign.isSechedule === true ? moment(campaign?.scheduleDateTime) : moment(campaign?.createTime);
        const isBeforeToDate = campaignCreateTime.isSameOrBefore(fetchedToDate);
        return isBeforeToDate;
      });
    }
    if (
      filters?.status[0] === null &&
      filters?.status.length === 1 &&
      filters?.toDate != null &&
      filters?.fromDate != null
    ) {
      return campaigns.filter(campaign => {
        const campaignCreateTime =
          campaign.isSechedule === true ? moment(campaign?.scheduleDateTime) : moment(campaign?.createTime);
        const isAfterFromDate = campaignCreateTime.isSameOrAfter(fetchedFromDate);
        const isBeforeToDate = campaignCreateTime.isSameOrBefore(fetchedToDate);
        return isAfterFromDate && isBeforeToDate;
      });
    }
    if (filters?.status.length > 1 && filters?.toDate != null && filters?.fromDate === null) {
      return campaigns.filter(campaign => {
        const campaignCreateTime =
          campaign.isSechedul === true ? moment(campaign?.scheduleDateTime) : moment(campaign?.createTime);
        const isBeforeToDate = campaignCreateTime.isSameOrBefore(fetchedToDate);
        const isStatusMatch = filters?.status?.includes(campaign?.status);
        return isBeforeToDate && isStatusMatch;
      });
    }
    if (filters?.status.length > 1 && filters?.toDate === null && filters?.fromDate != null) {
      return campaigns.filter(campaign => {
        const campaignCreateTime =
          campaign.isSechedule === true ? moment(campaign?.scheduleDateTime) : moment(campaign?.createTime);
        const isAfterFromDate = campaignCreateTime.isSameOrAfter(fetchedFromDate);
        const isStatusMatch = filters?.status?.includes(campaign?.status);
        return isAfterFromDate && isStatusMatch;
      });
    }
    if (filters?.status.length > 1 && filters?.toDate != null && filters?.fromDate != null) {
      return campaigns.filter(campaign => {
        const campaignCreateTime =
          campaign.isSechedule === true ? moment(campaign?.scheduleDateTime) : moment(campaign?.createTime);
        const isAfterFromDate = campaignCreateTime.isSameOrAfter(fetchedFromDate);
        const isBeforeToDate = campaignCreateTime.isSameOrBefore(fetchedToDate);
        const isStatusMatch = filters?.status?.includes(campaign?.status);
        return isAfterFromDate && isBeforeToDate && isStatusMatch;
      });
    }
  };

  const getSearchedCampaigns = (campaigns = [], keyword = '') => {
    return campaigns.filter(
      campaign =>
        campaign?.title?.toLowerCase()?.includes(keyword) ||
        campaign?.fromEmail?.toLowerCase()?.includes(keyword) ||
        campaign?.status?.toLowerCase()?.includes(keyword),
    );
  };

  const getSearchedAndFilteredCampaigns = (campaigns, keyword, filters) => {
    if (campaigns?.length === 0 || (keyword?.length === 0 && isEmpty(filters))) {
      return campaigns;
    } else if (keyword?.length > 0 && !isEmpty(filters)) {
      const searchedCampaigns = getSearchedCampaigns(campaigns, keyword);
      const searchedAndFilterCampaigns = getFilteredCampaigns(searchedCampaigns, filters);
      return searchedAndFilterCampaigns;
    } else if (keyword?.length > 0) {
      return getSearchedCampaigns(campaigns, keyword);
    } else {
      return getFilteredCampaigns(campaigns, filters);
    }
  };

  const pageCount = useMemo(() => {
    const count = Math.ceil(
      getSearchedAndFilteredCampaigns(campaigns, searchedKeyword, appliedFilters)?.length / PER_PAGE_CAMPAIGNS_COUNT,
    );
    return count;
  }, [campaigns, searchedKeyword, appliedFilters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageCount]);

  useEffect(() => {
    setEmailSubscription({ ...(emailSubscription || {}), loading: true });
    getTotalSentEmails()
      .then(data => {
        const { totalSendEmail: totalSentEmails, totalEmails: totalAllowedEmails } = data;
        setEmailSubscription({ ...(emailSubscription || {}), totalSentEmails, totalAllowedEmails, loading: false });
      })
      .catch(() => setEmailSubscription({ ...(emailSubscription || {}), loading: false }));
  }, []);

  /* if (domainsLoading) {
    return <Loader />;
  } */

  return (
    <div className={classes.container}>
      {!isEmpty(campaignNotification) && <Notification notification={campaignNotification} />}
      <div className={classes.headerContainer}>
        <StyledMainTitleContainer>
          <div className={classes.heading}>
            One-Time Email Campaigns <StyledBetaTag>Beta</StyledBetaTag>
          </div>
          <StyledSubtitleContainer>
            <div className="subtitle-text">Emails Included in the Current Month's Subscription:</div>
            {emailSubscription?.loading ? (
              <span>Loading...</span>
            ) : (
              <div className="subtitle-values">
                {localeAwareNumberFormatting(emailSubscription?.totalSentEmails || 0)} /{' '}
                {localeAwareNumberFormatting(emailSubscription?.totalAllowedEmails || 0)}
              </div>
            )}
          </StyledSubtitleContainer>
        </StyledMainTitleContainer>
        <Tooltip title={showIntroPage ? 'Please connect your domain first, then this will be enabled.' : ''} arrow>
          <button
            className={createCampaignBtnClass}
            disabled={showIntroPage}
            onClick={handleCreateCampaignClick}
            style={{ width: '187px', paddingLeft: '0px', paddingRight: '0px' }}
          >
            Create a Campaign
          </button>
        </Tooltip>
      </div>
      {showIntroPage ? (
        <div className={classes.bodyContainer}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <IntroCard />
            </Grid>
            <Grid container item md={6} xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
              <Grid item md={11} xs={12} className={classes.slider}>
                <Silder
                  items={CROUSAL_ITEMS}
                  navButtonsAlwaysInvisible={true}
                  indicatorIconButtonProps={indicatorIconButtonProps}
                  activeIndicatorIconButtonProps={activeIndicatorIconButtonProps}
                  indicatorContainerProps={indicatorContainerProps}
                  interval={7000}
                  stopAutoPlayOnHover={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Analytics />
              <StyledDivider />
              <PrevCampaigns
                campaigns={getSearchedAndFilteredCampaigns(campaigns, searchedKeyword, appliedFilters)?.slice(
                  (currentPage - 1) * PER_PAGE_CAMPAIGNS_COUNT,
                  currentPage * PER_PAGE_CAMPAIGNS_COUNT,
                )}
                setSearchedKeyword={setSearchedKeyword}
                setIsFilterApplied={setIsFilterApplied}
                setAppliedFilters={setAppliedFilters}
                isFilterApplied={isFilterApplied}
                appliedFilters={appliedFilters}
                searchedKeyword={searchedKeyword}
                setShowList={setShowList}
              />
              {campaigns?.length > 0 && !showList && (
                <PaginationBar
                  page={currentPage}
                  count={pageCount}
                  handleChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      <Modal
        isOpen={mobileView}
        title="Can't Access"
        reduceCancelButton
        hiddenCancel
        onCloseClick={() => {}}
        onSubmit={() => {
          history.push('/dashboard');
        }}
      >
        For optimal performance and full functionality, we recommend using a browser on a desktop or laptop computer.
      </Modal>
    </div>
  );
};

export default AllCampaigns;
