import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import UploadThumbnail from 'pages/CreateContribution/components/UploadThumbnail';
import { useContribution } from 'pages/ContributionView/hooks';
import { useRouter } from 'hooks';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { updateContributionActions } from 'actions/contributions';
import { useDispatch } from 'react-redux';
import VideoCarousel from 'components/Video/VideoCarousel';
import { isEqual } from 'lodash';
import ErrorBoundary from 'components/ErrorBoundary';

const StyledVideoContainer = styled.div`
  margin-bottom: 1.25rem;
`;
const LiveSessionVideo = ({ videoSources, session, sessionTime }) => {
  const [isOpenUploadThumbnail, setIsOpenUploadThumbnail] = useState(false);
  const [currentRoomId, setCurrentRoomId] = useState(null);
  const [currentVideoPoster, setCurrentVideoPoster] = useState();
  const contribution = useContribution();
  const { query } = useRouter();
  const isEditing = Boolean(query.id);
  const { onSave: onSaveSelfPacedThumnail } = useSaveContribution(isEditing);
  const dispatch = useDispatch();
  const updateSessionthumbnail = async thumbnail => {
    const sessionId = session.id;
    const sessionTimeId = sessionTime.id;
    const updateContribution = {
      ...contribution,
      sessions: contribution.sessions.map(session => {
        if (session.id === sessionId) {
          return {
            ...session,
            sessionTimes: session.sessionTimes.map(sessionTime => {
              if (sessionTime.id === sessionTimeId) {
                return {
                  ...sessionTime,
                  recordingInfos: sessionTime.recordingInfos.map(recordingInfo => {
                    if (recordingInfo.roomId === currentRoomId) {
                      return {
                        ...recordingInfo,
                        thumbnailUrl: thumbnail,
                      };
                    }
                    return recordingInfo;
                  }),
                };
              }
              return sessionTime;
            }),
          };
        }
        return session;
      }),
    };
    dispatch(updateContributionActions.success(updateContribution));
    await onSaveSelfPacedThumnail(updateContribution);
  };
  return (
    <>
      <ErrorBoundary>
        <StyledVideoContainer>
          <VideoCarousel
            videoSources={videoSources}
            uploadThumbnail={setIsOpenUploadThumbnail}
            setCurrentRoomId={setCurrentRoomId}
            poster={contribution?.previewContentUrls?.at(0)}
            setCurrentVideoPoster={setCurrentVideoPoster}
          />
        </StyledVideoContainer>
        {isOpenUploadThumbnail && (
          <UploadThumbnail
            setIsOpen={setIsOpenUploadThumbnail}
            isOpen={isOpenUploadThumbnail}
            title="Select a Thumbnail"
            thumbnailValue={currentVideoPoster}
            fieldName="selfPacedThumbnailUrl"
            isPreviewMode
            setFieldValue={null}
            updateSessionthumbnail={updateSessionthumbnail}
            modalProps={
              contribution?.isDarkModeEnabled
                ? {
                    applyTheming: true,
                    // isCreatingContribution: false, brandingColor: false
                  }
                : {}
            }
          />
        )}
      </ErrorBoundary>
    </>
  );
};

export default memo(LiveSessionVideo, isEqual);
