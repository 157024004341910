import React from 'react';
import classes from './Notification.module.scss';
import CrossIconSvg from './CrossIconSvg';
import CheckIconSvg from './CheckIconSvg';
import { useDispatch } from 'react-redux';
import { setCampaignNotification } from 'actions/campaign';
import { isEmpty } from 'lodash';

const Notification = ({ notification }) => {
  const dispatch = useDispatch();
  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <div className={classes.heading_container}>
          <div className={classes.check_box}>
            <CheckIconSvg />
          </div>
          <div className={classes.heading}>{notification?.heading}</div>
        </div>
        <div
          className={classes.cross_btn}
          onClick={() => {
            dispatch(setCampaignNotification(''));
          }}
        >
          <CrossIconSvg />
        </div>
      </div>
      <div className={classes.message}>{notification?.message}</div>
    </div>
  );
};

export default Notification;
