import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as paidTierActions from 'actions/paidTierOptions';
import * as paidTierService from 'services/paidTierOptions.service';
import { purchaseAcademyContribution } from 'services/paidTierOptions.service';
import { cohereAcademyIsEnableSelector } from 'selectors/user';
import { cancelMembership } from 'services/purchase.service';
import * as paidTier from 'selectors/paidTier';
import { setPaidTierContribution, setActivePaidTierContributions } from 'actions/paidTierOptions';
import { getBoughtByType } from 'services/contributions.service';
import { ContributionType, SIGNUP_TYPES } from 'helpers/constants';
import usePaidTier from 'hooks/usePaidTier';
import Dictionary from '../dictionary.json';
import { useStyles, CustomList, CohereAcademyTitle, CohereAcademyP } from '../Billing.styles';
import AdvertismentCard from '../../../../components/AdvertismentCard';
import LiWithIcon from '../../../../components/PlanCard/AdvantagesItem';
import { useAccount, useShallowEqualSelector } from 'hooks/index';
import { PAID_TIER_TITLES } from '../../../../constants';

const methodsType = {
  enroll: 'enroll',
  leave: 'leave',
};

const BillingCohereAcademy = ({ setIsShowAcademyWarning }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { signupType } = useSelector(state => state.account);
  const { user } = useAccount();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);

  const shouldBeDisabled =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    user.signupType === SIGNUP_TYPES.TYPE_B ||
    user.signupType === SIGNUP_TYPES.TYPE_A;

  const { academyContributions, activeAcademyContributions } = usePaidTier();
  const isEnable = useSelector(cohereAcademyIsEnableSelector);

  const { title, description, includedList } = Dictionary.cohereAcademy;
  const [isShowLoader, setIsShowLoader] = useState({
    id: null,
    status: false,
  });
  const updateActiveContributionsState = useCallback(() => {
    return (
      getBoughtByType(ContributionType.contributionMembership)
        // .then(filterMembership)
        .then(matchedMemberships => {
          dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
        })
    );
  }, []);
  useEffect(() => {
    setIsShowLoader({
      id: null,
      status: false,
    });
  }, [activeAcademyContributions]);

  const enrollMembershipOnclick = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    if (isShowLoader.id) {
      return;
    }

    setIsShowLoader({
      id,
      status: true,
    });

    purchaseAcademyContribution(id)
      .then(() => {
        updateActiveContributionsState();

        setTimeout(() => {
          updateActiveContributionsState().then(() => {
            setIsShowLoader({
              id: null,
              status: false,
            });
          });
        }, 2000);
      })
      .catch(e => {
        setIsShowAcademyWarning(true);
        setIsShowLoader({
          id: null,
          status: false,
        });
      });
  };

  const leaveMembershipOnclick = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    if (isShowLoader.id) {
      return;
    }

    setIsShowLoader({
      id,
      status: true,
    });

    cancelMembership(id).then(() => {
      updateActiveContributionsState().then(() => {
        setIsShowLoader({
          id: null,
          status: false,
        });
      });
    });
  };

  return (
    <div className={classes.cohereAcademyContainer}>
      <div className={classes.cohereAcademy}>
        <div>
          <CohereAcademyTitle>{title}</CohereAcademyTitle>
          <CohereAcademyP>{description}</CohereAcademyP>
        </div>
        <div>
          <CustomList>
            {includedList.map(label => (
              <LiWithIcon key={label} label={label} padding="0px 0px 10px 0px" />
            ))}
          </CustomList>
        </div>
      </div>

      {academyContributions.map(item => (
        <Grid
          item
          xs={12}
          className={shouldBeDisabled || isEnable ? classes.disableMe : classes.aroundPaddings}
          key={item.id}
        >
          <Link to={`/contribution-view/${item.id}/about`} className={classes.cohereAcademyLink} key={item.id}>
            <AdvertismentCard
              key={item.id}
              userId={item.userId}
              img={item.image}
              title={item.title}
              author={item.serviceProviderName}
              theme={item?.categories[0]}
              handleEnrollOnclick={event => enrollMembershipOnclick(event, item.id)}
              isActive={activeAcademyContributions.some(el => el.id === item.id)}
              handleLeaveOnclick={event => leaveMembershipOnclick(event, item.id)}
              isShowLoader={item.id === isShowLoader.id ? isShowLoader : null}
              AvatarUrl={item.avatarUrl}
            />
          </Link>
        </Grid>
      ))}
    </div>
  );
};

export default BillingCohereAcademy;
