import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TOOLTIP } from '../../../../../constants';
import isEmpty from 'lodash/isEmpty';
import Modal from 'components/UI/Modal';
import TemplateOne from 'assets/congratulations.png';
import * as contributionActions from 'actions/contributions';
import * as userActions from 'actions/user';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import CoachImage from 'assets/chatlogo.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useHttp, useAccount } from 'hooks';
import { toRem, colors } from 'utils/styles';
import { usePlaidLink } from 'react-plaid-link';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import * as bankAccountsActions from 'actions/bankAccounts';
import Loader from 'components/UI/Loader';
import Grid from '@material-ui/core/Grid';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from 'components/FormUI/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import '../../Payment.scss';
import styled from 'styled-components';
import AddBankAccountPopup from './AddBankAccountPopup';
import { useLocation, useParams } from 'react-router-dom';
import BankAccountActionsOptionsButton from './BankAccountActionsOptionsButton';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import { useHistory } from 'react-router-dom';
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);
const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);

const StyledBadge = styled.span`
  background-color: ${colors.darkOceanBlue};
  color: white;
  padding: 4px 12px;
  font-size: ${toRem(11)};
  font-weight: 900;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
`;

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const BankAccountCard = ({ getBankAccounts, bankAccounts, accountsLoading, type }) => {
  const { request, loading } = useHttp();
  const [affilateAccounts, setaffilateAccounts] = useState([]);
  const [nonAffilateAccounts, setnonAffilateAccounts] = useState([]);
  const { user } = useAccount();
  const dispatch = useDispatch();
  const isUsaUser = user?.countryId === '60b8ddb57205057e7ce2b861';
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const [stepIndex, setStepIndex] = useState(3);
  const { state } = useLocation();
  const initialStep = state?.from === 'bankAccount';
  const showCompletedGetStart = useSelector(state => state.contributions.showCompletedGetStart);
  const refreshStep = showCompletedGetStart === 'bankAccountRefresh';
  const PopUpBank = useSelector(state => state.contributions.showPopUpBank);
  const [run, setRun] = useState(
    (user?.userProgressbarData?.IsBankAccountConnected === false && initialStep === true) ||
      (user?.userProgressbarData?.IsBankAccountConnected === true && refreshStep),
  );
  let joyRideHelpers;
  const [showModal, setShowModal] = useState(false);

  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const history = useHistory();
  const [contibId, setContibId] = useState(null);
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);

  const updateUserF = async () => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        IsBankAccountConnected: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (res.userProgressbarData?.IsPlanPurchased === false) {
        history.push('/account/payment', { from: 'verify' });
      } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
        history.push('/account/payment', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IntegrationsDone === false) {
        history.push('/account/integrations', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
        history.push('/account/profile');
      } else if (res?.userProgressbarData?.FirstContributionDone === false) {
        dispatch(contributionActions.setPopUp(true));
        dispatch(contributionActions.setShowCompletedGetStart('create'));
        history.push('/dashboard');
      } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
        history.push('/account/profile', { from: 'Manage Your Website Information' });
      } else if (res?.userProgressbarData?.InviteLink === false) {
        contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
      }
    });
    joyRideHelpers.skip();
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
      dispatch(contributionActions.setPopUpBank(false));
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);

    console.groupEnd();
  };

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  useEffect(() => {
    getBankAccounts();
  }, []);
  useEffect(() => {
    if (user.payoutsEnabled) {
      getBankAccounts();
    }
    userActions.getProfile(user.id, dispatch);
  }, [user.payoutsEnabled, getBankAccounts]);

  useEffect(() => {
    if (type === 'affiliate') {
      affiliateAccountsFilter();
    } else {
      nonaffiliateAccountsFilter();
    }
  }, [bankAccounts]);

  const fetchStripeToken = useCallback(
    (accessToken, accountId) =>
      request(
        '/api/payment/fetch-stripe-token',
        'POST',
        { accessToken, accountId },
        {
          'Content-Type': 'application/json',
        },
      ),
    [request],
  );

  const attachExternalAccount = useCallback(
    stripeToken =>
      request(
        '/api/Payment/attach-external-account',
        'POST',
        { stripeToken },
        {
          'Content-Type': 'application/json',
        },
      ),
    [request],
  );

  const onSuccess = useCallback(
    (token, metadata) => {
      request(
        '/api/payment/exchange',
        'POST',
        { publicToken: token },
        {
          'Content-Type': 'application/json',
        },
      ).then(({ accessToken }) => {
        fetchStripeToken(accessToken, metadata.account_id).then(
          ({ stripeToken }) => attachExternalAccount(stripeToken),
          userActions.getProfile(user.id, dispatch),
        );
      });
    },
    [request, fetchStripeToken, attachExternalAccount],
  );

  const config = {
    clientName: 'Cohere Inc.',
    env: process.env.REACT_APP_PLAID_ENVIRONMENT,
    product: ['auth', 'transactions'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  const [addStripeBankAccountDataPopup, setAddStripeBankAccountDataPopup] = useState(false);

  const remove = useCallback(
    bankAccountId => {
      request(
        'api/Payment/remove-bank-account',
        'POST',
        {
          bankAccountId,
        },
        {
          'Content-Type': 'application/json',
        },
      )
        .then(e => {
          getBankAccounts();
          userActions.getProfile(user.id, dispatch);
        })
        .catch(console.dir);
    },
    [request, getBankAccounts],
  );

  const setAsDefault = useCallback(
    bankAccountId => {
      request(
        'api/Payment/set-bank-account-as-default',
        'POST',
        {
          bankAccountId,
        },
        {
          'Content-Type': 'application/json',
        },
      )
        .then(e => {
          getBankAccounts();
          userActions.getProfile(user.id, dispatch);
        })
        .catch(console.dir);
    },
    [request],
  );
  useEffect(() => {
    if (
      (isEmpty(bankAccounts) === true || isEmpty(bankAccounts) === false) &&
      !user?.userProgressbarData?.IsBankAccountConnected
    ) {
      setRun(true);
    }
  }, []);

  const affiliateAccountsFilter = () => {
    const accountsArray = bankAccounts?.filter(item => item.isAffiliate === true);
    setaffilateAccounts(accountsArray);
  };

  const nonaffiliateAccountsFilter = () => {
    const accountsArrayNon = bankAccounts?.filter(item => item.isAffiliate === false);
    setnonAffilateAccounts(accountsArrayNon);
  };

  return (
    <>
      {loading && <Loader />}
      <Grid item xs={10} classes={{ root: 'card-container' }}>
        <Card id={'connectBank'} maxHeight>
          <CardHeader>
            <PageTitleSecond style={{ flexGrow: 1 }}>
              {type != 'affiliate' ? `Connect a bank account` : `Connect a bank to payout your affiliate revenue`}
            </PageTitleSecond>
            {!isEmpty(bankAccounts) && (
              <>
                <Button
                  autoWidth
                  variant={type === 'affiliate' ? 'secondary' : 'primary'}
                  style={{ marginLeft: '10px' }}
                  onClick={() => setAddStripeBankAccountDataPopup(true)}
                >
                  Manual Connect
                </Button>
                {/* {isUsaUser && (
                  <Button style={{ marginLeft: '10px' }} disabled={!ready} autoWidth variant="primary" onClick={open}>
                    Automatic Connect
                  </Button>
                )} */}
              </>
            )}
          </CardHeader>
          <CardBody className="card-body-container card-body-container__mobile">
            {!accountsLoading && (
              <>
                {isEmpty(bankAccounts) && (
                  <Grid container justify="space-between">
                    <Grid container lg={5} sm={12}>
                      Connect your bank account to transfer available funds.
                    </Grid>
                    <Grid container justify={downMd ? 'flex-start' : 'flex-end'} lg={7} sm={12}>
                      <Button
                        autoWidth
                        onClick={() => setAddStripeBankAccountDataPopup(true)}
                        style={{
                          marginRight: 12,
                          marginTop: downMd ? 12 : 0,
                        }}
                        variant={type === 'affiliate' ? 'secondary' : 'primary'}
                      >
                        Manual Connect
                      </Button>
                      <Tooltip
                        style={{ marginTop: '10px' }}
                        title="Please connect your account manually, if you are using cohere payment processor."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} />
                      </Tooltip>
                      {/* {isUsaUser && (
                        <Button
                          autoWidth
                          onClick={() => open()}
                          style={{ marginTop: downMd ? 12 : 0 }}
                          variant="primary"
                        >
                          Automatic Connect
                        </Button>
                      )} */}
                    </Grid>
                  </Grid>
                )}
                {type === 'affiliate'
                  ? !isEmpty(affilateAccounts) && (
                      <StyledTableContainer component={Paper} mobileView={mobileView}>
                        <Table className="" aria-label="contributions table">
                          <TableHead>
                            <TableRow>
                              <BoldTableCell>Bank Name</BoldTableCell>
                              <BoldTableCell>Account Number</BoldTableCell>
                              {affilateAccounts?.length > 1 && <BoldTableCell>Account Type</BoldTableCell>}
                              <BoldTableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {affilateAccounts.map(bankAccount => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {bankAccount.bankName}{' '}
                                    {bankAccount.isDefaultForCurrency && (
                                      <StyledBadge style={{ marginLeft: '10px' }}>Default</StyledBadge>
                                    )}
                                  </TableCell>
                                  <TableCell>*{bankAccount.last4}</TableCell>
                                  {bankAccounts?.length > 1 && (
                                    <TableCell>{bankAccount.isStandard ? 'Advance' : 'Simple'}</TableCell>
                                  )}
                                  <TableCell>
                                    {!bankAccount.isDefaultForCurrency && (
                                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <BankAccountActionsOptionsButton
                                          onSetAsDefault={() => setAsDefault(bankAccount.id)}
                                          onRemove={() => remove(bankAccount.id)}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </StyledTableContainer>
                    )
                  : !isEmpty(bankAccounts) && (
                      <StyledTableContainer component={Paper} mobileView={mobileView}>
                        <Table className="" aria-label="contributions table">
                          <TableHead>
                            <TableRow>
                              <BoldTableCell>Bank Name</BoldTableCell>
                              <BoldTableCell>Account Number</BoldTableCell>
                              <BoldTableCell>Account Type</BoldTableCell>
                              <BoldTableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {nonAffilateAccounts.map(bankAccount => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {bankAccount.bankName}{' '}
                                    {bankAccount.isDefaultForCurrency && (
                                      <StyledBadge style={{ marginLeft: '10px' }}>Default</StyledBadge>
                                    )}
                                  </TableCell>
                                  <TableCell>*{bankAccount.last4}</TableCell>
                                  {<TableCell>{bankAccount.isStandard ? 'Advance' : 'Simple'}</TableCell>}
                                  <TableCell>
                                    {!bankAccount.isDefaultForCurrency && (
                                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <BankAccountActionsOptionsButton
                                          onSetAsDefault={() => setAsDefault(bankAccount.id)}
                                          onRemove={() => remove(bankAccount.id)}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </StyledTableContainer>
                    )}
                <AddBankAccountPopup
                  addStripeBankAccountDataPopup={addStripeBankAccountDataPopup}
                  type={type}
                  type={type}
                  onCancel={async () => {
                    await getBankAccounts();
                    setAddStripeBankAccountDataPopup(false);
                    userActions.getProfile(user.id, dispatch);
                  }}
                />
              </>
            )}
          </CardBody>
        </Card>
        <Modal
          title=""
          isOpen={showModal}
          onCloseText="OK"
          hiddenCancel
          onSubmit={togglePopup}
          onCancel={togglePopup}
          disableFooter
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
            <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
            <div
              style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}
            >
              You are fully set up & ready to transform lives!
            </div>
            <Button
              autoWidth
              backgroundColor={'#C9B382'}
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
              onClick={togglePopup}
            >
              Let’s do this!
            </Button>
          </div>
        </Modal>
      </Grid>
    </>
  );
};

BankAccountCard.propTypes = {
  bankAccounts: PropTypes.arrayOf({
    bankName: PropTypes.string,
    last4: PropTypes.number,
  }).isRequired,
  accountsLoading: PropTypes.bool.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
};

const mapStateToProps = ({ bankAccounts }) => ({
  bankAccounts: bankAccounts?.bankAccounts,
  accountsLoading: bankAccounts?.loading,
});

const actions = {
  getBankAccounts: bankAccountsActions.fetchBalance,
};

export default connect(mapStateToProps, actions)(BankAccountCard);
