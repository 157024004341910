import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { ROUTES } from 'constants.js';

import { useAccount, useRouter } from 'hooks';
import usePaidTier from 'hooks/usePaidTier';
import { setActivePaidTierPlan } from 'actions/paidTierOptions';
import { useStyles } from '../Billing.styles';
import { cancelPaidTierOptions, getCurrentPlan } from '../../../../services/paidTierOptions.service';

import BillingDescription from './Billing.description';
import BillingPlans from './Billing.plans';
import BillingCohereAcademy from './Billing.cohereAcademy';

import { SuccessfulPurchaseModal } from '../../../../components/Modals/SuccessfulPurchaseModal';
import { FailedPurchaseModal } from '../../../../components/Modals/FailedPurchaseModal';
import PaidTierModal from '../../../../components/Modals/PaidTierModal';
import styled from 'styled-components';
import { SIGNUP_TYPES } from 'helpers/constants';
import { purchaseSuccessful } from 'actions/user';
import useShallowEqualSelector from '../../../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../../../constants';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
import { ContributionType } from 'helpers/constants';

const StyledGrid = styled(Grid)`
  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;
const CohealerBillingPage = ({ modalsApi }) => {
  const classes = useStyles();
  const { signupType } = useSelector(state => state.account);
  const { user } = useAccount();
  const { query, history } = useRouter();
  const dispatch = useDispatch();

  // const { isScalePlan } = usePaidTier();
  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });

  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;

  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);

  // Correct behavior - 100%
  const filterMembership = useCallback(
    res => {
      const filteredMemberships = [];

      academyContributions.forEach(item => {
        const matchedMembership = res.find(el => el.id === item.id);

        if (matchedMembership && matchedMembership.isPurchased) {
          filteredMemberships.push(item);
        }
        return true;
      });

      return filteredMemberships;
    },
    [academyContributions],
  );

  const updateActiveContributionsState = useCallback(() => {
    return getBoughtByType(ContributionType.contributionMembership)
      .then(filterMembership)
      .then(matchedMemberships => {
        dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
      });
  }, [filterMembership]);

  useEffect(() => {
    // this is a temp solution, we need to find a way to not repopulate it again if not needed
    // TODO: don't get paid tiers plans if the right version for existing user is already loaded
    // if (paidTierPlans.length) {
    //   return;
    // }
    setIsLoader(s => ({ ...s, paidTierPlans: true }));

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  useEffect(() => {
    setIsLoader(s => ({ ...s, academyMemberships: true }));

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
      setIsLoader(s => ({ ...s, academyMemberships: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

    updateActiveContributionsState().then(() => {
      setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
    });
  }, []);

  const { cancelletion_success, warning_before_cancellation, upgrade_impossible, upgrade_success } = modalsApi;

  const [paidTierSuccessModalOpen, setPaidTierSuccessModalOpen] = useState(query.success === 'true');
  const [paidTierFailedModalOpen, setPaidTierFailedModalOpen] = useState(query.success === 'false');
  const [isShowAcademyWarning, setIsShowAcademyWarning] = useState(false);

  const clearUrlSearch = () => {
    history.replace({
      search: '',
    });
  };

  const handleSuccessModalClose = () => {
    setPaidTierSuccessModalOpen(false);
    clearUrlSearch();
    dispatch(purchaseSuccessful('NONE'));
    history.push(ROUTES.DASHBOARD);
  };
  const handleFailedModalClose = () => {
    setPaidTierFailedModalOpen(false);
    clearUrlSearch();
  };

  const cancelSubsAfterConfirm = (userId, itemId) => {
    cancelPaidTierOptions(userId, itemId).then(() => {
      getCurrentPlan().then(data => {
        dispatch(setActivePaidTierPlan(data));
        cancelletion_success.setIsShowSuccessCancellationModal({
          isShow: true,
          label: `Your ${
            data?.paidTierOption?.displayName
          } plan has been successfully cancelled. These changes will go into effect on ${new Date(
            data?.endDateTime,
          ).toLocaleDateString('en-US')} & you will be on the free ${data?.nextPaidTierOption?.displayName} plan.`,
        });
      });
    });
  };

  const cancellationWarningHandleClose = () => {
    warning_before_cancellation.setIsShowBeforeCancellationWarning({
      isShow: false,
      label: '',
      userId: null,
      itemId: null,
    });
  };
  const cancellationWarningHandleSubmit = () => {
    cancelSubsAfterConfirm(
      warning_before_cancellation.isShowBeforeCancellationWarning.userId,
      warning_before_cancellation.isShowBeforeCancellationWarning.itemId,
    );

    warning_before_cancellation.setIsShowBeforeCancellationWarning({
      isShow: false,
      label: '',
      userId: null,
      itemId: null,
    });
  };

  return (
    <Grid container className={classes.root}>
      <BillingDescription />
      <BillingPlans isModalView={false} modalsApi={modalsApi} />

      <BillingCohereAcademy setIsShowAcademyWarning={setIsShowAcademyWarning} />

      {!isScalePlan && (
        <Grid
          container
          className={
            user.signupType === SIGNUP_TYPES.TYPE_B || signupType === SIGNUP_TYPES.TYPE_B
              ? classes.disableMe
              : classes.moreOptionsLink
          }
        >
          <Link to={ROUTES.ACCOUNT_COACH_BILLING}>See less options</Link>
        </Grid>
      )}

      {paidTierSuccessModalOpen && (
        <SuccessfulPurchaseModal isOpen={paidTierSuccessModalOpen} handleClose={handleSuccessModalClose} />
      )}

      {paidTierFailedModalOpen && (
        <FailedPurchaseModal isOpen={paidTierFailedModalOpen} handleClose={handleFailedModalClose} />
      )}

      {isShowAcademyWarning && (
        <PaidTierModal
          title="Warning"
          submitTitle="Ok"
          isOpen={isShowAcademyWarning}
          handleSubmit={() => setIsShowAcademyWarning(false)}
          handleClose={() => setIsShowAcademyWarning(false)}
          hiddenCancel
          label="To access the Academy, please subscribe to correct billing plan."
        />
      )}

      {warning_before_cancellation.isShowBeforeCancellationWarning.isShow && (
        <PaidTierModal
          title="Cancellation"
          submitTitle="Yes"
          isOpen={warning_before_cancellation.isShowBeforeCancellationWarning.isShow}
          handleClose={cancellationWarningHandleClose}
          handleSubmit={cancellationWarningHandleSubmit}
          label={warning_before_cancellation.isShowBeforeCancellationWarning.label}
        />
      )}

      {cancelletion_success.isShowSuccessCancellationModal.isShow && (
        <PaidTierModal
          isOpen={cancelletion_success.isShowSuccessCancellationModal.isShow}
          handleClose={() => cancelletion_success.setIsShowSuccessCancellationModal(false)}
          handleSubmit={() => cancelletion_success.setIsShowSuccessCancellationModal(false)}
          label={cancelletion_success.isShowSuccessCancellationModal.label}
          hiddenCancel
        />
      )}

      {upgrade_impossible.impossibleToUpgradeModal.isShow && (
        <PaidTierModal
          isOpen={upgrade_impossible.impossibleToUpgradeModal.isShow}
          hiddenCancel
          label={upgrade_impossible.impossibleToUpgradeModal.label}
          handleClose={() => upgrade_impossible.setImpossibleToUpgradeModal({ isShow: false, label: '' })}
          handleSubmit={() => upgrade_impossible.setImpossibleToUpgradeModal({ isShow: false, label: '' })}
        />
      )}

      {upgrade_success.isShowSuccessUpgradeModal.isShow && (
        <PaidTierModal
          isOpen={upgrade_success.isShowSuccessUpgradeModal.isShow}
          hiddenCancel
          label={upgrade_success.isShowSuccessUpgradeModal.label}
          handleClose={() => upgrade_success.setIsShowSuccessUpgradeModal({ isShow: false, label: '' })}
          handleSubmit={() => upgrade_success.setIsShowSuccessUpgradeModal({ isShow: false, label: '' })}
        />
      )}
    </Grid>
  );
};

export default CohealerBillingPage;
