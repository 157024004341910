import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { Checkbox, FormControl, OutlinedInput, TextField, Typography, useMediaQuery } from '@material-ui/core';
import Input from 'components/FormUI/Input';
import { FormikScrollToError } from 'components/FormikScrollToError';
import Modal from 'components/UI/Modal';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CKEditor } from 'ckeditor4-react';
import SignatureCanvas from 'react-signature-canvas';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import Button from 'components/FormUI/Button';
import { Grid } from '@material-ui/core';
import { StyledAttachTermsButton } from '../style';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';
import BackIcon from '../../../assets/BackIcon.svg';
import { determineColorToUse, determineDarkThemedColorToUse, getSingleContract } from 'services/contributions.service';
import { jsPDF } from 'jspdf';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import classes from './CreateCustomWaiver.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from 'polished';
import moment from 'moment';
import { lightOrDark } from 'utils/utils';

function CreateCustomWaiver({
  isOpen,
  onCancel,
  onSubmit,
  contribution,
  setCustomWaiverId,
  waiverData,
  settemplateChanged,
  setSelectedTemplate = () => {},
  isDarkModeAllowed = false,
  email = '',
  hideSaveButton = false,
  waiverBoby = '',
  allContractsFetched = [],
  isCreateContribution = true,
  isCoachorPartner = false,
}) {
  const formRef = useRef();
  const modalRef = useRef();
  const canvasRef = useRef(null);
  const mobileView = useMediaQuery('(max-width:400px)');
  const [isSelectTemplatePage, setIsSelectTemplatePage] = useState(null);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [data, setData] = useState(waiverBoby?.length === 0 ? waiverData?.completeDescription : waiverBoby);
  const [loader, setLoader] = useState(true);
  const [isSelectedOldTemplate, setIsSelectedOlTemplatePage] = useState(false);
  const [widthOfSignPad, setWidthOfSignPad] = useState(700);
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableBtnTitle, setDisableBtnTitle] = useState(true);
  const [showError, setShowError] = useState(false);
  const { currentRole, user } = useAccount();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [defaultTempleateSelected, setDefaultTempleateSelected] = useState(false);
  const togglePopup = () => {
    setShowModal(!showModal);
    setAcceptedTerms(false);
  };

  const togglePopupSubmit = () => {
    setShowModal(!showModal);
    setCustomWaiverId(defaultTempleateSelected?.id);
    settemplateChanged(true);
    onSelectTemplate(defaultTempleateSelected);
  };

  const isCoach = currentRole === UserRoles.cohealer;
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const history = useHistory();
  const isCACStep =
    history.location.pathname.includes('edit-contribution') || history.location.pathname.includes('create-contribution')
      ? false
      : true;

  const onFormSubmit = async values => {
    console.log('Data Updated----->waiverValues------>', values);
    if (isCoach) {
      const isMatched = allContractsFetched.find(
        temp => temp?.templateName?.toLowerCase() === values?.formName?.toLowerCase(),
      );
      if (isMatched && isMatched.id !== values.templateId) {
        formRef.current.setErrors({ formName: 'Form name already exsist' });
        return;
      }
    }
    onSubmit(values);
  };
  let colorToUse = determineColorToUse(contribution);
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const options = {
    orientation: 'portrait',
    format: [297, 210],
    unit: 'px',
  };

  const clearSign = () => {
    if (canvasRef?.current) {
      formRef.current.setFieldValue('clientSignature', '');
      canvasRef.current.clear();
      setDisableBtn(true);
      setIsCanvasEmpty(true);
    }
  };

  const onChangeFormNameValue = value => {
    formRef.current.setFieldValue('formName', value);
    isValueUpdated('formName', value);
    if (value.length > 0) {
      setShowError(false);
    } else if (value.length === 0) {
      setShowError(true);
    }
  };

  const onChangeDescriptionValue = value => {
    formRef.current.setFieldValue('description', value);
    isValueUpdated('description', value);
  };
  const onChangeLongDescriptionValue = value => {
    formRef.current.setFieldValue('completeDescription', value);
    setData(value);
    isValueUpdated('completeDescription', value);
    setLoader(false);
  };

  const onSelectTemplate = temp => {
    console.log('Data Updated----->waiver11', temp);
    formRef.current.setValues({
      ...waiverData,
      formName: temp?.formName,
      description: temp?.formDescription,
      completeDescription: temp?.formText,
      templateId: temp.id ? temp.id : temp.value,
      id: temp.id ? temp.id : temp.value,
      formText: temp.completeDescription || temp.formText,
      completeDescription: temp.completeDescription || temp.formText,
    });
    setSelectedTemplate(temp.id);
    setData(temp?.formText || temp?.completeDescription);
    setIsSelectedOlTemplatePage(true);
    setIsSelectTemplatePage(!isSelectTemplatePage);
  };

  const isValueUpdated = (valueOf, value) => {
    let isMatchedName = false;
    let isMatchedDescription = false;
    let isMatchedCompleteDescription = false;
    const matchTemplate = waiverData?.templatesList?.find(
      waiver => waiver?.id === formRef?.current?.values?.templateId,
    );
    if (matchTemplate) {
      if (valueOf === 'formName' && matchTemplate?.formName.trim() !== value?.trim()) {
        isMatchedName = true;
      } else if (valueOf === 'description' && matchTemplate?.formDescription.trim() !== value) {
        isMatchedDescription = true;
      } else if (valueOf === 'completeDescription' && matchTemplate?.formText.trim() !== value) {
        isMatchedCompleteDescription = true;
      }
      if (isMatchedName || isMatchedDescription || isMatchedCompleteDescription) {
        setIsSelectedOlTemplatePage(false);
        return;
      }
      formRef.current.setFieldValue('saveTemplate', false);
      setIsSelectedOlTemplatePage(true);
    } else {
      setIsSelectedOlTemplatePage(false);
      return;
    }
  };

  useEffect(() => {
    formRef.current.setFieldValue('formName', waiverData.formName);
    formRef.current.setFieldValue('description', waiverData.description);
    formRef.current.setFieldValue('completeDescription', waiverData.completeDescription);
  }, [waiverData]);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current && !isCoach) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      formRef.current.handleSubmit();
    }
  };
  const getSingleContractApi = async () => {
    setLoader(true);
    getSingleContract(contribution?.id, email)
      .then(res => {
        formRef.current.setFieldValue('formName', res.formName);
        formRef.current.setFieldValue('description', res.formDescription);
        formRef.current.setFieldValue('completeDescription', res.completeDescription);
        setData(res.formText);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (isCoach && email.length > 0) {
      getSingleContractApi();
    } else if (contribution.customWaiverId != null && isCoach && email.length === 0) {
      getSingleContractApi();
    } else {
      setLoader(false);
    }
  }, []);
  const useStyles = makeStyles(theme => ({
    customBorder: {
      borderColor: colorToUse.AccentColorCode,
    },
  }));
  const classesBorder = useStyles();
  return (
    <Formik
      initialValues={{ ...waiverData, clientSignature: '' }}
      validationSchema={Yup.object().shape({
        formName: isCoach ? Yup.string().required('Contract name is required').trim() : Yup.string(),
        description: isCoach
          ? Yup.string().required('Description is required').max(1000, 'Cannot exceed 1000 characters').trim()
          : Yup.string(),
        completeDescription: isCoach
          ? Yup.string()
              .required('Complete Description is required')
              // .max(10000, 'Cannot exceed 10000 characters')
              .trim()
          : Yup.string(),
        clientName: isCoach ? Yup.string() : Yup.string().required('Client name is required'),
        clientSignature: isCoach ? Yup.string() : Yup.string().required('Signature is required').trim(),
        saveTemplate: Yup.boolean(),
      })}
      innerRef={formRef}
      onSubmit={onFormSubmit}
    >
      {({ handleChange, setFieldValue, handleSubmit, values, errors, touched }) => {
        return (
          <>
            <Modal
              className="custom-waiver-modal-form"
              ref={modalRef}
              isOpen={isOpen}
              onCancel={onCancel}
              dontCancelOnSideClick
              isDarkModeSelected={isDarkModeAllowed && isDarkThemeEnabled ? true : isCACStep}
              isCreatingContribution={isDarkModeAllowed && isDarkThemeEnabled ? false : true}
              brandingColor={isCoachorPartner && isCreateContribution ? false : true}
              title={
                isCoachorPartner
                  ? isSelectTemplatePage
                    ? 'Choose Template'
                    : 'Create Custom Contract'
                  : waiverData.formName
              }
              onSubmit={() => {
                if (
                  !isCoach &&
                  (formRef?.current?.values?.clientName === undefined ||
                    formRef?.current?.values?.clientName.length === 0)
                ) {
                  setShowError(true);
                  return;
                } else if (
                  isCoachorPartner &&
                  (formRef?.current?.values?.formName === undefined || formRef?.current?.values?.formName.length === 0)
                ) {
                  setShowError(true);
                  return;
                }
                // formRef?.current.handleSubmit();
                onFormSubmit(values);
              }}
              cancelTitle={'Close'}
              submitTitle={isCoachorPartner ? 'Use Template' : 'I Agree'}
              disableFooter={isSelectTemplatePage ? true : false}
              disableSubmitClick={!isCoachorPartner && disableBtn && disableBtnTitle}
              scrollToBottom={scrollToBottom}
              ignoreDisable={true}
              cancelAsBack={isSelectTemplatePage ? true : false}
              backButton={() => {
                setIsSelectTemplatePage(!isSelectTemplatePage);
              }}
            >
              <FormikScrollToError>
                <Form className={!isCoachorPartner ? 'modal-form-style client-side-modal' : `modal-form-style`}>
                  {!isSelectTemplatePage && (
                    <>
                      <Grid
                        style={{
                          display: 'flex',
                          alignItems: 'end',
                          marginBottom: '8px',
                          justifyContent: 'space-between',
                        }}
                        spacing={1}
                      >
                        <Grid item xs={isCoachorPartner ? (mobileView ? 6 : 9) : 12}>
                          {isCoachorPartner && (
                            <FormControl
                              fullWidth
                              disabled={!isCoachorPartner}
                              className={isCoachorPartner ? 'isFontColorBlack' : ''}
                            >
                              {/* {!isCoach && (
                              <TextField
                                variant="standard"
                                fullWidth
                                label={'Form name'}
                                name={'formName'}
                                value={waiverData.formName}
                                type="text"
                                noHelperText={true}
                                InputLabelProps={{
                                  style: {
                                    color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black',
                                  },
                                }}
                                InputProps={{ readOnly: true, disabled: true }}
                              />
                            )} */}
                              {isCoachorPartner && (
                                <Input
                                  fullWidth
                                  label={'Contract name'}
                                  name={'formName'}
                                  InputLabelProps={{
                                    style: {
                                      color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black',
                                    },
                                  }}
                                  focused
                                  onChange={e => onChangeFormNameValue(e?.target?.value)}
                                  type="text"
                                  noHelperText={true}
                                />
                              )}
                            </FormControl>
                          )}
                        </Grid>
                        {isCoachorPartner && (
                          <Grid style={{ marginLeft: mobileView ? '' : '10px' }} item>
                            <Button
                              backgroundColor={isCreateContribution === false && btnColor}
                              textColor={isCreateContribution === false && textColor}
                              className={mobileView && classes.chooseTemplateButton}
                              onClick={e => {
                                e.preventDefault();
                                setIsSelectTemplatePage(!isSelectTemplatePage);
                              }}
                            >
                              Choose Template
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                      {isCoachorPartner &&
                        showError &&
                        (values?.formName === undefined || values?.formName.length === 0) &&
                        errors?.formName && (
                          <div style={{ color: '#9164a2', marginTop: '2px' }}>Contract name is required</div>
                        )}
                      {isCoachorPartner && (
                        <FormControl fullWidth>
                          <div style={{ marginTop: '15px' }}>
                            {/* {!isCoach && (
                            <TextField
                              fullWidth
                              label={'Description'}
                              name={'description'}
                              value={waiverData.description}
                              type="text"
                              InputLabelProps={{
                                style: {
                                  color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black',
                                },
                              }}
                              InputProps={{ readOnly: true, disabled: true }}
                            />
                          )} */}
                            {isCoachorPartner && (
                              <Input
                                fullWidth
                                label={'Description'}
                                name={'description'}
                                focused
                                InputLabelProps={{
                                  style: {
                                    color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black',
                                  },
                                }}
                                onChange={e => onChangeDescriptionValue(e.target.value)}
                                type="text"
                              />
                            )}
                          </div>
                        </FormControl>
                      )}
                      {!isCoachorPartner && (
                        <FormControl fullWidth>
                          <Typography
                            style={{
                              marginBottom: '20px',
                              color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : '',
                            }}
                          >
                            {`The below agreement is between ${user.firstName} ${user.lastName}, and ${
                              contribution.serviceProviderName
                            }  as of ${moment().format('MMM DD, YYYY')}`}
                          </Typography>
                        </FormControl>
                      )}
                      {loader === false && (
                        <FormControl
                          fullWidth
                          className={isDarkModeAllowed && isDarkThemeEnabled ? 'custom-ckeditor' : ''}
                          style={{ marginTop: 10 }}
                        >
                          {values.formName == 'Default Template' ? (
                            <CKEditor
                              config={{
                                allowedContent: true,
                                height: '500px',
                                removePlugins: 'elementspath',
                                readOnly: true,
                              }}
                              initData={data}
                              onChange={e => {
                                onChangeLongDescriptionValue(e.editor.getData());
                              }}
                            />
                          ) : isCoachorPartner ? (
                            <CKEditor
                              config={{
                                allowedContent: true,
                                height: '500px',
                                removePlugins: 'elementspath',
                                readOnly: !isCoachorPartner,
                              }}
                              initData={data}
                              onChange={e => {
                                onChangeLongDescriptionValue(e.editor.getData());
                              }}
                            />
                          ) : (
                            <CKEditor
                              config={{
                                allowedContent: true,
                                height: '500px',
                                removePlugins: 'elementspath',
                                readOnly: !isCoachorPartner,
                                removeButtons: 'Source,About,SpellCheck',
                                toolbar: [
                                  { name: 'document', items: ['Maximize'] }, // Only keep the Maximize button
                                ],
                              }}
                              initData={data}
                              onChange={e => {
                                onChangeLongDescriptionValue(e.editor.getData());
                              }}
                            />
                          )}
                          {touched?.completeDescription && errors?.completeDescription && (
                            <CommonErrorMessage align="left" message={errors?.completeDescription} />
                          )}
                        </FormControl>
                      )}
                      <div className="client-area" style={{ marginTop: 5 }}>
                        {!isCoachorPartner && (
                          <FormControl fullWidth>
                            <Typography
                              style={{
                                color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : '',
                              }}
                            >
                              Signature Section of Coaching Agreement
                            </Typography>
                            <Typography
                              style={{
                                color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : '',
                              }}
                            >
                              {`Effective date: ${moment().format('MMM DD, YYYY')}`}
                            </Typography>
                          </FormControl>
                        )}
                        <FormControl fullWidth>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p
                              style={{
                                color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : '#116582',
                                marginBottom: 5,
                              }}
                            >
                              The Client:
                            </p>
                            {!isCoachorPartner && (
                              <p
                                style={{
                                  color: '#116582',
                                  marginBottom: 5,
                                  color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : '#116582',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  const pdf = new jsPDF(options);
                                  pdf.setFontSize(10);
                                  pdf.html(`<html><body style="width:100%, font-size: 9px">${data}</body></html>`, {
                                    html2canvas: {
                                      scale: 0.2645,
                                    },
                                    width: 675,
                                    windowWidth: 675,
                                    autoPaging: 'text',
                                    margin: [10, 20, 30, 10],
                                    callback: function (pdf) {
                                      pdf.save(`${contribution.serviceProviderName} Waiver Form.pdf`);
                                    },
                                  });
                                }}
                              >
                                Download PDF
                              </p>
                            )}
                          </div>
                          <OutlinedInput
                            ref={messagesEndRef}
                            color={colorToUse.accentColorCode}
                            fullWidth
                            name={'clientName'}
                            value={waiverData.clientName}
                            type="text"
                            placeholder="Enter your name here"
                            InputProps={{
                              classes: {
                                notchedOutline: classesBorder.customBorder,
                              },
                            }}
                            onChange={val => {
                              if (isCoachorPartner === false) {
                                setFieldValue('clientName', val.target.value);
                                if (val.target.value.length > 0) {
                                  setShowError(false);
                                  setDisableBtnTitle(false);
                                } else if (val.target.value.length === 0) {
                                  setDisableBtnTitle(true);
                                  setShowError(true);
                                }
                              }
                            }}
                            style={{
                              background: isCoachorPartner && '#dfe3e4',
                            }}
                            disabled={isCoachorPartner}
                          />
                          {showError &&
                            (values?.clientName === undefined || values?.clientName.length === 0) &&
                            errors?.clientName && <CommonErrorMessage align="left" message={errors?.clientName} />}
                        </FormControl>

                        {!isCoachorPartner && (
                          <div style={{ marginTop: '10px' }}>
                            <Typography variant="caption">
                              By signing below, I acknowledge that I have read and agreed to the entire agreement.
                            </Typography>
                          </div>
                        )}
                        <div
                          style={{
                            position: 'relative',
                            pointerEvents: isCoachorPartner && 'none',
                            background: isCoachorPartner && '#dfe3e4',
                          }}
                        >
                          <div
                            type="button"
                            onClick={clearSign}
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'dark',
                              bottom: '8px',
                            }}
                          >
                            Clear
                          </div>
                          {isCanvasEmpty ? (
                            <div
                              style={{
                                color: isCoachorPartner ? lighten(0.1, '#A6A6A6') : '#A6A6A6',
                                position: 'absolute',
                                top: '10px',
                                left: '13px',
                              }}
                            >
                              {isCoachorPartner ? <p>Your client will sign here</p> : <p>Sign here</p>}
                            </div>
                          ) : null}
                          <div className="signContainer" style={{ borderColor: isCoachorPartner && '#a6a8a9' }}>
                            <SignatureCanvas
                              ref={canvasRef}
                              penColor={isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black'}
                              canvasProps={{ className: 'signPad', style: { height: 200, width: '100%' } }}
                              onEnd={() => {
                                setDisableBtn(false);
                                if (canvasRef?.current) {
                                  const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                                  setFieldValue('clientSignature', val);
                                }
                              }}
                              onBegin={() => {
                                setIsCanvasEmpty(false);
                              }}
                            />
                          </div>
                        </div>
                        {touched.clientSignature && errors?.clientSignature && (
                          <CommonErrorMessage align="left" message={errors?.clientSignature} />
                        )}
                      </div>
                      {!isCoachorPartner && (
                        <FormControl fullWidth>
                          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                            <p
                              style={{
                                color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : '#116582',
                                marginBottom: 0,
                                marginTop: 5,
                              }}
                            >
                              The Coach:
                            </p>
                            <Typography
                              style={{
                                color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : '',
                              }}
                            >
                              {`${contribution?.serviceProviderName}`}
                            </Typography>
                            <Typography
                              style={{
                                color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : '',
                              }}
                            >
                              {`Location: ${contribution?.coachCountry}`}
                            </Typography>
                          </div>
                        </FormControl>
                      )}
                      {isCoachorPartner && hideSaveButton === false && (
                        <>
                          <Checkbox
                            id="create-contribution-agreement"
                            color="primary"
                            name="saveTemplate"
                            checked={values.saveTemplate}
                            disabled={isSelectedOldTemplate}
                            onChange={handleChange}
                          />
                          <StyledAttachTermsButton
                            invert
                            type="button"
                            style={{
                              paddingLeft: window.innerWidth > 990 && 0,
                              background: isDarkModeAllowed && isDarkThemeEnabled ? '#2d2f31' : 'white',
                              color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black',
                              fontWeight: '400',
                            }}
                            disableRipple
                            component="span"
                          >
                            Save Template
                          </StyledAttachTermsButton>
                        </>
                      )}
                    </>
                  )}
                  {isSelectTemplatePage && (
                    <>
                      <Grid style={{ margin: '0px', marginBottom: '10px' }} container spacing={2}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', padding: '8px 0px' }}>
                          {/* <MdOutlineArrowBackIos
                            style={{
                              fontSize: 20,
                              color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black',
                              cursor: 'pointer',
                            }}
                            onClick={() => setIsSelectTemplatePage(!isSelectTemplatePage)}
                          /> */}
                          <div
                            style={{
                              fontSize: '20px',
                              fontWeight: 'bold',
                              marginRight: '1px',
                              color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black',
                              cursor: 'pointer',
                            }}
                            onClick={() => setIsSelectTemplatePage(!isSelectTemplatePage)}
                          >{`<`}</div>
                          <h5
                            onClick={() => setIsSelectTemplatePage(!isSelectTemplatePage)}
                            style={{
                              marginBottom: 0,
                              color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black',
                              cursor: 'pointer',
                            }}
                          >
                            Back
                          </h5>
                        </Grid>
                        {allContractsFetched.length > 0 &&
                          allContractsFetched?.map(temp => (
                            <Grid
                              item
                              xs={12}
                              style={{ padding: '5px 0px', cursor: 'pointer' }}
                              onClick={() => {
                                if (
                                  temp.title === 'Default Template' ||
                                  temp?.id === 'default_6523ce0d6a9682c73d9cf831'
                                ) {
                                  setShowModal(true);
                                  setDefaultTempleateSelected(temp);
                                } else {
                                  setCustomWaiverId(temp?.id);
                                  settemplateChanged(true);
                                  onSelectTemplate(temp);
                                }
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: '700',
                                  color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : 'black',
                                }}
                              >
                                {temp?.formName}
                              </div>
                              <div
                                style={{
                                  fontWeight: '400',
                                  opacity: '40%',
                                  fontSize: '14px',
                                  color: isDarkModeAllowed && isDarkThemeEnabled ? 'white' : '#282B2B',
                                }}
                              >
                                {temp.formDescription}
                              </div>
                            </Grid>
                          ))}
                      </Grid>
                    </>
                  )}
                </Form>
              </FormikScrollToError>
            </Modal>
            {
              <Modal
                title="Disclaimer"
                isOpen={showModal}
                submitTitle="Confirm"
                onSubmit={togglePopupSubmit}
                onCancel={togglePopup}
                disableSubmitClick={acceptedTerms === false}
                headerBorder={true}
                isDarkModeSelected={false}
                isCreatingContribution={true}
                brandingColor={false}
              >
                <div style={{ marginTop: '10px' }}>
                  TERMS. You understand that an attorney-client relationship does not exist between the creator of these
                  templates and your use of the templates.
                  <br />
                  <br />
                  YOUR RESPONSIBILITY. You accept and agree that you are 100% responsible for the completion of this
                  template and all future templates provided by Cohere Inc. Cohere Inc. makes no representations,
                  warranties or guarantees verbally or in writing regarding any use of the template, including without
                  limitation, their enforceability or whether they fulfill their intended purpose. You are responsible
                  for ensuring contracts comply with your specific state, territory, or national law. You are advised to
                  consult an attorney regarding any use of these templates.
                  <br />
                  <br />
                  NON-DISCLOSURE OF TEMPLATE. This template is proprietary and copyrighted. You agree that such
                  proprietary material is solely for your own use on the Cohere platform. Any disclosure to a third
                  party (other than the Client) is strictly prohibited. This template is for your individual use only
                  and is granted as a single-user license. No license to sell or distribute these materials is granted
                  or implied.
                  <br />
                  <br />
                  INDEMNIFICATION. You hereby indemnify and hold harmless Cohere Inc. and respective officers,
                  directors, agents, employees, and independent contractors from any and all claims, demands, losses,
                  causes of action, damage, lawsuits, judgments, including attorneys’ fees and costs, arising out of, or
                  relating to, your participation or action(s) relating to the use of these templates. You agree to
                  defend against any and all claims, demands, causes of action, lawsuits, and/or judgments arising out
                  of, or relating to, such use unless expressly stated otherwise in writing.
                  <br />
                  <br />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '-10px' }}>
                  <Checkbox
                    id="create-contribution-agreement"
                    color="primary"
                    name="saveTemplate"
                    checked={acceptedTerms}
                    onChange={() => {
                      if (acceptedTerms) {
                        setAcceptedTerms(false);
                      } else {
                        setAcceptedTerms(true);
                      }
                    }}
                  />
                  <div>I agree to the Terms of Service</div>
                </div>
              </Modal>
            }
          </>
        );
      }}
    </Formik>
  );
}

export default CreateCustomWaiver;
