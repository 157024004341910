import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/FormUI/Button';

const StyledButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: ${({ backButtonTitle }) => (backButtonTitle ? 'space-between' : 'flex-end')};
`;

const Buttons = ({
  formId,
  disabled,
  backButtonTitle,
  nextButtonTitle,
  backClickHandler,
  onNextClickHandler,
  saveDraftButton,
  saveDraftButtonHandler,
  saveDraftButtonTitle,
}) => {
  return (
    <StyledButtonContainer backButtonTitle={backButtonTitle}>
      {backButtonTitle && (
        <>
          <Button invert autoWidth type="button" onClick={backClickHandler}>
            {backButtonTitle}
          </Button>
        </>
      )}
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        {saveDraftButton && (
          <>
            <Button
              style={{ marginRight: '10px', borderColor: '#C9B382', color: '#C9B382' }}
              invert
              autoWidth
              type="button"
              onClick={saveDraftButtonHandler}
            >
              {saveDraftButtonTitle}
            </Button>
          </>
        )}
        <Button autoWidth type="submit" form={formId} disabled={disabled} onClick={onNextClickHandler}>
          {nextButtonTitle}
        </Button>
      </div>
    </StyledButtonContainer>
  );
};

Buttons.propTypes = {
  disabled: PropTypes.bool,
  formId: PropTypes.string.isRequired,
  backButtonTitle: PropTypes.string,
  nextButtonTitle: PropTypes.string,
  backClickHandler: PropTypes.func,
  onNextClickHandler: PropTypes.func,
};

Buttons.defaultProps = {
  disabled: false,
  backButtonTitle: null,
  nextButtonTitle: null,
  backClickHandler: () => {},
  onNextClickHandler: undefined,
};

export default Buttons;
