import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { fetchCohealerContribution } from 'actions/contributions';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import * as R from 'ramda';
import EventsPopup from 'pages/MasterCalendarPage/components/EventsPopup';
import DateNavigationComponent from 'pages/MasterCalendarPage/components/DateNavigator';
import { colors } from 'utils/styles';
import { getEventsForCalendar } from 'utils/calendar';
import { darken } from 'polished';
import Loader from 'components/UI/Loader';
import { useRouter } from 'hooks';
const localizer = momentLocalizer(moment);
const allViews = R.compose(R.values, R.omit(['AGENDA', 'WORK_WEEK']))(Views);

const ClientCalendar = ({ contribution, calendarActiveView }) => {
  const { pathname } = useRouter();
  const [events, setEvents] = useState([]);
  const [showEventsPopup, setShowEventsPopup] = useState(false);
  const [prevView, setPrevView] = useState('');
  const [currentViewDateRange, setCurrentViewDateRange] = useState('');
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [seeAllEvents, setSeeAllEvents] = useState(false);
  const [currentDateRange, setCurrentDateRange] = useState();
  const history = useHistory();
  const [selectedDateEvents, setSelectedDateEvents] = useState({
    date: new Date(),
    events: [],
  });
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const clientPreviewMode = pathname?.includes('clientpreview');
  const CustomToolbar = toolbar => {
    return (
      <span className={mobileView && 'custom-calender-toolbar'}>
        <div className="calendar-custom-header">
          <DateNavigationComponent toolbar={toolbar} calendarActiveView={calendarActiveView} />
        </div>
      </span>
    );
  };

  const handleStartAndEndDateOfView = (range, view) => {
    if (view === prevView) return;
    setCurrentDateRange(range);
    setPrevView(view || prevView);
    let viewStartDate;
    let viewEndDate;
    if (Array.isArray(range)) {
      // Handle the case when range is an array of Date objects in week and day view
      viewStartDate = range[0];
      viewEndDate = range[range.length - 1];
    } else if (typeof range === 'object' && range?.start && range?.end) {
      // Handle the case when range is an object with start and end properties usually in month view
      viewStartDate = range.start;
      viewEndDate = range.end;
    }
    setCurrentViewDateRange(range);
    setIsCalendarLoading(true);
    getEventsForCalendar({ viewStartDate, viewEndDate, contributionId: !seeAllEvents && contribution?.id })
      .then(allEvents => {
        setEvents(allEvents);
      })
      .finally(() => {
        setIsCalendarLoading(false);
      });
  };
  useEffect(() => {
    if (currentDateRange) {
      handleStartAndEndDateOfView(currentDateRange, '');
    }
  }, [seeAllEvents]);

  const onSelectEvent = event => {
    if (!clientPreviewMode) {
      history.push(`/contribution-view/${event?.contributionId}/sessions`, {
        sessionId: event.sessionId,
      });
    }
  };
  return (
    <>
      {isCalendarLoading && <Loader />}
      <div className="master-calendar-container" style={{ zIndex: 1 }}>
        <BigCalendar
          className="cohere-master-calendar custom-calendar"
          style={{ color: 'black' }}
          localizer={localizer}
          events={events}
          views={allViews}
          onSelectEvent={onSelectEvent}
          defaultView={Views.MONTH}
          step={30}
          showMultiDayTimes
          defaultDate={new Date()}
          startAccessor="start"
          endAccessor="end"
          onShowMore={(dateEvents, date) => {
            setSelectedDateEvents({
              date,
              events: dateEvents,
            });
            setShowEventsPopup(true);
          }}
          onView={view => {
            if (view === 'day' || view === 'week') {
              setTimeout(() => {
                // Scroll to 9 AM when navigating to the Day view
                const calendar = document.getElementsByClassName('rbc-time-view')[0];
                if (calendar) {
                  calendar.scrollTo({
                    top: 640,
                    behavior: 'smooth',
                  });
                }
              }, 100);
            }
          }}
          formats={{ eventTimeRangeFormat: () => '' }}
          components={{
            toolbar: CustomToolbar,
          }}
          onDrillDown={() => {}}
          onRangeChange={handleStartAndEndDateOfView}
          eventPropGetter={({ color }) => {
            const nonMonthProps = {
              backgroundColor: '#F5F5F5',
              border: 'none',
              borderRadius: '0',
              borderLeft: `4px solid ${darken(0.1, color)}`,
              borderTop: `1px solid ${darken(0.1, color)}`,
              borderTopRightRadius: '4px',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              paddingTop: '5px',
            };
            let newStyle = {
              color: color || colors.darkOceanBlue,
              backgroundColor: 'rgba(0,0,0,0)',
              minHeight: 24,
              fontSize: 14,
              fontWeight: 500,
            };
            if (prevView !== 'month') {
              newStyle = {
                ...newStyle,
                ...nonMonthProps,
              };
            }

            return {
              className: '',
              style: newStyle,
            };
          }}
        />
      </div>
      {showEventsPopup && (
        <EventsPopup
          events={selectedDateEvents.events}
          date={selectedDateEvents.date}
          onCancel={() => setShowEventsPopup(false)}
          onEventClick={onSelectEvent}
        />
      )}
    </>
  );
};

ClientCalendar.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  calendarActiveView: PropTypes.string.isRequired,
};

const actions = {
  getCohealerContribution: fetchCohealerContribution,
};

const mapStateToProps = ({ calendars }) => ({
  calendarActiveView: calendars.currentView,
});

export default connect(mapStateToProps, actions)(ClientCalendar);
