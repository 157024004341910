import React, { useState, useEffect, useCallback } from 'react';
import PropTypes, { array } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { useLoadMemberPodsByContribution } from 'components/MemberPods';
import useAccount from 'hooks/useAccount';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  fetchClientContribution,
  fetchCohealerContribution,
  setSessionId as setSessionCode,
} from 'actions/contributions';
import Session from './Session';
import ProgressBar from '@ramonak/react-progress-bar';
import axiosInstance from 'utils/axiosInstance';
import { useHttp, useClientPreview } from 'hooks';
import find from 'lodash/find';
import Modal from 'components/UI/Modal';
// import { Grid } from 'antd';
import * as S from '../../../ContributionView/components/ContributionHeader/styled';
import { Grid, Box } from '@material-ui/core';
import Button from '@mui/material/Button';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionType, UserRoles } from 'helpers/constants';
import { ColorSchemeService } from 'services/color.service';
import { determineColorToUse, getThemedColors, shareViaEmail } from 'services/contributions.service';
import { useParams } from 'react-router-dom';
import { lightOrDark } from 'utils/utils';
import { downloadFile, CanClinetInviteFrieds } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import TemplateThree from 'pages/ContributionView/Templates/TemplateThree';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ShareModalContainer from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import ErrorBoundary from 'components/ErrorBoundary';
import { memberPodsService } from 'services/memberPods/memberPods.service';

const StickyPortion = styled.div`
  ${({ isSticky, top, themedCardBackgroundColor }) =>
    isSticky &&
    css`
      position: sticky;
      top: ${top};
      z-index: 19;
      background-color: ${themedCardBackgroundColor};
    `}
`;

const StyledSessiosContainer = styled.div`
  width: 100%;
  overflow: hidden;

  ${({ clientPreviewMode }) =>
    clientPreviewMode &&
    css`
      pointer-events: none;
    `}
`;

const StyledTab = styled.div`
  margin-right: 20px;
  border: 1px solid ${({ borderColor }) => borderColor};
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  padding: 5px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  margin-left: ${({ marginLeft }) => marginLeft};
`;

const CourseSessions = ({
  contribution,
  contribution: { sessions, id: contributionId, isPurchased, timeZoneId, percentageCompleted },
  changeShowPopUp,
  renderSessionTimeActions,
}) => {
  const { clientPreviewMode } = useClientPreview();
  const [connectStripeModal, setConnectStripeModal] = useState(false);
  const { user, currentRole } = useAccount();
  const stickH = useSelector(state => state.stickyHeader?.stickyHeader);
  const { sessionId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [sessionIdToday, setSessionId] = useState(null);
  const [podsArray, setPodsArray] = useState([]);
  const { request } = useHttp();
  const isCoach = currentRole == UserRoles.cohealer && !clientPreviewMode;
  const theme = useTheme();
  const {
    themedCardBackgroundColor: oldThemedCardBackgroundColor,
    newThemedCardColor,
    newThemedBackgroundColor,
    themedColor,
    newThemedTextColor,
  } = getThemedColors(contribution);
  const themedCardBackgroundColor = isCoach ? oldThemedCardBackgroundColor : newThemedCardColor;
  const isUpcomingSession = find(sessions, { isCompleted: false });
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.down('lg'));
  const sessionToBeExpanded = useSelector(state => state.contributions.sessionId);
  useEffect(() => {
    memberPodsService.getContributionPods(contribution.id).then(data => {
      setPodsArray(data);
    });
    filterSessions();
  }, []);

  useEffect(() => {
    if (
      sessionId ||
      location?.state?.sessionId ||
      (window?.history?.state?.sessionId && sessionToBeExpanded === null)
    ) {
      const idToScroll = sessionId || location?.state?.sessionId || window?.history.state?.sessionId;
      setTimeout(() => {
        const element = document.getElementById(idToScroll);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 3000);
    } else if (sessionIdToday && !!currentRole) {
      setTimeout(() => {
        const element = document.getElementById(sessionIdToday.id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 3000);
    }
  }, [sessionId, sessionIdToday, sessionToBeExpanded]);

  useEffect(() => {
    if (sessionToBeExpanded) {
      setTimeout(() => {
        const element = document.getElementById(sessionToBeExpanded);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 3000);
      setTimeout(() => {
        dispatch(setSessionCode(null));
      }, 5000);
    }
  }, [sessionToBeExpanded]);

  const onShareButtonClick = () => {
    request(`/Contribution/DownloadSelfpacedModuleDetails?contributionId=${contributionId}`, 'POST', null, {}, 'blob')
      .then(res => downloadFile(res, 'Self-paced Engagement Data.csv'))
      .catch(err => {
        setConnectStripeModal(true);
      });
  };
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const type = contribution?.type;
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;

  const getTop = type => {
    let t;
    switch (type) {
      case TemplateType.TemplateThree:
        t = '150px';
        break;
      case TemplateType.TemplateOne:
        t = '94px';
        break;
      case TemplateType.TemplateTwo:
        t = '94px';
        break;
      default:
        t = '139px';
    }

    return t;
  };
  const btnColor = colorToUse?.PrimaryColorCode;
  const progressbarColor = colorToUse?.AccentColorCode;
  const FILTERTYPES = {
    ALL: 'All',
    LIVE: 'Live',
    SELFPACED: 'SelfPaced',
    PAST: 'Past',
    UPCOMING: 'Upcoming',
  };
  const TABS = ['Live', 'Self-Paced'];
  const [activeTab, setActiveTab] = useState('Live');
  const filters = ['All', 'Upcoming', 'Live', 'SelfPaced', 'Past'];
  const [filter, setFilter] = useState(filters[0]);

  const [filterClicked, setFilterClicked] = useState(false);

  const convertedToSelfPaced = () => {
    if (filter != filters[0]) {
      setFilter(filters[0]);
      setFilterClicked(true);
    }
  };

  const FilteredSessionsCount = (sessions, type) => {
    if (isEmpty(user) || contribution.isPurchased === false) {
      if (type == FILTERTYPES.ALL) {
        return sessions.length;
      }
      if (type == FILTERTYPES.LIVE) {
        return sessions.filter(s => !s.isPrerecorded).length;
      } else if (type == FILTERTYPES.SELFPACED) {
        return sessions.filter(s => s.isPrerecorded).length;
      } else if (type == FILTERTYPES.PAST) {
        if (isCoach) {
          return sessions.filter(s => !s.isPrerecorded && s.isCompleted).length;
        } else {
          return sessions.filter(s => s.isCompleted).length;
        }
      } else if (type == FILTERTYPES.UPCOMING) {
        if (isCoach) {
          const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
          const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
          const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For coach all those sessions which are not completed will be considered as upcoming sessions
          const allSelfpacedSessionsUpcoming = allSelfpacedSessions; // For coach all self paced module will be considered as upcoming sessions bcz coach have to authority to complete the selfpaced modules
          const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
          return AllUpcomingSessions.length || [].length;
        } else {
          const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
          const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
          const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For client all those sessions which are not completed will be considered as upcoming sessions
          const allSelfpacedSessionsUpcoming = allSelfpacedSessions.filter(s => !s.isCompleted); // For client all those self paced modules which are not completed will be considered as upcoming modules
          const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
          return AllUpcomingSessions.length || [].length;
        }
      } else {
        return sessions.length;
      }
    }
    if (type == FILTERTYPES.ALL) {
      const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded).length;
      if (filterSessions() === 0 && allSelfpacedSessions === 0) {
        return [].length;
      } else if (allSelfpacedSessions > 0 && filterSessions() === 0) {
        return allSelfpacedSessions.length || [].length;
      } else {
        return filterSessions();
      }
    }
    if (type == FILTERTYPES.LIVE) {
      if (filterSessions() === 0) {
        return 0;
      } else {
        return filterSessions() - sessions.filter(s => s.isPrerecorded).length;
      }
    } else if (type == FILTERTYPES.SELFPACED) {
      return sessions.filter(s => s.isPrerecorded).length;
    } else if (type == FILTERTYPES.PAST) {
      if (isCoach) {
        return sessions.filter(s => !s.isPrerecorded && s.isCompleted).length;
      } else {
        return sessions.filter(s => s.isCompleted).length;
      }
    } else if (type == FILTERTYPES.UPCOMING) {
      if (isCoach) {
        const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
        const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
        const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For coach all those sessions which are not completed will be considered as upcoming sessions
        const allSelfpacedSessionsUpcoming = allSelfpacedSessions; // For coach all self paced module will be considered as upcoming sessions bcz coach have to authority to complete the selfpaced modules
        const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
        return AllUpcomingSessions.length || [].length;
      } else {
        const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
        const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
        const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For client all those sessions which are not completed will be considered as upcoming sessions
        const allSelfpacedSessionsUpcoming = allSelfpacedSessions.filter(s => !s.isCompleted); // For client all those self paced modules which are not completed will be considered as upcoming modules
        const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
        if (filterSessions() === 0 && allSelfpacedSessions.length === 0) {
          return [].length;
        } else {
          return filterSessions() - sessions.filter(s => !s.isPrerecorded && s.isCompleted).length || [].length;
        }
      }
    } else {
      return filterSessions();
    }
  };
  const FilteredSessions = (sessions, type) => {
    if (type == FILTERTYPES.ALL) {
      return sessions;
    }
    if (type == FILTERTYPES.LIVE) {
      return sessions.filter(s => !s.isPrerecorded);
    } else if (type == FILTERTYPES.SELFPACED) {
      return sessions.filter(s => s.isPrerecorded);
    } else if (type == FILTERTYPES.PAST) {
      if (isCoach) {
        return sessions.filter(s => !s.isPrerecorded && s.isCompleted);
      } else {
        return sessions.filter(s => s.isCompleted);
      }
    } else if (type == FILTERTYPES.UPCOMING) {
      if (isCoach) {
        let filteredSessions = sessions.filter(
          session => !(session.isPrerecorded === false && session.isCompleted === true),
        );
        // const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
        // const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
        // const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For coach all those sessions which are not completed will be considered as upcoming sessions
        // const allSelfpacedSessionsUpcoming = allSelfpacedSessions; // For coach all self paced module will be considered as upcoming sessions bcz coach have to authority to complete the selfpaced modules
        // const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
        return filteredSessions || [];
      } else {
        // const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
        // const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
        // const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For client all those sessions which are not completed will be considered as upcoming sessions
        // const allSelfpacedSessionsUpcoming = allSelfpacedSessions.filter(s => !s.isCompleted); // For client all those self paced modules which are not completed will be considered as upcoming modules
        // const AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
        let filteredSessions = sessions.filter(session => !(session.isCompleted === true));
        return filteredSessions || [];
      }
    } else {
      return sessions;
    }
  };
  const isTodaySession = session => {
    return session.sessionTimes.some(
      st => moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') == 0,
    );
  };

  const liveSessions = sessions => {
    const live = sessions?.filter(s => !s.isPrerecorded && !s.isCompleted);
    return live;
  };

  const sessionsHappenningToday = sessions => {
    const todaySessions = sessions?.filter(s => isTodaySession(s) && !s.isPrerecorded && !s.isCompleted);
    return todaySessions || [];
  };

  const sessionsHappenningNotToday = sessions => {
    const notTodaySessions = sessions?.filter(s => !isTodaySession(s) || s.isPrerecorded);
    return notTodaySessions || [];
  };

  const splitAndSortSessions = sessions => {
    const happeningToday = sessionsHappenningToday(sessions);
    const notHappeningToday = sessionsHappenningNotToday(sessions);
    const happeningTodaySorted = !!happeningToday ? orderBy(happeningToday, ['startTime'], ['asc']) : happeningToday;
    const notHappeningTodaySorted = !!notHappeningToday
      ? orderBy(notHappeningToday, ['startTime'], ['asc'])
      : notHappeningToday;
    const mergedSessions = [].concat(happeningTodaySorted, notHappeningTodaySorted);
    return mergedSessions;
  };

  useEffect(() => {
    const allLiveUncompletedSessions = liveSessions(sessions);
    const allLiveUncompletedSessionsSorted = !!allLiveUncompletedSessions
      ? orderBy(allLiveUncompletedSessions, ['startTime'], ['asc'])
      : allLiveUncompletedSessions;
    const happeningTodaySessions = sessionsHappenningToday(sessions);
    const notHappeningTodaySessions = sessionsHappenningNotToday(sessions);
    const happeningTodaySessionsSorted = !!happeningTodaySessions
      ? orderBy(happeningTodaySessions, ['startTime'], ['asc'])
      : happeningTodaySessions;
    const notHappeningTodaySessionsSorted = !!notHappeningTodaySessions
      ? orderBy(notHappeningTodaySessions, ['startTime'], ['asc'])
      : notHappeningTodaySessions;
    if (allLiveUncompletedSessions.length > 0) {
      setSessionId(happeningTodaySessionsSorted[0]);
    }
  }, [sessions]);
  useLoadMemberPodsByContribution({ contributionId, userId: user.id });
  const [InviteModalOpened, setInviteModalOpened] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });

  const handleModalClose = useCallback(() => {
    setEmailsError({
      isError: false,
      errorMessage: null,
    });
    setInviteModalOpened(false);
  }, [setInviteModalOpened]);

  const handleModalSubmit = useCallback(
    (emails, priceId) => {
      shareViaEmail(
        priceId === null
          ? { contributionId: contribution?.id, emailAddresses: emails }
          : { contributionId: contribution?.id, emailAddresses: emails, priceId: priceId },
      )
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({
            isError: true,
            errorMessage: err?.response?.data?.message,
          });
        });
    },
    [contribution?.id, handleModalClose],
  );
  let countNumber = 0;
  const filterSessions = () => {
    // const podIds = sessions.flatMap(session => session.sessionTimes.filter(sessionTime => sessionTime.podId !== "").map(sessionTime => sessionTime.podId));
    let count = 0;
    console.log('dATA----->', podsArray);
    for (const session of sessions) {
      let shouldCountSession = false;
      for (const sessionTime of session.sessionTimes) {
        // Check if SessionTime does not have a PodId
        if (sessionTime.podId === '' || sessionTime.podId === null) {
          shouldCountSession = true;
          break; // One SessionTime without a PodId is enough to count the session
        } else {
          // If SessionTime has a PodId, check if the user is not in the Pod's ClientIds
          const pod = podsArray.find(p => p.id === sessionTime.podId);
          if (isEmpty(pod) || pod.clientIds.includes(user.id)) {
            shouldCountSession = true;
            break; // User not being in a Pod's ClientIds is enough to count the session
          }
        }
      }

      // If the criteria are met, count the session
      if (shouldCountSession) {
        count = count + 1;
      }
    }
    console.log('dATA----->', count);
    return count;
  };

  return (
    <>
      <StickyPortion
        themedCardBackgroundColor={isCoach ? themedCardBackgroundColor : newThemedBackgroundColor}
        isSticky={stickH && !mobileView}
        top={isCoach ? '159px' : getTop(activeTemplate)}
      >
        <Grid
          container
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <Grid
            style={{ marginTop: '10px' }}
            item
            lg={activeTemplate === TemplateType.TemplateOne && !isCoach && !isPurchased ? 12 : 7}
            sm={12}
            xs={12}
          >
            <Grid
              container
              style={{
                marginTop: '10px',
                marginBottom: '15px',
                display: 'flex',
                justifyContent:
                  tabletView || (activeTemplate === TemplateType.TemplateOne && !isCoach && !isPurchased)
                    ? 'center'
                    : 'flexStart',
                width: '100%',
              }}
            >
              {filters.map((item, index) => {
                return (
                  <div>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}> */}
                    <Button
                      variant="contained"
                      value={item}
                      disableElevation="true"
                      style={{
                        backgroundColor:
                          item == filter ? btnColor : contribution?.isDarkModeEnabled ? 'rgb(40, 43, 43)' : 'white',
                        paddingLeft:
                          item === FILTERTYPES.SELFPACED ? '20px' : item === FILTERTYPES.UPCOMING ? '30px' : '50px',
                        paddingRight:
                          item === FILTERTYPES.SELFPACED ? '20px' : item === FILTERTYPES.UPCOMING ? '30px' : '50px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        margin: '10px',
                        marginLeft: activeTemplate === TemplateType.TemplateOne ? '5px' : '',
                        marginRight: activeTemplate === TemplateType.TemplateOne ? '5px' : '',
                        display: 'flex',
                        justifyContent: 'center',
                        textTransform: 'none',
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: item == filter ? textColor : btnColor,
                        border: `1px solid ${btnColor}`,
                        minWidth: 'auto',
                        borderRadius: '5px',
                        marginRight: '10px',
                      }}
                      onClick={e => {
                        setFilter(e.target.value);
                        setFilterClicked(true);
                      }}
                    >
                      {item == FILTERTYPES.SELFPACED ? 'Self-Paced' : item} {FilteredSessionsCount(sessions, item)}
                    </Button>
                    {/* </Box> */}
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <Grid
            item
            lg={activeTemplate == TemplateType.TemplateOne && !isCoach && !isPurchased ? 12 : 5}
            sm={12}
            xs={12}
            style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}
          >
            <div style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'center', color: progressbarColor, fontWeight: '900' }}>
                <p style={{ marginBottom: '7px' }}>Completion Progress </p>
              </div>
              <ProgressBar completed={percentageCompleted} bgColor={progressbarColor} />
            </div>

            {contribution?.isPurchased && CanClinetInviteFrieds(contribution) && (
              <>
                <S.StyledShareButton
                  themedColor={themedColor}
                  style={{ textWrap: 'nowrap', margin: '10px', paddingTop: '20px' }}
                  onClick={() => {
                    setInviteModalOpened(true);
                  }}
                  isDarkModeEnabled={contribution?.isDarkModeEnabled}
                >
                  <S.StyledShareIcon />
                  Invite Friends
                </S.StyledShareButton>
                {InviteModalOpened && (
                  <ShareModalContainer
                    isOpen={InviteModalOpened}
                    onSubmit={handleModalSubmit}
                    onModalClose={handleModalClose}
                    paymentInfo={contribution?.paymentInfo}
                    isInvoiced={contribution?.isInvoiced}
                    contributionId={contribution?.id}
                    symbol={contribution?.defaultSymbol}
                    setEmailsError={setEmailsError}
                    emailsError={emailsError}
                    type={contribution.type}
                    IsModalForClient={!isCoach}
                    inviteClientType={contribution?.inviteClientType}
                  />
                )}
              </>
            )}
            {isCoach && sessions.filter(s => s.isPrerecorded).length > 0 && (
              <S.StyledShareButton onClick={onShareButtonClick} themedColor={progressbarColor}>
                Client Self-Paced Engagement Data
              </S.StyledShareButton>
            )}
          </Grid>
          <Modal
            isOpen={connectStripeModal}
            onCancel={() => {
              setConnectStripeModal(false);
            }}
            onSubmit={() => {
              setConnectStripeModal(false);
            }}
            hiddenCancel
            title="Error"
            submitTitle="OK"
          >
            <div style={{ color: newThemedTextColor }}>No Enrollment found for Contribution: {contribution.title}</div>
          </Modal>
        </Grid>
      </StickyPortion>
      {/* {!isEmpty(sessions) &&
        orderBy(sessions, ['startTime'], ['asc']).map((s, i) => (
          <Session
            key={s.id}
            number={i + 1}
            session={s}
            contributionId={contributionId}
            contributionTimeZoneId={timeZoneId}
            isPurchased={isPurchased}
            renderSessionTimeActions={renderSessionTimeActions}
          />
        ))} */}

      {/* {filter === 'Upcoming' && (
        <Grid container>
          <Grid item sm={3} xs={12}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '70px' }}>
              {TABS.map(item => {
                return (
                  <StyledTab
                    color={item == activeTab ? textColor : btnColor}
                    bgColor={item == activeTab ? btnColor : 'white'}
                    borderColor={btnColor}
                    onClick={() => setActiveTab(item)}
                    marginLeft={mobileView && item === 'Live' ? '10px' : '0px'}
                  >
                    {item}
                  </StyledTab>
                );
              })}
            </div>
          </Grid>
        </Grid>
      )} */}

      <StyledSessiosContainer clientPreviewMode={clientPreviewMode}>
        {!isEmpty(sessions) &&
          orderBy(
            FilteredSessions(sessions, filter),
            [filter === 'Past' ? 'completedDateTime' : 'startTime'],
            [filter === 'Past' ? 'desc' : 'asc'],
          ).map((s, i) => (
            <Session
              focusedSession={sessionId || sessionToBeExpanded}
              key={s.id}
              number={i + 1}
              session={s}
              contribution={contribution}
              contributionId={contributionId}
              contributionTimeZoneId={timeZoneId}
              isPurchased={isPurchased}
              changeShowPopUp={changeShowPopUp}
              renderSessionTimeActions={renderSessionTimeActions}
              filter={filter}
              filterClicked={filterClicked}
              convertedToSelfPaced={convertedToSelfPaced}
            />
          ))}
      </StyledSessiosContainer>
    </>
  );
};

CourseSessions.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
    isPurchased: PropTypes.bool,
    allowClientsToInvite: PropTypes.bool,
    isDarkModeEnabled: PropTypes.bool,
    inviteClientType: PropTypes.string,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    timeZoneId: PropTypes.string,
  }).isRequired,
  renderSessionTimeActions: PropTypes.func.isRequired,
};

export default CourseSessions;
