import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { PAID_TIER_TITLES } from '../../../../constants';
import useRouter from 'hooks/useRouter';
import useAccount from 'hooks/useAccount';
import { useSelector, useDispatch } from 'react-redux';
import { getUpcomingCreated } from 'services/contributions.service';
import { postDiscountCodes, putDiscountCodes, getDiscountCodeById } from 'services/discountCodes.service';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../../../constants';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import CreateButton from 'components/UI/UpgradeButton';
import { setCohereAcademyStatus } from 'actions/update-user';
import { Formik, Form } from 'formik';
import Button from 'components/FormUI/Button';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import { Icon } from '@mdi/react';
import { mdiLoading } from '@mdi/js';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { toRem } from 'utils/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { ContributionType } from 'helpers/constants';
import useDiscountCodes from 'hooks/useDiscountCodes';
import Dictionary from './dictionary.json';
import Loader from 'components/UI/Loader';
import { getCouponValidationSchema } from 'utils/validation';
import { useHeader } from 'hooks/usePageActions';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import { useStyles } from './style';
import { LeakAddTwoTone } from '@material-ui/icons';
import useNotifications from 'hooks/useNotifications';
import usePaidTier from 'hooks/usePaidTier';
import { RadioGroup, FormControl, FormControlLabel, Radio, FormLabel, Typography } from '@material-ui/core';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import * as paidTier from 'selectors/paidTier';
const PAGE = {
  EDIT: 'editCoupon',
  CREATE: 'createCoupon',
};

const ButtonsContainer = styled.div`
  margin-top: ${toRem(24)};
`;
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontWeight: 350,
    fontSize: '16px',
  },
}));
const CouponsPage = () => {
  useHeader('Coupons');
  const classes = useStyles();
  const theme = useTheme();
  const tooltipText = 'for optimal results no spaces and all capital letters is best';
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { successMessage, errorMessage } = useNotifications();
  const userDetail = useSelector(state => state.account.user);
  const { history, pathname } = useRouter();
  const { user } = useAccount();
  // const { isLaunchPlan } = usePaidTier();
  const [activeCurrency, setActiveCurrency] = useState('$');
  // TODO: use value from store so we wont have to load each time
  const [contributions, setContributions] = useState([]);

  const [couponType, setCouponType] = useState('Percent Discount');
  const [metadata, setMetadata] = useState(null);
  const [filteredContributions, setFilteredContributions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const formRef = useRef(null);

  const { title, couponCode, discount, thisCanBeUsed, codeAppliesTo, expiration } = Dictionary;

  const isEditPage = pathname.includes(PAGE.EDIT);
  const isCreatePage = pathname.includes(PAGE.CREATE);

  const { code } = useDiscountCodes();
  const [discountType, setDiscountType] = useState(
    isEditPage && code?.amountOff && code?.amountOff !== null ? 'FixedAmount' : 'Percentage',
  );
  const initialDiscountCycle = isEditPage && code?.discountFirstBillingCycleOnly ? 'firstCycleOnly' : 'everyCycle';
  const [discountCycle, setDiscountCycle] = useState(initialDiscountCycle);

  const [showBillingCycleOption, setShowBillingCycleOption] = useState(false);

  const getNoOfMonths = () => {
    const numbers = Array.from({ length: 11 }, (_, i) => ({
      title: i + 1,
      value: i + 1,
    }));
    const key = 'value';
    return [...new Map(numbers.map(item => [item[key], item])).values()];
  };
  useEffect(() => {
    if (isEditPage && code?.discountFirstBillingCycleOnly && filteredContributions.length > 0) {
      const initialShowBillingCycleOption = filteredContributions.some(
        m => m.type === ContributionType.contributionCommunity || m.type === ContributionType.contributionMembership,
      );
      setShowBillingCycleOption(initialShowBillingCycleOption);
    }
  }, [filteredContributions, isEditPage]);
  const initialValues = {
    DurationInMonths: isEditPage && code ? code.durationInMonths : 1,
    duration: 'forever',
    coachId: user.id,
    name: isEditPage && code ? code.name : '',
    allowedContributionTypes: [],
    maxRedemptions:
      isEditPage && code && code.maxRedemptions && !isNaN(code.maxRedemptions) ? Number(code.maxRedemptions) : null,
    redeemBy: isEditPage && code ? code.redeemBy : null,
    percentOff: isEditPage && code && code.percentOff && !isNaN(code.percentOff) ? Number(code.percentOff) : 0,
    amountOff: isEditPage && code && code.amountOff && !isNaN(code.amountOff) ? Number(code.amountOff) : 0,
    couponDiscountType: discountType,
    SelectedCurrency: isEditPage && code ? code.currency.toUpperCase() : 'USD',
    paymentMethod: user.defaultPaymentMethod,
    taxType: '',
    isInvoiced: false,
  };

  const onBackClick = () => {
    history.goBack();
  };

  useEffect(() => {
    if (isEditPage && !code?.id?.length) {
      onBackClick();
    }
    setLoading(true);
    getUpcomingCreated(user.id)
      .then(({ contributionsForTable }) => {
        setContributions(contributionsForTable);
        setFilteredContributions(
          contributionsForTable.filter(
            item =>
              item.currency === initialValues.SelectedCurrency && item.paymentType === initialValues.paymentMethod,
          ),
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    if (isEditPage && !metadata && code?.metadata) {
      let meta = new Map(metadata);
      Object.entries(code.metadata).forEach(item => {
        if (item.length > 1) {
          meta.set(item[0], item[1]);
        }
      });
      setMetadata(meta);
    }
  }, []);
  const dispatch = useDispatch();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  // const { isLaunchPlan } = usePaidTier();
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);
  const pickContribution = (e, contribution) => {
    const meta = new Map(metadata);
    if (!e.target.checked) {
      if (
        contribution.type == ContributionType.contributionCommunity ||
        contribution.type == ContributionType.contributionMembership
      ) {
        setShowBillingCycleOption(false);
      }
      meta.delete(contribution.id, contribution.title);
    }
    if (e.target.checked) {
      if (
        contribution.type == ContributionType.contributionCommunity ||
        contribution.type == ContributionType.contributionMembership
      ) {
        setShowBillingCycleOption(true);
      }
      meta.set(contribution.id, contribution.title);
    }
    setMetadata(meta);

    let selectedContributionIdsFromMeta = Array.from(meta.keys());
    let selectedContributions = filteredContributions.filter(m => selectedContributionIdsFromMeta.includes(m.id));

    if (
      selectedContributions.some(
        m => m.type === ContributionType.contributionCommunity || m.type === ContributionType.contributionMembership,
      )
    ) {
      setShowBillingCycleOption(true);
    }
  };

  const getAvailableCurrencies = () => {
    const currencies = contributions?.map(c => ({
      title: c.currency,
      value: c.currency,
      symbol: c.symbol,
    }));
    const key = 'value';

    return [...new Map(currencies.map(item => [item[key], item])).values()];
  };

  const filterContributions = (value, paymentmethod) => {
    const shallowCopy = [...contributions];
    const filtered = shallowCopy.filter(item => item.currency === value && item.paymentType === paymentmethod);
    setFilteredContributions(filtered);
  };
  const filterPaymentContributions = (value, currnecy) => {
    const shallowCopy = [...contributions];
    const filtered = shallowCopy.filter(item => item.paymentType === value && item.currency === currnecy);
    setFilteredContributions(filtered);
  };

  const submitHanler = useCallback(
    values => {
      setSaving(true);
      if (isEditPage) {
        putDiscountCodes({
          id: code.id,
          name: values.name,
          metadata: metadata && Object.fromEntries(metadata),
          paymentType: values.paymentMethod,
          discountFirstBillingCycleOnly: discountCycle == 'firstCycleOnly' ? true : false,
        })
          .then(res => {
            setSaving(false);
            history.push('/account/payment');
          })
          .catch(err => {
            const message = err?.response?.data?.message;
            if (message) {
              setSaving(false);
              errorMessage(message);
            }
          });
      } else if (isCreatePage) {
        postDiscountCodes(
          discountType === 'FixedAmount'
            ? {
                durationInMonths: discountCycle == 'firstCycleOnly' ?  values.DurationInMonths : null,
                duration: 'forever',
                coachId: user.id,
                name: values.name,
                metadata: metadata && Object.fromEntries(metadata),
                allowedContributionTypes: values.allowedContributionTypes,
                maxRedemptions:
                  values.maxRedemptions && !isNaN(values.maxRedemptions) ? Number(values.maxRedemptions) : null,
                redeemBy: values.redeemBy,
                SelectedCurrency: values.SelectedCurrency,
                amountOff: values.amountOff && !isNaN(values.amountOff) ? Number(values.amountOff) : null,
                couponDiscountType: discountType,
                paymentType: values.paymentMethod,
                discountFirstBillingCycleOnly: discountCycle == 'firstCycleOnly' ? true : false,
              }
            : {
                durationInMonths: discountCycle == 'firstCycleOnly' ? values.DurationInMonths : null,
                duration: 'forever',
                coachId: user.id,
                name: values.name,
                metadata: metadata && Object.fromEntries(metadata),
                allowedContributionTypes: values.allowedContributionTypes,
                maxRedemptions:
                  values.maxRedemptions && !isNaN(values.maxRedemptions) ? Number(values.maxRedemptions) : null,
                redeemBy: values.redeemBy,
                SelectedCurrency: values.SelectedCurrency,
                percentOff: values.percentOff && !isNaN(values.percentOff) ? Number(values.percentOff) : null,
                couponDiscountType: discountType,
                paymentType: values.paymentMethod,
                discountFirstBillingCycleOnly: discountCycle == 'firstCycleOnly' ? true : false,
              },
        )
          .then(res => {
            setSaving(false);
            history.push('/account/payment');
          })
          .catch(err => {
            setSaving(false);
            const message = err?.response?.data?.message;
            if (message) {
              errorMessage(message);
            }
          });
      }
    },
    [metadata, discountType, discountCycle],
  );

  const handleDiscountTypeChanged = type => {
    setCouponType(type);

    if (formRef?.current?.values) {
      formRef.current.values.percentOff = '';
      formRef.current.values.amountOff = '';
    }
    // setPercentOff(null);
    // setAmountOff(null);
  };

  return (
    <div className={classes.root}>
      {loading && <Loader />}
      <Grid
        container
        alignItems="stretch"
        classes={{
          container: classes.container,
        }}
      >
        <Grid container xs={12}>
          <Grid container item xs={4}>
            <button type="button" className={classes.backButton} onClick={onBackClick}>
              <ArrowBackIosIcon />
              Back
            </button>
          </Grid>
          <Grid item xs={8}>
            {isEditPage && <h4>{title.edit}</h4>}
            {isCreatePage && <h4>{title.create}</h4>}
          </Grid>
        </Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={() => getCouponValidationSchema(discountType)}
          innerRef={formRef}
          onSubmit={submitHanler}
        >
          {({ setFieldValue, values, validateForm }) => {
            return (
              <Form id="couponForm">
                <Grid container spacing={4} className={classes.row}>
                  <Grid item sm={12} xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <span>
                      <Input label="Coupon Code" type="text" name="name" />
                    </span>
                    <Tooltip
                      title={tooltipText}
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </Tooltip>
                  </Grid>
                </Grid>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="couponDiscountType"
                    style={{ marginTop: '10px' }}
                    onChange={e => {
                      setDiscountType(e.target.value);
                    }}
                    value={discountType}
                  >
                    <FormControlLabel
                      value="Percentage"
                      disabled={isEditPage}
                      control={<Radio color="primary" />}
                      label="Percentage (%)"
                    />
                    <FormControlLabel
                      value="FixedAmount"
                      disabled={isEditPage}
                      control={<Radio color="primary" />}
                      label="Amount"
                    />
                  </RadioGroup>
                </FormControl>
                {discountType === 'Percentage' && (
                  <Grid container spacing={4} className={classes.row}>
                    <Grid item sm={12} xs={12}>
                      <div className="add-suffix input-suffix">
                        <Input
                          disabled={isEditPage}
                          label="Percent Discount"
                          type="number"
                          name="percentOff"
                          value={values.percentOff < 0 ? 0 : values.percentOff >= 100 ? 100 : values.percentOff}
                          min={0}
                          max={100}
                          InputProps={{
                            endAdornment: <div>%</div>,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
                {discountType === 'FixedAmount' && (
                  <Grid container spacing={4} className={classes.row}>
                    <Grid item sm={12} xs={12}>
                      <div className="add-suffix input-suffix">
                        <Input
                          disabled={isEditPage}
                          label="Amount"
                          type="number"
                          value={values.amountOff < 0 ? 0 : values.amountOff}
                          name="amountOff"
                          min={0}
                          InputProps={{
                            endAdornment: <div>{activeCurrency}</div>,
                          }}
                        />
                        {discountType === 'FixedAmount' && values.amountOff === '' && (
                          <CommonErrorMessage align="left" message="Amount is required" />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                )}
                {couponType === 'USD Discount' && (
                  <Grid container spacing={4} className={classes.row}>
                    <Grid item sm={12} xs={12}>
                      <div className="add-suffix dollar-suffix">
                        <Input disabled={isEditPage} label="USD Discount" type="number" name="amountOff" min={1} />
                      </div>
                    </Grid>
                  </Grid>
                )}
                {/* <Grid container spacing={4} className={classes.row}>
                  <Grid item sm={12} xs={12}>
                  <select name="discountType" onChange={e => handleDiscountTypeChanged(e.target.value)}>
                      {discount.discountOptions.map(option => (
                        <option value={option}>{option}</option>
                      ))}
                    </select>
                  </Grid>
                </Grid> */}
                {contributions && (
                  <Grid item spacing={4} container sm={7} xs={12}>
                    <Grid item container xs={12}>
                      <Select
                        name="SelectedCurrency"
                        fullWidth
                        disabled={isEditPage}
                        onChange={({ target: { value } }) => {
                          const isMatch = getAvailableCurrencies().find(c => c.value === value);
                          if (isMatch) {
                            setActiveCurrency(isMatch.symbol);
                          }
                          filterContributions(value, values.paymentMethod);
                          setFieldValue('SelectedCurrency', value);
                        }}
                        label="Currency"
                        value={values.SelectedCurrency}
                        items={getAvailableCurrencies()}
                      />
                    </Grid>
                  </Grid>
                )}
                {user?.isStandardAccount && (
                  <Grid item spacing={4} container sm={7} xs={12}>
                    <Grid item container xs={12}>
                      <Select
                        label="Payment Processor"
                        name="paymentMethod"
                        fullWidth
                        // disabled={isFeeDisabled}
                        onChange={({ target: { value } }) => {
                          filterPaymentContributions(value, values.SelectedCurrency);
                          setFieldValue('paymentMethod', value);
                        }}
                        value={values.paymentMethod}
                        items={
                          user.connectedStripeAccountId === null
                            ? [
                                {
                                  value: 'Advance',
                                  title: `${
                                    userDetail.firstName.charAt(0).toUpperCase() + userDetail.firstName.slice(1)
                                  }'s Stripe Account`,
                                },
                              ]
                            : [
                                { value: 'Simple', title: 'Cohere Payment Processor' },
                                {
                                  value: 'Advance',
                                  title: `${
                                    userDetail.firstName.charAt(0).toUpperCase() + userDetail.firstName.slice(1)
                                  }'s Stripe Account`,
                                },
                              ]
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={4} className={classes.row}>
                  <Grid item sm={12} xs={12}>
                    <Input
                      disabled={isEditPage}
                      label={thisCanBeUsed.subTitle}
                      type="number"
                      name="maxRedemptions"
                      min={1}
                      helperText={thisCanBeUsed.helpLabel}
                    />
                  </Grid>
                </Grid>
                {showBillingCycleOption && (
                  <Grid container item xs={12} alignItems="center" spacing={2}>
                    <Grid item xs={12} disabled={isEditPage} style={{ padding: '2px', marginTop: '8px' }}>
                      <Typography
                        variant="subtitle1"
                        style={{ color: isEditPage ? 'gray' : 'inherit', fontWeight: 300, fontSize: '14px' }}
                      >
                        For Memberships and Communities Only
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '2px', marginBottom: '10px' }}>
                      <FormControl component="fieldset" fullWidth>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                            <RadioGroup
                              value={discountCycle}
                              name="discountFirstBillingCycleOnly"
                              row
                              onChange={e => {
                                setDiscountCycle(e.target.value);
                              }}
                            >
                              <StyledFormControlLabel
                                value="everyCycle"
                                control={<Radio color="primary" />}
                                label="Discount Every Billing Cycle"
                                disabled={isEditPage}
                                style={{ marginBottom: '0px' }}
                              />
                              <StyledFormControlLabel
                                value="firstCycleOnly"
                                control={<Radio color="primary" />}
                                label="Discount Billing Cycle"
                                disabled={isEditPage}
                                style={{ marginBottom: '0px' }}
                              />
                            </RadioGroup>
                            {discountCycle == 'firstCycleOnly' && (
                              <Tooltip
                                title={
                                  'Discounts for multiple billing cycles are available for monthly subscriptions. For yearly subscriptions, the discount applies only to the first billing cycle.'
                                }
                                arrow
                                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                              >
                                <InfoIcon htmlColor={colors.lightBrown} />
                              </Tooltip>
                            )}

                            {discountCycle == 'firstCycleOnly' && (
                              <Grid item xs={1} style={{ marginLeft: '15px' }}>
                                <Select
                                  name="DurationInMonths"
                                  fullWidth
                                  disabled={isEditPage}
                                  onChange={({ target: { value } }) => {
                                    setFieldValue('DurationInMonths', value);
                                  }}
                                  value={
                                    values.DurationInMonths == null || values.DurationInMonths == ' '
                                      ? 1
                                      : values.DurationInMonths
                                  }
                                  items={getNoOfMonths()}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={4} className={classes.row}>
                  <Grid item sm={12} xs={12}>
                    <div className={classes.codeAppliesTo}>
                      <p>{codeAppliesTo.subTitle}</p>
                      <div>
                        {filteredContributions.map(contribution => {
                          if (contribution?.status === 'Approved') {
                            return (
                              <div key={contribution.id}>
                                <input
                                  type="checkbox"
                                  name="contributionCheckbox"
                                  onChange={e => pickContribution(e, contribution)}
                                  checked={metadata?.has(contribution.id)}
                                />
                                <label htmlFor="contributionCheckbox">{contribution.title}</label>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={4} className={classes.row}>
                  <Grid item sm={12} xs={12}>
                    <DateTimePicker
                      disabled={isEditPage}
                      label={expiration.subTitle}
                      type="date"
                      format="MMMM Do YYYY"
                      name="redeemBy"
                      helperText={expiration.helpLabel}
                    />
                    {values.redeemBy && !isEditPage && (
                      <span
                        className="pointer"
                        onClick={() => {
                          values.redeemBy = null;
                          validateForm();
                        }}
                      >
                        Clear
                      </span>
                    )}
                  </Grid>
                </Grid>
                <Grid container justify={mobileView && 'center'}>
                  <ButtonsContainer>
                    <Button disabled={saving || isLaunchPlan} type="submit">
                      {saving ? <Icon path={mdiLoading} size={1} spin={1} /> : 'Save Coupon'}
                    </Button>
                  </ButtonsContainer>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </div>
  );
};

export default CouponsPage;
