import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon } from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { useHttp, useAccount, useVideoChatActions } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Popup from 'components/Popup/Popup';
import { Contribution } from './Contribution';
import PaginationRounded from './PaginationAllContributions';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);

const BoldTableCellNext = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '30px',
  },
})(TableCell);

const BoldTableCellStatus = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '10px',
  },
})(TableCell);

const BoldTableCellEdit = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '25px',
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);
const StyledIcon = styled(Icon)`
  // margin: 0 10px;
`;
const ContributionsTable = ({ totalCount, childpageNumber, rows, isArchived }) => {
  const location = useLocation();
  const [popupText, setPopupText] = useState(null);
  const { request } = useHttp();
  const { user } = useAccount();
  const [contributionData, setContributionData] = useState(rows);
  const { startVideoChat } = useVideoChatActions();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePageNumber = useCallback(page => {
    childpageNumber(page);
  }, []);
  const onLaunchSession = (
    contributionId,
    type,
    classGroupId,
    classId,
    chatSid,
    title,
    { RecordParticipantsOnConnect },
  ) => {
    request('/Video/CreateRoomAndGetToken', 'POST', {
      contributionId,
      classId,
      RecordParticipantsOnConnect,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ room: { sid: roomId }, token }) =>
      startVideoChat({
        contributionId,
        type,
        sessionId: classGroupId,
        classId,
        title,
        roomId,
        chatId: chatSid,
        token,
        deleteRoomOnVideoEnd: true,
      }),
    );
  };
  useEffect(() => {
    setContributionData(rows);
  }, [rows]);

  const handleClosePopup = () => {
    setPopupText(null);
  };
  const deleteIndex = indexData => {
    const data1 = contributionData.filter((item, index) => index != indexData);
    setContributionData(data1);
  };
  const archiveIndex = indexData => {
    const data2 = contributionData.filter((item, index) => index != indexData);
    setContributionData(data2);
  };
  return (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Service Name</BoldTableCell>
              <BoldTableCellStatus>Status</BoldTableCellStatus>
              <BoldTableCell>Type</BoldTableCell>
              <BoldTableCellNext>Next Session</BoldTableCellNext>
              <BoldTableCell align="center">Invite</BoldTableCell>
              <BoldTableCell align="center">Clients Served</BoldTableCell>
              <BoldTableCell align="center">Revenue Earned</BoldTableCell>
              <BoldTableCellEdit>Chat</BoldTableCellEdit>
              <BoldTableCellEdit>Edit</BoldTableCellEdit>
              <BoldTableCellEdit>Analytics</BoldTableCellEdit>
              <BoldTableCell>{/* <StyledIcon path={mdiDelete} size={1} /> */}</BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contributionData.map((row, index, contributionData) => (
              <Contribution
                rows={contributionData}
                index={index}
                key={row.id}
                {...row}
                setPopupText={setPopupText}
                onLaunchSession={onLaunchSession}
                deleteIndex={deleteIndex}
                archiveIndex={archiveIndex}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {/* {location.pathname === '/contributions/all' && totalCount > 10 && (
        <div style={{ padding: '25px 0px 25px 0px', display: 'flex', justifyContent: 'center' }}>
          <PaginationRounded Totalcount={totalCount} pageNumber={handlePageNumber} />
        </div>
      )} */}
      <Popup title="Rejected reason" text={popupText} open={popupText} onCloseText="OK" onClose={handleClosePopup} />
    </>
  );
};

ContributionsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  isArchived: PropTypes.bool,
};

ContributionsTable.defaultProps = {
  rows: [],
  isArchived: false,
};

export default ContributionsTable;
