import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import ChatButton from 'components/UI/ListButtons/ChatButton';
import Loader from 'components/UI/Loader';
import { addhttp } from 'utils/utils';
import useRouter from 'hooks/useRouter';
import '../../../pages/AllApplicationsPage/ApplicationPage.scss';
import { Avatar, IconButton } from '@material-ui/core';
import { getInitialSymbol } from 'components/UI/Header';
import NoteIcon from 'pages/ClientPage/components/NoteIcon';

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})(TableCell);

export const Client = ({ index, row, setClientId }) => {
  const { id, name, image, recentContributionId, recentContributionName, revenueEarned } = row;
  const contributionURL =
    row.isContribution === false
      ? `/lead-magnet-view/${row?.recenetContributionId}/landingpage`
      : `/contribution-view/${row?.recenetContributionId}/about`;
  const clientURL = `/client/${id}`;
  const [loader, setLoader] = useState(false);

  const { domain } = useRouter();
  const currencies = Object.keys(revenueEarned);

  return (
    <>
      <TableRow key={index}>
        <NarrowTableCell component="th" scope="row">
          <div style={{ display: 'flex' }}>
            <Avatar
              alt={name}
              src={image}
              style={{
                margin: 'auto 10px auto 0',
                width: '30px',
                height: '30px',
                fontSize: '16px',
              }}
            >
              {getInitialSymbol(name)}
            </Avatar>
            <Link to={clientURL} className="d-flex">
              <p style={{ margin: 'auto 0' }}>{name}</p>
            </Link>
          </div>
        </NarrowTableCell>
        {row?.recenetContributionId === null && row.isImported ? (
          <NarrowTableCell style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{row?.source}</NarrowTableCell>
        ) : (
          <NarrowTableCell style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
            <Link to={contributionURL}>{row?.contributionTitle}</Link>
          </NarrowTableCell>
        )}
        <NarrowTableCell>
          {currencies.reduce((accumulatedVal, currentVal) => {
            return accumulatedVal
              .concat(accumulatedVal ? ',' : '')
              .concat(revenueEarned[currentVal])
              .concat(` ${currentVal?.toUpperCase()}`);
          }, '')}
        </NarrowTableCell>
        <NarrowTableCell align="center">
          <ChatButton
            className="icon-button"
            handleClick={() => {
              window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${id}`));
            }}
          />
        </NarrowTableCell>
        <NarrowTableCell align="center">
          <IconButton className="icon-button" onClick={() => setClientId(id)}>
            <NoteIcon fillColor="#215C73" />
          </IconButton>
        </NarrowTableCell>
      </TableRow>
      {loader && <Loader />}
    </>
  );
};
Client.propTypes = {
  index: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    recentContributionId: PropTypes.string.isRequired,
    recentContributionName: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    revenueEarned: PropTypes.string.isRequired,
  }),
  setClientId: PropTypes.func.isRequired,
};

Client.defaultProps = {
  row: {
    id: '',
    name: '',
    recentContributionId: '',
    recentContributionName: '',
    image: '',
    revenueEarned: {
      usd: '$0.0',
    },
  },
};
