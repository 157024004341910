import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';
import moment from 'moment';
import { useAccount } from 'hooks';

import * as leadMagnetService from 'services/leadMagnets.service';
import { ContributionType, LeadMagnetStatus } from 'helpers/constants';
import { getCurrentSessionTime } from 'utils/getSessionTime';
import { RESPONSE_CODES, PAYMENT_OPTIONS } from '../constants';
import { redirectTo } from 'services/links';
import { cloneDeep, isArray, isEqual, omit, sortBy, toNumber, uniqBy } from 'lodash';
import { batch } from 'react-redux';

const LEAD_MAGNET_STATUSES = {
  DRAFT: 'Draft',
};

export const CREATE_LEAD_MAGNET_REQUEST = 'CREATE_LEAD_MAGNET_REQUEST';
export const CREATE_LEAD_MAGNET_SUCCESS = 'CREATE_LEAD_MAGNET_SUCCESS';
export const CREATE_LEAD_MAGNET_ERROR = 'CREATE_LEAD_MAGNET_ERROR';

export const FETCH_LEAD_MAGNET_REQUEST = 'FETCH_LEAD_MAGNET_REQUEST';
export const FETCH_LEAD_MAGNET_SUCCESS = 'FETCH_LEAD_MAGNET_SUCCESS';
export const FETCH_LEAD_MAGNET_ERROR = 'FETCH_LEAD_MAGNET_ERROR';
export const FETCH_LEAD_MAGNET_CANCEL = 'FETCH_LEAD_MAGNET_CANCEL';

export const UPDATE_LEAD_MAGNET_REQUEST = 'UPDATE_LEAD_MAGNET_REQUEST';
export const UPDATE_LEAD_MAGNET_SUCCESS = 'UPDATE_LEAD_MAGNET_SUCCESS';
export const UPDATE_LEAD_MAGNET_ERROR = 'UPDATE_LEAD_MAGNET_ERROR';
export const SHOW_ERROR_POPUP = 'SHOW_ERROR_POPUP';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_WELCOME_EMAIL_ENABLED = 'SET_WELCOME_EMAIL_ENABLED';

export const FETCH_LEAD_MAGNETS_REQUEST = 'FETCH_LEAD_MAGNETS_REQUEST';
export const FETCH_LEAD_MAGNETS_SUCCESS = 'FETCH_LEAD_MAGNETS_SUCCESS';
export const FETCH_LEAD_MAGNETS_ERROR = 'FETCH_LEAD_MAGNETS_ERROR';
export const FETCH_LEAD_MAGNETS_UPDATE = 'FETCH_LEAD_MAGNETS_UPDATE';

// export const FETCH_UPCOMING_CREATED_REQUEST = 'FETCH_UPCOMING_CREATED_REQUEST';
// export const FETCH_UPCOMING_CREATED_SUCCESS = 'FETCH_UPCOMING_CREATED_SUCCESS';
// export const FETCH_UPCOMING_CREATED_ERROR = 'FETCH_UPCOMING_CREATED_ERROR';

// export const FETCH_UPCOMING_CREATED_WITH_TYPE_REQUEST = 'FETCH_UPCOMING_CREATED_WITH_TYPE_REQUEST';
// export const FETCH_UPCOMING_CREATED_WITH_TYPE_SUCCESS = 'FETCH_UPCOMING_CREATED_WITH_TYPE_SUCCESS';
// export const FETCH_UPCOMING_CREATED_WITH_TYPE_ERROR = 'FETCH_UPCOMING_CREATED_WITH_TYPE_ERROR';

export const FETCH_ARCHIVED_LEAD_MAGNETS_REQUEST = 'FETCH_ARCHIVED_LEAD_MAGNETS_REQUEST';
export const FETCH_ARCHIVED_LEAD_MAGNETS_SUCCESS = 'FETCH_ARCHIVED_LEAD_MAGNETS_SUCCESS';
export const FETCH_ARCHIVED_LEAD_MAGNETS_ERROR = 'FETCH_ARCHIVED_LEAD_MAGNETS_ERROR';
export const FETCH_ARCHIVED_LEAD_MAGNETS_UPDATE = 'FETCH_ARCHIVED_LEAD_MAGNETS_UPDATE';

// export const RESCHEDULE_SESSION_REQUEST = 'RESCHEDULE_SESSION_REQUEST';
// export const RESCHEDULE_SESSION_SUCCESS = 'RESCHEDULE_SESSION_SUCCESS';
// export const RESCHEDULE_SESSION_ERROR = 'RESCHEDULE_SESSION_ERROR';

// export const GET_CONTRIBUTION_FROM_LS = 'GET_CONTRIBUTION_FROM_LS';
export const SAVE_LEAD_MAGNET_TO_LS = 'SAVE_LEAD_MAGNET_TO_LS';
export const RESET_LEAD_MAGNET = 'RESET_LEAD_MAGNET';
export const SAVE_LEAD_MAGNET = 'SAVE_LEAD_MAGNET';

// export const FETCH_CONTRIBUTION_TIMES_REQUEST = 'FETCH_CONTRIBUTION_TIMES_REQUEST';
// export const FETCH_CONTRIBUTION_TIMES_SUCCESS = 'FETCH_CONTRIBUTION_TIMES_SUCCESS';
// export const FETCH_CONTRIBUTION_TIMES_ERROR = 'FETCH_CONTRIBUTION_TIMES_ERROR';
// export const SET_RESCHEDULE_MODAL = 'SET_RESCHEDULE_MODAL';
// export const CALCULATE_CREATE_SLOTS_REQUEST = 'CALCULATE_CREATE_SLOTS_REQUEST';
// export const CALCULATE_CREATE_SLOTS_SUCCESS = 'CALCULATE_CREATE_SLOTS_SUCCESS';
// export const SET_PICK_ANOTHER_TIME = 'SET_PICK_ANOTHER_TIME';
// export const CALCULATE_CREATE_SLOTS_ERROR = 'CALCULATE_CREATE_SLOTS_ERROR';

// export const FETCH_EDIT_CONTRIBUTION_TIMES_REQUEST = 'FETCH_EDIT_CONTRIBUTION_TIMES_REQUEST';
// export const FETCH_EDIT_CONTRIBUTION_TIMES_SUCCESS = 'FETCH_EDIT_CONTRIBUTION_TIMES_SUCCESS';
// export const FETCH_EDIT_CONTRIBUTION_TIMES_ERROR = 'FETCH_EDIT_CONTRIBUTION_TIMES_ERROR';

// export const FETCH_CONTRIBUTIONS_ADMIN_REQUEST = 'FETCH_CONTRIBUTIONS_ADMIN_REQUEST';
// export const FETCH_CONTRIBUTIONS_ADMIN_SUCCESS = 'FETCH_CONTRIBUTIONS_ADMIN_SUCCESS';
// export const FETCH_CONTRIBUTIONS_ADMIN_ERROR = 'FETCH_CONTRIBUTIONS_ADMIN_ERROR';

// export const SET_CONTRIBUTIONS_LOADER = 'SET_CONTRIBUTIONS_LOADER';
// export const SET_INVITE_CODE = 'SET_INVITE_CODE';
export const SET_POP_UP = 'SET_POP_UP'; // Note: used in joyride, not used yet
// export const SET_POP_UP_BANK = 'SET_POP_UP_BANK';
// export const SET_PRICE_CODE = 'SET_PRICE_CODE';
// export const SET_SINGLE_CODE = 'SET_SINGLE_CODE';
// export const SET_COUPON_CODE = 'SET_COUPON_CODE';
// export const SET_SESSION_CODE = 'SET_SESSION_CODE';
// export const SET_SHOW_THANK_YOU_PAGE = 'SET_SHOW_THANK_YOU_PAGE';
export const SHOW_DUPLICATE_LOADING = 'SHOW_DUPLICATE_LOADING';
export const HIDE_DUPLICATE_LOADING = 'HIDE_DUPLICATE_LOADING';
// export const SET_REFETCH_CONTRIBUTION = '  SET_REFETCH_CONTRIBUTION';

// export const getContributionFromLS = createAction(GET_CONTRIBUTION_FROM_LS);
export const saveLeadMagnetToLS = createAction(SAVE_LEAD_MAGNET_TO_LS);
export const resetLeadMagnet = createAction(RESET_LEAD_MAGNET);
export const saveLeadMagnet = createAction(SAVE_LEAD_MAGNET);
// export const firstTimeRedirect = createAction('UPDATE_FOR_FIRST_REDIRECT');
// export const updateSessionAttachments = createAction('UPDATE_SESSION_ATTACHMENTS');
// export const updateSessionTimeAttachments = createAction('UPDATE_SESSIONTIME_ATTACHMENTS');
// export const updateSession = createAction('UPDATE_SESSION');
// export const addSessionAttachments = createAction('ADD_SESSION_ATTACHMENTS');
// export const addSessionTimeAttachments = createAction('ADD_SESSION_TIME_ATTACHMENTS');
// export const updateOneToOneSessionAttachments = createAction('UPDATE_ONE_TO_ONE_SESSION_ATTACHMENTS');
// export const updateSubscriptionInfo = createAction('UPDATE_SUBSCRIPTION_INFO');
// export const updateSessionCompletedList = createAction('UPDATE_SESSION_COMPLETED_LIST');
export const showDuplicateLoading = createAction(SHOW_DUPLICATE_LOADING);
export const hideDuplicateLoading = createAction(HIDE_DUPLICATE_LOADING);

export const createLeadMagnetActions = {
  request: createAction(CREATE_LEAD_MAGNET_REQUEST),
  success: createAction(CREATE_LEAD_MAGNET_SUCCESS),
  error: createAction(CREATE_LEAD_MAGNET_ERROR),
};

export const fetchLeadMagnetActions = {
  request: createAction(FETCH_LEAD_MAGNET_REQUEST),
  success: createAction(FETCH_LEAD_MAGNET_SUCCESS),
  error: createAction(FETCH_LEAD_MAGNET_ERROR),
  cancel: createAction(FETCH_LEAD_MAGNET_CANCEL),
};

// export const fetchContributionTimesActions = {
//   request: createAction(FETCH_CONTRIBUTION_TIMES_REQUEST),
//   success: createAction(FETCH_CONTRIBUTION_TIMES_SUCCESS),
//   error: createAction(FETCH_CONTRIBUTION_TIMES_ERROR),
// };
// export const calculateCreateSlotsActions = {
//   request: createAction(CALCULATE_CREATE_SLOTS_REQUEST),
//   success: createAction(CALCULATE_CREATE_SLOTS_SUCCESS),
//   error: createAction(CALCULATE_CREATE_SLOTS_ERROR),
// };

// export const fetchEditContributionTimesActions = {
//   request: createAction(FETCH_EDIT_CONTRIBUTION_TIMES_REQUEST),
//   success: createAction(FETCH_EDIT_CONTRIBUTION_TIMES_SUCCESS),
//   error: createAction(FETCH_EDIT_CONTRIBUTION_TIMES_ERROR),
// };

export const updateLeadMagnetActions = {
  request: createAction(UPDATE_LEAD_MAGNET_REQUEST),
  success: createAction(UPDATE_LEAD_MAGNET_SUCCESS),
  error: createAction(UPDATE_LEAD_MAGNET_ERROR),
};

export const fetchLeadMagnetsActions = {
  request: createAction(FETCH_LEAD_MAGNETS_REQUEST),
  success: createAction(FETCH_LEAD_MAGNETS_SUCCESS),
  error: createAction(FETCH_LEAD_MAGNETS_ERROR),
  update: createAction(FETCH_LEAD_MAGNETS_UPDATE),
};

// export const fetchUpcomingCreatedActions = {
//   request: createAction(FETCH_UPCOMING_CREATED_REQUEST),
//   success: createAction(FETCH_UPCOMING_CREATED_SUCCESS),
//   error: createAction(FETCH_UPCOMING_CREATED_ERROR),
// };

// export const fetchUpcomingCreatedWithTypeActions = {
//   request: createAction(FETCH_UPCOMING_CREATED_WITH_TYPE_REQUEST),
//   success: createAction(FETCH_UPCOMING_CREATED_WITH_TYPE_SUCCESS),
//   error: createAction(FETCH_UPCOMING_CREATED_WITH_TYPE_ERROR),
// };

export const fetchArchivedLeadMagnetsActions = {
  request: createAction(FETCH_ARCHIVED_LEAD_MAGNETS_REQUEST),
  success: createAction(FETCH_ARCHIVED_LEAD_MAGNETS_SUCCESS),
  error: createAction(FETCH_ARCHIVED_LEAD_MAGNETS_ERROR),
  update: createAction(FETCH_ARCHIVED_LEAD_MAGNETS_UPDATE),
};

// export const fetchContributionAdminActions = {
//   request: createAction(FETCH_CONTRIBUTIONS_ADMIN_REQUEST),
//   success: createAction(FETCH_CONTRIBUTIONS_ADMIN_SUCCESS),
//   error: createAction(FETCH_CONTRIBUTIONS_ADMIN_ERROR),
// };

// export const rescheduleSessionActions = {
//   request: createAction(RESCHEDULE_SESSION_REQUEST),
//   success: createAction(RESCHEDULE_SESSION_SUCCESS),
//   error: createAction(RESCHEDULE_SESSION_ERROR),
// };

export const fetchLeadMagnetsList = () => async dispatch => {
  dispatch(fetchLeadMagnetsActions.request());

  try {
    const response = await leadMagnetService.getLeadMagnetsForProfilePage();
    dispatch(fetchLeadMagnetsActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchLeadMagnetsActions.error, dispatch)(error);
  }
};

export const fetchLeadMagnets = id => async dispatch => {
  dispatch(fetchLeadMagnetsActions.request());

  try {
    const response = await leadMagnetService.getLeadMagnets(id);
    dispatch(fetchLeadMagnetsActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchLeadMagnetsActions.error, dispatch)(error);
  }
};

// export const fetchContributionTimes = (id, isCoach, _offset = 0, duration = 0) => async dispatch => {
//   dispatch(fetchContributionTimesActions.request());
//   const { getCoachContributionTimes, getClientContributionTimes } = contributionService;
//   const request = isCoach ? getCoachContributionTimes : getClientContributionTimes;
//   // const offset = isArray(_offset) ? _offset : [_offset];
//   const increments = [_offset];
//   // if (_offset > 0) {
//   //   for (let i = 0; i < Math.ceil(duration / _offset); i++) {
//   //     const newOffset = i * _offset;
//   //     // if (newOffset > 60) {
//   //     //   break;
//   //     // }
//   //     increments.push(newOffset);
//   //   }
//   // }

//   // if (increments.every(k => k !== 0)) {
//   //   increments.push(0);
//   // }
//   // increments = increments.filter(k => k <= 60);

//   try {
//     let response = null;
//     if (increments && isArray(increments) && increments?.length) {
//       response = await Promise.allSettled(increments.map(o => request(id, o))).then(resps => {
//         return resps.reduce(
//           (acc, curr) => (acc.some(k => k.startTime === curr.value?.startTime) ? acc : [...acc, ...curr.value]),
//           [],
//         );
//       });
//     }
//     // else if (offset && (typeof offset === 'string' || typeof offset === 'number')) {
//     else {
//       response = await request(id, _offset);
//       // ?.reduce(
//       //   (acc, curr) => (acc.some(k => k.startTime === curr?.startTime) ? acc : [...acc, ...curr]),
//       //   [],
//       // );
//     }
//     const distinct = uniqBy(response, 'startTime');
//     dispatch(fetchContributionTimesActions.success(distinct));
//     // dispatch(fetchContributionTimesActions.success(distinct));

//     // const day12thOnly = response.filter(k => {
//     //   const d = moment(k.startTime).format('YYYY-MM-DD');
//     //   return moment('2024-01-12').isSame(d, 'day');
//     // });
//     // dispatch(fetchEditContributionTimesActions.success(day12thOnly));
//   } catch (error) {
//     pipe(get('response'), get('data'), fetchContributionTimesActions.error, dispatch)(error);
//   }
// };

// export const fetchEditContributionTimes = (id, oneToOneSessionDataUi, params, duration = 0) => async dispatch => {
//   dispatch(fetchEditContributionTimesActions.request());
//   const { getCoachEditContributionTimes } = contributionService;
//   // const { startTimeIncrement: _offset, ...rest } = oneToOneSessionDataUi;
//   const { offset: _offset, ...rest } = params;
//   // const offset = _offset ? [toNumber(_offset)] : [];
//   // if (toNumber(_offset) !== 0) {
//   //   offset.push(0);
//   // }
//   const increments = [_offset];
//   // if (_offset > 0) {
//   //   for (let i = 0; i < Math.ceil(duration / _offset); i++) {
//   //     const newOffset = i * _offset;
//   //     // if (newOffset > 60) {
//   //     //   break;
//   //     // }
//   //     increments.push(newOffset);
//   //   }
//   // }
//   // if (increments.every(k => k !== 0)) {
//   //   increments.push(0);
//   // }
//   // increments = increments.filter(k => k <= 60);
//   try {
//     let response = null;
//     if (increments && isArray(increments) && increments?.length) {
//       response = await Promise.allSettled(
//         // offset.map(o => getCoachEditContributionTimes(id, { ...rest, offset: o }, params)),
//         increments.map(o => getCoachEditContributionTimes(id, oneToOneSessionDataUi, { ...rest, offset: o })),
//       ).then(resps => {
//         return resps.reduce(
//           (acc, curr) => (acc.some(k => k.startTime === curr.startTime) ? acc : [...acc, ...curr.value]),
//           [],
//         );
//       });
//     }
//     // else if (offset && (typeof offset === 'string' || typeof offset === 'number')) {
//     else {
//       response = await getCoachEditContributionTimes(id, oneToOneSessionDataUi, params);
//       // ?.reduce(
//       //   (acc, curr) => (acc.some(k => k.startTime === curr?.startTime) ? acc : [...acc, ...curr]),
//       //   [],
//       // );
//     }
//     const distinct = uniqBy(response, 'startTime');
//     dispatch(fetchEditContributionTimesActions.success(distinct));
//     // const keys = response.map(k => k.startTime);
//     // const duplicated = response
//     //   .filter((k, i) => {
//     //     return keys.indexOf(k.startTime) !== i;
//     //   })
//     //   ?.map(k => k.startTime);
//     // const duplicateSorted = sortBy(duplicated, k => k.startTime);

//     // const day12thOnly = response.filter(k => {
//     //   const d = moment(k.startTime).format('YYYY-MM-DD');
//     //   return moment('2024-01-12').isSame(d, 'day');
//     // });
//     // dispatch(fetchEditContributionTimesActions.success(day12thOnly));
//   } catch (error) {
//     pipe(get('response'), get('data'), fetchEditContributionTimesActions.error, dispatch)(error);
//   }
// };

// export const calculateCreateSlots = oneToOneSessionDataUi => async dispatch => {
//   dispatch(calculateCreateSlotsActions.request());

//   try {
//     const response = await contributionService.calculateSlots(oneToOneSessionDataUi);
//     dispatch(calculateCreateSlotsActions.success(response));
//   } catch (error) {
//     pipe(get('response'), get('data'), calculateCreateSlotsActions.error, dispatch)(error);
//   }
// };
// export const fetchUpcomingCreated = (id, pagepnumber) => async dispatch => {
//   dispatch(fetchUpcomingCreatedActions.request());
//   try {
//     let assign = pagepnumber;
//     if (pagepnumber > 0) {
//       assign = pagepnumber - 1;
//     }
//     const response = await contributionService.getUpcomingCreated(id, assign);
//     if (pagepnumber === 0) {
//       pagepnumber = 1;
//     }
//     dispatch(fetchUpcomingCreatedActions.success({ response, pagepnumber }));
//   } catch (error) {
//     pipe(get('response'), get('data'), fetchUpcomingCreatedActions.error, dispatch)(error);
//   }
// };

// export const fetchUpcomingCreatedWithType = (id, type) => async dispatch => {
//   dispatch(fetchUpcomingCreatedWithTypeActions.request());

//   try {
//     const response = await contributionService.getUpcomingCreatedWithType(id, type);
//     dispatch(fetchUpcomingCreatedWithTypeActions.success(response));
//   } catch (error) {
//     pipe(get('response'), get('data'), fetchUpcomingCreatedWithTypeActions.error, dispatch)(error);
//   }
// };

export const fetchArchivedLeadMagnets = () => async dispatch => {
  dispatch(fetchArchivedLeadMagnetsActions.request());

  try {
    const response = await leadMagnetService.getArchivedLeadMagnets();
    dispatch(fetchArchivedLeadMagnetsActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchArchivedLeadMagnetsActions.error, dispatch)(error);
  }
};

// export const fetchContribution = id => (dispatch, getState) => {
//   dispatch(fetchContributionActions.request());

//   return contributionService
//     .getContribution(id)
//     .then(fetchContributionActions.success)
//     .then(dispatch)
//     .catch(pipe(get('response'), get('data'), fetchContributionActions.error, dispatch));

//   return contributionService
//     .getContribution(id)
//     .then(resp => {
//       const oldObj = getState()?.contributions?.activeContribution;
//       const newObj = resp;

//       const propsToExclude = ['updateTime'];
//       const oldObjWithoutUpdateTime = omit(oldObj, propsToExclude);
//       const newObjWithoutUpdateTime = omit(newObj, propsToExclude);

//       if (isEqual(oldObjWithoutUpdateTime, newObjWithoutUpdateTime)) {
//         return dispatch(fetchContributionActions.cancel());
//       }
//       return dispatch(fetchContributionActions.success(resp));
//     })
//     .catch(pipe(get('response'), get('data'), fetchContributionActions.error, dispatch));
// };

export const fetchCohealerLeadMagnet = id => (dispatch, getState) => {
  dispatch(fetchLeadMagnetActions.request());

  return leadMagnetService
    .getCohealerLeadMagnet(id)
    .then(fetchLeadMagnetActions.success)
    .then(dispatch)
    .catch(err => {
      // eslint-disable-next-line no-undef
      if (err?.response?.status === RESPONSE_CODES.CLIENT.NOT_ALLOWED) {
        return Promise.reject(err);
      }
      dispatch(fetchLeadMagnetActions.error(err?.response?.data));
      redirectTo('/');
    });

  // return leadMagnetService
  //   .getCohealerLeadMagnet(id)
  //   .then(resp => {
  //     const oldObj = getState()?.contributions?.activeContribution;
  //     const newObj = resp;

  //     const propsToExclude = ['updateTime'];
  //     const oldObjWithoutUpdateTime = omit(oldObj, propsToExclude);
  //     const newObjWithoutUpdateTime = omit(newObj, propsToExclude);

  //     if (isEqual(oldObjWithoutUpdateTime, newObjWithoutUpdateTime)) {
  //       return dispatch(fetchLeadMagnetActions.cancel());
  //     }
  //     return dispatch(fetchLeadMagnetActions.success(resp));
  //   })
  //   .catch(err => {
  //     // eslint-disable-next-line no-undef
  //     if (err?.response?.status === RESPONSE_CODES.CLIENT.NOT_ALLOWED) {
  //       return Promise.reject(err);
  //     }
  //     dispatch(fetchLeadMagnetActions.error(err?.response?.data));
  //     redirectTo('/');
  //   });
};

export const fetchClientLeadMagnet = id => (dispatch, getState) => {
  dispatch(fetchLeadMagnetActions.request());

  return leadMagnetService
    .getClientLeadMagnet(id)
    .then(fetchLeadMagnetActions.success)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), fetchLeadMagnetActions.error, dispatch));

  // return leadMagnetService
  //   .getClientLeadMagnet(id, ipAddress, priceId)
  //   .then(resp => {
  //     const oldObj = getState()?.leadMagnets?.activeLeadMagnet;
  //     const newObj = resp;

  //     const propsToExclude = ['updateTime'];
  //     const oldObjWithoutUpdateTime = omit(oldObj, propsToExclude);
  //     const newObjWithoutUpdateTime = omit(newObj, propsToExclude);

  //     if (isEqual(oldObjWithoutUpdateTime, newObjWithoutUpdateTime)) {
  //       // return dispatch(fetchContributionActions.cancel());
  //       return;
  //     }
  //     batch(() => {
  //       dispatch(fetchLeadMagnetActions.request());
  //       dispatch(fetchLeadMagnetActions.success(resp));
  //     });
  //   })
  // .catch(pipe(get('response'), get('data'), fetchLeadMagnetActions.error, dispatch));
};

// export const fetchClientContributionAfterInterval = (id, ipAddress, priceId) => (dispatch, getState) => {
//   // dispatch(fetchContributionActions.request());

//   // return contributionService
//   //   .getClientContribution(id, ipAddress, priceId)
//   //   .then(fetchContributionActions.success)
//   //   .then(dispatch);

//   return contributionService.getClientContribution(id, ipAddress, priceId).then(resp => {
//     const oldObj = getState()?.contributions?.activeContribution;
//     const newObj = resp;

//     const propsToExclude = ['updateTime'];
//     const oldObjWithoutUpdateTime = omit(oldObj, propsToExclude);
//     const newObjWithoutUpdateTime = omit(newObj, propsToExclude);

//     if (isEqual(oldObjWithoutUpdateTime, newObjWithoutUpdateTime)) {
//       console.info('rerender-caused-not');
//       // return dispatch(fetchContributionActions.cancel());
//       return;
//     }
//     console.info('rerender-caused');
//     batch(() => {
//       dispatch(fetchContributionActions.request());
//       dispatch(fetchContributionActions.success(resp));
//     });
//   });
//   // .catch(pipe(get('response'), get('data'), re => {console.info('rerender-3:error:', re); dispatch(fetchContributionActions.cancel())}));
// };

// export const getLastDraftContribution = () => dispatch => {
//   return contributionService.getLastDraftContribution().then(getContributionFromLS).then(dispatch);
// };

export const saveDraftLeadMagnet = data => dispatch => {
  const { status, timeZoneId, ...body } = data;
  // const showonlyFree = body.showOnlyFreeOption;
  // const showFreeOption = body.showFreeOption;
  // const sessions = [
  //   {
  //     maxParticipantsNumber: 20,
  //     minParticipantsNumber: 2,
  //     sessionTimes: [getCurrentSessionTime(false, moment().add(1, 'd'))],
  //     title: 'Session 1',
  //     name: 'Session',
  //     isPrerecorded: false,
  //   },
  // ];
  if (data.id) {
    console.info('mode:edit', data.id);
    // if (body.type === ContributionType.contributionMembership) {
    //   const isNeedChangePaymentOptions =
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREE);
    //   // if (!body?.sessions?.length) {
    //   //   body.sessions = sessions;
    //   // }
    //   if (isNeedChangePaymentOptions) {
    //     // body.paymentInfo.paymentOptions = [PAYMENT_OPTIONS.PER_MONTH];
    //     if (showonlyFree) {
    //       body.paymentInfo.paymentOptions = [PAYMENT_OPTIONS.FREE];
    //     } else {
    //       body.paymentInfo.paymentOptions = [PAYMENT_OPTIONS.PER_MONTH];
    //     }
    //   }
    // }
    // if (body.type === ContributionType.contributionCourse) {
    //   const isNeedChangePaymentOptions =
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREE) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS);
    //   if (isNeedChangePaymentOptions) {
    //     body.paymentInfo.paymentOptions = [PAYMENT_OPTIONS.ENTIRE_COURSE];
    //   }
    //   // if (!body?.sessions?.length) {
    //   //   body.sessions = sessions;
    //   // }
    // }
    // if (body.type === ContributionType.contributionOneToOne) {
    //   const isNeedChangePaymentOptions =
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREE) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
    //   if (isNeedChangePaymentOptions) {
    //     body.paymentInfo.paymentOptions = [PAYMENT_OPTIONS.FREE];
    //   }
    //   if (!body?.durations?.length) {
    //     body.durations = [60];
    //   }
    //   body.sessions = [];
    // }
    // if (body.type === ContributionType.contributionCommunity) {
    //   const isNeedChangePaymentOptions =
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) &&
    //     !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREE);
    //   if (isNeedChangePaymentOptions) {
    //     body.paymentInfo.paymentOptions = [PAYMENT_OPTIONS.FREE];
    //   }
    // }
    return leadMagnetService.updateDraftLeadMagnet(null, body).then(resp => {
      dispatch(saveLeadMagnetToLS(resp));
      return resp;
    });
  } else {
    console.info('mode:create', data?.id);
    body.status = status;
    body.timeZoneId = timeZoneId; // TODO: unnecessary
    // body.paymentInfo = { paymentOptions: [] };
    body.languageCodes = ['En']; // TODO: unnecessary
    body.invitationOnly = true;
    body.arePublicPostsAllowed = true; // TODO: unnecessary
    body.areClientPublicPostsAllowed = true; // TODO: unnecessary
    // if (body.type === ContributionType.contributionOneToOne) {
    //   if (showonlyFree) {
    //     body.durations = [60];
    //     body.paymentInfo.packageSessionNumbers = 5;
    //     body.paymentInfo.paymentOptions.push(PAYMENT_OPTIONS.FREE);
    //   } else {
    //     body.durations = [60];
    //     body.paymentInfo.paymentOptions.push('PerSession');
    //     body.paymentInfo.packageSessionNumbers = 5;
    //   }
    // } else if (body.type === ContributionType.contributionCourse) {
    //   if (showFreeOption) {
    //     body.paymentInfo.paymentOptions.push(PAYMENT_OPTIONS.FREE);
    //   } else {
    //     body.paymentInfo.paymentOptions.push(PAYMENT_OPTIONS.ENTIRE_COURSE);
    //   }
    // } else if (body.type === ContributionType.contributionMembership) {
    //   if (showonlyFree || showFreeOption) {
    //     body.paymentInfo.paymentOptions.push(PAYMENT_OPTIONS.FREE);
    //   } else {
    //     body.paymentInfo.paymentOptions.push(PAYMENT_OPTIONS.PER_MONTH);
    //   }
    // } else if (body.type === ContributionType.contributionCommunity) {
    //   body.paymentInfo.paymentOptions.push(PAYMENT_OPTIONS.FREE);
    // }
    // if (body.type !== ContributionType.contributionCommunity) {
    //   body.sessions = sessions;
    // } else {
    //   body.sessions = [];
    // }
    if (body?.isDuplicate) {
      return leadMagnetService.createDuplicateLeadMagnet(body).then(resp => {
        batch(() => {
          dispatch(saveLeadMagnetToLS(resp));
          dispatch(fetchLeadMagnetsActions.update([resp]));
        });
        return resp;
      });
      // .then(saveLeadMagnetToLS).then(dispatch)
      // .then(fetchLeadMagnetsActions.update(body)).then(dispatch)
    } else {
      // return leadMagnetService.createDraftLeadMagnet(body).then(saveLeadMagnetToLS).then(dispatch);
      return leadMagnetService.createDraftLeadMagnet(body).then(res => dispatch(saveLeadMagnetToLS(res)));
    }
  }
};

export const updateLeadMagnet = (id, data) => dispatch => {
  dispatch(updateLeadMagnetActions.request());

  if (data.hasAgreedContributionTerms && data.status === LeadMagnetStatus.unfinished) {
    // delete data.status;

    return leadMagnetService
      .submitDraftLeadMagnet(data)
      .then(updateLeadMagnetActions.success)
      .then(dispatch)
      .catch(pipe(get('response'), get('data'), updateLeadMagnetActions.error, dispatch));
  }
  let request =
    data.status === LeadMagnetStatus.unfinished
      ? leadMagnetService.updateDraftLeadMagnet
      : leadMagnetService.updateLeadMagnet;
  if (data.status === LeadMagnetStatus.unfinished) {
    delete data?.status;
  }
  // delete data.status;

  return request(id, data)
    .then(updateLeadMagnetActions.success)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), updateLeadMagnetActions.error, dispatch));
};

export const createLeadMagnet = data => dispatch => {
  dispatch(createLeadMagnetActions.request());
  const request =
    data.status === LEAD_MAGNET_STATUSES.DRAFT
      ? leadMagnetService.submitDraftLeadMagnet
      : leadMagnetService.createLeadMagnet;

  delete data.status;

  return request(data)
    .then(createLeadMagnetActions.success)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), createLeadMagnetActions.error, dispatch));
};

// export const fetchContributionAdmin = () => async dispatch => {
//   dispatch(fetchContributionAdminActions.request());

//   try {
//     const response = await contributionService.getContributionsAdmin(); //
//     dispatch(fetchContributionAdminActions.success(response));
//   } catch (error) {
//     pipe(get('response'), get('data'), fetchContributionActions.error, dispatch)(error);
//   }
// };

// export const rescheduleSession = data => dispatch => {
//   dispatch(rescheduleSessionActions.request());

//   return contributionService
//     .rescheduleSession(data)
//     .then(rescheduleSessionActions.success)
//     .then(dispatch)
//     .catch(pipe(get('response'), get('data'), rescheduleSessionActions.error, dispatch));
// };

// export const rescheduleSessionForClient = data => dispatch => {
//   dispatch(rescheduleSessionActions.request());

//   return contributionService
//     .rescheduleSessionForClient(data)
//     .then(rescheduleSessionActions.success)
//     .then(dispatch)
//     .catch(pipe(get('response'), get('data'), rescheduleSessionActions.error, dispatch));
// };

// export const setInviteCode = code => ({
//   type: SET_INVITE_CODE,
//   payload: code,
// });
// export const setSingleCode = code => ({
//   type: SET_SINGLE_CODE,
//   payload: code,
// });
// export const setCouponCode = code => ({
//   type: SET_COUPON_CODE,
//   payload: code,
// });

// export const setRefetchContribution = code => ({
//   type: SET_REFETCH_CONTRIBUTION,
//   payload: code,
// });

// export const setPickAnotherTime = code => ({
//   type: SET_PICK_ANOTHER_TIME,
//   payload: code,
// });

// export const setRescheduleModal = code => ({
//   type: SET_RESCHEDULE_MODAL,
//   payload: code,
// });

// Note: used in joyride, not used yet
export const setPopUp = code => ({
  type: SET_POP_UP,
  payload: code,
});
// export const setPopUpBank = code => ({
//   type: SET_POP_UP_BANK,
//   payload: code,
// });
// export const setPriceId = code => ({
//   type: SET_PRICE_CODE,
//   payload: code,
// });

// export const setShowThankYouPage = code => ({
//   type: SET_SHOW_THANK_YOU_PAGE,
//   payload: code,
// });

export const setErrorPopUp = code => ({
  type: SHOW_ERROR_POPUP,
  payload: code,
});

export const setErrorMessage = error => ({
  type: SET_ERROR_MESSAGE,
  payload: error,
});
// export const setContributionsLoader = code => ({
//   type: SET_CONTRIBUTIONS_LOADER,
//   payload: code,
// });
// export const setSessionId = code => ({
//   type: SET_SESSION_CODE,
//   payload: code,
// });

export const setWelcomeEmailEnabled = state => ({
  type: SET_WELCOME_EMAIL_ENABLED,
  payload: state,
});
