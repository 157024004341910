import { isEmpty } from 'lodash';
import moment from 'moment';

const getFilteredContactsByTags = (contacts = [], filters = {}) => {
  return contacts.filter(contact => contact.tags && contact.tags.some(tag => filters.tags.includes(tag.id)));
};

const getFilteredContactsByContrib = (contacts = [], filters = {}) => {
  return contacts.filter(contact => filters?.contributions?.includes(contact?.recenetContributionId));
};

const getFilteredContactsByFromDate = (contacts = [], fromDate) => {
  return contacts.filter(contact => moment(contact?.joinDate).isSameOrAfter(fromDate));
};

const getFilteredContactsByToDate = (contacts = [], toDate) => {
  return contacts.filter(contact => moment(contact?.joinDate).isSameOrBefore(toDate));
};

const getFilteredData = (data = [], filters = {}) => {
  const fromDate = moment(filters?.fromDate);
  const toDate = moment(filters?.toDate);
  let fetchedToDate = toDate.set({
    year: toDate.year(),
    month: toDate.month(),
    date: toDate.date(),
    hour: 23,
    minute: 59,
    second: 0,
  });
  let fetchedFromDate = fromDate.set({
    year: fromDate.year(),
    month: fromDate.month(),
    date: fromDate.date(),
    hour: 0,
    minute: 0,
    second: 0,
  });

  //Case#1: If only contributions selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.tags.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null
  ) {
    return getFilteredContactsByContrib(data, filters);
  }

  //Case#2: If only tags selected in filters
  if (
    filters?.tags.length > 0 &&
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null
  ) {
    return getFilteredContactsByTags(data, filters);
  }

  //Case#3: If only fromDate selected in filters
  if (
    filters?.tags.length === 0 &&
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null
  ) {
    return getFilteredContactsByFromDate(data, fetchedFromDate);
  }

  //Case#4: If only toDate selected in filters
  if (
    filters?.tags.length === 0 &&
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null
  ) {
    return getFilteredContactsByToDate(data, fetchedToDate);
  }

  //Case#5: If only fromDate && toDate selected in filters
  if (
    filters?.tags.length === 0 &&
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    return filteredByFromAndToDate;
  }

  //Case#6: If only fromDate && contributions selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length === 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    return getFilteredContactsByContrib(filteredByFromDate, filters);
  }

  //Case#7: If only fromDate && tags selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    return getFilteredContactsByTags(filteredByFromDate, filters);
  }

  //Case#8: If only toDate && contributions are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length === 0
  ) {
    const filteredBytoDate = getFilteredContactsByToDate(data, fetchedToDate);
    return getFilteredContactsByContrib(filteredBytoDate, filters);
  }

  //Case#9: If only toDate && tags are selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0
  ) {
    const filteredBytoDate = getFilteredContactsByToDate(data, fetchedToDate);
    return getFilteredContactsByTags(filteredBytoDate, filters);
  }

  //Case#10: If only contributions && tags are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0
  ) {
    const filteredByContrib = getFilteredContactsByContrib(data, filters);
    return getFilteredContactsByTags(filteredByContrib, filters);
  }

  //Case#11: If only fromDate && toDate && contributions selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length === 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    return getFilteredContactsByContrib(filteredByFromAndToDate, filters);
  }

  //Case#12: If only fromDate && toDate && tags selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    return getFilteredContactsByTags(filteredByFromAndToDate, filters);
  }

  //Case#13: If only fromDate && contributions && tags selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndContrib = getFilteredContactsByContrib(filteredByFromDate, filters);
    return getFilteredContactsByTags(filteredByFromAndContrib, filters);
  }

  //Case#14: If only toDate && contributions && tags selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0
  ) {
    const filteredByToDate = getFilteredContactsByToDate(data, fetchedToDate);
    const filteredByToDateAndTags = getFilteredContactsByTags(filteredByToDate, filters);
    return getFilteredContactsByContrib(filteredByToDateAndTags, filters);
  }

  //Case#15: If all filters are selected
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    const filteredByFromDateAndToDateAndTags = getFilteredContactsByTags(filteredByFromAndToDate, filters);
    return getFilteredContactsByContrib(filteredByFromDateAndToDateAndTags, filters);
  }
};

const getSearchedData = (data = [], keyword = '') => {
  console.log('Data--->', data);
  return data?.filter(
    item =>
      item?.firstName?.toLowerCase()?.includes(keyword) ||
      item?.lastName?.toLowerCase()?.includes(keyword) ||
      `${item?.firstName?.toLowerCase()} ${item?.lastName?.toLowerCase()}`?.includes(keyword) ||
      item?.email?.toLowerCase()?.includes(keyword) ||
      item?.source?.toLowerCase()?.includes(keyword) ||
      item?.contributionTitle?.toLowerCase()?.includes(keyword) ||
      item?.tags?.some(item => item.name.toLowerCase().includes(keyword)),
  );
};

export const getSearchedAndFilteredData = (data, keyword, filters) => {
  if (data?.length === 0 || (keyword?.length === 0 && isEmpty(filters))) {
    return data;
  } else if (keyword?.length > 0 && !isEmpty(filters)) {
    const searchedData = getSearchedData(data, keyword);
    return getFilteredData(searchedData, filters);
  } else if (keyword?.length > 0) {
    return getSearchedData(data, keyword);
  } else {
    return getFilteredData(data, filters);
  }
};
