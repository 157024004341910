import React, { Children, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { fetchTimeZones } from 'actions/timeZone';
import { useAccount, useClientPreview, useRouter } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { lighten } from 'polished';
import { colors } from 'utils/styles';
import { PageSubtitle } from 'components/UI/Text/TextStyles';
import Loader from 'components/UI/Loader';
import axiosInstance from 'utils/axiosInstance';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarContributionViewForPaymentCard.scss';
import { TIMEZONES } from 'constants.js';
import { UserRoles } from 'helpers/constants';
import { lightOrDark } from 'utils/utils';
import { determineColorToUse, determineDarkThemedColorToUse } from 'services/contributions.service';
import { color } from '@mui/system';
import { Grid, SvgIcon, makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';

import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';
import { Opacity } from '@mui/icons-material';

const StyledHeading = styled.div`
  font-family: 'Brandon Text';
  font-style: normal;
  font-size: 25px;
  font-weight: 800;
  justify-content: center;
  display: flex;
`;

const PageSubtitleCustom = styled(PageSubtitle)`
  font-family: 'Brandon Text';
  font-style: normal;
  padding-top: 10px;
  // padding-bottom: 10px;
  margin-left: 10px;
`;

const localizer = momentLocalizer(moment);
const formats = {
  weekdayFormat: (date, culture, localizer1) => localizer1.format(date, 'dd', culture),
};
const allViews = [Views.MONTH];

const CalendarContributionViewForPaymentCard = ({
  events,
  onSelectEvent,
  onDrillDown,
  calendarRef,
  rescheduleMode,
  dateToShow,
  loadingTimes,
  timeZoneId,
  contribution,
}) => {
  const { currentRole } = useAccount();
  const { AccentColorCode = '' } = determineColorToUse(contribution.activeContribution);
  const isCoach = currentRole === UserRoles.cohealer;
  const modifiedevents = events.filter(e => moment(e.start).isAfter(moment().add(24, 'h')));
  const dispatch = useDispatch();
  const currentContribution = useContribution();
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const [colorForSelect, setColorForSelect] = useState('darkgrey');
  const [timeZone, setTimeZone] = useState('');
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const { newThemedTextColor, themedBackgroundColor } = getThemedColors(currentContribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(currentContribution);
  const { clientPreviewMode } = useClientPreview();
  const { pathname } = useRouter();

  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
  }, [timeZones, timeZoneLoading]);
  const useSelectStyle = makeStyles(() => ({
    select: {
      width: '100%',
      color: colorForSelect,
      backgroundColor: 'white',
      borderRadius: '20px',
      border: '1px solid #f5f0f0',
    },
  }));
  const selectStyle = useSelectStyle();
  const colorToUse = determineColorToUse(contribution.activeContribution);

  const CustomToolbar = toolbar => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };

    const label = () => {
      const date = moment(toolbar.date);
      const todayDate = moment(new Date());
      return (
        <span>
          {/* <b>{date.format('DD')}</b> */}
          <b> {date.format('MMMM')}</b>
          <span>
            {' '}
            <b>{date.format('YYYY')}</b>
          </span>
        </span>
      );
    };

    return (
      <div className="toolbar-container">
        <div>
          <button
            type="button"
            className="btn-back"
            style={{
              backgroundColor: isDarkThemeEnabled ? '#252728' : 'white',
              color: newThemedTextColor,
              fontSize: '25px',
            }}
            onClick={goToBack}
          >
            {`<`}
          </button>
        </div>
        <div>
          <button
            type="button"
            className="btn-current"
            style={{ backgroundColor: isDarkThemeEnabled ? '#252728' : 'white', color: newThemedTextColor }}
            onClick={goToCurrent}
          >
            {label()}
          </button>
        </div>
        <div>
          <button
            type="button"
            className="btn-next"
            style={{
              backgroundColor: isDarkThemeEnabled ? '#252728' : 'white',
              color: newThemedTextColor,
              fontSize: '25px',
            }}
            onClick={goToNext}
          >
            {`>`}
          </button>
        </div>
      </div>
    );
  };

  const CustomToolbarCalendly = toolbar => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span style={{ fontFamily: 'Avenir', fontSize: '20px', fontStyle: 'normal', fontWeight: '400' }}>
          {date.format('MMMM')}
          <span> {date.format('YYYY')}</span>
        </span>
      );
    };

    return (
      <div className="toolbar-container calendly">
        <div>
          <button
            type="button"
            className="btn-current"
            style={{ backgroundColor: isDarkThemeEnabled ? '#252728' : 'white', color: newThemedTextColor }}
            onClick={goToCurrent}
          >
            {label()}
          </button>
        </div>
        <div className="navigation-buttons">
          <div>
            <SvgIcon onClick={goToBack} style={{ width: '29px', height: '29px' }} viewBox="0 0 29 29">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                <path
                  d="M20.333 6.92943L12.7632 14.5168L20.333 22.1019L18.0034 24.4334L8.08674 14.5168L18.0034 4.6001L20.333 6.92943Z"
                  fill={newThemedTextColor}
                />
              </svg>
            </SvgIcon>
          </div>
          <div>
            <SvgIcon onClick={goToNext} style={{ width: '29px', height: '29px' }} viewBox="0 0 29 29">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                <path
                  d="M9.16699 6.92943L16.7368 14.5168L9.16699 22.1019L11.4966 24.4334L21.4133 14.5168L11.4966 4.6001L9.16699 6.92943Z"
                  fill={newThemedTextColor}
                />
              </svg>
            </SvgIcon>
          </div>
        </div>
      </div>
    );
  };

  const ColoredDateCellWrapper = ({ children, value }) => {
    const today = new Date();
    const momentToday = moment(today).format('MMM Do YY');
    const momentValue = moment(value).format('MMM Do YY');

    const eventDates = [];
    const bookedDates = [];
    for (var e of events) {
      const d = moment(e.start).format('MMM Do YY');
      if (!eventDates.includes(d)) {
        eventDates.push(d);
      }
      if (e.isBooked) {
        if (!bookedDates.includes(d)) {
          bookedDates.push(d);
        }
      }
    }
    let bgColor = themedBackgroundColor;
    let opacity = '100%';
    if (eventDates.includes(momentValue)) {
      bgColor = AccentColorCode;
      opacity = '30%';
    }
    if (momentValue === momentToday) {
      bgColor = AccentColorCode;
      opacity = '100%';
    }
    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        border: 'none',
        borderRadius: '25px',
        maxWidth: '33px',
        maxHeight: '33px',
        backgroundColor: bgColor,
        fontWeight: '900',
        opacity,
      },
    });
  };

  return (
    <div className="calendar-container-custom" style={{ color: newThemedTextColor }} ref={calendarRef}>
      <Grid item md={12} xs={12} className="book-your-next-session" style={{ padding: '10px 0px' }}>
        <StyledHeading>{rescheduleMode ? 'Reschedule your session' : 'Book your next session'}</StyledHeading>
        <PageSubtitleCustom style={{ color: newThemedTextColor }}>
          Session Availability in {TIMEZONES[timeZoneId]}.{' '}
          {isCoach && !clientPreviewMode
            ? 'These are the session times that can be booked.'
            : 'Choose a time on the calendar below that works best for you.'}
        </PageSubtitleCustom>
      </Grid>
      {loadingTimes && (
        <div className="custom-calendar" style={{ position: 'relative' }}>
          <div className="rbc-month-view">
            <div className="">
              <Loader relative />
            </div>
          </div>
        </div>
      )}
      {!loadingTimes && (
        <div className={`calendar-container-custom ${isDarkThemeEnabled ? 'cohere-dark-mode' : ''}`}>
          <p
            className={
              window?.location?.pathname?.includes('/sessions') && !contribution?.activeContribution?.isPurchased
                ? 'easy-booking-title'
                : 'non-easy-booking-title'
            }
            style={{ color: AccentColorCode }}
          >
            Select Date and Time
          </p>
          <BigCalendar
            className={`custom-calendar ${isDarkThemeEnabled ? 'dark-theme' : ''}`}
            style={{ color: 'black' }}
            localizer={localizer}
            // events={rescheduleMode ? modifiedevents : events}
            events={[]}
            views={allViews}
            defaultView={Views.MONTH}
            // date={dateToShow}
            formats={formats}
            components={{
              toolbar: pathname.includes('sessions') ? CustomToolbarCalendly : CustomToolbar,
              dateCellWrapper: ColoredDateCellWrapper,
            }}
            step={30}
            showMultiDayTimes
            titleAccessor={event => `${event.title.substring(0, 15)}...`}
            defaultDate={new Date()}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={onSelectEvent}
            onDrillDown={onDrillDown}
          />
        </div>
      )}
    </div>
  );
};

CalendarContributionViewForPaymentCard.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  calendarRef: PropTypes.shape({ current: PropTypes.any }),
  loadingTimes: PropTypes.bool,
  timeZoneId: PropTypes.string,
};

CalendarContributionViewForPaymentCard.defaultProps = {
  calendarRef: null,
  loadingTimes: false,
};

const mapStateToProps = ({ contributions }) => ({
  loadingTimes: contributions?.loadingTimes,
  contribution: contributions,
});

export default connect(mapStateToProps, null)(CalendarContributionViewForPaymentCard);
