import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContributionReviewModal from 'pages/ContributionView/ContributionReviews/components/ContributionReviewModal';
import Loader from 'components/UI/Loader';
import { useHttp } from 'hooks/index';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  addTestimonial,
  addTestimonialAvatar,
  deleteTestimonial,
  editTestimonial,
  toggleShowcase,
} from 'services/testimonials.service';
import { fetchTestimonialTemplates, fetchTestimonials } from 'actions/testimonials';
import { getAllTestimonials } from 'selectors/testimonials';
import TestimonialIcon from 'assets/testimonial.svg';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import TestimonialModal from './components/TestimonialModal';
import TestimonialTemplateModal from './components/TestimonialTemplateModal';

const useStyles = makeStyles(theme => ({
  root: {
    width: 335,
    height: 135,
    overflow: 'visible',
    backgroundColor: '#fafafa',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: 16,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 50,
    paddingLeft: 70,
  },
  containerMobile: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: 50,
    paddingLeft: 70,
  },
  avatar: {
    backgroundColor: 'blue',
    width: '87px',
    height: '87px',
    left: '-33px',
    top: '15px',
    border: '2px solid white',
    position: 'absolute',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
  header: {
    position: 'relative',
    paddingLeft: 46,
    paddingBottom: 0,
    paddingTop: 10,
    '& .MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  title: {
    color: '#000',
    fontSize: 16,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 14,
  },
  content: {
    paddingLeft: 63,
    paddingTop: 0,
    height: 60,
    overflow: 'hidden',
  },
  createReviewContainer: {
    marginBottom: 25,
  },
  menuPaper: {
    backgroundColor: ({ cardBackgroundColor }) => cardBackgroundColor,
    color: ({ color }) => color,
  },
}));

function ContributionReviews({ getAllTestimonialTemplates }) {
  const StyledButton = styled(Button)`
    margin-right: 0px;
  `;
  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);

  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const styledProps = {
    cardBackgroundColor: themedCardBackgroundColor,
    color: themedColor,
  };
  const classes = useStyles(styledProps);
  const [modalOpened, setModalOpened] = useState(false);
  const [avatarError, setAvatarError] = useState('');
  const { request, loading } = useHttp();
  const { id: contributionId } = useContribution();
  const dispatch = useDispatch();
  const testimonials = useSelector(getAllTestimonials);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(null);
  const [openedModal, setOpenedModal] = useState(null);
  const [menus, setMenus] = useState({});
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const handleSubmit = ({ file, name, role, description, id, addedToShowcase, avatarUrl, isTemplate }) => {
    const formData = new FormData();
    formData.append('file', file);
    if (id || isTemplate) {
      if (file) {
        return addTestimonialAvatar(contributionId, formData, avatarUrl)
          .then(res => {
            if (isTemplate) {
              return addTestimonial({
                contributionId,
                name,
                role,
                description,
                avatarUrl: res,
              }).then(() => {
                dispatch(fetchTestimonials(contributionId));
                setOpenedModal(null);
                setSelected(null);
              });
            }

            return editTestimonial(id, {
              contributionId,
              name,
              role,
              description,
              avatarUrl: res,
              addedToShowcase,
            });
          })
          .then(() => {
            dispatch(fetchTestimonials(contributionId));
            getAllTestimonialTemplates();
            setOpenedModal(null);
            setSelected(null);
          })
          .catch(() => {
            setOpenedModal(null);
            setSelected(null);
          });
      }

      if (isTemplate) {
        return addTestimonial({
          contributionId,
          name,
          role,
          description,
          avatarUrl,
        }).then(() => {
          dispatch(fetchTestimonials(contributionId));
          getAllTestimonialTemplates();
          setOpenedModal(null);
          setSelected(null);
        });
      }

      return editTestimonial(id, {
        contributionId,
        name,
        role,
        description,
        avatarUrl,
        addedToShowcase,
      })
        .then(() => {
          dispatch(fetchTestimonials(contributionId));
          getAllTestimonialTemplates();
          setOpenedModal(null);
          setSelected(null);
        })
        .catch(() => {
          setOpenedModal(null);
          setSelected(null);
        });
    }

    return addTestimonialAvatar(contributionId, formData)
      .then(res => {
        return addTestimonial({
          contributionId,
          name,
          role,
          description,
          avatarUrl: res,
        });
      })
      .then(() => {
        dispatch(fetchTestimonials(contributionId));
        getAllTestimonialTemplates();
        setSelected(null);
        setOpenedModal(null);
      })
      .catch(error => {
        if (error.response.status === 400) {
          setAvatarError('Please upload an image');
        }
      });
  };

  const handleClose = () => {
    setModalOpened(false);
  };

  const handleModalOpen = () => {
    setModalOpened(true);
  };

  const handleDelete = id => {
    setMenus(prev => ({ ...prev, [id]: false }));
    setAnchorEl(null);

    deleteTestimonial(id).then(() => {
      dispatch(fetchTestimonials(contributionId));
    });
  };

  const handleEdit = id => {
    setSelected(testimonials.find(el => el.id === id));

    setOpenedModal('new');
    setAnchorEl(null);
    setMenus(prev => ({ ...prev, [id]: false }));
  };

  const handleAddToShowcase = id => {
    setAnchorEl(null);
    setMenus(prev => ({ ...prev, [id]: false }));

    toggleShowcase(id).then(() => {
      dispatch(fetchTestimonials(contributionId));
    });
  };

  useEffect(() => {
    dispatch(fetchTestimonials(contributionId));
  }, [dispatch, contributionId]);

  useEffect(() => {
    getAllTestimonialTemplates();
  }, []);

  const handleTestimonialModalClick = type => {
    setOpenedModal(type);
    setModalOpened(false);
  };

  return (
    <>
      <Grid container justify="space-between" className={classes.createReviewContainer}>
        <Grid item>
          <Typography style={{ color: themedColor }} variant="h6">
            Testimonials
          </Typography>
        </Grid>
        <Grid item>
          <StyledButton
            style={{ backgroundColor: colorToUse.PrimaryColorCode, color: textColor }}
            autoWidth
            onClick={handleModalOpen}
          >
            Create Testimonial
          </StyledButton>
        </Grid>
      </Grid>
      <div className={mobileView ? classes.containerMobile : classes.container}>
        {testimonials.map(({ avatarUrl, name, role, description, id, addedToShowcase }) => (
          <div key={id}>
            <Card style={{ backgroundColor: themedCardBackgroundColor }} className={classes.root}>
              <CardHeader
                className={classes.header}
                avatar={
                  <Avatar src={avatarUrl} className={classes.avatar}>
                    {name.charAt()}
                  </Avatar>
                }
                title={
                  <Typography style={{ color: themedColor }} variant="h6">
                    {name}
                  </Typography>
                }
                subheader={
                  <Typography style={{ color: themedColor }} variant="subtitle1" color="textSecondary">
                    {role}
                  </Typography>
                }
                action={
                  <>
                    <img color="red" src={TestimonialIcon} alt="" />
                    <IconButton
                      onClick={e => {
                        setAnchorEl(() => e.currentTarget);
                        setMenus(prev => ({ ...prev, [id]: true }));
                      }}
                      aria-label="settings"
                    >
                      <MoreVertIcon style={{ color: themedColor }} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={menus?.[id]}
                      classes={{ paper: classes.menuPaper }}
                      onClose={() => {
                        setMenus(prev => ({ ...prev, [id]: false }));
                        setAnchorEl(null);
                      }}
                    >
                      <MenuItem onClick={() => handleAddToShowcase(id)}>
                        {addedToShowcase ? 'Remove from Showcase' : 'Add to Showcase'}
                      </MenuItem>
                      <MenuItem onClick={() => handleEdit(id)}>Edit</MenuItem>
                      <MenuItem onClick={() => handleDelete(id)}>Delete</MenuItem>
                    </Menu>
                  </>
                }
              />
              <CardContent className={classes.content}>
                <Typography style={{ color: themedColor }} variant="body2" color="textSecondary" component="p">
                  {description}
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      {modalOpened && (
        <TestimonialModal handleClick={handleTestimonialModalClick} handleClose={handleClose} isOpen={modalOpened} />
      )}
      {openedModal === 'new' && (
        <ContributionReviewModal
          item={selected}
          isOpen
          handleSubmit={handleSubmit}
          setAvatarError={setAvatarError}
          avatarError={avatarError}
          handleClose={() => {
            setOpenedModal(null);
            setSelected(null);
          }}
        />
      )}
      {openedModal === 'template' && (
        <TestimonialTemplateModal
          isOpen
          handleSubmit={testimonial => {
            setSelected({ ...testimonial, isTemplate: true });
            setOpenedModal('new');
          }}
          handleClose={() => {
            setOpenedModal(null);
            setModalOpened(true);
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

const actions = {
  getAllTestimonialTemplates: fetchTestimonialTemplates,
};

export default connect(null, actions)(ContributionReviews);
