import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setActivePaidTierPlan } from 'actions/paidTierOptions';
import { updateUser } from 'actions/update-user';
import useRouter from 'hooks/useRouter';
import useAccount from 'hooks/useAccount';

import { Link } from 'react-router-dom';
import { Grid, useMediaQuery } from '@material-ui/core';
import { ROUTES } from 'constants.js';

import { cancelPaidTierOptions, getCurrentPlan, hidePaidTierOptionBanner } from 'services/paidTierOptions.service';

import ToggleSwitch from 'components/switch';
import { OnePlanViewContainer, useStyles, PlansTitle } from '../Billing.styles';

import PaidTierExtended from './PaidTierBigView';
import CohereAcademy from './CohereAcademy';
import Description from '../DefaultBillingView/Billing.description';
import PaidTierModal from '../../../../components/Modals/PaidTierModal';

import { SuccessfulPurchaseModal } from '../../../../components/Modals/SuccessfulPurchaseModal';
import { FailedPurchaseModal } from '../../../../components/Modals/FailedPurchaseModal';

import Dictionary from '../dictionary.json';
import { SIGNUP_TYPES } from 'helpers/constants';
import { getProfile, purchaseSuccessful } from 'actions/user';

const OnePlanView = ({ modalsApi }) => {
  const [cohereacademyactive, setcohereacdemyactive] = useState(false);
  const ResponsivemobileView = useMediaQuery('(max-width: 450px)');
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname, query, history } = useRouter();
  const { signupType } = useSelector(state => state.account);
  const { user } = useAccount();

  const isDashboard = pathname.includes('dashboard');

  // hot-fixed as it sets outdated state
  // useEffect(() => {
  //   return () => {
  //     if (isDashboard) {
  //       hidePaidTierOptionBanner();
  //
  //       const userWithHiddenPaidTierOptionBanner = { ...user, paidTierOptionBannerHidden: true };
  //       dispatch(updateUser(userWithHiddenPaidTierOptionBanner));
  //     }
  //   };
  // }, []);

  const [paidTierSuccessModalOpen, setPaidTierSuccessModalOpen] = useState(query.success === 'true');
  const [paidTierFailedModalOpen, setPaidTierFailedModalOpen] = useState(query.success === 'false');
  const [isShowAcademyWarning, setIsShowAcademyWarning] = useState(false);

  const clearUrlSearch = () => {
    history.replace({
      search: '',
    });
  };

  const handleSuccessModalClose = () => {
    setPaidTierSuccessModalOpen(false);
    clearUrlSearch();
    dispatch(purchaseSuccessful('NONE'));
    history.push(ROUTES.DASHBOARD);
  };
  const handleFailedModalClose = () => {
    setPaidTierFailedModalOpen(false);
    clearUrlSearch();
  };

  const { isShowBeforeCancellationWarning, setIsShowBeforeCancellationWarning } = modalsApi.warning_before_cancellation;
  const { setIsShowSuccessCancellationModal, isShowSuccessCancellationModal } = modalsApi.cancelletion_success;

  const cancelSubsAfterConfirm = (userId, itemId) => {
    cancelPaidTierOptions(userId, itemId).then(() => {
      getCurrentPlan().then(data => {
        dispatch(setActivePaidTierPlan(data));
        setIsShowSuccessCancellationModal({
          isShow: true,
          label: `Your ${
            data?.paidTierOption?.displayName
          } plan has been successfully cancelled. These changes will go into effect on ${new Date(
            data?.endDateTime,
          ).toLocaleDateString('en-US')} & you will be on the free ${data?.nextPaidTierOption?.displayName} plan.`,
        });
      });
    });
  };

  const cancellationWarningHandleClose = () => {
    setIsShowBeforeCancellationWarning({
      isShow: false,
      label: '',
      userId: null,
      itemId: null,
    });
  };
  const cancellationWarningHandleSubmit = () => {
    cancelSubsAfterConfirm(isShowBeforeCancellationWarning.userId, isShowBeforeCancellationWarning.itemId);

    setIsShowBeforeCancellationWarning({
      isShow: false,
      label: '',
      userId: null,
      itemId: null,
    });
  };
  const shouldHide =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    user.signupType === SIGNUP_TYPES.TYPE_B ||
    user.signupType === SIGNUP_TYPES.TYPE_A;
  return (
    <>
      {!isDashboard && <Description />}
      <OnePlanViewContainer className={classes.onePlanViewContainerMedia}>
        <PaidTierExtended setcohereacdemyactive={setcohereacdemyactive} modalsApi={modalsApi} />
        {ResponsivemobileView ? (
          <>{cohereacademyactive ? <CohereAcademy setIsShowAcademyWarning={setIsShowAcademyWarning} /> : ''}</>
        ) : (
          <CohereAcademy setIsShowAcademyWarning={setIsShowAcademyWarning} />
        )}
      </OnePlanViewContainer>

      {/* {!shouldHide && (
        <Grid container className={classes.moreOptionsLink}>
          <Link to={ROUTES.ACCOUNT_BILLING_EXTENDED}>See more options</Link>
        </Grid>
      )} */}

      {isShowBeforeCancellationWarning.isShow && (
        <PaidTierModal
          title="Cancellation"
          submitTitle="Yes"
          isOpen={isShowBeforeCancellationWarning.isShow}
          handleClose={cancellationWarningHandleClose}
          handleSubmit={cancellationWarningHandleSubmit}
          label={isShowBeforeCancellationWarning.label}
        />
      )}

      {isShowSuccessCancellationModal.isShow && (
        <PaidTierModal
          isOpen={isShowSuccessCancellationModal.isShow}
          handleClose={() => setIsShowSuccessCancellationModal(false)}
          handleSubmit={() => setIsShowSuccessCancellationModal(false)}
          label={isShowSuccessCancellationModal.label}
          hiddenCancel
        />
      )}

      {paidTierSuccessModalOpen && (
        <SuccessfulPurchaseModal isOpen={paidTierSuccessModalOpen} handleClose={handleSuccessModalClose} />
      )}

      {isShowAcademyWarning && (
        <PaidTierModal
          title="Warning"
          submitTitle="Ok"
          isOpen={isShowAcademyWarning}
          handleSubmit={() => setIsShowAcademyWarning(false)}
          handleClose={() => setIsShowAcademyWarning(false)}
          hiddenCancel
          label="To access the Academy, please subscribe to correct billing plan."
        />
      )}

      {paidTierFailedModalOpen && (
        <FailedPurchaseModal isOpen={paidTierFailedModalOpen} handleClose={handleFailedModalClose} />
      )}
    </>
  );
};

export default OnePlanView;
