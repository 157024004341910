import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import axiosInstance from 'utils/axiosInstance';
import styled from 'styled-components';
import useRouter from 'hooks/useRouter';
import Nav from './components/sections/Nav';
import About from './components/sections/About';
import Links from './components/sections/Links';
import LetsWorkTogether from './components/sections/LetsWorkTogether';
import { LeadMagnetTypesMap } from 'utils/getLeadMagnetTypeView';
import { cloneDeep } from 'lodash';

export const Main = styled.div`
  background-color: ${props => (props.theme === 'dark' ? '#252728' : 'white')};
  font-family: 'Poppins';
  min-height: 100vh;
  overflow: 'auto';
  @media (max-width: ${props => props.mobileView}) {
    background-color: ${props => (props.theme === 'dark' ? '#252728' : 'white')};
  }
`;
const checkIfLeadMagnet = type => Object.hasOwnProperty.call(LeadMagnetTypesMap, type);
function ViewAsCoachPage({ match: { path } }) {
  const [theme, setTheme] = useState('dark');
  const [apiData, setApiData] = useState(null);
  const [dynamicLinks, setDynamicLinks] = useState([]);
  const [dynamicArrayOfContributions, setDynamicArrayOfContributions] = useState([]);
  const { subdomain, pathname } = useRouter();

  const getApiData = () => axiosInstance.get(`/ProfilePage/GetProfilePageInfoByName?profileName=${subdomain}`);
  const [currentPage, setCurrentPage] = useState(pathname.replace('/', ''));
  const mobileView = '576px';

  ViewAsCoachPage.propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string,
      url: PropTypes.string,
      isExact: PropTypes.bool,
      params: PropTypes.object,
    }).isRequired,
  };
  const themedHoverColor = theme === 'dark' ? 'white' : '#d08acd';

  const about = [
    {
      name: 'About',
      path: 'about',
    },
  ];
  const links = [
    {
      name: 'Links',
      path: 'links',
    },
  ];

  useEffect(() => {
    setCurrentPage(pathname.replace('/', ''));
  }, [pathname]);
  useEffect(() => {
    getApiData().then(response => {
      if (response?.data) {
        let { profilePageViewModel = {} } = response.data || {};
        if (profilePageViewModel === null) {
          profilePageViewModel = {
            contributions: [],
            isDarkModeEnabled: false,
          };
        }
        const { contributions = [], isDarkModeEnabled } = profilePageViewModel;
        const res = contributions.reduce((acc, currentValue) => {
          if (!currentValue?.isEnabled) {
            return acc;
          }
          let existedObj;
          const contributionType = currentValue?.isLeadMagnet ? 'Free Content' : currentValue.type;
          if (acc[contributionType] && acc[contributionType].length > 0) {       
            existedObj = acc[contributionType].find(
              obj => (obj?.isLeadMagnet ? 'Free Content' : obj.type) === contributionType,
            );
          }
          if (existedObj) {
            acc[contributionType].push(currentValue);
          } else {
            acc[contributionType] = new Array(currentValue);
          }
          return acc;
        }, {});
        setDynamicLinks(
          Object.keys(res).map(key => {
            let navPath = key.toLowerCase();
            navPath = navPath.replace(/ /g, '_');
            return { name: key, path: `${navPath}` };
          }),
        );
        setDynamicArrayOfContributions(res);
        if (!isDarkModeEnabled) {
          setTheme('light');
        }
        setApiData(response?.data);
      }
    });
  }, []);

  return (
    <>
      {apiData && (
        <Main mobileView={mobileView} theme={theme}>
          <Nav
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            links={about.concat(dynamicLinks).concat(links)} /* all routes */
            apiData={apiData?.profilePageViewModel}
            brandingColors={apiData?.brandingColors}
            theme={theme}
            customLogo={apiData?.customLogo}
            themedHoverColor={themedHoverColor}
            messageRedirection={apiData?.profilePageViewModel?.messageRedirection}
          />
          <Switch>
            <Route path="/about" render={() => <About data={apiData} theme={theme} />} />
            <Route
              path="/links"
              render={() => (
                <span className="other-section">
                  <Links
                    profileImage={apiData?.avatarUrl}
                    data={apiData?.profilePageViewModel}
                    theme={theme}
                    brandingColors={apiData?.brandingColors}
                  />
                </span>
              )}
            />
            {dynamicLinks &&
              dynamicArrayOfContributions &&
              dynamicLinks.map(link => {
                return (
                  <Route
                    path={`/${link.path}`}
                    render={() => (
                      <span className="other-section">
                        <LetsWorkTogether
                          contributions={dynamicArrayOfContributions[link?.name]}
                          data={apiData?.profilePageViewModel}
                          theme={theme}
                          brandingColors={apiData?.brandingColors}
                        />
                      </span>
                    )}
                  />
                );
              })}
            <Redirect to="/about" />
          </Switch>
        </Main>
      )}
    </>
  );
}

export default ViewAsCoachPage;
