import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';
import { useAccount, useClientPreview, useScreenView, useShallowEqualSelector } from 'hooks';
import MeetYourCoach from 'pages/ContributionView/components/AboutMainInfoBlock/MeetYourCoachTemTwo';
import AboutMainInfoBlock from 'pages/ContributionView/components/AboutMainInfoBlock/AboutMainInfoTemTwo';
import AboutDetailsBlock from 'pages/ContributionView/components/AboutDetailsBlockTemTwo';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import useRouter from 'hooks/useRouter';
import { UserRoles } from 'helpers/constants';
import styled from 'styled-components';
import OptinPageMetaPixelLM from 'components/AdsComponents/OptinPageLM';
import GoogleAnalytics from 'components/AdsComponents/GoogleTag';
import ClarityScript from 'components/AdsComponents/ClarityScript';
import PixelComponent from 'components/AdsComponents/PixelComponent';

const StyledGrid = styled(Grid)`
  width: 100%;
  margin: 0;
  pointer-events: ${({ disableUserInteraction }) => disableUserInteraction && 'none'};
  a {
    pointer-events: initial;
  }
`;

function LeadMagnetLandingTempOne() {
  const leadMagnet = useLeadMagnet();
  const {
    id,
    previewContentUrls,
    categories,
    serviceProviderName,
    gender,
    minAge,
    languageCodes,
    whoIAm,
    whatWeDo,
    purpose,
    preparation,
    earnedRevenue,
    paymentInfo,
    enrollment,
    coachCountry,
    closestClassForBanner,
    contributionPartners,
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    tiktokUrl,
    facebookUrl,
    websiteUrl,
    whoIAmLabel,
    whatWeDoLabel,
    purposeLabel,
    preparationLabel,
    isPurchased,
    type,
    viewPreparation,
    viewPurpose,
    viewWhatWeDo,
    viewWhoIAm,
    whoIAmIcon,
    whatWeDoIcon,
    purposeIcon,
    preparationIcon,
    title,
    customTitleForMeetYourCoach,
    contributionTagline,
    testimonials,
    isCustomBrandingColorsActive,
    brandingColors,
    bio,
    coachAvatarUrl,
  } = leadMagnet;

  let { colorToUse } = useLeadMagnetTheme();
  const urls = {
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    facebookUrl,
    websiteUrl,
    tiktokUrl,
  };
  const { userId } = leadMagnet;
  const { user, isCoach } = useAccount();
  const { mobileView } = useScreenView();
  const { pathname } = useRouter();
  const { clientPreviewMode: isClientPreview } = useClientPreview();
  return (
    !isEmpty(leadMagnet) && (
      <>
        {id === '66466bdaee9e6c514da9d7d9' && <OptinPageMetaPixelLM />}
        {id === '66466bdaee9e6c514da9d7d9' && <GoogleAnalytics />}
        {id === '66466bdaee9e6c514da9d7d9' && <ClarityScript />}
        {id === '66611264d7b9941dec80d5b9' && <PixelComponent />}
        <StyledGrid
          container
          // spacing={4}
          // disableUserInteraction={isClientPreview || isCoach}
          disableUserInteraction={isClientPreview}
          // style={{ width: '100%', margin: '0', pointerEvents: (isClientPreview || isCoach) && 'none' }}
        >
          <Grid item xs={12} style={{ padding: '0px' }}>
            <AboutMainInfoBlock
              id={id}
              user={user}
              userId={userId}
              previewContentUrls={previewContentUrls}
              socialUrls={urls}
              categories={categories}
              contributionTagline={contributionTagline}
              title={title}
              earnedRevenue={earnedRevenue}
              paymentInfo={paymentInfo}
              brandingColors={colorToUse}
              isLeadMagnet
            />
          </Grid>

          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={{ paddingTop: mobileView ? '0rem' : '2.69rem', margin: mobileView ? '1rem' : '' }}
          >
            {
              <Grid item xs={12} style={{ marginBottom: mobileView ? '0rem' : '-5rem' }}>
                <AboutDetailsBlock
                  whoIAm={whoIAm}
                  whatWeDo={whatWeDo}
                  purpose={purpose}
                  preparation={preparation}
                  whoIAmLabel={whoIAmLabel}
                  whatWeDoLabel={whatWeDoLabel}
                  purposeLabel={purposeLabel}
                  preparationLabel={preparationLabel}
                  viewPreparation={viewPreparation}
                  viewPurpose={viewPurpose}
                  viewWhatWeDo={viewWhatWeDo}
                  viewWhoIAm={viewWhoIAm}
                  whoIAmIcon={whoIAmIcon}
                  whatWeDoIcon={whatWeDoIcon}
                  purposeIcon={purposeIcon}
                  preparationIcon={preparationIcon}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={colorToUse}
                  isLeadMagnet
                />
              </Grid>
            }
            {!mobileView && (
              <Grid item xs={12}>
                <MeetYourCoach
                  id={id}
                  user={user}
                  userId={userId}
                  bio={bio || ''}
                  coachCountry={coachCountry || ''}
                  customTitleForMeetYourCoach={customTitleForMeetYourCoach}
                  previewContentUrls={previewContentUrls}
                  serviceProviderName={serviceProviderName || ''}
                  contributionPartners={contributionPartners}
                  socialUrls={urls}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={colorToUse}
                  isLeadMagnet
                  {...{ coachAvatarUrl }}
                />
              </Grid>
            )}

            {mobileView && (
              <Grid item xs={12}>
                <MeetYourCoach
                  mobileView={mobileView}
                  id={id}
                  user={user}
                  userId={userId}
                  bio={bio || ''}
                  coachCountry={coachCountry || ''}
                  customTitleForMeetYourCoach={customTitleForMeetYourCoach || ''}
                  previewContentUrls={previewContentUrls}
                  serviceProviderName={serviceProviderName || ''}
                  contributionPartners={contributionPartners}
                  socialUrls={urls}
                  isCustomBrandingColorsActive={isCustomBrandingColorsActive}
                  brandingColors={brandingColors}
                  isLeadMagnet
                  {...{ coachAvatarUrl }}
                />
              </Grid>
            )}
          </Grid>
        </StyledGrid>
      </>
    )
  );
}

export default LeadMagnetLandingTempOne;
