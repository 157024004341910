import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Loader from 'components/UI/Loader';
import '../../../pages/AllApplicationsPage/ApplicationPage.scss';
import { StyledShareButton } from 'pages/ContributionView/components/ContributionHeader/styled';
import { Icon } from '@mdi/react';
import { mdiShareVariant } from '@mdi/js';
import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import EditButton from 'components/UI/ListButtons/EditButton';
import DeleteButton from 'components/UI/ListButtons/DeleteButton';
import { getCohealerContribution, shareViaEmail } from 'services/contributions.service';
import usePaidTier from 'hooks/usePaidTier';
import moment from 'moment';
import useAccount from 'hooks/useAccount';
import { fetchContributionActions } from 'actions/contributions';
import CreateApplicationFormModal from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/CreateApplicationFormModal';
import { formatTime } from 'utils/utils';
import { TRIAL_PERIOD_DAYS, TRIAL_PERIOD_START_DATE } from '../Contribution';
import DeleteApplicationFormModal from './DeleteApplicationFormModal';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import * as paidTier from 'selectors/paidTier';
import { isPaidTierAnnualSelector } from 'selectors/user';
import useShallowEqualSelector from '../../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../../constants';
import { UserRoles } from 'helpers/constants';
const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})(TableCell);

export const ApplicationFormRow = ({ index, row }) => {
  const { contributionId, contributionName, createTime, id: formId } = row;
  const url = `/contribution-view/${contributionId}/about`;
  const [modalOpened, setModalOpened] = useState(false);
  const [showApplicationFormPopup, setShowApplicationFormPopup] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const [openingModal, setOpeningModal] = useState(false);
  const { user } = useAccount();
  // const { isLaunchPlan } = usePaidTier();
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const todayDate = moment(moment());
  const [contribution, setContribution] = useState(null);
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setDeleteModal] = useState(false);

  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);

  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const handleModalOpen = useCallback(() => {
    if (
      isLaunchPlan &&
      trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
      todayDate.isAfter(trialPeriodEnd)
    ) {
      return;
    }
    setOpeningModal(true);
    getCohealerContribution(contributionId)
      .then(contrib => {
        setContribution(contrib);
        setModalOpened(true);
      })
      .finally(() => setOpeningModal(false));
  }, [setModalOpened, isLaunchPlan, user]);

  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  const handleEdit = () => {
    setOpeningModal(true);
    getCohealerContribution(contributionId).then(data => {
      dispatch(
        fetchContributionActions.success({
          ...data,
          brandingColors: undefined,
          coachSelectedBrandingColors: undefined,
          isDarkModeEnabled: undefined,
        }),
      );
      setShowApplicationFormPopup(true);
      setOpeningModal(false);
    });
  };

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const handleModalSubmit = useCallback(
    emails => {
      shareViaEmail({ contributionId, emailAddresses: emails })
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({ isError: true, errorMessage: err?.response?.data?.message });
        });
    },
    [handleModalClose],
  );

  return (
    <>
      <TableRow key={index}>
        <NarrowTableCell style={{ textDecoration: 'underline' }}>
          <Link to={url}>{contributionName}</Link>
        </NarrowTableCell>
        <NarrowTableCell component="th" scope="row">
          {formatTime(createTime)}
        </NarrowTableCell>
        <NarrowTableCell>
          <StyledShareButton
            style={{ float: 'left', width: 'auto' }}
            startIcon={<Icon path={mdiShareVariant} color="#116582" size={1} />}
            onClick={handleModalOpen}
          />
        </NarrowTableCell>
        <NarrowTableCell>
          <EditButton handleOnClick={handleEdit} />
        </NarrowTableCell>
        <NarrowTableCell>
          <DeleteButton handleOnClick={handleDelete} />
        </NarrowTableCell>
      </TableRow>

      {showApplicationFormPopup && (
        <CreateApplicationFormModal
          showApplicationFormPopup={showApplicationFormPopup}
          setShowApplicationFormPopup={setShowApplicationFormPopup}
          onCancel={() => dispatch(fetchContributionActions.success(null))}
          onSubmit={() => dispatch(fetchContributionActions.success(null))}
          onClose={() => {}}
          isEditMode
        />
      )}

      {modalOpened && (
        <ShareModal
          isOpen={modalOpened}
          onSubmit={handleModalSubmit}
          onModalClose={() => setModalOpened(false)}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
          contributionId={contributionId}
          paymentInfo={contribution?.paymentInfo || {}}
          type={contribution?.type || ''}
          isInvoiced={contribution?.isInvoiced}
          symbol={contribution?.defaultSymbol || '$'}
        />
      )}
      {openingModal && <Loader />}
      {isDeleteModalOpen && (
        <DeleteApplicationFormModal
          contributionName={contributionName}
          isOpen={isDeleteModalOpen}
          setOpen={setDeleteModal}
          contributionId={contributionId}
          formId={formId}
        />
      )}
    </>
  );
};
ApplicationFormRow.propTypes = {
  index: PropTypes.string.isRequired,
  row: PropTypes.shape({
    contributionName: PropTypes.string.isRequired,
    formLinkId: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
    questions: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

ApplicationFormRow.defaultProps = {
  row: {
    contributionName: '',
    formLinkId: '',
    contributionId: '',
    questions: '',
  },
};
