import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { styleOverrideStyledFn } from './styled';
import { useContribution } from 'pages/ContributionView/hooks';
import useClientPreview from 'hooks/useClientPreview';
import useAccount from 'hooks/useAccount';
import { isElementInViewport } from '../helpers';
import { useSelector } from 'react-redux';
import { TemplateType } from 'helpers/constants/templateType';
import { FILTERTYPES } from '../constants';
import { debounce, isEqual } from 'lodash';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';
import { useChangedProps } from 'hooks/index';

// const ColumnHead = props => {
//   const { children, styles } = props;

//   const Styled = styled.div`
//     display: flex;
//     height: 4.375rem;
//     padding: 0.9375rem 0.625rem;
//     flex-direction: column;
//     align-items: center;
//     align-self: stretch;

//     border-bottom: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
//     background: #fff;

//     ${styleOverrideStyledFn}
//   `;
//   return (
//     <>
//       <Styled styleOverrides={styles}>{children}</Styled>
//     </>
//   );
// };
// const ColumnBody = props => {
//   const { children, styles } = props;
//   const Styled = styled.div`
//     display: flex;
//     padding: 0rem 1.5rem;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 0.625rem;
//     align-self: stretch;

//     ${styleOverrideStyledFn}
//   `;
//   return (
//     <>
//       <Styled styleOverrides={styles}>{children}</Styled>
//     </>
//   );
// };
// const GenericColumn = props => {
//   const { children, head, body, headStyles, bodyStyles, styles } = props;
//   const Styled = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 0.625rem;
//     align-self: stretch;

//     ${styleOverrideStyledFn}
//   `;
//   return (
//     <>
//       <Styled styleOverrides={styles}>
//         <ColumnHead styles={headStyles}>{head}</ColumnHead>
//         <ColumnBody styles={bodyStyles}>{body}</ColumnBody>
//       </Styled>
//     </>
//   );
// };
const ColumnHead = styled.div`
  transition: all 0.3s ease-in-out;
  display: flex;
  height: 4.375rem;
  padding: 0.9375rem 0.625rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  border-bottom: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  background: #fff;

  ${styleOverrideStyledFn}
`;
const ColumnBody = styled.div`
  transition: all 0.3s ease-in-out;
  display: flex;
  padding: 0rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;

  ${styleOverrideStyledFn}
`;
const ColumnBodyEdit = styled.div`
  transition: all 0.3s ease-in-out;
  display: flex;
  padding: 0rem 0rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;

  ${styleOverrideStyledFn}
`;
const ColumnContainer = styled.div`
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;

  ${styleOverrideStyledFn}
`;

const LeftContainer = styled(ColumnContainer)`
  /* border: 1px solid blue; */
  ${({ mobileView }) =>
    mobileView
      ? css`
          flex: 1;
        `
      : css`
          flex-basis: 350px;
          flex-grow: 350;
          max-width: 350px;
          min-width: 350px;
        `}
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  border: solid ${({ borderColor }) => borderColor || '#dfe3e4'};
  /* border-width: 1px 1px 0 0; */
  border-width: 0 1px 0 0;
  background: ${({ bodyBackgroundColor, backgroundColor }) => bodyBackgroundColor || backgroundColor || '#fff'};
  padding-bottom: 1.5rem;
  ${({ isCenterColumnFullScreenMode }) =>
    isCenterColumnFullScreenMode &&
    css`
      display: none !important;
    `};
  ${({ mobileView }) =>
    mobileView
      ? css`
          /* padding-top: 1.25rem; */
          /* padding-bottom: 1.25rem; */
          padding-bottom: 5.5rem;
          /* margin-bottom: 5.5rem; */
        `
      : css`
          padding-bottom: 7.04rem;
        `};
  ${({
    useInternalLeftColumnScroll,
    isClient,
    isCoach,
    activeTemplate,
    isApplicationFormBannerDisplayed,
    isBannerInViewport,
  }) =>
    useInternalLeftColumnScroll &&
    css`
      overflow-y: scroll;
      /* min-height: fit-content; */
      height: calc(
        100vh -
          ${isClient && (activeTemplate === TemplateType.TemplateTwo || activeTemplate === TemplateType.TemplateOne)
            ? 177
            : 242}px - ${isCoach && isApplicationFormBannerDisplayed && isBannerInViewport ? 68 : 0}px
      );
      /* height: calc(100vh - 242px); */
      /* min-height: calc(100vh - 350px);
      height: 100%; */
      /* max-height: max-content; */
      position: sticky;
      /* top: ${isApplicationFormBannerDisplayed ? 350 : 242}px; */
      top: ${isClient && (activeTemplate === TemplateType.TemplateTwo || activeTemplate === TemplateType.TemplateOne)
        ? 177
        : 242}px;
    `}
  ${props => props.containerStyles && props.containerStyles}
`;
const LeftHead = styled(ColumnHead)`
  ${props =>
    props.headBackgroundColor &&
    css`
      background-color: ${props.headBackgroundColor};
    `};
  ${props =>
    props.headBorderColor &&
    css`
      border-color: ${props.headBorderColor};
    `};
  ${props => props.headStyles && props.headStyles}
`;
const LeftBody = styled(ColumnBody)`
  .session-group-container {
    // copied from "ColumnBody" styles
    display: flex;
    /* padding: 0rem 1.5rem; */
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
  }
  ${props =>
    props.bodyBackgroundColor &&
    css`
      background-color: ${props.bodyBackgroundColor};
    `};
  ${props =>
    props.bodyBorderColor &&
    css`
      border-color: ${props.bodyBorderColor};
    `};
  ${props => props.bodyStyles && props.bodyStyles}
`;

const RightSidebarColumnContainer = styled(ColumnContainer)`
  /* border: 1px solid green; */
  ${({ mobileView, collapsed }) =>
    mobileView
      ? css`
          flex: 1;
        `
      : css`
          flex-basis: 295px;
          flex-grow: ${collapsed ? 0 : 295};
          max-width: ${collapsed ? '4rem' : '295px'};
          min-width: ${collapsed ? '4rem' : '295px'};
        `}
  border: solid ${({ borderColor }) => borderColor || '#dfe3e4'};
  /* border-width: 1px 0 0 1px; */
  border-width: 0 0 0 1px;
  background: ${({ bodyBackgroundColor, backgroundColor }) => bodyBackgroundColor || backgroundColor || '#fff'};
  ${({ isCenterColumnFullScreenMode }) =>
    isCenterColumnFullScreenMode &&
    css`
      display: none !important;
    `}
  ${({ mobileView }) =>
    mobileView
      ? css`
          /* padding-top: 1.25rem; */
          /* padding-bottom: 1.25rem; */
          padding-bottom: 5.5rem;
          /* margin-bottom: 5.5rem; */
        `
      : css`
          padding-bottom: 7.04rem;
        `}
    ${({ containerStyles }) => containerStyles && containerStyles}
`;

const RightSidebarColumnHead = styled(ColumnHead)`
  ${({ headBackgroundColor }) =>
    headBackgroundColor &&
    css`
      background-color: ${headBackgroundColor};
    `};
  ${({ headBorderColor }) =>
    headBorderColor &&
    css`
      border-color: ${headBorderColor};
    `};
  ${({ headStyles }) => headStyles && headStyles}
`;

const RightSidebarColumnBody = styled(ColumnBody)`
  padding: 0px 20px;
  overflow-x: hidden;
  ${({ bodyBackgroundColor }) =>
    bodyBackgroundColor &&
    css`
      background-color: ${bodyBackgroundColor};
    `};
  ${({ bodyBorderColor }) =>
    bodyBorderColor &&
    css`
      border-color: ${bodyBorderColor};
    `};
  ${({ bodyStyles }) => bodyStyles && bodyStyles}
`;

const MainContentColumnContainer = styled(ColumnContainer)`
  /* border: 1px solid magenta; */
  ${({ mobileView, leftColWidth, rightColWidth, rightColCollapsed, extraWidth }) =>
    mobileView
      ? css`
          flex: 1;
        `
      : css`
          flex-basis: 874px;
          flex-grow: 874;
          min-width: calc(
            100vw - ${leftColWidth || '350px'} - ${rightColWidth || (rightColCollapsed ? '4rem' : '295px')} -
              ${extraWidth || '17px'}
          );
          max-width: calc(
            100vw - ${leftColWidth || '350px'} - ${rightColWidth || (rightColCollapsed ? '4rem' : '295px')} -
              ${extraWidth || '17px'}
          );
          width: calc(
            100vw - ${leftColWidth || '350px'} - ${rightColWidth || (rightColCollapsed ? '4rem' : '295px')} -
              ${extraWidth || '17px'}
          );
          /* max-width: 1050px; */
          width: 65.5%;
        `}
  border: solid ${({ borderColor }) => borderColor || '#dfe3e4'};
  /* border-width: 1px 0 0; */
  border-width: 0;
  background: ${({ bodyBackgroundColor, backgroundColor }) => bodyBackgroundColor || backgroundColor || '#fff'};
  ${({ isCenterColumnFullScreenMode }) =>
    isCenterColumnFullScreenMode &&
    css`
      flex: 1;
      min-width: calc(100vw - 0rem - 17px);
      max-width: calc(100vw - 0rem - 17px);
      display: flex;
      position: absolute;
      top: 0;
      z-index: 22;
      min-height: 100vh;
      max-height: max-content;
      height: fit-content;
    `}
  ${({ mobileView, isClient }) =>
    mobileView
      ? css`
          padding-top: 1.25rem;
          /* padding-bottom: 1.25rem; */
          /* padding-bottom: 5.5rem; */
          ${isClient &&
          css`
            padding-bottom: 1.25rem;
          `}
        `
      : css`
          padding-bottom: 7.04rem;
        `}
    ${({ containerStyles }) => containerStyles && containerStyles}
`;

const MainContentColumnHead = styled(ColumnHead)`
  padding: ${({ mobileView }) => (mobileView ? '0 1.12rem 1rem' : '1rem 1.5rem')};
  position: relative;
  ${({ headBackgroundColor }) =>
    headBackgroundColor &&
    css`
      background-color: ${headBackgroundColor};
    `};
  ${({ headBorderColor }) =>
    headBorderColor &&
    css`
      border-color: ${headBorderColor};
    `};
  ${({ headStyles }) => headStyles && headStyles}
`;

const MainContentColumnBody = styled(ColumnBody)`
  ${({ bodyBackgroundColor }) =>
    bodyBackgroundColor &&
    css`
      background-color: ${bodyBackgroundColor};
    `};
  ${({ bodyBorderColor }) =>
    bodyBorderColor &&
    css`
      border-color: ${bodyBorderColor};
    `};
  /* &.pdf-viewer-visible {
      ${({ mobileView }) =>
    mobileView &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
    } */
  ${({ bodyStyles }) => bodyStyles && bodyStyles}
`;

const MainContentColumnEditBody = styled(ColumnBodyEdit)`
  ${({ bodyBackgroundColor }) =>
    bodyBackgroundColor &&
    css`
      background-color: ${bodyBackgroundColor};
    `};
  ${({ bodyBorderColor }) =>
    bodyBorderColor &&
    css`
      border-color: ${bodyBorderColor};
    `};
  /* &.pdf-viewer-visible {
      ${({ mobileView }) =>
    mobileView &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
    } */
  ${({ bodyStyles }) => bodyStyles && bodyStyles}
`;

// const LeftSidebarColumn = styled.div``;
// const RightSidebarColumn = styled.div``;
// const MainContentColumn = styled.div``;
export const LeftSidebarColumn = memo(props => {
  const {
    head = null,
    children = null,
    borderColor,
    backgroundColor,
    headBackgroundColor,
    bodyBackgroundColor,
    headBorderColor,
    bodyBorderColor,
    mobileView = false,
    containerStyles,
    headStyles,
    bodyStyles,
    useInternalLeftColumnScroll = false,
    filter,
  } = props;
  const contribution = useContribution();
  const { isCenterColumnFullScreenMode, selectedSessionId, selectedSessionTimeOrContentId } = useSelector(
    activeContribSessTabViewSettingsSelector,
  );
  // const isCenterColumnFullScreenMode = false;
  const { isApplicationFormBannerPreviewed, applicationFormViewModel, activeTemplate } = useContribution();
  const { clientPreviewMode } = useClientPreview();
  const { isCoach, isClient } = useAccount();
  const isApplicationFormBannerDisplayed =
    isCoach &&
    isApplicationFormBannerPreviewed === 'false' &&
    // showApplicationFormBanner &&
    !applicationFormViewModel &&
    !clientPreviewMode;
  const bannerEl = document.getElementById('app-form-banner');
  const [isBannerInViewport, setIsBannerInViewport] = useState(isElementInViewport(bannerEl));
  useEffect(() => {
    const checkAndSetVisibility = () => setIsBannerInViewport(isElementInViewport(bannerEl));

    // document.addEventListener('scroll', checkAndSetVisibility);
    // document.addEventListener('resize', checkAndSetVisibility);
    window.addEventListener('scroll', checkAndSetVisibility);
    window.addEventListener('resize', checkAndSetVisibility);
    return () => {
      // document.removeEventListener('scroll', checkAndSetVisibility);
      // document.removeEventListener('resize', checkAndSetVisibility);
      window.removeEventListener('scroll', checkAndSetVisibility);
      window.removeEventListener('resize', checkAndSetVisibility);
    };
  }, []);
  useEffect(() => {
    console.info('left mounted');
  }, []);

  const scrollToIdMemoized = useCallback(idToScroll => {
    if (idToScroll) {
      // setTimeout(() => {
      const element = document.getElementById(idToScroll);
      console.info('el-1:', idToScroll, Boolean(element));
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
      // }, 50);
    }
  }, []);
  const debouncedScroll = debounce(scrollToIdMemoized, 100);
  // useLayoutEffect(() => {
  //   if (
  //     selectedSessionId &&
  //     selectedSessionTimeOrContentId &&
  //     [FILTERTYPES.ALL, FILTERTYPES.LIVE, FILTERTYPES.UPCOMING].includes(filter)
  //   ) {
  //     // let t;
  //     console.info('updated-2:', selectedSessionId, selectedSessionTimeOrContentId);
  //     const idToScroll = selectedSessionTimeOrContentId || selectedSessionId;
  //     if (idToScroll) {
  //       const t = setTimeout(() => {
  //         const element = document.getElementById(idToScroll);
  //         console.info('el-2:', idToScroll, Boolean(element));
  //         if (element) {
  //           element.scrollIntoView({ behavior: 'auto', block: 'center' });
  //         }
  //       }, 50);
  //       return () => {
  //         console.info('cleared timeout-2:', t);
  //         clearTimeout(t);
  //       };
  //     }
  //     // debouncedScroll(idToScroll);
  //   }
  // }, [selectedSessionTimeOrContentId, selectedSessionId, filter]);
  const mounted = useRef(false);
  useEffect(() => {
    if (
      !mounted.current &&
      selectedSessionId &&
      selectedSessionTimeOrContentId
      // && [FILTERTYPES.ALL, FILTERTYPES.LIVE, FILTERTYPES.UPCOMING].includes(filter)
    ) {
      mounted.current = true;
      // let t;
      const idToScroll = selectedSessionTimeOrContentId || selectedSessionId;
      if (idToScroll) {
        const t = setTimeout(() => {
          const element = document.getElementById(idToScroll);
          console.info('el-2:', idToScroll, Boolean(element));
          if (element) {
            const scrollOptions = { behavior: 'auto', block: 'center', inline: 'nearest' };
            element.scrollIntoViewIfNeeded ? element.scrollIntoViewIfNeeded(true) : element.scrollIntoView(scrollOptions);
          }
        }, 2000);
        return () => {
          console.info('cleared timeout-2:', t);
          clearTimeout(t);
        };
      }
      // debouncedScroll(idToScroll);
    }
  }, [selectedSessionId, selectedSessionTimeOrContentId, filter]);
  useEffect(() => {
    // if (
    //   selectedSessionId &&
    //   selectedSessionTimeOrContentId &&
    //   [FILTERTYPES.ALL, FILTERTYPES.LIVE, FILTERTYPES.UPCOMING].includes(filter)
    // ) {
    //   // let t;
    //   console.info('updated-2:', selectedSessionId, selectedSessionTimeOrContentId);
    //   const idToScroll = selectedSessionTimeOrContentId || selectedSessionId;
    //   if (idToScroll) {
    //     const t = setTimeout(() => {
    //       const element = document.getElementById(idToScroll);
    //       console.info('el-2:', idToScroll, Boolean(element));
    //       if (element) {
    //         element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //       }
    //     }, 2000);
    //     return () => {
    //       console.info('cleared timeout-2:', t);
    //       clearTimeout(t);
    //     };
    //   }
    //   // debouncedScroll(idToScroll);
    // }
    mounted.current = false;
  }, [filter]);
  const headPrev = useRef();
  useEffect(() => {
    console.info('head:', isEqual(headPrev.current, head));
    headPrev.current = head;
  }, [head]);
  const childrenPrev = useRef();
  useEffect(() => {
    console.info('children:', isEqual(childrenPrev.current, children));
    childrenPrev.current = children;
  }, [children]);

  const memoizedHead = useMemo(
    () =>
      head ? (
        <LeftHead className="column--sessions-contents--head" {...{ headBackgroundColor, headBorderColor }}>
          {head}
        </LeftHead>
      ) : (
        <></>
      ),
    [head],
  );
  const memoizedChildren = useMemo(
    () => (children ? <LeftBody className="column--sessions-contents--body">{children}</LeftBody> : <></>),
    [children],
  );

  return (
    <LeftContainer
      className="column--sessions-contents"
      {...{
        mobileView,
        borderColor,
        bodyBackgroundColor,
        backgroundColor,
        isCenterColumnFullScreenMode,
        useInternalLeftColumnScroll,
        isApplicationFormBannerDisplayed,
        isBannerInViewport,
        isClient,
        isCoach,
        activeTemplate,
      }}
    >
      {memoizedHead}
      <LeftBody className="column--sessions-contents--body" {...{ bodyBackgroundColor, bodyBorderColor }}>
        {children}
      </LeftBody>
    </LeftContainer>
  );
}, isEqual);
export const RightSidebarColumn = props => {
  const {
    head = null,
    collapsed = false,
    children = null,
    borderColor,
    backgroundColor,
    headBackgroundColor,
    bodyBackgroundColor,
    headBorderColor,
    bodyBorderColor,
    mobileView = false,
    containerStyles,
    headStyles,
    bodyStyles,
  } = props;
  const { isCenterColumnFullScreenMode } = useSelector(activeContribSessTabViewSettingsSelector);

  return (
    <RightSidebarColumnContainer
      className="column--attachments-notes"
      mobileView={mobileView}
      collapsed={collapsed}
      borderColor={borderColor}
      bodyBackgroundColor={bodyBackgroundColor}
      backgroundColor={backgroundColor}
      isCenterColumnFullScreenMode={isCenterColumnFullScreenMode}
      containerStyles={containerStyles}
    >
      {head && (
        <RightSidebarColumnHead
          className="column--attachments-notes--head"
          headBackgroundColor={headBackgroundColor}
          headBorderColor={headBorderColor}
          headStyles={headStyles}
        >
          {head}
        </RightSidebarColumnHead>
      )}
      {children && (
        <RightSidebarColumnBody
          className="column--attachments-notes--body"
          bodyBackgroundColor={bodyBackgroundColor}
          bodyBorderColor={bodyBorderColor}
          bodyStyles={bodyStyles}
        >
          {children}
        </RightSidebarColumnBody>
      )}
    </RightSidebarColumnContainer>
  );
};
export const MainContentColumn = props => {
  const {
    head = null,
    rightColCollapsed = false,
    leftColWidth = null,
    rightColWidth = null,
    extraWidth = null,
    children = null,
    borderColor,
    backgroundColor,
    headBackgroundColor,
    bodyBackgroundColor,
    textColor,
    headBorderColor,
    bodyBorderColor,
    mobileView = false,
    containerStyles,
    headStyles,
    bodyStyles,
    isClient = false,
  } = props;
  const { isCenterColumnFullScreenMode } = useSelector(activeContribSessTabViewSettingsSelector);
  const sessionTimeEditMode = useSelector(state => state?.contributions?.editMode);

  return (
    <MainContentColumnContainer
      className="column--main-content"
      mobileView={mobileView}
      leftColWidth={leftColWidth}
      rightColWidth={rightColWidth}
      rightColCollapsed={rightColCollapsed}
      extraWidth={extraWidth}
      bodyBackgroundColor={bodyBackgroundColor}
      backgroundColor={backgroundColor}
      isCenterColumnFullScreenMode={isCenterColumnFullScreenMode}
      isClient={isClient}
      containerStyles={containerStyles}
      borderColor={borderColor}
    >
      {head && (
        <MainContentColumnHead
          className="column--main-content--head"
          mobileView={mobileView}
          headBackgroundColor={headBackgroundColor}
          headBorderColor={headBorderColor}
          headStyles={headStyles}
        >
          {head}
        </MainContentColumnHead>
      )}
      {children && sessionTimeEditMode === false && (
        <MainContentColumnBody
          className="column--main-content--body"
          bodyBackgroundColor={bodyBackgroundColor}
          bodyBorderColor={bodyBorderColor}
          mobileView={mobileView}
          bodyStyles={bodyStyles}
        >
          {children}
        </MainContentColumnBody>
      )}
      {children && sessionTimeEditMode === true && (
        <MainContentColumnEditBody
          className="column--main-content--body"
          bodyBackgroundColor={bodyBackgroundColor}
          bodyBorderColor={bodyBorderColor}
          mobileView={mobileView}
          bodyStyles={bodyStyles}
        >
          {children}
        </MainContentColumnEditBody>
      )}
    </MainContentColumnContainer>
  );
};
