import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/FormUI/Button';
import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { submitApplicationResponse } from 'services/applicationForm.services';
import { useSelector } from 'react-redux';
import { redirectTo } from 'services/links';
import styled from 'styled-components';
import useRouter from 'hooks/useRouter';
import { determineColorToUse } from 'services/contributions.service';
import Modal from 'components/UI/Modal';
import { addhttp, lightOrDark } from 'utils/utils';
import useAccount from 'hooks/useAccount';

const QUESTION_TYPES = {
  Radio: 'Radio',
  Text: 'Text',
  Checkbox: 'CheckBox',
};

const ApplicationForm = ({
  applicationFormViewModel,
  contributionId,
  questionNumber,
  setQuestionNumber,
  coachName,
  contributionTitle,
  isDarkThemeEnabled,
}) => {
  const [answers, setAnswers] = useState(applicationFormViewModel?.questions || []);
  const [submitting, setSubmitting] = useState(false);
  const [showSubmitSuccessModal, setShowSumitSuccessModal] = useState(false);
  const { query, domain } = useRouter();
  const { user } = useAccount();

  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const colorToUse = determineColorToUse(activeContribution);

  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const StyledButton = styled(Button)`
    color: ${textColor};
    background-color: ${colorToUse?.PrimaryColorCode};
    &:hover {
      background-color: ${colorToUse?.PrimaryColorCode};
    }
  `;

  const { id: userId } = useSelector(state => state?.account?.user);

  useEffect(() => {
    setAnswers(applicationFormViewModel?.questions || []);
  }, [applicationFormViewModel?.questions]);

  const navigateToContribution = () => {
    redirectTo(`/apply-contribution-view/${contributionId}${query?.code ? `/${query.code}` : ''}/about`);
  };

  const handleSetQuestionNumber = newQuestionNumber => {
    setSubmitting(true);
    const isSubmitCall = newQuestionNumber > answers?.length;
    const data = {
      ...applicationFormViewModel,
      clientId: userId,
      redirectUrl: addhttp(
        domain.concat(`/apply-contribution-view/${contributionId}${query?.code ? `/${query.code}` : ''}/about`),
      ),
      status: isSubmitCall ? 'Pending' : 'Draft',
      questions: answers,
    };
    submitApplicationResponse(data)
      .then(() => {
        if (isSubmitCall) {
          setShowSumitSuccessModal(true);
        }
        if (newQuestionNumber > 0 && newQuestionNumber <= answers?.length) {
          setQuestionNumber(newQuestionNumber);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleSetAnswer = (value, type) => {
    const newAnswers = [...answers];
    let newAnswer = [...answers][questionNumber - 1]?.userResponse || [];
    if (type === QUESTION_TYPES.Checkbox) {
      newAnswer = newAnswer.includes(value) ? newAnswer?.filter(e => e !== value) : [...newAnswer, value];
    } else {
      newAnswer = [value];
    }
    newAnswers[questionNumber - 1] = {
      ...newAnswers[questionNumber - 1],
      userResponse: newAnswer,
    };
    setAnswers(newAnswers || []);
  };

  const getAnswerInput = () => {
    switch (answers[questionNumber - 1]?.questionType) {
      case QUESTION_TYPES.Radio:
        return (
          <>
            <RadioGroup
              value={
                answers[questionNumber - 1]?.userResponse?.length ? answers[questionNumber - 1]?.userResponse[0] : ''
              }
              onChange={e => handleSetAnswer(e.target.value, QUESTION_TYPES.Radio)}
              className="answer"
            >
              {answers[questionNumber - 1]?.options.map(option => {
                return <FormControlLabel value={option} control={<Radio color="primary" />} label={option} />;
              })}
            </RadioGroup>
          </>
        );
      case QUESTION_TYPES.Text:
        return (
          <>
            <TextField
              value={
                answers[questionNumber - 1]?.userResponse?.length ? answers[questionNumber - 1]?.userResponse[0] : ''
              }
              onChange={e => handleSetAnswer(e.target.value, QUESTION_TYPES.Text)}
              className="answer"
              variant="outlined"
              fullWidth
              placeholder="Answer"
              multiline
            />
          </>
        );
      case QUESTION_TYPES.Checkbox:
        return (
          <div style={{ marginBottom: '20px' }}>
            {answers[questionNumber - 1]?.options?.map((option, index) => {
              return (
                <div style={{ marginTop: index > 0 ? '20px' : '10px' }}>
                  <FormControlLabel
                    label={option}
                    className="answer mb-0"
                    control={
                      <Checkbox
                        color="primary"
                        checked={answers[questionNumber - 1]?.userResponse?.includes(option)}
                        onChange={e => handleSetAnswer(option, QUESTION_TYPES.Checkbox)}
                      />
                    }
                  />
                </div>
              );
            })}
          </div>
        );
      default:
        return (
          <>
            <TextField variant="outlined" fullWidth />
          </>
        );
    }
  };

  return (
    <>
      {questionNumber > 0 && (
        <div className="application-form">
          {questionNumber <= answers?.length && (
            <div style={{ display: 'flex' }}>
              <p className="question-statement">
                {`${questionNumber}.  ${answers[questionNumber - 1]?.name}`}
                {answers[questionNumber - 1]?.isOptional === false && (
                  <span style={{ marginLeft: '10px', color: 'red', marginRight: 'auto' }}>*</span>
                )}
              </p>
              {answers[questionNumber - 1]?.isOptional ? (
                <p style={{ marginLeft: '10px', color: 'grey', marginRight: 'auto' }}>(optional)</p>
              ) : (
                <></>
              )}
              <p className="question-number">{`${questionNumber}/${answers?.length}`}</p>
            </div>
          )}
          {getAnswerInput()}
          <StyledButton
            className="form-next-button"
            disabled={submitting}
            onClick={() => {
              const hasNonEmptyString = answers[questionNumber - 1]?.userResponse?.some(str => str !== '');
              if (hasNonEmptyString || answers[questionNumber - 1]?.isOptional) {
                handleSetQuestionNumber(questionNumber + 1);
              }
            }}
          >
            {questionNumber === answers?.length ? 'Submit' : 'Next'}
          </StyledButton>
          {questionNumber > 1 && (
            <StyledButton
              className="form-back-button"
              disabled={submitting}
              onClick={() => handleSetQuestionNumber(questionNumber - 1)}
            >
              Back
            </StyledButton>
          )}
        </div>
      )}
      <Modal
        title="Congrats! Your application has been submitted."
        isOpen={showSubmitSuccessModal}
        hiddenCancel
        applyTheming={isDarkThemeEnabled}
        onSubmit={navigateToContribution}
        onCancel={navigateToContribution}
      >
        {`Your application has been submitted and will be reviewed by ${coachName}! After it has been reviewed, you will receive an email to ${user?.email} with the next steps!`}
      </Modal>
    </>
  );
};

ApplicationForm.propTypes = {
  contributionId: PropTypes.string.isRequired,
  applicationFormViewModel: PropTypes.shape({
    clientId: PropTypes.string,
    formLinkId: PropTypes.string,
    status: PropTypes.string,
    userId: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        questionType: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }).isRequired,
  questionNumber: PropTypes.number.isRequired,
  setQuestionNumber: PropTypes.func.isRequired,
  coachName: PropTypes.string.isRequired,
  contributionTitle: PropTypes.string.isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
};

export default ApplicationForm;
