import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

import { useHttp, useShallowEqualSelector } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { setUserNotes, clearUserNotes } from 'actions/userNotes';
import Loader from 'components/UI/Loader';
import NoteModal from 'pages/ContributionView/components/NoteModal/NoteModalContainer';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

import './ContributionNotes.scss';

const BoldTableCell = withStyles({
  root: {
    fontSize: ({ mobileView }) => (mobileView ? '16px' : '17.8px'),
    fontWeight: 500,
    letterSpacing: '0.15px',
  },
})(TableCell);

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 22,
    paddingBottom: 22,
  },
})(TableCell);

const ContributionNotes = ({ match: { params } }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [stateIds, setStateIds] = useState({});

  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);

  const dispatch = useDispatch();
  const notes = useShallowEqualSelector(state => state?.userNotes?.notes);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { request, loading } = useHttp();

  useEffect(() => {
    request(`/api/Note/${params.id}`).then(R.compose(dispatch, setUserNotes)).catch(console.dir);

    return R.compose(dispatch, clearUserNotes);
  }, [dispatch, request, params.id]);

  if (loading) {
    return <Loader />;
  }

  if (!notes || !notes.length) {
    return <div>There are no notes yet.</div>;
  }

  const handleOpenModal = (title, contributionId, isPrerecorded, classId, subClassId) => {
    setStateIds({ title, contributionId, isPrerecorded, classId, subClassId });
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
    setStateIds({});
  };

  const handleConfirm = () => {
    handleCloseModal();

    request(`api/Note/${params.id}`).then(R.compose(dispatch, setUserNotes)).catch(console.dir);
  };

  return (
    <>
      <TableContainer style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }} component={Paper}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell style={{ color: themedColor }} mobileView={mobileView}>
                Personal Notes
              </BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notes.map(({ id, title, contributionId, isPrerecorded, classId, subClassId }) => (
              <TableRow key={id} className="row">
                <NarrowTableCell style={{ color: themedColor }} component="th" scope="row">
                  <span
                    role="button"
                    tabIndex="0"
                    className="title"
                    onClick={() => handleOpenModal(title, contributionId, isPrerecorded, classId, subClassId)}
                    onKeyPress={() => handleOpenModal(title, contributionId, isPrerecorded, classId, subClassId)}
                  >
                    {isPrerecorded == true ? `${title} - Self Paced` : `${title} - Live Session`}
                  </span>
                </NarrowTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modalOpened && (
        <NoteModal
          noteProps={stateIds}
          isOpen={modalOpened}
          onModalClose={handleCloseModal}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

ContributionNotes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

export default ContributionNotes;
