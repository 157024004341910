import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const getOnToOnePaymentInfo = (id, paymentOption, priceId, couponId) =>
  axiosInstance
    .post(
      `/api/purchase/one-to-one/${id}${priceId ? `/${priceId}` : ''}/${paymentOption}${couponId ? `/${couponId}` : ''}`,
    )
    .then(get('data'));
const getCoursePaymentInfo = (id, paymentOption, priceId, couponId) =>
  axiosInstance
    .post(`/api/purchase/course/${id}/${paymentOption}${priceId ? `/${priceId}` : ''}${couponId ? `/${couponId}` : ''}`)
    .then(get('data'));

const getMembershipPaymentInfo = (id, paymentOption, couponId) =>
  axiosInstance
    .post(`/api/purchase/membership/${id}/${paymentOption}${couponId ? `/${couponId}` : ''}`)
    .then(get('data'));

const getCommunityPaymentInfo = (id, paymentOption, couponId) =>
  axiosInstance
    .post(`/api/purchase/community/${id}/${paymentOption}${couponId ? `/${couponId}` : ''}`)
    .then(get('data'));

const purchaseMembershipSubscription = data => axiosInstance.post(`/api/purchase/membership`, data).then(get('data'));

const purchaseCommunitySubscription = data => axiosInstance.post(`/api/purchase/community`, data).then(get('data'));

const cancelMembership = id => axiosInstance.post(`/api/purchase/cancel/membership/${id}`).then(get('data'));

const upgradeMembership = data => axiosInstance.post(`/api/purchase/upgrade/membership`, data).then(get('data'));

const getCoaches = () => axiosInstance.get(`/api/purchase/ListMyCoaches`).then(get('data'));

export {
  getOnToOnePaymentInfo,
  getCoursePaymentInfo,
  getMembershipPaymentInfo,
  getCommunityPaymentInfo,
  purchaseMembershipSubscription,
  purchaseCommunitySubscription,
  getCoaches,
  cancelMembership,
  upgradeMembership,
};
