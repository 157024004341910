import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { useAccount } from 'hooks';
import getSymbolFromCurrency from 'currency-symbol-map';

import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { formatMoney } from 'utils/datesAndMoney';
import { colors } from 'utils/styles';
import {
  toggleAffiliateEnrollment,
  getEnrollmentStatus,
  getAffiliateCoach,
  downloadEnrollmentdetails,
} from 'services/affiliate.service';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from '../../../../../components/FormUI/Button';
import InvitationCoaches from '../Invitation/InvitationCoaches';
import { CircularProgress } from '@material-ui/core';
import './AffiliateSummaryCard.scss';
import { redirectTo } from 'services/links';
import Modal from 'components/UI/Modal';
import { downloadFile } from 'utils/utils';

const StyledAffiliateDescription = styled.div`
  text-align: center;
`;

const StyledEnrollmentButton = styled(Button)`
  min-width: 100%;
  margin-bottom: 30px;
`;

const StyledGetPaidButton = styled(Button)`
  margin-top: 30px;
  min-width: 100%;
`;

const StyledButtonsGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const StyledLink = styled.a`
  color: ${colors.darkOceanBlue};
`;

const AffiliateSummaryCard = ({ incomes, onGetPaidClick }) => {
  const { user } = useAccount();
  const [isEnrolled, setIsEnrolled] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [stripeUrl, setStripeUrl] = useState(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    getEnrollmentStatus()
      .then(enrollmentStatus => setIsEnrolled(enrollmentStatus))
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, [setIsEnrolled]);

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleCloseModalNow = () => {
    setModalVisible(false);
    redirectTo(stripeUrl);
  };

  const handlerEnrollClick = useCallback(() => {
    toggleAffiliateEnrollment().then(() => {
      if (user.isAffiliateCoachEnable === false) {
        getAffiliateCoach().then(res => {
          setModalVisible(true);
          setStripeUrl(res);
        });
      }
      setIsEnrolled(prevIsEnrolled => !prevIsEnrolled);
    });
  }, [setIsEnrolled]);

  const handlerDownloadCsv = useCallback(() => {
    setLoader(true);
    downloadEnrollmentdetails()
      .then(res => {
        downloadFile(res, 'AffiliateTracking.csv');
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  // const displayGetPaid = user.payoutsEnabled && isEnrolled;
  const displayGetPaid = false;

  const titleText =
    isEnrolled && user?.isAffiliateCoachEnable
      ? `You are ${isEnrolled && user?.isAffiliateCoachEnable ? '' : 'un'}enrolled in the Cohere Affiliate program.`
      : `Enroll into the Cohere Affiliate program to earn Revenue`;
  const referredCoaches = `${incomes?.referralsCount === undefined ? 0 : incomes?.referralsCount} Coach${
    incomes?.referralsCount === 1 ? '' : 'es'
  } Referred`;
  const referredCoachesWithSales = `${
    incomes?.referralsWithSalesCount === undefined ? 0 : incomes?.referralsWithSalesCount
  } Coach${incomes?.referralsWithSalesCount === 1 ? '' : 'es'} with Sales`;
  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      <Card maxHeight>
        <CardHeader>
          <Grid container item md={12} xs={12}>
            <Grid container item md={9} xs={12}>
              <PageTitleSecond>{titleText}</PageTitleSecond>
            </Grid>
            <Grid container item md={3} xs={12} justify="center" alignContent="center">
              <InvitationCoaches disableInvite={isEnrolled && user?.isAffiliateCoachEnable} />
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody className="card-body-container">
          <Grid container item md={12} xs={12}>
            <Grid container item md={9} xs={12}>
              <Button style={{ marginBottom: '20px' }} onClick={handlerDownloadCsv}>
                {loader ? <CircularProgress size={'18px'} /> : 'Download Affiliate Tracking CSV'}
              </Button>
              {
                <>
                  <Grid container item md={12} xs={12}>
                    <div>{referredCoaches}</div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>{referredCoachesWithSales}</div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>
                      {getSymbolFromCurrency(incomes?.availableToPayoutCurrency)}
                      {formatMoney(incomes?.totalRevenue)} {incomes?.availableToPayoutCurrency.toUpperCase()} Affiliate
                      Revenue Earned
                    </div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>
                      {getSymbolFromCurrency(incomes?.paidOutCurrency)}
                      {formatMoney(incomes?.paidOutRevenue)} {incomes?.paidOutCurrency.toUpperCase()} Cash Paid Out
                    </div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>
                      {getSymbolFromCurrency(incomes?.availableToPayoutCurrency)}
                      {formatMoney(incomes?.inEscrowRevenue)} {incomes?.availableToPayoutCurrency.toUpperCase()}{' '}
                      Available in 60 Days or Less
                    </div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>
                      {getSymbolFromCurrency(incomes?.paidOutCurrency)}
                      {formatMoney(incomes?.revenueAvailableSoon)} {incomes?.paidOutCurrency.toUpperCase()} Transferred
                      and will be available for payout on stripe soon
                    </div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>
                      {getSymbolFromCurrency(incomes?.paidOutCurrency)}
                      {formatMoney(incomes?.availableToPayoutRevenue)} {incomes?.paidOutCurrency.toUpperCase()} Cash
                      Available
                    </div>
                  </Grid>
                </>
              }
            </Grid>
            <StyledButtonsGrid container item md={3} xs={12}>
              <Grid container item md={12} xs={12} justify="center" alignContent="center">
                <StyledEnrollmentButton onClick={handlerEnrollClick}>
                  {loader ? (
                    <CircularProgress size={'18px'} />
                  ) : isEnrolled && user?.isAffiliateCoachEnable ? (
                    'Unenroll'
                  ) : (
                    'Enroll'
                  )}
                </StyledEnrollmentButton>
              </Grid>
              {/* <Grid container item md={12} xs={12} justify="center" alignContent="center">
                <StyledAffiliateDescription>Earn up to $500 Per Coach Referral.</StyledAffiliateDescription>
              </Grid> */}
              <Grid container item md={12} xs={12} justify="center" alignContent="center">
                <StyledAffiliateDescription>Terms: 30% Revenue Share</StyledAffiliateDescription>
              </Grid>
              <Grid container item md={12} xs={12} justify="center" alignContent="center">
                <StyledLink
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    setShowTerms(true);
                  }}
                >
                  Terms and Conditions
                </StyledLink>
              </Grid>
              {displayGetPaid && (
                <Grid container item md={12} xs={12} justify="center" alignContent="center">
                  <StyledGetPaidButton onClick={onGetPaidClick}>Get Paid</StyledGetPaidButton>
                </Grid>
              )}
            </StyledButtonsGrid>
          </Grid>
        </CardBody>
      </Card>
      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
      <Modal
        isOpen={modalVisible}
        onSubmit={handleCloseModalNow}
        title={''}
        submitTitle={'Verify Now'}
        cancelTitle={'Later'}
        onCancel={handleCloseModal}
      >
        <p>
          Cohere has partnered with Stripe to support automated affiliate revenue collection and payouts. This next step
          will take just a couple minutes.
        </p>
      </Modal>
    </Grid>
  );
};

AffiliateSummaryCard.propTypes = {
  incomes: PropTypes.shape({
    referralsCount: PropTypes.number,
    referralsWithSalesCount: PropTypes.number,
    availableToPayoutRevenue: PropTypes.number,
    totalRevenue: PropTypes.number,
    inEscrowRevenue: PropTypes.number,
    paidOutRevenue: PropTypes.number,
  }),
  onGetPaidClick: PropTypes.func,
};

export default AffiliateSummaryCard;
