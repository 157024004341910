import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import 'react-color-palette/lib/css/styles.css';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors, determineColorToUse, determineDarkThemedColorToUse } from 'services/contributions.service';
import { getStyledButton, getStyledSecondaryButton } from 'utils/utils';
import { SlimSwitch, StyledInput, SlimStyledSlider } from 'components/switch/style';
import SvgHandler from './SvgHandler';
import { useStyles } from './useStyles';
import { getContributionTextColorCode } from './AddQuestion';

const SAVING_QUESTION_STEPS = ['EDIT_MODE', 'SAVING_OPTIONS', 'VALIDATION_QUESTION', 'QUESTION_SAVED'];

const questionTypes = {
  single_choice: 'Radio',
  multiple_choice: 'CheckBox',
  open_text: 'Text',
};
const questionTypesUIValues = {
  Radio: 'Single Choice',
  CheckBox: 'Multiple Choice',
  Text: 'Written Answer',
};
function QuestionModal({
  open,
  handleClose,
  handleOpen,
  questions,
  setQuestions,
  editableQuestion = { name: '', questionType: questionTypes.single_choice, options: [], isOptional: false },
  style = {},
  contributionViewMode,
  draftPlaceholder,
  editError,
  draftQuestionRef,
}) {
  const [userInputField, setUserInputField] = useState(false);
  const [draftQuestion, setDraftQuestion] = useState(editableQuestion);
  const defaultErrorState = { name: false, questionType: false, options: false };
  const [error, setError] = useState(defaultErrorState);
  const [currentOption, setCurrentOption] = useState('');
  const [savingQuestionStep, setSavingQuestionStep] = useState(SAVING_QUESTION_STEPS[0]);
  const [openNew, setOpenNew] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const setDefaultQuestionType = () => {
    if (draftQuestion.questionType === '') {
      setDraftQuestion({ questionType: questionTypes.single_choice });
    }
  };
  useEffect(() => {
    if (draftQuestion.name === '') {
      setUserInputField(true);
    }
  }, []);
  setDefaultQuestionType();
  const contribution = useContribution();
  const { themedColor, themedBackgroundColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const colorToUse = determineColorToUse(contribution?.activeContribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const styledProps = {
    backgroundColor: themedCardBackgroundColor,
    color: themedColor,
  };
  const classes = useStyles(styledProps);
  const contributionTextColorCode = getContributionTextColorCode(contribution, colorToUse);
  const StyledButton = getStyledButton(contribution, contributionTextColorCode);
  const StyledSecondaryButton = getStyledSecondaryButton();

  const setErrorHandler = (key, value) => {
    setError(prevState => {
      const updatedObject = { ...prevState };
      updatedObject[key] = value;
      return updatedObject;
    });
  };

  const setQuestionHandler = (key, value, mode = '') => {
    setDraftQuestion(prevState => {
      const updatedObject = { ...prevState };
      if (key === 'options') {
        switch (mode) {
          case 'append':
            updatedObject[key] = [...updatedObject.options, value];
            break;
          default:
            updatedObject[key] = [...value];
        }
      } else {
        updatedObject[key] = value;
      }
      return updatedObject;
    });
  };
  const validator = question => {
    let isValidated = true;
    if (!question.name) {
      setErrorHandler('name', true);
      isValidated = false;
    }
    if (!question.questionType) {
      setErrorHandler('questionType', true);
      isValidated = false;
    }
    if ((question.questionType === 'Radio' || question.questionType === 'CheckBox') && question.options.length < 1) {
      setErrorHandler('options', 'Please add atleast 1 option');
      isValidated = false;
    }
    return isValidated;
  };
  const handleTypeChange = e => {
    setDraftQuestion({
      ...draftQuestion,
      options: [],
    });
    setUserInputField(true);
    setQuestionHandler('questionType', questionTypes[e.target.value]);
    setErrorHandler('questionType', false);
    setErrorHandler('options', false);
    setCurrentOption('');
  };

  const handleModalClose = () => {
    handleClose();
    setDraftQuestion({ name: '', questionType: '', options: [], isOptional: false });
    setError(defaultErrorState);
    setUserInputField(false);
  };

  const saveOption = () => {
    if (draftQuestion?.options.find(option => option === currentOption)) {
      setErrorHandler('options', "2 options can't have same value");
      return;
    }
    if (currentOption !== '') {
      setQuestionHandler('options', currentOption, 'append');
      setUserInputField(false);
      setCurrentOption('');
      setErrorHandler('options', false);
    }
  };

  const handleAddOption = () => {
    saveOption();
    setUserInputField(true);
  };

  const handleSaveOption = e => {
    if (e.key === 'Enter') {
      saveOption();
    }
  };

  const removeOptionHandler = deletedOption => {
    const updatedOptions = draftQuestion.options
      .filter(option => {
        return option !== deletedOption;
      })
      .map(filteredOptions => {
        return filteredOptions;
      });
    setQuestionHandler('options', updatedOptions);
  };
  const handleSaveQuestionStatement = async e => {
    e.persist();
    setQuestionHandler('name', e.target.value);
    setErrorHandler('name', false);
  };
  const handleSaveQuestion = async () => {
    setSavingQuestionStep(SAVING_QUESTION_STEPS[1]);
  };
  const handleAddAnotherQuestion = async e => {
    if (open) {
      if (draftQuestion.type !== questionTypes.open_text) {
        setOpenNew(true);
      }
      await handleSaveQuestion();
    }
  };
  const SaveDraftQuestion = () => {
    const isValidated = validator(draftQuestion);
    if (!isValidated) {
      setOpenNew(false);
      return;
    }
    if (editableQuestion.localId) {
      // edit question case
      const updatedQuestions = questions.map(question => {
        if (question.localId === editableQuestion.localId) {
          return { ...draftQuestion, localId: editableQuestion.localId, id: null };
        }
        return question;
      });
      setQuestions([...updatedQuestions]);
    } else {
      setQuestions(allQuestions => {
        return [
          ...allQuestions,
          {
            localId: Math.floor(Math.random() * 10000) + 1,
            id: null,
            name: draftQuestion.name,
            questionType: draftQuestion.questionType,
            options: draftQuestion.options || [],
            userResponse: [],
            isOptional: draftQuestion.isOptional,
          },
        ];
      });
    }
    setSavingQuestionStep(SAVING_QUESTION_STEPS[3]);
    handleModalClose();
    if (openNew) {
      handleOpen();
      setOpenNew(false);
    }
  };

  const setIsOptionQuestion = isOptional => {
    setDraftQuestion({
      ...draftQuestion,
      isOptional,
    });
  };

  useEffect(() => {
    if (!editableQuestion.localId) {
      setDraftQuestion(prevState => {
        const updatedObject = {
          name: prevState.name || '',
          questionType: prevState.questionType || questionTypes.single_choice,
          id: prevState.id || null,
          options: prevState.options || [],
          isOptional: prevState.isOptional,
        };
        return updatedObject;
      });
    } else {
      // edit question case
      let optionsArray = editableQuestion.options || [];
      if (draftQuestion.questionType === 'Text') optionsArray = [];
      const newObj = {
        name: editableQuestion.name || '',
        questionType: draftQuestion.questionType || editableQuestion.questionType,
        id: editableQuestion.id || null,
        options: optionsArray,
        isOptional: editableQuestion.isOptional,
      };
      setDraftQuestion(newObj);
    }
  }, [open, draftQuestion.questionType, editableQuestion]);

  useEffect(() => {
    if (savingQuestionStep === SAVING_QUESTION_STEPS[1]) {
      handleAddOption();
      setSavingQuestionStep(SAVING_QUESTION_STEPS[2]);
    } else if (savingQuestionStep === SAVING_QUESTION_STEPS[2]) {
      SaveDraftQuestion();
    }
  }, [savingQuestionStep]);

  return (
    <div
      style={{
        ...style,
        backgroundColor: isDarkThemeEnabled && contributionViewMode ? '#252728' : '#F5F5F5',
        padding: '8px',
        borderRadius: '4px',
        gap: '20px',
      }}
      className="d-flex flex-column draft-question"
      ref={draftQuestionRef}
    >
      {editableQuestion /*&& !editableQuestion.name*/ && (
        <div style={{ display: 'flex' }}>
          <p
            style={{
              color: themedColor,
              display: 'inline-block',
              fontWeight: 'bold',
              fontSize: '20px',
              margin: '0 auto auto 0',
            }}
            id="modal-modal-title"
          >
            Draft Question
            {editError && (
              <p
                style={{ color: 'red', fontSize: '1rem', fontWeight: '400', marginBottom: '0' }}
                className="error-text"
              >
                {editError}
              </p>
            )}
          </p>
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div>
              <p style={{ marginTop: '0px', marginBottom: '5px', marginRight: '5px' }}>Optional</p>
            </div>
            <SlimSwitch className="switch">
              <StyledInput
                type="checkbox"
                onClick={() => setIsOptionQuestion(!draftQuestion.isOptional)}
                checked={draftQuestion.isOptional}
              />
              <SlimStyledSlider className="slider round" />
            </SlimSwitch>
          </div>
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <TextField
            inputProps={{
              style: {
                fontFamily: 'Avenir',
                background: '#F5F5F5',
                borderRadius: '5px',
                border: '1px solid #7D9CB8',
                paddingLeft: '10px',
              },
            }}
            autoFocus
            placeholder={draftPlaceholder ? 'Which is your best social media site' : 'Question'}
            value={draftQuestion.name}
            onChange={handleSaveQuestionStatement}
            className={`mt-2 mb-2 ${classes.Input}`}
            fullWidth
            id="margin-none"
            error={error.name}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            displayEmpty
            style={{
              color: themedColor,
              fontFamily: 'Avenir',
              background: isDarkThemeEnabled && contributionViewMode ? themedBackgroundColor : '#F5F5F5',
              borderColor: isDarkThemeEnabled && contributionViewMode ? themedBackgroundColor : 'inherit',
              borderRadius: '5px',
              border: '1px solid #B2CAE0',
            }}
            value={draftQuestion.questionType}
            renderValue={() =>
              draftQuestion.questionType
                ? questionTypesUIValues[draftQuestion.questionType]
                : questionTypesUIValues[questionTypes.single_choice]
            }
            margin="dense"
            className={`mt-2 mb-2 ${classes.Select}`}
            MenuProps={{
              PaperProps: {
                style: { fontFamily: 'Avenir' },
                sx: {
                  color: themedColor,
                  bgcolor: themedCardBackgroundColor,
                },
              },
            }}
            fullWidth
            error={error.questionType}
            onChange={handleTypeChange}
          >
            {Object.entries(questionTypes).map(key => {
              return (
                <MenuItem style={{ display: 'flex', padding: '6px 16px', justifyContent: 'left' }} value={key[0]}>
                  {questionTypesUIValues[key[1]]}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
      {draftQuestion.questionType === 'Text' && !userInputField && <div className="text-field small">Answer...</div>}
      {draftQuestion.options &&
        draftQuestion.options.length > 0 &&
        draftQuestion.options.map(option => {
          return (
            <>
              <Grid container>
                <Grid item xl={9} lg={9} md={9} sm={12} xs={12} className="options-container">
                  {draftQuestion.questionType === 'CheckBox' && (
                    <SvgHandler
                      style={{ margin: 'auto 10px auto 0', minWidth: '24px', minHeight: '24px' }}
                      fill={themedColor}
                      height="24px"
                      width="24px"
                      viewBox="0 0 24 24"
                      pathD="M5.75,3 L18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 Z M5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 L5.75,4.5 Z"
                    />
                  )}
                  {draftQuestion.questionType === 'Radio' && (
                    <SvgHandler
                      style={{ margin: 'auto 10px auto 0', minWidth: '24px', minHeight: '24px' }}
                      fill={themedColor}
                      height="24px"
                      width="24px"
                      viewBox="0 0 24 24"
                      pathD="M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.387 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12 4.5C10.5166 4.5 9.0666 4.93987 7.83323 5.76398C6.59986 6.58809 5.63856 7.75943 5.07091 9.12988C4.50325 10.5003 4.35473 12.0083 4.64411 13.4632C4.9335 14.918 5.64781 16.2544 6.6967 17.3033C7.7456 18.3522 9.08197 19.0665 10.5368 19.3559C11.9917 19.6453 13.4997 19.4968 14.8701 18.9291C16.2406 18.3614 17.4119 17.4001 18.236 16.1668C19.0601 14.9334 19.5 13.4834 19.5 12C19.5 10.0109 18.7098 8.10323 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5Z"
                    />
                  )}
                  <div
                    style={{
                      border: '1px solid #B2CAE0',
                      borderRadius: '4px',
                      marginRight: '8px',
                      width: '100%',
                      padding: '10px',
                    }}
                  >
                    <p className="options" style={{ margin: 'auto 0' }}>
                      {option}
                    </p>
                  </div>
                  <SvgHandler
                    style={{ margin: 'auto 0 auto 10px' }}
                    fill={themedColor}
                    height="22px"
                    width="22px"
                    className="cursor-pointer"
                    onClick={() => removeOptionHandler(option)}
                    viewBox="0 0 1024 1024"
                    pathD="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z"
                  />
                </Grid>
              </Grid>
            </>
          );
        })}
      {userInputField && (
        <>
          <Grid container>
            <Grid item xl={9} lg={9} md={9} sm={12} xs={12} className="options-container">
              {draftQuestion.questionType === 'Radio' && (
                <SvgHandler
                  style={{ margin: 'auto 10px auto 0', minWidth: '24px', minHeight: '24px' }}
                  fill={themedColor}
                  height="24px"
                  width="24px"
                  viewBox="0 0 24 24"
                  className="top-15"
                  pathD="M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.387 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12 4.5C10.5166 4.5 9.0666 4.93987 7.83323 5.76398C6.59986 6.58809 5.63856 7.75943 5.07091 9.12988C4.50325 10.5003 4.35473 12.0083 4.64411 13.4632C4.9335 14.918 5.64781 16.2544 6.6967 17.3033C7.7456 18.3522 9.08197 19.0665 10.5368 19.3559C11.9917 19.6453 13.4997 19.4968 14.8701 18.9291C16.2406 18.3614 17.4119 17.4001 18.236 16.1668C19.0601 14.9334 19.5 13.4834 19.5 12C19.5 10.0109 18.7098 8.10323 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5Z"
                />
              )}
              {draftQuestion.questionType === 'CheckBox' && (
                <SvgHandler
                  style={{ margin: 'auto 10px auto 0', minWidth: '24px', minHeight: '24px' }}
                  fill={themedColor}
                  height="24px"
                  width="24px"
                  viewBox="0 0 24 24"
                  className="top-15"
                  pathD="M5.75,3 L18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 Z M5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 L5.75,4.5 Z"
                />
              )}
              {(draftQuestion.questionType === 'CheckBox' || draftQuestion.questionType === 'Radio') && (
                <TextField
                  inputProps={{
                    style: {
                      fontFamily: 'Avenir',
                      background: '#F5F5F5',
                      borderRadius: '5px',
                      border: '1px solid #7D9CB8',
                      paddingLeft: '10px',
                    },
                  }}
                  className={`${classes.Input}`}
                  placeholder="Add an option.."
                  value={currentOption}
                  size="small"
                  onChange={e => setCurrentOption(e.target.value)}
                  onKeyPress={handleSaveOption}
                  fullWidth
                />
              )}
              {(draftQuestion.questionType === 'CheckBox' || draftQuestion.questionType === 'Radio') && (
                <SvgHandler
                  viewBox="0 0 1024 1024"
                  style={{ margin: 'auto 0 auto 10px' }}
                  fill={themedColor}
                  height="22px"
                  width="22px"
                  className="top-15 cursor-pointer"
                  onClick={() => {
                    setUserInputField(false);
                    setCurrentOption('');
                  }}
                  pathD="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z"
                />
              )}
              {draftQuestion.questionType === 'Text' && <div className="text-field small">Answer...</div>}
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          {(draftQuestion.questionType === 'Radio' || draftQuestion.questionType === 'CheckBox') && (
            <>
              <StyledButton
                type="button"
                style={
                  isDarkThemeEnabled
                    ? { backgroundColor: 'rgb(45, 47, 49)', borderColor: '#D7D7D7', color: 'white' }
                    : { backgroundColor: '#D7D7D7', borderColor: '#D7D7D7', color: 'black' }
                }
                className="btn btn-secondary float-left"
                onClick={handleAddOption}
              >
                {/* <svg
                  className="bottom-2 mr-1"
                  style={{ marginBottom: '4px' }}
                  height="15px"
                  width="15px"
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect fill="none" height="256" width="256" />
                  <line
                    fill="none"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                    x1="40"
                    x2="216"
                    y1="128"
                    y2="128"
                  />
                  <line
                    fill="none"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="12"
                    x1="128"
                    x2="128"
                    y1="40"
                    y2="216"
                  />
                </svg> */}
                + Add an option
              </StyledButton>
            </>
          )}
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'flex-end', gap: '10px' }}
        >
          <StyledSecondaryButton
            style={
              isDarkThemeEnabled
                ? { backgroundColor: 'rgb(45, 47, 49)', borderColor: '#D7D7D7', color: 'white' }
                : { backgroundColor: '#D7D7D7', borderColor: '#D7D7D7', color: 'black' }
            }
            type="button"
            className="btn question-delete-button"
            onClick={() => handleClose('delete')}
          >
            Delete
          </StyledSecondaryButton>
          <StyledButton type="button" className="btn question-add-button" onClick={handleSaveQuestion}>
            Add
          </StyledButton>
        </Grid>
      </Grid>
      {error.options && (
        <p style={{ paddingLeft: '8px', color: 'red' }} className="error-text float-right ">
          {error.options}
        </p>
      )}
    </div>
  );
}
QuestionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setQuestions: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  editableQuestion: PropTypes.object,
  style: PropTypes.shape({}).isRequired,
  contributionViewMode: PropTypes.bool.isRequired,
};

QuestionModal.defaultProps = {
  editableQuestion: {
    name: '',
    questionType: questionTypes.single_choice,
    options: [],
    id: null,
    localId: null,
    isOptional: false,
    style: {},
  },
};
export default QuestionModal;
