import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import SvgHandler from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/SvgHandler';
import PropTypes from 'prop-types';
import { propTypes } from 'react-input-emoji';

const ApplicationQuestionsResponse = ({
  index,
  questionType,
  questionStatement,
  userResponse,
  isOptional,
  options = [],
}) => {
  const selectedRadioButtonSVGpath =
    'M12.0000002,1.99896738 C17.523704,1.99896738 22.0015507,6.47681407 22.0015507,12.0005179 C22.0015507,17.5242217 17.523704,22.0020684 12.0000002,22.0020684 C6.47629639,22.0020684 1.99844971,17.5242217 1.99844971,12.0005179 C1.99844971,6.47681407 6.47629639,1.99896738 12.0000002,1.99896738 Z M12.0000002,3.49896738 C7.30472352,3.49896738 3.49844971,7.30524119 3.49844971,12.0005179 C3.49844971,16.6957946 7.30472352,20.5020684 12.0000002,20.5020684 C16.6952769,20.5020684 20.5015507,16.6957946 20.5015507,12.0005179 C20.5015507,7.30524119 16.6952769,3.49896738 12.0000002,3.49896738 Z M11.9965637,5.99896738 C15.3092306,5.99896738 17.9946777,8.68441449 17.9946777,11.9970814 C17.9946777,15.3097483 15.3092306,17.9951954 11.9965637,17.9951954 C8.68389682,17.9951954 5.99844971,15.3097483 5.99844971,11.9970814 C5.99844971,8.68441449 8.68389682,5.99896738 11.9965637,5.99896738 Z';
  const selectedCheckBoxSVGpath =
    'M810.666 128H213.334C166.396 128 128 166.396 128 213.334v597.332C128 857.604 166.396 896 213.334 896h597.332C857.604 896 896 857.604 896 810.666V213.334C896 166.396 857.604 128 810.666 128z m-384 597.334L213.334 512l59.728-59.728 153.604 153.604 324.272-324.272 59.728 59.73-384 384z';

  return (
    <Fragment key={index}>
      <div className="row m-0" style={{ paddingTop: '12px' }}>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <span style={{ display: 'flex', minHeight: '18px', marginBottom: '10px' }}>
            <Typography
              style={{
                color: '#215c73',
                display: 'inline-block',
                overflowWrap: 'break-word',
                fontWeight: 'bold',
              }}
              variant="h8"
              className="mb-1"
              component="div"
            >
              {index + 1}. {questionStatement}
            </Typography>

            {isOptional ? (
              <p style={{ marginLeft: '10px', color: 'grey' }}>(optional)</p>
            ) : (
              <p style={{ marginLeft: '10px', color: 'red' }}>*</p>
            )}
          </span>
        </Grid>
      </div>
      {questionType === 'Text' ? (
        <Grid item xl={12} lg={12} md={12} sm={11} xs={11}>
          <p fullWidth style={{ margin: '0px', marginBottom: '6px' }}>
            {' '}
            {userResponse}{' '}
          </p>
        </Grid>
      ) : (
        options.map(option => (
          <Grid key={Math.floor(Math.random() * 10000 + 1)} className="mb-0" container spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              {questionType === 'CheckBox' && (
                <SvgHandler
                  fill="black"
                  height="28px"
                  width="28px"
                  viewBox={userResponse?.includes(option) ? '0 0 1024 1024' : '0 0 24 24'}
                  className="top-15 cursor-pointer"
                  pathD={
                    userResponse?.includes(option)
                      ? selectedCheckBoxSVGpath
                      : 'M5.75,3 L18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 Z M5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 L5.75,4.5 Z'
                  }
                />
              )}
              {questionType === 'Radio' && (
                <SvgHandler
                  fill="black"
                  height="28px"
                  width="28px"
                  viewBox="0 0 24 24"
                  className="top-15 cursor-pointer"
                  pathD={
                    userResponse?.includes(option)
                      ? selectedRadioButtonSVGpath
                      : 'M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.387 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12 4.5C10.5166 4.5 9.0666 4.93987 7.83323 5.76398C6.59986 6.58809 5.63856 7.75943 5.07091 9.12988C4.50325 10.5003 4.35473 12.0083 4.64411 13.4632C4.9335 14.918 5.64781 16.2544 6.6967 17.3033C7.7456 18.3522 9.08197 19.0665 10.5368 19.3559C11.9917 19.6453 13.4997 19.4968 14.8701 18.9291C16.2406 18.3614 17.4119 17.4001 18.236 16.1668C19.0601 14.9334 19.5 13.4834 19.5 12C19.5 10.0109 18.7098 8.10323 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5Z'
                  }
                />
              )}
            </Grid>
            <Grid item xl={11} lg={11} md={10} sm={11} xs={11}>
              <p style={{ margin: '0px' }} className="options">
                {option}
              </p>
            </Grid>
          </Grid>
        ))
      )}
    </Fragment>
  );
};
ApplicationQuestionsResponse.propTypes = {
  index: PropTypes.number.isRequired,
  questionType: PropTypes.string.isRequired,
  questionStatement: PropTypes.string.isRequired,
  isOptional: PropTypes.bool.isRequired,
  userResponse: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
};

ApplicationQuestionsResponse.defaultProps = {
  options: [],
};

export default ApplicationQuestionsResponse;
