import { UserRoles } from 'helpers/constants';
import { isEmpty } from 'lodash';
import { FILTERTYPES } from 'pages/ContributionView/ContributionSessions/constants';
import { FilteredSessions } from 'pages/ContributionView/ContributionSessions/helpers';
import { createSelector } from 'reselect';
import { memberPodsSelector } from 'selectors/MemberPods';
// basic selectors
export const getActiveContribution = s => s.contributions.activeContribution;
// advanced / memoized
export const getActiveContribSessions = createSelector(
  [getActiveContribution],
  activeContribution => activeContribution?.sessions || [],
);
export const getActiveContribFilteredSessions = createSelector(
  [
    getActiveContribSessions,
    // memberPodsSelector,
    // s => getActiveContribution(s)?.isPurchased,
    // s => s?.account?.user?.id,
    s => s?.account?.currentRole === UserRoles.cohealer,
    (
      _,
      selectedFilter,
      //   // podsArray
    ) => ({
      selectedFilter,
      //  podsArray
    }),
  ],
  (
    sessions,
    // contributionMemberPods,
    // isPurchased,
    // userId,
    isCoach,
    {
      selectedFilter,
      //  podsArray
    },
  ) => {
    const dd = FilteredSessions(sessions, selectedFilter, isCoach);
    // ?.filter(k => {
    //   console.info('hi:5');
    //   return (
    //     isCoach ||
    //     k?.sessionTimes?.some(st => {
    //       const pod = podsArray.find(p => p.id === st.podId);
    //       return st?.podId === '' || st?.podId === null || isEmpty(pod) || pod?.clientIds?.includes(userId);
    //     })
    //   );
    // });
    return dd;
  },
);
export const getActiveContribSessionById = createSelector(
  [getActiveContribSessions, (_, sessionId) => sessionId],
  (sessions, sessionId) => sessions?.find(session => session.id === sessionId),
);
export const getActiveContribSessionTimesBySessionId = createSelector(
  [getActiveContribSessions, (_, sessionId) => sessionId],
  (sessions, sessionId) => sessions?.find(session => session.id === sessionId)?.sessionTimes,
);
export const getActiveContribSessionTimeById = createSelector(
  [getActiveContribSessionTimesBySessionId, (_, _2, sessionTimeId) => sessionTimeId],
  (times, sessionTimeId) => times?.find(time => time.id === sessionTimeId),
);
export const activeContribSessTabViewSettingsSelector = createSelector(
  [s => s.contributions?.sessionsTabViewScreenUI],
  sessionsTabViewScreenUI =>
    sessionsTabViewScreenUI || {
      loading: false,
      selectedSessionId: null,
      selectedSessionTimeOrContentId: null,
      isCenterColumnFullScreenMode: false,
      showMobileDetailedView: false,
    },
);
