import { useDeepEqualSelector, useShallowEqualSelector } from 'hooks';

const selector = state => state?.contributions?.activeContribution || {};

function useContribution() {
  // return useDeepEqualSelector(selector);
  return useShallowEqualSelector(selector);
}

export default useContribution;
