import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { addhttp, formatTime } from 'utils/utils';
import useRouter from 'hooks/useRouter';
import ApplicationQuestionsResponse from './ApplicationQuestionsResponse';
import ChatButton from '../../../components/UI/ListButtons/ChatButton';
import '../../AllApplicationsPage/ApplicationPage.scss';

const StyledOverFlowcontainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #215c73;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #215c73;
  }
`;

const StyledLabel = styled.span`
  font-weight: 800;
  size: 16px;
  margin-right: 5px;
  color: #215c73;
`;

const StyledInfo = styled.p`
  margin: 5px 0px;
`;

function ApplicationFormDetail({ applicationFormResponse }) {
  const {
    clientName = '',
    contributionName = '',
    createTime = '',
    questions = [],
    clientId = '',
  } = applicationFormResponse;
  const { domain } = useRouter();

  return (
    <>
      <div className="application-form-detail">
        <div style={{ display: 'flex', justifyContent: 'space-between', fontFamily: 'Avenir' }}>
          <StyledInfo>
            {' '}
            <StyledLabel> Name:</StyledLabel>
            {clientName}
          </StyledInfo>
          <div className="chat-icon">
            <ChatButton
              handleClick={() => {
                window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${clientId}`));
              }}
            />
          </div>
        </div>

        <StyledInfo>
          {' '}
          <StyledLabel>Contribution:</StyledLabel> {contributionName}{' '}
        </StyledInfo>

        <StyledInfo>
          {' '}
          <StyledLabel>Date:</StyledLabel> {formatTime(createTime)}
        </StyledInfo>
      </div>
      <div style={{ margin: '20px 0px' }}>
        <div>
          <StyledLabel>Questions</StyledLabel>
        </div>
        <StyledOverFlowcontainer>
          <Grid container spacing={2} style={{ margin: '0' }}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0' }}>
              {questions.map((question, index) => {
                return (
                  <ApplicationQuestionsResponse
                    index={index}
                    questionType={question.questionType}
                    questionStatement={question.name}
                    isOptional={question.isOptional}
                    userResponse={question.userResponse}
                    options={question.options}
                  />
                );
              })}
            </Grid>
          </Grid>
        </StyledOverFlowcontainer>
      </div>
    </>
  );
}

ApplicationFormDetail.propTypes = {
  applicationFormResponse: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    contributionName: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

ApplicationFormDetail.defaultProps = {
  applicationFormResponse: {
    clientId: '',
    clientName: '',
    contributionName: '',
    createTime: '',
    status: '',
    contributionId: '',
    id: '',
  },
};

export default ApplicationFormDetail;
