import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import useNotifications from 'hooks/useNotifications';
import CoachImage from 'assets/chatlogo.png';
import { updateContributionActions } from 'actions/contributions';
import InfoIcon from '@material-ui/icons/Info';
import { toRem, colors } from 'utils/styles';
import usePaidTier from 'hooks/usePaidTier';
import { mdiDelete, mdiPlus } from '@mdi/js';
import * as paidTier from 'selectors/paidTier';
import { Icon } from '@mdi/react';
import {
  useTheme,
  useMediaQuery,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  NativeSelect,
  MenuItem,
  OutlinedInput,
  Grid,
  Typography,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TemplateOne from 'assets/congratulations.png';
import * as bankAccountsService from 'services/bankAccounts.service';
import { redirectTo } from 'services/links';
import { addPublicFile } from 'services/content.service';
import * as contributionActions from 'actions/contributions';
import { createContract, getAllContracts, getSingleContract, updateContract } from 'services/contributions.service';
import { IconAttach } from 'components/Icons/IconAttach';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { PageTitleSecond, BodyOrLeftText, BodyMainText } from 'components/UI/Text/TextStyles';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { useRouter, useHttp, useAccount, usePartnerCoach, useShallowEqualSelector } from 'hooks';
import { useSaveContribution } from 'hooks/useSaveContribution';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import {
  StyleLabel,
  StyledButtonAsLink,
  StyledLink,
  StyledAttachTermsButton,
  StyledButtonContainer,
  StyledCrossIcon,
  StyledFileName,
  HiddenFileInput,
  StyledCustomTerms,
  StyleLabelSignature,
} from './style';
import Modal from '../../components/UI/Modal.js';
import { ContributionType } from 'helpers/constants';
import styled from 'styled-components';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import CreateThankYouPage from './CreateThankYouPage';
import CreateCustomWaiver from './components/CreateCustomWaiver';
import { ClientPreviewModeOn, ClientPreviewModeOff } from 'actions/clientpreviewmode';
import {
  getLatestApproved,
  getUpcomingCreated,
  saveThankYouPage,
  saveCustomWaiver,
} from 'services/contributions.service';
import WritingPencil from '../../assets/WritingPencil.svg';
import Select from 'components/UI/Select';
import { makeStyles } from '@material-ui/core/styles';
import { ElectricalServicesSharp } from '@mui/icons-material';
import CrossIcon from '@material-ui/icons/Clear';
import CustomExpansionPanel from './components/ExpansionPanel';
export const PAID_TIER_TITLES = {
  launch: 'Launch',
  impact: 'Impact',
  scale: 'Scale',
};
const ACCEPTABLE_FILE_TYPES =
  'application/pdf,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation';

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const HyperLink = styled.a`
  visibility: hidden;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;
const useStyles = makeStyles(({ mobileView }) => ({
  formControl: {
    width: '100%',
    marginTop: 0,
  },
  cardContainer: {
    width: '100%',
    border: '1px solid #E7E7E7',
    borderRadius: '4px',
  },
  cardHeader: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 24px',
    borderBottom: '1px solid #E7E7E7',
  },

  cardHeaderHeading: {
    fontFamily: 'Avenir',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '30px',
    letterSpacing: '0.1px',
  },
  cardHeaderHeadingMobile: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '30px',
    letterSpacing: '0.1px',
  },
  cardBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px 24px',
  },
}));
const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
  color: gray;
`;
function ReviewAndSubmit({ user, saveContributionToLS, error, loading, contribution, saveContribution }) {
  const { history, query, domain } = useRouter();
  const anchorRef = useRef();
  const isEditing = !!query.id;
  const { request } = useHttp();
  const dispatch = useDispatch();
  const clientPreviewMode = useSelector(state => state?.clientPreviewMode?.clientPreview);
  const errorState = useSelector(state => state.contributions.error);
  const errorOccured = useSelector(state => state?.contributions.showError);
  const [loader, setLoader] = useState(false);
  const [dataToBeEvaluatedwithChanges, setdataToBeEvaluatedwithChanges] = useState(null);
  const [allContractsFetched, setallContractsFetched] = useState([]);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const breakPointForCustomWavier = useMediaQuery('(max-width:650px)');
  const [showThankYouPage, setshowThankYouPage] = useState(false);
  const [thankYouPageButton, setThankYouPageButton] = useState(false);
  const { partnerCoaches } = usePartnerCoach(query?.id);
  const isPartnerCoach = partnerCoaches.some(obj => obj.userId === user.id);
  const {
    user: { transfersEnabled, isStandardAccount, defaultPaymentMethod, standardAccountTransfersEnabled, avatarUrl },
  } = useAccount();
  const { successMessage, errorMessage } = useNotifications();
  const [templateOptions, setTemplateOptions] = useState([
    {
      title: 'None',
      value: 'None',
      formDescription: null,
      id: null,
    },
  ]);
  const [selectedTemplate, setSelectedTemplate] = useState('None');
  const [selectedTemplateId, setSelectedTemplateId] = useState('None');
  const [previousSelectedTemplateId, setPreviousSelectedTemplateId] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [changeButtonStatus, setchangeButtonStatus] = useState(contribution?.customWaiverId === null ? false : true);
  const classes = useStyles();
  const { isLaunchPlan } = usePaidTier();
  const [agree, setAgree] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [confirmationFirstPopup, setConfirmationFirstPopup] = useState(false);
  const [tempData, setTempData] = useState({});
  const [templateChanged, settemplateChanged] = useState(false);
  const [isElectronicSignatureActive, setIsElectronicSignatureActive] = useState(
    contribution?.isElectronicSignatureActive || false,
  );
  const [errorPopUpState, seterrorPopUpState] = useState(true);
  const [thankyouPage, setThankyouPage] = useState(null);
  const [isApplicationActive, setIsApplicationActive] = useState(false);
  const [isThankyouPageActive, setIsThankyouPageActive] = useState(contribution?.isThankyouPageAdded);
  const [customWaiverId, setCustomWaiverId] = useState(contribution?.customWaiverId);
  const [showTerms, setShowTerms] = useState(false);
  const [dataToBeUpdated, setdataToBeUpdated] = useState(null);
  const [waiverBoby, setWaiverBoby] = useState('');
  const [createdContribId, setCreatedContribId] = useState(null);
  const [documents, setDocuments] = useState({
    termsOfUse: null,
    payment: null,
  });
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);

  // const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  const [showModalPlan, setShowModalPlan] = useState(false);
  const [showCustomWaiverModal, setShowCustomWaiverModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(contribution?.customToS);
  const saveHandler = isEditing ? saveContribution : saveContributionToLS;
  const [contibId, setContibId] = useState(null);
  const [isRequiredMissing, setIsRequiredMissing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);
  const [tempName, setTempName] = useState('None');
  useEffect(() => {
    getSingleContract(contribution?.id)
      .then(res => {
        setdataToBeEvaluatedwithChanges(res);
        setWaiverData(res);
        setTempData(res);
        // setIsElectronicSignatureActive(true);
      })
      .catch(() => {
        // setIsElectronicSignatureActive(false);
      });
  }, []);
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
    getAllContracts({ coachId: user.id }).then(res => {
      const tempTemplateOptions = res.map(option => {
        return {
          title: option.formName,
          formDescription: option.formDescription,
          value: option.formName,
          id: option.id,
          formName: option.formName,
          completeDescription: option.formText,
        };
      });
      setWaiverData({
        ...waiverData,
        templatesList: res,
      });
      setTemplateOptions([...templateOptions, ...tempTemplateOptions]);
      setallContractsFetched(tempTemplateOptions);
    });
  }, []);
  const fileInput = React.createRef();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (contribution?.customWaiverId != null && selectedTemplate === 'None') {
      setSelectedTemplate(contribution.customWaiverTemplateName);
      setSelectedTemplateId(contribution?.customWaiverId);
      setIsElectronicSignatureActive(true);
      setIsAttachmentOrWaiver('customwaiver');
    } else if (contribution?.customToS?.length > 0 && fileUrl?.length > 0) {
      setIsAttachmentOrWaiver('attachments');
    }
  }, [contribution]);

  useEffect(() => {
    console.log('selectedTemplateUpdated', selectedTemplate);
  }, [selectedTemplate]);

  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const togglePopupPlan = () => {
    setShowModalPlan(!showModalPlan);
  };
  const [run, setRun] = useState(!user?.userProgressbarData?.FirstContributionDone);
  const [stepIndex, setStepIndex] = useState(4);
  const [isCustomWaiverChecked, setIsCustomWaiverChecked] = useState(false);
  const [isAttachmentOrWaiver, setIsAttachmentOrWaiver] = useState(null);
  const [offset, setOffset] = useState(0);
  const [waiverData, setWaiverData] = useState({
    formName: '',
    clientName: '',
    clientSignature: '',
    description: '',
    completeDescription: '',
    templateId: null,
    saveTemplate: false,
    templatesList: [],
  });

  let joyRideHelpers;

  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            You’re almost done!
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            In this step, you can add terms for your clients to agree to at checkout. As soon as you agree to the terms
            and submit, you’ll be ready to share this new service with your clients. Congrats!
          </div>
          <div
            className="buttons"
            style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}
          >
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();

                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {contibId != null && (
              <Button
                className="btn-right"
                autoWidth
                invert
                style={{
                  marginTop: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#215C73',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#215C73',
                  // marginLeft: '10px',
                }}
                onClick={() => {
                  updateUserF(true);

                  // joyRideHelpers.skip();
                  // setRun(false);
                }}
              >
                Complete & Next
              </Button>
            )}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#reviewAndSubmit',
    },
    // {
    //   hideFooter: true,
    //   disableBeacon: true,
    //   content: (
    //     <div>
    //       <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
    //       <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
    //       <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
    //         You are fully set up & ready to transform lives!
    //       </div>
    //       <Button
    //         autoWidth
    //         backgroundColor={'#C9B382'}
    //         style={{
    //           marginTop: '40px',
    //           paddingTop: '10px',
    //           paddingBottom: '10px',
    //         }}
    //         onClick={() => {
    //           joyRideHelpers.skip();
    //         }}
    //       >
    //         Let’s do this!
    //       </Button>
    //     </div>
    //   ),
    //   placement: 'center',
    //   target: 'body',
    // },
  ]);

  const onSubmitWaiverForm = values => {
    let tempWaiverData = waiverData;
    const finalValues = {
      formName: values.formName,
      formDescription: values.description,
      formText: values.completeDescription,
      coachId: user.id,
      isTemplate: values.saveTemplate,
      templateId: values.templateId,
    };
    const isMatched = isFormMatched(values);
    if (contribution.customWaiverId === null) {
      if (isMatched.length > 0 && values.saveTemplate === true) {
        setShowAlertModal(true);
      } else {
        if (contribution.customWaiverId === null) {
          addNewFormTemplate(finalValues);
        } else {
          if (
            dataToBeEvaluatedwithChanges.formName != values.formName ||
            dataToBeEvaluatedwithChanges.formDescription != values.description ||
            dataToBeEvaluatedwithChanges.formText != values.completeDescription
          ) {
            setdataToBeUpdated(finalValues);
            setConfirmationFirstPopup(true);
          } else {
            addNewFormTemplate(finalValues);
          }
        }
      }
    } else {
      if (isMatched.length > 0 && values.saveTemplate === true) {
        setShowAlertModal(true);
      } else {
        if (
          dataToBeEvaluatedwithChanges.formName != values.formName ||
          dataToBeEvaluatedwithChanges.formDescription != values.description ||
          dataToBeEvaluatedwithChanges.formText != values.completeDescription
        ) {
          setConfirmationPopup(true);
        } else {
          updateFormTemplate();
        }
      }
    }
    // if (isMatched !== -1) {
    //   if (
    //     `${values?.formName?.toLowerCase()}` !== `${tempWaiverData?.templatesList[isMatched]?.formName?.toLowerCase()}`
    //   ) {
    //     //Name Not Matched
    //     addNewFormTemplate(finalValues);
    //   } else if (isMatched === 0) {
    //     if (
    //       `${values?.formName?.toLowerCase()}` !==
    //         `${tempWaiverData?.templatesList[isMatched]?.formName?.toLowerCase()}` ||
    //       `${values?.completeDescription}` !== `${tempWaiverData?.templatesList[isMatched]?.formText}`
    //     ) {
    //       //Name Not Matched
    //       addNewFormTemplate(finalValues);
    //     } else {
    //       const contribData = {
    //         ...contribution,
    //         customWaiverId: values.templateId,
    //         customWaiverTemplateName: values.formName,
    //       };
    //       setIsAttachmentOrWaiver('customwaiver');
    //       settemplateChanged(false);
    //       onSaveEndExit(contribData);
    //       setShowCustomWaiverModal(!showCustomWaiverModal);
    //     }
    //   } else {
    //     setTempData(finalValues);
    //     setConfirmationPopup(true);
    //   }
    // }
    // if (isMatched === -1) {
    //   addNewFormTemplate(finalValues);
    // }
    setFileUrl('');
    setTempData(finalValues);
    setchangeButtonStatus(true);
    getAllContracts({ coachId: user.id }).then(res => {
      const tempTemplateOptions = res.map(option => {
        return {
          title: option.formName,
          formDescription: option.formDescription,
          value: option.formName,
          id: option.id,
          formName: option.formName,
          completeDescription: option.formText,
        };
      });
      setallContractsFetched(tempTemplateOptions);
    });
  };

  const isFormMatched = value => {
    return allContractsFetched.filter(template => template.formName === value.formName);
  };

  const addNewFormTemplate = async finalValues => {
    let tempWaiverData = { ...waiverData };
    finalValues = {
      ...finalValues,
      contributionId: contribution?.id,
    };
    createContract(finalValues)
      .then(data => {
        setWaiverBoby(data?.formText);
        tempWaiverData = {
          ...tempWaiverData,
          formName: data?.formName,
          formDescription: data?.formDescription,
          formText: data?.formText,
          description: data?.formDescription,
          templateId: data.id,
          id: data.id,
          completeDescription: data?.formText,
        };
        if (data.isTemplate === false) {
          let tempWaiverDataFind = templateOptions.find(
            waiver => waiver.title == data.formName && data.isTemplate === false,
          );
          // if(tempWaiverDataFind?.length === 0 ||tempWaiverDataFind=== undefined){
          //   tempWaiverData.templatesList.push({
          //     id: data.id,
          //     coachId: user.id,
          //     formName: data?.formName,
          //     formDescription: data?.formDescription,
          //     formText: data?.formText,
          //     title: data?.formName,
          //     value: data?.formName,
          //   });
          // }
          if (tempWaiverDataFind?.length === 0 || tempWaiverDataFind === undefined) {
            templateOptions.push({
              title: data.formName,
              value: data.formName,
              id: data.id,
            });
          } else {
            getAllContracts({ coachId: user.id }).then(res => {
              const tempTemplateOptions = res.map(option => {
                return {
                  title: option.formName,
                  formDescription: option.formDescription,
                  value: option.formName,
                  id: option.id,
                };
              });
              setWaiverData({
                ...waiverData,
                templatesList: res,
              });
              setTemplateOptions([...tempTemplateOptions]);
              setallContractsFetched(tempTemplateOptions);
            });
          }
        } else {
          getAllContracts({ coachId: user.id }).then(res => {
            const tempTemplateOptions = res.map(option => {
              return {
                title: option.formName,
                formDescription: option.formDescription,
                value: option.formName,
                id: option.id,
              };
            });
            setWaiverData({
              ...waiverData,
              templatesList: res,
            });
            setTemplateOptions([...tempTemplateOptions]);
            setallContractsFetched(tempTemplateOptions);
          });
        }
        setTemplateOptions(templateOptions);
        setWaiverData({ ...tempWaiverData });
        setSelectedTemplate(data.formName);
        setSelectedTemplateId(data?.id);
      })
      .then(res => {
        const contribData = {
          ...contribution,
          customWaiverId: tempWaiverData.id,
          customWaiverTemplateName: tempWaiverData.formName,
          isElectronicSignatureActive: true,
        };
        onSaveEndExit(contribData);
      });

    setShowCustomWaiverModal(!showCustomWaiverModal);
    setIsAttachmentOrWaiver('customwaiver');
    setIsCustomWaiverChecked(true);
    setIsElectronicSignatureActive(true);
  };

  const updateFormTemplate = async () => {
    let tempWaiverData = { ...waiverData };
    let finalValues;
    if (templateChanged) {
      finalValues = {
        coachId: tempData.coachId,
        formDescription: tempData.formDescription || tempWaiverData?.formDescription,
        formName: tempData.formName,
        formText: tempData.formText || tempWaiverData.formText,
        isTemplate: tempData.isTemplate,
        templateId: tempData.isTemplate ? undefined : tempData.templateId,
        id: contribution?.customWaiverId,
        contributionId: contribution?.id,
      };
    } else {
      finalValues = {
        coachId: tempData.coachId,
        formDescription: tempData.formDescription || tempWaiverData?.formDescription,
        formName: tempData.formName,
        formText: tempData.formText || tempWaiverData.formText,
        isTemplate: tempData.isTemplate,
        id: contribution?.customWaiverId,
        templateId: tempData.isTemplate ? undefined : tempData.templateId,
        contributionId: contribution?.id,
      };
    }
    updateContract(finalValues)
      .then(data => {
        setWaiverBoby(data?.formText);
        if (data) {
          tempWaiverData = {
            ...tempWaiverData,
            formName: data?.formName,
            description: data?.formDescription,
            completeDescription: data?.formText,
            formText: data?.formText,
          };
          if (data.isTemplate === false) {
            let tempWaiverDataFind = templateOptions.find(waiver => waiver.title == data.formName);
            if (tempWaiverDataFind?.length === 0 || tempWaiverDataFind === undefined) {
              templateOptions.push({
                title: data.formName,
                value: data.formName,
                id: data.id,
              });
            } else {
              getAllContracts({ coachId: user.id }).then(res => {
                const tempTemplateOptions = res.map(option => {
                  return {
                    title: option.formName,
                    formDescription: option.formDescription,
                    value: option.formName,
                    id: option.id,
                  };
                });
                setWaiverData({
                  ...waiverData,
                  templatesList: res,
                });
                setTemplateOptions([...tempTemplateOptions]);
                setallContractsFetched(tempTemplateOptions);
              });
            }
          } else if (data.isTemplate === true) {
            getAllContracts({ coachId: user.id }).then(res => {
              const tempTemplateOptions = res.map(option => {
                return {
                  title: option.formName,
                  formDescription: option.formDescription,
                  value: option.formName,
                  id: option.id,
                };
              });
              setWaiverData({
                ...waiverData,
                templatesList: res,
              });
              setTemplateOptions([...tempTemplateOptions]);
              setallContractsFetched(tempTemplateOptions);
            });
          } else {
            getAllContracts({ coachId: user.id }).then(res => {
              const tempTemplateOptions = res.map(option => {
                return {
                  title: option.formName,
                  formDescription: option.formDescription,
                  value: option.formName,
                  id: option.id,
                };
              });
              setWaiverData({
                ...waiverData,
                templatesList: res,
              });
              setTemplateOptions([...tempTemplateOptions]);
              setallContractsFetched(tempTemplateOptions);
            });
          }
          setWaiverData({ ...tempWaiverData });
          setConfirmationPopup(false);
          setSelectedTemplate(data.formName);
          setCustomWaiverId(contribution.customWaiverId);
          setSelectedTemplateId(data?.id);
          setShowCustomWaiverModal(!showCustomWaiverModal);
        }
      })
      .then(res => {
        const contribData = {
          ...contribution,
          customWaiverId: finalValues.id,
          customWaiverTemplateName: tempWaiverData.formName,
          isElectronicSignatureActive: true,
        };
        settemplateChanged(false);
        onSaveEndExit(contribData);
      });
  };

  const onSubmitConfirmation = () => {
    updateFormTemplate();
    setConfirmationPopup(false);
    setShowCustomWaiverModal(!showCustomWaiverModal);
    setIsCustomWaiverChecked(true);
    setIsElectronicSignatureActive(true);
    setIsAttachmentOrWaiver('customwaiver');
  };
  const onSubmitFirstConfirmation = () => {
    setConfirmationFirstPopup(false);
    addNewFormTemplate(dataToBeUpdated);
  };
  const updateUserF = async (goToNextStep = false) => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (goToNextStep) {
        if (res.userProgressbarData?.IsPlanPurchased === false) {
          history.push('/account/payment', { from: 'verify' });
        } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
          history.push('/account/payment', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IntegrationsDone === false) {
          history.push('/account/integrations', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
          history.push('/account/profile');
        } else if (res?.userProgressbarData?.FirstContributionDone === false) {
          dispatch(contributionActions.setPopUp(true));
          dispatch(contributionActions.setShowCompletedGetStart('create'));
          history.push('/dashboard');
        } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
          history.push('/account/profile', { from: 'Manage Your Website Information' });
        } else if (res?.userProgressbarData?.InviteLink === false) {
          contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
        }
      }
    });
    joyRideHelpers.skip();
  };

  useEffect(() => {
    if (!!user?.userProgressbarData?.FirstContributionDone) {
      setSteps([]);
    }

    if (isEditing === true) {
      setRun(false);
    }
  }, []);

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const saveFile = () => {
    saveAs(fileUrl, 'Terms of Use');
  };

  const uploadFileHandler = useCallback(
    e => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      addPublicFile(formData)
        .then(url => {
          setFileUrl(url);
          setIsRequiredMissing(false);
          const contribData = {
            ...contribution,
            customWaiverId: null,
            customWaiverTemplateName: null,
            customToS: url,
            hasAgreedContributionTerms: agree,
          };
          if (isEditing === false) {
            onSaveEndExit(contribData);
          }
        })
        .catch(console.dir());
    },
    [setFileUrl, contribution],
  );

  const handleResetCustomToS = () => {
    setFileUrl(null);
    fileInput.current.value = null;
  };

  const handleAttachFileClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  const [connectStripeModal, setConnectStripeModal] = useState(false);
  const handleConnectStripeModalClose = useCallback(() => setConnectStripeModal(false), [connectStripeModal]);

  useEffect(() => {
    request('/ReferenceData/GetAgreements').then(response => {
      const termsOfUse = response.find(doc => doc.agreementType === 'TermsOfUse');
      const payment = response.find(doc => doc.agreementType === 'Payment');
      setDocuments({ termsOfUse, payment });
    });
  }, [request]);

  const redirect = useCallback(
    (action, savedContribution) => {
      if (action.type === contributionActions.UPDATE_CONTRIBUTION_SUCCESS) {
        setCreatedContribId(savedContribution.id);
      }

      if (action.type === contributionActions.CREATE_CONTRIBUTION_SUCCESS) {
        saveContributionToLS({});
        setCreatedContribId(savedContribution.id);
      }
    },
    [createdContribId],
  );

  const { onSave } = useSaveContribution(
    true,
    agree,
    isElectronicSignatureActive,
    isCustomWaiverChecked,
    isThankyouPageActive,
    thankyouPage,
    {
      fileUrl,
    },
    selectedTemplateId,
    isAttachmentOrWaiver,
  );
  const onSubmit = () => {
    if (isLaunchPlan && !isPartnerCoach) {
      setShowModalPlan(true);
      return;
    }
    if (isAttachmentOrWaiver && (selectedTemplate === 'None' || selectedTemplate === null) && !fileUrl) {
      setIsRequiredMissing(true);
      const contribData = {
        ...contribution,
        customWaiverId: null,
        customWaiverTemplateName: null,
        isElectronicSignatureActive: isElectronicSignatureActive,
      };
      onSaveEndExit(contribData);
      return;
    }

    if (
      contribution?.paymentInfo?.paymentOptions.length === 1 &&
      contribution?.paymentInfo?.paymentOptions.includes('Free')
    ) {
      const contribData = {
        ...contribution,
        isElectronicSignatureActive: isElectronicSignatureActive,
      };
      onSave(contribData, redirect);
    } else if (
      contribution?.paymentInfo?.paymentOptions.length === 1 &&
      contribution?.paymentInfo?.paymentOptions.includes('FreeSessionsPackage')
    ) {
      const contribData = {
        ...contribution,
        isElectronicSignatureActive: isElectronicSignatureActive,
      };
      onSave(contribData, redirect);
    } else if (
      contribution?.paymentInfo?.paymentOptions.length === 2 &&
      contribution?.paymentInfo?.paymentOptions.includes('Free') &&
      contribution?.paymentInfo?.paymentOptions.includes('FreeSessionsPackage')
    ) {
      const contribData = {
        ...contribution,
        isElectronicSignatureActive: isElectronicSignatureActive,
      };
      onSave(contribData, redirect);
    } else {
      if (contribution?.paymentType === 'Advance') {
        if (standardAccountTransfersEnabled) {
          const contribData = {
            ...contribution,
            isElectronicSignatureActive: isElectronicSignatureActive,
          };
          onSave(contribData, redirect);
        } else {
          if (isPartnerCoach) {
            const contribData = {
              ...contribution,
              isElectronicSignatureActive: isElectronicSignatureActive,
            };
            onSave(contribData, redirect);
          } else {
            setConnectStripeModal(true);
          }
        }
      } else {
        if (transfersEnabled) {
          const contribData = {
            ...contribution,
            isElectronicSignatureActive: isElectronicSignatureActive,
          };
          onSave(contribData, redirect);
        } else {
          if (isPartnerCoach) {
            const contribData = {
              ...contribution,
              isElectronicSignatureActive: isElectronicSignatureActive,
            };
            onSave(contribData, redirect);
          } else {
            setConnectStripeModal(true);
          }
        }
      }
    }

    // transfersEnabled || isStandardAccount ? onSave(undefined, redirect) : setConnectStripeModal(true);
  };

  const goTOClientPreviewMode = () => {
    dispatch(ClientPreviewModeOn(`/edit-contribution/${contribution?.id}/review`));
    if (anchorRef?.current) {
      anchorRef.current.click();
    }
  };

  const onSaveEndExit = async contribData => {
    if (isEditing) {
      await saveHandler(contribData);
      dispatch(updateContributionActions.success(contribData));
      await onSaveContribution(contribData);
    } else {
      await saveHandler(contribData);
      dispatch(updateContributionActions.success(contribData));
    }
  };

  const backClickHandler = useCallback(
    contribData => {
      if (query.id) {
        history.push(`/edit-contribution/${query.id}/payment`);
      } else {
        history.push('/create-contribution/payment');
      }
      saveContributionToLS(contribData);
      dispatch(updateContributionActions.success(contribData));
    },
    [history, query.id],
  );

  const handleConnectStripeModalSubmit = useCallback(
    () =>
      bankAccountsService
        .verifyStripeAccount()
        .then(({ link }) => redirectTo(link))
        .catch(err => console.dir(err)),
    [],
  );
  const onVerificate = useCallback(() => {
    request('api/Payment/generate-account-verification-link?forStandardAccount=true', 'POST').then(({ link }) => {
      redirectTo(link);
    });
  }, [request]);

  const backFn = () => {
    setshowThankYouPage(false);
    dispatch(contributionActions.setShowThankYouPage(false));
  };

  const onCancelCustomWaiver = () => {
    if (
      waiverData?.formName?.trim() === '' ||
      waiverData?.formDescription?.trim() === '' ||
      waiverData?.formText?.trim() === ''
    ) {
      setIsCustomWaiverChecked(false);
      // setIsElectronicSignatureActive(false);
    }
    setWaiverData({
      ...waiverData,
      formName: '',
      description: '',
      completeDescription: '',
    });

    // setSelectedTemplate('None');
    setIsCustomWaiverChecked(false);
    // setIsElectronicSignatureActive(false);
    setShowCustomWaiverModal(!setShowCustomWaiverModal);
  };

  const onCheckCustomWaiver = e => {
    let { value } = e.target;
    if (!value) {
      return;
    }
    if (value === 'customwaiver') {
      getSingleContract(contribution?.id)
        .then(res => {
          setWaiverData({
            ...waiverData,
            formName: res?.formName,
            formDescription: res?.formDescription,
            formText: res?.formText,
            description: res?.formDescription,
            templateId: res.id,
            id: res.id,
            completeDescription: res?.formText,
          });
          setWaiverBoby(res?.formText);
          setdataToBeEvaluatedwithChanges(res);
          setIsElectronicSignatureActive(true);
          setSelectedTemplate(waiverData.formName);
          setSelectedTemplateId(waiverData?.id);
          setIsCustomWaiverChecked(true);
        })
        .catch(() => {
          setchangeButtonStatus(false);
        });
      // if (waiverData?.formName !== '') {
      //   setWaiverData({
      //     ...waiverData,
      //   });
      //   setIsElectronicSignatureActive(true);
      //   setSelectedTemplate(waiverData.formName);
      //   setSelectedTemplateId(waiverData?.id);
      //   setIsCustomWaiverChecked(true);
      // }
    }
    if (value === 'attachments') {
      setIsElectronicSignatureActive(false);
      setSelectedTemplate('None');
      setWaiverData({
        ...waiverData,
        formName: '',
        description: '',
        completeDescription: '',
        templateId: null,
      });
    }
    if (!!value?.length && value !== undefined) {
      const isInList = waiverData.templatesList.find(template => template.formName === value);
      if (isInList || value === 'None') {
        setIsAttachmentOrWaiver('customwaiver');
      } else {
        setIsAttachmentOrWaiver(value);
      }
      if (value === 'customwaiver') {
        setIsElectronicSignatureActive(true);
      }
    }
  };

  const changeView = async values => {
    setshowThankYouPage(false);
    dispatch(contributionActions.setShowThankYouPage(false));
    const data = {
      ...values,
      bannerImage: thankyouPage.bannerImage,
      contributionRedirectText: values.buttonTitle,
      contributionId: contribution.id,
      isVideoBanner: thankyouPage?.isVideoBanner,
      isSessionTab: thankyouPage?.isSessionTab,
    };
    await saveThankYouPage(data)
      .then(async res => {
        const contribData = {
          ...contribution,
          isThankyouPageAdded: true,
          thankyouPage: res,
          ServiceProviderName: null,
          timeZoneId: null,
        };
        await saveHandler(contribData);
        dispatch(updateContributionActions.success(contribData));
        setThankYouPageButton(true);
      })
      .catch(err => {
        console.log('=======>', err);
      });
    setThankyouPage(thankyouPage);
  };
  useEffect(() => {
    if (contribution != undefined) {
      setTimeout(() => {
        setThankYouPageButton(contribution?.isThankyouPageAdded);
        setIsThankyouPageActive(contribution?.isThankyouPageAdded);
        setThankyouPage({
          profileImage: contribution?.thankyouPage?.profileImage,
          bannerImage: contribution?.thankyouPage?.bannerImage,
          isVideoBanner: contribution?.thankyouPage?.isVideoBanner,
          tagline: contribution?.thankyouPage?.tagline,
          contributionRedirectText: contribution?.thankyouPage?.contributionRedirectText,
          description: contribution?.thankyouPage?.description,
          isSessionTab: contribution?.thankyouPage?.isSessionTab,
        });
      }, [500]);
    } else {
      setThankyouPage({
        profileImage: contribution?.thankyouPage?.profileImage || avatarUrl,
        bannerImage: contribution?.thankyouPage?.bannerImage || user?.profilePageViewModel?.primaryBannerUrl,
        isVideoBanner: contribution?.thankyouPage?.isVideoBanner || user?.profilePageViewModel?.isPrimaryBannerVideo,
        tagline: contribution?.thankyouPage?.tagline || 'WELCOME IN!',
        isSessionTab: contribution?.thankyouPage?.isSessionTab || false,
        contributionRedirectText:
          contribution?.thankyouPage?.contributionRedirectText || `Access ${contribution?.title}`,
        description:
          contribution?.thankyouPage?.description ||
          'We’re excited to have you confirmed! Please check your email inbox for calendar invites for any upcoming LIVE sessions and click the button below to see what’s coming up!',
      });
    }
  }, [contribution, contribution?.isThankyouPageAdded, contribution?.thankyouPage]);

  if (createdContribId) {
    return <Redirect to={`/contribution-view/${createdContribId}/about`} />;
  }

  return (
    <MainContainer>
      {showThankYouPage ? (
        <CreateThankYouPage
          backClicked={backFn}
          changeView={changeView}
          setThankYouPageModel={setThankyouPage}
          thankYouPageModel={thankyouPage}
        />
      ) : (
        <>
          {error?.Error?.ExceptionName == 'ZoomException' &&
            error.Error.Message ==
              'Class Name: Microsoft.AspNetCore.Routing.EndpointRoutingMiddleware - Method Name: System.Threading.Tasks.Task Invoke(Microsoft.AspNetCore.Http.HttpContext) - OuterException: Invalid access token. \n InnerException: ' && (
              <div
                className="sticky"
                style={{
                  top: offset >= 110 ? 0 : 110,
                  width: '45%',
                  backgroundColor: 'white',
                  marginInline: '20%',
                  border: '1px solid #DBE7F2',
                  borderRadius: '4px',
                  zIndex: 1,
                  transitionDuration: '1s',
                }}
              >
                <div style={{ padding: '15px 10px 10px 15px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontWeight: '700',
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#1E1E1E',
                          marginInline: '10px',
                        }}
                      >
                        Before you proceed...
                      </div>
                    </div>
                    <StyledCloseIcon
                      onClick={() => {
                        seterrorPopUpState(false);
                        // dispatch(contributionActions.setErrorPopUp(false));
                      }}
                      fontSize="10px"
                      style={{ color: 'black' }}
                    />
                  </div>

                  {errorState?.message ===
                  `Your availability time frame windows are overlapping. Please fix this on step 3` ? (
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginTop: '10px',
                        marginInline: '35px',
                      }}
                    >
                      You already have a session scheduled on the added date & time. Please add another date & time in
                      order to proceed with “Save and Exit” Please fill out missing field in order to continue
                    </div>
                  ) : errorState?.Error?.Message ? (
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginTop: '10px',
                        marginInline: '35px',
                      }}
                    >
                      Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom on
                      the integrations tab.
                    </div>
                  ) : (
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginTop: '10px',
                        marginInline: '35px',
                      }}
                    >
                      {
                        'Your Zoom access token has expired.Please update your Zoom access token  by reconnecting zoom on the integrations tab.'
                      }
                    </div>
                  )}
                </div>
              </div>
            )}
          {error?.Error?.ExceptionName == 'ArgumentNullException' &&
            error.Error.Message ==
              "Class Name: Microsoft.AspNetCore.Routing.EndpointRoutingMiddleware - Method Name: System.Threading.Tasks.Task Invoke(Microsoft.AspNetCore.Http.HttpContext) - OuterException: Value cannot be null. (Parameter 'refreshToken') \n InnerException: " && (
              <div
                className="sticky"
                style={{
                  top: offset >= 110 ? 0 : 110,
                  width: '45%',
                  backgroundColor: 'white',
                  marginInline: '20%',
                  border: '1px solid #DBE7F2',
                  borderRadius: '4px',
                  zIndex: 1,
                  transitionDuration: '1s',
                }}
              >
                <div style={{ padding: '15px 10px 10px 15px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontWeight: '700',
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#1E1E1E',
                          marginInline: '10px',
                        }}
                      >
                        Before you proceed...
                      </div>
                    </div>
                    <StyledCloseIcon
                      onClick={() => {
                        seterrorPopUpState(false);
                        // dispatch(contributionActions.setErrorPopUp(false));
                      }}
                      fontSize="10px"
                      style={{ color: 'black' }}
                    />
                  </div>

                  {errorState?.message ===
                  `Your availability time frame windows are overlapping. Please fix this on step 3` ? (
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginTop: '10px',
                        marginInline: '35px',
                      }}
                    >
                      You already have a session scheduled on the added date & time. Please add another date & time in
                      order to proceed with “Save and Exit” Please fill out missing field in order to continue
                    </div>
                  ) : errorState?.Error?.Message ? (
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginTop: '10px',
                        marginInline: '35px',
                      }}
                    >
                      Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom on
                      the integrations tab.
                    </div>
                  ) : (
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginTop: '10px',
                        marginInline: '35px',
                      }}
                    >
                      {
                        'Your Zoom access token has expired.Please update your Zoom access token  by reconnecting zoom on the integrations tab.'
                      }
                    </div>
                  )}
                </div>
              </div>
            )}
          {error?.Error?.ExceptionName == 'ZoomException' &&
            error.Error.Message !=
              'Class Name: Microsoft.AspNetCore.Routing.EndpointRoutingMiddleware - Method Name: System.Threading.Tasks.Task Invoke(Microsoft.AspNetCore.Http.HttpContext) - OuterException: Invalid access token. \n InnerException: ' && (
              <div
                className="sticky"
                style={{
                  top: offset >= 110 ? 0 : 110,
                  width: '45%',
                  backgroundColor: 'white',
                  marginInline: '20%',
                  border: '1px solid #DBE7F2',
                  borderRadius: '4px',
                  zIndex: 1,
                  transitionDuration: '1s',
                }}
              >
                <div style={{ padding: '15px 10px 10px 15px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontWeight: '700',
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#1E1E1E',
                          marginInline: '10px',
                        }}
                      >
                        Before you proceed...
                      </div>
                    </div>
                    <StyledCloseIcon
                      onClick={() => {
                        seterrorPopUpState(false);
                        // dispatch(contributionActions.setErrorPopUp(false));
                      }}
                      fontSize="10px"
                      style={{ color: 'black' }}
                    />
                  </div>

                  {errorState?.message ===
                  `Your availability time frame windows are overlapping. Please fix this on step 3` ? (
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginTop: '10px',
                        marginInline: '35px',
                      }}
                    >
                      You already have a session scheduled on the added date & time. Please add another date & time in
                      order to proceed with “Save and Exit” Please fill out missing field in order to continue
                    </div>
                  ) : errorState?.Error?.Message.includes(
                      'You have exceeded the daily rate limit (100) of Meeting Create/Update API requests permitted for this particular user.',
                    ) === false ? (
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginTop: '10px',
                        marginInline: '35px',
                      }}
                    >
                      Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom on
                      the integrations tab.
                    </div>
                  ) : (
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginTop: '10px',
                        marginInline: '35px',
                      }}
                    >
                      {
                        'You have exceeded the daily rate limit (100) of Meeting Create/Update API requests permitted for this particular user.'
                      }
                    </div>
                  )}
                </div>
              </div>
            )}
          {error?.message && (
            <div
              className="sticky"
              style={{
                top: offset >= 110 ? 0 : 110,
                width: '45%',
                backgroundColor: 'white',
                marginInline: '20%',
                border: '1px solid #DBE7F2',
                borderRadius: '4px',
                zIndex: 1,
                transitionDuration: '1s',
              }}
            >
              <div style={{ padding: '15px 10px 10px 15px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '700',
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: '#1E1E1E',
                        marginInline: '10px',
                      }}
                    >
                      Before you proceed...
                    </div>
                  </div>
                  <StyledCloseIcon
                    onClick={() => {
                      seterrorPopUpState(false);
                      // dispatch(contributionActions.setErrorPopUp(false));
                    }}
                    fontSize="10px"
                    style={{ color: 'black' }}
                  />
                </div>

                {errorState?.message ===
                `Your availability time frame windows are overlapping. Please fix this on step 3` ? (
                  <div
                    style={{
                      fontFamily: 'Avenir',
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: '#1E1E1E',
                      marginTop: '10px',
                      marginInline: '35px',
                    }}
                  >
                    You already have a session scheduled on the added date & time. Please add another date & time in
                    order to proceed with “Save and Exit” Please fill out missing field in order to continue
                  </div>
                ) : errorState?.Error?.Message ? (
                  <div
                    style={{
                      fontFamily: 'Avenir',
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: '#1E1E1E',
                      marginTop: '10px',
                      marginInline: '35px',
                    }}
                  >
                    Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom on the
                    integrations tab.
                  </div>
                ) : (
                  <div
                    style={{
                      fontFamily: 'Avenir',
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: '#1E1E1E',
                      marginTop: '10px',
                      marginInline: '35px',
                    }}
                  >
                    {error?.message ===
                    'Your availability time frame windows are overlapping. Please fix this on step 3'
                      ? 'Your availability time frame windows are overlapping. Please fix this on step 3'
                      : error?.message.includes(
                          'at ZoomNet.OAuthConnectionInfo..ctor(String clientId, String clientSecret, String refreshToken, String accessToken, OnTokenRefreshedDelegate onTokenRefreshed)',
                        )
                      ? `Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom on the
                      integrations tab.`
                      : 'Please complete all required details to submit.'}
                  </div>
                )}
              </div>
            </div>
          )}
          <Joyride
            // tooltipComponent={Tooltip}
            callback={handleJoyrideCallback}
            continuous={true}
            getHelpers={getHelpers}
            run={run}
            stepIndex={stepIndex}
            showProgress={false}
            showSkipButton={false}
            steps={steps}
            styles={{
              options: {
                primaryColor: '#CDBA8F',
                zIndex: 10000000,
              },
            }}
          />
          <StyledMainSection mobileView={mobileView}>
            <Card id={'reviewAndSubmit'} mobileView={mobileView}>
              <CardHeader mobileView={mobileView}>
                <PageTitleSecond mobileView={mobileView}>5. Review and Submit</PageTitleSecond>
              </CardHeader>
              <CardBody>
                <BodyMainText>
                  {user.firstName}, great work on creating this new Contribution. Upon clicking submit, you’ll preview
                  the admin view of your service and will have a shareable link to preview your landing page and start
                  inviting new clients to join!
                </BodyMainText>
                <BodyMainText>
                  We’re thrilled for you & the impact you will have on your clients lives through this contribution!
                </BodyMainText>
                <BodyMainText>
                  Please note, you can come back to edit any portion of the contribution except for the contribution
                  type & payment options. If you’re not sure you want to submit yet - feel free to click “Save & Exit”
                  to save it as a draft to come back to.
                </BodyMainText>
                <CustomExpansionPanel
                  summaryTitle={
                    <div className={mobileView ? classes.cardHeaderHeadingMobile : classes.cardHeaderHeading}>
                      Create a thank you page? (Optional)
                    </div>
                  }
                  panelHeight={mobileView ? '' : '50px'}
                  panelPadding="10px 24px 10px 24px"
                  customStyle={{ padding: '30px 24px' }}
                  expand={isExpanded}
                >
                  <div
                    style={{
                      width: '99%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: mobileView ? 'wrap' : '',
                    }}
                  >
                    <StyleLabelSignature mobileView={mobileView}>
                      <Checkbox
                        id="create-contribution-agreement"
                        color="primary"
                        checked={thankYouPageButton}
                        onChange={e => {
                          setIsThankyouPageActive(e.target.checked);
                          setThankYouPageButton(e.target.checked);
                        }}
                      />
                      Enable thank you page
                    </StyleLabelSignature>

                    <Button
                      onClick={() => {
                        setshowThankYouPage(true);
                        dispatch(contributionActions.setShowThankYouPage(true));
                        setIsExpanded(true);
                      }}
                      style={{
                        fontSize: '14px',
                        fontWeight: '900',
                        width: '240px',
                        marginLeft: mobileView ? '10px' : '',
                      }}
                      autoWidth
                      disabled={!isThankyouPageActive}
                    >
                      {!contribution?.thankyouPage || contribution?.isThankyouPageAdded === false
                        ? `Create Thank You Page`
                        : `Edit Thank You Page`}
                    </Button>
                  </div>
                </CustomExpansionPanel>
                <CustomExpansionPanel
                  summaryTitle={
                    <div className={mobileView ? classes.cardHeaderHeadingMobile : classes.cardHeaderHeading}>
                      Create or attach a contract? (Optional)
                    </div>
                  }
                  panelHeight={mobileView ? '' : '50px'}
                  panelPadding="10px 24px 10px 24px"
                  customStyle={{ padding: '30px 24px' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <RadioGroup
                      className="waiver-radio-group"
                      value={isAttachmentOrWaiver}
                      onClick={e => {
                        if (e?.target.type === 'file') {
                          onCheckCustomWaiver(e);
                          return;
                        }
                        e.preventDefault();
                        if (!!e.target?.value?.length && e?.target?.value !== undefined) {
                          setFileUrl('');
                          onCheckCustomWaiver(e);
                        }
                      }}
                    >
                      <div>
                        <FormControlLabel
                          value="attachments"
                          control={<Radio color={isAttachmentOrWaiver === 'attachments' ? 'primary' : 'gray'} />}
                          label={
                            <div>
                              <StyledAttachTermsButton
                                invert
                                style={{
                                  padding: '1rem 1.5rem 1rem 0px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: isAttachmentOrWaiver !== 'attachments' ? 'gray' : 'black',
                                  fontSize: mobileView ? '15px' : '1rem',
                                  fontWeight: '400',
                                  fontFamily: 'Avenir',
                                }}
                                mobileView={mobileView}
                                type="button"
                                onClick={handleAttachFileClick}
                                disableRipple
                                component="span"
                              >
                                <IconAttach
                                  className="mr-3"
                                  color={isAttachmentOrWaiver !== 'attachments' ? 'gray' : 'black'}
                                />
                                Attach Your Terms
                              </StyledAttachTermsButton>

                              <HiddenFileInput
                                onChange={uploadFileHandler}
                                ref={fileInput}
                                accept={ACCEPTABLE_FILE_TYPES}
                                disabled={isAttachmentOrWaiver !== 'attachments'}
                              />
                            </div>
                          }
                        />
                        <StyledCustomTerms>
                          <div style={{ width: '100%' }}>
                            Optional: You can also attach your own terms, or create an embedded contract called waiver,
                            unique for your business here. Your clients will agree to these terms at checkout.
                          </div>
                        </StyledCustomTerms>
                      </div>
                      {fileUrl && (
                        <StyledFileName mobileView={mobileView}>
                          <button style={{ border: 'none' }} onClick={saveFile}>
                            My Terms of Use
                          </button>
                          <StyledCrossIcon onClick={handleResetCustomToS} />
                        </StyledFileName>
                      )}
                      <div>
                        <FormControlLabel
                          value="customwaiver"
                          style={{
                            // display: 'flex',
                            alignItems: mobileView ? 'flex-start' : 'center',
                          }}
                          control={<Radio color={isAttachmentOrWaiver !== 'attachments' ? 'primary' : 'gray'} />}
                          label={
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                color: isAttachmentOrWaiver === 'attachments' ? 'gray' : 'black',
                              }}
                            >
                              <Grid container>
                                <Grid item>
                                  <StyledAttachTermsButton
                                    invert
                                    mobileView={mobileView}
                                    type="button"
                                    style={{
                                      minWidth: '0px',
                                      paddingLeft: '4px',
                                      color: isAttachmentOrWaiver !== 'customwaiver' ? 'gray' : 'black',
                                      fontSize: mobileView ? '15px' : '1rem',
                                      cursor: 'default',
                                      fontFamily: 'Avenir',
                                      fontWeight: '400',
                                      // textDecoration: isAttachmentOrWaiver === 'customwaiver' ? 'underline' : '',
                                    }}
                                    // onClick={() => {
                                    //   if (isAttachmentOrWaiver === 'customwaiver') {
                                    //     if (contribution?.customWaiverId !== null) {
                                    //       const tempWaiverData = waiverData.templatesList.find(
                                    //         waiver => waiver.formName == contribution?.customWaiverTemplateName,
                                    //       );
                                    //       if (tempWaiverData) {
                                    //         setWaiverData({
                                    //           ...waiverData,
                                    //           formName: tempWaiverData?.formName,
                                    //           formDescription: tempWaiverData?.formDescription,
                                    //           formText: tempWaiverData?.formText,
                                    //           description: tempWaiverData?.formDescription,
                                    //           templateId: tempWaiverData.id,
                                    //           id: tempWaiverData.id,
                                    //           completeDescription: tempWaiverData?.formText,
                                    //         });
                                    //       }
                                    //     }
                                    //     setShowCustomWaiverModal(true);
                                    //   }
                                    // }}
                                    disableRipple
                                    component="span"
                                  >
                                    <img
                                      src={WritingPencil}
                                      // className="mr-3"
                                      style={{
                                        paddingLeft: 0,
                                        opacity: isAttachmentOrWaiver !== 'customwaiver' ? 0.5 : 1,
                                        marginRight: '11px',
                                      }}
                                      alt="pencil"
                                    />
                                    {'Use Custom Contract'}
                                  </StyledAttachTermsButton>
                                </Grid>
                                {!breakPointForCustomWavier && (
                                  <Grid
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      marginBottom: '5px',
                                    }}
                                    item
                                  >
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formControl}
                                      style={{ marginTop: 0 }}
                                    >
                                      {isAttachmentOrWaiver === 'customwaiver' && (
                                        <>
                                          <Button
                                            // disabled={isPartnerCoach}
                                            onClick={() => {
                                              if (contribution?.customWaiverId !== null) {
                                                getSingleContract(contribution.id)
                                                  .then(res => {
                                                    setWaiverData({
                                                      ...waiverData,
                                                      formName: res?.formName,
                                                      formDescription: res?.formDescription,
                                                      formText: res?.formText,
                                                      description: res?.formDescription,
                                                      templateId: res.id,
                                                      id: res.id,
                                                      completeDescription: res?.formText,
                                                    });
                                                    setWaiverBoby(res?.formText);
                                                    setdataToBeEvaluatedwithChanges(res);
                                                  })
                                                  .then(() => {
                                                    setShowCustomWaiverModal(true);
                                                  });
                                              } else {
                                                setShowCustomWaiverModal(true);
                                              }
                                              getAllContracts({ coachId: user.id }).then(res => {
                                                const tempTemplateOptions = res.map(option => {
                                                  return {
                                                    title: option.formName,
                                                    formDescription: option.formDescription,
                                                    value: option.formName,
                                                    id: option.id,
                                                    formName: option.formName,
                                                    completeDescription: option.formText,
                                                  };
                                                });
                                                setallContractsFetched(tempTemplateOptions);
                                              });
                                            }}
                                            style={{ fontSize: '14px', fontWeight: '900', width: '252px' }}
                                            autoWidth
                                          >
                                            {changeButtonStatus === false
                                              ? 'Create Custom Contract'
                                              : 'Update Custom Contract'}
                                          </Button>
                                        </>
                                      )}
                                    </FormControl>
                                    {contribution?.customWaiverId && isAttachmentOrWaiver === 'customwaiver' && (
                                      <span>
                                        <StyledIcon
                                          path={mdiDelete}
                                          size={1}
                                          onClick={() => {
                                            setLoader(true);
                                            const contribData = {
                                              ...contribution,
                                              customWaiverId: null,
                                              customWaiverTemplateName: null,
                                              isElectronicSignatureActive: false,
                                            };
                                            setIsAttachmentOrWaiver(null);
                                            setIsElectronicSignatureActive(false);
                                            setSelectedTemplateId(null);
                                            setSelectedTemplate('None');
                                            setWaiverBoby('');
                                            onSaveEndExit(contribData).then(() => {
                                              setLoader(false);
                                            });
                                          }}
                                          isLive={!mobileView}
                                        />
                                      </span>
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                            </div>
                          }
                        />
                        {breakPointForCustomWavier && (
                          <Grid item>
                            <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 0 }}>
                              {isAttachmentOrWaiver === 'customwaiver' && (
                                <Button
                                  // disabled={isPartnerCoach}
                                  onClick={() => {
                                    if (contribution?.customWaiverId !== null) {
                                      getSingleContract(contribution.id)
                                        .then(res => {
                                          setWaiverData({
                                            ...waiverData,
                                            formName: res?.formName,
                                            formDescription: res?.formDescription,
                                            formText: res?.formText,
                                            description: res?.formDescription,
                                            templateId: res.id,
                                            id: res.id,
                                            completeDescription: res?.formText,
                                          });
                                          setWaiverBoby(res?.formText);
                                        })
                                        .then(() => {
                                          setShowCustomWaiverModal(true);
                                        })
                                        .catch(() => {
                                          setchangeButtonStatus(false);
                                        });
                                    } else {
                                      setShowCustomWaiverModal(true);
                                    }
                                  }}
                                  style={{ fontSize: '14px', fontWeight: '900', width: '252px' }}
                                  autoWidth
                                >
                                  {changeButtonStatus === false ? 'Create Custom Contract' : 'Update Custom Contract'}
                                </Button>
                              )}
                            </FormControl>
                          </Grid>
                        )}
                      </div>
                    </RadioGroup>
                    <StyleLabelSignature mobileView={mobileView}>
                      <Checkbox
                        // disabled={isPartnerCoach}
                        id="create-contribution-agreement"
                        color="primary"
                        checked={isElectronicSignatureActive}
                        onChange={e => {
                          if (isAttachmentOrWaiver === 'customwaiver' && isElectronicSignatureActive === true) {
                            setIsAttachmentOrWaiver(null);
                            setIsElectronicSignatureActive(e.target.checked);
                            setIsCustomWaiverChecked(false);
                            setCustomWaiverId(null);
                            setSelectedTemplateId(null);
                            setSelectedTemplate(null);
                          } else {
                            setIsElectronicSignatureActive(e.target.checked);
                          }

                          // setIsCustomWaiverChecked(e.target.checked);
                        }}
                      />
                      Require electronic signature from your clients
                    </StyleLabelSignature>
                  </div>
                </CustomExpansionPanel>
                <div className={classes.cardContainer}>
                  <div className={classes.cardHeader}>
                    <div className={mobileView ? classes.cardHeaderHeadingMobile : classes.cardHeaderHeading}>
                      Terms & Conditions
                    </div>
                  </div>
                  <div className={classes.cardBody}>
                    <BodyMainText>
                      If you have any questions, feel free to email us at &nbsp;
                      <StyledLink href="mailto:support@cohere.live">support@cohere.live</StyledLink> or review the&nbsp;
                      <StyledLink
                        href="https://www.cohere.live/privacy-and-terms/user-agreement/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Terms of Service.
                      </StyledLink>
                      <StyleLabel mobileView={mobileView}>
                        <Checkbox
                          id="create-contribution-agreement"
                          color="primary"
                          checked={agree}
                          onChange={e => setAgree(e.target.checked)}
                        />
                        <a
                          href="/"
                          onClick={e => {
                            e.preventDefault();
                            setShowTerms(true);
                          }}
                        >
                          I agree to the Terms of Service
                        </a>
                      </StyleLabel>
                    </BodyMainText>
                  </div>
                </div>

                {/* <RadioGroup
                  className="waiver-radio-group"
                  value={isAttachmentOrWaiver}
                  onClick={e => {
                    if (e?.target.type === 'file') {
                      onCheckCustomWaiver(e);
                      return;
                    }
                    e.preventDefault();
                    if (!!e.target?.value?.length && e?.target?.value !== undefined) {
                      setFileUrl('');
                      onCheckCustomWaiver(e);
                    }
                  }}
                >
                  <div>
                    <FormControlLabel
                      value="attachments"
                      control={<Radio color={isAttachmentOrWaiver === 'attachments' ? 'primary' : 'gray'} />}
                      label={
                        <div>
                          <StyledAttachTermsButton
                            invert
                            style={{
                              padding: '1rem 1.5rem 1rem 0px',
                              display: 'flex',
                              alignItems: 'center',
                              color: isAttachmentOrWaiver !== 'attachments' ? 'gray' : 'black',
                              fontSize: mobileView ? '15px' : '',
                            }}
                            mobileView={mobileView}
                            type="button"
                            onClick={handleAttachFileClick}
                            disableRipple
                            component="span"
                          >
                            <IconAttach
                              className="mr-3"
                              color={isAttachmentOrWaiver !== 'attachments' ? 'gray' : 'black'}
                            />
                            Attach Your Terms
                          </StyledAttachTermsButton>

                          <HiddenFileInput
                            onChange={uploadFileHandler}
                            ref={fileInput}
                            accept={ACCEPTABLE_FILE_TYPES}
                            disabled={isAttachmentOrWaiver !== 'attachments'}
                          />
                        </div>
                      }
                    />
                  </div>
                  {fileUrl && (
                    <StyledFileName mobileView={mobileView}>
                      <button style={{ border: 'none' }} onClick={saveFile}>
                        My Terms of Use
                      </button>
                      <StyledCrossIcon onClick={handleResetCustomToS} />
                    </StyledFileName>
                  )}
                  <div>
                    <FormControlLabel
                      value="customwaiver"
                      style={{
                        // display: 'flex',
                        alignItems: mobileView ? 'flex-start' : 'center',
                      }}
                      control={<Radio color={isAttachmentOrWaiver !== 'attachments' ? 'primary' : 'gray'} />}
                      label={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: isAttachmentOrWaiver === 'attachments' ? 'gray' : 'black',
                          }}
                        >
                          <Grid container>
                            <Grid item>
                              <StyledAttachTermsButton
                                invert
                                mobileView={mobileView}
                                type="button"
                                style={{
                                  minWidth: '0px',
                                  paddingLeft: '4px',
                                  color: isAttachmentOrWaiver !== 'customwaiver' ? 'gray' : 'black',
                                  fontSize: mobileView ? '15px' : '',
                                  cursor: 'default',
                                  // textDecoration: isAttachmentOrWaiver === 'customwaiver' ? 'underline' : '',
                                }}
                                // onClick={() => {
                                //   if (isAttachmentOrWaiver === 'customwaiver') {
                                //     if (contribution?.customWaiverId !== null) {
                                //       const tempWaiverData = waiverData.templatesList.find(
                                //         waiver => waiver.formName == contribution?.customWaiverTemplateName,
                                //       );
                                //       if (tempWaiverData) {
                                //         setWaiverData({
                                //           ...waiverData,
                                //           formName: tempWaiverData?.formName,
                                //           formDescription: tempWaiverData?.formDescription,
                                //           formText: tempWaiverData?.formText,
                                //           description: tempWaiverData?.formDescription,
                                //           templateId: tempWaiverData.id,
                                //           id: tempWaiverData.id,
                                //           completeDescription: tempWaiverData?.formText,
                                //         });
                                //       }
                                //     }
                                //     setShowCustomWaiverModal(true);
                                //   }
                                // }}
                                disableRipple
                                component="span"
                              >
                                <img
                                  src={WritingPencil}
                                  // className="mr-3"
                                  style={{
                                    paddingLeft: 0,
                                    opacity: isAttachmentOrWaiver !== 'customwaiver' ? 0.5 : 1,
                                    marginRight: '11px',
                                  }}
                                  alt="pencil"
                                />
                                {'Use Custom Contract'}
                              </StyledAttachTermsButton>
                            </Grid>
                            {!breakPointForCustomWavier && (
                              <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} item>
                                <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                  style={{ marginTop: 0 }}
                                >
                                  {isAttachmentOrWaiver === 'customwaiver' && (
                                    <>
                                      <Button
                                        // disabled={isPartnerCoach}
                                        onClick={() => {
                                          if (contribution?.customWaiverId !== null) {
                                            getSingleContract(contribution.id)
                                              .then(res => {
                                                setWaiverData({
                                                  ...waiverData,
                                                  formName: res?.formName,
                                                  formDescription: res?.formDescription,
                                                  formText: res?.formText,
                                                  description: res?.formDescription,
                                                  templateId: res.id,
                                                  id: res.id,
                                                  completeDescription: res?.formText,
                                                });
                                                setWaiverBoby(res?.formText);
                                                setdataToBeEvaluatedwithChanges(res);
                                              })
                                              .then(() => {
                                                setShowCustomWaiverModal(true);
                                              });
                                          } else {
                                            setShowCustomWaiverModal(true);
                                          }
                                          getAllContracts({ coachId: user.id }).then(res => {
                                            const tempTemplateOptions = res.map(option => {
                                              return {
                                                title: option.formName,
                                                formDescription: option.formDescription,
                                                value: option.formName,
                                                id: option.id,
                                                formName: option.formName,
                                                completeDescription: option.formText,
                                              };
                                            });
                                            setallContractsFetched(tempTemplateOptions);
                                          });
                                        }}
                                        style={{ fontSize: '14px', fontWeight: '900', width: '252px' }}
                                        autoWidth
                                      >
                                        {changeButtonStatus === false
                                          ? 'Create Custom Contract'
                                          : 'Update Custom Contract'}
                                      </Button>
                                    </>
                                  )}
                                </FormControl>
                                {contribution?.customWaiverId && isAttachmentOrWaiver === 'customwaiver' && (
                                  <span>
                                    <StyledIcon
                                      path={mdiDelete}
                                      size={1}
                                      onClick={() => {
                                        setLoader(true);
                                        const contribData = {
                                          ...contribution,
                                          customWaiverId: null,
                                          customWaiverTemplateName: null,
                                          isElectronicSignatureActive: false,
                                        };
                                        setIsAttachmentOrWaiver(null);
                                        setIsElectronicSignatureActive(false);
                                        setSelectedTemplateId(null);
                                        setSelectedTemplate('None');
                                        setWaiverBoby('');
                                        onSaveEndExit(contribData).then(() => {
                                          setLoader(false);
                                        });
                                      }}
                                      isLive={!mobileView}
                                    />
                                  </span>
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      }
                    />
                    {breakPointForCustomWavier && (
                      <Grid item>
                        <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: 0 }}>
                          {isAttachmentOrWaiver === 'customwaiver' && (
                            <Button
                              // disabled={isPartnerCoach}
                              onClick={() => {
                                if (contribution?.customWaiverId !== null) {
                                  getSingleContract(contribution.id)
                                    .then(res => {
                                      setWaiverData({
                                        ...waiverData,
                                        formName: res?.formName,
                                        formDescription: res?.formDescription,
                                        formText: res?.formText,
                                        description: res?.formDescription,
                                        templateId: res.id,
                                        id: res.id,
                                        completeDescription: res?.formText,
                                      });
                                      setWaiverBoby(res?.formText);
                                    })
                                    .then(() => {
                                      setShowCustomWaiverModal(true);
                                    })
                                    .catch(() => {
                                      setchangeButtonStatus(false);
                                    });
                                } else {
                                  setShowCustomWaiverModal(true);
                                }
                              }}
                              style={{ fontSize: '14px', fontWeight: '900', width: '252px' }}
                              autoWidth
                            >
                              {changeButtonStatus === false ? 'Create Custom Contract' : 'Update Custom Contract'}
                            </Button>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                  </div>
                </RadioGroup> */}

                {/* </StyledCustomTerms> */}
                {/* <StyleLabelSignature mobileView={mobileView}>
                  <Checkbox
                    // disabled={isPartnerCoach}
                    id="create-contribution-agreement"
                    color="primary"
                    checked={isElectronicSignatureActive}
                    onChange={e => {
                      if (isAttachmentOrWaiver === 'customwaiver' && isElectronicSignatureActive === true) {
                        setIsAttachmentOrWaiver(null);
                        setIsElectronicSignatureActive(e.target.checked);
                        setIsCustomWaiverChecked(false);
                        setCustomWaiverId(null);
                        setSelectedTemplateId(null);
                        setSelectedTemplate(null);
                      } else {
                        setIsElectronicSignatureActive(e.target.checked);
                      }

                      // setIsCustomWaiverChecked(e.target.checked);
                    }}
                  />
                  Require electronic signature from your clients
                </StyleLabelSignature>
                <StyleLabelSignature mobileView={mobileView}>
                  <Checkbox
                    id="create-contribution-agreement"
                    color="primary"
                    checked={thankYouPageButton}
                    onChange={e => {
                      setIsThankyouPageActive(e.target.checked);
                      setThankYouPageButton(e.target.checked);
                    }}
                  />
                  Enable thank you page
                </StyleLabelSignature> */}
                {/* {isThankyouPageActive && (
                  <Button
                    onClick={() => {
                      setshowThankYouPage(true);
                      dispatch(contributionActions.setShowThankYouPage(true));
                    }}
                    style={{ fontSize: '14px', fontWeight: '900', width: '240px' }}
                    autoWidth
                  >
                    {!contribution?.thankyouPage || contribution?.isThankyouPageAdded === false
                      ? `Create Thank You Page`
                      : `Edit Thank You Page`}
                  </Button>
                )} */}
                {isRequiredMissing && (
                  <CommonErrorMessage
                    message={
                      isAttachmentOrWaiver === 'customwaiver'
                        ? 'Please create custom contract to proceed.'
                        : 'Please attach your terms to proceed.'
                    }
                  />
                )}
                {error?.Error?.ExceptionName == 'ZoomException' &&
                  error.Error.Message ==
                    'Class Name: Microsoft.AspNetCore.Routing.EndpointRoutingMiddleware - Method Name: System.Threading.Tasks.Task Invoke(Microsoft.AspNetCore.Http.HttpContext) - OuterException: Invalid access token. \n InnerException: ' && (
                    <CommonErrorMessage
                      message={
                        'Your Zoom access token has expired.Please update your Zoom access token  by reconnecting zoom on the integrations tab.'
                      }
                    />
                  )}
                {error?.Error?.ExceptionName == 'ArgumentNullException' &&
                  error.Error.Message ==
                    "Class Name: Microsoft.AspNetCore.Routing.EndpointRoutingMiddleware - Method Name: System.Threading.Tasks.Task Invoke(Microsoft.AspNetCore.Http.HttpContext) - OuterException: Value cannot be null. (Parameter 'refreshToken') \n InnerException: " && (
                    <CommonErrorMessage
                      message={
                        'Your Zoom access token has expired.Please update your Zoom access token  by reconnecting zoom on the integrations tab.'
                      }
                    />
                  )}
                {error?.Error?.ExceptionName == 'ZoomException' &&
                  error.Error.Message !=
                    'Class Name: Microsoft.AspNetCore.Routing.EndpointRoutingMiddleware - Method Name: System.Threading.Tasks.Task Invoke(Microsoft.AspNetCore.Http.HttpContext) - OuterException: Invalid access token. \n InnerException: ' && (
                    <CommonErrorMessage
                      message={
                        'You have exceeded the daily rate limit (100) of Meeting Create/Update API requests permitted for this particular user.'
                      }
                    />
                  )}
                {error?.message && (
                  <CommonErrorMessage
                    message={
                      error?.message ===
                      'Your availability time frame windows are overlapping. Please fix this on step 3'
                        ? 'Your availability time frame windows are overlapping. Please fix this on step 3'
                        : 'Please complete all required details to submit.'
                    }
                  />
                )}
              </CardBody>
            </Card>

            <StyledButtonContainer>
              <Button
                invert
                autoWidth
                type="button"
                onClick={() => {
                  const contribData = {
                    ...contribution,
                    isElectronicSignatureActive:
                      isAttachmentOrWaiver === 'customwaiver' && changeButtonStatus === false
                        ? false
                        : isAttachmentOrWaiver === 'customwaiver' &&
                          changeButtonStatus === true &&
                          selectedTemplateId != null
                        ? true
                        : isElectronicSignatureActive,
                  };
                  backClickHandler(contribData);
                }}
              >
                Back
              </Button>
              &nbsp;&nbsp;&nbsp;
              <div>
                <HyperLink
                  ref={anchorRef}
                  href={`https://${domain}/contribution-view/${contribution?.id}/clientpreview/about`}
                  target="_blank"
                />
                <Button autoWidth clientPreviewBtn onClick={goTOClientPreviewMode} disabled={loading || !agree}>
                  Client Preview
                </Button>
                <Button autoWidth onClick={onSubmit} disabled={loading || !agree || loader}>
                  Submit
                </Button>
              </div>
            </StyledButtonContainer>
          </StyledMainSection>
        </>
      )}
      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
      {showCustomWaiverModal && (
        <CreateCustomWaiver
          isOpen={showCustomWaiverModal}
          onSubmit={onSubmitWaiverForm}
          contribution={contribution}
          waiverData={setchangeButtonStatus ? '' : waiverData}
          setCustomWaiverId={setCustomWaiverId}
          setSelectedTemplate={setSelectedTemplate}
          settemplateChanged={settemplateChanged}
          onCancel={() => onCancelCustomWaiver()}
          waiverBoby={setchangeButtonStatus ? '' : waiverBoby}
          allContractsFetched={allContractsFetched}
          isCoachorPartner={true}
        />
      )}
      {showAlertModal && (
        <Modal
          isOpen={showAlertModal}
          hiddenCancel={true}
          onCancel={() => {
            setShowAlertModal(false);
          }}
          brandingColor={false}
          isCreatingContribution={true}
          onSubmit={() => {
            setShowAlertModal(false);
          }}
          title="Alert"
          submitTitle="Ok"
        >
          <Typography>Please update template name to continue.</Typography>
        </Modal>
      )}
      {confirmationPopup && (
        <Modal
          isOpen={confirmationPopup}
          onCancel={() => {
            setConfirmationPopup(false);
          }}
          brandingColor={false}
          isCreatingContribution={true}
          isBackButton={true}
          onBack={() => {
            setConfirmationPopup(false);
            setTimeout(() => {}, 0);
          }}
          onSubmit={() => {
            onSubmitConfirmation();
          }}
          title="Confirmation"
          submitTitle="Yes"
          cancelTitle="No"
        >
          Do you really want to make changes to {tempData.formName}?
        </Modal>
      )}
      {confirmationFirstPopup && (
        <Modal
          isOpen={confirmationFirstPopup}
          onCancel={() => {
            setConfirmationFirstPopup(false);
          }}
          brandingColor={false}
          isCreatingContribution={true}
          isBackButton={true}
          onBack={() => {
            setConfirmationFirstPopup(false);
            setTimeout(() => {
              setShowCustomWaiverModal(!showCustomWaiverModal);
            }, 0);
          }}
          onSubmit={() => {
            onSubmitFirstConfirmation();
          }}
          title="Confirmation"
          submitTitle="Yes"
          cancelTitle="No"
        >
          Do you really want to make changes to {tempData.formName}?
        </Modal>
      )}

      <Modal
        isOpen={connectStripeModal}
        onCancel={handleConnectStripeModalClose}
        brandingColor={false}
        onSubmit={() => {
          contribution?.paymentType === 'Advance' ? onVerificate() : handleConnectStripeModalSubmit();
        }}
        hiddenCancel
        title="Account Verification Required"
        submitTitle="Verify"
        isCreatingContribution={true}
      >
        Please verify your information for your Contribution to be approved.
      </Modal>
      <Modal
        title=""
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
        disableFooter
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
          <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
          <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
            You are fully set up & ready to transform lives!
          </div>
          <Button
            autoWidth
            backgroundColor={'#C9B382'}
            style={{
              marginTop: '40px',
              paddingTop: '10px',
              paddingBottom: '10px',
              marginBottom: '10px',
            }}
            onClick={togglePopup}
          >
            Let’s do this!
          </Button>
        </div>
      </Modal>
      <Modal
        title="Upgrade Your Account"
        isOpen={showModalPlan}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopupPlan}
        onCancel={togglePopupPlan}
        isCreatingContribution={true}
      >
        Please upgrade your account to create a service.
      </Modal>
    </MainContainer>
  );
}

ReviewAndSubmit.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
  saveContribution: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

ReviewAndSubmit.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account, contributions }) => ({
  user: account?.user,
  error: contributions?.error,
  loading: contributions?.loading,
  contribution: contributions.activeContribution,
});

const actions = {
  saveContributionToLS: contributionActions.saveDraftContribution,
  saveContribution: contributionActions.saveContribution,
};

export default connect(mapStateToProps, actions)(ReviewAndSubmit);
