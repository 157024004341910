import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useRef, useCallback } from 'react';
import classes from './CustomBrandingModal.module.scss';
import {
  StyledHeading,
  StyledIconSection,
  StyledRow,
  StyledTooltipWrapModal,
  StyledTooltipModal,
  Container,
  InputHidden,
  EmptyIconContainer,
  StyledColorSection,
  StyledColorSelector,
  StyledColorPicker,
  StyledColorCodeWrapper,
} from 'pages/ContributionView/ContributionCommunication/ContributionCommunication';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP } from '../../../../../constants';
import ButtonExample from 'pages/Profile/ButtonExample';
import TextField from 'components/UI/TextField';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Select from 'components/FormUI/Select';
import { Formik } from 'formik';
import styled from 'styled-components';
import { dataURLtoFile } from 'utils/utils';
import { useHttp } from 'hooks/index';

const IconContainer = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  border: solid 1px #dadada;
`;

const CustomBrandingModal = ({ campaign }) => {
  const IconUpload = useRef();
  const theme = useTheme();
  const { request, loading } = useHttp();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const onClickHandler = () => {
    if (IconUpload) {
      IconUpload.current.click();
    }
  };

  const handleIconChange = useCallback((e, setFieldValue) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        const formData = new FormData();
        // setFieldValue('customLogo', reader.result);
        formData.append('file', dataURLtoFile(reader.result));
        request('/content/addpublicimage', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        })
          .then(image => {
            setFieldValue('customLogo', image);
          })
          .catch(console.dir)
          .finally(() => {
            // onClose();
            // setUploading(false);
          });
      };
      reader.readAsDataURL(file);
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          customLogo: campaign?.brandingColors?.PrimaryColorCode || '',
          isDarkModeEnabled: campaign?.brandingColors?.PrimaryColorCode || false,
          isCustomBrandingColorsActive: false,
          PrimaryColorCode: campaign?.brandingColors?.PrimaryColorCode || '#000000',
          AccentColorCode: campaign?.brandingColors?.AccentColorCode || '#000000',
          TertiaryColorCode: campaign?.brandingColors?.TertiaryColorCode || '#000000',
          TextColorCode: campaign?.brandingColors?.TextColorCode || 'Auto',
        }}
        // validationSchema={{}}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue, handleBlur }) => {
          return (
            <div>
              <Grid container item style={{ marginBottom: '50px', width: mobileView ? 'auto' : '500px' }}>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7', height: '120px' }}>
                  <div className={classes.heading}>
                    <p>Brand Elements</p>
                  </div>
                  <div>
                    <p>Cohere allows you to adjust your custom branding colors</p>
                  </div>
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7' }}>
                  <StyledIconSection>
                    <StyledRow>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Logo</StyledHeading>
                        <StyledTooltipModal
                          title="This is the logo that will appear on your branded landing pages on Cohere."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <Container onClick={onClickHandler}>
                        {values?.customLogo ? (
                          <IconContainer>
                            <Image src={values.customLogo} />
                          </IconContainer>
                        ) : (
                          <EmptyIconContainer>{loading ? '...' : '+'}</EmptyIconContainer>
                        )}
                      </Container>
                    </StyledRow>
                  </StyledIconSection>
                  <InputHidden
                    ref={IconUpload}
                    onChange={e => {
                      handleIconChange(e, setFieldValue);
                      setFieldValue('isCustomBrandingColorsActive', true);
                    }}
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7' }}>
                  <StyledIconSection>
                    <StyledRow>
                      <p style={{ margin: 'auto 0' }}>Choose Style Mode</p>
                      <ToggleButtonGroup
                        className="toggle-button"
                        color="primary"
                        value={values.isDarkModeEnabled ? 'dark' : 'light'}
                        exclusive
                        onChange={() => {
                          setFieldValue('isDarkModeEnabled', !values.isDarkModeEnabled);
                          setFieldValue('isCustomBrandingColorsActive', true);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="light">Light</ToggleButton>
                        <ToggleButton value="dark">Dark</ToggleButton>
                      </ToggleButtonGroup>
                    </StyledRow>
                  </StyledIconSection>
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7', marginTop: '20px' }}>
                  <StyledColorSection>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Primary color</StyledHeading>
                        <StyledTooltipModal
                          title="This color will be associated with call-to-action (CTA) buttons on your branded pages"
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>

                      <StyledColorPicker>
                        <ButtonExample
                          colorCode={values.PrimaryColorCode}
                          onchangevalue={code => {
                            setFieldValue('PrimaryColorCode', code);
                            // setFieldValue('isLegacyColorsActive', true);
                            setFieldValue('isCustomBrandingColorsActive', true);
                            // saveColor({
                            //   colorName: 'PrimaryColorCode',
                            //   colorCode: code,
                            //   formValues: values,
                            // });
                          }}
                        />
                        {/* <StyledColor colorCode={values.PrimaryColorCode} onClick={funTest} /> */}
                        <StyledColorCodeWrapper>
                          <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                          <div style={{ width: '85%' }}>
                            <TextField
                              name="PrimaryColorCode"
                              value={values?.PrimaryColorCode?.substring(1)}
                              type="text"
                              onChange={e => {
                                const colorCode = '#' + e.target.value;
                                setFieldValue('PrimaryColorCode', colorCode);
                                // formProps.setFieldValue('isLegacyColorsActive', true);
                                setFieldValue('isCustomBrandingColorsActive', true);
                                if (colorCode.length == 7) {
                                  // saveColor({
                                  //   colorName: 'PrimaryColorCode',
                                  //   colorCode: colorCode,
                                  //   // formValues: values,
                                  // });
                                }
                              }}
                              onBlur={handleBlur}
                              fullWidth
                            />
                          </div>
                        </StyledColorCodeWrapper>
                      </StyledColorPicker>
                    </StyledColorSelector>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Accent color</StyledHeading>
                        <StyledTooltipModal
                          title="The color will be associated with various tabs, labels, and misc. components on your branded pages."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <StyledColorPicker>
                        <ButtonExample
                          colorCode={values?.AccentColorCode}
                          onchangevalue={code => {
                            setFieldValue('AccentColorCode', code);
                            // formProps.setFieldValue('isLegacyColorsActive', true);
                            setFieldValue('isCustomBrandingColorsActive', true);
                            // saveColor({
                            //   colorName: 'AccentColorCode',
                            //   colorCode: code,
                            //   // formValues: values,
                            // });
                          }}
                        />
                        <StyledColorCodeWrapper>
                          <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                          <div style={{ width: '85%' }}>
                            <TextField
                              name="AccentColorCode"
                              value={values?.AccentColorCode?.substring(1)}
                              type="text"
                              onChange={e => {
                                // formProps.setFieldValue('isLegacyColorsActive', true);
                                setFieldValue('isCustomBrandingColorsActive', true);
                                const colorCode = '#' + e.target.value;
                                setFieldValue('AccentColorCode', colorCode);

                                if (colorCode.length == 7) {
                                  // saveColor({
                                  //   colorName: 'AccentColorCode',
                                  //   colorCode: colorCode,
                                  //   // formValues: values,
                                  // });
                                }
                              }}
                              onBlur={handleBlur}
                              fullWidth
                            />
                          </div>
                        </StyledColorCodeWrapper>
                      </StyledColorPicker>
                    </StyledColorSelector>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Tertiary color</StyledHeading>
                        <StyledTooltipModal
                          title="This color is only used if you have more than two categories on your Contribution."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <StyledColorPicker>
                        <ButtonExample
                          colorCode={values?.TertiaryColorCode}
                          onchangevalue={code => {
                            // setFieldValue('isLegacyColorsActive', true);
                            setFieldValue('TertiaryColorCode', code);

                            // saveColor({
                            //   colorName: 'TertiaryColorCode',
                            //   colorCode: code,
                            //   // formValues: values,
                            // });
                          }}
                        />
                        {/* <StyledColor colorCode={values.TertiaryColorCode} /> */}
                        <StyledColorCodeWrapper>
                          <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                          <div style={{ width: '85%' }}>
                            <TextField
                              value={values?.TertiaryColorCode?.substring(1)}
                              name="TertiaryColorCode"
                              type="text"
                              onChange={e => {
                                // formProps.setFieldValue('isLegacyColorsActive', true);
                                const colorCode = '#' + e.target.value;
                                setFieldValue('TertiaryColorCode', colorCode);

                                if (colorCode.length == 7) {
                                  // saveColor({
                                  //   colorName: 'TertiaryColorCode',
                                  //   colorCode: colorCode,
                                  //   // formValues: values,
                                  // });
                                }
                              }}
                              onBlur={handleBlur}
                              fullWidth
                            />
                          </div>
                        </StyledColorCodeWrapper>
                      </StyledColorPicker>
                    </StyledColorSelector>
                  </StyledColorSection>
                  <StyledColorSelector>
                    <StyledTooltipWrapModal>
                      <StyledHeading>Text color</StyledHeading>
                      <StyledTooltipModal
                        title="This color is used show text color on your Contribution."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor="#47627B" />
                      </StyledTooltipModal>
                    </StyledTooltipWrapModal>
                    <Select
                      className={classes.color_select}
                      label=""
                      name="TextColorCode"
                      fullWidth
                      items={[
                        { title: 'Black', value: '#000000' },
                        { title: 'White', value: '#FFFFFF' },
                        { title: 'Auto', value: 'Auto' },
                      ]}
                      value={values?.TextColorCode}
                      onChange={e => {
                        setFieldValue('TextColorCode', e.target.value);
                        // formProps.setFieldValue('isLegacyColorsActive', true);
                        setFieldValue('isCustomBrandingColorsActive', true);
                      }}
                    />
                  </StyledColorSelector>
                </Grid>
              </Grid>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default CustomBrandingModal;
