import React, { useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AddBankAccountPopup from '../../../pages/Account/Payment/components/BankAccountCard/AddBankAccountPopup';
import { useHttp, useAccount } from 'hooks';
import { useTheme, useMediaQuery, Tooltip, TextField } from '@material-ui/core';
import * as userActions from 'actions/user';
import InfoIcon from '@material-ui/icons/Info';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import '../Banner.scss';
import { getProfile, editProfile } from 'actions/user';
import Modal from 'components/UI/Modal';
import { makeStyles } from '@material-ui/core';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;

const useStyles = makeStyles(() => ({
  email_main_container: {
    width: '100%',
  },

  email_main: {
    width: '100%',
  },

  email_root: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },

  email_notchedOutline: {
    border: 'none',
  },
}));

const AddressBanner = () => {
  const { request } = useHttp();
  const { user } = useAccount();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [physicalAddress, setPhysicalAddress] = useState('');
  const BannerButton = mobileView ? StyledButton : Button;
  const classes = useStyles();

  const checkOnClick = () => {
    setIsAddressModalOpen(true);
  };

  const handlePhysicalAddressChange = e => {
    const input = e.target.value;
    setPhysicalAddress(e.target.value);
  };

  return (
    <>
      <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
        <p className="banner-container__text">
          Please add your physical address to proceed with sending email campaigns.
          <span>
            <Tooltip
              title="Businesses must include a physical address in the footer of all email campaigns, as required by laws such as the CAN-SPAM Act in the United States. These laws aim to prevent the sending of unsolicited, fraudulent, or deceptive emails, promoting transparency and reducing spam."
              arrow
            >
              <InfoIcon style={{ marginLeft: '10px', marginBottom: '3px' }} htmlColor="" />
            </Tooltip>
          </span>
        </p>
        <div>
          <BannerButton variant="primary" className="banner-container__button" autoWidth onClick={checkOnClick}>
            Add Address
          </BannerButton>
        </div>
      </div>
      <Modal
        isOpen={isAddressModalOpen}
        title={
          <span>
            Add your physical address{' '}
            <Tooltip
              title="Businesses must include a physical address in the footer of all email campaigns, as required by laws such as the CAN-SPAM Act in the United States. These laws aim to prevent the sending of unsolicited, fraudulent, or deceptive emails, promoting transparency and reducing spam."
              arrow
            >
              <InfoIcon style={{ marginLeft: '8px', marginBottom: '3px' }} htmlColor="#C9B382" />
            </Tooltip>
          </span>
        }
        disableSubmitClick={physicalAddress?.length === 0}
        submitTitle="Done"
        onSubmit={() => {
          if (physicalAddress?.length > 0) {
            const data = {
              ...user,
              address: physicalAddress,
            };
            dispatch(editProfile(user?.id, data));
            dispatch(getProfile(user?.id));
            setIsAddressModalOpen(false);
          }
        }}
        onCancel={() => {
          setPhysicalAddress('');
          setIsAddressModalOpen(false);
        }}
        hiddenCancel
        widthRequired
      >
        <div className={classes.email_main_container}>
          <TextField
            className={classes.email_main}
            placeholder="Please enter your physical address"
            variant="outlined"
            value={physicalAddress}
            onChange={handlePhysicalAddressChange}
            InputProps={{
              classes: {
                root: classes.email_root,
                notchedOutline: classes.email_notchedOutline,
              },
            }}
          />
        </div>
      </Modal>
    </>
  );
};

AddressBanner.propTypes = {
  userId: PropTypes.string,
  getUserProfile: PropTypes.func.isRequired,
};

AddressBanner.defaultProps = {
  userId: null,
};

const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
});

const actions = {
  getUserProfile: userActions.getProfile,
};

export default connect(mapStateToProps, actions)(AddressBanner);
