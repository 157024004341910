import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Form } from 'formik';
import Tooltip from '@material-ui/core/Tooltip';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { colors } from 'utils/styles';
import { useShallowEqualSelector } from 'hooks';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { LiveVideoProvider } from 'constants.js';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import InfoIcon from '@material-ui/icons/Info';
import { ContributionType } from 'helpers/constants';
import usePaidTier from 'hooks/usePaidTier';
import { TOOLTIP } from '../../../constants';
import Sessions from './MembershipForm/components/Sessions';
import LiveVideo from './MembershipForm/components/LiveVideo';
import EnrollmentDates from './MembershipForm/components/EnrollmentDates';
import { useDispatch } from 'react-redux';
import { setCohereAcademyStatus } from 'actions/update-user';
import { PAID_TIER_TITLES } from '../../../constants';
import * as paidTier from 'selectors/paidTier';
const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const initialLiveVideProviders = [
  LiveVideoProvider.Cohere,
  LiveVideoProvider.Custom,
  LiveVideoProvider.InPersonSession,
];

const CommunityForm = ({ mysession, values, errors, touched, setFieldValue }) => {
  const [liveVideoProviders, setLiveVideoProviders] = useState(initialLiveVideProviders);
  const user = useShallowEqualSelector(state => state?.account?.user);
  const dispatch = useDispatch();
  // const { isLaunchPlan } = usePaidTier();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const Itemcallback = useCallback(data => {
    mysession(data);
  }, []);

  useEffect(() => {
    if (user?.isZoomEnabled) {
      setLiveVideoProviders(prevState => [...prevState, LiveVideoProvider.Zoom]);
    }
    if (isLaunchPlan) {
      setFieldValue('liveVideoServiceProvider.providerName', LiveVideoProvider.Custom.value);
    }
  }, [user.isZoomEnabled]);
  return (
    <Form id="create-contribution-form">
      <Grid container spacing={4}>
        <EnrollmentDates
          // removeCalendar={removeCalendar}
          setFieldValue={setFieldValue}
          values={values}
          enrollment={values.enrollment}
        />
        <Sessions
          values={values}
          providerName={values.liveVideoServiceProvider.providerName}
          sessions={values.sessions}
          mysession={Itemcallback}
          errors={errors}
          touched={touched}
          type={ContributionType.contributionCommunity}
          setFieldValue={setFieldValue}
        />

        <LiveVideo
          values={values}
          liveVideoServiceProvider={values.liveVideoServiceProvider}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </Form>
  );
};

CommunityForm.propTypes = {
  values: PropTypes.shape({
    sessions: PropTypes.arrayOf(PropTypes.shape({})),
    liveVideoServiceProvider: PropTypes.shape({
      providerName: PropTypes.string,
      customLink: PropTypes.string,
    }),
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CommunityForm;
