import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import humanizeDuration from 'humanize-duration';

import { isEmptyOrNil, lightOrDark } from 'utils/utils';
import {
  StyledPdfContainer,
  StyledPlayIconWrapper,
  StyledPdfIcon,
  StyledTextContainer,
  StyledVideoContainer,
  StyledDownloadIconContainer,
  StyledDownloadVideo,
  StyledVideoTextedContainer,
  StyledDurationContainer,
  StyledDurationText,
} from './SharedStyledComponents';
import { AudioIcon, PdfIcon, DownloadIcon, GoToIcon } from 'pages/CreateLeadMagnet/components/Icon';
import UploadThumbnailIcon from 'pages/CreateContribution/components/UploadThumbnailIcon';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import { PlayIcon } from 'pages/ContributionView/ContributionSessions/component/icons';
import useScreenView from 'hooks/useScreenView';
import { millisecondsToDuration } from 'utils/datesAndMoney';
import useVideoPlayer from 'hooks/useVideoPlayer';
import { useSelector } from 'react-redux';
import { useLeadMagnet } from '../hooks';
import { handleLeadMagnetDownload } from '../helper';
import styled, { css } from 'styled-components';

const StyledReactPlayer = styled(ReactPlayer)`
  iframe {
    ${({ iframeBorderRadius }) =>
      iframeBorderRadius &&
      css`
        border-radius: ${iframeBorderRadius};
      `}
  }
`;

const DownloadContents = ({ type, content }) => {
  const [showVideo, setShowVideo] = useState();
  const [timeStamp, setTimeStamp] = useState(0);
  const [contentLink, setContentLink] = useState(null);
  const { colorToUse, themedColors } = useLeadMagnetTheme();
  const { mobileView } = useScreenView();
  const { generatePresignedUrlForRecordedLeadMagnet } = useVideoPlayer({});
  const { id, previewContentUrls } = useLeadMagnet();
  const userEmail = useSelector(state => state?.account?.user?.email) || 'pikachu7@yopmail.com';
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const { documentOriginalNameWithExtension: fileName } = content;
  const [displayedFileName, setDisplayedFileName] = useState(fileName);
  const leadMagnet = useLeadMagnet();
  const {
    themedCardBackgroundColor,
    themedColor,
    newThemedTextColor,
    newThemedBackgroundColor,
    themedBackgroundColor,
  } = themedColors;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const onPlay = e => {
    const video = videoRef.current;
    video.currentTime = timeStamp;
    video.play();
    // if (
    //   !isCohealer // && !sessionTime?.usersWhoViewedRecording.includes(user?.id)
    // ) {
    //   setVideoAsWatched().then(() => refetch(true));
    // }
  };

  const onPause = e => {
    const video = videoRef.current;
    video.pause();
    setTimeStamp(video.duration && video.currentTime === video.duration ? 0 : video.currentTime);
    video.load();
  };

  // function formatDuration(durationInSeconds) {
  //   const hours = Math.floor(durationInSeconds / 3600);
  //   const minutes = Math.floor((durationInSeconds % 3600) / 60);
  //   const seconds = Math.floor(durationInSeconds % 60);

  //   const formattedHours = String(hours).padStart(2, '0');
  //   const formattedMinutes = String(minutes).padStart(2, '0');
  //   const formattedSeconds = String(seconds).padStart(2, '0');

  //   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  // }

  useEffect(() => {
    if (userEmail) {
      generatePresignedUrlForRecordedLeadMagnet({
        leadMagnetId: id,
        preRecordedDataId: content?.id,
        userEmail: userEmail,
      }).then(({ contentUrl }) => {
        setContentLink(contentUrl);
      });
    }
  }, []);
  useEffect(() => {
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const charsPerLine = containerWidth / 12; // Approximate width of each character
      const halfPerLine = charsPerLine / 2;
      if (fileNameWithoutExtension.length > charsPerLine) {
        // If text exceeds two lines
        const truncatedText1 = fileNameWithoutExtension.substring(0, charsPerLine);
        const truncatedText2 = fileNameWithoutExtension.substring(charsPerLine, charsPerLine + halfPerLine);
        const fullName =
          truncatedText1 +
          truncatedText2 +
          '...' +
          fileNameWithoutExtension.slice(-1) +
          content.extension?.toLowerCase();
        setDisplayedFileName(fullName);
      } else {
        // setDisplayedFileName(fullName);
      }
    }
  }, [fileName]);

  const handleDownload = () => {
    handleLeadMagnetDownload(contentLink, content?.documentOriginalNameWithExtension);
  };

  if (!isEmptyOrNil(content.embeddedVideoUrl)) {
    return mobileView ? (
      <>
        <div className="position-relative w-100">
          <StyledDownloadIconContainer
            isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
            style={{ borderRadius: '0.25rem' }}
          >
            <a href={content?.embeddedVideoUrl} target="_blank" rel="noreferrer">
              <GoToIcon style={{ width: '18px', height: '18px' }} color={colorToUse.AccentColorCode} />
            </a>
          </StyledDownloadIconContainer>
          <StyledVideoContainer
            mobileView
            bgColor={themedBackgroundColor}
            isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
          >
            <StyledReactPlayer
              url={content.embeddedVideoUrl}
              height={'100%'}
              width={'100%'}
              iframeBorderRadius="0.375rem"
              // style={{ borderRadius: '0.25rem' }}
              controls={true}
              playing={false}
            />
            {/* <StyledVideoTextedContainer mobileView bgColor={themedCardBackgroundColor}>
              <StyledTextContainer mobileView bgColor={themedCardBackgroundColor}>
                {content?.documentOriginalNameWithExtension}
              </StyledTextContainer>
            </StyledVideoTextedContainer> */}
          </StyledVideoContainer>
        </div>
      </>
    ) : (
      <div className="position-relative w-100">
        <StyledDownloadIconContainer
          isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
          style={{ height: '2.25rem', width: '2.25rem', borderRadius: '0.25rem' }}
        >
          <a href={content?.embeddedVideoUrl} target="_blank" rel="noreferrer">
            <GoToIcon style={{ width: '18px', height: '18px' }} color={colorToUse.AccentColorCode} />
          </a>
        </StyledDownloadIconContainer>
        <StyledVideoContainer bgColor={themedBackgroundColor} isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}>
          <StyledReactPlayer
            url={content?.embeddedVideoUrl}
            height={'100%'}
            width={'100%'}
            controls={true}
            playing={false}
            iframeBorderRadius="0.375rem"
            // style={{ borderRadius: '0.25rem' }}
          />
          {/* <StyledVideoTextedContainer bgColor={themedCardBackgroundColor}>
            <StyledTextContainer bgColor={themedCardBackgroundColor}>
              {content?.documentOriginalNameWithExtension}
            </StyledTextContainer>
            <GoToIcon style={{ width: '18px', height: '18px', color: colorToUse.AccentColorCode }} />
          </StyledVideoTextedContainer> */}
        </StyledVideoContainer>
      </div>
    );
  }
  if (type === 'video')
    return mobileView ? (
      <div className="position-relative w-100">
        <StyledDownloadIconContainer
          isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
          role="button"
          onClick={handleDownload}
        >
          <DownloadIcon style={{ width: '18px', height: '18px' }} color={colorToUse.AccentColorCode} />
        </StyledDownloadIconContainer>
        <StyledDurationContainer {...{ mobileView }} style={{ bottom: mobileView ? '25px' : '' }}>
          <StyledDurationText>{millisecondsToDuration(Number(content?.duration))}</StyledDurationText>
        </StyledDurationContainer>
        <StyledVideoContainer
          mobileView
          bgColor={themedBackgroundColor}
          isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
          style={{ height: '9rem' }}
        >
          {showVideo && contentLink ? (
            <StyledDownloadVideo
              ref={videoRef}
              controls
              preload="none"
              poster={content?.thumbnailUrl || previewContentUrls?.at(0) || ''}
              onPlay={onPlay}
              onPause={onPause}
              mobileView={mobileView}
              bgColor={newThemedBackgroundColor}
              style={{ height: '9rem', borderRadius: '0.375rem' }}
            >
              <source src={contentLink} />
            </StyledDownloadVideo>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '9.6875rem',
                backgroundColor: 'black',
                // backgroundColor: isDarkModeEnabled ? 'black' : 'white',
                backgroundColor: 'transparent',
                width: '100%',
                borderRadius: '0.375rem',
                overflow: 'hidden',
              }}
              onClick={() => {
                setShowVideo(true);
                if (contentLink) {
                  setTimeout(() => {
                    onPlay();
                  }, [2000]);
                }
              }}
            >
              <img
                src={content?.thumbnailUrl || previewContentUrls?.at(0) || ''}
                style={{
                  height: mobileView ? '9.6875rem' : '39.9366rem',
                  maxWidth: '100%',
                  borderRadius: '0.375rem 0.375rem 0 0',
                  overflow: 'hidden',
                }}
              />
              <StyledPlayIconWrapper bgColor={colorToUse?.AccentColorCode}>
                <PlayIcon
                  style={{
                    height: '24.152px',
                    width: '24.152px',
                    color: '#FFF',
                    position: 'absolute',
                    zIndex: 15,
                    cursor: 'pointer',
                  }}
                />
              </StyledPlayIconWrapper>
            </div>
          )}
        </StyledVideoContainer>
      </div>
    ) : (
      <StyledVideoContainer
        bgColor={themedBackgroundColor}
        isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
        style={{ height: '40.1rem', position: 'relative', marginBottom: mobileView ? '' : '2rem' }}
      >
        <StyledDownloadIconContainer
          isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
          style={{ height: '2.25rem', width: '2.25rem', borderRadius: '0.25rem' }}
        >
          <div role="button" onClick={handleDownload}>
            <DownloadIcon style={{ width: '1.5rem', height: '1.5rem' }} color={colorToUse.AccentColorCode} />
          </div>
        </StyledDownloadIconContainer>
        {showVideo && contentLink ? (
          <>
            <StyledDownloadVideo
              ref={videoRef}
              controls
              preload="none"
              poster={content?.thumbnailUrl || previewContentUrls?.at(0) || ''}
              onPlay={onPlay}
              onPause={onPause}
              mobileView={mobileView}
              bgColor={newThemedBackgroundColor}
              style={{ height: '40.1rem', borderRadius: '0.375rem' }}
            >
              <source src={contentLink} />
            </StyledDownloadVideo>
          </>
        ) : (
          <div className="position-relative w-100">
            <StyledDurationContainer>
              <StyledDurationText>{millisecondsToDuration(Number(content?.duration))}</StyledDurationText>
            </StyledDurationContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '39.9366rem',
                backgroundColor: 'black',
                width: '100%',
                borderRadius: '0.375rem',
                overflow: 'hidden',
              }}
              onClick={() => {
                setShowVideo(true);
                if (contentLink) {
                  setTimeout(() => {
                    onPlay();
                  }, [2000]);
                }
              }}
            >
              <img
                src={content?.thumbnailUrl || previewContentUrls?.at(0) || ''}
                style={{
                  height: mobileView ? 'auto' : '39.9366rem',
                  maxWidth: '100%',
                  borderRadius: '0.375rem 0.375rem 0 0',
                  overflow: 'hidden',
                }}
              />
              <StyledPlayIconWrapper bgColor={colorToUse?.AccentColorCode}>
                <PlayIcon
                  style={{
                    height: '24.152px',
                    width: '24.152px',
                    color: '#FFF',
                    position: 'absolute',
                    zIndex: 15,
                    cursor: 'pointer',
                  }}
                />
              </StyledPlayIconWrapper>
            </div>
          </div>
        )}
      </StyledVideoContainer>
    );
  return (
    <div className="position-relative">
      <StyledDownloadIconContainer
        isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
        role="button"
        onClick={handleDownload}
      >
        {/* TODO: this icon will change to download icon */}
        <DownloadIcon style={{ width: '18px', height: '18px' }} color={colorToUse.AccentColorCode} />
      </StyledDownloadIconContainer>
      <StyledPdfContainer isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}>
        <StyledPdfIcon
          style={{
            backgroundColor: leadMagnet?.isDarkModeEnabled ? '#2D2F31' : '#fafafa',
            borderRadius: '0.25rem 0.25rem 0 0',
          }}
        >
          {content?.extension?.toLowerCase()?.includes('pdf') ? (
            <PdfIcon
              style={{
                width: '6.5625rem',
                height: '6.5625rem',
                // color: colorToUse.AccentColorCode,
              }}
            />
          ) : (
            <AudioIcon
              style={{
                width: '6.5625rem',
                height: '6.5625rem',
                // color: colorToUse.AccentColorCode,
              }}
            />
          )}
        </StyledPdfIcon>
        <StyledTextContainer
          ref={containerRef}
          style={{
            wordWrap: 'break-word',
            height: 'auto !important',
            textAlign: 'center',
            borderRadius: '0 0 0.25rem 0.25rem',
          }}
          bgColor={themedCardBackgroundColor}
          isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
        >
          {displayedFileName}
        </StyledTextContainer>
      </StyledPdfContainer>
    </div>
  );
};

export default DownloadContents;
