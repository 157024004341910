import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { FieldArray, Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import orderBy from 'lodash/orderBy';
import * as R from 'ramda';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRouter } from 'hooks';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme, useMediaQuery, makeStyles, CircularProgress } from '@material-ui/core';
import Select from '@mui/material/Select';
import InfoIcon from '@material-ui/icons/Info';
import Session from '../../Session';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { toRem, colors } from 'utils/styles';
import { useShallowEqualSelector } from 'hooks';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { getNextCurrentSessionTime } from 'utils/getSessionTime';
import { TOOLTIP, TIMEZONES } from 'constants.js';
import { ContributionType } from 'helpers/constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import * as calendarsActions from 'actions/calendars';
import SendInvitesFromModal from 'components/Modals/AddCalendarModal/SendInvitesFromModal.jsx';
import CloseIcon from '@material-ui/icons/Close';
import FilterComponent from './FilterComponent';
import moment from 'moment';
import { useVideoDurationParse } from 'pages/CreateContribution/hooks/useVideoDurationParse';
import { ClickAwayListener } from '@material-ui/core';

const styles = makeStyles(theme => ({
  select_root: {
    border: '1px solid #dbe7f2',
    borderRadius: '4px',
    padding: '0px 10px',
  },
  button: {
    color: 'gray',
    border: '1px solid gray',
    textTransform: 'None',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid gray',
        borderColor: 'gray',
      },
      '&:hover fieldset': {
        border: '1px solid gray',
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid gray',
        borderColor: 'gray',
      },
    },
  },
  enableDownloadMain: {
    display: 'flex',
    alignItems: 'center',
  },
  enableHeading: {
    paddingTop: '14px',
  },
}));

const StyledSelect = styled(Select)`
  width: 100%;
  .MuiInputBase-root {
    margin-top: 0;
    .MuiSelect-root {
      color: ${({ color }) => color};
    }
    svg {
      color: ${({ color }) => color};
    }
  }
`;

const DropDown = styled.div`
  width: ${({ w }) => w};
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  border: 1px solid #dfe3e4;
  border-radius: 4px;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
`;

const StyledOption = styled.div`
  width: 100%;
  font-family: 'Avenir';
  font-size: ${({ mobileView }) => (mobileView ? '10px' : '16px')};
  // font-weight: 800;
  line-height: 21px;
  text-align: left;
  border-radius: 0px;
  padding: 16px 20px;
  cursor: pointer;
  border-bottom: ${({ borderBottom }) => (borderBottom ? '2px solid #e7e7e7' : '')};
  ${({ disabled }) =>
    disabled &&
    `
  cursor: default;
  color: darkgray;
  `}
  ${({ selected }) =>
    selected &&
    `
    font-weight: 800;
  `}
`;

const StyledTimeZoneLabel = styled.p`
  display: inline-block;
  font-size: ${toRem(13)};
  background: ${colors.darkOceanBlue};
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
`;

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0 !important;
`;
const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const StyledEmail = styled.span`
  white-space: nowrap;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCalendar = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => (mobileView ? '120px' : '140px')};
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 6px;
  margin-right: 2px;
`;

const StyledFullWidthFlexContainer = styled(StyledFlexContainer)`
  width: 100%;
`;

const StyledCalendarTabContainer = styled(StyledFullWidthFlexContainer)`
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  align-items: ${({ mobileView }) => (mobileView ? 'flex-start' : 'center')};
  justify-content: flex-end;
  overflow: hidden;
`;

const StyledCalendarsContainer = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => (mobileView ? '290px' : '320px')};
  justify-content: ${({ onlyChild }) => (onlyChild ? 'flex-end' : 'flex-start')};
  overflow-x: auto;
  padding: ${({ mobileView }) => (mobileView ? '10px 0' : '10px 5px')};
  margin-left: ${({ mobileView }) => (mobileView ? '0' : '10px')};
`;

const StyledButton = styled(Button)`
  margin: ${({ mobileView }) => (mobileView ? '10px 0' : '0 10px')};
  min-width: 180px;
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  ${({ mobileView }) => mobileView && `font-size: 16px`}
`;
const DROPDOWN_WITH = 140;
const DROPDOWN_WITH_MOBILE = 90;
const PARTICIPANTS_NUMBER = {
  MIN: 2,
  MAX: 50,
};

const Sessions = ({
  values,
  enrollment,
  mysession,
  sessions,
  errors,
  touched,
  providerName,
  type,
  setFieldValue,
  calendars,
  addCalendar,
}) => {
  const [sessionCount, setSessionCount] = useState(R.inc(sessions.filter(s => !s.isPrerecorded).length));
  const user = useShallowEqualSelector(state => state?.account?.user);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const sessionsCount = sessions.length - 1;
  const { history, query, params } = useRouter();
  const isEditing = Boolean(query?.id);
  const [dragging, isDragging] = useState(false);
  const [openSessionIndex, setOpenSessionIndex] = useState(isEditing ? 0 : sessions.length - 1);
  const [filteredStates, setfilteredStates] = useState({ status: 'All', type: 'All' });
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [filtering, setFiltering] = useState(true);
  const [loaderstate, setLoaderstate] = useState(false);
  const [openTypeDropDown, setOpenTypeDropDown] = useState(false);
  const [openStatusDropDown, setOpenStatusDropDown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
  const classes = styles();
  const isFirstRender = useRef(true);
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    filteredSessionsFunction();
  }, [filteredStates.status, filteredStates.type, sessions]);

  console.log('openSessionIndex>>', query?.sessionId);
  function findIndexById(array, id) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return i; // Return the index if ID matches
      }
    }
    return -1; // Return -1 if ID is not found
  }
  useEffect(() => {
    setOpenSessionIndex(sessions.length - 1);
    if (isEditing && query?.sessionId) {
      const index = findIndexById(sessions, query?.sessionId);
      setOpenSessionIndex(index);
    }
  }, [sessionsCount]);

  useEffect(() => {
    if (isEditing && query?.sessionId) {
      const index = findIndexById(sessions, query?.sessionId);
      setOpenSessionIndex(index);
      const idToScroll = query?.sessionId;
      setTimeout(() => {
        const element = document.getElementById(idToScroll);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 3000);
    }
  }, [query?.sessionId]);

  const handleEnd = r => {
    if (!r.destination) {
      return null;
    } else {
      const item = sessions.slice();
      const [order] = item.splice(r.source.index, 1);
      item.splice(r.destination.index, 0, order);
      mysession(item);
      // let itemSession;
      // itemSession = [];
      // itemSession = sessions;
      // let source = sessions[r.source.index];
      // let dest = sessions[r?.destination?.index];
      // itemSession[r.source.index] = dest;
      // itemSession[r?.destination?.index] = source;
    }
    isDragging(false);
  };
  const [isSendInvitesFromModalOpen, setIsSendInvitesFromModalOpen] = useState(false);
  const isCommunityType = type == ContributionType.contributionCommunity ? 'true' : 'false';
  const { pathname } = useRouter();
  const isCreate = pathname === '/create-contribution/sessions';

  const handleOpenSendInvitesFromModalOpen = e => {
    e.preventDefault();
    setIsSendInvitesFromModalOpen(true);
  };
  const handleCloseSendInvitesFromModalOpen = () => {
    setIsSendInvitesFromModalOpen(false);
  };

  const onRemoveCalendar = () => {
    setFieldValue('externalCalendarEmail', undefined);
  };

  const handleModalSubmit = useCallback(async () => {
    setIsSendInvitesFromModalOpen(false);
  }, []);

  function filterSessionsByStartTimeInFuture(sessions) {
    const currentDate = moment();
    return sessions.filter(item => item.sessionTimes.some(session => moment(session.startTime).isAfter(currentDate)));
  }

  function filterSessionsByStartTimeInPast(sessions) {
    const currentDate = moment();
    return sessions.filter(item => {
      if (item.sessionTimes.length > 1) {
        return item.sessionTimes.every(session => moment(session.startTime).isBefore(currentDate));
      } else {
        return item.sessionTimes.some(session => moment(session.startTime).isBefore(currentDate));
      }
    });
  }
  function findIndexById(array, id) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return i; // Return the index if found
      }
    }
    return -1; // Return -1 if not found
  }
  const filteredSessionsFunction = () => {
    console.log('sessions-------->', sessions, filteredStates);
    if (filteredStates.status === 'All' && filteredStates.type === 'All') {
      setFilteredSessions(sessions);
      setFiltering(false);
    } else if (filteredStates.type === 'All' && filteredStates.status === 'Upcoming') {
      setFiltering(true);
      const result = filterSessionsByStartTimeInFuture(sessions);
      console.log('sessions-------->1', result);
      setFilteredSessions(result);
    } else if (filteredStates.type === 'All' && filteredStates.status === 'Past') {
      setFiltering(true);
      const result = filterSessionsByStartTimeInPast(sessions);
      console.log('sessions-------->2', result);
      setFilteredSessions(result);
    } else if (filteredStates.type === 'Selfpaced' && filteredStates.status === 'All') {
      setFiltering(true);
      const filteredSelfPacedSessions = sessions.filter(s => s.isPrerecorded);
      console.log('sessions-------->3', filteredSelfPacedSessions);
      setFilteredSessions(filteredSelfPacedSessions);
    } else if (filteredStates.type === 'Selfpaced' && filteredStates.status === 'Upcoming') {
      setFiltering(true);
      const filteredSelfPacedSessions = sessions.filter(s => s.isPrerecorded);
      const result = filterSessionsByStartTimeInFuture(filteredSelfPacedSessions);
      console.log('sessions-------->4', result);
      setFilteredSessions(result);
    } else if (filteredStates.type === 'Selfpaced' && filteredStates.status === 'Past') {
      setFiltering(true);
      const filteredSelfPacedSessions = sessions.filter(s => s.isPrerecorded);
      const result = filterSessionsByStartTimeInPast(filteredSelfPacedSessions);
      console.log('sessions-------->5', result);
      setFilteredSessions(result);
    } else if (filteredStates.type === 'Live' && filteredStates.status === 'All') {
      setFiltering(true);
      const filteredLiveSessions = sessions.filter(s => !s.isPrerecorded);
      console.log('sessions-------->6', filteredLiveSessions);
      setFilteredSessions(filteredLiveSessions);
    } else if (filteredStates.type === 'Live' && filteredStates.status === 'Upcoming') {
      setFiltering(true);
      const filteredLiveSessions = sessions.filter(s => !s.isPrerecorded);
      const result = filterSessionsByStartTimeInFuture(filteredLiveSessions);
      console.log('sessions-------->8', result);
      setFilteredSessions(result);
    } else if (filteredStates.type === 'Live' && filteredStates.status === 'Past') {
      setFiltering(true);
      const filteredLiveSessions = sessions.filter(s => !s.isPrerecorded);
      const result = filterSessionsByStartTimeInPast(filteredLiveSessions);
      console.log('sessions-------->9', result);
      setFilteredSessions(result);
    }
  };
  console.log('Filtered sTATES------>', filteredStates, filteredSessions);
  const statesHandlingFunction = data => {
    if ((filteredStates.type === 'All' && filteredStates.status === 'All') === false) {
      if (filteredStates.type === 'Live' && data === true) {
        setLoaderstate(true);
        setfilteredStates({ type: null, status: null });
        setTimeout(() => {
          setfilteredStates({ type: 'All', status: 'All' });
          setLoaderstate(false);
        }, 1000);
      } else if (filteredStates.type === 'Selfpaced' && data === false) {
        setLoaderstate(true);
        setfilteredStates({ type: null, status: null });
        setTimeout(() => {
          setfilteredStates({ type: 'All', status: 'All' });
          setLoaderstate(false);
        }, 1000);
      }
    }
  };

  const revertingStatesFunction = (states, data) => {
    if ((filteredStates.type === 'All' && filteredStates.status === 'All') === false) {
      if (filteredStates.type === 'Live' && data === true) {
      } else if (filteredStates.type === 'Selfpaced' && data === false) {
      } else {
        setLoaderstate(true);
        setTimeout(() => {
          setfilteredStates(states);
          setLoaderstate(false);
        }, 1500);
      }
    }
  };
  return (
    <Grid item xs={12}>
      <Card mobileView={mobileView}>
        <CardHeader style={{ justifyContent: 'space-between' }} mobileView={mobileView}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <PageTitleSecond mobileView={mobileView}>Live Sessions and Self-Paced Modules</PageTitleSecond>
            {user.timeZoneId && (
              <NavLink to="/account/profile">
                <StyledTimeZoneLabel>{TIMEZONES[user.timeZoneId]}</StyledTimeZoneLabel>
              </NavLink>
            )}
            <Tooltip
              title="You can edit Contribution sessions, update times and even add additional sessions after the contribution is created."
              arrow
              className="ml-2"
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <InfoIcon htmlColor={colors.lightBrown} />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', flexDirection: mobileView ? 'column' : 'row', alignItems: 'center' }}>
            {loaderstate === true && <CircularProgress size={30} />}
            {loaderstate === false && filteredStates.status != null && (
              <>
                <ClickAwayListener
                  onClickAway={e => {
                    if (!e.defaultPrevented) {
                      setOpenTypeDropDown(false);
                      // setEventClicked(null);
                    }
                  }}
                >
                  <Grid style={mobileView ? { position: 'relative' } : { position: 'relative', marginRight: '10px' }}>
                    <Grid
                      onClick={e => {
                        e.preventDefault();
                        setOpenTypeDropDown(!openTypeDropDown);
                      }}
                      classes={{
                        root: classes.select_root,
                      }}
                      style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      item
                      sm={12}
                      xs={12}
                      className="dropdown"
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <p
                          style={
                            filteredStates?.type != 'All'
                              ? {
                                  margin: 2,
                                  fontFamily: 'Avenir',
                                  fontWeight: '800',
                                  fontSize: mobileView ? '10px' : '14px',
                                  width: mobileView ? '55px' : '115px',
                                  height: '38px',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }
                              : {
                                  margin: 2,
                                  fontFamily: 'Avenir',
                                  fontWeight: '400',
                                  fontSize: mobileView ? '10px' : '14px',
                                  width: mobileView ? '55px' : '115px',
                                  height: '38px',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }
                          }
                        >
                          {`Type:${` `} ${
                            filteredStates?.type === 'Selfpaced'
                              ? 'Self-Paced'
                              : filteredStates?.type === 'All'
                              ? 'All Content'
                              : filteredStates?.type
                          }`}
                        </p>
                        <ExpandMoreIcon />
                      </div>
                    </Grid>
                    {openTypeDropDown && (
                      <DropDown
                        mobileView={mobileView}
                        top={50}
                        left={dropdownPosition.x}
                        w={mobileView ? `${DROPDOWN_WITH_MOBILE}px` : `${DROPDOWN_WITH}px`}
                      >
                        <StyledOption
                          mobileView={mobileView}
                          selected={filteredStates?.type === 'All'}
                          borderBottom={true}
                          onClick={e => {
                            e.preventDefault();
                            setfilteredStates({ ...filteredStates, type: 'All' });
                            setOpenTypeDropDown(false);
                          }}
                        >
                          All Content
                        </StyledOption>
                        <StyledOption
                          mobileView={mobileView}
                          selected={filteredStates?.type === 'Live'}
                          borderBottom={true}
                          onClick={e => {
                            e.preventDefault();
                            setfilteredStates({ ...filteredStates, type: 'Live' });
                            setOpenTypeDropDown(false);
                          }}
                        >
                          Live
                        </StyledOption>
                        <StyledOption
                          mobileView={mobileView}
                          selected={filteredStates?.type === 'Selfpaced'}
                          onClick={e => {
                            e.preventDefault();
                            setfilteredStates({ ...filteredStates, type: 'Selfpaced' });
                            setOpenTypeDropDown(false);
                          }}
                        >
                          Self-Paced
                        </StyledOption>
                      </DropDown>
                    )}
                  </Grid>
                </ClickAwayListener>
              </>
            )}

            {loaderstate === false && filteredStates.type != null && (
              <>
                <ClickAwayListener
                  onClickAway={e => {
                    if (!e.defaultPrevented) {
                      setOpenStatusDropDown(false);
                      // setEventClicked(null);
                    }
                  }}
                >
                  <Grid style={mobileView ? { marginTop: '10px' } : { position: 'relative' }}>
                    <Grid
                      onClick={e => {
                        e.preventDefault();
                        setOpenStatusDropDown(!openStatusDropDown);
                      }}
                      classes={{
                        root: classes.select_root,
                      }}
                      style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      item
                      sm={12}
                      xs={12}
                      className="dropdown"
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <p
                          style={
                            filteredStates?.status != 'All'
                              ? {
                                  margin: 2,
                                  fontFamily: 'Avenir',
                                  fontWeight: '800',
                                  fontSize: mobileView ? '10px' : '14px',
                                  width: mobileView ? '55px' : '120px',
                                  height: '38px',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }
                              : {
                                  margin: 2,
                                  fontFamily: 'Avenir',
                                  fontWeight: '400',
                                  fontSize: mobileView ? '10px' : '14px',
                                  width: mobileView ? '55px' : '120px',
                                  height: '38px',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }
                          }
                        >
                          {`Status:${` `} ${filteredStates?.status === 'All' ? 'All Content' : filteredStates?.status}`}
                        </p>
                        <ExpandMoreIcon />
                      </div>
                    </Grid>
                    {openStatusDropDown && (
                      <DropDown
                        mobileView
                        top={mobileView ? 630 : 50}
                        left={mobileView ? 250 : dropdownPosition.x}
                        w={mobileView ? `${DROPDOWN_WITH_MOBILE}px` : `${DROPDOWN_WITH}px`}
                      >
                        <StyledOption
                          mobileView={mobileView}
                          selected={filteredStates?.status === 'All'}
                          borderBottom={true}
                          onClick={e => {
                            e.preventDefault();
                            setfilteredStates({ ...filteredStates, status: 'All' });
                            setOpenStatusDropDown(false);
                          }}
                        >
                          All Content
                        </StyledOption>
                        <StyledOption
                          mobileView={mobileView}
                          selected={filteredStates?.status === 'Upcoming'}
                          borderBottom={true}
                          onClick={e => {
                            e.preventDefault();
                            setfilteredStates({ ...filteredStates, status: 'Upcoming' });
                            setOpenStatusDropDown(false);
                          }}
                        >
                          Upcoming
                        </StyledOption>
                        <StyledOption
                          mobileView={mobileView}
                          selected={filteredStates?.status === 'Past'}
                          onClick={e => {
                            e.preventDefault();
                            setfilteredStates({ ...filteredStates, status: 'Past' });
                            setOpenStatusDropDown(false);
                          }}
                        >
                          Past
                        </StyledOption>
                      </DropDown>
                    )}
                  </Grid>
                </ClickAwayListener>
              </>
            )}
            {(filteredStates.type != 'All' || filteredStates.status != 'All') && (
              <div
                onClick={() => {
                  setLoaderstate(true);
                  setfilteredStates({ type: null, status: null });
                  setTimeout(() => {
                    setfilteredStates({ type: 'All', status: 'All' });
                    setLoaderstate(false);
                  }, 1000);
                }}
                style={
                  mobileView
                    ? {
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '12px',
                        cursor: 'pointer',
                      }
                    : {
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '12px',
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }
                }
              >
                Clear
              </div>
            )}
          </div>
        </CardHeader>
        <CardBody ref={bottomRef} mobileView={mobileView}>
          {' '}
          <FieldArray name="sessions">
            {({ push, remove, form }) => {
              const addHandler = data => {
                const filterdStates = filteredStates;
                statesHandlingFunction(data?.isPrerecorded);
                const lastSession = sessions[sessionsCount];
                const lastSessionTime =
                  lastSession && lastSession.sessionTimes.length > 0
                    ? lastSession.sessionTimes[lastSession.sessionTimes.length - 1]
                    : {};
                if (lastSessionTime.recurringSessionTime != null) {
                  const lastSessionUpdated = {
                    ...lastSession,
                    sessionTimes: [{ ...lastSession.sessionTimes[0], recurringSessionTime: null }],
                  };
                  remove(sessionsCount);
                  push(lastSessionUpdated);
                }
                push({
                  ...data,
                  title: data?.isPrerecorded ? `Module` : `Session ${sessionCount}`,
                  name: data?.isPrerecorded ? `Module` : `Session`,
                  minParticipantsNumber: lastSession ? lastSession.minParticipantsNumber : PARTICIPANTS_NUMBER.MIN,
                  maxParticipantsNumber: lastSession ? lastSession.maxParticipantsNumber : PARTICIPANTS_NUMBER.MAX,
                  dateAvailable: data?.isPrerecorded
                    ? getNextCurrentSessionTime(false, lastSessionTime, enrollment).startTime
                    : null,
                  sessionTimes: [getNextCurrentSessionTime(false, lastSessionTime, enrollment)],
                });
                revertingStatesFunction(filterdStates, data?.isPrerecorded);
                if (!data?.isPrerecorded) {
                  setSessionCount(R.inc);
                }
              };

              const removeHandler = (id, isPrerecorded) => {
                remove(id);
                if (!isPrerecorded) {
                  setSessionCount(R.dec);
                }
              };
              console.log('filteredSessions---------->', filteredSessions);
              return (
                <>
                  {filteredSessions.length >= 3 && loaderstate === false && (
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                      <Button
                        type="button"
                        className="mr-3"
                        onClick={() => {
                          addHandler({ isPrerecorded: false });
                          scrollToBottom();
                        }}
                        autoWidth
                      >
                        Create New Live Session
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          addHandler({
                            isPrerecorded: true,
                            contentIsUploading: false,
                          });
                          scrollToBottom();
                        }}
                        autoWidth
                      >
                        Create New Self-Paced Module
                      </Button>
                    </div>
                  )}
                  {sessions.length > 0 && (
                    <DragDropContext
                      onDragStart={() => {
                        isDragging(true);
                      }}
                      onDragEnd={handleEnd}
                    >
                      <Droppable disableInteractiveElementBlocking={true} droppableId="Session">
                        {provided => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                width: '100%',
                                // minHeight: 200,
                                overflow: 'hidden',
                              }}
                            >
                              {sessions?.map((item, index) => {
                                const key = `${index}`;
                                const isLast = index === sessions.length - 1;

                                return (
                                  <Draggable draggableId={key} index={index} isDragDisabled={!item.isPrerecorded}>
                                    {provided => {
                                      return (item.isPrerecorded === true && filteredStates.type === 'All') ||
                                        (item.isPrerecorded === true && filteredStates.type === 'Selfpaced') ? (
                                        item.isPrerecorded === true &&
                                        filteredStates.status === 'Past' &&
                                        filterSessionsByStartTimeInPast([item]).length > 0 ? (
                                          <div
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                          >
                                            <Session
                                              dragging={dragging}
                                              key={key}
                                              index={index}
                                              session={item}
                                              providerName={providerName}
                                              isPrerecorded={item?.isPrerecorded}
                                              timesCount={item?.sessionTimes?.length || 1}
                                              onDelete={removeHandler}
                                              form={form}
                                              isLastIndex={isLast}
                                              isSessionOpen={
                                                openSessionIndex === index ||
                                                (item?.isPrerecorded && item?.contentIsUploading)
                                              }
                                              setOpenSessionIndex={setOpenSessionIndex}
                                            />
                                          </div>
                                        ) : item.isPrerecorded === true &&
                                          filteredStates.status === 'Upcoming' &&
                                          filterSessionsByStartTimeInFuture([item]).length > 0 ? (
                                          <div
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                          >
                                            <Session
                                              dragging={dragging}
                                              key={key}
                                              index={index}
                                              session={item}
                                              providerName={providerName}
                                              isPrerecorded={item?.isPrerecorded}
                                              timesCount={item?.sessionTimes?.length || 1}
                                              onDelete={removeHandler}
                                              form={form}
                                              isLastIndex={isLast}
                                              isSessionOpen={
                                                openSessionIndex === index ||
                                                (item?.isPrerecorded && item?.contentIsUploading)
                                              }
                                              setOpenSessionIndex={setOpenSessionIndex}
                                            />
                                          </div>
                                        ) : item.isPrerecorded === true && filteredStates.status === 'All' ? (
                                          <div
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                          >
                                            <Session
                                              dragging={dragging}
                                              key={key}
                                              index={index}
                                              session={item}
                                              providerName={providerName}
                                              isPrerecorded={item?.isPrerecorded}
                                              timesCount={item?.sessionTimes?.length || 1}
                                              onDelete={removeHandler}
                                              form={form}
                                              isLastIndex={isLast}
                                              isSessionOpen={
                                                openSessionIndex === index ||
                                                (item?.isPrerecorded && item?.contentIsUploading)
                                              }
                                              setOpenSessionIndex={setOpenSessionIndex}
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )
                                      ) : (item.isPrerecorded === false && filteredStates.type === 'All') ||
                                        (item.isPrerecorded === false && filteredStates.type === 'Live') ? (
                                        item.isPrerecorded === false &&
                                        filteredStates.status === 'Past' &&
                                        filterSessionsByStartTimeInPast([item]).length > 0 ? (
                                          <div
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                          >
                                            <Session
                                              dragging={dragging}
                                              key={key}
                                              index={index}
                                              session={item}
                                              providerName={providerName}
                                              isPrerecorded={item?.isPrerecorded}
                                              timesCount={item?.sessionTimes?.length || 1}
                                              onDelete={removeHandler}
                                              form={form}
                                              isLastIndex={isLast}
                                              isSessionOpen={
                                                openSessionIndex === index ||
                                                (item?.isPrerecorded && item?.contentIsUploading)
                                              }
                                              setOpenSessionIndex={setOpenSessionIndex}
                                            />
                                          </div>
                                        ) : item.isPrerecorded === false &&
                                          filteredStates.status === 'Upcoming' &&
                                          filterSessionsByStartTimeInFuture([item]).length > 0 ? (
                                          <div
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                          >
                                            <Session
                                              dragging={dragging}
                                              key={key}
                                              index={index}
                                              session={item}
                                              providerName={providerName}
                                              isPrerecorded={item?.isPrerecorded}
                                              timesCount={item?.sessionTimes?.length || 1}
                                              onDelete={removeHandler}
                                              form={form}
                                              isLastIndex={isLast}
                                              isSessionOpen={
                                                openSessionIndex === index ||
                                                (item?.isPrerecorded && item?.contentIsUploading)
                                              }
                                              setOpenSessionIndex={setOpenSessionIndex}
                                            />
                                          </div>
                                        ) : item.isPrerecorded === false && filteredStates.status === 'All' ? (
                                          <div
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                          >
                                            <Session
                                              dragging={dragging}
                                              key={key}
                                              index={index}
                                              session={item}
                                              providerName={providerName}
                                              isPrerecorded={item?.isPrerecorded}
                                              timesCount={item?.sessionTimes?.length || 1}
                                              onDelete={removeHandler}
                                              form={form}
                                              isLastIndex={isLast}
                                              isSessionOpen={
                                                openSessionIndex === index ||
                                                (item?.isPrerecorded && item?.contentIsUploading)
                                              }
                                              setOpenSessionIndex={setOpenSessionIndex}
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )
                                      ) : (
                                        <></>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </DragDropContext>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      type="button"
                      className="mr-3"
                      onClick={() => {
                        addHandler({ isPrerecorded: false });
                      }}
                      autoWidth
                    >
                      Create New Live Session
                    </Button>
                    <Button
                      type="button"
                      onClick={() =>
                        addHandler({
                          isPrerecorded: true,
                          contentIsUploading: false,
                        })
                      }
                      autoWidth
                    >
                      Create New Self-Paced Module
                    </Button>
                    {/* <div style={{ marginLeft: '10px' }} className={classes.enableDownloadMain}>
                      <Field as={StyledCheckbox} name="isDownloadEnabled" type="checkbox" color="primary" />{' '}
                      <p className={classes.enableHeading}>Enable client ability to download self paced modules</p>
                    </div> */}
                  </div>
                </>
              );
            }}
          </FieldArray>
          {errors.sessions && touched.sessions && typeof errors.sessions === 'string' && (
            <CommonErrorMessage message={errors.sessions} />
          )}
        </CardBody>
      </Card>
      <div ref={bottomRef}></div>

      {isSendInvitesFromModalOpen && (
        <SendInvitesFromModal
          isOpen
          onModalClose={handleCloseSendInvitesFromModalOpen}
          onSubmit={handleModalSubmit}
          setFieldValue={setFieldValue}
          values={values}
          // onSubmit={handleModalSubmit}
          // calendars={calendars}
          addCalendar={addCalendar}
          contributionId={query.id}
          isCreate={isCreate}
          // lastAddedMail={lastAddedMail}
          // loadingLastEmail={loadingLastEmail}
        />
      )}
    </Grid>
  );
};

Sessions.propTypes = {
  enrollment: PropTypes.shape({
    toDate: PropTypes.string,
    fromDate: PropTypes.string,
    anyTime: PropTypes.bool,
  }),
  sessions: PropTypes.arrayOf(),
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ oneToOneData, contributions, calendars }) => ({
  calendars: calendars.calendarsAccounts,
  lastAddedMail: calendars.lastAddedEmail,
  loadingLastEmail: calendars.loadingLastEmail,
  accountsForConflictsCheck: calendars.accountsForConflictsCheck,
  contribution: contributions.activeContribution,
  loadingTimes: contributions.loadingTimes,
});
const actions = {
  fetchCalendars: calendarsActions.fetchCalendars,
  addCalendar: calendarsActions.addCalendar,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
  getAcсountForSendReminders: calendarsActions.getAcсountForSendReminders,
  removeCalendar: calendarsActions.removeCalendar,
};

Sessions.defaultProps = {
  enrollment: {},
  sessions: [],
};

export default connect(mapStateToProps, actions)(Sessions);
