import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Video } from 'components/Video/Video';
import { Formik, Form, Field } from 'formik';
import UploadThumbnail from 'pages/CreateContribution/components/UploadThumbnail';
import { useContribution } from 'pages/ContributionView/hooks';
import { useRouter } from 'hooks';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { updateContributionActions } from 'actions/contributions';
import { useDispatch } from 'react-redux';
import { getThemedColors } from 'services/contributions.service';
const StyledBody = styled.div`
  padding: 20px 0px;
`;

const StyledVideoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.232px;
  border: 1.018px solid ${({ borderColor }) => borderColor || '#dadada'};
`;

const SelfPacedVideo = ({ session, sessionIndex, sessionTime, sessionTimeIndex, refetch }) => {
  const [isOpenUploadThumbnail, setIsOpenUploadThumbnail] = useState(false);
  const contribution = useContribution();
  const { query } = useRouter();
  const isEditing = Boolean(query.id);
  const { onSave: onSaveSelfPacedThumnail } = useSaveContribution(isEditing);
  const dispatch = useDispatch();
  const { themedCardOutlineColor } = getThemedColors(contribution);
  const { isDarkModeEnabled } = contribution;

  const updateSessionthumbnail = async thumbnail => {
    const sessionId = session.id;
    const sessionTimeId = sessionTime.id;
    const updateContribution = {
      ...contribution,
      sessions: contribution.sessions.map(session => {
        if (session.id === sessionId) {
          return {
            ...session,
            sessionTimes: session.sessionTimes.map(sessionTime => {
              if (sessionTime.id === sessionTimeId) {
                return {
                  ...sessionTime,
                  selfPacedThumbnailUrl: thumbnail,
                };
              }
              return sessionTime;
            }),
          };
        }
        return session;
      }),
    };
    dispatch(updateContributionActions.success(updateContribution));
    await onSaveSelfPacedThumnail(updateContribution);
  };
  return (
    <StyledBody>
      <StyledVideoContainer borderColor={isDarkModeEnabled && themedCardOutlineColor}>
        <Video
          style={{ width: '100%' }}
          isPreviewMode
          isSessionView
          session={session}
          sessionIndex={sessionIndex}
          sessionTime={sessionTime}
          sessionTimeIndex={sessionTimeIndex}
          uploadThumbnail={setIsOpenUploadThumbnail}
          refetch={refetch}
        />
      </StyledVideoContainer>
      {isOpenUploadThumbnail && (
        <UploadThumbnail
          setIsOpen={setIsOpenUploadThumbnail}
          isOpen={isOpenUploadThumbnail}
          title="Select a Thumbnail"
          thumbnailValue={sessionTime?.selfPacedThumbnailUrl}
          fieldName="selfPacedThumbnailUrl"
          isPreviewMode
          updateSessionthumbnail={updateSessionthumbnail}
        />
      )}
    </StyledBody>
  );
};

export default SelfPacedVideo;
