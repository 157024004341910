import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useAccount, useRouter } from 'hooks';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { orderBy, uniqBy } from 'lodash';
import { TIMEZONES } from 'constants.js';
import { colors } from 'utils/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { LabelText, SimpleText } from 'components/UI/Text/TextStyles';
import Modal from 'components/UI/Modal';
import Loader from 'components/UI/Loader';
import { getTimePeriodsForAvailability, DATE_FORMATS } from 'utils/datesAndMoney';
import { getClientContributionTimes, getCoachContributionTimes, getThemedColors } from 'services/contributions.service';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { startIncrementDurationsOneToOne } from 'pages/CreateContribution/components/OneToOneForm';
const StyledLink = styled(Link)`
  color: ${colors.lightBrown};
`;
const StyledSimpleText = styled(SimpleText)`
  margin: 25px 10px 25px 0px;
  display: inline-block;
`;
const StyledLabelText = styled(LabelText)`
  margin: 25px 0;
  display: inline-block;
`;
const StyledTimeSlots = styled.div`
  display: inline-block;
  width: 240px;
  height: 54px;
  border: 1px solid ${colors.stroke};
  border-radius: 8px;
  margin: 5px;

  &:hover {
    cursor: pointer;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
      // width: 145px;
      width: 120px;
  `}

  ${({ isSelected }) =>
    isSelected &&
    `
    border-color: ${colors.lightBrown};
    color: ${colors.lightBrown};
  `}

  ${({ isBooked }) =>
    isBooked &&
    `
    color: ${colors.stroke};
    
    &:hover {
      cursor: default;
    }
  `}
`;
const StyledTimeSlotValue = styled.div`
  margin: 15px 0;
  text-align: center;
`;

const SlotsModal = React.memo(
  ({
    isOpen,
    onClose,
    onSubmit,
    selectedDate,
    isCoach,
    contributionId,
    contributionTimeZoneId,
    serviceProviderName,
    duration,
    title,
    isSelectable,
    clientName,
  }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const request = isCoach ? getCoachContributionTimes : getClientContributionTimes;
    const [slots, setSlots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const contribution = useSelector(state => state.contributions?.activeContribution);
    let colorToUse = determineColorToUse(contribution);
    const { themedColor } = getThemedColors(contribution);
    const { domain } = useRouter();

    const PrimaryColor = colorToUse?.PrimaryColorCode;
    const TextColor =
      colorToUse?.TextColorCode === 'Auto'
        ? lightOrDark(colorToUse?.PrimaryColorCode)
        : colorToUse?.TextColorCode === '#000000'
        ? '#000000'
        : '#FFFFFF';
    const formattedTime = selectedSlot
      ? `${moment(selectedSlot.start).format(DATE_FORMATS.DATE_TIME)} - ${moment(selectedSlot.end).format(
          DATE_FORMATS.TIME_12,
        )}`
      : '';

    useEffect(() => {
      // const increments = contribution?.sessionIncrements || [];
      //const sessionDuration = contribution?.durations?.[0];
      const startTimeIncrementDuration = contribution?.sessionIncrements?.[0];
      const increments = [startTimeIncrementDuration];
      // if (startTimeIncrementDuration > 0) {
      //   for (let i = 0; i < Math.ceil(sessionDuration / startTimeIncrementDuration); i++) {
      //     increments.push(i * startTimeIncrementDuration);
      //   }
      // }
      // if (increments.every(k => k !== 0)) {
      //   increments.push(0);
      // }
      if (contribution.bufferTimeAfter > 0 || contribution.bufferTimeBefore > 0) {
        Promise.allSettled(
          increments.map(k =>
            request(
              contributionId,
              k,
              moment(selectedDate._d).format('YYYY-MM-DD'),
              moment(selectedDate._d).format('YYYY-MM-DD'),
            ),
          ),
        ).then(_slots => {
          const zeroSlots = _slots.reduce(
            (acc, curr) => (acc.some(k => k.startTime === curr.value?.startTime) ? acc : [...acc, ...curr.value]),
            [],
          );
          const formattedSlots = zeroSlots
            .filter(slot => moment(slot.startTime).isSame(selectedDate._d, 'day'))
            .sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'));

          const events = getTimePeriodsForAvailability({
            availabilityPeriods: uniqBy(formattedSlots, 'id'),
            duration,
            serviceProviderName,
          });
          setSlots(events);
          setLoading(false);
          const firstFreeSlot = events.find(s => !s.isBooked);
          const isBefore = isCurrentDateTimeBeforeSelected(firstFreeSlot?.start);
          if (!isBefore) {
            setSelectedSlot(firstFreeSlot);
          }
        });
      } else {
        Promise.allSettled(
          increments.map(k =>
            request(
              contributionId,
              k,
              moment(selectedDate._d).format('YYYY-MM-DD'),
              moment(selectedDate._d).format('YYYY-MM-DD'),
            ),
          ),
        ).then(_slots => {
          const zeroSlots = _slots.reduce(
            (acc, curr) => (acc.some(k => k.startTime === curr.value?.startTime) ? acc : [...acc, ...curr.value]),
            [],
          );
          const formattedSlots = zeroSlots
            .filter(slot => moment(slot.startTime).isSame(selectedDate._d, 'day'))
            .sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'));

          const events = getTimePeriodsForAvailability({
            availabilityPeriods: uniqBy(formattedSlots, 'id'),
            duration,
            serviceProviderName,
          });
          setSlots(events);
          setLoading(false);
          const firstFreeSlot = events.find(s => !s.isBooked);
          const isBefore = isCurrentDateTimeBeforeSelected(firstFreeSlot?.start);
          if (!isBefore) {
            setSelectedSlot(firstFreeSlot);
          }
        });
      }
    }, []);
    const buttons = [];
    const { user } = useAccount();
    const titleText = isSelectable
      ? 'Select your session time'
      : 'These are the session times your clients can self-book';
    function isCurrentDateTimeBeforeSelected(selectedDateTime) {
      const currentDateTime = moment();
      const selectedDate = moment(selectedDateTime);
      const currentDate = currentDateTime.isSame(selectedDate, 'day');
      if (currentDate) {
        return !currentDateTime.isBefore(selectedDate);
      } else {
        return false;
      }
    }
    return (
      <>
        <Modal
          title={titleText}
          applyTheming
          isOpen={isOpen}
          onCancel={onClose}
          submitTitle="Confirm"
          cancelTitle="Cancel"
          buttons={buttons}
          onSubmit={() => onSubmit(selectedSlot)}
          hiddenCancel={!isSelectable}
          disableConfirm={!isSelectable}
        >
          {isSelectable && (
            <StyledLabelText style={{ color: themedColor }}>
              Please select your session time for {title} with {isCoach ? clientName : serviceProviderName}
            </StyledLabelText>
          )}
          {loading && <Loader relative withoutTopOffset />}
          <div>
            {orderBy(slots, k => moment(k.start), 'asc').map((slot, index) => {
              const isBefore = isCurrentDateTimeBeforeSelected(slot.start);
              return (
                !isBefore && (
                  <StyledTimeSlots
                    mobileView={mobileView}
                    key={slot.id}
                    isBooked={slot.isBooked}
                    onClick={() => !slot.isBooked && setSelectedSlot(slot)}
                    isSelected={selectedSlot && selectedSlot.id === slot.id}
                  >
                    <StyledTimeSlotValue style={{ color: themedColor }}>
                      {moment(slot.start).format(DATE_FORMATS.TIME_12)}
                    </StyledTimeSlotValue>
                  </StyledTimeSlots>
                )
              );
            })}
          </div>
          <StyledSimpleText>{formattedTime}</StyledSimpleText>
          <StyledSimpleText>{TIMEZONES[contributionTimeZoneId || user?.timeZoneId]}</StyledSimpleText>
          {user?.timeZoneId && (
            <u
              style={{ cursor: 'pointer' }}
              onClick={() => {
                redirectToAppIfIsCoachDomain(domain, '/account/profile');
              }}
            >
              Update My Timezone
            </u>
          )}
        </Modal>
      </>
    );
  },
);

SlotsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  isCoach: PropTypes.bool,
  contributionId: PropTypes.string.isRequired,
  contributionTimeZoneId: PropTypes.string,
  serviceProviderName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  isSelectable: PropTypes.bool,
};

SlotsModal.defaultProps = {
  isCoach: false,
  isSelectable: true,
};

export default SlotsModal;
