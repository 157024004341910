import React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useTheme, useMediaQuery } from '@material-ui/core';
import ImageCropper from '../../CreateContribution/components/Cropper/ImageCropper';
import { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import Modal from '../../../components/UI/Modal';
import { useRouter, useHttp, useAccount } from 'hooks';
import { connect, useDispatch, useSelector } from 'react-redux';
import TextField from 'components/UI/TextField';
import { dataURLtoFile, lightOrDark } from 'utils/utils';
import {
  ClientEmailTabContainer,
  ClientNotificationP,
  ClientNotificationDiv,
  NotificationBannerMainDiv,
  NotificationBannerOk,
  NotificationBannerP,
  DropDownMainContainer,
  InfoIconStyled,
  useStyles,
} from './components/ContributionCommunicationStyled';
import { Typography } from '@mui/material';

import { editEmailUserColors, editUserColors } from 'services/user.service';
import * as userActions from 'actions/user';
import { enableBrandingColorsOnEmailTemplates, getCommunicationTabData } from 'services/communicationTab.service ';
import ContributionCommunicationPanel from './ContributionCommunicationPanel';
import { colors } from 'utils/styles';
import ContributionCommunicationCkEditor from './ContributionCommunicationCkEditor';
import { GetCustomizedContributions, CopyContributionEmailSettings } from 'services/communicationTab.service ';
import useContribution from '../hooks/useContribution';
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './components/assets/content-styles.css';
import { Formik } from 'formik';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import Select from 'components/FormUI/Select';
import { Tooltip, Avatar } from '@material-ui/core';
import { TOOLTIP } from '../../../constants';
import ButtonExample from 'pages/Profile/ButtonExample';
import { fetchCohealerContribution, fetchContribution } from 'actions/contributions';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import SmsNotificationPanel from './SmsNotificationPanel';

const IconContainer = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;
const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
  color: ${props => props.labelColor};
  label {
    color: ${props => props.labelColor};
  }

  svg {
    color: ${props => props.labelColor};
  }
`;
export const StyledColorSection = styled.div`
  margin-top: 15px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 20px;
`;
const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
`;
export const StyledColorPicker = styled.div`
  border: 1px solid rgb(178, 202, 224);
  border-radius: 5px;
  display: flex;
  padding: 3px;
  width: 145px;
  height: 32px;
`;
export const StyledColorSelector = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const StyledTooltipWrapModal = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledHeading = styled(Typography)`
  color: '#213649';
`;

export const StyledTooltipModal = styled(Tooltip)`
  position: absolute;
  right: -23px;
  top: calc(50% - 0px);
  transform: translateY(-50%);
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Container = styled.div`
  cursor: pointer;
`;
export const StyledColorCodeWrapper = styled.div`
  display: flex;
  width: 70%;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
`;

export const StyledIconSection = styled.div`
  margin-top: 15px;
`;

export const InputHidden = styled.input.attrs({ type: 'file' })`
  display: none;
`;
export const EmptyIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  color: #bdbdbd;
  height: 75px;
  width: 75px;
  border-radius: 10px;
`;

const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  border: solid 1px #dadada;
`;

function ContributionCommunication() {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const IconUpload = useRef();
  const { request, loading } = useHttp();
  const [age, setAge] = React.useState('');
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const contribution = useSelector(state => state.contributions?.activeContribution);
  const [ckEditorConditional, setckEditorConditional] = useState(false);
  const [openBrandingModal, setOpenBrandingModal] = useState(false);
  const [openBrandingModalPopUp, setOpenBrandingModalPopUp] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [userObj, setuserObj] = useState();
  const [editorData, setEditorData] = useState('');
  const [handlePopUp, sethandlePopUp] = useState(true);
  const { id: contributionId } = useContribution();
  const [dropDownData, setdropDownData] = useState([]);
  const [handleData, sethandleData] = useState();
  const classes = useStyles();
  const [getApiCall, setgetApiCall] = useState();
  const [clickedIconeType, setClickedIconeType] = useState(null);
  const [imageToCrop, setImageToCrop] = useState('');
  const [brandingColorsActive, setBrandingColorsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { query, history } = useRouter();
  const dispatch = useDispatch();
  const { themedColor, themedBackgroundColor, themedCardBackgroundColor } = getThemedColors(contribution);
  let colorToUse = determineColorToUse(contribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.AccentColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const handleIconChange = useCallback((e, setFieldValue) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        const formData = new FormData();
        // setFieldValue('customLogo', reader.result);
        formData.append('file', dataURLtoFile(reader.result));
        request('/content/addpublicimage', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        })
          .then(image => {
            setFieldValue('customLogo', image);
          })
          .catch(console.dir)
          .finally(() => {
            // onClose();
            // setUploading(false);
          });
      };
      reader.readAsDataURL(file);
    }
  }, []);
  useEffect(() => {
    getCommunicationData();
    GetCustomizedContributions(contributionId)
      .then(res => {
        setdropDownData(res);
      })
      .catch(err => console.log(err, 'Err ===>'));
  }, [contributionId, ckEditorConditional]);

  const getCommunicationData = () => {
    getCommunicationTabData(contributionId)
      .then(res => {
        setBrandingColorsActive(res.isBrandingColorsEnabled);
      })
      .catch(err => {});
  };

  const fun = UserObj => {
    setuserObj(UserObj);
    setckEditorConditional(true);
  };
  const ViewOne = () => {
    setckEditorConditional(false);
  };
  const handleEditorChange = event => {
    setEditorData(event.editor.getData());
  };
  const onClickHandler = () => {
    if (IconUpload) {
      IconUpload.current.click();
    }
  };
  function copySettings(e) {
    setAge(e.target.value);
    CopyContributionEmailSettings(e.target.value, contributionId)
      .then(res => {
        setgetApiCall(res);
        setBrandingColorsActive(res.isBrandingColorsEnabled);
        setConnectStripeModal(false);
      })
      .catch(err => console.log(err, 'Err Put Requ===>'));
  }
  const [connectStripeModal, setConnectStripeModal] = useState(false);
  const [selectedContributon, setSelectedContribution] = useState(false);
  const handleConnectStripeModalClose = useCallback(() => setConnectStripeModal(false), [connectStripeModal]);

  const tooltipText =
    'All of the below emails get sent automatically to your clients. You can customize these & enable/ disable them from being sent anytime!';
  return (
    <>
      <>
        {ckEditorConditional ? (
          <Grid container className={classes.MainContainer}>
            <ContributionCommunicationCkEditor
              userObj={userObj}
              handleOnClick={ViewOne}
              onChange={handleEditorChange}
            />
          </Grid>
        ) : (
          <>
            <Grid container className={classes.MainContainer}>
              {/* <ClientNotificationP
                style={{ marginTop: '10px', color: colorToUse?.AccentColorCode }}
                mobileView={mobileView}
              >
                Client Notifications
              </ClientNotificationP>
              <Tooltip
                title="You can edit this later from the Notifications tab inside contribution view after the contribution is created."
                arrow
                className="ml-2 mt-2"
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <InfoIcon htmlColor={colors.lightBrown} />
              </Tooltip>
              <ClientNotificationP
                onClick={() => {
                  setSmsNotification(true);
                }}
                style={{ marginTop: '10px', color: colorToUse?.AccentColorCode, marginLeft: '50px' }}
                mobileView={mobileView}
              >
                SMS Notifications
              </ClientNotificationP>
              <Tooltip
                title="You can edit this later from the Notifications tab inside contribution view after the contribution is created."
                arrow
                className="ml-2 mt-2"
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <InfoIcon htmlColor={colors.lightBrown} />
              </Tooltip>  */}
              {handlePopUp && (
                <Grid item md={12}>
                  <NotificationBannerMainDiv
                    style={{ backgroundColor: colorToUse.AccentColorCode }}
                    mobileView={mobileView}
                  >
                    <NotificationBannerP style={{ color: textColor }}>
                      <Tooltip title={tooltipText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                        <StyledInfoIcon htmlColor={colors.white} style={{ marginRight: '10px' }} />
                      </Tooltip>
                      {/* <InfoIconStyled mobileView={mobileView}>
                        <InfoIcon style={{ paddingRight: '4px' }} htmlColor={colors.white} />
                      </InfoIconStyled> */}
                      All of the below emails get sent automatically to your clients. You can customize these & enable/
                      disable them from being sent anytime!
                    </NotificationBannerP>
                    <NotificationBannerOk
                      style={{ color: textColor }}
                      onClick={() => sethandlePopUp(false)}
                      mobileView={mobileView}
                    >
                      OK
                    </NotificationBannerOk>
                  </NotificationBannerMainDiv>
                </Grid>
              )}
            </Grid>
            <Grid container className={classes.MainContainer}>
              <Grid item md={12}>
                <DropDownMainContainer mobileView={mobileView}>
                  <Grid item xs={12} container wrap="nowrap">
                    <Grid item md={6} xs={12}>
                      <div>
                        <Formik
                          initialValues={{
                            copyMySettingsFromContribution: age.name,
                            PrimaryColorCode: contribution.brandingColors?.PrimaryColorCode,
                            AccentColorCode: contribution.brandingColors?.AccentColorCode,
                            TextColorCode: contribution.brandingColors?.TextColorCode || 'Auto',
                            TertiaryColorCode: contribution.brandingColors?.TertiaryColorCode,
                            customLogo: contribution?.customLogo || null,
                            isLegacyColorsActive: contribution?.isLegacyColorsActive || false,
                          }}
                        >
                          {formProps => {
                            return (
                              <>
                                <StyledSelect
                                  labelColor={themedColor}
                                  label="Copy my settings from this contribution"
                                  name="copyMySettingsFromContribution"
                                  onChange={e => {
                                    setConnectStripeModal(true);
                                    setSelectedContribution(e);
                                  }}
                                  style={{ color: themedColor }}
                                  itemTextColor={themedColor}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        color: themedColor,
                                        backgroundColor: themedCardBackgroundColor,
                                      },
                                    },
                                  }}
                                  items={sortBy(dropDownData, ['contributionName']).map(data => ({
                                    title: data.contributionName,
                                    value: data,
                                  }))}
                                />
                                <ImageCropper
                                  title={`Crop Your ${clickedIconeType?.toUpperCase()} Icon`}
                                  submitTitle="Set Icon"
                                  imageToCrop={imageToCrop}
                                  isOpen={isOpen}
                                  onClose={() => {
                                    setIsOpen(false);
                                    setClickedIconeType(null);
                                  }}
                                  setFieldValue={formProps.setFieldValue}
                                  field={`${clickedIconeType}Icon`}
                                  shape="square"
                                />
                                <Modal
                                  title="Custom Branding Colors "
                                  isOpen={openBrandingModal}
                                  applyTheming
                                  brandingColor={false}
                                  hiddenCancel
                                  isCreatingContribution={true}
                                  // loading={saving}
                                  onCancel={() => {
                                    setOpenBrandingModal(false);
                                  }}
                                  onSubmit={() => {
                                    setOpenBrandingModalPopUp(true);
                                  }}
                                  submitTitle="Save"
                                >
                                  <Grid container item style={{ marginBottom: '50px', width: '500px' }}>
                                    <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7', height: '120px' }}>
                                      <div className="heading">
                                        <p>Brand Elements</p>
                                      </div>
                                      <div>
                                        <p>Cohere allows you to adjust your custom branding colors</p>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7' }}>
                                      <StyledIconSection>
                                        <StyledRow>
                                          <StyledTooltipWrapModal>
                                            <StyledHeading>Logo</StyledHeading>
                                            <StyledTooltipModal
                                              title="This is the logo that will appear on your branded landing pages on Cohere."
                                              arrow
                                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                            >
                                              <InfoIcon htmlColor="#47627B" />
                                            </StyledTooltipModal>
                                          </StyledTooltipWrapModal>
                                          <Container onClick={onClickHandler}>
                                            {formProps.values.customLogo ? (
                                              <IconContainer>
                                                <Image src={formProps.values.customLogo} />
                                              </IconContainer>
                                            ) : (
                                              <EmptyIconContainer style={{ backgroundColor: themedBackgroundColor }}>
                                                +
                                              </EmptyIconContainer>
                                            )}
                                          </Container>
                                        </StyledRow>
                                      </StyledIconSection>
                                      <InputHidden
                                        ref={IconUpload}
                                        onChange={e => {
                                          handleIconChange(e, formProps.setFieldValue);
                                          formProps.setFieldValue('isCustomBrandingColorsActive', true);
                                        }}
                                        accept="image/x-png,image/gif,image/jpeg"
                                      />
                                    </Grid>
                                    <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7', marginTop: '20px' }}>
                                      <StyledColorSection>
                                        <StyledColorSelector>
                                          <StyledTooltipWrapModal>
                                            <StyledHeading>Primary color</StyledHeading>
                                            <StyledTooltipModal
                                              title="This color will be associated with call-to-action (CTA) buttons on your branded pages"
                                              arrow
                                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                            >
                                              <InfoIcon htmlColor="#47627B" />
                                            </StyledTooltipModal>
                                          </StyledTooltipWrapModal>

                                          <StyledColorPicker>
                                            <ButtonExample
                                              colorCode={formProps.values.PrimaryColorCode}
                                              onchangevalue={code => {
                                                formProps.setFieldValue('PrimaryColorCode', code);
                                                formProps.setFieldValue('isLegacyColorsActive', true);
                                                formProps.setFieldValue('isCustomBrandingColorsActive', true);
                                                // saveColor({
                                                //   colorName: 'PrimaryColorCode',
                                                //   colorCode: code,
                                                //   // formValues: values,
                                                // });
                                              }}
                                            />
                                            {/* <StyledColor colorCode={values.PrimaryColorCode} onClick={funTest} /> */}
                                            <StyledColorCodeWrapper>
                                              <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                                              <div style={{ width: '85%' }}>
                                                <TextField
                                                  name="PrimaryColorCode"
                                                  value={formProps?.values?.PrimaryColorCode?.substring(1)}
                                                  type="text"
                                                  onChange={e => {
                                                    const colorCode = '#' + e.target.value;
                                                    formProps.setFieldValue('PrimaryColorCode', colorCode);
                                                    formProps.setFieldValue('isLegacyColorsActive', true);
                                                    formProps.setFieldValue('isCustomBrandingColorsActive', true);
                                                    if (colorCode.length == 7) {
                                                      // saveColor({
                                                      //   colorName: 'PrimaryColorCode',
                                                      //   colorCode: colorCode,
                                                      //   // formValues: values,
                                                      // });
                                                    }
                                                  }}
                                                  InputProps={{
                                                    style: { color: themedColor },
                                                  }}
                                                  onBlur={formProps.handleBlur}
                                                  fullWidth
                                                />
                                              </div>
                                            </StyledColorCodeWrapper>
                                          </StyledColorPicker>
                                        </StyledColorSelector>
                                        <StyledColorSelector>
                                          <StyledTooltipWrapModal>
                                            <StyledHeading>Accent color</StyledHeading>
                                            <StyledTooltipModal
                                              title="The color will be associated with various tabs, labels, and misc. components on your branded pages."
                                              arrow
                                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                            >
                                              <InfoIcon htmlColor="#47627B" />
                                            </StyledTooltipModal>
                                          </StyledTooltipWrapModal>
                                          <StyledColorPicker>
                                            <ButtonExample
                                              colorCode={formProps.values.AccentColorCode}
                                              onchangevalue={code => {
                                                formProps.setFieldValue('AccentColorCode', code);
                                                formProps.setFieldValue('isLegacyColorsActive', true);
                                                formProps.setFieldValue('isCustomBrandingColorsActive', true);
                                                // saveColor({
                                                //   colorName: 'AccentColorCode',
                                                //   colorCode: code,
                                                //   // formValues: values,
                                                // });
                                              }}
                                            />
                                            <StyledColorCodeWrapper>
                                              <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                                              <div style={{ width: '85%' }}>
                                                <TextField
                                                  name="AccentColorCode"
                                                  value={formProps?.values?.AccentColorCode?.substring(1)}
                                                  type="text"
                                                  onChange={e => {
                                                    formProps.setFieldValue('isLegacyColorsActive', true);
                                                    formProps.setFieldValue('isCustomBrandingColorsActive', true);
                                                    const colorCode = '#' + e.target.value;
                                                    formProps.setFieldValue('AccentColorCode', colorCode);

                                                    if (colorCode.length == 7) {
                                                      // saveColor({
                                                      //   colorName: 'AccentColorCode',
                                                      //   colorCode: colorCode,
                                                      //   // formValues: values,
                                                      // });
                                                    }
                                                  }}
                                                  InputProps={{
                                                    style: { color: themedColor },
                                                  }}
                                                  onBlur={formProps.handleBlur}
                                                  fullWidth
                                                />
                                              </div>
                                            </StyledColorCodeWrapper>
                                          </StyledColorPicker>
                                        </StyledColorSelector>
                                        <StyledColorSelector>
                                          <StyledTooltipWrapModal>
                                            <StyledHeading>Tertiary color</StyledHeading>
                                            <StyledTooltipModal
                                              title="This color is only used if you have more than two categories on your Contribution."
                                              arrow
                                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                            >
                                              <InfoIcon htmlColor="#47627B" />
                                            </StyledTooltipModal>
                                          </StyledTooltipWrapModal>
                                          <StyledColorPicker>
                                            <ButtonExample
                                              colorCode={formProps.values.TertiaryColorCode}
                                              onchangevalue={code => {
                                                formProps.setFieldValue('isLegacyColorsActive', true);
                                                formProps.setFieldValue('TertiaryColorCode', code);

                                                // saveColor({
                                                //   colorName: 'TertiaryColorCode',
                                                //   colorCode: code,
                                                //   // formValues: values,
                                                // });
                                              }}
                                            />
                                            {/* <StyledColor colorCode={values.TertiaryColorCode} /> */}
                                            <StyledColorCodeWrapper>
                                              <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                                              <div style={{ width: '85%' }}>
                                                <TextField
                                                  value={formProps?.values?.TertiaryColorCode?.substring(1)}
                                                  name="TertiaryColorCode"
                                                  type="text"
                                                  onChange={e => {
                                                    formProps.setFieldValue('isLegacyColorsActive', true);
                                                    const colorCode = '#' + e.target.value;
                                                    formProps.setFieldValue('TertiaryColorCode', colorCode);

                                                    if (colorCode.length == 7) {
                                                      // saveColor({
                                                      //   colorName: 'TertiaryColorCode',
                                                      //   colorCode: colorCode,
                                                      //   // formValues: values,
                                                      // });
                                                    }
                                                  }}
                                                  InputProps={{
                                                    style: { color: themedColor },
                                                  }}
                                                  onBlur={formProps.handleBlur}
                                                  fullWidth
                                                />
                                              </div>
                                            </StyledColorCodeWrapper>
                                          </StyledColorPicker>
                                        </StyledColorSelector>
                                        {/* <StyledColorSelector>
                                          <StyledTooltipWrapModal>
                                            <StyledHeading>Text color</StyledHeading>
                                            <StyledTooltipModal
                                              title="This color is used show text color on your Contribution."
                                              arrow
                                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                            >
                                              <InfoIcon htmlColor="#47627B" />
                                            </StyledTooltipModal>
                                          </StyledTooltipWrapModal>

                                          <Select
                                            className={classes.colorSelect}
                                            label=""
                                            name="TextColorCode"
                                            fullWidth
                                            items={[
                                              { title: 'Black', value: '#000000' },
                                              { title: 'White', value: '#FFFFFF' },
                                              { title: 'Auto', value: 'Auto' },
                                            ]}
                                            value={formProps?.values?.TextColorCode}
                                          />
                                        </StyledColorSelector> */}
                                      </StyledColorSection>
                                    </Grid>
                                  </Grid>
                                  {formProps.errors && formProps.errors.PrimaryColorCode && (
                                    <p style={{ color: 'red' }}>{formProps.errors.PrimaryColorCode}</p>
                                  )}
                                  {formProps.errors && formProps.errors.AccentColorCode && (
                                    <p style={{ color: 'red' }}>{formProps.errors.AccentColorCode}</p>
                                  )}
                                  {formProps.errors && formProps.errors.TertiaryColorCode && (
                                    <p style={{ color: 'red' }}>{formProps.errors.TertiaryColorCode}</p>
                                  )}
                                  {formProps.errors && formProps.errors.TextColorCode && (
                                    <p style={{ color: 'red' }}>{formProps.errors.TextColorCode}</p>
                                  )}
                                </Modal>
                                <Modal
                                  title="Custom Branding Colors Set As Default"
                                  isOpen={openBrandingModalPopUp}
                                  // hiddenCancel
                                  // loading={saving}
                                  onCancel={() => {
                                    if (
                                      !formProps.errors?.PrimaryColorCode &&
                                      !formProps.errors?.AccentColorCode &&
                                      !formProps.errors?.TertiaryColorCode
                                    ) {
                                      const userData = {
                                        id: contributionId,
                                        brandingColors: {
                                          PrimaryColorCode: formProps.values.PrimaryColorCode,
                                          AccentColorCode: formProps.values.AccentColorCode,
                                          TertiaryColorCode: formProps.values.TertiaryColorCode,
                                          TextColorCode: formProps.values.TextColorCode,
                                        },
                                        customLogo: formProps.values.customLogo,
                                        isLegacyColorsActive: formProps.values.isLegacyColorsActive,
                                      };
                                      // saveData(formProps, to, true);
                                      editEmailUserColors(userData).then(res => {
                                        dispatch(fetchCohealerContribution(contributionId));
                                        dispatch(userActions.getUserSuccess(res));
                                        setOpenBrandingModal(false);
                                        setOpenBrandingModalPopUp(false);
                                      });
                                    }
                                    setOpenBrandingModalPopUp(false);
                                    setOpenBrandingModal(false);
                                  }}
                                  onSubmit={() => {
                                    if (
                                      !formProps.errors?.PrimaryColorCode &&
                                      !formProps.errors?.AccentColorCode &&
                                      !formProps.errors?.TertiaryColorCode &&
                                      !formProps.errors?.TextColorCode
                                    ) {
                                      const userData = {
                                        id: contribution.userId,
                                        brandingColors: {
                                          PrimaryColorCode: formProps.values.PrimaryColorCode,
                                          AccentColorCode: formProps.values.AccentColorCode,
                                          TertiaryColorCode: formProps.values.TertiaryColorCode,
                                          TextColorCode: formProps.values.TextColorCode,
                                        },
                                        customLogo: formProps.values.customLogo,
                                      };
                                      const contributionData = {
                                        id: contributionId,
                                        brandingColors: {
                                          PrimaryColorCode: formProps.values.PrimaryColorCode,
                                          AccentColorCode: formProps.values.AccentColorCode,
                                          TertiaryColorCode: formProps.values.TertiaryColorCode,
                                          TextColorCode: formProps.values.TextColorCode,
                                        },
                                        customLogo: formProps.values.customLogo,
                                        isLegacyColorsActive: formProps.values.isLegacyColorsActive,
                                      };
                                      editEmailUserColors(contributionData).then(res => {
                                        dispatch(fetchCohealerContribution(contributionId));
                                        editUserColors(userData).then(res => {
                                          setOpenBrandingModal(false);
                                          setOpenBrandingModalPopUp(false);
                                          dispatch(userActions.getUserSuccess(res));
                                        });
                                      });

                                      // editProfile(user?.id, userData);
                                    }
                                  }}
                                  cancelTitle="Just For This Contribution"
                                  submitTitle="Save To Profile And For This Contribution"
                                >
                                  <Grid container item style={{ marginBottom: '50px', width: '500px' }}>
                                    Do you also want to make these colors your default profile colors?
                                  </Grid>
                                </Modal>
                              </>
                            );
                          }}
                        </Formik>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <div
                      style={{ display: 'flex', alignItems: 'center', padding: '20px 0px', justifyContent: 'flex-end' }}
                    >
                      {/* <Checkbox
                        style={{
                          color: colorToUse.PrimaryColorCode,
                        }}
                        checked={brandingColorsActive}
                        onChange={({ target: { checked } }) => {
                          if (brandingColorsActive === true) {
                            enableBrandingColorsOnEmailTemplates(false, contributionId).then(() => {
                              getCommunicationData();
                            });
                          } else {
                            enableBrandingColorsOnEmailTemplates(true, contributionId).then(() => {
                              getCommunicationData();
                            });
                          }
                        }}
                      /> */}

                      <Link
                        // style={formProps.values.isCustomBrandingColorsActive ? {} : { pointerEvents: 'none' }}
                        onClick={() => {
                          setOpenBrandingModal(true);
                        }}
                      >
                        <p style={{ margin: '0px', padding: '0px' }}>Custom colors and branding logo </p>
                      </Link>
                    </div>
                  </Grid>
                </DropDownMainContainer>
                <Modal
                  applyTheming
                  isOpen={connectStripeModal}
                  onCancel={handleConnectStripeModalClose}
                  onSubmit={() => {
                    copySettings(selectedContributon);
                  }}
                  title="Copy Settings from Selected Contribution"
                  submitTitle="Yes"
                  cancelTitle="No"
                >
                  Do you agree to copy settings from {selectedContributon?.target?.value?.contributionName}{' '}
                  contribution?
                </Modal>
              </Grid>
            </Grid>
            {/* {smsNotification ? (
              <Grid container className={classes.MainContainer}>
               
              </Grid>
            ) : ( */}
            <Grid container className={classes.MainContainer}>
              <ContributionCommunicationPanel getApiCall={getApiCall} click={fun} />
              <SmsNotificationPanel getApiCall={getApiCall} click={fun} />
            </Grid>
            {/* )} */}
          </>
        )}
      </>
    </>
  );
}

export default ContributionCommunication;
