import React, { useState, useRef, useEffect, useMemo, memo } from 'react';

import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import { Tooltip } from '@material-ui/core';
import UploadThumbnailIcon from 'pages/CreateContribution/components/UploadThumbnailIcon';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TOOLTIP } from '../../constants';
import { PlayIcon } from 'pages/ContributionView/ContributionSessions/component/icons';
import { useAccount, useClientPreview, useScreenView, useVideoChat } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { UserRoles } from 'helpers/constants';
import { determineColorToUse } from 'services/contributions.service';
import { isEqual } from 'lodash';

const StyledUploadThumbnailContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 15px;
  z-index: 100;
  cursor: pointer;
`;
const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #215c73;
  font-family: 'Avenir';
  margin-top: 0.625rem;
`;

const StyledFooterContent = styled.div`
  color: ${({ accentColor }) => accentColor && accentColor};
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1rem; /* 114.286% */
`;

const StyledBackArrowIcon = styled(ArrowBackIosNewIcon)`
  cursor: pointer;
  font-size: 1rem;
  color: ${color => color};
`;
const StyledForwardArrowIcon = styled(ArrowForwardIosIcon)`
  cursor: pointer;
  font-size: 1rem;
  color: ${color => color};
`;
const StyledPlayButtonWrapper = styled.div`
  height: 52.045px;
  width: 52.045px;
  color: gray;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  border-radius: 46.263px;
  background: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 0.25rem;
  }
`;
const StyledPlayIcon = styled(PlayIcon)`
  height: 24.152px;
  width: 24.152px;
  color: #fff;
  position: absolute;
  z-index: 15;
  cursor: pointer;
`;
const StyledImg = styled.img`
  height: ${({ mobileView, hasThumbnail }) => (mobileView || hasThumbnail ? 'auto' : '435px')};

  /* width: 100%; */
  /* min-width: fit-content; */
  /* width: fit-content; */
  max-width: 100%;
  /* height: auto; */
`;
const StyledVideo = styled.video`
  height: ${({ mobileView, hasThumbnail }) => (mobileView || hasThumbnail ? 'auto' : '435px')};

  /* height: 435px; */
  /* width: 100%; */
  /* min-width: fit-content; */
  /* width: fit-content; */
  width: 100%;
  /* height: auto; */
`;
const StyledPlayableImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 435px; */
  /* width: 100% */
`;
const StyledVideoWrapper = styled.div`
  height: ${({ mobileView, hasThumbnail }) => (mobileView || hasThumbnail ? 'auto' : '435px')};
  width: 100%;

  background-color: black;
  display: flex !important;
  justify-content: center !important;
`;
const StyledVideoPlusThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const VideoCarousel = ({ videoSources = [], uploadThumbnail, poster, setCurrentRoomId, setCurrentVideoPoster }) => {
  const sliderRef = useRef(null);
  const videoRef = useRef(null);
  const [timeStamp, setTimeStamp] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [slideCount, setSlideCount] = useState(videoSources?.length);
  const [showVideo, setShowVideo] = useState(false);
  const { currentRole, user } = useAccount();
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);
  const { mobileView } = useScreenView();
  const isContributionPurchased = contribution.isPurchased;
  const isClient = currentRole === UserRoles.client;
  useEffect(() => {
    if (!showVideo) {
      setTimeStamp(0);
    }
  }, [showVideo]);

  const onPlay = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = timeStamp;
      video.play();
    }
  };

  const onPause = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setTimeStamp(video.duration && video.currentTime === video.duration ? 0 : video.currentTime);
      if (video.duration && video.currentTime === video.duration) {
        video.load();
      }
    }
  };
  const prevArrowHandler = () => {
    sliderRef.current.slickPrev();
    setShowVideo(false);
    if (currentSlide === 1) {
      setCurrentSlide(videoSources?.length);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextArrowHandler = () => {
    sliderRef.current.slickNext();
    setShowVideo(false);
    if (currentSlide === videoSources?.length) {
      setCurrentSlide(1);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const CustomFooter = ({ children }) => (
    <StyledFooter>
      <StyledBackArrowIcon onClick={prevArrowHandler} color={colorToUse?.AccentColorCode} />
      <StyledFooterContent accentColor={colorToUse?.AccentColorCode}>
        {currentSlide}/{slideCount}
      </StyledFooterContent>
      <StyledForwardArrowIcon onClick={nextArrowHandler} color={colorToUse?.AccentColorCode} />
    </StyledFooter>
  );
  const sliderConfig = useMemo(
    () => ({
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <></>,
      nextArrow: <></>,
      appendDots: () => <CustomFooter />,
      lazyLoad: false,
      // swipe: false,
      beforeChange: (current, next) => {
        // onPause(current);
        setTimeStamp(0);
        setShowVideo(false);
      },
      afterChange: current => {
        console.info('current-slide:', current);
        setCurrentSlide(current + 1);
      },
      onSwipe: swipeDirection => {
        setShowVideo(false);
      },
    }),
    [currentSlide, slideCount],
  );

  return (
    <Slider ref={sliderRef} {...sliderConfig}>
      {videoSources?.map((videoSource, index) =>
        isClient ? (
          <StyledVideoWrapper key={videoSource?.roomId} hasThumbnail={Boolean(videoSource?.poster || poster)}>
            {showVideo === false ? (
              <StyledPlayableImageContainer
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowVideo(true);
                  // setTimeout(() => {
                  //   onPlay();
                  // }, [2000]);
                }}
              >
                <StyledImg
                  src={videoSource?.poster || poster}
                  mobileView={mobileView}
                  hasThumbnail={Boolean(videoSource?.poster || poster)}
                />

                <StyledPlayButtonWrapper backgroundColor={colorToUse?.AccentColorCode}>
                  <StyledPlayIcon />
                </StyledPlayButtonWrapper>
              </StyledPlayableImageContainer>
            ) : (
              <StyledVideo
                ref={index === currentSlide - 1 ? videoRef : null}
                onPlay={onPlay}
                onPause={onPause}
                preload="none"
                controls
                poster={videoSource?.poster || poster}
                mobileView={mobileView}
              >
                <source src={videoSource?.link} />
              </StyledVideo>
            )}
          </StyledVideoWrapper>
        ) : (
          <>
            {' '}
            <StyledVideoWrapper key={videoSource?.roomId} hasThumbnail={Boolean(videoSource?.poster || poster)}>
              <StyledVideoPlusThumbnailContainer className="position-relative w-100">
                <Tooltip
                  title="Thumbnail"
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <StyledUploadThumbnailContainer
                    onClick={() => {
                      setCurrentRoomId(videoSource.roomId);
                      setCurrentVideoPoster(videoSource?.poster);
                      uploadThumbnail(true);
                    }}
                  >
                    <UploadThumbnailIcon style={{ width: '36px', height: '37px', backgroundColor: 'transparent' }} />
                  </StyledUploadThumbnailContainer>
                </Tooltip>
                {showVideo === false ? (
                  <StyledPlayableImageContainer
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowVideo(true);
                      // setTimeout(() => {
                      //   onPlay();
                      // }, [2000]);
                    }}
                  >
                    <StyledImg
                      src={videoSource?.poster || poster}
                      mobileView={mobileView}
                      hasThumbnail={Boolean(videoSource?.poster || poster)}
                    />
                    <StyledPlayButtonWrapper backgroundColor={colorToUse?.AccentColorCode}>
                      <StyledPlayIcon />
                    </StyledPlayButtonWrapper>
                  </StyledPlayableImageContainer>
                ) : (
                  <>
                    {' '}
                    <StyledVideo
                      ref={index === currentSlide - 1 ? videoRef : null}
                      onPlay={onPlay}
                      onPause={onPause}
                      preload="none"
                      controls
                      poster={videoSource?.poster || poster}
                      mobileView={mobileView}
                    >
                      <source src={videoSource?.link} />
                    </StyledVideo>
                  </>
                )}
                {/* <video
                  preload="none"
                  controls
                  poster={videoSource.poster ? videoSource.poster : poster}
                  style={{ height: '465px', width: '100%' }}
                >
                  <source src={videoSource.link} />
                </video> */}
              </StyledVideoPlusThumbnailContainer>
            </StyledVideoWrapper>
          </>
        ),
      )}
    </Slider>
  );
};

export default memo(VideoCarousel, isEqual);
