import React from 'react';
import styled from 'styled-components';
import { Card as UICard } from 'components/UI/Card';
import { devices } from 'utils/styles';
const CONTAINER_WIDTH = 600;
const StyledCard = styled(UICard)`
  max-width: ${CONTAINER_WIDTH}px;
  margin: auto;
`;
const Container = styled.div`
  max-width: ${CONTAINER_WIDTH}px;
  min-width: ${CONTAINER_WIDTH}px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
  //   @media screen and (${devices.mobile}) {
  //     padding: 40px 100px;
  //   }
`;
const StyledDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
`;
const StyledHeader = styled.div`
  font-family: Avenir;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
`;
const Card = ({ header, children }) => {
  return (
    <StyledCard>
      <Container>
        {header && <StyledHeader>{header}</StyledHeader>}
        <StyledDivider />
        {children && children}
      </Container>
    </StyledCard>
  );
};
export default Card;
