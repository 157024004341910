import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Logo from 'components/FormUI/Logo';
import { useLocation, useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Loader from 'components/UI/Loader';
import { useAccount, useHttp, useRouter } from 'hooks/index';
import { useDispatch } from 'react-redux';

import Button from 'components/FormUI/Button';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import { useContribution } from 'pages/ContributionView/hooks';

const ApprovedPost = ({}) => {
  const { status, postId, contributionId } = useParams();
  const { history, location, query, domain, pathname } = useRouter();
  const [apiError, setApiError] = useState(null);
  const [apiResponse, setApiResonse] = useState(null);
  const { request } = useHttp();
  const dispatch = useDispatch();
  const { user } = useAccount();
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  useEffect(() => {
    request(`/Post/ApproveRejectPost?postId=${postId}&isApproved=${status === 'approved' ? true : false}`, 'PUT')
      .then(res => {
        console.log('RES----->', res);
        setApiResonse(res);
      })
      .catch(err => {
        console.log('RESErr----->', err.response.data);
        setApiError(err.response.data);
      });
  }, []);

  if (isEmpty(user)) {
    return <></>;
  }
  return (
    <Grid container style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      {apiError === null && apiResponse != null ? (
        <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '50px', display: 'grid' }}>
          <Logo />
          {status === 'approved' ? (
            <div style={{ justifyContent: 'center', alignItems: 'center' }}>Post approved.</div>
          ) : (
            <div style={{ justifyContent: 'center', alignItems: 'center' }}>Post declined.</div>
          )}
          <Button
            backgroundColor={colorToUse?.PrimaryColorCode}
            textColor={textColor}
            style={{ marginTop: '20px' }}
            autoWidth
            onClick={() => {
              history.push(`/contribution-view/${contributionId}/community`);
            }}
          >
            Go To Community
          </Button>
        </div>
      ) : apiError != null && apiResponse === null ? (
        <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '50px', display: 'grid' }}>
          <Logo />
          <div>{apiError}</div>
          <Button
            backgroundColor={colorToUse?.PrimaryColorCode}
            textColor={textColor}
            style={{ marginTop: '20px' }}
            autoWidth
            onClick={() => {
              history.push(`/contribution-view/${contributionId}/community`);
            }}
          >
            Go To Community
          </Button>
        </div>
      ) : (
        <Loader />
      )}
    </Grid>
  );
};

export default ApprovedPost;
