import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import Button from '@material-ui/core/Button';
import { downloadFileByLink, lightOrDark } from 'utils/utils';
import { useHttp } from 'hooks';
import './SessionTime2.scss';
import { TemplateType } from 'helpers/constants/templateType';
import { useAccount, useShallowEqualSelector } from 'hooks';

import './SessionTime.scss';
import { UserRoles } from 'helpers/constants';
import { FormControl, InputLabel, MenuItem, Paper, Select, useMediaQuery } from '@material-ui/core';
import { BiCalendar } from 'react-icons/bi';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import styled from 'styled-components';
import { addToGoogleCalender } from 'services/contributions.service';
import { useSelector } from 'react-redux';

const CalendarContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const CalendarMenu = styled.div`
  width: 220px;
  background-color: white;
  color: black;
  box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  position: absolute;
  ${({ mobileView }) => (mobileView ? `margin-top: 95px;` : `margin-top: 75px; `)}
  z-index: 1000;
`;

const SessionTime = ({
  className,
  // startTime,
  // endTime,
  // participantId,
  session,
  timeDetails,
  session: { id, isCompleted, startTime, endTime, attachments, participantId },
  booked,
  renderActions,
  activeTemplate,
  isRescheduleDisabled,
  handleReschedule,
  btnColor = { btnColor },
  textColor = { textColor },
  mobileView,
}) => {
  const { request } = useHttp();
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const dropdownRef = useRef(null);
  const [isVisibleCalendarDropdown, setIsVisibleCalendarDropdown] = useState(false);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const { firstName, lastName } = useShallowEqualSelector(
    state =>
      (state?.contributions?.activeContribution?.participants || []).find(client => client.id === participantId) || {},
  );

  const startTimeMoment = moment(startTime);
  const endTimeMoment = moment(endTime);
  const duration = humanizeDuration(endTimeMoment.diff(startTimeMoment));

  const handleDownloadFile = async fileName => {
    const result = await request(
      `/Zoom/GetPresignedUrlForRecordedSession/${session.zoomMeetingData.meetingId}/${fileName}?asAttachment=true`,
      'GET',
    );
    downloadFileByLink(result, fileName);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const onClickAddToGoogleCalender = async (type = false) => {
    const data = {
      contributionId: activeContribution.id,
      AvailabilityTimeId: timeDetails?.timeId,
      isCoach,
      isDownloadable: type === 'Download',
    };
    const addToCalenderURL = await addToGoogleCalender(data);
    window.open(addToCalenderURL, '_blank');
    setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown);
  };

  const handleOutsideClick = e => {
    if (!isVisibleCalendarDropdown) {
      return;
    }
    if (
      dropdownRef &&
      dropdownRef?.current &&
      !e?.target?.className?.includes('clickedOutSide') &&
      !dropdownRef?.current?.contains(e.target)
    ) {
      setIsVisibleCalendarDropdown(false);
    }
  };

  const chatFiles = session?.zoomMeetingData?.chatFiles?.map(chatFile => (
    <div key={chatFile} className="course-session-time__actions" style={{ width: '100%' }}>
      <Button
        classes={{
          root: 'text-lowercase regular-tex download-attach',
        }}
        onClick={() => handleDownloadFile(chatFile)}
        disableRipple
      >
        <span className="ml-2">{chatFile}</span>
      </Button>
    </div>
  ));
  return (
    <>
      {activeTemplate != TemplateType.TemplateThree && !isCoach && mobileView ? (
        <>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '50%', display: 'flex' }}>
              {startTime && (
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold' }}>Date</p>
                  <p style={{ marginLeft: '7px' }}>{startTimeMoment.format('MMM Do YYYY')}</p>
                </div>
              )}
            </div>
            <div style={{ width: '50%' }}>
              {startTime && (
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold' }}>Time</p>
                  <p style={{ marginLeft: '7px' }}>
                    {startTimeMoment.format('h:mma')}-{endTimeMoment.format('h:mma')}
                  </p>
                </div>
              )}
            </div>
          </div>
          {!activeContribution.externalCalendarEmail && session?.id && (
            <div style={{ width: 200 }}>
              <p
                ref={dropdownRef}
                onClick={() => setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown)}
                style={{ cursor: 'pointer' }}
                className="one-to-one-session-time__label one-to-one-session-time__label--type--duration clickedOutSide"
              >
                <BiCalendar style={{ fontSize: 20 }} /> Add to Calendar{' '}
                {!isVisibleCalendarDropdown ? (
                  <RiArrowDropDownLine style={{ fontSize: 20 }} />
                ) : (
                  <RiArrowDropUpLine style={{ fontSize: 20 }} />
                )}
              </p>
              {isVisibleCalendarDropdown && (
                <CalendarContainer className="clickedOutSide" ref={dropdownRef}>
                  <CalendarMenu className="clickedOutSide" mobileView={mobileView}>
                    <MenuItem
                      onClick={() => onClickAddToGoogleCalender('Download')}
                      style={{ display: 'flex', justifyContent: 'flex-start', borderBottom: '0.5px solid gray' }}
                    >
                      Download iCal
                    </MenuItem>
                    <MenuItem
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                      onClick={() => onClickAddToGoogleCalender()}
                    >
                      Add to Google Calendar
                    </MenuItem>
                  </CalendarMenu>
                </CalendarContainer>
              )}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'row' }}>
            {activeTemplate === TemplateType.TemplateThree || isCoach || !session?.id ? (
              <>
                <div className="one-to-one-session-time__actions">{renderActions(session)}</div>
              </>
            ) : (
              <>
                {session?.id && (
                  <button
                    type="button"
                    onClick={handleReschedule}
                    disabled={isRescheduleDisabled}
                    className="course-session-summary__reschedule2"
                    style={{ backgroundColor: btnColor, width: '100%', marginRight: '0px' }}
                  >
                    Reschedule
                  </button>
                )}
              </>
            )}
          </div>
          <div>{session?.zoomMeetingData?.chatFiles?.length > 0 && <>{chatFiles}</>}</div>
          <hr />
        </>
      ) : (
        <>
          <div
            className={classNames(className, 'one-to-one-session-time')}
            style={activeTemplate === TemplateType.TemplateThree || isCoach ? {} : { flexWrap: isIpad ? '' : 'nowrap' }}
          >
            {startTime && (
              <div style={activeTemplate === TemplateType.TemplateThree || isCoach ? {} : { width: '200px' }}>
                <p className="one-to-one-session-time__label one-to-one-session-time__label--type--date">Date</p>
                <p className="one-to-one-session-time__value one-to-one-session-time__value--type--date">
                  {startTimeMoment.format('MMM Do YYYY')}
                </p>
              </div>
            )}
            {startTime && (
              <div style={!isCoach && activeTemplate != TemplateType.TemplateThree ? { width: '20%' } : {}}>
                <p className="one-to-one-session-time__label one-to-one-session-time__label--type--start-time">
                  Start Time
                </p>
                <p className="one-to-one-session-time__value one-to-one-session-time__value--type--start-time">
                  {startTimeMoment.format('h:mm a')}
                </p>
              </div>
            )}
            {endTime && (
              <div>
                <p className="one-to-one-session-time__label one-to-one-session-time__label--type--end-time">
                  End Time
                </p>
                <p className="one-to-one-session-time__value one-to-one-session-time__value--type--end-time">
                  {endTimeMoment.format('h:mm a')}
                </p>
              </div>
            )}
            {startTime && (activeTemplate === TemplateType.TemplateThree || isCoach) && (
              <div>
                <p className="one-to-one-session-time__label one-to-one-session-time__label--type--duration">
                  Duration
                </p>
                <p className="one-to-one-session-time__value one-to-one-session-time__value--type--duration">
                  {duration}
                </p>
              </div>
            )}
            {!activeContribution.externalCalendarEmail && session?.id && (
              <div style={{ width: 200 }}>
                <p
                  ref={dropdownRef}
                  onClick={() => setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown)}
                  style={{ cursor: 'pointer' }}
                  className="one-to-one-session-time__label one-to-one-session-time__label--type--duration clickedOutSide"
                >
                  <BiCalendar style={{ fontSize: 20 }} /> Add to Calendar{' '}
                  {!isVisibleCalendarDropdown ? (
                    <RiArrowDropDownLine style={{ fontSize: 20 }} />
                  ) : (
                    <RiArrowDropUpLine style={{ fontSize: 20 }} />
                  )}
                </p>
                {isVisibleCalendarDropdown && (
                  <CalendarContainer className="clickedOutSide" ref={dropdownRef}>
                    <CalendarMenu className="clickedOutSide" mobileView={mobileView}>
                      <MenuItem
                        onClick={() => onClickAddToGoogleCalender('Download')}
                        style={{ display: 'flex', justifyContent: 'flex-start', borderBottom: '0.5px solid gray' }}
                      >
                        Download iCal
                      </MenuItem>
                      <MenuItem
                        style={{ display: 'flex', justifyContent: 'flex-start' }}
                        onClick={() => onClickAddToGoogleCalender()}
                      >
                        Add to Google Calendar
                      </MenuItem>
                    </CalendarMenu>
                  </CalendarContainer>
                )}
              </div>
            )}
            {isCoach ? (
              <></>
            ) : (
              // <div>
              //   <p className="one-to-one-session-time__label one-to-one-session-time__label--type--booked">Client</p>
              //   <p className="one-to-one-session-time__value one-to-one-session-time__value--type--booked">
              //     {`${firstName} ${lastName}`}
              //   </p>
              // </div>
              booked && (
                <div>
                  <p className="one-to-one-session-time__label one-to-one-session-time__label--type--booked">Booked</p>
                  <p className="one-to-one-session-time__value one-to-one-session-time__value--type--booked">
                    {booked ? 'Yes' : 'No'}
                  </p>
                </div>
              )
            )}
            {activeTemplate === TemplateType.TemplateThree || isCoach || !session?.id ? (
              <div className="one-to-one-session-time__actions">{renderActions(session)}</div>
            ) : (
              <>
                {session?.id && (
                  <button
                    type="button"
                    onClick={handleReschedule}
                    disabled={isRescheduleDisabled}
                    className="course-session-summary__reschedule2"
                    style={isRescheduleDisabled ? {} : { backgroundColor: btnColor, color: textColor, border: '0px' }}
                  >
                    Reschedule
                  </button>
                )}
              </>
            )}
          </div>
          <div>{session?.zoomMeetingData?.chatFiles?.length > 0 && <>{chatFiles}</>}</div>
          {activeTemplate != TemplateType.TemplateThree && !isCoach && <hr />}
        </>
      )}
    </>
  );
};

SessionTime.defaultProps = {
  startTime: '',
  endTime: '',
  participantId: '',
  booked: false,
  session: {},
};

SessionTime.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  className: PropTypes.string.isRequired,
  participantId: PropTypes.string,
  booked: PropTypes.bool,
  renderActions: PropTypes.func.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    isCompleted: PropTypes.bool,
    participantId: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default SessionTime;
