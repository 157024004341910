import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';

import { useAccount, useEndVideoChat, useRouter, useShallowEqualSelector, useClientPreview } from 'hooks';
import * as contributionActions from 'actions/contributions';
import { PAYMENTS_STATUS } from 'pages/ContributionView/components/PurchaseMembershipModal/PurchaseModal.constants';

import Banner from 'components/Banner/Banner';

import { ContributionType, UserRoles } from 'helpers/constants';

import TestimonialsBlock from 'pages/ContributionView/components/TestimonialsBlock';
import { fetchTestimonials } from 'actions/testimonials';
import { isTestimonialsVisible } from 'selectors/testimonials';
import { isNil } from 'ramda';
import AboutMainInfoBlock from '../components/AboutMainInfoBlock/AboutMainInfoBlock';
import AboutGeneralInfoBlock from '../components/AboutGeneralInfoBlock';
import AboutDetailsBlock from '../components/AboutDetailsBlock';
import EnrollmentBlock from '../components/EnrollmentBlock';
import AboutPaymentBlock from '../components/AboutPaymentBlock';
import PurchaseBlock from '../components/PurchaseBlock/PurchaseBlock';
import { PAYMENT_OPTIONS } from '../../../constants';
import { sameAccountPopUp } from 'actions/user';
import Modal from 'components/UI/Modal';
import GoogleAnalytics from 'components/AdsComponents/GoogleTag';
import OptinPageMetaPixelContrib from 'components/AdsComponents/OptinPageContrib';
import ClarityScript from 'components/AdsComponents/ClarityScript';

function AboutContribution({ contribution, getClientContribution, getCohealerContribution, currentRole }) {
  const {
    id,
    previewContentUrls,
    categories,
    serviceProviderName,
    gender,
    minAge,
    languageCodes,
    whoIAm,
    whatWeDo,
    purpose,
    preparation,
    earnedRevenue,
    paymentInfo,
    enrollment,
    closestClassForBanner,
    contributionPartners,
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    tiktokUrl,
    facebookUrl,
    websiteUrl,
    whoIAmLabel,
    whatWeDoLabel,
    purposeLabel,
    preparationLabel,
    whoIAmIcon,
    whatWeDoIcon,
    purposeIcon,
    preparationIcon,
    brandingColors,
    isPurchased,
    type,
    viewPreparation,
    viewPurpose,
    viewWhatWeDo,
    viewWhoIAm,
  } = contribution;
  const urls = {
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    facebookUrl,
    websiteUrl,
    tiktokUrl,
  };
  const { history, query } = useRouter();
  const { clientPreviewMode } = useClientPreview();
  const { userId } = useShallowEqualSelector(state => state?.contributions?.activeContribution || '');
  const { showSameCoachJoinPopUp } = useSelector(state => state.account);
  const [showUnableJoin, setshowUnableJoin] = useState(showSameCoachJoinPopUp);
  const { user } = useAccount();
  const isTestimonialsEnabled = useSelector(isTestimonialsVisible);
  const dispatch = useDispatch();
  // const hasAnyValue = !viewWhoIAm || !viewWhatWeDo || !viewPurpose || !viewPreparation;
  const hasAnyValue =
    (!viewWhoIAm && !!whoIAm) ||
    (!viewWhatWeDo && !!whatWeDo) ||
    (!viewPurpose && !!purpose) ||
    (!viewPreparation && !!preparation);
  const refreshContribution = useCallback(() => {
    if (currentRole === UserRoles.client) {
      getClientContribution(id);
    } else if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
      getCohealerContribution(id);
    }
  }, [id, currentRole, getClientContribution, getCohealerContribution]);

  const { Popup } = useEndVideoChat(refreshContribution);
  const isEnrollmentVisible = enrollment && !enrollment.anyTime;
  const isPurchaseBlock = [
    ContributionType.contributionCourse,
    ContributionType.contributionMembership,
    ContributionType.contributionCommunity,
    // ContributionType.contributionOneToOne,
  ].includes(type);

  const [shouldRedirect, setShouldRedirect] = useState(false);
  // redirect after joining
  useEffect(() => {
    if (isPurchased) {
      if (query.payment === PAYMENTS_STATUS.SUCCESS) {
        if (contribution?.thankYouPageRedirectUrl?.length > 0) {
          // commented since it can be blocked on mobile that treats it like a pop up
          // window.open(contribution.thankYouPageRedirectUrl);
          window.location.href = contribution.thankYouPageRedirectUrl;
        } else {
          history.push(`/contribution-view/${id}/sessions`);
        }
      }

      if (shouldRedirect) {
        if (contribution?.thankYouPageRedirectUrl?.length > 0) {
          // commented since it can be blocked on mobile that treats it like a pop up
          // window.open(contribution.thankYouPageRedirectUrl);
          window.location.href = contribution.thankYouPageRedirectUrl;
        } else {
          dispatch(contributionActions.setInviteCode(null));
          history.push(`/contribution-view/${id}/sessions`);
          setTimeout(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }, 1000);
        }
      }
    }
  }, [dispatch, history, id, isPurchased, query.payment, shouldRedirect, query.code]);

  // useEffect(() => {
  //   if (!isNil(query?.code)) {
  //     dispatch(contributionActions.setInviteCode(query?.code));
  //   }
  //   // else {
  //   //   dispatch(contributionActions.setInviteCode(null));
  //   // }
  // }, [dispatch, query?.code]);

  useEffect(() => {
    dispatch(fetchTestimonials(id));
  }, [dispatch, id]);
  return (
    !isEmpty(contribution) && (
      <>
        {id === '66466c79ee9e6c514da9d7f0' && <GoogleAnalytics />}
        {id === '66466c79ee9e6c514da9d7f0' && <OptinPageMetaPixelContrib />}
        {id === '66466c79ee9e6c514da9d7f0' && <ClarityScript />}
        <Banner type="closestSession" closestClassForBanner={closestClassForBanner} />
        <Grid container spacing={4} style={{ width: '100%', margin: '0' }}>
          <Grid item xs={12}>
            <AboutMainInfoBlock
              id={id}
              user={user}
              userId={userId}
              previewContentUrls={previewContentUrls}
              serviceProviderName={serviceProviderName}
              contributionPartners={contributionPartners}
              socialUrls={urls}
              contribution={contribution}
            />
          </Grid>
          <Grid item xs={12}>
            <AboutGeneralInfoBlock
              contribution={contribution}
              categories={categories}
              gender={gender}
              minAge={minAge}
              languageCodes={languageCodes}
            />
          </Grid>
          {hasAnyValue && (
            <Grid item xs={12}>
              <AboutDetailsBlock
                whoIAm={whoIAm}
                whatWeDo={whatWeDo}
                purpose={purpose}
                preparation={preparation}
                whoIAmLabel={whoIAmLabel}
                whatWeDoLabel={whatWeDoLabel}
                purposeLabel={purposeLabel}
                preparationLabel={preparationLabel}
                viewPreparation={viewPreparation}
                viewPurpose={viewPurpose}
                viewWhatWeDo={viewWhatWeDo}
                viewWhoIAm={viewWhoIAm}
                whoIAmIcon={whoIAmIcon}
                whatWeDoIcon={whatWeDoIcon}
                purposeIcon={purposeIcon}
                preparationIcon={preparationIcon}
                brandingColors={brandingColors}
              />
            </Grid>
          )}

          {!!isTestimonialsEnabled && (
            <Grid item xs={12}>
              <TestimonialsBlock />
            </Grid>
          )}
          {isEnrollmentVisible && (
            <Grid item xs={12}>
              <EnrollmentBlock enrollment={enrollment} />
            </Grid>
          )}
          {isPurchaseBlock && (currentRole !== UserRoles.cohealer || clientPreviewMode) && !isPurchased && (
            <Grid item xs={12}>
              <PurchaseBlock onJoin={setShouldRedirect} />
            </Grid>
          )}
          {!isPurchaseBlock &&
            !(
              (paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREE) && paymentInfo.paymentOptions.length === 1) ||
              (paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE) &&
                paymentInfo.paymentOptions.length === 1) ||
              (paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE) &&
                paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.FREE) &&
                paymentInfo.paymentOptions.length === 2)
            ) && (
              <Grid item xs={12}>
                <AboutPaymentBlock
                  contribution={contribution}
                  earnedRevenue={earnedRevenue}
                  paymentInfo={paymentInfo}
                  onJoin={setShouldRedirect}
                />
              </Grid>
            )}
        </Grid>
        {currentRole === UserRoles.cohealer && <Popup applyTheming />}
      </>
    )
  );
}

const mapStateToProps = ({ contributions: { activeContribution, loading }, account }) => ({
  contribution: activeContribution || {},
  loading,
  currentRole: account?.currentRole,
});

const actions = {
  getClientContribution: contributionActions.fetchClientContribution,
  getCohealerContribution: contributionActions.fetchCohealerContribution,
};

AboutContribution.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
  contribution: PropTypes.objectOf(PropTypes.any),
  getClientContribution: PropTypes.func.isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  currentRole: PropTypes.string.isRequired,
};

AboutContribution.defaultProps = {
  contribution: {},
};

export default connect(mapStateToProps, actions)(AboutContribution);
