import React, { useEffect, useRef, useState } from 'react';
import classes from './TitlePage.module.scss';
import CreateCampaignMain from 'pages/CreateCampaign/Components/Containers/MainContainer/MainContainer';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Buttons from 'pages/CreateContribution/components/Buttons';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { useTheme, useMediaQuery, Box, MenuItem, OutlinedInput, Tooltip } from '@material-ui/core';
import useRouter from 'hooks/useRouter';
import Stepper from 'pages/CreateCampaign/Components/Stepper/Stepper';
import Input from 'components/FormUI/Input';
import LabelInput from 'pages/CreateCampaign/Components/LabelInput/LabelInput';
import Select from 'components/FormUI/Select';
import verfiedIcon from 'assets/verified.png';
import { Icon } from '@mdi/react';
import { mdiContentCopy } from '@mdi/js';
import notverifiedIcon from 'assets/notverified.png';
import { createDraftCampaignAsync, saveCampaignToLS, setSendEmailTo } from 'actions/campaign';
import { useDispatch, useSelector } from 'react-redux';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';
import { createCampaignServices } from 'pages/CreateCampaign/Services/CreateCampaign.service';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import Loader from 'components/UI/Loader';
import Modal from 'components/UI/Modal';
import { EmailMarketingIntegrationService } from 'integrations/EmailMarketing/service/marketingAccountIntegration.service';
import MUISelect from 'components/UI/Select';
import TextField from '@mui/material/TextField';
import { setAccount, setDomains } from 'actions/emailMarketing.actions';
import useAccount from 'hooks/useAccount';
import { CampaignStatus } from 'pages/CreateCampaign/Constants/constants';
import { ArrowDropDownIcon } from '../DesignPage/assests/SvgImages';
import copyToClipboard from 'utils/copyToClipboard';

const TitlePage = () => {
  const underLineColorOfField = '#DFE3E4';
  const campaignFormRef = useRef();
  const [isLoading, setIsLoading] = useState();
  const { user } = useAccount();
  const { query } = useRouter();
  const isEditing = !!query?.id;
  const [showSelect, setShowSelect] = useState(false);
  const [email, setEmail] = useState('');
  const [addEmailPopUpOpen, setAddEmailPopUpOpen] = useState(false);
  const [accountCreationError, setAccountCreationError] = useState('');
  const [isToolTipOpenSubject, setIsToolTipOpenSubject] = useState(false);
  const [isSubjectPersonalizeDropDownOpen, setIsSubjectPersonalizeDropDownOpen] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { history, location } = useRouter();
  console.log('titlepagelocation>>', location);
  const dispatch = useDispatch();
  const {
    domains,
    verifiedDomains,
    verifiedSendingAddresses,
    defaultVerifiedSendingAddress,
    isLoading: domainsLoading,
  } = useEmailMarketing();
  const { campaign } = useCampaign();
  const isApprovedCampaign = campaign?.status === CampaignStatus.Approved;
  const [selectedDomain, setSelectedDomain] = useState(verifiedDomains[0]?.domain);

  useEffect(() => {
    if (defaultVerifiedSendingAddress) {
      setShowSelect(true);
    }
  }, [defaultVerifiedSendingAddress]);

  useEffect(() => {
    if (verifiedDomains.length > 0) {
      setSelectedDomain(verifiedDomains[0]?.domain);
    } else {
      setSelectedDomain('');
    }
  }, [verifiedDomains]);

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const submitHandler = (values, to) => {
    setIsLoading(true);
    let data = {
      ...campaign,
      ...values,
    };

    if (data?.id) {
      if (isEditing && isApprovedCampaign) {
        createCampaignServices
          .updateApprovedCampaign(data)
          .then(res => {
            dispatch(saveCampaignToLS(res));
            history.replace(redirectTo);
          })
          .catch(err => {
            console.log('err', err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        createCampaignServices
          .updateDraftCampaign(data)
          .then(res => {
            dispatch(saveCampaignToLS(res));
            history.replace(redirectTo);
          })
          .catch(err => {
            console.log('err', err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      createCampaignServices
        .createDraftCampaign(data)
        .then(res => {
          dispatch(saveCampaignToLS(res));
          history.replace(redirectTo);
        })
        .catch(err => {
          console.log('err', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    // dispatch(createDraftCampaignAsync(values));
  };

  const handleBackClick = () => {
    dispatch(setSendEmailTo(null));
    history.goBack();
    if (history.length === 1) {
      history.push('/dashboard');
    }
  };
  return (
    <>
      <CreateCampaignMain
        headerProps={{
          handleBackClick,
        }}
      >
        <Formik
          innerRef={campaignFormRef}
          enableReinitialize
          initialValues={{
            userId: campaign?.userId || user?.id,
            title: campaign?.title || '',
            emailSubject: campaign?.emailSubject || '',
            preHeaderText: campaign?.preHeaderText || '',
            fromName: campaign?.fromName || '',
            fromEmail: campaign?.fromEmail || defaultVerifiedSendingAddress?.email,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(100, 'Title can be a max of 100 characters').required('This is a required field'),
            emailSubject: Yup.string()
              .max(100, 'Subject can be a max of 100 characters')
              .required('This is a required field'),
            preHeaderText: Yup.string().max(100, 'Preheader can be a max of 100 characters'),
            // .required('This is a required field'),
            fromName: Yup.string()
              .max(100, 'Form Name can be a max of 100 characters')
              .required('This is a required field'),
            fromEmail: Yup.string().required('This is required field'),
          })}
          onSubmit={submitHandler}
        >
          {({ values, setFieldValue, handleBlur, handleChange, handleSubmit, errors, touched }) => {
            const handleSendingAddressChange = ({ target: { name, value } }) => {
              if (value === 'Add Another Email') {
                setAddEmailPopUpOpen(true);
              } else {
                setFieldValue(name, value);
              }
            };
            return (
              <>
                {(isLoading || domainsLoading) && <Loader />}
                <Card id={'createCampaign'} mobileView={mobileView}>
                  <CardHeader mobileView={mobileView}>
                    <div className={classes.stepper_container}>
                      <Stepper
                        activeStep={0}
                        handleSubmit={({ to }) => {
                          setRedirectTo(to);
                          handleSubmit(values);
                        }}
                      />
                    </div>
                  </CardHeader>
                  <CardBody className={classes.form_body} mobileView={mobileView}>
                    <form className={classes.form_container}>
                      <div className={classes.title_class}>Campaign Details</div>
                      <div className={classes.field_container}>
                        <LabelInput
                          tooltipText="This is the title of the Campaign, so you can find it easily."
                          label={'Campaign Title'}
                        />
                        <Input
                          underLineColor={underLineColorOfField}
                          type="text"
                          name="title"
                          fullWidth
                          redError={true}
                          inputClasses={{
                            error: classes.error,
                          }}
                        />
                      </div>
                      <div className={classes.field_container}>
                        <LabelInput tooltipText="This is the subject line of your email." label={'Email Subject'} />
                        <Input
                          underLineColor={underLineColorOfField}
                          type="text"
                          name="emailSubject"
                          fullWidth
                          redError={true}
                          inputClasses={{
                            error: classes.error,
                          }}
                          // endAdornment={
                          //   <>
                          //     <Tooltip
                          //       className="cursor-pointer"
                          //       title="Copied!"
                          //       open={isToolTipOpenSubject}
                          //       onClose={() => {
                          //         setTimeout(() => {
                          //           setIsToolTipOpenSubject(false);
                          //         }, 2000);
                          //       }}
                          //       arrow
                          //     >
                          //       <div
                          //         className={classes.personalize_dropdown}
                          //         onClick={() => {
                          //           setIsSubjectPersonalizeDropDownOpen(!isSubjectPersonalizeDropDownOpen);
                          //         }}
                          //       >
                          //         <ArrowDropDownIcon onClick={() => {}} />
                          //       </div>
                          //     </Tooltip>
                          //     {isSubjectPersonalizeDropDownOpen && (
                          //       <div
                          //         style={{ position: 'absolute', top: '35px', right: 0, zIndex: 100 }}
                          //         className={classes.personalize_dropdownpanel}
                          //       >
                          //         <div
                          //           className={classes.personalize_dropdownpanel_item}
                          //           onClick={e => {
                          //             copyToClipboard('{{first_name}}');
                          //             setIsSubjectPersonalizeDropDownOpen(false);
                          //             setIsToolTipOpenSubject(true);
                          //           }}
                          //         >
                          //           {`First Name`}
                          //           <Icon size={0.7} color={'#4a4a4a'} path={mdiContentCopy} />
                          //         </div>
                          //         <div
                          //           className={classes.personalize_dropdownpanel_item_last}
                          //           onClick={e => {
                          //             copyToClipboard('{{last_name}}');
                          //             setIsSubjectPersonalizeDropDownOpen(false);
                          //             setIsToolTipOpenSubject(true);
                          //           }}
                          //         >
                          //           {`Last Name`}
                          //           <Icon size={0.7} color={'#4a4a4a'} path={mdiContentCopy} />
                          //         </div>
                          //       </div>
                          //     )}
                          //   </>
                          // }
                        />
                      </div>
                      <div className={classes.field_container}>
                        <LabelInput
                          tooltipText="Text that appears after your subject line. If this field is left blank, the first line of your email will display."
                          label={'Preheader'}
                        />
                        <Input
                          underLineColor={underLineColorOfField}
                          type="text"
                          name="preHeaderText"
                          fullWidth
                          redError={true}
                          inputClasses={{
                            error: classes.error,
                          }}
                        />
                      </div>
                      <div className={classes.field_container}>
                        <LabelInput tooltipText="This is the name your email will be sent from." label={'From Name'} />
                        <Input
                          underLineColor={underLineColorOfField}
                          type="text"
                          name="fromName"
                          fullWidth
                          redError={true}
                          inputClasses={{
                            error: classes.error,
                          }}
                        />
                      </div>
                      {showSelect && (
                        <div className={classes.field_container}>
                          <LabelInput
                            tooltipText="This is the email address you are sending from."
                            label={'Sending Address'}
                          />
                          <Select
                            helperTextErrorColor="red"
                            underLineColor={underLineColorOfField}
                            className={classes.select}
                            name="fromEmail"
                            onChange={handleSendingAddressChange}
                            items={verifiedSendingAddresses?.concat([{ email: 'Add Another Email' }]).map(item => ({
                              title: item.email,
                              value: item.email,
                              customIcon: item?.isVerified ? verfiedIcon : notverifiedIcon,
                            }))}
                          />
                        </div>
                      )}
                    </form>
                  </CardBody>
                </Card>
                <Buttons
                  nextButtonTitle="Save and Next"
                  formId="create-campaign-form"
                  onNextClickHandler={event => {
                    const to = isEditing ? `/edit-campaign/${query?.id}/template` : '/create-campaign/template';
                    setRedirectTo(to);
                    handleSubmit(event);
                  }}
                  disabled={isLoading}
                />
                <Modal
                  isOpen={addEmailPopUpOpen}
                  title="Where do you want your marketing emails come from?"
                  submitTitle="Add Email"
                  cancelTitle="Cancel"
                  onSubmit={() => {
                    setIsLoading(true);
                    const data = `${email}@${selectedDomain}`;
                    EmailMarketingIntegrationService.addSenderEmail(data)
                      .then(res => {
                        dispatch(setAccount(res));
                        dispatch(setDomains(res?.domainData));
                        setEmail('');
                        setAddEmailPopUpOpen(false);
                        setIsLoading(false);
                      })
                      .catch(err => {
                        const errorMessage = err?.response?.data?.message || err?.message;
                        setAccountCreationError(errorMessage);
                        setIsLoading(false);
                      });
                  }}
                  onCancel={() => {
                    setEmail('');
                    setAddEmailPopUpOpen(false);
                  }}
                  widthRequired
                  reduceCancelButton
                >
                  <div className={classes.email_main_container}>
                    <TextField
                      className={classes.email_main}
                      placeholder="Email Address"
                      variant="outlined"
                      value={email}
                      onChange={handleEmailChange}
                      InputProps={{
                        classes: {
                          root: classes.email_root,
                          notchedOutline: classes.email_notchedOutline,
                          adornedEnd: classes.email_adornedEnd,
                        },
                        endAdornment: (
                          <div className={classes.endAdornment}>
                            <MUISelect
                              name="domain-select"
                              value={selectedDomain}
                              disableUnderline={true}
                              items={verifiedDomains?.map(d => {
                                return {
                                  title: `@${d.domain}`,
                                  value: d.domain,
                                };
                              })}
                              onChange={e => {
                                setSelectedDomain(e.target.value);
                              }}
                              classes={{
                                root: classes.select_root,
                              }}
                            />
                          </div>
                        ),
                      }}
                    />
                  </div>
                </Modal>
              </>
            );
          }}
        </Formik>
      </CreateCampaignMain>
    </>
  );
};

TitlePage.propTypes = {};

TitlePage.defaultProps = {};

export default TitlePage;
