import React, { useEffect } from 'react';
import classes from './PreCampaigns.module.scss';
import { ClickAwayListener, FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';
import styled from 'styled-components';
import SearchBox from '../Components/SearchBox/SearchBox';
import FilterIcon from '../Styles/SVG/FilterSvg';
import CampaignsTable from '../Components/Table/CampaignsTable';
import Modal from 'components/UI/Modal';
import Loader from 'components/UI/Loader';
import CampaignsFilter from 'pages/Contacts/Components/Filter/Filter';
import { FilterTypes } from 'pages/Contacts/Components/Filter/FilterConstants';
import { isEmpty } from 'lodash';
import { CampaignServices } from '../Services/AllCampaigns.service';
import { fetchAllEmailCampaignsAsync } from 'actions/campaign';
import { useDispatch, useSelector } from 'react-redux';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';
import SelectedClients from '../../Contacts/Clients/Clients';

const StyledLabel = styled.div`
  font-family: 'Avenir';
  font-weight: 350;
  font-size: 14px;
  line-height: 16px;
  color: #4a4a4a;
`;

const FilterTag = styled.div`
  position: absolute;
  right: -3px;
  top: -3px;
  background-color: ${({ color }) => color ?? 'black'};
  width: 7px;
  height: 7px;
  border-radius: 9px;
`;

const style = {
  fontFamily: 'Avenir',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  color: 'black',
  maxWidth: '750px',
};

const PrevCampaigns = ({
  campaigns = [],
  setSearchedKeyword = () => {},
  setIsFilterApplied,
  setAppliedFilters,
  isFilterApplied,
  appliedFilters,
  searchedKeyword,
  setShowList,
}) => {
  const dispatch = useDispatch();
  const { contacts: LSContacts } = useSelector(state => state?.contacts);
  const { isLoading: loadingCampaignsCollection, campaignsCollection } = useCampaign();
  const [allSelected, setAllSelected] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteAllModalOpen, setDeleteAllModalOpen] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [showContactsList, setShowContactsList] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isReadOnlyView, setIsReadOnlyView] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  console.log(
    'selectedContacts>>>LSContacts>>LSContacts?.filter(c => selectedContacts.includes(c.id))',
    selectedContacts,
    LSContacts,
    LSContacts?.filter(c => selectedContacts.includes(c.id)),
  );

  const isChecked = id => {
    return selectedCampaigns.includes(id);
  };

  useEffect(() => {
    setShowList(showContactsList);
  }, [showContactsList]);

  useEffect(() => {
    if (selectedCampaign) {
      setSelectedContacts([...selectedCampaign?.recipients?.map(r => r.userId)]);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedCampaigns?.length === 0) {
      setAllSelected(false);
    } else if (selectedCampaigns?.length === campaignsCollection?.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedCampaigns]);

  const onCheckClicked = id => {
    if (isChecked(id)) {
      setSelectedCampaigns(selectedCampaigns.filter(campaignId => campaignId !== id));
    } else {
      setSelectedCampaigns([...selectedCampaigns, ...[id]]);
    }
  };
  const handleDeleteAll = () => {
    setDeleteAllModalOpen(true);
  };
  const handleSearch = e => {
    setSearchedKeyword(e.target.value);
  };
  const handleFilterClick = () => {
    setFilterPanelOpen(!filterPanelOpen);
  };

  const handleSubmitFilter = values => {
    setAppliedFilters(values);
    setIsFilterApplied(true);
    setFilterPanelOpen(false);
  };

  const handleBackClick = () => {
    setSelectedCampaign(null);
    setIsReadOnlyView(false);
    setShowContactsList(false);
  };

  return (
    <>
      {showContactsList ? (
        <div className={classes.container}>
          <SelectedClients
            showTopBar={false}
            showFilter={true}
            clients={LSContacts?.filter(c => selectedContacts.includes(c.id))}
            setClients={setSelectedContacts}
            markedClients={selectedContacts}
            onBackClick={handleBackClick}
            isReadOnlyView={isReadOnlyView}
          />
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.actions}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start' }}>
              <FormControlLabel
                style={{ fontFamily: 'Avenir', marginRight: '5px' }}
                checked={allSelected}
                onChange={() => {
                  setAllSelected(!allSelected);
                  if (allSelected === false) {
                    setSelectedCampaigns([...campaignsCollection?.map(c => c.id)]);
                  } else {
                    setSelectedCampaigns([]);
                  }
                }}
                control={<Checkbox color="primary" />}
                label={<StyledLabel>Select all</StyledLabel>}
                name="allSelect"
                disabled={false}
              />
              {(allSelected || selectedCampaigns?.length > 0) && (
                <button className={classes.deleteBtn} type="button" onClick={handleDeleteAll}>
                  Delete
                </button>
              )}
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ marginRight: '10px' }}>
                <SearchBox
                  background="white"
                  placeholder="Search"
                  searchIconColor="rgba(0, 0, 0, 0.87)"
                  onChange={handleSearch}
                />
              </div>
              <ClickAwayListener
                onClickAway={e => {
                  const cls = e.target.className;
                  setFilterPanelOpen(false);
                }}
              >
                <div style={{ position: 'relative' }}>
                  <button className={classes.filterBtn} type="button" onClick={handleFilterClick}>
                    <FilterIcon /> Filter
                  </button>
                  {!isEmpty(appliedFilters) && <FilterTag color="#215C73" />}
                  {filterPanelOpen && (
                    <CampaignsFilter
                      type={FilterTypes.Campaign}
                      value={appliedFilters}
                      onCancelClick={() => {
                        setFilterPanelOpen(false);
                      }}
                      onSubmitClick={handleSubmitFilter}
                      onClear={() => {
                        setAppliedFilters({});
                        setIsFilterApplied(false);
                        setFilterPanelOpen(false);
                      }}
                    />
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
          <div className={classes.tableContainer}>
            {!loadingCampaignsCollection && campaignsCollection?.length === 0 ? (
              <div className={classes.no_campaign_view}>No Email Campaign(s) created yet</div>
            ) : (
              <>
                {campaigns?.length === 0 && (!isEmpty(appliedFilters) || searchedKeyword.length > 0) ? (
                  <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>No search results</div>
                ) : (
                  <CampaignsTable
                    campaigns={campaigns}
                    isChecked={isChecked}
                    onCheckClicked={onCheckClicked}
                    setShowContactsList={setShowContactsList}
                    setSelectedCampaign={setSelectedCampaign}
                    setIsReadOnlyView={setIsReadOnlyView}
                  />
                )}
              </>
            )}
          </div>
          <Modal
            isOpen={deleteAllModalOpen}
            onCancel={() => {
              setDeleteAllModalOpen(false);
            }}
            onSubmit={() => {
              setIsDeleting(true);
              const data = [...selectedCampaigns];
              CampaignServices.deleteCampaigns(data)
                .then(res => {
                  console.log('res', res);
                  dispatch(fetchAllEmailCampaignsAsync());
                  setIsDeleting(false);
                  setDeleteAllModalOpen(false);
                })
                .catch(err => {
                  setIsDeleting(false);
                  console.log('err', err);
                });
            }}
            submitTitle="Delete"
            title="Are you sure you want to delete?"
            disableSubmitClick={isDeleting}
          >
            <div style={style}>
              Deleting {selectedCampaigns?.length > 1 ? 'these' : 'this'} email{' '}
              {selectedCampaigns?.length > 1 ? 'campaigns' : 'campaign'} will permanently remove it from your account.
              This action cannot be undone. Are you sure you want to proceed with the deletion?
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default PrevCampaigns;
