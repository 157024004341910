import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info.js';
import Input from 'components/FormUI/Input';
import Button from 'components/FormUI/Button';
import { makeStyles } from '@material-ui/core';

export const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;
export const PageTitleSecond = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const PageTitleFirst = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const StyledTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 0px;
`;
export const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

export const StyledInput = styled(Input)`
  height: 48px;
  & * {
    font-size: 1rem;
    font-weight: 400;
  }
  .MuiInputBase-root,
  .MuiInputBase-input {
    font-size: 1rem;
  }
  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }
  .MuiFormHelperText-root.Mui-error {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
`;
export const StyledGridContianer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.75rem;
  ${({ mobileView }) => mobileView && 'padding-top:4.5rem;'};
`;
export const StyledGridContent = styled.div`
  padding: ${({ mobileView, isRight }) =>
    mobileView
      ? '1.5rem 1rem 0rem'
      : isRight
      ? // '2.5rem 6rem 0rem 2.44rem'
        '0 6rem 0rem 2.44rem'
      : // '2.5rem 2.44rem 0rem 6rem'
        '0 2.44rem 0rem 6rem'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ mobileView }) => (mobileView ? '1.5rem' : '1.875rem')};
`;
export const StyledHeadingOne = styled.p`
  color: ${({ textColor }) => textColor};
  font-family: Poppins;
  font-size: ${({ mobileView }) => (mobileView ? '1.75rem' : '2.25rem')};
  font-style: normal;
  font-weight: 700;
  line-height: ${({ mobileView }) => (mobileView ? '2.125rem' : 'inherit')}; /* 222.222% */
  letter-spacing: 0.0125rem;
  margin-bottom: 0rem;
`;

export const StyledText = styled.p`
  color: ${({ textColor }) => textColor};
  font-family: Poppins;
  font-size: ${({ mobileView }) => (mobileView ? '0.875rem' : '1rem')};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0125rem;
`;

export const StyledImage = styled.img`
  width: 100%;
  /* height: ${({ mobileView }) => (mobileView ? '13.22788rem' : '24.16038rem')}; */
  height: auto;
`;
export const StyledVideo = styled.video`
  width: 100%;
  height: ${({ mobileView }) => (mobileView ? '13.22788rem' : '24.16038rem')};
`;
export const StyledDownLoadContianer = styled.div`
  display: flex;
  padding: 2.5rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 1.875rem;
  width: 100%;
  align-self: stretch;
  background-color: ${({ bgColor }) => bgColor};
`;
export const StyledDownLoadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  gap: 1.25rem;
`;

export const StyledPdfContainer = styled.div`
  display: flex;
  /* height: 13.125rem; */
  width: 12.0625rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.375rem;
  border: 1px solid
    ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#4a4a4a' : 'var(--Cohere-Greys-Grey-Tint, #dfe3e4)')};
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  /* &:nth-child(1) {
    border-radius: 0.25rem 0.25rem 0 0;
  }
  &:nth-child(2) {
    border-radius: 0 0 0.25rem 0.25rem;
  } */
`;

export const StyledPdfIcon = styled.div`
  display: flex;
  height: 9.6875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  align-self: stretch;
  background: #fafafa;
`;

export const StyledTextContainer = styled.div`
  /* color: var(--Cohere-Greys-Text, #4a4a4a); */
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? 'white' : 'var(--Cohere-Greys-Text, #4a4a4a)')};
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
  height: ${({ mobileView }) => (mobileView ? 'auto' : 'auto')};
  padding: ${({ mobileView }) => (mobileView ? '' : '0.625rem 0.875rem')};
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  width: 100%;
`;

export const StyledVideoMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  padding: ${({ mobileView }) => (mobileView ? '0rem 1rem' : '0 8rem 2.5rem 8rem')};
  /* padding: ${({ mobileView }) => (mobileView ? '0rem 1rem' : '2.5rem 8rem')}; */
`;

export const StyledVideoContainer = styled.div`
  display: flex;
  height: ${props => (props.mobileView ? '13.125rem' : '44.68656rem')};
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.375rem;
  border: 1px solid
    ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#4a4a4a' : 'var(--Cohere-Greys-Grey-Tint, #dfe3e4)')};
  margin-bottom: 1rem;
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
`;

export const StyledVideoTextedContainer = styled.div`
  display: flex;
  padding: 1.25rem 0.875rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: ${({ mobileView }) => (mobileView ? '3.3rem' : '4.6rem')};
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  border-radius: 0 0 0.375rem 0.375rem;
`;
export const StyledDownloadVideo = styled.video`
  display: flex;
  height: ${({ mobileView }) => (mobileView ? '9.6875rem' : '39.9366rem')};
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  align-self: stretch;
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  border-radius: 0.375rem 0.375rem 0 0;
`;

export const StyledDownloadIconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 15px;
  z-index: 10;
  cursor: pointer;
  border-radius: ${({ mobileView }) => (mobileView ? '2px' : '0.125rem')};
  background: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#2D2F31' : '#fff')};
  box-shadow: 0px 2px 2.9px 0px rgba(0, 0, 0, 0.2);
  width: ${({ mobileView }) => (mobileView ? '30px' : '1.875rem')};
  height: ${({ mobileView }) => (mobileView ? '30px' : '1.875rem')};
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid
    ${({ isDarkModeEnabled }) =>
      isDarkModeEnabled ? 'var(--Cohere-Greys-Text, #4A4A4A)' : '1px solid var(--Cohere-Greys-Grey-Tint, #DFE3E4)'};
`;
export const StyledDurationContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: ${props => (props.mobileView ? '80px' : '20px')};
  z-index: 10;
  cursor: pointer;
  border-radius: 2px;
  border-radius: 0.125rem;
  background: rgba(0, 0, 0, 0.5);
  height: 1.187rem;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
export const StyledDurationText = styled.div`
  color: var(--Cohere-Primary-White, #fff);
  text-align: center;
  font-family: Avenir;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 220% */
`;
export const StyledPlayIconWrapper = styled.div`
  height: 52.045px;
  width: 52.045px;
  color: gray;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  border-radius: 46.263px;
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 0.25rem;
  }
`;

export const DropDownMainContainer = styled.div`
  display: flex;
  ${({ mobileView }) =>
    mobileView &&
    `
  display: block;
  `};
`;

export const ClientContriP = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 850;
  font-size: 22px;
  color: #4a4a4a;
`;
export const DraftBtn = styled.div`
  background: #dbe7f2;
  border-radius: 5px;
  padding: 6px 14px;
  height: 35px;
  color: #4a4a4a;
`;
export const ClientMainDvi = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ mobileView }) =>
    mobileView &&
    `
       padding: 30px 0px 14px 0px;`}
`;

export const BtnDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  min-width: 150px;
  width: 80px;
  background: #ffffff;
  border: 1px solid #c9b382;
  border-radius: 2px;
  color: #c9b382;
  padding: 7px 24px;
  &:hover {
    cursor: pointer;
    background-color: #fff;
  }
  ${({ mobileView }) =>
    mobileView &&
    `
    min-width:  170px;
       
       `}
`;

export const StyledButtonSaved = styled(Button)`
  min-width: 150px;
  width: 80px;
  background: #c9b382;
  border: 1px solid #c9b382;
  border-radius: 2px;
  color: #fff;
  padding: 7px 24px;
  ${({ mobileView }) =>
    mobileView &&
    `
    min-width:  170px;
       
       `}
`;

export const SubjectHeading = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #4a4a4a;
`;

export const GropLeadMagnetHeading = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4a4a4a;
  border-radius: 2px;
  ${({ mobileView }) =>
    mobileView &&
    `
   font-size: 14px;
   margin: 0px 14px;

`}
`;

export const PurchaseGroupContainer = styled.div`
  display: flex;
  ${({ mobileView }) =>
    mobileView &&
    `
   display: block;
 `}
`;
export const useStyles = makeStyles(theme => ({
  notchedOutline: {
    border: '1px solid white',
    borderColor: 'white',
  },
  CkEditorMainDiv: {
    width: '100%',
    margin: 'auto',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
  },
  CkEditorEmailH: {
    padding: '14px 0px',
  },
  CkEditorContainer: {
    width: '100%',
    margin: '0px 50px',
    border: '1px solid #DFE3E4',
    [theme.breakpoints.down(600)]: {
      margin: '0px',
      width: '100%',
    },
  }
}));

export const NotificationMainDiv = styled.div`
  display: flex;
  ${({ mobileView }) =>
    mobileView &&
    `
   padding: 20px 0px;
 `}
`;

export const EditNotificationP = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #c9b382;
  margin: 0px;
`;
