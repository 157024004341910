import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { fetchCohealerContribution } from 'actions/contributions';
import { useEndVideoChat } from 'hooks';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import * as R from 'ramda';
import EventsPopup from 'pages/MasterCalendarPage/components/EventsPopup';
import DateNavigationComponent from 'pages/MasterCalendarPage/components/DateNavigator';
import { colors } from 'utils/styles';
import { Switch, StyledInput, StyledSlider } from 'components/switch/style';
import { getEventsForCalendar } from 'utils/calendar';
import { darken } from 'polished';
import Loader from 'components/UI/Loader';
import CustomEvent from 'pages/MasterCalendarPage/components/CustomEventsUI';
import { useHistory } from 'react-router-dom';
import { ContributionType } from 'helpers/constants';
import Modal from 'components/UI/Modal';
import { useRouter } from 'hooks';
import { rescheduleSession } from 'actions/contributions';
import RefuseModal from 'pages/ContributionView/components/RefuseModal';
import { BodyText } from 'components/UI/Text/TextStyles';
import TextField from 'components/UI/TextField';
import { formatTime } from 'utils/utils';
import ReschduleSessionPopup from 'pages/MasterCalendarPage/components/ReschedulePopup';
import { getUpcomingCreated } from 'services/contributions.service';

const localizer = momentLocalizer(moment);
const allViews = R.compose(R.values, R.omit(['AGENDA', 'WORK_WEEK']))(Views);

const StyledOption = styled.div`
  width: 100%;
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: 800;
  line-height: 21px;
  text-align: left;
  border-radius: 0px;
  padding: 16px 20px;
  cursor: pointer;
  border-bottom: ${({ borderBottom }) => (borderBottom ? '2px solid #e7e7e7' : '')};
  /* &:not(:last-of-type) {
    border-bottom: 2px solid #e7e7e7;
  } */
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          color: darkgray;
        `
      : css``}
`;

const DropDown = styled.div`
  width: ${({ w }) => w};
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  border: 1px solid #dfe3e4;
  border-radius: 4px;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
`;

const DROPDOWN_WITH = 200;
const CohealerCourseCalendar = ({
  user,
  contribution,
  getCohealerContribution,
  calendarActiveView,
  rescheduleChosenSession,
}) => {
  const [events, setEvents] = useState([]);
  const [showEventsPopup, setShowEventsPopup] = useState(false);
  const [prevView, setPrevView] = useState('');
  const [currentViewDateRange, setCurrentViewDateRange] = useState('');
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [seeAllEvents, setSeeAllEvents] = useState(false);
  const [currentDateRange, setCurrentDateRange] = useState();
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
  const [showDropDown, setShowDropDown] = useState(false);
  const [eventClicked, setEventClicked] = useState(null);
  const [rescheduledSession, setRescheduledSession] = useState(null);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showExternalCalendarEventPopup, setShowExternalCalendarEventPopup] = useState(false);
  const [showCustomMessageModal, setShowCustomMessageModal] = useState(false);
  const [showSuccessfulReschedulePop, setShowSuccessfulReschedulePop] = useState(false);
  const [showUnsuccessfulReschedulePop, setShowUnsuccessfulReschedulePop] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState();
  const [messageText, setMessageText] = useState('');
  const [isRescheduleLoading, setIsRescheduleLoading] = useState(false);
  const [targetEvent, setTargetEvent] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState({
    date: new Date(),
    events: [],
  });
  const ss = useSelector(s => s);
  const { protocol, domain } = useRouter();
  const [contributionsTable, setContributionTable] = useState();
  const themedColors = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const handleViewSession = event => {
    setIsCalendarLoading(true);
    const nextState = {
      sessionId: targetEvent?.sessionId,
      contributionId: targetEvent?.contributionId,
    };
    // window.history.pushState(nextState, '', `/contribution-view/${targetEvent?.contributionId}/sessions`);
    window.location.href = `/contribution-view/${targetEvent?.contributionId}/sessions/${nextState.sessionId}/${targetEvent?.id}`;
  };
  useEffect(() => {
    setLoading(true);
    getUpcomingCreated(user.id)
      .then(({ contributionsForTable }) => {
        setContributionTable(contributionsForTable);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const Reschedule = event => {
    if (
      targetEvent.contributionType === ContributionType.contributionOneToOne
      // && moment(targetEvent.start).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
    ) {
      const contr = contributionsTable?.find(k => k.id === targetEvent.contributionId);
      setRescheduledSession({ ...targetEvent, sessionIncrements: contr?.sessionIncrements });
      setShowRescheduleModal(true);
    } else if (targetEvent.contributionType !== ContributionType.contributionOneToOne && targetEvent?.contributionId) {
      setIsCalendarLoading(true);
      const contributionUrl = new URL(
        `/edit-contribution/${targetEvent?.contributionId}/sessions`,
        `${protocol}//${domain}`,
      ).toString();
      window.location.href = contributionUrl;
    }
  };
  const onRescheduleSlotSelection = slot => {
    setSelectedSlot(slot);
    setShowCustomMessageModal(true);
    setShowRescheduleModal(false);
  };
  const onReschedule = () => {
    const { offset, id: rescheduleToId } = selectedSlot;
    const { contributionId, id: rescheduleFromId } = rescheduledSession;

    setIsRescheduleLoading(true);
    rescheduleChosenSession({
      contributionId,
      note: messageText,
      offset,
      rescheduleFromId,
      rescheduleToId,
    })
      .then(data => {
        if (typeof data?.payload === 'string' && data.payload.includes('already booked')) {
          setShowUnsuccessfulReschedulePop(true);
        } else {
          handleStartAndEndDateOfView(currentViewDateRange, '', false);
          setShowSuccessfulReschedulePop(true);
        }
      })
      .finally(() => {
        setShowCustomMessageModal(false);
        setShowEventsPopup(false);
        setIsRescheduleLoading(false);
        setMessageText(null);
      });
  };
  const handleOutsideClick = e => {
    if (!e.defaultPrevented) {
      setShowDropDown(false);
      setEventClicked(null);
    }
    document.removeEventListener('click', handleOutsideClick);
  };
  const onEventClick = (event, e) => {
    if (event?.isExternalEvent) {
      setShowExternalCalendarEventPopup(true);
    } else {
      setTargetEvent(event);
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const ele = document.getElementById('calendarmain');
      ele.addEventListener('contextmenu', function (e) {
        e.preventDefault();
      });
      let clientX;
      let clientY;
      const rect = ele.getBoundingClientRect();

      clientX = e?.clientX || event?.box?.clientX || event?.bounds?.x;
      clientY = e?.clientY || event?.box?.clientY || event?.bounds?.y;

      const offsetForMobile = event?.bounds && mobileView ? window.scrollY : 0;
      clientX = clientX - rect.left;
      clientY = clientY - rect.top - offsetForMobile;
      const spaceLeft = clientX;
      const spaceRight = window.innerWidth - clientX - 270;

      // Open on the right side by default
      if (spaceRight >= DROPDOWN_WITH) {
        clientX = clientX;
        clientY = clientY;
      } else if (spaceLeft >= DROPDOWN_WITH) {
        // If there's not enough space on the right, switch to the left
        clientX = clientX - DROPDOWN_WITH;
        clientY = clientY;
      } else {
        // If there's not enough space on either side, prioritize right
        clientX = clientX;
        clientY = clientY;
      }

      // const { clientX, clientY } = e || event?.box;
      setDropdownPosition({ x: clientX, y: clientY });
      setShowDropDown(true);
      document.addEventListener('click', handleOutsideClick);
    }
  };
  const handleRefreshList = useCallback(async () => {
    getCohealerContribution(contribution.id);
  }, [contribution.id, getCohealerContribution]);
  const { Popup, onShowPopup, handleSessionComplete } = useEndVideoChat(handleRefreshList);
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const CustomToolbar = toolbar => {
    return (
      <span className={mobileView && 'custom-calender-toolbar'}>
        <div className="calendar-custom-header">
          <DateNavigationComponent toolbar={toolbar} calendarActiveView={calendarActiveView} />
          {mobileView ? (
            <span style={{ display: 'inline' }}>
              <Switch className="switch">
                <StyledInput
                  value={seeAllEvents}
                  type="checkbox"
                  onChange={() => {
                    setSeeAllEvents(!seeAllEvents);
                  }}
                  checked={seeAllEvents}
                />
                <StyledSlider className="slider round" />
              </Switch>
              <p style={{ float: 'left', marginRight: '10px', color: '#71717a' }}>See All Events</p>
            </span>
          ) : (
            <span style={{ display: 'flex' }}>
              <Switch className="switch" style={{ margin: 'auto' }}>
                <StyledInput
                  value={seeAllEvents}
                  type="checkbox"
                  onChange={() => {
                    setSeeAllEvents(!seeAllEvents);
                  }}
                  checked={seeAllEvents}
                />
                <StyledSlider className="slider round" />
              </Switch>
              <p style={{ margin: 'auto auto auto 10px', color: '#71717a' }}>See All Events</p>
            </span>
          )}
        </div>
      </span>
    );
  };

  const handleStartAndEndDateOfView = (range, view, id) => {
    if (view === prevView) return;
    setCurrentDateRange(range);
    setPrevView(view || prevView);
    let viewStartDate;
    let viewEndDate;
    if (Array.isArray(range)) {
      // Handle the case when range is an array of Date objects in week and day view
      viewStartDate = range[0];
      viewEndDate = range[range.length - 1];
    } else if (typeof range === 'object' && range?.start && range?.end) {
      // Handle the case when range is an object with start and end properties usually in month view
      viewStartDate = range.start;
      viewEndDate = range.end;
    }
    setCurrentViewDateRange(range);
    setIsCalendarLoading(true);
    getEventsForCalendar({
      viewStartDate,
      viewEndDate,
      contributionId: !seeAllEvents && (id || contribution?.id),
    })
      .then(allEvents => {
        setEvents(allEvents);
      })
      .finally(() => {
        setIsCalendarLoading(false);
      });
  };

  useEffect(() => {
    if (currentDateRange) {
      handleStartAndEndDateOfView(currentDateRange, '');
    }
  }, [seeAllEvents]);

  return (
    <>
      {isCalendarLoading && <Loader />}
      <div className="master-calendar-container" id="calendarmain">
        <BigCalendar
          className="cohere-master-calendar custom-calendar"
          style={{ color: 'black' }}
          localizer={localizer}
          events={events}
          onSelectEvent={onEventClick}
          views={allViews}
          defaultView={Views.MONTH}
          step={30}
          showMultiDayTimes
          defaultDate={new Date()}
          startAccessor="start"
          endAccessor="end"
          onShowMore={(dateEvents, date) => {
            setSelectedDateEvents({
              date,
              events: dateEvents,
            });
            setShowEventsPopup(true);
          }}
          onView={view => {
            if (view === 'day' || view === 'week') {
              setTimeout(() => {
                // Scroll to 9 AM when navigating to the Day view
                const calendar = document.getElementsByClassName('rbc-time-view')[0];
                if (calendar) {
                  calendar.scrollTo({
                    top: 640,
                    behavior: 'smooth',
                  });
                }
              }, 100);
            }
          }}
          formats={{ eventTimeRangeFormat: () => '' }}
          components={{
            toolbar: CustomToolbar,
            event: event => CustomEvent({ ...event, view: prevView }),
          }}
          onDrillDown={() => {}}
          onRangeChange={(...params) => {
            const url = new URL(window?.location?.href);
            const pathSegments = url.pathname.split('/');
            const id = pathSegments[pathSegments.length - 2];
            handleStartAndEndDateOfView(...params, id);
          }}
          eventPropGetter={({ color }) => {
            const nonMonthProps = {
              backgroundColor: '#F5F5F5',
              border: 'none',
              borderRadius: '0',
              borderLeft: `4px solid ${darken(0.1, color)}`,
              borderTop: `1px solid ${darken(0.1, color)}`,
              borderTopRightRadius: '4px',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              paddingTop: '5px',
            };
            let newStyle = {
              color: color || colors.darkOceanBlue,
              backgroundColor: 'rgba(0,0,0,0)',
              minHeight: 24,
              fontSize: 14,
              fontWeight: 500,
            };
            if (prevView !== 'month') {
              newStyle = {
                ...newStyle,
                ...nonMonthProps,
              };
            }

            return {
              className: '',
              style: newStyle,
            };
          }}
        />{' '}
        {showDropDown && (
          <DropDown
            style={{
              background: isDarkThemeEnabled ? themedColors.newThemedCardColor : 'inherit',
              color: isDarkThemeEnabled ? themedColors.newThemedTextColor : 'inherit',
            }}
            top={dropdownPosition.y}
            left={dropdownPosition.x}
            w={`${DROPDOWN_WITH}px`}
          >
            <StyledOption borderBottom onClick={Reschedule}>
              Reschedule
            </StyledOption>
            <StyledOption onClick={handleViewSession}>View session</StyledOption>
          </DropDown>
        )}
      </div>
      {showEventsPopup && (
        <EventsPopup
          events={selectedDateEvents.events}
          date={selectedDateEvents.date}
          onEventClick={onEventClick}
          onCancel={() => setShowEventsPopup(false)}
          isDropdown={true}
        />
      )}
      {showExternalCalendarEventPopup && (
        <Modal
          className="external-event-popup"
          isOpen={showExternalCalendarEventPopup}
          onCancel={() => setShowExternalCalendarEventPopup(false)}
          title="External Calendar Event"
          cancelTitle="Close"
          disableConfirm
        >
          <p>Please use parent calendar or some external calendar to edit this event</p>
        </Modal>
      )}{' '}
      {showRescheduleModal && (
        <ReschduleSessionPopup
          isModalOpen={showRescheduleModal}
          session={rescheduledSession}
          onCloseModal={() => setShowRescheduleModal(false)}
          onConfirm={onRescheduleSlotSelection}
          contribution={contribution}
        />
      )}{' '}
      {showCustomMessageModal && (
        <Modal
          isOpen={showCustomMessageModal}
          onCancel={() => setShowCustomMessageModal(false)}
          onSubmit={onReschedule}
          title="Confirm your new session time"
          hiddenCancel
          submitTitle="Confirm Reschedule"
          widthRequired="850px"
        >
          <BodyText style={{ overflow: 'hidden' }} className="test">
            By clicking Confirm, you will be updating the session time with {rescheduledSession?.clientName}. It’s your
            responsibility to confirm with {rescheduledSession?.clientName} that this new time also works for them.
          </BodyText>
          <TextField
            style={{ width: '100%' }}
            placeholder="Optional. What is the reason for rescheduling? This information will be shared with your client"
            multiline
            rowsMax={6}
            value={messageText}
            onChange={e => setMessageText(e.target.value)}
            className="rescheduleResonInput"
          />
        </Modal>
      )}
      {showSuccessfulReschedulePop && (
        <Modal
          isOpen={showSuccessfulReschedulePop}
          onCancel={() => setShowSuccessfulReschedulePop(false)}
          onSubmit={() => setShowSuccessfulReschedulePop(false)}
          title="Reschedule Successful"
          width="800px"
          widthRequired="800px"
          hiddenCancel
        >
          <BodyText style={{ fontFamily: 'Avenir', fontSize: '18px', fontWeight: '500px' }}>
            Session has been successfully reschedule on{' '}
            <span style={{ color: '#C9B382' }}>
              {' '}
              {formatTime(selectedSlot.start)}, {moment(selectedSlot.start).format('h A')}{' '}
            </span>
          </BodyText>
        </Modal>
      )}
      {showUnsuccessfulReschedulePop && (
        <RefuseModal
          className="master-calendar-refuse-model"
          isOpen
          onClose={() => setShowUnsuccessfulReschedulePop(false)}
          onSubmit={() => setShowUnsuccessfulReschedulePop(false)}
          message="Slot already booked"
        />
      )}
      <Popup applyTheming />
    </>
  );
};

CohealerCourseCalendar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  calendarActiveView: PropTypes.string.isRequired,
};

const actions = {
  getCohealerContribution: fetchCohealerContribution,
  rescheduleChosenSession: rescheduleSession,
};

const mapStateToProps = ({ calendars }) => ({
  calendarActiveView: calendars.currentView,
});

export default connect(mapStateToProps, actions)(CohealerCourseCalendar);
