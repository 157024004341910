import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    backgroundColor: ({ cardBackgroundColor }) => cardBackgroundColor,
    color: ({ color }) => color,
  },
}));

const MoreOptions = ({
  className,
  hasLastMessage,
  isLastMessageReadByMe,
  onToggleReadMessages,
  isFavorite,
  onToggleIsFavorite,
  onClosed,
  onToggleDelete,
  messageMenu = false,
}) => {
  const anchorRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const styledProps = {
    cardBackgroundColor: themedCardBackgroundColor,
    color: themedColor,
  };
  const classes = useStyles(styledProps);
  const onClose = () => setIsOpen(false);

  return messageMenu ? (
    <>
      <IconButton
        ref={anchorRef}
        className={className}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(true);
        }}
        color="inherit"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={onClose}
        onClick={e => e.stopPropagation()}
        onExited={onClosed}
        classes={{ paper: classes.menuPaper }}
      >
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            onToggleDelete();
            onClose();
          }}
        >
          {'Delete'}
        </MenuItem>
      </Menu>
    </>
  ) : (
    <>
      <IconButton
        ref={anchorRef}
        className={className}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(true);
        }}
        color="inherit"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={onClose}
        onClick={e => e.stopPropagation()}
        onExited={onClosed}
        classes={{ paper: classes.menuPaper }}
      >
        {hasLastMessage && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              onToggleReadMessages();
              onClose();
            }}
          >
            {isLastMessageReadByMe ? 'Mark as unread' : 'Mark as read'}
          </MenuItem>
        )}
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            onToggleIsFavorite();
            onClose();
          }}
        >
          {isFavorite ? 'Mark as regular' : 'Mark as favorite'}
        </MenuItem>
        {/* <MenuItem
          onClick={e => {
            e.stopPropagation();
            onToggleDelete();
            onClose();
          }}
        >
          {'Delete'}
        </MenuItem> */}
      </Menu>
    </>
  );
};

MoreOptions.propTypes = {
  className: PropTypes.string.isRequired,
  hasLastMessage: PropTypes.bool.isRequired,
  isLastMessageReadByMe: PropTypes.bool.isRequired,
  onToggleReadMessages: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onToggleIsFavorite: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
};

export default MoreOptions;
