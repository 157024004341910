import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, FieldArray, Form, Formik } from 'formik';
import Modal from 'components/UI/Modal';
import { Icon } from '@mdi/react';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import { formatMoney } from 'utils/datesAndMoney';
import { colors } from 'utils/styles';
import { useAccount, useShallowEqualSelector } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import './LiveCourses.scss';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { ContributionStatus, ContributionType } from 'helpers/constants';
import useRouter from 'hooks/useRouter';
import { FORM_FIELDS } from 'pages/CreateContribution/Payment/components/Membership/constants';
import usePaidTier from 'hooks/usePaidTier';
import { PAYMENT_OPTIONS, TOOLTIP } from '../../../../constants';
import { getCurrency } from 'utils/getPaymentOptionsView';
import { isNil, isUndefined, parseInt, toUpper } from 'lodash';
import { mdiMinusCircleOutline, mdiPlus } from '@mdi/js';
import { toRem } from 'utils/styles';
import CustomExpansionPanel from 'pages/CreateContribution/components/ExpansionPanel';
import {
  FormControlLabelStyled,
  StyledHeader,
  StyledSummaryBodyContainer,
  StyledText,
  StyledTooltip,
  StyledTooltipWrap,
  InputStyled,
  SelectWrapStyled,
  SelectStyled,
  BlueCheckboxForAccordianSummaryStyled,
  SummaryPricingTextContainer,
  FormControlFullWidthStyled,
} from './Membership/styledComponents';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { setCohereAcademyStatus } from 'actions/update-user';
import { PAID_TIER_TITLES } from '../../../../constants';
import * as paidTier from 'selectors/paidTier';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 10px 1px;
  padding-top: 5px;
`;
const StyledIconDisabled = styled(Icon)`
  cursor: pointer;
  margin: 10px 1px;
  color: gray;
  padding-top: 5px;
`;

const StyledLink = styled.div`
  /* max-width: 260px; */
  display: flex;
  height: 1.875rem;
  padding: 0 0.625rem;
  justify-content: center;
  align-items: center;
  /* gap: 0.625rem; */

  cursor: pointer;
  border: 1px solid rgba(223, 227, 228, 1);
  border-radius: 0.25rem;

  span {
    font-family: Brandon Text;
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.3125rem;
    letter-spacing: 0.00625rem;
    color: #215c73;
  }

  &:hover {
    text-decoration: underline;
  }
`;
const DisabledLink = styled.span`
  width: 300px;
  max-width: 100%;
  display: flex;
  align-items: center;
  font-size: ${toRem(16)};
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
  margin-top: 16px;
  margin-left: -5px;
`;

const BlueCheckbox = withStyles({
  root: {
    padding: '9px',
    '&$checked': {
      color: colors.darkOceanBlue,
    },
  },
  checked: {},
})(Checkbox);

const FormLabelStyled = styled(FormLabel)`
  height: 42.38px;
  /* margin: 0.5rem 0 0.25rem 0; */
  display: block;
  padding: 0.7rem 0;
  color: var(--Cohere-Greys-Text, #4a4a4a);
  font-family: Avenir;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.00938rem;
`;

const MAX_SPLIT_NUMBERS = 12;

function renderSplitCost(values, sessionTime) {
  return formatMoney(sessionTime / values.splitNumbers);
}

function splitNumbersOptions() {
  return Array.from({ length: MAX_SPLIT_NUMBERS - 1 }, (_, index) => {
    const value = index + 2;
    return { title: `${value} Payments`, value };
  });
}

const LiveCourses = ({ contribution, shouldBeDisabled, saveToStorageHandler, children }) => {
  const { query } = useRouter();
  const userDetail = useSelector(state => state.account.user);
  const isFeeDisabled = !!query.id && contribution?.status !== ContributionStatus.unfinished;
  const isPurchasedWithSplitPayment = false;
  const { user } = useAccount();
  const {
    user: { transfersEnabled, isStandardAccount, defaultPaymentMethod, standardAccountTransfersEnabled, avatarUrl },
  } = useAccount();
  const serviceAgreementType = user?.serviceAgreementType;
  const isBetaUser = user?.isBetaUser;
  const showOnlyFreeOption = serviceAgreementType && isBetaUser ? true : false;
  const [supportPopup, setSupportPopup] = useState(false);
  const [invoicePopup, setInvoicePopup] = useState(false);
  const [disableInvoice, setDisableInvoice] = useState(false);
  let freeCheck = false;
  const [isDisabled, setIsDisabled] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const smView = useMediaQuery(theme.breakpoints.up('sm'));
  const isEditing = !!query.id;
  let formRef = useRef();
  useEffect(() => {
    formRef.current.setFieldValue('coachPaysStripeFee', contribution?.paymentInfo?.coachPaysStripeFee);
    if (formRef?.current?.values?.paymentType === 'Simple' && contribution?.taxType === 'No') {
      formRef.current.setFieldValue('taxType', 'Exclusive');
    } else if (formRef?.current?.values?.paymentType === 'Advance' && contribution?.taxType === 'No') {
      formRef.current.setFieldValue('taxType', 'No');
    }
  }, [formRef]);

  const formatTotalPrice = ({ cost, packageSessionDiscountPercentage }, index) =>
    formatMoney((index * (100 - packageSessionDiscountPercentage)) / 100);

  const handleFormKeyDown = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };
  // const { isLaunchPlan } = usePaidTier();
  const dispatch = useDispatch();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const handleChangeCurrency = ({ target: { value } }, formProps) => {
    const symbol = contribution.availableCurrencies?.find(c => c.code === value).symbol;
    formProps.setFieldValue('defaultCurrency', value);
    formProps.setFieldValue('defaultSymbol', symbol);
  };
  const handlePaymentProcessor = ({ target: { value } }, formProps) => {
    if (value === 'Advance') {
      if (!user.isStandardTaxEnabled) {
        console.log('formProps------------->', formProps);
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      setSupportPopup(true);
      setDisableInvoice(false);
      formProps.setFieldValue('taxType', 'No');
      if (standardAccountTransfersEnabled === false) {
        formProps.setFieldValue('multiplePriceList', []);
        formProps.setFieldValue('cost', null);
        formProps.setFieldValue('splittedCost', null);
        formProps.setFieldValue('paymentOptions', ['Free']);
      }
    }
    if (value === 'Simple') {
      setIsDisabled(false);
      setDisableInvoice(true);
      formProps.setFieldValue('isInvoiced', false);
      formProps.setFieldValue('taxType', 'Exclusive');
    }
    formProps.setFieldValue('paymentType', value);
  };
  const handleTaxType = ({ target: { value } }, formProps) => {
    formProps.setFieldValue('taxType', value);
  };
  const handleChangeInvoice = ({ target: { value } }, formProps) => {
    formProps.setFieldValue('isInvoiced', !formProps.values.isInvoiced);
    if (formProps.values.isInvoiced === false) {
      freeCheck = true;
      setInvoicePopup(true);
    }
  };
  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        cost: contribution?.paymentInfo?.cost || null,
        splittedCost: contribution?.paymentInfo?.splittedCost || null,
        multiplePriceList:
          contribution?.paymentInfo?.multiplePriceList?.length === 0
            ? [
                {
                  cost: contribution?.paymentInfo?.cost || 0,
                  splittedCost: renderSplitCost(
                    { splitNumbers: contribution?.paymentInfo?.splitNumbers },
                    contribution?.paymentInfo?.cost,
                  ),
                },
              ]
            : contribution?.paymentInfo?.multiplePriceList || [{ cost: 0, splittedCost: 0 }],
        paymentOptions:
          contribution?.paymentInfo?.paymentOptions &&
          !contribution?.paymentInfo?.paymentOptions.some(
            option => option === 'SessionsPackage' || option === 'PerSession',
          )
            ? contribution?.paymentInfo?.paymentOptions
            : ['EntireCourse'],
        splitNumbers: contribution?.paymentInfo?.splitNumbers || 3,
        splitPeriod: contribution?.paymentInfo?.splitPeriod || 'Monthly',
        defaultSymbol: contribution?.defaultSymbol,
        defaultCurrency: contribution?.defaultCurrency,
        paymentType: contribution?.paymentType || user?.defaultPaymentMethod,
        taxType: contribution?.taxType || 'No',
        packageDiscount: !!contribution?.paymentInfo?.packageSessionDiscountPercentage,
        packageSessionDiscountPercentage: contribution?.paymentInfo?.packageSessionDiscountPercentage
          ? contribution?.paymentInfo?.packageSessionDiscountPercentage
          : '',
        coachPaysStripeFee: contribution?.paymentInfo?.coachPaysStripeFee,
        isInvoiced: isNil(contribution?.isInvoiced) ? false : contribution?.isInvoiced,
      }}
      validationSchema={yup.object().shape({
        // cost: yup.number().when('paymentOptions', {
        //   is: values => values.includes('SplitPayments') || values.includes('EntireCourse'),
        //   then: yup.number().min(1).integer('Please only enter numbers').required('This is a required field'),
        //   otherwise: yup.number().nullable(),
        // }),
        multiplePriceList: yup.array().of(
          yup.object().shape({
            cost: yup.number().when('paymentOptions', {
              is: values => values?.includes('EntireCourse'),
              then: yup
                .number()
                .typeError('Please enter a valid number')
                .test('is-number', 'Please enter a valid number', function (value) {
                  return typeof value === 'number' && !isNaN(value);
                })
                .min(1, 'Cost must be greater than or equal to 1')
                // .integer('Please only enter numbers')
                .required('This is a required field'),
              otherwise: yup
                .number()
                .typeError('Please enter a valid number')
                .min(1, 'Cost must be greater than or equal to 1')
                // .integer('Please only enter numbers')
                .required('This is a required field'),
            }),
            splittedCost: yup.number().when('paymentOptions', {
              is: values => values?.includes('SplitPayments'),
              then: yup
                .number()
                .typeError('Please enter a valid number')
                .test('is-number', 'Please enter a valid number', function (value) {
                  return typeof value === 'number' && !isNaN(value);
                })
                .min(1, 'Splitted Cost must be greater than or equal to 1')
                // .integer('Please only enter numbers')
                .required('This is a required field')
                .test('lessThanCost', 'Split cost must be lower than the total cost', function (value) {
                  if (formRef?.current?.values?.paymentOptions?.includes('EntireCourse')) {
                    const costValue = this.parent.cost;
                    return value < costValue;
                  } else {
                    return true;
                  }
                }),
            }),
          }),
        ),
        splitNumbers: yup
          .number()
          .min(2)
          .max(MAX_SPLIT_NUMBERS)
          .integer('please only enter numbers')
          .required('This is a required field'),
        paymentOptions: yup
          .array()
          .of(yup.mixed().oneOf(['Free', 'EntireCourse', 'SplitPayments']))
          .min(1),
        coachPaysStripeFee: yup.bool(),
        isInvoiced: yup.bool(),
      })}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        const renderCardBody = (
          <Form id="create-contribution-form" onKeyDown={handleFormKeyDown}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8} style={smView ? {} : { paddingBottom: '0.5rem' }}>
                <FieldArray name="paymentOptions">
                  {({ push, remove, form }) => {
                    const changeHandler = ({ target: { value, checked } }) => {
                      if (checked) {
                        push(value);
                        if (value === 'SplitPayments') {
                          const updatedSpltPayemts = formProps.values.multiplePriceList.map(item => {
                            return {
                              ...item,
                              splittedCost: parseFloat(renderSplitCost(formProps.values, item.cost).replace(/,/g, '')),
                            };
                          });
                          form.setFieldValue(`multiplePriceList`, updatedSpltPayemts);
                        }
                        if (value === 'EntireCourse') {
                          const updatedTotalPayments = formProps.values.multiplePriceList.map(item => {
                            return {
                              ...item,
                              cost: parseInt(
                                formatMoney(
                                  (item.splittedCost *
                                    formProps.values.splitNumbers *
                                    (100 - formProps.values.packageSessionDiscountPercentage)) /
                                    100,
                                ),
                              ),
                            };
                          });
                          form.setFieldValue(`multiplePriceList`, updatedTotalPayments);
                        }
                      } else {
                        const idx = form.values.paymentOptions.indexOf(value);
                        if (value === 'EntireCourse') {
                          form.setFieldValue('packageDiscount', false);
                          form.setFieldValue('packageSessionDiscountPercentage', '');
                          if (!formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) {
                            form.setFieldValue('cost', '');
                          }
                        }
                        if (value === 'EntireCourse') {
                          const nullTotalPayments = formProps.values.multiplePriceList.map(item => {
                            return { ...item, cost: null };
                          });
                          form.setFieldValue(`multiplePriceList`, nullTotalPayments);
                        }
                        if (value === 'SplitPayments') {
                          const nullSplitPayments = formProps.values.multiplePriceList.map(item => {
                            return { ...item, splittedCost: null };
                          });
                          form.setFieldValue(`multiplePriceList`, nullSplitPayments);
                        }
                        if (
                          value === PAYMENT_OPTIONS.SPLIT_PAYMENTS &&
                          !formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE)
                        ) {
                          form.setFieldValue('cost', '');
                        }
                        remove(idx);
                      }
                    };

                    // if (showOnlyFreeOption) {
                    //   form.values.paymentOptions = [PAYMENT_OPTIONS.ENTIRE_COURSE];
                    // }
                    const hasNoSelectionError =
                      !isUndefined(formProps.errors.paymentOptions) &&
                      typeof formProps.errors.paymentOptions === 'string' &&
                      form.values.paymentOptions?.length <= 0;
                    return (
                      <Grid container>
                        <FormControlFullWidthStyled error={hasNoSelectionError} fullWidth>
                          <Grid item sm={12}>
                            {hasNoSelectionError && (
                              <FormLabel className="mb-3" component="legend">
                                Pick at least one option
                              </FormLabel>
                            )}
                          </Grid>
                          <Grid item sm={12}>
                            <CustomExpansionPanel
                              // asyncContainerPadding={mobileView}
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                              minHeight="30px"
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Free"
                                    disabled={
                                      ((isLaunchPlan || (shouldBeDisabled && contribution?.status === 'Approved')) &&
                                        !showOnlyFreeOption) ||
                                      formProps.values.isInvoiced
                                    }
                                    value={PAYMENT_OPTIONS.FREE}
                                    onChange={changeHandler}
                                    checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE)}
                                    style={{
                                      fontSize: '18px',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      lineHeight: '30px',
                                      letterSpacing: '0.1px',
                                      margin: '0 1rem 0 -4px',
                                    }}
                                  />
                                  <StyledTooltip
                                    title="If you select this option, you will have a free link where clients can join session for free."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              // mobileView={mobileView}
                              // expand={false}
                              disabledExpansion
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                              minHeight="30px"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Full Payment"
                                    value="EntireCourse"
                                    onChange={changeHandler}
                                    disabled={
                                      isPurchasedWithSplitPayment ||
                                      (shouldBeDisabled && contribution?.status === 'Approved') ||
                                      (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                      (formProps.values.paymentType === 'Advance' &&
                                        standardAccountTransfersEnabled === false)
                                    }
                                    checked={form.values.paymentOptions.includes('EntireCourse')}
                                    style={{
                                      marginLeft: '-4px',
                                      marginBottom: 0,
                                    }}
                                  />
                                  <StyledTooltip
                                    title="Pay in full for all sessions"
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              summarySubTitle={
                                <div style={{ paddingLeft: 9 }}>
                                  {form.values.paymentOptions.includes('EntireCourse') &&
                                    `${
                                      form?.values?.multiplePriceList?.length > 1 ? 'Multiple Prices:' : 'Price:'
                                    } ${form?.values?.multiplePriceList
                                      ?.map(k => `${form.values.defaultSymbol || '$'}${formatMoney(k.cost)}`)
                                      ?.join(', ')}`}
                                </div>
                              }
                              // mobileView={mobileView}
                              expand={form.values.paymentOptions.includes('EntireCourse')}
                              disabledExpansion={!form.values.paymentOptions.includes('EntireCourse')}
                            >
                              <Grid container>
                                <Grid item sm={6} xs={12} direction="column">
                                  {(formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                                    formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) && (
                                    <>
                                      <Grid item xs={12}>
                                        <StyledHeader>Total Price</StyledHeader>
                                      </Grid>
                                      <FieldArray name="multiplePriceList">
                                        {({ push, remove, form }) => {
                                          return (
                                            <Grid container item>
                                              <Grid item xs={12}>
                                                {formProps.values.paymentOptions.includes('EntireCourse') &&
                                                  form?.values?.multiplePriceList?.map((c, i) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          flexDirection: 'row',
                                                          alignItems: 'self-start',
                                                          // ,borderBottom:`1px solid #949494`,
                                                          marginTop: i === 1 && '8px',
                                                        }}
                                                      >
                                                        <Grid item sm={9} xs={11}>
                                                          <InputStyled
                                                            startAdornment={
                                                              form.values.defaultSymbol
                                                                ? form.values.defaultSymbol
                                                                : '$'
                                                            }
                                                            type="text"
                                                            variant="outlined"
                                                            name={`multiplePriceList[${i}].cost`}
                                                            fullWidth
                                                            onChange={({ target: { value } }) => {
                                                              if (!/\D/.test(value)) {
                                                                if (
                                                                  form.values.paymentOptions.includes('SplitPayments')
                                                                ) {
                                                                  form.setFieldValue(`multiplePriceList[${i}]`, {
                                                                    cost: value,
                                                                    splittedCost: parseFloat(
                                                                      renderSplitCost(form.values, value).replace(
                                                                        /,/g,
                                                                        '',
                                                                      ),
                                                                    ),
                                                                  });
                                                                } else {
                                                                  form.setFieldValue(`multiplePriceList[${i}]`, {
                                                                    cost: value,
                                                                    splittedCost: 0,
                                                                  });
                                                                }
                                                              }
                                                            }}
                                                            margin="dense"
                                                            label=""
                                                            // label={
                                                            //   i > 0
                                                            //     ? ``
                                                            //     : `Total Price  ${
                                                            //         form.values.defaultSymbol
                                                            //           ? form.values.defaultSymbol
                                                            //           : '$'
                                                            //       }`
                                                            // }
                                                            disabled={
                                                              form?.values?.multiplePriceList[i]?.id && shouldBeDisabled
                                                            }
                                                          />
                                                        </Grid>
                                                        <Grid item sm={1} xs={1} className="ml-3">
                                                          {(form?.values?.multiplePriceList || [])?.length > 1 &&
                                                            i >= 0 &&
                                                            (form?.values?.multiplePriceList[i]?.id &&
                                                            shouldBeDisabled ? (
                                                              <StyledIconDisabled
                                                                onClick={() => {
                                                                  if (
                                                                    form?.values?.multiplePriceList[i]?.id &&
                                                                    shouldBeDisabled
                                                                  ) {
                                                                  } else {
                                                                    remove(i);
                                                                  }
                                                                }}
                                                                path={mdiMinusCircleOutline}
                                                                size={1}
                                                              />
                                                            ) : (
                                                              <StyledIcon
                                                                onClick={() => {
                                                                  if (
                                                                    form?.values?.multiplePriceList[i]?.id &&
                                                                    shouldBeDisabled
                                                                  ) {
                                                                  } else {
                                                                    remove(i);
                                                                  }
                                                                }}
                                                                path={mdiMinusCircleOutline}
                                                                size={1}
                                                              />
                                                            ))}
                                                        </Grid>
                                                      </div>
                                                    );
                                                  })}
                                              </Grid>
                                              <Grid item style={{ marginTop: '1.25rem' }}>
                                                <StyledLink
                                                  onClick={() => {
                                                    push({ cost: 0, splittedCost: 0 });
                                                  }}
                                                >
                                                  {/* <Icon path={mdiPlus} size={1} color={colors.darkOceanBlue2} /> */}
                                                  <span>+Add Another Price</span>
                                                </StyledLink>
                                              </Grid>
                                            </Grid>
                                          );
                                        }}
                                      </FieldArray>

                                      {formProps.values.paymentOptions.includes('EntireCourse') && (
                                        <>
                                          <Grid item xs={12}>
                                            <Field name="packageDiscount">
                                              {() => {
                                                const changePackageDiscount = ({ target: { checked } }) => {
                                                  if (checked) {
                                                    form.setFieldValue('packageSessionDiscountPercentage', 10);
                                                  } else {
                                                    form.setFieldValue('packageSessionDiscountPercentage', '');
                                                  }
                                                  form.setFieldValue('packageDiscount', !form.values.packageDiscount);
                                                };

                                                return (
                                                  <>
                                                    <StyledTooltipWrap style={{ marginTop: '1.25rem' }}>
                                                      <FormControl>
                                                        <FormControlLabelStyled
                                                          name="packageDiscount"
                                                          control={<BlueCheckbox color="primary" />}
                                                          label="Full Payment Discount"
                                                          onChange={changePackageDiscount}
                                                          checked={form.values.packageDiscount}
                                                          disabled={
                                                            isPurchasedWithSplitPayment ||
                                                            (shouldBeDisabled && contribution?.status === 'Approved')
                                                          }
                                                        />
                                                      </FormControl>
                                                      <StyledTooltip
                                                        title="An incentive to have clients pay in full"
                                                        arrow
                                                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                                      >
                                                        <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                                      </StyledTooltip>
                                                    </StyledTooltipWrap>
                                                  </>
                                                );
                                              }}
                                            </Field>
                                          </Grid>
                                          {formProps.values.packageDiscount && (
                                            <Grid item xs={12}>
                                              <Grid item sm={9} xs={8}>
                                                <InputStyled
                                                  startAdornment="%"
                                                  type="text"
                                                  variant="outlined"
                                                  name="packageSessionDiscountPercentage"
                                                  label=""
                                                  margin="dense"
                                                  onChange={({ target: { value } }) => {
                                                    if (!/\D/.test(value))
                                                      if (value < 100) {
                                                        formProps.setFieldValue(
                                                          'packageSessionDiscountPercentage',
                                                          value,
                                                        );
                                                      }
                                                  }}
                                                  disabled={
                                                    isPurchasedWithSplitPayment ||
                                                    (shouldBeDisabled && contribution?.status === 'Approved')
                                                  }
                                                />
                                              </Grid>
                                            </Grid>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>
                          <Grid item sm={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                              minHeight="30px"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name="paymentOptions"
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Split Payments"
                                    value="SplitPayments"
                                    onChange={changeHandler}
                                    disabled={
                                      isPurchasedWithSplitPayment ||
                                      (shouldBeDisabled && contribution?.status === 'Approved') ||
                                      (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                      (formProps.values.paymentType === 'Advance' &&
                                        standardAccountTransfersEnabled === false)
                                    }
                                    checked={form.values.paymentOptions.includes('SplitPayments')}
                                    style={{
                                      marginLeft: '-4px',
                                      marginBottom: 0,
                                    }}
                                  />
                                  <StyledTooltip
                                    title="For automatic recurring payments that allow your clients to pay for the program, overtime (monthly, weekly or daily)"
                                    arrow
                                    className="mb-2"
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              summarySubTitle={
                                <div style={{ paddingLeft: 9 }}>
                                  {form.values.paymentOptions.includes('SplitPayments') &&
                                    `${formProps.values?.splitNumbers} ${formProps.values?.splitPeriod} payments of ${
                                      // formatMoney(formProps?.values?.multiplePriceList[0]?.cost || 0)
                                      form?.values?.multiplePriceList
                                        ?.map(k => `${form.values.defaultSymbol || '$'}${formatMoney(k.splittedCost)}`)
                                        ?.join(', ')
                                    }`}
                                </div>
                              }
                              // mobileView={mobileView}
                              expand={form.values.paymentOptions.includes('SplitPayments')}
                              disabledExpansion={!form.values.paymentOptions.includes('SplitPayments')}
                            >
                              <Grid container spacing={2} direction="row">
                                <Grid item sm={6} xs={12} direction="column">
                                  <Grid item sm={12} xs={12}>
                                    <StyledHeader id="lbl-number-of-payments">Number of Payments</StyledHeader>
                                  </Grid>
                                  <Grid item sm={8} xs={12}>
                                    <SelectWrapStyled>
                                      <SelectStyled
                                        style={{
                                          fontFamily: 'Avenir',
                                          border: '1px solid #DFE3E4',
                                          borderRadius: '4px',
                                          fontSize: '1rem',
                                          fontWeight: '400',
                                          lineHeight: '24px',
                                          letterSpacing: '0.15px',
                                        }}
                                        margin="dense"
                                        labelId="lbl-number-of-payments"
                                        variant="outlined"
                                        label=""
                                        name="splitNumbers"
                                        fullWidth
                                        disabled={
                                          !formProps.values.paymentOptions.includes('SplitPayments') ||
                                          isPurchasedWithSplitPayment ||
                                          (shouldBeDisabled && contribution?.status === 'Approved') ||
                                          (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                          (formProps.values.paymentType === 'Advance' &&
                                            standardAccountTransfersEnabled === false)
                                        }
                                        onChange={e => {
                                          formProps.setFieldValue('splitNumbers', e.target.value);
                                          if (e.target.value) {
                                            const updatedSpltPayemts = formProps.values.multiplePriceList.map(item => {
                                              return {
                                                ...item,
                                                splittedCost: renderSplitCost(
                                                  { splitNumbers: e.target.value },
                                                  item.cost,
                                                ),
                                              };
                                            });
                                            const resultArray = updatedSpltPayemts.map(obj => ({
                                              ...obj,
                                              splittedCost: parseFloat(obj.splittedCost.replace(/,/g, '')),
                                            }));
                                            formProps.setFieldValue(`multiplePriceList`, resultArray);
                                          }
                                        }}
                                        items={splitNumbersOptions()}
                                      />
                                    </SelectWrapStyled>
                                  </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Grid item sm={8} xs={12}>
                                    <StyledHeader id="lbl-split-period">Split Period</StyledHeader>
                                    <SelectWrapStyled>
                                      <SelectStyled
                                        style={{
                                          fontFamily: 'Avenir',
                                          border: '1px solid #DFE3E4',
                                          borderRadius: '4px',
                                          fontSize: '1rem',
                                          fontWeight: '400',
                                          lineHeight: '24px',
                                          letterSpacing: '0.15px',
                                        }}
                                        margin="dense"
                                        labelId="lbl-split-period"
                                        label=""
                                        name="splitPeriod"
                                        fullWidth
                                        variant="outlined"
                                        disabled={
                                          !formProps.values.paymentOptions.includes('SplitPayments') ||
                                          isPurchasedWithSplitPayment ||
                                          (shouldBeDisabled && contribution?.status === 'Approved') ||
                                          (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                          (formProps.values.paymentType === 'Advance' &&
                                            standardAccountTransfersEnabled === false)
                                        }
                                        items={[
                                          { title: 'Monthly', value: 'Monthly' },
                                          { title: 'Weekly', value: 'Weekly' },
                                          { title: 'Daily', value: 'Daily' },
                                        ]}
                                      />
                                    </SelectWrapStyled>
                                  </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Grid item sm={8} xs={12}>
                                    {(formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                                      formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) && (
                                      <>
                                        <FieldArray name="multiplePriceList">
                                          {formProps.values.paymentOptions.includes('SplitPayments') && (
                                            <Grid item xs={12}>
                                              <Grid item xs={12}>
                                                <StyledHeader id="lbl-split-payments">Split Payments</StyledHeader>
                                              </Grid>
                                              {formProps?.values?.multiplePriceList?.map((c, i) => {
                                                return (
                                                  <InputStyled
                                                    labelId="lbl-split-payments"
                                                    startAdornment={
                                                      formProps.values.defaultSymbol
                                                        ? formProps.values.defaultSymbol
                                                        : '$'
                                                    }
                                                    type="text"
                                                    name={`multiplePriceList[${i}].splittedCost`}
                                                    fullWidth
                                                    label=""
                                                    variant="outlined"
                                                    margin="dense"
                                                    // onChange={({ target: { value } }) => {
                                                    //   if (!/\D/.test(value)) {
                                                    //     formProps.setFieldValue(`multiplePriceList[${i}]`, {
                                                    //       splittedCost: value,
                                                    //       cost: c.cost,
                                                    //     });
                                                    //   }
                                                    // }}
                                                    disabled={
                                                      form?.values?.multiplePriceList[i]?.id && shouldBeDisabled
                                                    }
                                                  />
                                                );
                                              })}
                                            </Grid>
                                          )}
                                        </FieldArray>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Grid item sm={8} xs={12}>
                                    <Grid item xs={12}>
                                      <Grid item xs={12}>
                                        <StyledHeader id="lbl-total-price-calculated">Total Price</StyledHeader>
                                      </Grid>
                                      {formProps?.values?.multiplePriceList?.length > 0
                                        ? formProps?.values?.multiplePriceList?.map(v => (
                                            <FormLabelStyled labelId="lbl-total-price-calculated">
                                              {formProps.values.defaultSymbol}
                                              {formatMoney(v?.cost)}
                                            </FormLabelStyled>
                                          ))
                                        : 0}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>
                          <Grid item xs={12}>
                            <CustomExpansionPanel
                              bodyPadding="20px 24px !important"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <div
                                  style={{
                                    fontSize: '1.125rem',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '30px',
                                    letterSpacing: '0.1px',
                                    color: 'black',
                                    marginLeft: '-1px',
                                  }}
                                >
                                  Payment Settings
                                </div>
                              }
                              summarySubTitle={
                                formProps.values.paymentOptions?.length &&
                                (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) ? (
                                  <div>
                                    {[
                                      toUpper(formProps.values?.defaultCurrency),
                                      (formProps.values?.paymentType === 'Simple' && 'Cohere Payment Processor') ||
                                        (formProps.values?.paymentType === 'Advance' &&
                                          `${
                                            userDetail.firstName.charAt(0).toUpperCase() + userDetail.firstName.slice(1)
                                          }'s Stripe Account`),
                                      user?.isStandardAccount === true &&
                                        formProps?.values?.paymentType === 'Advance' &&
                                        (formProps.values?.taxType === 'No' ? 'No Tax' : formProps.values?.taxType),
                                      formProps.values?.coachPaysStripeFee ? 'Coach Pays Stripe Processing Fees' : '',
                                    ]
                                      .filter(k => !!k)
                                      .join(', ')}
                                  </div>
                                ) : (
                                  <></>
                                )
                              }
                              // mobileView={mobileView}
                              // onToggle={setIsSettingsAccordianOpen}
                              // expand={isSettingsAccordianOpen}
                              // disabledExpansion={!isSettingsAccordianOpen}
                            >
                              <Grid container spacing={2}>
                                {(showOnlyFreeOption || user?.isStandardAccount) &&
                                  (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                                    formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) && (
                                    <>
                                      <Grid item sm={9} xs={12} direction="column">
                                        <Grid item sm={9} xs={12}>
                                          <StyledHeader id="lbl-currency">Currency</StyledHeader>
                                        </Grid>
                                        <SelectWrapStyled>
                                          <SelectStyled
                                            style={{
                                              fontFamily: 'Avenir',
                                              border: '1px solid #DFE3E4',
                                              borderRadius: '4px',
                                              fontSize: '1rem',
                                              fontWeight: '400',
                                              lineHeight: '24px',
                                              letterSpacing: '0.15px',
                                            }}
                                            margin="dense"
                                            labelId="lbl-currency"
                                            // label=""
                                            variant="outlined"
                                            name="defaultCurrency"
                                            fullWidth
                                            disabled={isFeeDisabled}
                                            onChange={event => handleChangeCurrency(event, formProps)}
                                            value={formProps.values?.defaultCurrency}
                                            items={getCurrency(contribution)}
                                          />
                                        </SelectWrapStyled>

                                        {/* {!showOnlyFreeOption && (
                                            <Grid item container xs={12}>
                                              <StyledText>{contribution.defaultCurrency?.toUpperCase()}</StyledText>
                                            </Grid>
                                          )} */}
                                      </Grid>
                                    </>
                                  )}
                                {(formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS)) && (
                                  <>
                                    {user?.isStandardAccount && (
                                      <>
                                        <Grid item sm={9} xs={12} direction="column">
                                          <Grid item sm={9} xs={12}>
                                            <StyledHeader id="lbl-payment-processor">Payment Processor</StyledHeader>
                                          </Grid>
                                          <SelectWrapStyled>
                                            <SelectStyled
                                              style={{
                                                fontFamily: 'Avenir',
                                                border: '1px solid #DFE3E4',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.15px',
                                              }}
                                              margin="dense"
                                              labelId="lbl-payment-processor"
                                              label=""
                                              name="paymentType"
                                              fullWidth
                                              variant="outlined"
                                              disabled={isFeeDisabled}
                                              onChange={event => handlePaymentProcessor(event, formProps)}
                                              value={formProps.values?.paymentType}
                                              items={
                                                user.connectedStripeAccountId === null
                                                  ? [
                                                      {
                                                        value: 'Advance',
                                                        title: `${
                                                          userDetail.firstName.charAt(0).toUpperCase() +
                                                          userDetail.firstName.slice(1)
                                                        }'s Stripe Account`,
                                                      },
                                                    ]
                                                  : [
                                                      { value: 'Simple', title: 'Cohere Payment Processor' },
                                                      {
                                                        value: 'Advance',
                                                        title: `${
                                                          userDetail.firstName.charAt(0).toUpperCase() +
                                                          userDetail.firstName.slice(1)
                                                        }'s Stripe Account`,
                                                      },
                                                    ]
                                              }
                                            />
                                          </SelectWrapStyled>
                                        </Grid>
                                      </>
                                    )}

                                    {/* {user?.isStandardAccount && formProps.values?.paymentType === 'Advance' && ( */}
                                    {
                                      <>
                                        <Grid item sm={9} xs={12} direction="column">
                                          <Grid item sm={9} xs={12}>
                                            <StyledHeader id="lbl-tax-type">Tax Type</StyledHeader>
                                          </Grid>
                                          <SelectWrapStyled>
                                            <SelectStyled
                                              style={{
                                                fontFamily: 'Avenir',
                                                border: '1px solid #DFE3E4',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.15px',
                                              }}
                                              margin="dense"
                                              labelId="lbl-tax-type"
                                              label=""
                                              name="taxType"
                                              fullWidth
                                              variant="outlined"
                                              disabled={isFeeDisabled}
                                              defaultValue={formProps.values?.taxType}
                                              isOptionDisabled={option => option.isdisabled}
                                              onChange={event => handleTaxType(event, formProps)}
                                              value={formProps.values?.taxType}
                                              items={
                                                formProps.values?.paymentType === 'Simple'
                                                  ? [
                                                      {
                                                        value: 'Exclusive',
                                                        title: 'Exclusive',
                                                        disabled: isDisabled,
                                                      },
                                                      {
                                                        value: 'Inclusive',
                                                        title: 'Inclusive',
                                                        disabled: isDisabled,
                                                      },
                                                    ]
                                                  : [
                                                      {
                                                        value: 'Exclusive',
                                                        title: 'Exclusive',
                                                        disabled: isDisabled,
                                                      },
                                                      {
                                                        value: 'Inclusive',
                                                        title: 'Inclusive',
                                                        disabled: isDisabled,
                                                      },
                                                      { value: 'No', title: 'No Tax' },
                                                    ]
                                              }
                                            />
                                          </SelectWrapStyled>
                                        </Grid>
                                      </>
                                    }
                                    {/* )} */}
                                  </>
                                )}

                                {false && (
                                  <Grid item sm={9} xs={12} direction="column">
                                    <StyledTooltipWrap style={{ marginTop: '1.25rem' }}>
                                      <FormControl>
                                        <StyledHeader id="lbl-how-client-pays">How Your Client Pays</StyledHeader>
                                      </FormControl>
                                      <StyledTooltip
                                        title="The recomended payment approach is for client to pay with the credit card. Most coaches prefer simple checkout process where your client immidately pays with the credit card. If you prefer your clients to pay via invoice, they will also have the option to pay with the bank transfer instead."
                                        arrow
                                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      >
                                        <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                      </StyledTooltip>
                                    </StyledTooltipWrap>
                                    <SelectWrapStyled>
                                      <SelectStyled
                                        style={{
                                          fontFamily: 'Avenir',
                                          border: '1px solid #DFE3E4',
                                          borderRadius: '4px',
                                          fontSize: '1rem',
                                          fontWeight: '400',
                                          lineHeight: '24px',
                                          letterSpacing: '0.15px',
                                        }}
                                        margin="dense"
                                        labelId="lbl-how-client-pays"
                                        label=""
                                        name="taxType"
                                        fullWidth
                                        variant="outlined"
                                        disabled={isFeeDisabled}
                                        defaultValue={!user.isStandardTaxEnabled ? 'No' : contribution?.taxType}
                                        isOptionDisabled={option => option.isdisabled}
                                        onChange={event => handleTaxType(event, formProps)}
                                        value={formProps.values?.taxType}
                                        items={[
                                          {
                                            value: 'Exclusive',
                                            title: 'Exclusive',
                                            disabled: isDisabled,
                                          },
                                          {
                                            value: 'Inclusive',
                                            title: 'Inclusive',
                                            disabled: isDisabled,
                                          },
                                          { value: 'No', title: 'No Tax' },
                                        ]}
                                      />
                                    </SelectWrapStyled>
                                  </Grid>
                                )}

                                <Grid item sm={9} xs={12} direction="row">
                                  <StyledTooltipWrap>
                                    <FormControlLabelStyled
                                      name="coachPaysStripeFee"
                                      control={<BlueCheckbox color="primary" />}
                                      fontSize="16px"
                                      fontWeight="400"
                                      label="Coach Pays for Processing Fee"
                                      checked={
                                        formRef?.current?.values?.paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
                                        formRef?.current?.values?.paymentOptions?.length === 1
                                          ? false
                                          : formProps.values.coachPaysStripeFee
                                      }
                                      onChange={formProps.handleChange('coachPaysStripeFee')}
                                      disabled={
                                        isFeeDisabled ||
                                        (formRef?.current?.values?.paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
                                          formRef?.current?.values?.paymentOptions?.length === 1) ||
                                        (formProps.values.paymentType === 'Simple' && transfersEnabled === false)
                                      }
                                      items={splitNumbersOptions()}
                                    />
                                    <StyledTooltip
                                      title="If the box is checked, you are choosing to pay the Stripe credit card processing fee. If left unchecked, your client will be charged the processing fee at checkout. The Stripe processing fee is a 2.9% plus 30 cents surcharge on top of your base pricing."
                                      arrow
                                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      className="mb-2"
                                    >
                                      <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                    </StyledTooltip>
                                  </StyledTooltipWrap>
                                </Grid>

                                {contribution?.isWorkshop == false &&
                                  user?.isStandardAccount &&
                                  (formProps.values.paymentOptions.includes('EntireCourse') ||
                                    formProps.values.paymentOptions.includes('SplitPayments')) && (
                                    <>
                                      {formProps?.values?.paymentType != 'Simple' && (
                                        <Grid item sm={9} xs={12} direction="row">
                                          <StyledTooltipWrap>
                                            <FormControlLabelStyled
                                              fontSize="16px"
                                              name="isInvoiced"
                                              control={<BlueCheckbox color="primary" />}
                                              label="Payment Invoice"
                                              fontWeight="400"
                                              checked={formProps.values?.isInvoiced}
                                              value={formProps.values?.isInvoiced}
                                              onChange={event => handleChangeInvoice(event, formProps)}
                                              disabled={
                                                isFeeDisabled ||
                                                disableInvoice ||
                                                (formProps.values.paymentType === 'Advance' &&
                                                  standardAccountTransfersEnabled === false)
                                              }
                                            />
                                            <StyledTooltip
                                              title="If your clients pay by invoice, they will be able to join after purchasing and you will be able to collect payment via credit card or bank transfer."
                                              arrow
                                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                              className="mb-2"
                                            >
                                              <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                            </StyledTooltip>
                                          </StyledTooltipWrap>
                                        </Grid>
                                      )}
                                    </>
                                  )}
                                {invoicePopup && (
                                  <>
                                    <Modal
                                      CrossIconHide
                                      isOpen={invoicePopup}
                                      widthRequiredIs
                                      handleOnCancel
                                      onCancel={() => {
                                        setInvoicePopup(false);
                                        formProps.setFieldValue('isInvoiced', false);
                                      }}
                                      onSubmit={() => {
                                        setInvoicePopup(false);
                                        formProps.setFieldValue('isInvoiced', true);
                                      }}
                                      submitTitle={'I understand'}
                                    >
                                      <p>
                                        If your clients pay by invoice, they will be able to join after purchasing and
                                        you will be able to collect payment via credit card or bank transfer.
                                      </p>
                                    </Modal>
                                  </>
                                )}
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>
                        </FormControlFullWidthStyled>
                      </Grid>
                    );
                  }}
                </FieldArray>
              </Grid>
              <Grid item xs={12} sm={4} style={smView ? { paddingLeft: 0 } : { paddingTop: 0 }}>
                <div
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #E7E7E7',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '30px',
                      letterSpacing: '0.1px',
                      minHeight: '50px',
                      padding: '10px 24px',
                      borderBottom: '1px solid #E7E7E7',
                    }}
                  >
                    Payment Summary
                  </div>
                  <StyledSummaryBodyContainer>
                    {contribution?.type === ContributionType.contributionCourse &&
                      (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE) ||
                        formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) ||
                        formProps.values.paymentOptions.includes('SplitPayments')) && (
                        <StyledText style={{ fontSize: '1rem' }}>
                          The following pricing links will be created for this contribution:
                        </StyledText>
                      )}
                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE) &&
                      contribution?.type === ContributionType.contributionCourse && (
                        <SummaryPricingTextContainer>
                          <StyledHeader className="value-title">Free:</StyledHeader>
                          <StyledText className="value-body">
                            A link will be created for clients to join for free
                          </StyledText>
                        </SummaryPricingTextContainer>
                      )}
                    {/* {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) &&
                      contribution?.type === ContributionType.contributionCourse && (
                        <StyledHeader>Full Payment Pricing Options:</StyledHeader>
                      )} */}
                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) &&
                      (contribution?.isWorkshop ? (
                        <SummaryPricingTextContainer>
                          <StyledHeader className="value-title">Workshop Price:</StyledHeader>
                          <StyledText className="value-body">
                            {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                            {formatTotalPrice(formProps.values)}
                          </StyledText>
                        </SummaryPricingTextContainer>
                      ) : (
                        <SummaryPricingTextContainer>
                          <StyledHeader className="value-title-group">Full Payment Pricing Options:</StyledHeader>
                          {formProps?.values?.multiplePriceList.map((sessionTime, i) => (
                            <StyledText className="value-body-item" key={i}>
                              Price per Course:&nbsp;
                              {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                              {formatTotalPrice(formProps.values, sessionTime.cost)}
                            </StyledText>
                          ))}
                        </SummaryPricingTextContainer>
                      ))}
                    {/* {formProps.values.paymentOptions.includes('SplitPayments') &&
                      contribution?.type === ContributionType.contributionCourse && (
                        <StyledHeader>Split Payment Pricing Options:</StyledHeader>
                      )} */}
                    {formProps.values.paymentOptions.includes('SplitPayments') && (
                      <SummaryPricingTextContainer>
                        <StyledHeader className="value-title-group">Split Payment Pricing Options:</StyledHeader>
                        {formProps?.values?.multiplePriceList.map((sessionTime, i) => (
                          <StyledText className="value-body-item" key={i}>
                            Price per Split Payment:&nbsp;
                            {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                            {formatMoney(sessionTime.splittedCost || 0)}
                          </StyledText>
                        ))}
                      </SummaryPricingTextContainer>
                    )}
                  </StyledSummaryBodyContainer>
                </div>
              </Grid>
            </Grid>

            {supportPopup && (
              <>
                <Modal
                  CrossIconHide
                  isOpen={supportPopup}
                  widthRequiredIs
                  handleOnCancel
                  disableConfirm
                  onCancel={() => {
                    setSupportPopup(false);
                  }}
                >
                  <p>
                    You are creating contribution on standard stripe account. Please make sure automatic tax calculation
                    is enabled from stripe settings otherwise client's won't be able to purchase the contribution.
                  </p>
                </Modal>
              </>
            )}
          </Form>
        );

        return children(formProps, renderCardBody);
      }}
    </Formik>
  );
};

LiveCourses.propTypes = {
  contribution: PropTypes.shape(),
  saveToStorageHandler: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

LiveCourses.defaultProps = {
  contribution: null,
};

export default LiveCourses;
