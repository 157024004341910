import React, { useState, useCallback, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { useRouter, useHttp, useAccount, useScreenView } from 'hooks';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import EasyCropper from 'components/ImageCropper/Cropper/EasyCropper';
import { dataURLtoFile, isEmptyOrNil } from 'utils/utils';
import bannerplaceholdernew from 'assets/bannerplacholdenew.png';
import { HiddenFileInput } from './SharedStyledComponents';
import { CameraIcon, PictureIcon } from './Icon';
import useNotifications from 'hooks/useNotifications';
import { cloneDeep } from 'lodash';
import ImageDropzone from 'components/FormUI/ImageDropone';

const StyledBanner = styled.div`
  display: flex;
  /* width: 34.125rem; */
  height: 13rem;
  padding: 2.5rem 1.25rem 2.45rem 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  background: #f5f5f5;
`;
const StyledBannerCaption = styled.div`
  color: ${({ isError = false }) => (isError ? '#8B0000' : '#797a7b')};
  text-align: center;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 0.5rem;
`;
const StyledBannertext = styled.div`
  color: ${({ isError = false }) => (isError ? '#8B0000' : '#bdbdbd')};
  text-align: center;
  font-family: -apple-system, Avenir;
  font-size: 1rem;
  font-style: normal;
  font-weight: 350;
  line-height: 1.25rem; /* 125% */
  padding-left: 2rem;
  padding-right: 2rem;
`;

const StyledImageDropzone = styled(ImageDropzone)`
  height: auto;
  margin: 0;
  padding: 0;
  > p {
    margin: 0;
  }
`;

// 200 MBs file limit
const FILE_SIZE_LIMIT = 200 * 1024 * 1024;

const BannerUploader = ({ onBannerUpload = () => {}, isVideoBanner, bannerImage }) => {
  const bioFileInput = React.createRef();
  const { request, progress } = useHttp();
  const [fileUploadError, setfileUploadError] = useState(false);
  const [imageToCrop, setImageToCrop] = useState('');
  const [isPrimaryOpen, setIsPrimaryOpen] = useState(false);
  const [isBioOpen, setIsBioOpen] = useState(false);
  const { query, history } = useRouter();
  const bioBannerUrl = bannerImage || '';
  const [isUploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { successMessage } = useNotifications();
  const { mobileView } = useScreenView();
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState('');
  const messageTextRef = useRef(null);
  const videoPlayerRef = useRef(null);
  const videoPlayerContainerRef = useRef(null);
  const [videoPlayerHeight, setVideoPlayerHeight] = useState(0);
  useEffect(() => {
    if (videoPlayerContainerRef.current /* && isVideoBanner*/) {
      // const playerElement = videoPlayerRef.current.wrapper;
      // const currentWidth = playerElement.clientWidth;
      const currentWidth = videoPlayerContainerRef.current.getBoundingClientRect().width;
      const maxHeight = (currentWidth / 16) * 9;
      console.info('width ; max height:', currentWidth, maxHeight);
      setVideoPlayerHeight(maxHeight);
      if (videoPlayerRef?.current) {
        const playerWrapper = videoPlayerRef.current.wrapper;
        console.info('player ref:', videoPlayerRef?.current, playerWrapper);
        if (playerWrapper) {
          playerWrapper.style.maxHeight = maxHeight ? `${maxHeight}px` : '16.7855rem';
          playerWrapper.style.overflow = 'hidden';
        }
        const internalPlayer = videoPlayerRef.current.getInternalPlayer();
        console.info('internal player:', internalPlayer);
        if (internalPlayer) {
          internalPlayer.style.maxHeight = maxHeight ? `${maxHeight}px` : '16.7855rem';
        }
        setTimeout(() => {
          const internalPlayer = videoPlayerRef.current.getInternalPlayer();
          console.info('internal player:', internalPlayer);
          if (internalPlayer) {
            internalPlayer.style.maxHeight = maxHeight ? `${maxHeight}px` : '16.7855rem';
          }
        }, 300);
      }
    }
  }, [
    videoPlayerRef,
    videoPlayerRef?.current,
    videoPlayerContainerRef,
    videoPlayerContainerRef?.current,
    isVideoBanner,
  ]);
  const onBioBannerUploadClick = useCallback(() => {
    if (bioFileInput) {
      bioFileInput.current.click();
    }
  }, [bioFileInput]);
  const uploadFileHandler = (imageName, droppedFiles = []) => e => {
    setfileUploadError(false);
    setFileUploadErrorMessage('');

    let files;
    if (droppedFiles.length > 0) {
      files = droppedFiles;
    } else if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (!files || !files[0]) {
      return;
    }
    if (files[0].size > FILE_SIZE_LIMIT) {
      setFileUploadErrorMessage('Selected file size is greater than 200 MB.');
      setfileUploadError(true);
      return;
    }
    const reader = new FileReader();
    reader.onloadstart = () => {
      setUploading(true);
      //   setUploadProgress(0);
    };
    reader.onprogress = e => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;
        setUploadProgress(progress);
        console.log(`Progress: ${progress}%`);
      }
    };
    reader.onload = () => {
      setUploading(false);
      setUploadProgress(0);
      setfileUploadError(false);
      setFileUploadErrorMessage('');
      if (files[0].type.includes('video/')) {
        setUploading(true);
        const formData = new FormData();
        const imageSrc = reader.result;
        formData.append('file', dataURLtoFile(imageSrc));
        request('/content/AddPublicFile', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        })
          .then(video => {
            onBannerUpload(video, true);
            successMessage('Success', 'Media file uploaded successfully');
          })
          .catch(err => {
            setFileUploadErrorMessage('An error occurred while uploading. Please try again.');
            setfileUploadError(true);
          })
          .finally(() => {
            setUploading(false);
          });
        return;
      }
      // setUploading(false);
      setImageToCrop(reader.result?.toString() || '');
      if (imageName === 'bio') setIsBioOpen(true);
      if (imageName === 'primary') setIsPrimaryOpen(true);
    };
    reader.readAsDataURL(files[0]);
    setfileUploadError(false);
    setFileUploadErrorMessage('');
  };
  if (isUploading) {
    return (
      <StyledBanner style={{ position: 'relative' }}>
        <CircularProgressWithLabel value={uploadProgress || progress} />
      </StyledBanner>
    );
  }

  return (
    <>
      {isEmptyOrNil(bannerImage) ? (
        <>
          <StyledImageDropzone
            action={droppedFiles => {
              uploadFileHandler('bio', droppedFiles)();
            }}
            isDisabled={false}
            placeholder={
              <StyledBanner
                onClick={onBioBannerUploadClick}
                onKeyUp={onBioBannerUploadClick}
                role="button"
                tabIndex="0"
              >
                <PictureIcon style={{ width: '1.5rem', height: '1.5rem' }} />
                <HiddenFileInput
                  onChange={uploadFileHandler('bio')}
                  ref={bioFileInput}
                  accept="image/jpeg,image/png,image/gif,video/*,video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime"
                />
                <StyledBannertext>
                  If it's a photo, it should be approximately 1280×720px. If you are exporting from Canva or a similar
                  platform, export your image a 1.5X. If it's video, it should be in 16:9 format, and for best
                  performance it should not exceed 200 MB
                </StyledBannertext>
                {fileUploadError && fileUploadErrorMessage && (
                  <StyledBannertext isError>{fileUploadErrorMessage}</StyledBannertext>
                )}
              </StyledBanner>
            }
            type="image/jpeg,image/png,image/gif,video/*,video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime"
            stopPropagation
            multiple={false}
          />
        </>
      ) : (
        <div>
          <Grid
            style={{
              backgroundColor: '#F7F9FA',
              padding: '10px',
              minHeight: '16.7855rem',
              // height: fileUploadError ? 'auto' : '16.7855rem',
              height: 'auto',
              // maxHeight: videoPlayerHeight ? `${videoPlayerHeight}px` : '16.7855rem',
            }}
            className="profile-picture"
            // ref={videoPlayerRef}
          >
            <div className="banner-avatar-container " style={{ position: 'relative' }} ref={videoPlayerContainerRef}>
              {isVideoBanner ? (
                <ReactPlayer
                  muted
                  url={bannerImage}
                  playing
                  width="100%"
                  // // height="13.1355rem"
                  height="auto"
                  // style={{ maxHeight: videoPlayerHeight ? `${videoPlayerHeight}px` : '16.7855rem' }}
                  loop
                  ref={videoPlayerRef}
                />
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    alt="banner"
                    style={{
                      maxWidth: '100%',
                      minHeight: '13.1355rem',
                      height: 'auto',
                    }}
                    src={bannerImage}
                  />
                </div>
              )}

              <span
                style={{
                  height: '2rem',
                  width: '2rem',
                  position: 'absolute',
                  float: 'right',
                  right: '12px',
                  bottom: mobileView
                    ? `calc(${messageTextRef.current?.clientHeight || 79 || 95}px + 1rem + ${
                        fileUploadError ? 15 + 8 : 0
                      }px)`
                    : `calc(${messageTextRef.current?.clientHeight || 48}px + 1rem + ${
                        fileUploadError ? 15 + 8 : 0
                      }px)`,
                  borderRadius: '48px',
                  //   padding: '8px',
                  display: 'flex',
                  background: '#cdba8f',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={onBioBannerUploadClick}
                onKeyUp={onBioBannerUploadClick}
                role="button"
                tabIndex="0"
              >
                <CameraIcon style={{ color: 'white' }} />
                {/* <UploadImageIcon /> */}
              </span>
              <HiddenFileInput
                onChange={uploadFileHandler('bio')}
                ref={bioFileInput}
                accept="image/jpeg,image/png,image/gif,video/*,video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime"
              />
              {bannerImage && (
                <StyledBannerCaption ref={messageTextRef}>
                  If it's a photo, it should be approximately 1280×720px. If you are exporting from Canva or a similar
                  platform, export your image a 1.5X. If it's video, it should be in 16:9 format, and for best
                  performance it should not exceed 200 MB
                </StyledBannerCaption>
              )}
              {fileUploadError && fileUploadErrorMessage && (
                <StyledBannerCaption isError>{fileUploadErrorMessage}</StyledBannerCaption>
              )}
            </div>
          </Grid>
        </div>
      )}
      <EasyCropper
        title={`Crop Your Banner Image`}
        submitTitle="Set Image"
        imageToCrop={imageToCrop}
        isOpen={isBioOpen || isPrimaryOpen}
        onClose={() => {
          setIsBioOpen(false);
          setIsPrimaryOpen(false);
          setImageToCrop('');
        }}
        setFieldValue={(a, b) => {
          onBannerUpload(b, false);
        }}
        successCallback={image => {
          if (image) {
            successMessage('Success', 'Media file uploaded successfully');
          }
        }}
        field="file"
        shape="rectangle"
        cropRatio={16 / 9}
      />
    </>
  );
};

export default BannerUploader;
