import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { mdiShareVariant } from '@mdi/js';
import { fetchUpcomingCreated, fetchUpcomingCreatedWithType } from 'actions/contributions';
import VideoButton from 'components/UI/ListButtons/VideoButton';
import EditButton from 'components/UI/ListButtons/EditButton';
import ExportButton from 'components/UI/ListButtons/ExportButton';
import ChatButton from 'components/UI/ListButtons/ChatButton';
import ViewButton from 'components/UI/ListButtons/ViewButton';
import Modal from 'components/UI/Modal';
import AnalyticsButton from 'components/UI/ListButtons/AnalyticsButton';
import { ConversionMetricsModal } from 'components/Modals/ConversionMetricsModal';
import { ContributionStatus } from 'helpers/constants';
import { formatMoney } from 'utils/datesAndMoney';
import { getLeadMagnetStatusView } from 'utils/getLeadMagnetStatusView';
import { getLeadMagnetTypeView } from 'utils/getLeadMagnetTypeView';
import { LeadMagnetStatus } from 'helpers/constants';
import { useAccount, useRouter, useHttp } from 'hooks';
import { useRecordModal } from 'hooks/useSessionRecordModal';
import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import ShareIcon from '@material-ui/icons/Share';
import { shareViaEmail } from 'services/contributions.service';
import moment from 'moment';
import usePaidTier from 'hooks/usePaidTier';
import { Icon as Icon1 } from '@mdi/react';
import { TooltipWrapper } from '../UI/TooltipWrapper';
import ClickDropDown, { StyledDropDownItem } from '../UI/ClickDropDown';
import { StyledLink } from '../../pages/CreateContribution/style';
import { BodyText } from 'components/UI/Text/TextStyles';
import Loader from 'components/UI/Loader';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { fetchCohealerContribution } from 'actions/contributions';
import * as contributionActions from 'actions/contributions';
import getBackPages from 'utils/getBackPages';
import { makeStyles } from '@material-ui/core';

import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType, getClientContribution, getCohealerContribution } from 'services/contributions.service';
import {
  archiveLeadMagnet,
  deleteLeadMagnet,
  exportLeadMagnetUsersByLeadMagnetId,
  getProfileLinkNameByLeadMagnetId,
} from 'services/leadMagnets.service';
import * as paidTier from 'selectors/paidTier';
import { isPaidTierAnnualSelector } from 'selectors/user';
import useShallowEqualSelector from '../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../constants';
import { UserRoles } from 'helpers/constants';
import IconButton from '@material-ui/core/IconButton';
import { fetchCohealerLeadMagnet, fetchLeadMagnets, saveDraftLeadMagnet } from 'actions/leadMagnets';
import { cloneDeep } from 'lodash';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import { TextField } from '@material-ui/core';
import copyToClipboard from 'utils/copyToClipboard';
import Tooltip from '@material-ui/core/Tooltip';
import { addhttp, downloadFile } from 'utils/utils';
import useNotifications from 'hooks/useNotifications';

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})(TableCell);
const StyledShareButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    border-radius: 50%;
    padding: 12px;
    min-width: initial;
    .MuiButton-label {
      .MuiButton-startIcon {
        margin: 0;
      }
    }
  }
`;

const LabelBadgeStyles = styled.div`
  display: flex;
  padding: 0.4375rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Secondary-Brown, #c9b382);
  background: rgba(201, 179, 130, 0.1);

  height: fit-content;
  width: fit-content;

  div.text {
    /* display: flex;
    width: 1.6875rem;
    height: 0.75rem;
    flex-direction: column;
    justify-content: center; */

    color: var(--Cohere-Primary-Brown, #a58b62);
    font-family: Avenir;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.25125rem; /* 143% */
  }
`;

export const CopyButton = styled.button`
  margin: 16px 0 0 12px;
  position: relative;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  border: none;
  fill: #fff;
  font-weight: 900;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  transition: background 0.2s;
  font-family: 'Avenir';
  border-radius: 4px;
  background-color: #cdba8f;
  color: #ffffff;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    padding: 11.5px 14px;
  }
  .MuiOutlinedInput-root {
    margin-top: 15px;
  }
`;

const StyledLabelStatusBadge = styled(LabelBadgeStyles)`
  ${({ isDraft = true }) => {
    const pickedColors = isDraft
      ? {
          borderColor: 'var(--Cohere-Greys-Text, #4a4a4a)',
          backgroundColor: 'var(--Cohere-Greys-Background-Grey, #f5f5f5)',

          textColor: '#4a4a4a',
        }
      : {
          borderColor: 'var(--Cohere-Primary-Blue, #215c73)',
          backgroundColor: 'var(--Cohere-Bluish-Grey-Tint-06, #f1f8ff)',

          textColor: 'var(--Cohere-Primary-Blue, #215c73)',
        };

    return css`
      border-color: ${pickedColors?.borderColor};
      background-color: ${pickedColors?.backgroundColor};

      div.text {
        color: ${pickedColors?.textColor};
      }
    `;
  }}
`;
const StyledLabelTypeBadge = styled(LabelBadgeStyles)`
  border-color: var(--Cohere-Secondary-Brown, #c9b382);
  background-color: rgba(201, 179, 130, 0.1);

  div.text {
    color: var(--Cohere-Primary-Brown, #a58b62);
  }
`;

export const StyledShareIcon = styled(ShareIcon)`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;


export const TRIAL_PERIOD_START_DATE = '2021-11-01';
export const TRIAL_PERIOD_DAYS = 13;

const useStyles = makeStyles(theme => ({
  button: {
    // paddingLeft: '0px',
  },
}));

const LeadMagnet = ({
  id,
  type,
  closestSession,
  studentsNumber,
  title,
  isArchived,
  earnedRevenue,
  status,
  setPopupText,
  reasonDescription,
  currency,
  symbol,
  paymentInfo,
  archivingAllowed,
  deletingAllowed,
  isInvoiced,
  userId: leadMagnetUserId,
  index,
  rows,
  deleteIndex,
  archiveIndex,
  isArchivedPage,
}) => {
  const classes = useStyles();
  const { history, pathname, domain, parentDomain } = useRouter();
  const dispatch = useDispatch();

  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });

  const { currentRole } = useAccount();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const activePaidTierCurrentPaymentPeriod = useShallowEqualSelector(
    paidTier.activePaidTierCurrentPaymentPeriodSelector,
  );

  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const activeAcademyContributions = useSelector(paidTier.activeCohereAcademyContributionsSelector);
  const [analyticsModalOpened, setAnalyticsModalOpened] = useState(false);

  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  const handleAnalyticsModalOpen = () => {
    setAnalyticsModalOpened(true);
  };

  const handleAnalyticsModalClose = () => {
    setAnalyticsModalOpened(false);
  };

  // Correct behavior - 100%
  const filterMembershipById = useCallback(
    res => {
      const activeMembershipsArray = activeAcademyContributions;

      const matchedMembershipFromAll = academyContributions.find(membership => membership.id === res.id);

      if (!matchedMembershipFromAll) {
        return activeMembershipsArray;
      }

      const matchedMembershipFromActive = activeMembershipsArray.find(membership => membership.id === res.id);

      if (matchedMembershipFromActive) {
        return activeMembershipsArray;
      }

      activeMembershipsArray.push(matchedMembershipFromAll);

      return activeMembershipsArray;
    },
    [activeAcademyContributions],
  );

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  // const { isLaunchPlan } = usePaidTier();
  const { user } = useAccount();
  const { id: userId } = user;
  const [asCompletedModal, setAsCompletedModal] = useState(false);

  const url = status === LeadMagnetStatus.unfinished ? `/edit-lead-magnet/${id}` : `/lead-magnet-view/${id}`;
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });

  const { request } = useHttp();
  const [modalOpened, setModalOpened] = useState(false);
  const [asDeleteModal, setAsDeleteModal] = useState(false);
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const todayDate = moment(moment());
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [duplicateLeadMagnet, setDuplicateLeadMagnet] = useState(false);
  const [isDuplicated, setDuplicationSuccess] = useState(false);
  const { themedColors } = useLeadMagnetTheme();
  const { infoMessage, successMessage } = useNotifications();
  const { themedColor } = themedColors;
  const { origin } = window.location;
  //const handleCopyClick = () => copyToClipboard(user.profileLinkName ? addhttp(user.profileLinkName.concat('.', `${parentDomain}/lead-magnet-view/${id}/landingpage`)): `${origin}/lead-magnet-view/${id}/landingpage`);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [thankYouTooltipOpen, setThankYouTooltipOpen] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [leadMagnetShareableLink, setLeadMagnetShareableLink] = useState('');
  const [generatingShareableLink, setGeneratingShareableLink] = useState(false);
  const [leadMagnetShareableThankYouLink, setLeadMagnetShareableThankYouLink] = useState('');

  const generateShareableLink = useCallback(() => {
    if (id) {
      setGeneratingShareableLink(true);
      // return `${origin}/lead-magnet-view/${leadMagnet.id}/landingpage`;
      const urlPath = `/lead-magnet-view/${id}/landingpage`;
      const urlPathThankYouPage = `/lead-magnet-view/${id}/thankyoupage`;
      getProfileLinkNameByLeadMagnetId(id)
        .then(res => {
          if (res) {
            setLeadMagnetShareableLink(addhttp(`${res}.${parentDomain}${urlPath}`));
            setLeadMagnetShareableThankYouLink(addhttp(`${res}.${parentDomain}${urlPathThankYouPage}`));
          } else {
            setLeadMagnetShareableLink(`${origin}${urlPath}`);
            setLeadMagnetShareableThankYouLink(`${origin}${urlPathThankYouPage}`);
          }
        })
        .catch(() => {})
        .finally(() => {
          setGeneratingShareableLink(false);
        });
    }
  }, [id]);
  useEffect(() => {
    if (shareModalVisible) {
      generateShareableLink();
    }
  }, [shareModalVisible]);
  const handleCopyClick = useCallback(() => copyToClipboard(leadMagnetShareableLink), [leadMagnetShareableLink]);
  const handleCopyThankYouClick = useCallback(() => copyToClipboard(leadMagnetShareableThankYouLink), [
    leadMagnetShareableThankYouLink,
  ]);
  const handleSubmitDelete = () => {
    setDeleteModal(false);
  };
  const handleSubmit = () => {
    setShowArchiveModal(false);
  };

  const handleCloseModal = () => {
    setShareModalVisible(false);
  };

  const handleModalOpen = useCallback(() => {
    setShareModalVisible(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  const onSubmitHandler = leadMagnetId => {
    setLoader(true);
    return archiveLeadMagnet(leadMagnetId)
      .then(() => {
        archiveIndex(index);
      })
      .catch(console.dir)
      .finally(() => setLoader(false));
  };

  const onSubmitDeleteHandler = id => {
    setLoader(true);
    deleteLeadMagnet(id)
      .then(() => {
        deleteIndex(index);
        let url = getBackPages(type, false);
        if (url?.link?.includes('/lead-magnets')) {
          dispatch(fetchLeadMagnets(userId, type));
        }
      })
      .catch(console.dir);
  };
  const onSubmitDuplicateContributionHandler = leadMagnetId => {
    setDuplicateLeadMagnet(false);
    dispatch(fetchCohealerLeadMagnet(leadMagnetId))
      .then(data => {
        console.info('fetched:', cloneDeep(data));
        const magnetRecord = data.payload;
        if (magnetRecord?.id) {
          delete magnetRecord?.id;
        }
        const obj = {
          ...magnetRecord,
          // parentContributionId: leadMagnetId,
          parentLeadMagnetId: leadMagnetId,
          isDuplicate: true,
        };
        setLoader(true);
        return dispatch(saveDraftLeadMagnet(obj)).then(res => {
          // let url = getBackPages(type, false);
          // let allContributionUrl = window.location.pathname;
          // if (
          //   (url.link == '/contributions/group-courses' ||
          //     url.link == '/contributions/memberships' ||
          //     url.link == '/contributions/workshop') &&
          //   allContributionUrl !== '/contributions/all'
          // ) {
          //   dispatch(fetchUpcomingCreatedWithType(userId, type));
          // } else {
          //   dispatch(fetchUpcomingCreated(userId));
          // }
          setLoader(false);
          setDuplicationSuccess(true);
          // window.location.reload();
        });
        // .catch(console.dir);
      })
      .catch(console.dir);
  };

  const fetchandRemove = type => {
    if (type === 'delete') {
      dispatch(fetchUpcomingCreated(userId));
      // deleteIndex(index);
      setAsDeleteModal(false);
      setLoader(false);
    } else if (type === 'archive') {
      dispatch(fetchUpcomingCreated(userId));
      // archiveIndex(index);
      setLoader(false);
    }
  };
  const handleModalSubmit = useCallback(
    emails => {
      shareViaEmail({ contributionId: id, emailAddresses: emails })
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({ isError: true, errorMessage: err?.response?.data?.message });
        });
    },
    [handleModalClose],
  );
  const handleExportLeads = () => {
    exportLeadMagnetUsersByLeadMagnetId(id)
      .then(res => {
        if (!res) {
          return infoMessage('Info', 'No leads available to export.');
        }
        const fileName = `${title} - Leads`;
        return downloadFile(res, `${fileName}.csv`).then(() =>
          successMessage('Success', 'File exported successfully.'),
        );
      })
      .catch(console.dir);
  };

  const lastIndex = index === rows?.length - 1;
  const secondLast = index === rows?.length - 2;
  return (
    <>
      <TableRow>
        <NarrowTableCell component="th" scope="row">
          <Link to={url}>{title}</Link>
        </NarrowTableCell>
        <NarrowTableCell align="left">
          {isArchived ? (
            <NarrowTableCell component="th" style={{ border: 0, padding: 0 }}>
              Archived
            </NarrowTableCell>
          ) : (
            <StyledLabelStatusBadge isDraft={status === LeadMagnetStatus.unfinished}>
              <div className="text">{getLeadMagnetStatusView(status)}</div>
            </StyledLabelStatusBadge>
          )}
        </NarrowTableCell>
        <NarrowTableCell align="left">
          <StyledLabelTypeBadge>
            <div className="text">{getLeadMagnetTypeView(type)}</div>
          </StyledLabelTypeBadge>
        </NarrowTableCell>
        <NarrowTableCell align="center">
          <StyledShareButton
            disabled={status !== LeadMagnetStatus.approved || isArchived}
            startIcon={
              <Icon1
                path={mdiShareVariant}
                color={status !== LeadMagnetStatus.approved ? 'rgba(0, 0, 0, 0.26)' : '#116582'}
                size={1}
              />
            }
            onClick={handleModalOpen}
          />
        </NarrowTableCell>
        <NarrowTableCell align="center">
          {isArchived ? (
            <EditButton className={classes.button} disabled />
          ) : (
            <Link to={`/edit-lead-magnet/${id}`}>
              <EditButton className={classes.button} />
            </Link>
          )}
        </NarrowTableCell>
        <NarrowTableCell align="center">
          <ExportButton
            disabled={status === LeadMagnetStatus.unfinished}
            className={classes.button}
            onClick={status === LeadMagnetStatus.unfinished ? null : handleExportLeads}
          />
        </NarrowTableCell>
        <NarrowTableCell align="center">
          <AnalyticsButton
            className={classes.button}
            disabled={status !== LeadMagnetStatus.approved}
            handleClick={handleAnalyticsModalOpen}
          />
        </NarrowTableCell>
        {isArchivedPage ? null : isArchived ? (
          <NarrowTableCell />
        ) : (
          <NarrowTableCell>
            <ClickDropDown
              lastIndex={lastIndex}
              secondLast={secondLast}
              materialIcon
              id="header-user-dropdown"
              title=""
            >
              {leadMagnetUserId === userId &&
                (status === LeadMagnetStatus.unfinished || status === LeadMagnetStatus.approved) && (
                  // type !== 'ContributionOneToOne' && (
                  <div>
                    <StyledDropDownItem
                      onClick={() => {
                        setDuplicateLeadMagnet(true);
                      }}
                    >
                      Duplicate
                    </StyledDropDownItem>
                  </div>
                )}
              <div
                // onClick={() => (!archivingAllowed ? setShowArchiveModal(true) : setAsCompletedModal(true))}
                onClick={() => setAsCompletedModal(true)}
              >
                <StyledDropDownItem
                  className={!archivingAllowed && 'disableMe'}
                  onClick={() => {
                    setAsCompletedModal(true);
                  }}
                >
                  Archive
                </StyledDropDownItem>
              </div>
              {leadMagnetUserId === userId && (
                <div
                  // onClick={() => (!deletingAllowed ? setDeleteModal(true) : setAsDeleteModal(true))}
                  onClick={() => setAsDeleteModal(true)}
                >
                  <StyledDropDownItem
                    // className={!deletingAllowed && 'disableMe'}
                    onClick={() => {
                      setAsDeleteModal(true);
                    }}
                  >
                    Delete
                  </StyledDropDownItem>
                </div>
              )}
            </ClickDropDown>
          </NarrowTableCell>
        )}
      </TableRow>
      <Modal
        isOpen={showArchiveModal}
        onCancel={() => {
          setShowArchiveModal(false);
        }}
        onSubmit={handleSubmit}
        title="Archive Contribution"
        hiddenCancel
        disableConfirm
      >
        <BodyText>
          You are allowed to archive if you have no outstanding sessions with clients or have no unused package sessions
          with clients. Once archived, this will appear in the "Archived" section under "My Contributions" on your
          dashboard. If you want to re-launch this service, you will need to create a new Contribution.
        </BodyText>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onCancel={() => {
          setDeleteModal(false);
        }}
        onSubmit={handleSubmitDelete}
        title="Delete Contribution"
        hiddenCancel
        disableConfirm
        brandingColor={false}
      >
        <BodyText>
          Since you have enrolled clients, you cannot delete this contribution as they will lose access. You can archive
          this contribution. Or, if you still wish to delete this contribution, please contact{' '}
          <StyledLink href="support@cohere.live" rel="noopener noreferrer" target="_blank">
            (support@cohere.live).
          </StyledLink>{' '}
          <br />
        </BodyText>
      </Modal>
      <Modal
        isOpen={asDeleteModal}
        title="Delete Lead Magnet"
        submitTitle="OK"
        onSubmit={() => {
          onSubmitDeleteHandler(id);
        }}
        onCancel={() => {
          setAsDeleteModal(false);
        }}
        brandingColors={false}
      >
        <p>
          Everything associated with this lead magnet will be deleted from your account. Are you sure you wish to
          continue?
        </p>
      </Modal>
      <Modal
        isOpen={duplicateLeadMagnet}
        title="Duplicate Lead Magnet"
        submitTitle="OK"
        onSubmit={() => {
          onSubmitDuplicateContributionHandler(id);
        }}
        onCancel={() => {
          setDuplicateLeadMagnet(false);
        }}
      >
        <p>Are you sure you want to duplicate the lead magnet?</p>
      </Modal>
      <Modal
        isOpen={isDuplicated}
        hiddenCancel
        title="Duplicated Successfully"
        submitTitle="OK"
        onSubmit={() => {
          setDuplicationSuccess(false);
        }}
        onCancel={() => {
          setDuplicationSuccess(false);
        }}
      >
        <p>Your lead magnet has been duplicated. Please note that Step 3 may require additional information.</p>
      </Modal>
      {modalOpened && (
        <ShareModal
          isOpen={modalOpened}
          onSubmit={handleModalSubmit}
          onModalClose={handleModalClose}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
          contributionId={id}
          paymentInfo={paymentInfo}
          type={type}
          isInvoiced={isInvoiced}
          symbol={symbol}
        />
      )}
      <Modal
        isOpen={asCompletedModal}
        title="Archive Lead Magnet"
        submitTitle="OK"
        onSubmit={() => {
          onSubmitHandler(id);
        }}
        onCancel={() => {
          setAsCompletedModal(false);
        }}
      >
        <p>Are you sure you want to archive the lead magnet?</p>
      </Modal>
      <Modal
        isOpen={shareModalVisible}
        minHeight={'280px'}
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '0.625rem',
            }}
          >
            <StyledShareIcon style={{ margin: 0 }} />
            <div>Share Lead Magnet</div>
          </div>
        }
        hiddenCancel
        onCancel={handleCloseModal}
        className={'lead-magnet-form-modal'}
        disableFooter={true}
      >
        <div className="lead-magnet-heading">
          <div style={{ color: themedColor, marginTop: '12px' }}> Copy Opt-In Page Link - Share This With Leads </div>
        </div>
        <div style={{ display: 'flex' }}>
          <StyledTextField
            placeholder={generatingShareableLink ? 'Generating link...' : 'Copy Opt-In Page Link - Share This With Leads'}
            disabled={generatingShareableLink}
            value={leadMagnetShareableLink}
            //value={user.profileLinkName ? addhttp(user.profileLinkName.concat('.', `${parentDomain}/lead-magnet-view/${id}/landingpage`)): `${origin}/lead-magnet-view/${id}/landingpage` }
            fullWidth
            variant="outlined"
            id="margin-none"
          />
          <Link
            disabled={generatingShareableLink}
            onClick={
              generatingShareableLink
                ? null
                : () => {
                    handleCopyClick();
                    setTooltipOpen(true);
                    handleCopyClick();
                    setTimeout(() => {
                      setTooltipOpen(false);
                    }, 2000);
                  }
            }
          >
            <Tooltip className="cursor-pointer" title="Link Copied" open={tooltipOpen} onClose={() => {}} arrow>
              <CopyButton disabled={generatingShareableLink}>Copy</CopyButton>
            </Tooltip>
          </Link>
        </div>

        <div className="lead-magnet-sub-heading">
          <div style={{ color: themedColor, marginTop: '12px' }}> Copy Thank You Page Link </div>
        </div>
        <div style={{ display: 'flex' }}>
          <StyledTextField
            placeholder={generatingShareableLink ? 'Generating link...' : 'Copy Thank You Page Link'}
            disabled={generatingShareableLink}
            value={leadMagnetShareableThankYouLink}
            fullWidth
            variant="outlined"
            id="margin-none"
          />
          <Link
            disabled={generatingShareableLink}
            onClick={
              generatingShareableLink
                ? null
                : () => {
                    setThankYouTooltipOpen(true);
                    handleCopyThankYouClick();
                    setTimeout(() => {
                      setThankYouTooltipOpen(false);
                    }, 2000);
                  }
            }
          >
            <Tooltip className="cursor-pointer" title="Link Copied" open={thankYouTooltipOpen} onClose={() => {}} arrow>
              <CopyButton disabled={generatingShareableLink}>Copy</CopyButton>
            </Tooltip>
          </Link>
        </div>
      </Modal>
      {analyticsModalOpened && (
        <ConversionMetricsModal
          isOpen={analyticsModalOpened}
          leadMagnetId={id}
          handleClose={handleAnalyticsModalClose}
        />
      )}
      {loader && <Loader />}
    </>
  );
};

LeadMagnet.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closestSession: PropTypes.shape({
    chatSid: PropTypes.string,
  }),
  studentsNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isArchived: PropTypes.bool,
  earnedRevenue: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  setPopupText: PropTypes.func.isRequired,
  reasonDescription: PropTypes.string,
  isInvoiced: PropTypes.string,
  archivingAllowed: PropTypes.bool,
  deletingAllowed: PropTypes.bool,
  isArchivedPage: PropTypes.bool,
};

LeadMagnet.defaultProps = {
  closestSession: undefined,
  isArchived: undefined,
  reasonDescription: undefined,
  archivingAllowed: true,
  deletingAllowed: true,
  isArchivedPage: false,
};

export default LeadMagnet;
