import React, { useRef, useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import { TextField } from '@material-ui/core';

import {
  StyledVideo,
  StyledPlayIconWrapper,
  StyledDeleteContainer,
  StyledUploadThumbnailContainer,
  StyledPdfContainer,
  StyledPdfIcon,
  StyledPdfTextContainer,
  StyledTextContainer,
} from './StyleComponents';

import UploadThumbnailIcon from 'pages/CreateContribution/components/UploadThumbnailIcon';
import { colors } from 'utils/styles';
import { TOOLTIP } from 'pages/ContributionView/ContributionSessions/constants';
import { PlayIcon } from 'pages/ContributionView/ContributionSessions/component/icons';
import UploadThumbnail from 'pages/CreateContribution/components/UploadThumbnail';
import { DescriptionOutlined } from '@material-ui/icons';
import { AudioIcon, CrossOutlineIcon, PdfIcon } from './Icon';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';
import { getVideoUrl } from 'services/leadMagnets.service';
import useScreenView from 'hooks/useScreenView';

const DisplayContent = ({ content, setFieldValue, i, values, remove }) => {
  const { previewContentUrls } = useLeadMagnet();
  const [previewVideo, setPreviewVideo] = useState(null);
  const [timeStamp, setTimeStamp] = useState(0);

  const containerRef = useRef(null);
  const { documentOriginalNameWithExtension: fileName } = content;
  const [displayedFileName, setDisplayedFileName] = useState(fileName);

  const isVideo =
    content?.extension?.toLowerCase()?.includes('mp4') ||
    content?.extension?.toLowerCase()?.includes('webm') ||
    content?.extension?.toLowerCase()?.includes('mov');

  const [showVideo, setShowVideo] = useState(false);
  const [isOpenUploadThumbnail, setIsOpenUploadThumbnail] = useState(false);
  const videoRef = useRef(null);
  const { mobileView } = useScreenView();

  const onPlay = e => {
    const video = videoRef.current;
    video.currentTime = timeStamp;
    video.play();
  };

  const onPause = e => {
    const video = videoRef.current;
    video.pause();
    setTimeStamp(video.duration && video.currentTime === video.duration ? 0 : video.currentTime);
    video.load();
  };
  const getPreviewVideoUrl = async () => {
    getVideoUrl(content?.documentKeyWithExtension).then(res => {
      setPreviewVideo(res);
    });
  };

  useEffect(() => {
    // if (showVideo === true) {
    if (content?.documentKeyWithExtension) {
      getPreviewVideoUrl();
    }
    // }
  }, []);
  useEffect(() => {
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const charsPerLine = containerWidth / 12; // Approximate width of each character
      const halfPerLine = charsPerLine / 2;
      if (fileNameWithoutExtension.length > charsPerLine) {
        // If text exceeds two lines
        const truncatedText1 = fileNameWithoutExtension.substring(0, charsPerLine);
        const truncatedText2 = fileNameWithoutExtension.substring(charsPerLine, charsPerLine + halfPerLine);
        const fullName =
          truncatedText1 +
          truncatedText2 +
          '...' +
          fileNameWithoutExtension.slice(-1) +
          content.extension?.toLowerCase();
        setDisplayedFileName(fullName);
      } else {
        // setDisplayedFileName(fullName);
      }
    }
  }, [fileName]);
  return (
    <div style={{ marginBottom: '0.5rem' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px', marginBottom: '0.5rem' }}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            console.info('lead magnet removed');
            remove(i);
          }}
        >
          <CrossOutlineIcon />
        </div>
      </div>
      {isVideo ? (
        showVideo && previewVideo ? (
          <StyledVideo
            ref={videoRef}
            controls
            preload="none"
            onPlay={onPlay}
            onPause={onPause}
            poster={content?.thumbnailUrl || previewContentUrls?.at(0) || ''}
          >
            <source src={previewVideo} />
          </StyledVideo>
        ) : (
          <div className="position-relative w-100">
            <Tooltip
              title="Thumbnail"
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <StyledUploadThumbnailContainer
                onClick={() => {
                  setIsOpenUploadThumbnail(true);
                }}
              >
                <UploadThumbnailIcon style={{ width: '36px', height: '37px' }} />
              </StyledUploadThumbnailContainer>
            </Tooltip>
            <div className="position-relative w-100">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '250px',
                  // width: '100%',
                  maxWidth: '100%',
                  background: 'black',
                }}
                onClick={() => {
                  setShowVideo(true);
                  if (previewVideo) {
                    setTimeout(() => {
                      onPlay();
                    }, [2000]);
                  }
                }}
              >
                <img
                  style={{
                    height: '250px',
                    // width: '70%',
                    maxWidth: '100%',
                  }}
                  src={content?.thumbnailUrl || previewContentUrls?.at(0) || ''}
                />
                <StyledPlayIconWrapper
                  style={{
                    height: '52.045px',
                    width: '52.045px',
                    color: 'gray',
                    position: 'absolute',
                    zIndex: 10,
                    cursor: 'pointer',
                    borderRadius: '46.263px',
                    background: colors.darkOceanBlue,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <PlayIcon
                    style={{
                      height: '24.152px',
                      width: '24.152px',
                      color: '#FFF',
                      position: 'absolute',
                      zIndex: 15,
                      cursor: 'pointer',
                    }}
                  />
                </StyledPlayIconWrapper>
              </div>
            </div>
          </div>
        )
      ) : content?.embeddedVideoUrl ? (
        <>
          <TextField
            fullWidth
            name={values.content[i].embeddedVideoUrl}
            placeholder={`or Embed a video link by pasting URL (e.g. http://www.vimeo.com)`}
            variant="outlined"
            onChange={e => {
              setFieldValue(`content[${i}].embeddedVideoUrl`, e.target.value);
              console.info('lead magnet push', content);
            }}
            value={values.content[i].embeddedVideoUrl}
            type="text"
            InputProps={{
              style: {
                backgroundColor: '#f5f5f5',
                borderRadius: '0px',
              },
            }}
          />
        </>
      ) : content?.extension?.toLowerCase()?.includes('mp3') ||
        content?.extension?.toLowerCase()?.includes('wav') ||
        content?.extension?.toLowerCase()?.includes('m4a') ||
        content?.extension?.toLowerCase()?.includes('ogg') ? (
        <StyledPdfContainer {...{ mobileView }}>
          <StyledPdfIcon>
            <AudioIcon
              style={{
                width: '6.5625rem',
                height: '6.5625rem',
                color: '#116582',
              }}
            />
          </StyledPdfIcon>
          {mobileView ? (
            <StyledTextContainer
              ref={containerRef}
              style={{ wordWrap: 'break-word', height: 'auto !important', textAlign: 'center' }}
            >
              {displayedFileName}
            </StyledTextContainer>
          ) : (
            <StyledPdfTextContainer>{displayedFileName}</StyledPdfTextContainer>
          )}
        </StyledPdfContainer>
      ) : content?.extension?.toLowerCase()?.includes('pdf') ? (
        <>
          <StyledPdfContainer {...{ mobileView }}>
            <StyledPdfIcon>
              <PdfIcon
                style={{
                  width: '6.5625rem',
                  height: '6.5625rem',
                }}
              />
            </StyledPdfIcon>
            {mobileView ? (
              <StyledTextContainer
                ref={containerRef}
                style={{ wordWrap: 'break-word', height: 'auto !important', textAlign: 'center' }}
              >
                {displayedFileName}
              </StyledTextContainer>
            ) : (
              <StyledPdfTextContainer>{displayedFileName}</StyledPdfTextContainer>
            )}
          </StyledPdfContainer>
        </>
      ) : (
        <></>
      )}
      {isOpenUploadThumbnail && (
        <UploadThumbnail
          setIsOpen={setIsOpenUploadThumbnail}
          isOpen={isOpenUploadThumbnail}
          title="Select a Thumbnail"
          setFieldValue={setFieldValue}
          thumbnailValue={content?.thumbnailUrl}
          fieldName={`content[${i}].thumbnailUrl`}
        />
      )}
    </div>
  );
};

export default DisplayContent;
