import React from 'react';

export const ProfileIcon = () => (
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M18.9143 20.2818H6.96895C6.8126 20.2838 6.65775 20.2515 6.51569 20.1871C6.37363 20.1228 6.24792 20.0281 6.14771 19.9099C5.92712 19.6502 5.83821 19.2956 5.90405 18.937C6.19046 17.3723 7.08433 16.0579 8.48927 15.1352C9.73743 14.3161 11.3185 13.8652 12.9416 13.8652C14.5648 13.8652 16.1458 14.3164 17.394 15.1352C18.7989 16.0576 19.6928 17.372 19.9792 18.9367C20.0451 19.2953 19.9561 19.6498 19.7356 19.9095C19.6354 20.0278 19.5097 20.1226 19.3676 20.187C19.2256 20.2514 19.0707 20.2838 18.9143 20.2818Z"
      fill="#213649"
    />
    <path
      d="M14.7844 5.87259L14.7844 5.87251C14.856 4.94822 14.5545 4.1016 13.9456 3.47403C13.3426 2.85251 12.4917 2.5 11.5303 2.5C10.5609 2.5 9.70738 2.85146 9.10886 3.46737L14.7844 5.87259ZM14.7844 5.87259C14.6378 7.76943 13.1345 9.24069 11.5303 9.24069C9.92563 9.24069 8.42019 7.76878 8.27582 5.87267L14.7844 5.87259ZM8.2758 5.8725C8.2049 4.93571 8.50616 4.08772 9.10883 3.4674L8.2758 5.8725Z"
      stroke="#213649"
    />
    <path
      d="M16.2786 18.3986H17.9003C18.0671 18.4006 18.2323 18.3671 18.3838 18.3005C18.5353 18.2339 18.6694 18.1359 18.7763 18.0136C19.0116 17.7451 19.1064 17.3784 19.0362 17.0076C18.7307 15.3897 17.7772 14.0306 16.2786 13.0768C14.9472 12.2302 13.2608 11.7637 11.5294 11.7637C9.79806 11.7637 8.11159 12.2298 6.78022 13.0768C5.28162 14.0309 4.32816 15.39 4.02265 17.008C3.95243 17.3788 4.04726 17.7454 4.28255 18.0139C4.38945 18.1362 4.52354 18.2341 4.67507 18.3006C4.8266 18.3672 4.99177 18.4006 5.15855 18.3986H7.76471H9.89318H12.0217"
      stroke="#213649"
      stroke-linecap="round"
    />
  </svg>
);
