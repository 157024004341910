import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Modal from 'components/UI/Modal';
import { useSessionTimeAttachments } from 'hooks/useSessionTimeAttachments';
import { PaymentStatus } from 'helpers/constants';
import { useAccount, useClientPreview, useHttp } from 'hooks';
import { memberPodsSelector } from 'selectors/MemberPods';
import { checkIsFuture } from 'utils/datesAndMoney';
import useContribution from '../../../../hooks/useContribution';
import { BottomPanel } from '../BottomPanel/BottomPanel';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import { downloadFileByLink, getIpGlobal, lightOrDark } from 'utils/utils';
import Button from '@material-ui/core/Button';
import { addToGoogleCalender, determineDarkThemedColorToUse, getVideoUrl } from 'services/contributions.service';
import VideoButton from 'components/FormUI/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faPlus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Typography } from '@material-ui/core';
import './SessionTime.scss';
import ParticipantsModal from 'pages/ContributionView/ContributionSessions/CourseSessions/Session/ParticipantsModal';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import styled, { css } from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Video } from 'components/Video/Video';
import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo';
import TextArea from 'components/FormUI/TextArea';
import { useStyles } from 'components/Session/SessionMoreInfo/hooks/useStyles';
import LockIcon from '@mui/icons-material/Lock';
import { SessionMoreInfo } from 'components/Session/SessionMoreInfo/SessionMoreInfo';

// import { useCompleteSelfPacedSessionTime } from './hooks/useCompleteSelfPacedSession';
import { useCompleteSelfPacedSessionTime } from '../../ClientCourseSessions/hooks/useCompleteSelfPacedSession';
import { fetchClientContribution, fetchCohealerContribution, setSessionId } from 'actions/contributions';
import { DescriptionOutlined } from '@material-ui/icons';
import { determineColorToUse } from 'services/contributions.service';
import { F } from 'ramda';
import ShareSessionRecordingContainer from 'pages/ContributionView/components/ShareModal/Content/ShareSessionRecordingContainer';
import { UserRoles } from 'helpers/constants';
import { RECORDING_VIDEO_STATUS } from 'constants.js';
import Linkify from 'react-linkify';
import { mdiShareVariant, mdiPlusCircle } from '@mdi/js';
import { Icon } from '@mdi/react';
import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';
import { StyleTextLink } from 'pages/ContributionView/ContributionSocial/components/Post/Post.styled';
import { useVideoDurationParse } from 'pages/CreateContribution/hooks/useVideoDurationParse';
import useVideoPlayer from 'hooks/useVideoPlayer';
import { BiCalendar } from 'react-icons/bi';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import PopupOptions from 'components/PopupOptions/PopupOptions';
import { makeStyles } from '@material-ui/core';
import { TemplateType } from 'helpers/constants/templateType';

const StyledProgressWrap = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;
const StyledAttachText = styled.span`
  text-transform: initial;
  font-size: 14px;
`;

export const StyledText = styled.div`
  width: 100%;
  color: #525458;
  font-weight: 500;
  word-break: break-word;
  border: solid 1px #e4e4e4;
  border-radius: 3px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.27;
  letter-spacing: 0.25px;
  resize: none;
  padding: 0.85rem;
  font-family: 'Avenir';
  font-weight: normal;
  outline: none;

  ::-webkit-input-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  }
  ::-moz-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  } /* Firefox 19+ */
  :-moz-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  }
`;
const CalendarContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const CalendarMenu = styled.div`
  width: 220px;
  background-color: white;
  color: black;
  box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  position: absolute;
  ${({ mobileView }) => (mobileView ? `margin-top: 65px;` : `margin-top: 45px; `)}
  z-index: 1000;
`;
const SessionTime = ({
  className,
  number,
  maxParticipantsCount,
  session,
  sessionTime,
  sessionTime: { startTime, endTime, participantsIds, isCompleted, podId, ignoreDateAvailable },
  isCoach,
  changeShowPopUp,
  renderActions,
  isPrerecorded,
  activeTemplate,
  isPurchased,
  dateAvailable,
  sessionTimeIndex,
  sessionNumber,
  contribution,
  convertedToSelfPaced,
  isRescheduleDisabled,
  setSelfPacedSessionToBeExpanded,
  btnColor = { btnColor },
  contributionId,
  themedColor,
  setExpandedSession,
  themedBackgroundColor,
}) => {
  const { contributionMemberPods } = useSelector(memberPodsSelector);
  const { id, sessions } = useContribution();
  const { currentRole, user } = useAccount();
  const activeContribution = useSelector(state => state.contributions.activeContribution);
  const { clientPreviewMode } = useClientPreview();
  const { request } = useHttp();
  const [buttonState, setButtonState] = useState(false);
  const [toggleFlag, setToggleFlag] = useState(false);
  const [shouldVideoBeDisabled, setShouldVideoBeDisabled] = useState(false);
  const [isShowPartnerModal, setIsShowPartnerModal] = useState(false);
  const [isPasscodeEnabled, setIsPasscodeEnabled] = useState(sessionTime.isPassCodeEnabled);
  const { videoSrc } = useVideoDurationParse();
  const { getPreRecordedVideoLink, link, loading: loadingPreview } = useVideoPlayer({ sessionTime });
  const styles = useStyles();
  const dispatch = useDispatch();
  const refreshContribution = useCallback(async () => {
    const userIp = await getIpGlobal();
    dispatch(fetchClientContribution(id, userIp));
  }, [dispatch, id]);
  const [expanded, setExpanded] = useState(true);
  const [expandedPrerecorded, setExpandedPrerecorded] = useState(number === 1 ? true : false);
  const [showAudio, setShowAudio] = useState(false);
  const dropdownRef = useRef(null);
  const [isVisibleCalendarDropdown, setIsVisibleCalendarDropdown] = useState(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const text = isPrerecorded ? 'View Content' : 'Open Session';
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  const checkIfSessionTimeBooked = (sessionTime, userId) => {
    const { participantsIds } = sessionTime;

    if (!participantsIds || participantsIds.length === 0) {
      return false;
    }

    return participantsIds.includes(userId);
  };
  const handleClosePartnerMadal = () => {
    setIsShowPartnerModal(false);
    convertedToSelfPaced();
    // window.location.reload();
  };

  const handleCloseErrorMadal = () => {
    setIsShowErrorModal(false);
  };

  const { Popup, isOpen, onShowPopup } = useCompleteSelfPacedSessionTime(refreshContribution);

  const isHasPreRecordings = session.prerecordedSession || sessionTime?.prerecordedSession;
  const isBooked = checkIfSessionTimeBooked(sessionTime, user.id);
  const [isShowOptionsPopup, setIsShowOptionsPopup] = useState(false);
  const isHasRecordings =
    sessionTime.recordingInfos.length > 0 || isPrerecorded || sessionTime?.zoomMeetingData?.recordingFileName;
  const isViewRecordingButton = (isHasPreRecordings && isBooked) || isHasRecordings;

  const { progress, isFileUpload, handleUploadFile } = useSessionTimeAttachments(sessionTime.id, contributionId);

  const podName = contributionMemberPods?.[id]?.reduce((acc, next) => {
    return next.id === podId ? next.name : acc;
  }, '');

  const isSelfPacedRecording =
    isPrerecorded && (sessionTime?.prerecordedSession || session.prerecordedSession || sessionTime?.embeddedVideoUrl);

  const availabilityDate = isPrerecorded ? sessionTime?.startTime : dateAvailable;
  const startTimeMoment = moment(startTime);
  const dateAvailableMoment = moment(availabilityDate);
  const endTimeMoment = moment(endTime);
  const duration = isPrerecorded
    ? humanizeDuration(sessionTime?.prerecordedSession?.duration, { maxDecimalPoints: 0 })
    : humanizeDuration(endTimeMoment.diff(startTimeMoment));
  const { purchaseStatus } = useContribution();
  const isParticipantVisible = purchaseStatus !== PaymentStatus.unpurchased || isCoach;
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [recordedSession, setRecordedSession] = useState(null);
  const [isShowDisableModal, setShowDisableModal] = useState(false);
  const handleParticipantsModal = () => participantsIds?.length > 0 && setIsParticipantsModalOpen(true);

  const checkIsFuture = date => {
    return moment(date).isAfter(moment(), 'day');
  };

  useEffect(() => {
    if (!link && session?.id && !loadingPreview) {
      getPreRecordedVideoLink({ contributionId: contribution.id, sessionId: session.id });
    }
  }, [contribution.id, getPreRecordedVideoLink, link, loadingPreview, session]);

  const status =
    checkIsFuture(sessionTime?.startTime) || !sessionTime?.completedSelfPacedParticipantIds?.includes(user.id)
      ? 'Upcoming'
      : 'Completed';

  const handleDownloadFile = async fileName => {
    const result = await request(
      `/Zoom/GetPresignedUrlForRecordedSession/${sessionTime.zoomMeetingData.meetingId}/${fileName}?asAttachment=true`,
      'GET',
    );
    downloadFileByLink(result, fileName);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleOutsideClick = e => {
    if (!isVisibleCalendarDropdown) {
      return;
    }
    if (
      dropdownRef &&
      dropdownRef?.current &&
      !e?.target?.className?.includes('clickedOutSide') &&
      !dropdownRef?.current?.contains(e.target)
    ) {
      setIsVisibleCalendarDropdown(false);
    }
  };

  const chatFiles = sessionTime?.zoomMeetingData?.chatFiles?.map(chatFile => (
    <div key={chatFile} className="course-session-time__actions" style={{ width: '100%' }}>
      <Button
        classes={{
          root: 'text-lowercase regular-tex download-attach',
        }}
        onClick={() => handleDownloadFile(chatFile)}
        disableRipple
      >
        <span className="ml-2">{chatFile}</span>
      </Button>
    </div>
  ));

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const DocPreview =
    sessionTime?.prerecordedSession?.extension == '.pdf' ||
    sessionTime?.prerecordedSession?.extension == '.doc' ||
    sessionTime?.prerecordedSession?.extension == '.docx' ||
    sessionTime?.prerecordedSession?.extension == '.png' ||
    sessionTime?.prerecordedSession?.extension == '.jpg' ||
    sessionTime?.prerecordedSession?.extension == '.jpeg' ||
    sessionTime?.prerecordedSession?.extension == '.xls' ||
    sessionTime?.prerecordedSession?.extension == '.xlsx'
      ? true
      : false;

  useEffect(() => {
    if (isPrerecorded && !isCoach) {
      changeShowPopUp(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isCoach) {
      isVideoViewable();
      checkCompleteBtnStatus();
    }
  }, [sessions, activeContribution, toggleFlag, sessionTime]);

  const refetch = () => {
    if (!isCoach) {
      isVideoViewable();
      checkCompleteBtnStatus();
      setToggleFlag(!toggleFlag);
    }
  };
  const isVideoViewable = () => {
    if (
      sessionTime?.prerecordedSession?.extension.includes('jpg') ||
      sessionTime?.prerecordedSession?.extension.includes('png') ||
      sessionTime?.prerecordedSession?.extension.includes('jpeg') ||
      sessionTime?.prerecordedSession?.extension.includes('pdf') ||
      sessionTime?.prerecordedSession?.extension.includes('doc') ||
      sessionTime?.prerecordedSession?.extension.includes('xls')
    ) {
      if (ignoreDateAvailable) {
        if (checkIsFuture(startTime)) {
          setShouldVideoBeDisabled(true);
        } else {
          setShouldVideoBeDisabled(false);
        }
      } else {
        setShouldVideoBeDisabled(false);
      }
    } else {
      if (sessionTime?.mustWatchPriorSelfPacedRecords) {
        if (sessionTime?.ignoreDateAvailable) {
          if (checkIsFuture(startTime)) {
            setShouldVideoBeDisabled(true);
          } else {
            setShouldVideoBeDisabled(false);
          }
        }
        if (session.sessionTimes.length > 1 && sessionTimeIndex > 0) {
          if (!session.sessionTimes[sessionTimeIndex - 1]?.usersWhoViewedRecording?.includes(user.id)) {
            setShouldVideoBeDisabled(true);
          } else {
            if (!(sessionTime?.ignoreDateAvailable && checkIsFuture(startTime))) setShouldVideoBeDisabled(false);
          }
        } else {
          if (sessionNumber > 0) {
            for (let i = sessionNumber - 1; i >= 0; i--) {
              if (sessions[i].isPrerecorded) {
                if (
                  !sessions[i]?.sessionTimes[sessions[i]?.sessionTimes.length - 1]?.usersWhoViewedRecording?.includes(
                    user.id,
                  )
                ) {
                  setShouldVideoBeDisabled(true);
                } else {
                  if (shouldVideoBeDisabled && !checkIsFuture(startTime)) {
                    setShouldVideoBeDisabled(false);
                  } else if (
                    sessions[i]?.sessionTimes[sessions[i]?.sessionTimes.length - 1]?.usersWhoViewedRecording?.includes(
                      user.id,
                    )
                  ) {
                    setShouldVideoBeDisabled(false);
                  }
                }
                break;
              }
            }
          }
        }
      } else if (sessionTime?.ignoreDateAvailable && checkIsFuture(startTime)) {
        setShouldVideoBeDisabled(true);
      }
    }
  };

  const checkCompleteBtnStatus = () => {
    if (sessions[sessionNumber]?.sessionTimes[sessionTimeIndex]?.completedSelfPacedParticipantIds?.includes(user.id)) {
      setButtonState(true);
    } else if (
      sessionTime?.prerecordedSession?.extension.includes('jpg') ||
      sessionTime?.prerecordedSession?.extension.includes('png') ||
      sessionTime?.prerecordedSession?.extension.includes('jpeg') ||
      sessionTime?.prerecordedSession?.extension.includes('pdf') ||
      sessionTime?.prerecordedSession?.extension.includes('doc')
    ) {
      setButtonState(false);
    } else {
      if (sessions[sessionNumber]?.sessionTimes[sessionTimeIndex]?.usersWhoViewedRecording?.includes(user.id)) {
        setButtonState(false);
      } else {
        setButtonState(true);
      }
    }
  };

  const [popupCopmonent, setPopupComponent] = useState(null);

  const sessionTimeId = sessionTime?.id;

  const shareRecording = (contributionId, sessionTimeId) => {
    try {
      return axiosInstance
        .post(
          `api/SharedRecording/addSharedRecordingInfo?contributionId=${contributionId}&sessionTimeId=${sessionTimeId}`,
        )
        .then(get('data'));
    } catch (error) {
      console.error('Error:', error);
      throw new Error(error.message);
    }
  };

  const [passCode, setPassCode] = useState(null);

  useEffect(() => {
    const getPassCode = async () => {
      const res = await shareRecording(id, sessionTimeId);
      setPassCode(res.passCode);
    };
    getPassCode();
  }, [id, sessionTimeId]);

  const handleShareButtonClick = () => {
    shareRecording(id, sessionTimeId).then(() => {
      setPopupComponent(true);
    });
  };

  const handleClosePopup = () => {
    setPopupComponent(false);
  };

  const isShowAddToCalendar = () => {
    const isUserMatched = participantsIds.find(participant => participant === user.id);
    if ((isCoach || isUserMatched) && !contribution.externalCalendarEmail) {
      return true;
    }
    return false;
  };

  const isCohealer = currentRole === UserRoles?.cohealer;
  const preRecordedSession = sessionTime?.prerecordedSession || session?.prerecordedSession;
  const shouldShowViewButton =
    isCohealer ||
    sessionTime?.isCompleted ||
    session?.prerecordedSession ||
    sessionTime?.prerecordedSession ||
    !!sessionTime?.zoomMeetingData?.recordingFileName;

  const isFirstVideoViewable = session?.isPrerecorded
    ? preRecordedSession?.status !== RECORDING_VIDEO_STATUS?.available
    : sessionTime?.recordingInfos[0]?.status === RECORDING_VIDEO_STATUS?.available ||
      !!sessionTime?.zoomMeetingData?.recordingFileName;

  let colorToUse = determineColorToUse(contribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const onClickAddToGoogleCalender = async (activeSessionTime, type = false) => {
    const data = {
      contributionId: contribution.id,
      sessionTimeId: activeSessionTime.id,
      isCoach,
      isDownloadable: type === 'Download',
    };
    const addToCalenderURL = await addToGoogleCalender(data);
    if (mobileView) {
      window.location.href = addToCalenderURL;
    } else {
      window.open(addToCalenderURL, '_blank');
    }
    setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown);
  };
  const convertToSelfPaced = async roomId => {
    axiosInstance
      .put(
        `/api/SharedRecording/ConvertLiveToSelfPaced?contributionId=${contribution?.id}&sessionId=${
          session?.id
        }&DateAvailable=${moment().format('YYYY-MM-DD')}&RoomId=${roomId}`,
      )
      .then(res => {
        dispatch(setSessionId(res?.data?.id));
        setSelfPacedSessionToBeExpanded(res?.data?.id);
        dispatch(fetchCohealerContribution(id));
        setIsShowPartnerModal(true);
      })
      .catch(err => {
        setIsShowErrorModal(true);
      });
  };

  const onSelectOption = option => {
    if (!checkVideoStatus(option.status)) return;
    setIsShowOptionsPopup(false);
    if (option?.isConvertedToSelfPaced === true) {
      setShowDisableModal(true);
    } else {
      setRecordedSession(option.roomId);
      setIsShowConfirmationModal(true);
    }
  };
  const checkVideoStatus = status => {
    if (status !== RECORDING_VIDEO_STATUS.available) {
      return false;
    }
    return true;
  };
  const makeOptions = recordingInfos => {
    return recordingInfos.map((info, i) => ({
      text: `${i + 1} ${session?.title || ''} ${moment.utc(info.duration * 1000).format('HH:mm:ss')}`,
      value: {
        contributionId: id,
        roomId: info.roomId,
        status: info.status,
        compositionFileName: info?.compositionFileName,
        sessionTimeId: sessionTime.id,
        isConvertedToSelfPaced: info?.isConvertedToSelfPaced,
      },
    }));
  };
  const options = makeOptions(sessionTime.recordingInfos);
  const onClosePopup = () => {
    setIsShowOptionsPopup(false);
  };

  const onPlayVideo = () => {
    if (options?.length === 1) {
      if (options[0]?.value?.isConvertedToSelfPaced === false) {
        setIsShowConfirmationModal(true);
      } else {
        setShowDisableModal(true);
      }
    } else {
      setIsShowOptionsPopup(true);
    }
  };
  const handleCloseConfirmationMadal = () => {
    setIsShowConfirmationModal(false);
    if (options?.length === 1) {
      convertToSelfPaced(options[0]?.value?.roomId);
    } else {
      convertToSelfPaced(recordedSession);
    }
  };
  console.log('shouldBeDisabled-------------->', shouldVideoBeDisabled);
  return isPrerecorded ? (
    <ExpansionPanel
      style={{
        boxShadow: 'rgba(6, 24, 44, 0.1) 0px 0px 0px 1px',
        backgroundColor: themedBackgroundColor,
        color: themedColor,
        marginBottom: '15px',
      }}
      className="course-session"
      expanded={expandedPrerecorded}
      onChange={(_, newExpanded) => {
        setExpandedPrerecorded(newExpanded);
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <ExpansionPanelSummary
        style={{ display: mobileView ? '' : 'flex' }}
        // style={{ height: '100px' }}
        id="summary_check"
        // className={expandedPrerecorded ? summaryClasses.sessiontime_summary : ''}
        expandIcon={
          expandedPrerecorded ? (
            <ExpandMoreIcon style={{ color: themedColor }} />
          ) : (
            <div style={{ fontSize: '16px' }} className="course-session-summary__type">
              <u>{text}</u>
            </div>
          )
        }
      >
        {!expandedPrerecorded ? (
          <div style={{ fontWeight: '900', fontSize: '18px', fontFamily: 'Avenir', color: themedColor }}>
            {sessionTime.subCategoryName ? `${sessionTime.subCategoryName}` : `Lesson ${number}`}
          </div>
        ) : (
          <div
            style={{
              fontWeight: '900',
              fontSize: '18px',
              fontFamily: 'Avenir',
              color: themedColor,
              // ...(isCoach ? { marginLeft: '50px' } :{}),
              marginLeft: '20px',
            }}
          >
            {sessionTime.subCategoryName ? `${sessionTime.subCategoryName}` : `Lesson ${number}`}
          </div>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className="course-session-details">
          {isPrerecorded && (
            <Grid container style={{ margin: -8, width: 'calc(100% - 20px)', flexDirection: 'column', gap: '1rem' }}>
              <Grid
                item
                lg={12}
                sm={12}
                xs={12}
                style={
                  !isCoach && !isPurchased
                    ? { display: 'flex', justifyContent: 'center', alignItems: 'center' }
                    : { display: 'flex', justifyContent: 'center', alignItems: 'center' }
                }
              >
                {!isCoach &&
                  !isPurchased &&
                  (sessionTime?.selfPacedThumbnailUrl != null ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px',
                        width: '560px',
                      }}
                    >
                      <img
                        src={sessionTime?.selfPacedThumbnailUrl}
                        style={{
                          height: '300px',
                          width: '70%',
                        }}
                      />

                      <LockIcon
                        style={{ height: '100px', width: '100px', color: 'gray', position: 'absolute', zIndex: 10 }}
                      />
                    </div>
                  ) : contribution?.previewContentUrls[0] != null ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px',
                        width: '560px',
                      }}
                    >
                      <img
                        src={contribution?.previewContentUrls[0]}
                        style={{
                          height: '300px',
                          width: '70%',
                        }}
                      />

                      <LockIcon
                        style={{ height: '100px', width: '100px', color: 'gray', position: 'absolute', zIndex: 10 }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: 'black',
                        height: '300px',
                        width: '560px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        cursor: 'pointer',
                      }}
                    >
                      <LockIcon
                        style={{ height: '100px', width: '100px', color: 'gray', position: 'absolute', zIndex: 10 }}
                      />
                    </div>
                  ))}
              </Grid>
              <Grid
                item
                lg={12}
                sm={12}
                xs={12}
                style={
                  !isCoach && !isPurchased
                    ? { display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '50px' }
                    : { display: 'flex', justifyContent: 'center', alignItems: 'center' }
                }
              >
                <div className="course-session-times-block-wrapper">
                  {/* <div className="course-session-time__number">{number}.</div> */}
                  <div className="course-session-times-wrapper">
                    {(!user || isEmpty(user)) && !isCoach && !isPurchased && (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {ignoreDateAvailable && (
                          <div className="course-session-time__wrapper">
                            <p className="course-session-time__label">Date Available</p>
                            <p className="course-session-time__value">{dateAvailableMoment.format('MMM Do YYYY')}</p>
                          </div>
                        )}

                        {!isCoach && isPurchased && duration != '0 seconds' && (
                          <div className="course-session-time__wrapper">
                            <p className="course-session-time__label">Duration</p>
                            <p className="course-session-time__value">{duration}</p>
                          </div>
                        )}
                      </div>
                    )}
                    {/* {isPrerecorded && isCoach && (
            <div 
            className="course-session-time__wrapper"  
            >
              {isPrerecorded && isHasRecordings && (
                <Video isPreviewMode session={session} sessionTime={sessionTime} sessionTimeIndex={sessionTimeIndex} />
              )}
              {isHasRecordings && (
                <LaunchRecordedSessionVideo
                  contribution={contribution}
                  session={session}
                  sessionTime={sessionTime}
                  mobileView={mobileView}
                  isPreviewMode
                />
              )}
            </div>
          )} */}

                    {isPrerecorded && (isPurchased || isCoach) && (
                      <div>
                        {isSelfPacedRecording && (
                          <Grid container spacing={2}>
                            <Grid
                              container
                              style={{
                                position: 'relative',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {DocPreview ? (
                                shouldVideoBeDisabled ? (
                                  sessionTime?.selfPacedThumbnailUrl != null ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '300px',
                                        width: '560px',
                                      }}
                                    >
                                      <img
                                        src={sessionTime?.selfPacedThumbnailUrl}
                                        style={{
                                          height: '100%',
                                          width: '70%',
                                        }}
                                      />

                                      <LockIcon
                                        style={{
                                          height: '100px',
                                          width: '100px',
                                          color: 'gray',
                                          position: 'absolute',
                                          zIndex: 10,
                                        }}
                                      />
                                    </div>
                                  ) : contribution?.previewContentUrls[0] != null ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '300px',
                                        width: '560px',
                                      }}
                                    >
                                      <img
                                        src={contribution?.previewContentUrls[0]}
                                        style={{
                                          height: '100%',
                                          width: '70%',
                                        }}
                                      />

                                      <LockIcon
                                        style={{
                                          height: '100px',
                                          width: '100px',
                                          color: 'gray',
                                          position: 'absolute',
                                          zIndex: 10,
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundColor: 'black',
                                        height: '300px',
                                        width: '560px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                      }}
                                    >
                                      <LockIcon
                                        style={{
                                          height: '100px',
                                          width: '100px',
                                          color: 'gray',
                                          position: 'absolute',
                                        }}
                                      />
                                    </div>
                                  )
                                ) : sessionTime?.prerecordedSession?.extension.includes('png') ? (
                                  <Video
                                    shouldBeDisabled={shouldVideoBeDisabled}
                                    isPreviewMode
                                    session={session}
                                    sessionTime={sessionTime}
                                    sessionTimeIndex={sessionTimeIndex}
                                    setExpandedPrerecorded={setExpandedPrerecorded}
                                    refetch={refetch}
                                  />
                                ) : sessionTime?.prerecordedSession?.extension.includes('jpeg') ? (
                                  <Video
                                    shouldBeDisabled={shouldVideoBeDisabled}
                                    isPreviewMode
                                    session={session}
                                    sessionTime={sessionTime}
                                    sessionTimeIndex={sessionTimeIndex}
                                    setExpandedPrerecorded={setExpandedSession}
                                    refetch={refetch}
                                  />
                                ) : sessionTime?.prerecordedSession?.extension.includes('jpg') ? (
                                  <Video
                                    shouldBeDisabled={shouldVideoBeDisabled}
                                    isPreviewMode
                                    session={session}
                                    sessionTime={sessionTime}
                                    sessionTimeIndex={sessionTimeIndex}
                                    setExpandedPrerecorded={setExpandedSession}
                                    refetch={refetch}
                                  />
                                ) : sessionTime?.prerecordedSession?.extension.includes('pdf') ? (
                                  <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={e => {
                                      e.preventDefault();
                                      window.open(link ?? videoSrc?.[sessionTimeIndex], '_blank');
                                    }}
                                  >
                                    <DescriptionOutlined
                                      style={{ fontSize: 40, color: colorToUse?.PrimaryColorCode }}
                                    />
                                  </a>
                                ) : sessionTime?.prerecordedSession?.extension.includes('doc') ? (
                                  <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={e => {
                                      e.preventDefault();
                                      window.open(link ?? videoSrc?.[sessionTimeIndex], '_blank');
                                    }}
                                  >
                                    <DescriptionOutlined
                                      style={{ fontSize: 40, color: colorToUse?.PrimaryColorCode }}
                                    />
                                  </a>
                                ) : sessionTime?.prerecordedSession?.extension.includes('xls') ? (
                                  <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={e => {
                                      e.preventDefault();
                                      window.open(link ?? videoSrc?.[sessionTimeIndex], '_blank');
                                    }}
                                  >
                                    <DescriptionOutlined
                                      style={{ fontSize: 40, color: colorToUse?.PrimaryColorCode }}
                                    />
                                  </a>
                                ) : (
                                  <Video
                                    shouldBeDisabled={shouldVideoBeDisabled}
                                    isPreviewMode
                                    session={session}
                                    sessionTime={sessionTime}
                                    sessionTimeIndex={sessionTimeIndex}
                                    setExpandedPrerecorded={setExpandedSession}
                                    refetch={refetch}
                                  />
                                )
                              ) : sessionTime?.prerecordedSession?.extension?.includes('mp3') ? (
                                showAudio ? (
                                  <Video
                                    shouldBeDisabled={shouldVideoBeDisabled}
                                    isPreviewMode
                                    session={session}
                                    sessionTime={sessionTime}
                                    sessionTimeIndex={sessionTimeIndex}
                                    setExpandedPrerecorded={setExpandedSession}
                                    refetch={refetch}
                                  />
                                ) : (
                                  <AudioFileOutlinedIcon
                                    onClick={() => {
                                      setShowAudio(!showAudio);
                                    }}
                                    style={{ fontSize: 40, color: colorToUse?.PrimaryColorCode }}
                                  />
                                )
                              ) : (
                                <Video
                                  shouldBeDisabled={shouldVideoBeDisabled}
                                  isPreviewMode
                                  session={session}
                                  sessionTime={sessionTime}
                                  sessionTimeIndex={sessionTimeIndex}
                                  setExpandedPrerecorded={setExpandedSession}
                                  refetch={refetch}
                                />
                              )}
                              {/* {DocPreview ? (
                            <DescriptionOutlined />
                          ) : (
                            <Video
                              isPreviewMode
                              session={session}
                              sessionTime={sessionTime}
                              sessionTimeIndex={sessionTimeIndex}
                            />
                          )} */}

                              {!sessionTime?.prerecordedSession?.extension.includes('png') &&
                                !sessionTime?.prerecordedSession?.extension.includes('jpg') &&
                                !sessionTime?.prerecordedSession?.extension.includes('jpeg') &&
                                !sessionTime?.prerecordedSession?.extension.includes('pdf') &&
                                !sessionTime?.prerecordedSession?.extension.includes('doc') &&
                                !sessionTime?.prerecordedSession?.extension.includes('xls') &&
                                !sessionTime?.embeddedVideoUrl && (
                                  <div>
                                    <LaunchRecordedSessionVideo
                                      shouldBeDisabled={shouldVideoBeDisabled}
                                      contribution={contribution}
                                      session={session}
                                      sessionTime={sessionTime}
                                      mobileView={mobileView}
                                      sessionTimeIndex={sessionTimeIndex}
                                      isPreviewMode
                                      setExpandedPrerecorded={setExpandedSession}
                                      refetch={refetch}
                                    />
                                  </div>
                                )}
                            </Grid>
                          </Grid>
                        )}

                        {/* {isViewRecordingButton && !isSelfPacedRecording && (
                      <LaunchRecordedSessionVideo
                        contribution={contribution}
                        session={session}
                        sessionTime={sessionTime}
                        mobileView={mobileView}
                      />
                    )} */}
                      </div>
                    )}

                    {/* {isPrerecorded && (
            <div className="course-session-time__wrapper">
              <p className="course-session-time__label">Duration</p>
              <p className="course-session-time__value">{duration}</p>
            </div>
          )} */}

                    {/* {!isPrerecorded && (
                  <div className="course-session-time__wrapper">
                    <p className="course-session-time__label">Status</p>
                    <p className="course-session-time__value">{isCompleted ? 'Completed' : 'Upcoming'}</p>
                  </div>
                )} */}

                    {/* {!isPrerecorded && isParticipantVisible && (
                  <>
                    <div
                      className="course-session-time__wrapper course-session-time__wrapper--pointer"
                      onClick={handleParticipantsModal}
                    >
                      <p className="course-session-time__label">Participants</p>
                      <p className="course-session-time__value">
                        {participantsIds.length}/{maxParticipantsCount}
                      </p>
                    </div>
                    <ParticipantsModal
                      memberIds={participantsIds}
                      open={isParticipantsModalOpen}
                      onOpenChange={setIsParticipantsModalOpen}
                    />
                  </>
                )} */}
                    {/* {!isPrerecorded && !isParticipantVisible && !isEmpty(user) && (
                  <div className="course-session-time__wrapper">
                    <p className="course-session-time__label">Seats Available</p>
                    <p className="course-session-time__value">
                      {maxParticipantsCount - participantsIds.length}/{maxParticipantsCount}
                    </p>
                  </div>
                )} */}
                    {podName && (
                      <div className="course-session-time__wrapper">
                        <p className="course-session-time__label">Group</p>
                        <p className="course-session-time__value">{podName}</p>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                {sessionTime?.moreInfo && (isPurchased || isCoach) && (
                  <div
                    style={{ backgroundColor: isDarkThemeEnabled ? 'black' : 'white' }}
                    className="course-session-times-block-wrapper"
                  >
                    {isPrerecorded && (
                      <Grid
                        style={{
                          width: '100%',
                          margin: '20px 0 20px;',
                          backgroundColor: isDarkThemeEnabled ? 'black' : 'white',
                        }}
                      >
                        <Accordion
                          expanded={expanded}
                          onChange={() => {
                            setExpanded(!expanded);
                          }}
                          className={styles.accordion}
                          style={{ backgroundColor: isDarkThemeEnabled ? 'black' : 'white' }}
                        >
                          <AccordionSummary
                            style={{
                              backgroundColor: isDarkThemeEnabled ? 'black' : 'white',
                              color: isDarkThemeEnabled ? 'white' : 'black',
                            }}
                            className={styles.summary}
                            classes={{ content: styles.summaryContent }}
                            expandIcon={<ExpandMoreIcon style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} />}
                            sx={{ margin: '20px' }}
                          >
                            More Info
                          </AccordionSummary>
                          <AccordionDetails>
                            <StyledText style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
                              <Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                  <StyleTextLink target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                  </StyleTextLink>
                                )}
                              >
                                {!isEmpty(sessionTime.moreInfo) &&
                                  sessionTime.moreInfo.split('\n').map(str => (
                                    <>
                                      {str} <br />
                                    </>
                                  ))}
                              </Linkify>
                            </StyledText>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          )}
          <div
            className={`${isPrerecorded ? 'course-session-time__self-paced-actions' : 'course-session-time__actions'}`}
          >
            {renderActions(sessionTime)}
          </div>
          {sessionTime?.zoomMeetingData?.chatFiles?.length > 0 && <>{chatFiles}</>}
          {isPrerecorded && (isPurchased || isCoach) && (
            <div style={{ width: '100%', marginBottom: '40px', marginLeft: mobileView ? '' : '10px' }}>
              <BottomPanel
                title={session.title}
                contributionId={contributionId}
                sessionId={sessionTime.id}
                attachments={sessionTime.attachments}
                idForAttachment={session.id}
                isPrerecorded={isPrerecorded}
              />
            </div>
          )}
          {ignoreDateAvailable || !isCoach ? (
            <Grid container>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 8,
                  width: '100%',
                  // height: '100px',
                  textAlign: 'center',
                  justifyContent: ignoreDateAvailable ? 'space-between' : 'flex-end',
                }}
              >
                {ignoreDateAvailable && !isEmpty(user) && (
                  <div style={{ display: 'flex' }}>
                    <p style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} className="course-session-time__label">
                      Date Available: &nbsp;
                    </p>
                    <p
                      style={{ marginTop: mobileView ? '2px' : '', color: isDarkThemeEnabled ? 'white' : 'black' }}
                      className="course-session-time__value"
                    >
                      {' '}
                      {dateAvailableMoment.format('MMM Do YYYY')}
                    </p>
                  </div>
                )}
                {!isCoach && !isEmpty(user) && isPurchased && (
                  <VideoButton
                    textColor={textColor}
                    backgroundColor={colorToUse?.PrimaryColorCode}
                    autoWidth
                    disabled={buttonState}
                    onClick={() => {
                      onShowPopup({
                        contributionId: contribution.id,
                        sessionTimeId: sessionTime.id,
                        sessionId: session.id,
                      });
                    }}
                    style={{ alignSelf: 'flex-end', marginBottom: 8 }}
                  >
                    Complete
                  </VideoButton>
                )}
              </div>
            </Grid>
          ) : (
            <></>
          )}
          {isPrerecorded && <Popup />}
          {popupCopmonent && <div onClick={handleClosePopup}></div>}
          <Modal
            isOpen={isShowPartnerModal}
            onSubmit={handleClosePartnerMadal}
            title={'Conversion In Progress'}
            submitTitle={'Ok'}
            hiddenCancel={true}
            onCancel={() => {
              setIsShowPartnerModal(false);
            }}
          >
            This recording is currently being converted into a Self-Paced Module, a process that can take a few minutes.
            When the conversion is complete, you can re-order the position of the new Self-Paced module or delete the
            original Live Session by clicking Edit Contribution.
          </Modal>
          <Modal
            isOpen={isShowConfirmationModal}
            onSubmit={handleCloseConfirmationMadal}
            title={'Confirmation'}
            submitTitle={'Yes'}
            onCancel={() => {
              setIsShowConfirmationModal(false);
            }}
          >
            Are you sure you want to convert this live session into the Self-Paced module?
          </Modal>
          <Modal
            isOpen={isShowErrorModal}
            onSubmit={handleCloseErrorMadal}
            title={'Error Occured'}
            submitTitle={'Ok'}
            hiddenCancel={true}
            onCancel={() => {
              setIsShowErrorModal(false);
            }}
          >
            Something went wrong!
          </Modal>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ) : (
    <div
      className={classNames(className, 'course-session-time')}
      style={{ backgroundColor: themedBackgroundColor, borderColor: themedBackgroundColor }}
    >
      <div className="course-session-time__sub-category">
        <p className="course-session-time__label">{sessionTime.subCategoryName}</p>
      </div>
      {isPrerecorded && (
        <Grid container spacing={2}>
          <Grid item lg={6} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="course-session-times-block-wrapper">
              {/* <div className="course-session-time__number">{number}.</div> */}
              <div className="course-session-times-wrapper">
                {!isCoach && !isPurchased && (
                  <>
                    {ignoreDateAvailable && (
                      <div className="course-session-time__wrapper">
                        <p className="course-session-time__label">Date Available</p>
                        <p className="course-session-time__value">{dateAvailableMoment.format('MMM Do YYYY')}</p>
                      </div>
                    )}

                    {!isCoach && !isPurchased && duration != '0 seconds' && (
                      <div className="course-session-time__wrapper">
                        <p className="course-session-time__label">Duration</p>
                        <p className="course-session-time__value">{duration}</p>
                      </div>
                    )}
                  </>
                )}
                {/* {isPrerecorded && isCoach && (
            <div 
            className="course-session-time__wrapper"  
            >
              {isPrerecorded && isHasRecordings && (
                <Video isPreviewMode session={session} sessionTime={sessionTime} sessionTimeIndex={sessionTimeIndex} />
              )}
              {isHasRecordings && (
                <LaunchRecordedSessionVideo
                  contribution={contribution}
                  session={session}
                  sessionTime={sessionTime}
                  mobileView={mobileView}
                  isPreviewMode
                />
              )}
            </div>
          )} */}

                {isPrerecorded && (isPurchased || isCoach) && (
                  <div>
                    {isSelfPacedRecording && (
                      <Grid container spacing={2}>
                        <Grid
                          container
                          style={{
                            position: 'relative',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {DocPreview ? (
                            sessionTime?.prerecordedSession?.extension.includes('png') ? (
                              <Video
                                shouldBeDisabled={shouldVideoBeDisabled}
                                isPreviewMode
                                session={session}
                                sessionTime={sessionTime}
                                sessionTimeIndex={sessionTimeIndex}
                                refetch={refetch}
                              />
                            ) : sessionTime?.prerecordedSession?.extension.includes('jpeg') ? (
                              <Video
                                shouldBeDisabled={shouldVideoBeDisabled}
                                isPreviewMode
                                session={session}
                                sessionTime={sessionTime}
                                sessionTimeIndex={sessionTimeIndex}
                                refetch={refetch}
                              />
                            ) : sessionTime?.prerecordedSession?.extension.includes('jpg') ? (
                              <Video
                                shouldBeDisabled={shouldVideoBeDisabled}
                                isPreviewMode
                                session={session}
                                sessionTime={sessionTime}
                                sessionTimeIndex={sessionTimeIndex}
                                refetch={refetch}
                              />
                            ) : sessionTime?.prerecordedSession?.extension.includes('pdf') ? (
                              <a
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                  e.preventDefault();
                                  window.open(link ?? videoSrc?.[sessionTimeIndex], '_blank');
                                }}
                              >
                                <DescriptionOutlined style={{ fontSize: 40 }} />
                              </a>
                            ) : sessionTime?.prerecordedSession?.extension.includes('doc') ? (
                              <a
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                  e.preventDefault();
                                  window.open(link ?? videoSrc?.[sessionTimeIndex], '_blank');
                                }}
                              >
                                <DescriptionOutlined style={{ fontSize: 40 }} />
                              </a>
                            ) : sessionTime?.prerecordedSession?.extension.includes('xls') ? (
                              <a
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                  e.preventDefault();
                                  window.open(link ?? videoSrc?.[sessionTimeIndex], '_blank');
                                }}
                              >
                                <DescriptionOutlined style={{ fontSize: 40 }} />
                              </a>
                            ) : (
                              <Video
                                shouldBeDisabled={shouldVideoBeDisabled}
                                isPreviewMode
                                session={session}
                                sessionTime={sessionTime}
                                sessionTimeIndex={sessionTimeIndex}
                                refetch={refetch}
                              />
                            )
                          ) : sessionTime?.prerecordedSession?.extension.includes('mp3') ? (
                            showAudio ? (
                              <Video
                                shouldBeDisabled={shouldVideoBeDisabled}
                                isPreviewMode
                                session={session}
                                sessionTime={sessionTime}
                                sessionTimeIndex={sessionTimeIndex}
                                refetch={refetch}
                              />
                            ) : (
                              <AudioFileOutlinedIcon
                                onClick={() => {
                                  setShowAudio(!showAudio);
                                }}
                                style={{ fontSize: 40 }}
                              />
                            )
                          ) : (
                            <Video
                              shouldBeDisabled={shouldVideoBeDisabled}
                              isPreviewMode
                              session={session}
                              sessionTime={sessionTime}
                              sessionTimeIndex={sessionTimeIndex}
                              refetch={refetch}
                            />
                          )}
                          {/* {DocPreview ? (
                            <DescriptionOutlined />
                          ) : (
                            <Video
                              isPreviewMode
                              session={session}
                              sessionTime={sessionTime}
                              sessionTimeIndex={sessionTimeIndex}
                            />
                          )} */}

                          {!sessionTime?.embeddedVideoUrl &&
                            !sessionTime?.prerecordedSession?.extension.includes('png') &&
                            !sessionTime?.prerecordedSession?.extension.includes('jpg') &&
                            !sessionTime?.prerecordedSession?.extension.includes('jpeg') &&
                            !sessionTime?.prerecordedSession?.extension.includes('pdf') &&
                            !sessionTime?.prerecordedSession?.extension.includes('doc') &&
                            !sessionTime?.prerecordedSession?.extension.includes('xls') && (
                              <div>
                                <LaunchRecordedSessionVideo
                                  shouldBeDisabled={shouldVideoBeDisabled}
                                  contribution={contribution}
                                  session={session}
                                  sessionTime={sessionTime}
                                  mobileView={mobileView}
                                  sessionTimeIndex={sessionTimeIndex}
                                  isPreviewMode
                                  setExpandedPrerecorded={setExpandedSession}
                                  refetch={refetch}
                                />
                              </div>
                            )}
                        </Grid>
                        <Grid container>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginBottom: 8,
                              width: '100%',
                              height: '100px',
                              textAlign: 'center',
                            }}
                          >
                            {ignoreDateAvailable && (
                              <div style={{ display: 'flex' }}>
                                <p style={{ color: 'black' }} className="course-session-time__label">
                                  Date Available: &nbsp;
                                </p>
                                <p style={{ color: 'black' }} className="course-session-time__value">
                                  {' '}
                                  {dateAvailableMoment.format('MMM Do YYYY')}
                                </p>
                              </div>
                            )}
                            {!isCoach && (
                              <VideoButton
                                textColor={textColor}
                                backgroundColor={colorToUse?.PrimaryColorCode}
                                autoWidth
                                disabled={buttonState}
                                onClick={() => {
                                  onShowPopup({
                                    contributionId: contribution.id,
                                    sessionTimeId: sessionTime.id,
                                    sessionId: session.id,
                                  });
                                }}
                                style={{ alignSelf: 'flex-end', marginBottom: 8 }}
                              >
                                Complete
                              </VideoButton>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    )}

                    {/* {isViewRecordingButton && !isSelfPacedRecording && (
                      <LaunchRecordedSessionVideo
                        contribution={contribution}
                        session={session}
                        sessionTime={sessionTime}
                        mobileView={mobileView}
                      />
                    )} */}
                  </div>
                )}

                {/* {isPrerecorded && (
            <div className="course-session-time__wrapper">
              <p className="course-session-time__label">Duration</p>
              <p className="course-session-time__value">{duration}</p>
            </div>
          )} */}

                {/* {!isPrerecorded && (
                  <div className="course-session-time__wrapper">
                    <p className="course-session-time__label">Status</p>
                    <p className="course-session-time__value">{isCompleted ? 'Completed' : 'Upcoming'}</p>
                  </div>
                )} */}

                {/* {!isPrerecorded && isParticipantVisible && (
                  <>
                    <div
                      className="course-session-time__wrapper course-session-time__wrapper--pointer"
                      onClick={handleParticipantsModal}
                    >
                      <p className="course-session-time__label">Participants</p>
                      <p className="course-session-time__value">
                        {participantsIds.length}/{maxParticipantsCount}
                      </p>
                    </div>
                    <ParticipantsModal
                      memberIds={participantsIds}
                      open={isParticipantsModalOpen}
                      onOpenChange={setIsParticipantsModalOpen}
                    />
                  </>
                )} */}
                {/* {!isPrerecorded && !isParticipantVisible && !isEmpty(user) && (
                  <div className="course-session-time__wrapper">
                    <p className="course-session-time__label">Seats Available</p>
                    <p className="course-session-time__value">
                      {maxParticipantsCount - participantsIds.length}/{maxParticipantsCount}
                    </p>
                  </div>
                )} */}
                {podName && (
                  <div className="course-session-time__wrapper">
                    <p className="course-session-time__label">Group</p>
                    <p className="course-session-time__value">{podName}</p>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            {sessionTime?.moreInfo && (isPurchased || isCoach) && (
              <div className="course-session-times-block-wrapper">
                {isPrerecorded && (
                  <Grid style={{ width: '100%', margin: '20px 0 20px;' }}>
                    <Accordion
                      expanded={expanded}
                      onChange={() => {
                        setExpanded(!expanded);
                      }}
                      className={styles.accordion}
                    >
                      <AccordionSummary
                        className={styles.summary}
                        classes={{ content: styles.summaryContent }}
                        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                        sx={{ margin: '20px' }}
                      >
                        More Info
                      </AccordionSummary>
                      <AccordionDetails>
                        <StyledText>
                          <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                              <StyleTextLink target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                              </StyleTextLink>
                            )}
                          >
                            {!isEmpty(sessionTime.moreInfo) &&
                              sessionTime.moreInfo.split('\n').map(str => (
                                <>
                                  {str} <br />
                                </>
                              ))}
                          </Linkify>
                        </StyledText>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      )}

      {!isPrerecorded && (
        <>
          <div className="course-session-times-block-wrapper">
            <div className="course-session-time__number">{number}.</div>
            <div className="course-session-times-wrapper">
              <div className={ignoreDateAvailable ? '' : 'course-session-time__wrapper'}>
                {isPrerecorded ? (
                  <>
                    {ignoreDateAvailable && (
                      <>
                        <p className="course-session-time__label">Date Available</p>
                        <p className="course-session-time__value">{dateAvailableMoment.format('MMM Do YYYY')}</p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p className="course-session-time__label">Date</p>
                    <p className="course-session-time__value">{startTimeMoment.format('MMM Do YYYY')}</p>
                  </>
                )}
              </div>
              {!isPrerecorded && (
                <div className="course-session-time__wrapper">
                  <p className="course-session-time__label">Start Time</p>
                  <p className="course-session-time__value">{startTimeMoment.format('h:mm a')}</p>
                </div>
              )}
              {!isPrerecorded && (
                <div className="course-session-time__wrapper">
                  <p className="course-session-time__label">End Time</p>
                  <p className="course-session-time__value">{endTimeMoment.format('h:mm a')}</p>
                </div>
              )}

              {/* {isPrerecorded && (
                <div className="course-session-time__wrapper">
                  {isSelfPacedRecording && (
                    <Grid container direction="column">
                      <Grid style={{ position: 'relative' }}>
                        <Video
                          isPreviewMode
                          session={session}
                          sessionTime={sessionTime}
                          sessionTimeIndex={sessionTimeIndex}
                        />
                        <LaunchRecordedSessionVideo
                          contribution={contribution}
                          session={session}
                          sessionTime={sessionTime}
                          mobileView={mobileView}
                          sessionTimeIndex={sessionTimeIndex}
                          isPreviewMode
                        />
                      </Grid>
                      {!isCoach && (
                        <Button
                          autoWidth
                          disabled={isDisabledCompleteButton}
                          onClick={() =>
                            onShowPopup({
                              contributionId: contribution.id,
                              sessionTimeId: sessionTime.id,
                            })
                          }
                          style={{ alignSelf: 'flex-end', marginBottom: 8 }}
                        >
                          Complete
                        </Button>
                      )}
                    </Grid>
                  )}

                  {isViewRecordingButton && !isSelfPacedRecording && (
                    <LaunchRecordedSessionVideo
                      contribution={contribution}
                      session={session}
                      sessionTime={sessionTime}
                      mobileView={mobileView}
                    />
                  )}
                </div>
              )} */}

              {/* {isPrerecorded && (
          <div className="course-session-time__wrapper">
            <p className="course-session-time__label">Duration</p>
            <p className="course-session-time__value">{duration}</p>
          </div>
        )} */}

              {!isPrerecorded && (
                <div className="course-session-time__wrapper">
                  <p className="course-session-time__label">Status</p>
                  <p className="course-session-time__value">{isCompleted ? 'Completed' : 'Upcoming'}</p>
                </div>
              )}
              {!isPrerecorded && isCoach && !clientPreviewMode && (
                <>
                  <div
                    className="course-session-time__wrapper course-session-time__wrapper--pointer"
                    onClick={handleParticipantsModal}
                  >
                    <p className="course-session-time__label">Participants</p>
                    <p className="course-session-time__value">
                      {participantsIds.length}/{maxParticipantsCount}
                    </p>
                  </div>
                  <ParticipantsModal
                    memberIds={participantsIds}
                    open={isParticipantsModalOpen}
                    onOpenChange={setIsParticipantsModalOpen}
                  />
                </>
              )}
              {!isPrerecorded &&
                !isCoach &&
                !isEmpty(user) &&
                !clientPreviewMode &&
                contribution.isLiveSessionMembersHidden === false && (
                  <div className="course-session-time__wrapper">
                    <p className="course-session-time__label">Seats Available</p>
                    <p className="course-session-time__value">
                      {maxParticipantsCount - participantsIds.length}/{maxParticipantsCount}
                    </p>
                  </div>
                )}
              {isShowAddToCalendar() && (
                <div
                  style={{ width: 250, marginTop: -15 }}
                  className="course-session-time__wrapper course-session-time__wrapper--pointer"
                >
                  <p
                    ref={dropdownRef}
                    onClick={() => setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown)}
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                    className="ocourse-session-time__label"
                  >
                    <BiCalendar style={{ fontSize: 20 }} /> Add to Calendar{' '}
                    {!isVisibleCalendarDropdown ? (
                      <RiArrowDropDownLine style={{ fontSize: 20 }} />
                    ) : (
                      <RiArrowDropUpLine style={{ fontSize: 20 }} />
                    )}
                  </p>
                  {isVisibleCalendarDropdown && (
                    <CalendarContainer className="clickedOutSide" ref={dropdownRef}>
                      <CalendarMenu className="clickedOutSide" mobileView={mobileView}>
                        <MenuItem
                          onClick={() => onClickAddToGoogleCalender(sessionTime, 'Download')}
                          style={{ display: 'flex', justifyContent: 'flex-start', borderBottom: '0.5px solid gray' }}
                        >
                          Download iCal
                        </MenuItem>
                        <MenuItem
                          style={{ display: 'flex', justifyContent: 'flex-start' }}
                          onClick={() => onClickAddToGoogleCalender(sessionTime)}
                        >
                          Add to Google Calendar
                        </MenuItem>
                      </CalendarMenu>
                    </CalendarContainer>
                  )}
                </div>
              )}
              {podName && (
                <div className="course-session-time__wrapper">
                  <p className="course-session-time__label">Group</p>
                  <p className="course-session-time__value">{podName}</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className={`${isPrerecorded ? 'course-session-time__self-paced-actions' : 'course-session-time__actions'}`}>
        {renderActions(sessionTime)}
        {!isPrerecorded && isCoach && (
          <>
            {isViewRecordingButton && (
              <LaunchRecordedSessionVideo
                contribution={contribution}
                session={session}
                sessionTime={sessionTime}
                mobileView={mobileView}
                isPreviewMode
                refetch={refetch}
              />
            )}
          </>
        )}
      </div>
      {sessionTime?.zoomMeetingData?.chatFiles?.length > 0 && <>{chatFiles}</>}
      {isCoach && !isPrerecorded && (
        <>
          <div style={{ marginTop: '10px' }} className="course-session-time__attach-recording-container">
            {(shouldShowViewButton || isFirstVideoViewable) && (
              <>
                {isFirstVideoViewable && isCohealer && (
                  <>
                    <div className="course-session-time__attach-recording">
                      <Button
                        classes={{
                          root: 'position-right text-capitalize attach-button',
                        }}
                        component="span"
                        onClick={handleShareButtonClick}
                      >
                        <Icon size={1} color={colorToUse?.PrimaryColorCode} path={mdiShareVariant} />
                        <StyledAttachText className="regular-text" style={{ fontSize: '16px', color: themedColor }}>
                          &nbsp; Share Recording
                        </StyledAttachText>
                      </Button>
                    </div>
                    {popupCopmonent && (
                      <ShareSessionRecordingContainer
                        applyTheming
                        onClose={handleClosePopup}
                        isOpen={popupCopmonent}
                        contributionId={id}
                        sessionTimeId={sessionTime?.id || ''}
                        isPasscodeEnabled={isPasscodeEnabled}
                        setIsPasscodeEnabled={setIsPasscodeEnabled}
                        passCode={passCode}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {isShowOptionsPopup && (
              <PopupOptions
                title="Please select the video you would like to convert into a Self-Paced module:"
                options={options}
                onSelectOption={onSelectOption}
                open={isShowOptionsPopup}
                onClose={onClosePopup}
              />
            )}
            {!clientPreviewMode && (
              <div className="course-session-time__attach-recording">
                <input
                  accept="*"
                  className="d-none"
                  id={`attach-file-${sessionTime.id}`}
                  type="file"
                  onChange={handleUploadFile}
                  multiple
                />
                <label htmlFor={`attach-file-${sessionTime.id}`}>
                  <Button
                    disabled={isFileUpload}
                    classes={{
                      root: 'position-left text-capitalize attach-button',
                    }}
                    disableRipple
                    component="span"
                  >
                    {isFileUpload && (
                      <StyledProgressWrap position="relative">
                        <CircularProgressWithLabel color={themedColor} value={progress} />
                      </StyledProgressWrap>
                    )}
                    {/* <Icon size={1} color={colorToUse?.PrimaryColorCode} path={mdiPlusCircle} /> */}
                    <span
                      style={{
                        height: '1.5rem',
                        width: '1.5rem',
                        borderRadius: 100,
                        backgroundColor: colorToUse?.PrimaryColorCode,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <FontAwesomeSvgIcon icon={faPlus} size="lg" style={{ color: textColor, height: '0.7rem' }} />
                    </span>
                    <StyledAttachText className="regular-text" style={{ fontSize: '16px', color: themedColor }}>
                      &nbsp; Add Recording
                    </StyledAttachText>
                  </Button>
                </label>
              </div>
            )}
            {isCohealer &&
              sessionTime?.isCompleted &&
              !isPrerecorded &&
              isHasRecordings &&
              isFirstVideoViewable &&
              contribution.type != 'ContributionOneToOne' && (
                <div className="course-session-time__attach-selfpaced">
                  <label>
                    <Button
                      onClick={() => {
                        onPlayVideo();
                      }}
                      classes={{
                        root: 'position-left text-capitalize attach-button',
                      }}
                      disableRipple
                      component="span"
                    >
                      <span
                        style={{
                          height: '1.5rem',
                          width: '1.5rem',
                          borderRadius: 100,
                          backgroundColor: colorToUse?.PrimaryColorCode,
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <FontAwesomeSvgIcon
                          icon={faArrowsRotate}
                          size="lg"
                          style={{ color: textColor, height: '0.7rem' }}
                        />
                      </span>
                      <StyledAttachText className="regular-text" style={{ fontSize: '16px', color: themedColor }}>
                        &nbsp; Convert to Self-Paced
                      </StyledAttachText>
                    </Button>
                  </label>
                </div>
              )}
          </div>
        </>
      )}
      {isPrerecorded && (isPurchased || isCoach) && (
        <div style={{ width: '100%', marginBottom: '40px' }}>
          <BottomPanel
            title={session.title}
            contributionId={contributionId}
            sessionId={sessionTime.id}
            attachments={sessionTime.attachments}
            idForAttachment={session.id}
            isPrerecorded={isPrerecorded}
          />
        </div>
      )}
      {isPrerecorded && <Popup />}
      {popupCopmonent && <div onClick={handleClosePopup}></div>}
      <Modal
        isOpen={isShowPartnerModal}
        onSubmit={handleClosePartnerMadal}
        title={'Conversion In Progress'}
        submitTitle={'Ok'}
        hiddenCancel={true}
        onCancel={() => {
          setIsShowPartnerModal(false);
        }}
      >
        This recording is currently being converted into a Self-Paced Module, a process that can take a few minutes.
        When the conversion is complete, you can re-order the position of the new Self-Paced module or delete the
        original Live Session by clicking Edit Contribution.
      </Modal>
      <Modal
        isOpen={isShowDisableModal}
        onSubmit={() => {
          setShowDisableModal(false);
        }}
        title={''}
        submitTitle={'Ok'}
        hiddenCancel={true}
        onCancel={() => {
          setShowDisableModal(false);
        }}
      >
        This live session has already been converted to Self-Paced module.
      </Modal>
      <Modal
        isOpen={isShowConfirmationModal}
        onSubmit={handleCloseConfirmationMadal}
        title={'Confirmation'}
        submitTitle={'Yes'}
        onCancel={() => {
          setIsShowConfirmationModal(false);
        }}
      >
        Are you sure you want to convert this live session into the Self-Paced module?
      </Modal>
      <Modal
        isOpen={isShowErrorModal}
        onSubmit={handleCloseErrorMadal}
        title={'Error Occured'}
        submitTitle={'Ok'}
        hiddenCancel={true}
        onCancel={() => {
          setIsShowErrorModal(false);
        }}
      >
        Something went wrong!
      </Modal>
    </div>
  );
};

SessionTime.propTypes = {
  className: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  maxParticipantsCount: PropTypes.number.isRequired,
  sessionTime: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    participantsIds: PropTypes.arrayOf(PropTypes.string),
    isCompleted: PropTypes.bool,
    podId: PropTypes.string,
    subCategoryName: PropTypes.string,
  }).isRequired,
  isCoach: PropTypes.bool.isRequired,
  renderActions: PropTypes.func.isRequired,
  sessionTimeIndex: PropTypes.number,
  isPassCodeEnabled: PropTypes.bool,
};

SessionTime.defaultProps = {
  isPrerecorded: false,
  sessionTimeIndex: 0,
  isPassCodeEnabled: false,
};

export default SessionTime;
