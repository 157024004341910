import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, useTheme, useMediaQuery, TextField } from '@material-ui/core';
import { EmailHeading } from '../ContributionCommunication/components/ContributionCommunicationStyled';
import { Switch, StyledInput, StyledSlider } from '../../../components/switch/style';
import EditButton from '../../../components/UI/ListButtons/EditButton';
import ViewButton from '../../../components/UI/ListButtons/ViewButton';
import ChatIcon from './components/assets/Icons/Chat-icon.png';
import CommunitySvg from '../../../components/Icons/CommunityIcon';
import EnrolementSvg from '../../../components/Icons/EnrollmentIcon';
import SessionSvg from '../../../components/Icons/ReminderIcon';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { useState } from 'react';
import { getCommunicationTabData, updateEmailTemplate } from 'services/communicationTab.service ';
import useContribution from '../hooks/useContribution';
import { useEffect } from 'react';
import Loader from 'components/UI/Loader';
import { useStyles } from '../ContributionCommunication/components/ContributionCommunicationStyled';
import styled from 'styled-components';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import './ContributionCommunicationPanel.scss';
import Modal from 'components/UI/Modal';
import { updateSmsNotification } from 'services/user.service';
import { useDispatch } from 'react-redux';
import { fetchCohealerContribution } from 'actions/contributions';
import { Tooltip } from '@material-ui/core';
import { TOOLTIP } from '../../../../src/constants';
// import TextArea from 'components/FormUI/TextArea';

const StyledBetaTag = styled.span`
  font-family: Avenir;
  font-size: 13px;
  font-style: italic;
  font-weight: 800;
  line-height: 16px;
  border-radius: 4px;
  padding: 2px 6px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#215c73')};
  color: white;
`;
const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 20px;
`;

const StyledImage = styled.img`
  height: 60%;
  width: 2%;
  ${({ mobileView }) =>
    mobileView &&
    `
    height: 60%;
  width:10%;
  `}
`;
const COLUMS_WITH = [5, 4, 2, 1];

export default function SmsNotificationPanel({ click, getApiCall }) {
  const [customTemData, setcustomTemData] = useState([]);
  const [smsToBeUploaded, setsmsToBeUploaded] = useState('');
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const dispatch = useDispatch();
  const [initialMode, setinitialMode] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [accordionOpen1, setAccordionOpen1] = useState(false);
  const [accordionOpen2, setAccordionOpen2] = useState(false);
  const [accordionOpen3, setAccordionOpen3] = useState(false);
  const [accordionOpen4, setAccordionOpen4] = useState(false);
  const theme = useTheme();
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);
  const { id: contributionId } = contribution;
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const NotificationSettingTextColor = isDarkThemeEnabled ? colorToUse?.AccentColorCode : '#215C73';
  const NotificationSettingBgColor = isDarkThemeEnabled ? '#3D4246' : '#F1F8FF';
  const [isShowPartnerModal, setIsShowPartnerModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [oneHourSessionMessage, setOneHourSessionMessage] = useState(contribution?.customText?.OneHourSession);
  const [twentyFourHourSessionMessage, setTwentyFourHourSessionMessage] = useState(
    contribution?.customText?.TwentyFourHourSession,
  );
  const [oneHourSession, setOneHourSession] = useState(
    contribution?.subCategorySmsPermissionsPerContribution?.OneHourSession,
  );
  const [twentFourHourSession, setTwentyFourHourSession] = useState(
    contribution?.subCategorySmsPermissionsPerContribution?.TwentyFourHourSession,
  );
  const [smsPermissionsPerContribution, setSmsPermissionsPerContribution] = useState(
    contribution.smsPermissionsPerContribution.SessionReminder,
  );
  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const toggleEditPopup = () => {
    setShowEditModal(!showEditModal);
  };
  const submitSMS = async () => {
    let paramData;
    if (smsToBeUploaded === '1hr') {
      paramData = {
        type: 1,
        id: contributionId,
        customText: {
          OneHourSession: oneHourSessionMessage,
        },
      };
    } else {
      paramData = {
        type: 1,
        id: contributionId,
        customText: {
          TwentyFourHourSession: twentyFourHourSessionMessage,
        },
      };
    }
    updateSmsNotification(paramData).then(res => {
      dispatch(fetchCohealerContribution(contributionId));
    });
    setShowEditModal(!showEditModal);
  };
  const handleClosePartnerMadal = () => {
    setIsShowPartnerModal(false);
  };
  const styledProps = {
    backgroundColor: themedCardBackgroundColor,
    color: themedColor,
  };
  const classes = useStyles(styledProps);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [loader, setLoader] = useState(false);
  return (
    <>
      <Grid container className={isDarkThemeEnabled ? classes.PannelTopBarTopDark : classes.PannelTopBarTop}>
        <span>
          SMS Notifications <StyledBetaTag bgColor={colorToUse?.PrimaryColorCode}>Beta</StyledBetaTag>
        </span>
      </Grid>
      <Grid
        sm={12}
        container
        style={mobileView ? { flex: 'display', justifyContent: 'space-between' } : {}}
        className={classes.PannelTopBar}
      >
        <Grid md={COLUMS_WITH[0]} sm={12}>
          <EmailHeading className={classes.EmailText}>Name</EmailHeading>
        </Grid>
        <Grid md={COLUMS_WITH[1]} sm={12}>
          <EmailHeading className={classes.EmailText}>Edit</EmailHeading>
        </Grid>
        <Grid md={COLUMS_WITH[2]} sm={12}>
          <EmailHeading>
            Send this SMS{' '}
            <Tooltip
              title="If enabled as a coach and client you will receive an SMS reminder if you enrolled into a service and provided consent. If disabled as a coach and client you will not receive a SMS reminder for any services"
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
            >
              <StyledInfoIcon htmlColor={colorToUse?.PrimaryColorCode} style={{ marginRight: '10px' }} />
            </Tooltip>
          </EmailHeading>
        </Grid>
        <Grid md={COLUMS_WITH[3]} sm={12} />
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <div>
            <Accordion expanded={accordionOpen}>
              <AccordionSummary
                style={{
                  backgroundColor: NotificationSettingBgColor,
                  width: '100%',
                  padding: '0 10px',
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                onClick={() => setAccordionOpen(!accordionOpen)}
                className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
              >
                <SessionSvg color={NotificationSettingTextColor} height="60%" width="2%" />
                <Typography
                  sx={{
                    marginTop: '5px',
                    paddingLeft: '10px',
                    fontSize: '16px',
                    fontWeight: '850',
                    fontStyle: 'normal',
                    color: NotificationSettingTextColor,
                    fontFamily: 'Avenir',
                  }}
                  className={classes.TabsTyphography}
                >
                  Automated SMS Reminders
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}></Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  padding: '30px 16px 16px',
                  backgroundColor: themedCardBackgroundColor,
                  color: themedColor,
                }}
              >
                <Grid container>
                  <Grid
                    // onClick={() => {
                    //   setsmsToBeUploaded('1hr');
                    //   toggleEditPopup();
                    // }}
                    style={{ flexDirection: 'row', display: 'flex' }}
                    md={COLUMS_WITH[0]}
                  >
                    <p>1 Hour Session Reminder</p>{' '}
                    {/* <span style={{ marginLeft: '20px' }}>
                      {' '}
                      <EditIcon style={{ color: '#116582' }} />
                    </span> */}
                  </Grid>
                  <Grid md={COLUMS_WITH[1]}>
                    <div>
                      <EditIcon
                        style={{ color: '#116582', cursor: 'pointer' }}
                        onClick={() => {
                          setsmsToBeUploaded('1hr');
                          toggleEditPopup();
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid md={COLUMS_WITH[2]}>
                    <div style={{ display: 'flex' }}>
                      <p className={classes.ButtonViewHide}> {oneHourSession ? 'Enable' : 'Disable'}</p>
                      <Switch className="switch" style={{ marginLeft: '6px' }}>
                        <StyledInput
                          defaultChecked={oneHourSession}
                          value={oneHourSession}
                          type="checkbox"
                          onChange={e => {
                            if (oneHourSession === true) {
                            } else {
                              togglePopup();
                            }
                            const paramData = {
                              type: 1,
                              id: contributionId,
                              smsPermissions: {
                                SessionReminder:
                                  !oneHourSession === true || twentFourHourSession === true ? true : false,
                              },
                              subCategorySmsPermissions: {
                                OneHourSession: !oneHourSession,
                              },
                            };
                            updateSmsNotification(paramData).then(res => {
                              dispatch(fetchCohealerContribution(contributionId));
                              console.log('Res---------->', res);
                            });
                            setOneHourSession(!oneHourSession);
                          }}
                          checked={oneHourSession}
                        />
                        <StyledSlider
                          style={
                            isDarkThemeEnabled
                              ? {
                                  backgroundColor: oneHourSession ? colorToUse?.PrimaryColorCode : '#ccc',
                                }
                              : {}
                          }
                          className="slider round"
                        />
                      </Switch>
                    </div>
                  </Grid>
                  <Grid md={COLUMS_WITH[3]}></Grid>
                </Grid>
                <Grid container>
                  <Grid
                    // onClick={() => {
                    //   setsmsToBeUploaded('24hr');
                    //   toggleEditPopup();
                    // }}
                    style={{ flexDirection: 'row', display: 'flex' }}
                    md={COLUMS_WITH[0]}
                  >
                    <p>24 Hour Session Reminder</p>{' '}
                    {/* <span style={{ marginLeft: '20px' }}>
                      {' '}
                      <EditIcon style={{ color: '#116582' }} />
                    </span> */}
                  </Grid>
                  <Grid md={COLUMS_WITH[1]}>
                    <div>
                      <EditIcon
                        style={{ color: '#116582', cursor: 'pointer' }}
                        onClick={() => {
                          setsmsToBeUploaded('24hr');
                          toggleEditPopup();
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid md={COLUMS_WITH[2]}>
                    <div style={{ display: 'flex' }}>
                      <p className={classes.ButtonViewHide}> {twentFourHourSession ? 'Enable' : 'Disable'}</p>
                      <Switch className="switch" style={{ marginLeft: '6px' }}>
                        <StyledInput
                          defaultChecked={twentFourHourSession}
                          value={twentFourHourSession}
                          type="checkbox"
                          onChange={e => {
                            if (twentFourHourSession === true) {
                            } else {
                              togglePopup();
                            }
                            const paramData = {
                              type: 1,
                              id: contributionId,
                              smsPermissions: {
                                SessionReminder:
                                  oneHourSession === true || !twentFourHourSession === true ? true : false,
                              },
                              subCategorySmsPermissions: {
                                TwentyFourHourSession: !twentFourHourSession,
                              },
                            };
                            updateSmsNotification(paramData).then(res => {
                              dispatch(fetchCohealerContribution(contributionId));
                              console.log('Res---------->', res);
                            });
                            setTwentyFourHourSession(!twentFourHourSession);
                          }}
                          checked={twentFourHourSession}
                        />
                        <StyledSlider
                          style={
                            isDarkThemeEnabled
                              ? {
                                  backgroundColor: twentFourHourSession ? colorToUse?.PrimaryColorCode : '#ccc',
                                }
                              : {}
                          }
                          className="slider round"
                        />
                      </Switch>
                    </div>
                  </Grid>
                  <Grid md={COLUMS_WITH[3]}></Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
      <Modal
        title="SMS Service"
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
      >
        <div style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
          You will incur charges for the SMS service in the future. Currently, it's in beta and free.
        </div>
      </Modal>
      <Modal
        title={`Edit SMS for ${smsToBeUploaded === '1hr' ? '1 Hour Session Reminder' : '24 Hour Session Reminder'}`}
        isOpen={showEditModal}
        onCloseText="OK"
        onSubmit={submitSMS}
        onCancel={toggleEditPopup}
      >
        <TextField
          // disabled={isDisabled}
          fullWidth
          name="moreInfo"
          variant="outlined"
          multiline
          inputProps={{
            maxLength: 500,
            color: isDarkThemeEnabled ? 'white' : 'black',
          }}
          InputProps={{
            classes: {
              root: isDarkThemeEnabled ? classes.Input_root_dark : classes.Input_root_light,
            },
          }}
          rows={6}
          onChange={e => {
            smsToBeUploaded === '1hr'
              ? setOneHourSessionMessage(e.target.value)
              : setTwentyFourHourSessionMessage(e.target.value);
          }}
          style={
            mobileView
              ? { height: '350px', color: isDarkThemeEnabled ? 'white' : 'black', marginBottom: '10px' }
              : { color: isDarkThemeEnabled ? 'white' : 'black', marginBottom: '10px' }
          }
          placeholder="Please input text"
          rows={3}
          // textAreaClassNames={isSessionTime == 'true' ? classNames.textAreaSessionTime : classNames.textArea}
          value={smsToBeUploaded === '1hr' ? oneHourSessionMessage : twentyFourHourSessionMessage}
        />
      </Modal>
    </>
  );
}
