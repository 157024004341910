import {
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_ERROR,
  CREATE_CAMPAIGN_SUCCESS,
  SAVE_CAMPAIGN_TO_LS,
  RESET_CAMPAIGN,
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_ERROR,
  GET_CAMPAIGNS_SUCCESS,
  SET_CAMPAIGN,
  SET_USER_CONTACTS,
  SET_CAMPAIGN_NOTIFICATION,
} from 'actions/campaign';

const initialState = {
  isLoading: false,
  error: null,
  userContacts: [],
  campaignsCollection: [],
  activeCampaign: null,
  campaignNotification: '',
};

const campaignReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_CAMPAIGN_REQUEST:
    case GET_CAMPAIGNS_REQUEST:
      return { ...state, isLoading: true };
    case CREATE_CAMPAIGN_SUCCESS:
      return { ...state, isLoading: false, error: null, activeCampaign: payload };
    case GET_CAMPAIGNS_SUCCESS:
      return { ...state, isLoading: false, error: null, campaignsCollection: payload };
    case CREATE_CAMPAIGN_ERROR:
    case GET_CAMPAIGNS_ERROR:
      return { ...state, isLoading: false, error: payload };
    case SET_CAMPAIGN:
    case SAVE_CAMPAIGN_TO_LS:
      return { ...state, activeCampaign: payload };
    case RESET_CAMPAIGN:
      return { ...state, activeCampaign: null };
    case SET_USER_CONTACTS:
      return { ...state, userContacts: payload };
    case SET_CAMPAIGN_NOTIFICATION:
      return { ...state, campaignNotification: payload };
    default:
      return state;
  }
};

export default campaignReducer;
