import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import './indexstyle.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import copyShareRecordingLinkToClipboard from 'utils/copyShareRecordingLinkToClipboard';
import { mdiContentCopy } from '@mdi/js';
import { Icon } from '@mdi/react';
import styled, { css } from 'styled-components';
import axiosInstance from 'utils/axiosInstance';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import * as S from './styled';

const TOOLTIP_TITLE = 'Copied to clipboard';

const StyledShareRecordingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  width: 80px;
  height: 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  flex-direction: column-reverse;
`;

const ShareRecordingPopup = ({
  mobileView,
  sessionTimeId,
  isPasscodeEnabled,
  setIsPasscodeEnabled,
  passCode,
  applyTheming,
}) => {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const { themedColor, themedCardBackgroundColor, themedBackgroundColor } = getThemedColors(activeContribution);
  const color = applyTheming ? themedColor : '';
  const backgroundColor = applyTheming ? themedCardBackgroundColor : '';
  const background = applyTheming ? themedBackgroundColor : '';
  const isDarkModeEnabled = determineDarkThemedColorToUse(activeContribution);
  const contributionId = activeContribution?.id;
  const colorToUse = determineColorToUse(activeContribution);

  const handleChangePasscode = () => {
    axiosInstance
      .put(
        `api/SharedRecording/changePassCodeStatus?contributionId=${contributionId}&sessionTimeId=${sessionTimeId}&isPassCodeEnabled=${!isPasscodeEnabled}`,
      )
      .then(() => setIsPasscodeEnabled(!isPasscodeEnabled))
      .catch(err => console.log(err));
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleCopyToClipboard = useCallback(
    event => {
      if (event) event.preventDefault();
      copyShareRecordingLinkToClipboard({ contributionId, sessionTimeId });
      setTooltipOpen(true);
    },
    [contributionId, sessionTimeId],
  );

  const text = window.location.origin;
  const contributionLabel = contributionId ? `/share-recording/${contributionId}/${sessionTimeId}` : '';
  const shareableLink = `${text}${contributionLabel}`;

  const onCloseTooltip = useCallback(() => setTooltipOpen(false), [setTooltipOpen]);

  return (
    <S.ShareModalRecordingMain mobileView={mobileView} style={{ height: 'unset', backgroundColor }}>
      <S.ColumnFlexContainer>
        <div className="top-note">
          <p style={{ marginTop: '25px', color }}>You can share the record with this link below.</p>
        </div>
        <div>
          <S.Title>
            <div className="Invite-Purchase">
              <p style={{ fontSize: '16px', color }}> Who can view </p>
            </div>
          </S.Title>
          <div className="share-recording-text">
            <p>Everyone with the recording link</p>
          </div>
        </div>
        <div>
          <S.Title>
            <div className="Invite-Purchase">
              <p style={{ fontSize: '16px', color }}> Shareable Link </p>
            </div>
          </S.Title>
          <div className="share-recording-text" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '250px', color: '#215C73' }}>
              <p>{shareableLink}</p>
            </div>
            <div style={{ width: '100px' }}></div>
            <div style={{ marginRight: '0px' }}>
              <Tooltip
                className="cursor-pointer"
                title={TOOLTIP_TITLE}
                open={tooltipOpen}
                onClose={onCloseTooltip}
                arrow
              >
                <S.CopyToClipboardBlockDefault
                  className="share-btn"
                  style={{ color, backgroundColor: background, padding: '0.5rem' }}
                  onClick={() => {
                    handleCopyToClipboard();
                  }}
                >
                  <p style={{ margin: 0 }}>
                    <Icon size={0.7} color={color} path={mdiContentCopy} />
                    &nbsp;Copy
                  </p>
                </S.CopyToClipboardBlockDefault>
              </Tooltip>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            name="passcode"
            control={
              <Checkbox
                color="primary"
                checked={isPasscodeEnabled}
                onChange={handleChangePasscode}
                style={{
                  // color: isDarkModeEnabled ? themedColor : {}
                  color: colorToUse?.AccentColorCode,
                }}
              />
            }
            label="Passcode"
          />

          <StyledShareRecordingTextContainer>
            <div className="passcode-text">
              <p style={{ marginTop: '5px' }}>{passCode} </p>
            </div>
          </StyledShareRecordingTextContainer>
        </div>
      </S.ColumnFlexContainer>
    </S.ShareModalRecordingMain>
  );
};

ShareRecordingPopup.propTypes = {
  mobileView: PropTypes.bool,
  sessionTimeId: PropTypes.string,
  isPasscodeEnabled: PropTypes.bool,
  setIsPasscodeEnabled: PropTypes.func.isRequired,
};

ShareRecordingPopup.defaultProps = {
  mobileView: false,
  sessionTimeId: '',
  isPasscodeEnabled: false,
};

export default ShareRecordingPopup;
