import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import { Icon } from '@mdi/react';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';

export const StyledHeaderMainContainer = styled.div`
  background-color: ${props => (props.themedCardBackgroundColor ? props.themedCardBackgroundColor : 'white')};
  box-shadow: ${({ mobileView }) => mobileView && '0 0px 4px 0 rgba(157, 157, 157, 0.3)'};
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: center; */
  min-width: 320px;
  padding: 1.87rem 6rem;
  padding-bottom: 0px;
  ${({ mobileView }) => {
    return mobileView && `padding: 15px 20px; `;
  }};

  ${({ fixedHeader }) => {
    return (
      fixedHeader &&
      `
      position: sticky;
      top: 0;
      z-index: 100;  
    `
    );
  }}

  ${({ noShadow }) => {
    return noShadow && `box-shadow: none;`;
  }}
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSecondHeaderMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  gap: 0.62rem;
`;
export const StyledSecondHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.62rem;
`;
export const StyledHeaderText = styled.div`
  color: #656d6d;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */

  display: flex;
  justify-content: flex-end;
  padding-right: ${({ mobileView }) => (mobileView ? '0rem' : '25.5rem')};
`;
export const HeaderIcon = styled.img`
  margin-right: 20px;
  vertical-align: middle;
  object-fit: contain;
  width: 4.6875rem;
  height: auto;
  max-height: 3.75rem;
  ${({ mobileView }) => {
    return mobileView ? `width: 50px;` : `width: 75px;`;
  }};
`;

export const StyledUserSectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ mobileView }) => (mobileView ? '0.5rem' : '0.63rem')};
  ${({ tabletView }) => tabletView && `margin-right: -20px;`};
`;

export const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 24px;
        height: 24px;
        font-size: 0.7rem;
      `
    );
  }}
`;

export const StyledIcon = styled(Icon)`
  width: 22.5px;
  height: 22.5px;
  color: ${({ color }) => color};
  ${({ mobileView }) => mobileView && `width: 6px; height: 2px;`};
`;

export const StyledShareIcon = styled(ShareIcon)`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;

export const StyledEditIcon = styled(EditIcon)`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;
