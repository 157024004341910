import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import axiosInstance from 'utils/axiosInstance';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { RECORDING_VIDEO_STATUS } from 'constants.js';
import Modal from 'components/UI/Modal';
import { useAccount, useClientPreview } from 'hooks';
import { UserRoles } from 'helpers/constants';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import { fetchClientContribution, fetchCohealerContribution, setSessionId } from 'actions/contributions';
import PopupOptions from 'components/PopupOptions/PopupOptions';
import { useMediaQuery, useTheme } from '@material-ui/core';

const StyledAttachText = styled.span`
  text-transform: initial;
  text-align: center;
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1rem;
`;

export const LiveSessionConvertToSelfPaced = ({ options, selectedSession }) => {
  const [isShowOptionsPopup, setIsShowOptionsPopup] = useState(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isShowDisableModal, setShowDisableModal] = useState(false);
  const [isShowPartnerModal, setIsShowPartnerModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [recordedSession, setRecordedSession] = useState(null);

  const dispatch = useDispatch();
  const contribution = useContribution();
  const { currentRole, user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  let colorToUse = determineColorToUse(contribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const isCoach = currentRole === UserRoles.cohealer;
  const { clientPreviewMode } = useClientPreview();
  const { themedColor: themedTextColor, newThemedTextColor } = getThemedColors(contribution);
  const themedColor = isCoach && !clientPreviewMode ? themedTextColor : newThemedTextColor;

  const checkVideoStatus = status => {
    if (status !== RECORDING_VIDEO_STATUS.available) {
      return false;
    }
    return true;
  };
  const onConvertSelftoLive = () => {
    if (options?.length === 1) {
      if (options[0]?.value?.isConvertedToSelfPaced === false) {
        setIsShowConfirmationModal(true);
      } else {
        setShowDisableModal(true);
      }
    } else {
      setIsShowOptionsPopup(true);
    }
  };
  const convertToSelfPaced = async roomId => {
    axiosInstance
      .put(
        `/api/SharedRecording/ConvertLiveToSelfPaced?contributionId=${contribution?.id}&sessionId=${
          selectedSession?.id
        }&DateAvailable=${moment().format('YYYY-MM-DD')}&RoomId=${roomId}`,
      )
      .then(res => {
        dispatch(setSessionId(res?.data?.id));
        //setSelfPacedSessionToBeExpanded(res?.data?.id);
        dispatch(fetchCohealerContribution(contribution?.id));
        setIsShowPartnerModal(true);
      })
      .catch(err => {
        setIsShowErrorModal(true);
      });
  };

  const onSelectOption = option => {
    if (!checkVideoStatus(option.status)) return;
    setIsShowOptionsPopup(false);
    if (option?.isConvertedToSelfPaced === true) {
      setShowDisableModal(true);
    } else {
      setRecordedSession(option.roomId);
      setIsShowConfirmationModal(true);
    }
  };
  const handleCloseConfirmationMadal = () => {
    setIsShowConfirmationModal(false);
    if (options?.length === 1) {
      convertToSelfPaced(options[0]?.value?.roomId);
    } else {
      convertToSelfPaced(recordedSession);
    }
  };
  const onClosePopup = () => {
    setIsShowOptionsPopup(false);
  };
  const handleCloseErrorMadal = () => {
    setIsShowErrorModal(false);
  };
  const handleClosePartnerMadal = () => {
    setIsShowPartnerModal(false);
    // convertedToSelfPaced();
    // window.location.reload();
  };

  return (
    <>
      <div className="course-session-time__attach-selfpaced">
        <label>
          <Button
            onClick={() => {
              onConvertSelftoLive();
            }}
            classes={{
              root: 'position-left text-capitalize attach-button',
            }}
            style={mobileView ? { paddingRight: 0, width: 'fit-content' } : {}}
            disableRipple
            component="span"
            startIcon={
              <span
                style={{
                  height: '1.25rem',
                  width: '1.25rem',
                  borderRadius: 100,
                  backgroundColor: colorToUse?.PrimaryColorCode,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <FontAwesomeSvgIcon icon={faArrowsRotate} size="lg" style={{ color: textColor, height: '0.7rem' }} />
              </span>
            }
          >
            <StyledAttachText className="regular-text" style={{ color: colorToUse?.PrimaryColorCode }}>
              Convert to Self-Paced
            </StyledAttachText>
          </Button>
        </label>
      </div>
      {isShowOptionsPopup && (
        <PopupOptions
          title="Please select the video you would like to convert into a Self-Paced module:"
          options={options}
          onSelectOption={onSelectOption}
          open={isShowOptionsPopup}
          onClose={onClosePopup}
        />
      )}
      <Modal
        isOpen={isShowPartnerModal}
        onSubmit={handleClosePartnerMadal}
        title={'Conversion In Progress'}
        submitTitle={'Ok'}
        hiddenCancel={true}
        onCancel={() => {
          setIsShowPartnerModal(false);
        }}
      >
        This recording is currently being converted into a Self-Paced Module, a process that can take a few minutes.
        When the conversion is complete, you can re-order the position of the new Self-Paced module or delete the
        original Live Session by clicking Edit Contribution.
      </Modal>
      <Modal
        isOpen={isShowDisableModal}
        onSubmit={() => {
          setShowDisableModal(false);
        }}
        title={''}
        submitTitle={'Ok'}
        hiddenCancel={true}
        onCancel={() => {
          setShowDisableModal(false);
        }}
      >
        This live session has already been converted to Self-Paced module.
      </Modal>
      <Modal
        isOpen={isShowConfirmationModal}
        onSubmit={handleCloseConfirmationMadal}
        title={'Confirmation'}
        submitTitle={'Yes'}
        onCancel={() => {
          setIsShowConfirmationModal(false);
        }}
      >
        Are you sure you want to convert this live session into the Self-Paced module?
      </Modal>
      <Modal
        isOpen={isShowErrorModal}
        onSubmit={handleCloseErrorMadal}
        title={'Error Occured'}
        submitTitle={'Ok'}
        hiddenCancel={true}
        onCancel={() => {
          setIsShowErrorModal(false);
        }}
      >
        Something went wrong!
      </Modal>
    </>
  );
};
