import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { ClickAwayListener, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import ClickDropDown from 'components/UI/ClickDropDown';
import CohereIcon from 'assets/coherelogo.png';
import DummyImage from 'assets/CrousalItem1.png';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import classes from './Lead.module.scss';
import DropDownList from 'components/UI/DropDownList/DropDownList';
import { Link } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { colors } from 'utils/styles';
import Modal from 'components/UI/Modal';
import ConvertToClientModal from '../LeadModals/ConvertToClientModal';
import DeleteContactModal from '../LeadModals/DeleteContactModal';
import AddContactTagModal from '../LeadModals/AddContactTagModal';
import EditLeadModalForm from '../LeadModals/EditLeadModalForm/EditLeadModalForm';
import ChatButton from 'components/UI/ListButtons/ChatButton';
import moment from 'moment';
import { getInitialSymbol } from 'components/UI/Header';
import Avatar from '@material-ui/core/Avatar';
import useRouter from 'hooks/useRouter';
import { addhttp } from 'utils/utils';
import NoteIcon from 'pages/ClientPage/components/NoteIcon';
import Portal from 'components/UI/Portal';
import { ContactsServices } from 'pages/Contacts/Services/Contacts.service';
import { fetchAllContactsAsync, setContacts, setLeads } from 'actions/contacts';
import Loader from 'components/UI/Loader';
import CrossableTag from 'pages/Contacts/Components/CrossableTag/CrossableTag';
import { useDispatch, useSelector } from 'react-redux';
import { setSendEmailTo } from 'actions/campaign';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setCampaignNotification } from 'actions/campaign';
import { TOOLTIP } from '../../../../constants';

const StyledDropDownWrapper = styled.div`
  position: relative;
`;

const StyledType = styled.div`
  height: 24px;
  width: 50px;
  border-radius: 5px;
  padding: 2px 8px;
  color: #215c73;
  border: 1.5px solid #215c73;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.23999999463558197px;
  text-align: left;
`;

export const TagsContainer = styled.div`
  width: 125px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //   flex-wrap: wrap;
  gap: 5px;
`;

export const StyledTag = styled.div`
  // width: 100px;
  border-radius: 5px;
  padding: 2px 10px;
  color: white;
  background-color: ${colors.newGold};
  border: 1.5px solid ${colors.newGold};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.23999999463558197px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDropDownItem = styled(Link)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  text-decoration: none;
  white-space: nowrap;
  background-color: white;
  padding: 8px 20px;
  border-bottom: 1px solid #f0f0f0;
  min-width: 100px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  text-align: left;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 1;
    color: gray;
    cursor: pointer;
  
  `}

  &:hover {
    text-decoration: underline;
    ${({ isDisabled }) =>
      isDisabled &&
      `
    text-decoration: none;
    opacity: 1;
    color: gray;
  
  `}
  }
`;

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: 0,
    paddingRight: 0,
  },
})(TableCell);

const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 24px;
        height: 24px;
        font-size: 0.7rem;
      `
    );
  }}
`;

const StyledImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #dfe3e4;
`;

const StyledDropDown = styled.div`
  position: absolute;
  width: auto;
  top: ${({ top }) => (top ? top : '0px')};
  left: ${({ left }) => (left ? left : '0px')};
  z-index: 100;
  box-shadow: 0px 6px 30px 0px rgba(33, 92, 115, 0.32);
`;

const UnderlinedText = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const AdditionalTagsCount = styled.div`
  font-weight: 600;
  color: #215c73;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledDate = styled.div`
  width: 130px;
`;

const TagsCounts = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const TooltipText = 'There are no clients for this contribution yet';
export const TRIAL_PERIOD_START_DATE = '2021-11-01';
export const TRIAL_PERIOD_DAYS = 13;

const Lead = ({ index, row, isChecked, onCheckClicked, leadWithOpenDropDown, setId, setOpenNotesOf, isSummary }) => {
  const {
    id,
    avatarUrl,
    contributionTitle,
    currency,
    currencySymbol,
    email,
    firstName,
    lastName,
    joinDate,
    recenetContributionId,
    revenueEarned,
  } = row;
  console.log('leadWithOpenDropDown', leadWithOpenDropDown);
  const dispatch = useDispatch();
  const history = useHistory();
  const { leads } = useSelector(state => state.contacts);
  const [removedTags, setRemovedTags] = useState(false);
  const [convertToClientModalOpen, setConvertToClientModalOpen] = useState(false);
  const [addTagModalOpen, setAddTagModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUnderstandChecked, setIsUnderstandChecked] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [isEditLeadModalOpen, setIsEditLeadModalOpen] = useState(false);
  const [isRemovingtag, setIsRemovingtag] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  console.log('dropdownPosition==>', dropdownPosition);

  const {
    location: { pathname },
    domain,
  } = useRouter();
  const fullName = `${row?.firstName} ${row?.lastName}`;
  const joinedOn = moment(row?.joinDate).format('MMMM DD, YYYY');
  const contributionURL =
    row.isContribution === false
      ? `/lead-magnet-view/${row?.recenetContributionId}/landingpage`
      : `/contribution-view/${row?.recenetContributionId}/about`;
  const firstTagName =
    !Array.isArray(row?.tags) || row?.tags?.length === 0
      ? ''
      : row?.tags[0]?.name?.length > 7
      ? row?.tags[0]?.name?.substring(0, 7) + '...'
      : row?.tags[0]?.name;
  const isCreateOrEditCampaignPage = pathname.includes('create-campaign') || pathname.includes('edit-campaign');
  const clickAwayHandler = () => {
    setId(null);
  };

  useEffect(() => {
    if (leadWithOpenDropDown === null) {
      window.removeEventListener('click', clickAwayHandler);
    }
  }, [leadWithOpenDropDown]);

  const handleShowTags = () => {
    setIsTagModalOpen(true);
  };

  const handleSendMail = () => {
    const data = [row];
    dispatch(setSendEmailTo(data));
    history.push('/create-campaign/title');
  };

  const handleRemoveTag = data => {
    setIsRemovingtag(true);
    ContactsServices.removeTagFromUser(data)
      .then(() => {
        setIsRemovingtag(true);
        dispatch(fetchAllContactsAsync({}));
        setIsTagModalOpen(false);
      })
      .catch(() => {
        setIsRemovingtag(true);
      });
  };

  return (
    <>
      <TableRow className={classes.container}>
        <NarrowTableCell component="th" scope="row" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <FormControlLabel
            style={{ fontFamily: 'Avenir', marginRight: '5px', marginBottom: '0px' }}
            checked={isChecked(row?.id)}
            onChange={() => {
              onCheckClicked(row.id);
            }}
            control={<Checkbox color="primary" />}
            label={<></>}
            name="selected"
            disabled={isSummary}
          />{' '}
          <AvatarComponent alt={`${row.firstName} ${row.lastName}`} src={row?.avatarUrl}>
            {`${row?.firstName && getInitialSymbol(row?.firstName)}${row?.lastName && getInitialSymbol(row?.lastName)}`}
          </AvatarComponent>
          <div style={{ width: '125px', fontWeight: 'bold' }}>{fullName}</div>
        </NarrowTableCell>
        <Tooltip title={row?.email} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY} leaveTouchDelay={TOOLTIP.LEAVE_DELAY}>
          <NarrowTableCell> {row?.email.length > 20 ? row?.email.slice(0, 20) + '...' : row?.email}</NarrowTableCell>
        </Tooltip>
        {row?.recenetContributionId === null && row.isImported ? (
          <Tooltip
            title={row?.source}
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <NarrowTableCell style={{ fontWeight: 'bold' }}>
              {row?.source.length > 10 ? row?.source.slice(0, 10) + '...' : row?.source}
            </NarrowTableCell>
          </Tooltip>
        ) : (
          <Tooltip
            title={row?.contributionTitle}
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <NarrowTableCell
              style={{ textDecoration: row?.recenetContributionId === null ? 'none' : 'underline', fontWeight: 'bold' }}
            >
              <Link to={contributionURL}>
                {row?.contributionTitle.length > 10
                  ? row?.contributionTitle.slice(0, 10) + '...'
                  : row?.contributionTitle}
              </Link>
            </NarrowTableCell>
          </Tooltip>
        )}
        <NarrowTableCell>
          <StyledDate>{joinedOn}</StyledDate>
        </NarrowTableCell>
        {!isCreateOrEditCampaignPage && (
          <NarrowTableCell>
            {Array.isArray(row?.tags) && row?.tags?.length > 0 ? (
              <TagsContainer>
                <Tooltip title={row?.tags[0]?.name} arrow>
                  <StyledTag>{firstTagName}</StyledTag>
                </Tooltip>
                {row?.tags?.length > 1 && (
                  <AdditionalTagsCount onClick={handleShowTags}>+{row?.tags?.length - 1}</AdditionalTagsCount>
                )}
              </TagsContainer>
            ) : (
              '-'
            )}
          </NarrowTableCell>
        )}
        {!isCreateOrEditCampaignPage && (
          <NarrowTableCell align="center">
            <IconButton
              className="icon-button"
              onClick={() => {
                setOpenNotesOf(row?.id);
              }}
            >
              <NoteIcon fillColor="#215C73" />
            </IconButton>
          </NarrowTableCell>
        )}

        {!isCreateOrEditCampaignPage && (
          <NarrowTableCell align="center">
            <StyledDropDownWrapper>
              <MoreHorizIcon
                style={{ cursor: 'pointer' }}
                onClick={e => {
                  e.stopPropagation();
                  const rect = e.currentTarget.getBoundingClientRect();
                  const scrollTop = window.scrollY || document.documentElement.scrollTop;
                  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
                  setDropdownPosition({ top: rect.bottom + scrollTop, left: rect.left + scrollLeft - 160 });
                  if (leadWithOpenDropDown === row?.id) {
                    setId(null);
                  } else {
                    setId(row?.id);
                  }
                  window.removeEventListener('click', clickAwayHandler);
                  window.addEventListener('click', clickAwayHandler);
                }}
              />

              {leadWithOpenDropDown === row?.id && (
                <Portal>
                  <StyledDropDown top={`${dropdownPosition.top}px`} left={`${dropdownPosition.left}px`}>
                    <div>
                      <StyledDropDownItem
                        onClick={() => {
                          setConvertToClientModalOpen(true);
                        }}
                      >
                        Convert to Client
                      </StyledDropDownItem>
                    </div>
                    <div>
                      <StyledDropDownItem onClick={handleSendMail}>Send Email</StyledDropDownItem>
                    </div>
                    {row?.isImported && (
                      <div>
                        <StyledDropDownItem
                          onClick={() => {
                            setIsEditLeadModalOpen(true);
                          }}
                        >
                          Edit
                        </StyledDropDownItem>
                      </div>
                    )}

                    <div>
                      <StyledDropDownItem
                        onClick={() => {
                          setAddTagModalOpen(true);
                        }}
                      >
                        Add Tag
                      </StyledDropDownItem>
                    </div>
                    {row?.isImported && (
                      <div>
                        <StyledDropDownItem
                          onClick={() => {
                            setDeleteModalOpen(true);
                          }}
                        >
                          Delete
                        </StyledDropDownItem>
                      </div>
                    )}
                  </StyledDropDown>
                </Portal>
              )}
            </StyledDropDownWrapper>
          </NarrowTableCell>
        )}
      </TableRow>

      {isEditLeadModalOpen && (
        <EditLeadModalForm
          isOpen={isEditLeadModalOpen}
          onCancel={() => {
            setIsEditLeadModalOpen(false);
          }}
          lead={row}
        />
      )}

      <Modal
        title="Convert Lead to Client"
        isOpen={convertToClientModalOpen}
        onCancel={() => {
          setConvertToClientModalOpen(false);
        }}
        onSubmit={() => {
          const data = {
            contactId: row?.id,
            isLead: false,
          };
          ContactsServices.updateContact(data)
            .then(res => {
              console.log('res', res);
              setConvertToClientModalOpen(false);
              dispatch(
                setCampaignNotification({
                  heading: row?.isLead ? 'Lead Converted Successfully' : 'Client Converted Successfully',
                  message: `You have successfully converted ${row?.isLead ? 'Lead' : 'Client'}`,
                }),
              );
              setTimeout(() => {
                dispatch(setCampaignNotification(''));
              }, 8000);
              dispatch(fetchAllContactsAsync({}));
            })
            .catch(err => {
              console.log('err', err);
            });
        }}
        submitTitle="Convert"
        reduceCancelButton
      >
        <ConvertToClientModal />
      </Modal>

      {addTagModalOpen && (
        <AddContactTagModal
          contacts={[row]}
          contactsList={leads?.map(c => ({ title: `${c.firstName} ${c.lastName}`, value: c.id }))}
          isOpen={addTagModalOpen}
          onClose={() => {
            setAddTagModalOpen(false);
          }}
        />
      )}

      <Modal
        title="Delete Contact(s)"
        isOpen={deleteModalOpen}
        onCancel={() => {
          setDeleteModalOpen(false);
        }}
        onSubmit={() => {
          setIsDeleting(true);
          ContactsServices.deleteContact([row.id])
            .then(res => {
              console.log('res', res);
              setIsUnderstandChecked(false);
              setDeleteModalOpen(false);
              dispatch(fetchAllContactsAsync({}));
            })
            .catch(err => {
              console.log('err', err);
            })
            .finally(() => {
              setIsDeleting(false);
            });
        }}
        submitTitle="Delete"
        disableSubmitClick={!isUnderstandChecked || isDeleting}
        reduceCancelButton
      >
        <DeleteContactModal
          contacts={[row.id]}
          checkboxValue={isUnderstandChecked}
          onCheckboxChange={() => {
            setIsUnderstandChecked(!isUnderstandChecked);
          }}
        />
      </Modal>

      <Modal
        title="All Tags"
        isOpen={isTagModalOpen}
        onCancel={() => {
          setIsTagModalOpen(false);
          setRemovedTags(false);
          if (removedTags) {
            dispatch(fetchAllContactsAsync({}));
          }
        }}
        disableFooter
        widthRequiredIs
        minHeight="0px"
      >
        <div className={classes.all_tags_container}>
          {isRemovingtag && <Loader />}
          {row?.tags?.map(tag => {
            return (
              <CrossableTag
                tagName={tag?.name}
                onCross={() => {
                  const data = {
                    tagId: tag?.id,
                    contactId: row?.id,
                  };
                  setIsRemovingtag(true);
                  ContactsServices.removeTagFromUser(data)
                    .then(() => {
                      const updatedLead = {
                        ...row,
                        tags: row.tags.filter(t => t.id != tag.id),
                      };
                      const updatedLeads = leads?.map(item => (item.id === updatedLead.id ? updatedLead : item));
                      dispatch(setLeads(updatedLeads));
                      setIsRemovingtag(false);
                      setRemovedTags(true);
                      if (row?.tags?.length - 1 === 0) {
                        setIsTagModalOpen(false);
                        dispatch(fetchAllContactsAsync({}));
                      }
                    })
                    .catch(() => {
                      setIsRemovingtag(false);
                    });
                }}
              />
            );
          })}
        </div>
      </Modal>
    </>
  );
};

Lead.propTypes = {};

Lead.defaultProps = {};

export default Lead;
