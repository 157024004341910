import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/UI/Modal';
import { useHttp } from 'hooks';

export const ZoomDefaultProviderModal = ({ showTerms, onCancel, onSubmit }) => {
  return (
    <Modal
      isCreatingContribution={true}
      isOpen={showTerms}
      onCancel={onCancel}
      title="Important Zoom Info"
      cancelTitle={'No'}
      submitTitle={'Yes'}
      onSubmit={onSubmit}
    >
      <div className="d-flex flex-column">
        You have selected zoom as your video provider for Cohere sessions. Please remember to record to the cloud when
        you record your sessions if you wish for the recording sessions to appear inside Cohere.Also Would you like to
        make Zoom your default video provider on Cohere for all new contributions?
      </div>
    </Modal>
  );
};

ZoomDefaultProviderModal.propTypes = {
  showTerms: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
