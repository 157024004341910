import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { LiveCoursesPage, OneToOneSessionsPage, MembershipsPage } from 'pages';
import ArchivedPage from 'pages/ArchivedPage/ArchivedPage';
import WorkshopPage from 'pages/WorkshopPage/WorkshopPage';
import AllApplicationsPage from 'pages/AllApplicationsPage/AllApplicationsPage';
import MainContainer from './MainContainer';
import AllFormsPage from 'pages/AllFormsPage/AllFormsPage';

const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 5px' : '30px 55px')};
  margin-right: ${({ mobileView }) => (mobileView ? '15px' : '')};
`;
function ApplicationsContainer({ match: { path } }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView}>
        <Switch>
          <Route path={`${path}/forms`} exact component={AllFormsPage} />
          <Route path={`${path}/all`} exact component={AllApplicationsPage} />
          <Route path={`${path}/pending`} exact component={AllApplicationsPage} />
          <Route path={`${path}/approved`} exact component={AllApplicationsPage} />
          <Route path={`${path}/rejected`} exact component={AllApplicationsPage} />
          <Redirect to={`${path}/all`} />
        </Switch>
      </StyledMainSection>
    </MainContainer>
  );
}
ApplicationsContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};
export default ApplicationsContainer;
