import React from 'react';
import classes from './ContactsButtons.module.scss';
import ExportIcon from '../Icons/ExportIcon';

export const PAGE_TYPES = {
  AllContacts: 'AllContacts',
  Clients: 'Clients',
  Leads: 'Leads',
  Tags: 'Tags',
};

const ContactsButtons = ({
  page = PAGE_TYPES.AllContacts,
  exportBtnClassName = null,
  exportClickHandler = () => {},
  importBtnClassName = null,
  importClickHandler = () => {},
  addLeadBtnClassName = null,
  addLeadClickHandler = () => {},
  createNewTagBtnClassName = null,
  createNewTagClickHandler = () => {},
}) => {
  const AllConatctsPage = page === PAGE_TYPES.AllContacts;
  const ClientPage = page === PAGE_TYPES.Clients;
  const LeadsPage = page === PAGE_TYPES.Leads;
  const TagsPage = page === PAGE_TYPES.Tags;

  let exportBtnClass = classes.exportBtn;
  let importBtnClass = classes.importBtn;
  let addLeadBtnClass = classes.addLeadBtn;
  let createNewTagBtnClass = classes.createNewTagBtn;

  if (exportBtnClassName) {
    exportBtnClass = `${exportBtnClass} ${exportBtnClassName}`;
  }

  if (importBtnClassName) {
    importBtnClass = `${importBtnClass} ${importBtnClassName}`;
  }

  if (addLeadBtnClassName) {
    addLeadBtnClass = `${addLeadBtnClass} ${addLeadBtnClassName}`;
  }

  if (createNewTagBtnClassName) {
    createNewTagBtnClass = `${createNewTagBtnClass} ${createNewTagBtnClassName}`;
  }

  return (
    <div className={classes.container}>
      {(LeadsPage || AllConatctsPage || ClientPage) && (
        <button className={importBtnClass} type="button" onClick={importClickHandler}>
          <ExportIcon width="16" height="16" />
          Import CSV
        </button>
      )}
      {(AllConatctsPage || ClientPage || LeadsPage) && (
        <button className={exportBtnClass} type="button" onClick={exportClickHandler}>
          <ExportIcon width="16" height="16" />
          Export
        </button>
      )}
      {LeadsPage && (
        <>
          <button className={addLeadBtnClass} type="button" onClick={addLeadClickHandler}>
            Add a Lead
          </button>
        </>
      )}

      {TagsPage && (
        <>
          <button className={createNewTagBtnClass} type="button" onClick={createNewTagClickHandler}>
            Create a New Tag
          </button>
        </>
      )}
    </div>
  );
};

export default ContactsButtons;
