import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { fetchCohealerContribution } from 'actions/contributions';
import Button from 'components/FormUI/Button';
import LaunchSessionTime from 'components/SessionButtons/LaunchSessionTime';
import { useEndVideoChat, useRouter } from 'hooks';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import CourseSessions from '../CourseSessions';
import CourseSessionsRedesign from '../CourseSessions.redesign';
import Loader from 'components/UI/Loader';
import { useRef } from 'react';

const CohealerCourseSessions = ({ user, contribution, getCohealerContribution, calendarActiveView }) => {
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const handleRefreshList = useCallback(async () => {
    getCohealerContribution(contribution.id);
  }, [contribution.id, getCohealerContribution]);
  const { Popup, onShowPopup, handleSessionComplete } = useEndVideoChat(handleRefreshList);
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const columnsContainerRef = useRef();
  const { pathname } = useRouter();
  const devModeSessionsTab = true; // pathname?.includes('/sessions/dev') || false;
  // const headerHeight = stickyHeaderRef?.current ? stickyHeaderRef.current.offsetHeight : 169;
  // const heightOffset = (headerHeight || 169) +
  // const heightOffset = (columnsContainerRef?.current ? columnsContainerRef?.current?.offsetTop : 250) + 20;
  // const heightOffset = (columnsContainerRef?.current?.offsetTop || 250) + 20;
  const [heightOffset, setHeightOffset] = useState(250 + 20);
  // useEffect(() => console.info('stickyHeaderRef:', stickyHeaderRef), [stickyHeaderRef]);
  useEffect(() => {
    if (columnsContainerRef?.current) {
      setHeightOffset(columnsContainerRef?.current.offsetTop + 20);
    }
  }, [columnsContainerRef, columnsContainerRef?.current]);

  const SessionsComponent = devModeSessionsTab ? CourseSessionsRedesign : CourseSessions;
  // return <></>
  return (
    <>
      {isSessionLoading && <Loader />}
      <SessionsComponent
        layout={{ heightOffset, columnsContainerRef }}
        contribution={contribution}
        renderSessionTimeActions={(session, sessionTime, sessionTimeIndex) => {
          const isHasRecordings =
            sessionTime.recordingInfos.length > 0 ||
            session?.isPrerecorded ||
            sessionTime?.zoomMeetingData?.recordingFileName;
          return (
            <>
              {!session?.isPrerecorded ? (
                !sessionTime.isCompleted ? (
                  <Button
                    textColor={textColor}
                    backgroundColor={colorToUse.PrimaryColorCode}
                    autoWidth
                    onClick={() => onShowPopup(contribution.id, sessionTime.id, !sessionTime.isCompleted)}
                  >
                    Complete
                  </Button>
                ) : (
                  <Button
                    autoWidth
                    variant="grayedOut"
                    onClick={() => handleSessionComplete(contribution.id, sessionTime.id)}
                  >
                    Unmark as complete
                  </Button>
                )
              ) : null}

              {!session?.isPrerecorded && (
                <LaunchSessionTime
                  user={user}
                  contribution={contribution}
                  session={session}
                  sessionTime={sessionTime}
                />
              )}
              {/* {session?.isPrerecorded && isHasRecordings && (
                 <Video isPreviewMode session={session} sessionTime={sessionTime} sessionTimeIndex={sessionTimeIndex} />
            )}
            {isHasRecordings && (
              <LaunchRecordedSessionVideo
                contribution={contribution}
                session={session}
                sessionTime={sessionTime}
                mobileView={mobileView}
                isPreviewMode
              />
            )} */}
            </>
          );
        }}
      />
      <Popup applyTheming />
    </>
  );
};

CohealerCourseSessions.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  calendarActiveView: PropTypes.string.isRequired,
};

const actions = {
  getCohealerContribution: fetchCohealerContribution,
};

const mapStateToProps = ({ calendars }) => ({
  calendarActiveView: calendars.currentView,
});

export default connect(mapStateToProps, actions)(CohealerCourseSessions);
