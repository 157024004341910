import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Linkify from 'react-linkify';
import { SecureLink } from 'react-secure-link';
import getInitials from 'utils/getInitials';
import useMessage from './useMessage';
import { useAccount } from 'hooks';
import MessageType from '../../../MessageType';
import Media from './Media';
import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';
import './Message.scss';
import MoreOptions from 'components/UI/chats/ChatsList/ChatChannelsList/Chat/MoreOptions';
import chatService from 'services/chat.service';
import { getChatChannelDetails, getThemedColors } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';

const Message = ({ className, message, memberProvider, isUnread, onRead }) => {
  const {
    index,
    author: { avatarUrl, displayName },
    date,
    type,
    text,
    media,
  } = useMessage(message, memberProvider);
  const { user } = useAccount();
  const [chatDetail, setChatDetail] = useState(null);
  const [roleInChat, setRoleInChat] = useState(null);
  const [deleteButton, setDeleteButton] = useState(false);
  const [partnersArray, setpartnersArray] = useState([]);
  const [isShowPartnerModal, setIsShowPartnerModal] = useState(false);
  const messageRef = useRef();
  const contribution = useContribution();
  const { themedColor } = getThemedColors(contribution);

  useEffect(() => {
    const onIntersected = ([entry]) => {
      if (entry.isIntersecting) {
        onRead(index);
      }
    };
    const options = { threshold: 0.7 };

    const messageObserver = new IntersectionObserver(onIntersected, options);
    messageObserver.observe(messageRef.current);
    getChatChannelDetails(message.channel.sid).then(res => {
      setChatDetail(res);
      let partnersEmail = res.partners.map(value => value.partnerEmail);
      setpartnersArray(partnersEmail);
      let partner = res.partners.filter(test => test.userId === user?.id);
      if (user.id === res.coachId) {
        setRoleInChat('coach');
      } else if (partner.length == 1) {
        setRoleInChat('partner');
      } else {
        setRoleInChat('client');
      }
      showDeleteButton();
    });
    return () => {
      messageObserver.disconnect();
    };
  }, [isUnread, onRead, index]);
  const handleClosePartnerMadal = () => {
    setIsShowPartnerModal(false);
    chatService.deleteSingleMessage(
      chatDetail?.chatType,
      message?.channel.sid,
      message?.state.sid,
      chatDetail?.contributionId,
    );
  };
  const onToggleDelete = () => {
    setIsShowPartnerModal(!isShowPartnerModal);
  };

  const showDeleteButton = () => {
    if (chatDetail?.chatType === 'ContributionChat') {
      if (roleInChat == 'coach' && partnersArray.includes(message?.state?.author) === false) {
        return (
          <MoreOptions
            className="chats-list-chat-content__more-options"
            messageMenu={true}
            onToggleDelete={onToggleDelete}
          />
        );
      } else if (roleInChat == 'partner' && partnersArray.includes(message?.state?.author) === false) {
        return (
          message?.state?.author != chatDetail.email && (
            <MoreOptions
              className="chats-list-chat-content__more-options"
              messageMenu={true}
              onToggleDelete={onToggleDelete}
            />
          )
        );
      } else if (message?.state?.author === user.email) {
        return (
          <MoreOptions
            className="chats-list-chat-content__more-options"
            messageMenu={true}
            onToggleDelete={onToggleDelete}
          />
        );
      }
    } else if (message?.state?.author === user.email) {
      return (
        <MoreOptions
          className="chats-list-chat-content__more-options"
          messageMenu={true}
          onToggleDelete={onToggleDelete}
        />
      );
    }
  };

  return (
    <div
      className={classNames(className)}
      style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}
    >
      <div ref={messageRef} className={'chat-message'}>
        <Avatar className="chat-message__author-avatar" src={avatarUrl}>
          {getInitials(displayName)}
        </Avatar>
        <p className="chat-message__info chat-message-info">
          <span className="chat-message-info__author">{displayName}</span>
          &nbsp;
          <span className="chat-message-info__time">{date.format('hh:mm A')}</span>
        </p>
        <div className="chat-message__content chat-message-content">
          {type === MessageType.text && (
            <p className="chat-message-content__text">
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <SecureLink target="_blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </SecureLink>
                )}
              >
                {text}
              </Linkify>
            </p>
          )}
          {type === MessageType.media && (
            <Media type={media.type} size={media.sizeInBytes} fileName={media.fileName} url={media.contentUrl} />
          )}
        </div>
      </div>
      <div style={{ width: 40, height: 40 }}>{showDeleteButton()}</div>

      <Modal
        isOpen={isShowPartnerModal}
        onSubmit={handleClosePartnerMadal}
        title={'Delete Message'}
        submitTitle={'Yes, delete'}
        cancelTitle={'Cancel'}
        onCancel={() => {
          setIsShowPartnerModal(false);
        }}
        applyTheming
      >
        <LabelText style={{ color: themedColor }}>
          Are you sure you’d like to delete this message? Please note: You will NOT be able to recover it once deleted.
        </LabelText>
      </Modal>
    </div>
  );
};

Message.propTypes = {
  className: PropTypes.string.isRequired,
  message: PropTypes.shape({}).isRequired,
  memberProvider: PropTypes.func.isRequired,
  isUnread: PropTypes.bool.isRequired,
  onRead: PropTypes.func.isRequired,
};

export default Message;
