import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';

import {
  StyledHeadingOne,
  StyledGridContent,
  StyledText,
  StyledGridContianer,
  StyledImage,
  StyledDownLoadContianer,
  StyledDownLoadContent,
  StyledVideoContainer,
  StyledVideoMainContainer,
  StyledVideo,
  StyledPlayIconWrapper,
} from './components/SharedStyledComponents';
import ReactPlayer from 'react-player';
import Button from 'components/FormUI/Button';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import { isEmptyOrNil, lightOrDark } from 'utils/utils';
import useScreenView from 'hooks/useScreenView';
import { useLeadMagnet } from './hooks';
import DownloadContents from './components/DownloadContents';
import { cloneDeep, isEmpty } from 'lodash';
import { smoothScrollingToElement } from 'utils/dom';
import { openInNewWindow } from 'services/links';
import { createAccessCode } from 'services/contributions.service';
// import { PlayIcon } from '../ViewAsCoach/components/icons/Play';
import { PlayIcon } from 'pages/ContributionView/ContributionSessions/component/icons';
import styled, { css } from 'styled-components';
import ThankYouMetaPixelLM from 'components/AdsComponents/ThankYouMetapixelLM';
import GoogleAnalytics from 'components/AdsComponents/GoogleTag';
import ClarityScript from 'components/AdsComponents/ClarityScript';
import PixelComponent from 'components/AdsComponents/PixelComponent';

const StyledGrid = styled(Grid)`
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `};
  /* border: 1px solid salmon; */
`;

const LeadMagnetThankYouPage = () => {
  const leadMagnet = useLeadMagnet();
  const { content, thankyouPage, id } = leadMagnet;
  const { mobileView } = useScreenView();
  const { colorToUse, themedColors, textColor } = useLeadMagnetTheme();
  const [thankyouAccessContentVideoBannerPlaying, setThankyouAccessContentVideoBannerPlaying] = useState(false);
  const [thankyouAdditionalContentVideoBannerPlaying, setThankyouAdditionalContentVideoBannerPlaying] = useState(false);
  const bannerContainerRef1 = useRef(null);
  const bannerContainerRef2 = useRef(null);
  const videoPlayerRef1 = useRef(null);
  const videoPlayerRef2 = useRef(null);
  const [videoPlayerHeight, setVideoPlayerHeight] = useState(0);
  const [bannerContainerDimensionsIn16ratio9, setBannerContainerDimensionsIn16ratio9] = useState(null);
  const [contributionUrl, setContributionUrl] = useState(null);

  // useEffect(() => {
  //   if (thankyouPage?.additionalLeadMagnetThankyouPage?.selectedContributionId) {
  //     createAccessCode(thankyouPage?.additionalLeadMagnetThankyouPage?.selectedContributionId).then(data => {
  //       setContributionUrl(
  //         `/contribution-view/${thankyouPage?.additionalLeadMagnetThankyouPage?.selectedContributionId}/${data?.code}/about`,
  //       );
  //     });
  //   }
  // }, [thankyouPage?.additionalLeadMagnetThankyouPage?.selectedContributionId]);
  useEffect(() => {
    const pageRoot = window; // document.getElementById('lead-magnet-thank-you-root');
    if (pageRoot) {
      pageRoot.scrollTo(0, 0);
    }
  }, []);
  useEffect(() => {
    if (bannerContainerRef1?.current || bannerContainerRef2?.current) {
      const containerRef = bannerContainerRef1?.current || bannerContainerRef2?.current;
      const containerWidth = containerRef.getBoundingClientRect().width;
      const containerHeight = (containerWidth / 16) * 9;
      setBannerContainerDimensionsIn16ratio9({ width: containerWidth, height: containerHeight });
      console.info('banner:', cloneDeep({ width: containerWidth, height: containerHeight }));
      if (videoPlayerRef1?.current && thankyouPage?.leadMagnetThankyouPage?.isVideoBanner) {
        const playerWrapper = videoPlayerRef1.current.wrapper;
        console.info('player ref 1:', videoPlayerRef1?.current, playerWrapper);
        if (playerWrapper) {
          playerWrapper.style.maxHeight = containerHeight ? `${containerHeight}px` : '16.7855rem';
          playerWrapper.style.overflow = 'hidden';
        }
        const internalPlayer = videoPlayerRef1.current.getInternalPlayer();
        if (internalPlayer) {
          internalPlayer.style.maxHeight = containerHeight ? `${containerHeight}px` : '16.7855rem';
        }
        setTimeout(() => {
          const internalPlayer = videoPlayerRef1.current.getInternalPlayer();
          if (internalPlayer) {
            internalPlayer.style.maxHeight = containerHeight ? `${containerHeight}px` : '16.7855rem';
          }
        }, 300);
      }
      if (videoPlayerRef2?.current && thankyouPage?.additionalLeadMagnetThankyouPage?.isVideoBanner) {
        const playerWrapper = videoPlayerRef2.current.wrapper;
        console.info('player ref 2:', videoPlayerRef2?.current, playerWrapper);
        if (playerWrapper) {
          playerWrapper.style.maxHeight = containerHeight ? `${containerHeight}px` : '16.7855rem';
          playerWrapper.style.overflow = 'hidden';
        }
        const internalPlayer = videoPlayerRef2.current.getInternalPlayer();
        if (internalPlayer) {
          internalPlayer.style.maxHeight = containerHeight ? `${containerHeight}px` : '16.7855rem';
        }
        setTimeout(() => {
          const internalPlayer = videoPlayerRef2.current.getInternalPlayer();
          if (internalPlayer) {
            internalPlayer.style.maxHeight = containerHeight ? `${containerHeight}px` : '16.7855rem';
          }
        }, 300);
      }
    }
  }, [
    bannerContainerRef1,
    bannerContainerRef2,
    bannerContainerRef1?.current,
    bannerContainerRef2?.current,
    videoPlayerRef1,
    videoPlayerRef2,
    videoPlayerRef1?.current,
    videoPlayerRef2?.current,
    thankyouPage?.leadMagnetThankyouPage?.isVideoBanner,
    thankyouPage?.additionalLeadMagnetThankyouPage?.isVideoBanner,
  ]);

  useEffect(
    () => console.info('bannerContainerDimensionsIn16ratio9:', cloneDeep(bannerContainerDimensionsIn16ratio9)),
    [bannerContainerDimensionsIn16ratio9],
  );

  const filteredVideo = leadMagnet?.content?.prerecordedData.filter(
    item =>
      item.extension?.toLowerCase()?.includes('webm') ||
      item.extension?.toLowerCase()?.includes('mp4') ||
      item.extension?.toLowerCase()?.includes('mov') ||
      // item.embeddedVideoUrl !== '',
      !isEmptyOrNil(item.embeddedVideoUrl),
  );
  const filteredPdfAudio = leadMagnet?.content?.prerecordedData.filter(
    item =>
      item.extension?.toLowerCase()?.includes('pdf') ||
      item.extension?.toLowerCase()?.includes('mp3') ||
      item.extension?.toLowerCase()?.includes('wav') ||
      item.extension?.toLowerCase()?.includes('m4a') ||
      item.extension?.toLowerCase()?.includes('ogg'),
  );

  const {
    themedCardBackgroundColor,
    themedColor,
    newThemedTextColor,
    newThemedBackgroundColor,
    themedBackgroundColor,
  } = themedColors;
  // const textColor =
  //   colorToUse?.TextColorCode === 'Auto'
  //     ? lightOrDark(colorToUse?.PrimaryColorCode)
  //     : colorToUse?.TextColorCode === '#000000'
  //     ? '#000000'
  //     : '#FFFFFF';

  const setScrollingElementId = useCallback(() => smoothScrollingToElement('lead-magnet-content-container'), []);
  return (
    <>
      {id === '66466bdaee9e6c514da9d7d9' && <ThankYouMetaPixelLM />}
      {id === '66466bdaee9e6c514da9d7d9' && <GoogleAnalytics />}
      {id === '66466bdaee9e6c514da9d7d9' && <ClarityScript />}
      {id === '66611264d7b9941dec80d5b9' && <PixelComponent />}
      <StyledGridContianer {...{ mobileView }} id="lead-magnet-thank-you-root">
        {thankyouPage?.leadMagnetThankyouPage &&
          !isEmpty(thankyouPage?.leadMagnetThankyouPage) &&
          (thankyouPage?.leadMagnetThankyouPage?.bannerImage ||
            thankyouPage?.leadMagnetThankyouPage?.header ||
            thankyouPage?.leadMagnetThankyouPage?.description ||
            thankyouPage?.leadMagnetThankyouPage?.ctaButtonText) && (
            <Grid container>
              <StyledGrid
                classname="section-banner-container"
                container
                item
                xl={7}
                lg={7}
                sm={12}
                alignItems="center"
                justifyContent="center"
                {...{ ref: bannerContainerRef1, ...bannerContainerDimensionsIn16ratio9 }}
              >
                {thankyouPage?.leadMagnetThankyouPage?.bannerImage && (
                  <>
                    {thankyouPage?.leadMagnetThankyouPage?.isVideoBanner ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        }}
                        onClick={() => {
                          setThankyouAccessContentVideoBannerPlaying(!thankyouAccessContentVideoBannerPlaying);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <ReactPlayer
                          // muted
                          url={thankyouPage?.leadMagnetThankyouPage?.bannerImage}
                          playing={thankyouAccessContentVideoBannerPlaying}
                          width="100%"
                          // height={mobileView ? '13.22788rem' : '24.16038rem'}
                          height="auto"
                          loop
                          // controls={thankyouAccessContentVideoBannerPlaying}
                          ref={videoPlayerRef1}
                        />
                        {!thankyouAccessContentVideoBannerPlaying && (
                          <StyledPlayIconWrapper
                            bgColor={colorToUse?.AccentColorCode}
                            className="play-pause-button"
                            onClick={() =>
                              setThankyouAccessContentVideoBannerPlaying(!thankyouAccessContentVideoBannerPlaying)
                            }
                          >
                            <PlayIcon
                              style={{
                                height: '24.152px',
                                width: '24.152px',
                                color: '#FFF',
                                position: 'absolute',
                                zIndex: 15,
                                cursor: 'pointer',
                              }}
                            />
                          </StyledPlayIconWrapper>
                        )}
                      </div>
                    ) : (
                      <StyledImage {...{ mobileView }} src={thankyouPage?.leadMagnetThankyouPage?.bannerImage} />
                    )}
                  </>
                )}
              </StyledGrid>
              <Grid item xl={5} lg={5} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                <StyledGridContent isRight {...{ mobileView }}>
                  {thankyouPage?.leadMagnetThankyouPage?.header && (
                    <StyledHeadingOne {...{ mobileView }} textColor={colorToUse?.AccentColorCode}>
                      {thankyouPage?.leadMagnetThankyouPage?.header}
                    </StyledHeadingOne>
                  )}
                  {thankyouPage?.leadMagnetThankyouPage?.description && (
                    <StyledText {...{ mobileView }} textColor={themedColors.themedColor}>
                      {thankyouPage?.leadMagnetThankyouPage?.description}
                    </StyledText>
                  )}
                  {thankyouPage?.leadMagnetThankyouPage?.ctaButtonText && (
                    <Button
                      onClick={setScrollingElementId}
                      backgroundColor={colorToUse?.PrimaryColorCode}
                      textColor={textColor}
                      autoWidth
                    >
                      {thankyouPage?.leadMagnetThankyouPage?.ctaButtonText}
                    </Button>
                  )}
                </StyledGridContent>
              </Grid>
            </Grid>
          )}
        {thankyouPage?.additionalLeadMagnetThankyouPage &&
          !isEmpty(thankyouPage?.additionalLeadMagnetThankyouPage) &&
          (thankyouPage?.additionalLeadMagnetThankyouPage?.header ||
            thankyouPage?.additionalLeadMagnetThankyouPage?.description ||
            thankyouPage?.additionalLeadMagnetThankyouPage?.isCustomCTAEnabled ||
            thankyouPage?.additionalLeadMagnetThankyouPage?.bannerImage) && (
            <Grid container direction={mobileView && 'column-reverse'}>
              <Grid item xl={5} lg={5} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                <StyledGridContent {...{ mobileView }}>
                  {thankyouPage?.additionalLeadMagnetThankyouPage?.header && (
                    <StyledHeadingOne {...{ mobileView }} textColor={colorToUse?.AccentColorCode}>
                      {thankyouPage?.additionalLeadMagnetThankyouPage?.header}
                    </StyledHeadingOne>
                  )}
                  {thankyouPage?.additionalLeadMagnetThankyouPage?.description && (
                    <StyledText {...{ mobileView }} textColor={themedColors.themedColor}>
                      {thankyouPage?.additionalLeadMagnetThankyouPage?.description}
                    </StyledText>
                  )}
                  {thankyouPage?.additionalLeadMagnetThankyouPage?.isCustomCTAEnabled &&
                    thankyouPage?.additionalLeadMagnetThankyouPage?.ctaButtonText && (
                      <Button
                        backgroundColor={colorToUse?.PrimaryColorCode}
                        textColor={textColor}
                        autoWidth
                        // onClick={setScrollingElementId}
                        onClick={
                          contributionUrl ||
                          thankyouPage?.additionalLeadMagnetThankyouPage?.selectedContributionUrl ||
                          thankyouPage?.additionalLeadMagnetThankyouPage?.url
                            ? () => {
                                if (
                                  contributionUrl ||
                                  thankyouPage?.additionalLeadMagnetThankyouPage?.selectedContributionUrl
                                ) {
                                  openInNewWindow(
                                    contributionUrl ||
                                      thankyouPage?.additionalLeadMagnetThankyouPage?.selectedContributionUrl,
                                    '_blank',
                                  );
                                } else {
                                  const url = thankyouPage?.additionalLeadMagnetThankyouPage?.url;
                                  const absoluteUrl = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(url)
                                    ? url
                                    : `https://${url}`;
                                  openInNewWindow(absoluteUrl, '_blank');
                                }
                              }
                            : null
                        }
                      >
                        {thankyouPage?.additionalLeadMagnetThankyouPage?.ctaButtonText}
                      </Button>
                    )}
                </StyledGridContent>
              </Grid>
              <StyledGrid
                classname="section-banner-container"
                container
                item
                xl={7}
                lg={7}
                sm={12}
                alignItems="center"
                justifyContent="center"
                {...{ ref: bannerContainerRef1, ...bannerContainerDimensionsIn16ratio9 }}
              >
                {thankyouPage?.additionalLeadMagnetThankyouPage?.bannerImage && (
                  <>
                    {thankyouPage?.additionalLeadMagnetThankyouPage?.isVideoBanner ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        }}
                        onClick={() => {
                          setThankyouAdditionalContentVideoBannerPlaying(!thankyouAdditionalContentVideoBannerPlaying);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <ReactPlayer
                          // muted
                          url={thankyouPage?.additionalLeadMagnetThankyouPage?.bannerImage}
                          playing={thankyouAdditionalContentVideoBannerPlaying}
                          width="100%"
                          // height={mobileView ? '13.22788rem' : '24.16038rem'}
                          height="auto"
                          loop
                          // controls={thankyouAdditionalContentVideoBannerPlaying}
                          ref={videoPlayerRef2}
                        />
                        {!thankyouAdditionalContentVideoBannerPlaying && (
                          <StyledPlayIconWrapper
                            bgColor={colorToUse?.AccentColorCode}
                            className="play-pause-button"
                            onClick={() =>
                              setThankyouAdditionalContentVideoBannerPlaying(
                                !thankyouAdditionalContentVideoBannerPlaying,
                              )
                            }
                          >
                            <PlayIcon
                              style={{
                                height: '24.152px',
                                width: '24.152px',
                                color: '#FFF',
                                position: 'absolute',
                                zIndex: 15,
                                cursor: 'pointer',
                              }}
                            />
                          </StyledPlayIconWrapper>
                        )}
                      </div>
                    ) : (
                      <StyledImage
                        {...{ mobileView }}
                        src={thankyouPage?.additionalLeadMagnetThankyouPage?.bannerImage}
                      />
                    )}
                  </>
                )}
              </StyledGrid>
            </Grid>
          )}
        {(content?.contentHeader || filteredVideo?.length || filteredPdfAudio?.length) && (
          <StyledDownLoadContianer bgColor={leadMagnet?.isDarkModeEnabled ? '#2D2F31' : themedBackgroundColor}>
            {content?.contentHeader && (
              <StyledHeadingOne
                id="lead-magnet-content-container"
                {...{ mobileView }}
                textColor={colorToUse?.AccentColorCode}
              >
                {content?.contentHeader}
              </StyledHeadingOne>
            )}
            {filteredVideo && filteredVideo?.length > 0 && (
              <StyledVideoMainContainer {...{ mobileView }}>
                {filteredVideo.map((content, i) => {
                  return <DownloadContents type="video" {...{ content }} />;
                })}
              </StyledVideoMainContainer>
            )}

            {filteredPdfAudio && filteredPdfAudio?.length > 0 && (
              <StyledDownLoadContent {...{ mobileView }} style={{ alignItems: 'flex-start' }}>
                {filteredPdfAudio.map((content, i) => {
                  return <DownloadContents type="pdf" {...{ content }} />;
                })}
              </StyledDownLoadContent>
            )}
            {/* <Button backgroundColor={colorToUse?.PrimaryColorCode} textColor={textColor} autoWidth>
          Download Now
        </Button> */}
          </StyledDownLoadContianer>
        )}
        {/* </Grid> */}
      </StyledGridContianer>
    </>
  );
};

export default LeadMagnetThankYouPage;
