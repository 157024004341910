import React, { useMemo } from 'react';
import { useConversations, useRouter, useUnifiedCommunity } from 'hooks';
import styled from 'styled-components';
import TextField from 'components/UI/TextField';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchAndFilterSection, CreatePost } from '.';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import PostList from 'pages/ContributionView/ContributionSocial/components/PostList';
import { connect, useDispatch } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import * as conmmunityActions from '../../actions/community';
import {
  fetchClientContribution,
  fetchCohealerContribution,
  fetchContributionTimes,
  resetContribution,
  firstTimeRedirect,
} from 'actions/contributions';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { COUNT_POSTS_BY_PAGE } from '../../../src/constants';
import { CircularProgress, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import {
  getPost,
  searchByKeyword,
  getPostForKeywords,
  getHashTagByContributionId,
  getAllContributionForCommunityPostCoach,
  getAllContributionForCommunityPostClient,
  getSuggestedSearchOptionsForAllContributionOfCoach,
  getSuggestedSearchOptionsForAllContributionOfClient,
  getHashTagsIncommunitiesFromContributionsForCoach,
  getHashTagsIncommunitiesFromContributionsForClient,
} from 'services/community.service';
import useAccount from 'hooks/useAccount';
import { setPostsAsRead } from 'services/user.service';
import { useParams } from 'react-router-dom';
import { memberPodsService } from '../../../src/services/memberPods/memberPods.service';
import { useHttp } from 'hooks';
import * as R from 'ramda';
import { setCohealerInfoForClient } from 'actions/cohealerInfo';
import { UserRoles } from 'helpers/constants';
import { ROUTES } from '../../../src/constants';
import { useCallback } from 'react';
import { debounce } from 'lodash';

const StyledWrap = styled(Grid)`
  // padding-left: 30px;
  // box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  width: 100%;
  // @media screen and (max-width: 767px) {
  //   width: 95%;
  //   margin: auto;
  // }
  ${({ mobileView }) =>
    mobileView &&
    `
  padding-left: 0;
  `}
`;

const NewsFeedContainer = styled.div`
  width: 65%;
  ${({ mobileView }) =>
    mobileView &&
    `
   width: 100%;
  `}
`;

const NewsFeed = ({ communityPosts, getClientContribution, currentRole, getCohealerContribution }) => {
  const dispatch = useDispatch();
  const contribution = useContribution();
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const participants = contribution?.participants;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const [pods, setPods] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchWord, setSearchWord] = useState('');
  const [renderPosts, setRenderPosts] = useState(false);
  const [hashtags, setHashtags] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [communitiesForFilter, setCommunitiesForFilter] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [post, setPost] = useState([]);
  const [searchedWord, setSearchedWord] = useState('');
  const [keywordsLoaded, setKeywordsLoaded] = useState(false);
  const [selectedKeyWord, setSelectedKeyWord] = useState('');
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [searchLoader, setSearchLoader] = useState(false);
  const [showKeywordMenu, setShowKeywordMenu] = useState(true);
  const [users, setUsers] = useState([]);
  const hasMorePosts =
    communityPosts?.posts?.length != 0 &&
    communityPosts?.posts?.length % COUNT_POSTS_BY_PAGE === 0 &&
    communityPosts?.fetchedPosts?.length != 0;

  const isKeywordMenuOpen = searchedWord?.length > 0 && showKeywordMenu;
  const [filterParams, setFilterParams] = useState({
    users: [{ title: 'NONE', value: 'NONE' }],
    taggs: ['NONE'],
    communities: [{ id: 'NONE', title: 'NONE' }],
  });
  const noneIsSelectedInUsers = filterParams?.users?.length == 1 && filterParams?.users[0].value === 'NONE';
  const noneIsSelectedInTaggs = filterParams?.taggs?.length == 1 && filterParams?.taggs?.includes('NONE');
  const noneIsSelectedInCommunities =
    filterParams?.communities?.length == 1 && filterParams?.communities[0].id === 'NONE';
  const { user } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const [crossClicked, setCrossClicked] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);
  const [supportPopup, setSupportPopup] = useState(false);
  // const { postId } = useParams();
  const { contId } = useParams();
  const { request } = useHttp();
  const { history, location, query, domain } = useRouter();
  const [keywords, setKeywords] = useState([]);

  // const fetchPosts = () => {
  //   dispatch(conmmunityActions.getAllPostsForContribution(`${contId}/${pageNumber ?? 1}/${COUNT_POSTS_BY_PAGE}`))
  //     .then(() => setPageNumber(communityPosts?.currentPage))
  //     .then(() => setIsLoad(true))
  //     .then(() => setPostsAsRead({ contributionId: contId, userId: user.id }));
  // };

  const fetchPosts = data => {
    let payload = {
      skip: 0,
      take: COUNT_POSTS_BY_PAGE,
    };

    if (data?.keywords || data?.ContributionId || data?.taggs) {
      payload = data;
    }
    if (isCoach) {
      dispatch(conmmunityActions.getAllCommunityPostsForCoach(payload))
        .then(() => setPageNumber(communityPosts?.currentPage))
        .then(() => setIsLoad(true))
        .then(() => setSearchLoader(false));
      // .then(() => setPostsAsRead({ contributionId: contId, userId: user.id }));
    } else {
      dispatch(conmmunityActions.getAllCommunityPostsForClient(payload))
        .then(() => setPageNumber(communityPosts?.currentPage))
        .then(() => setIsLoad(true))
        .then(() => setSearchLoader(false));
      // .then(() => setPostsAsRead({ contributionId: contId, userId: user.id }));
    }
  };

  const fetchSearchedPosts = data => {
    setRenderPosts(false);
    dispatch(conmmunityActions.getAllPostsForKeyword(data))
      .then(() => setPageNumber(data.skip))
      .then(() => setIsLoad(true))
      .then(() => setRenderPosts(true))
      .then(() => setPostsAsRead({ contributionId: contId, userId: user.id }));
  };

  const getActiveContribution = useCallback(() => {
    if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
      getCohealerContribution(contId).then(res => {
        setRenderPosts(true);
      });
    } else if (currentRole === UserRoles?.client) {
      getClientContribution(contId).then(res => {
        setRenderPosts(true);
      });
    } else {
      getClientContribution(contId).then(res => {
        setRenderPosts(true);
      });
    }
  }, []);

  useEffect(() => {
    // getActiveContribution();
  }, [contId, currentRole, getClientContribution, getCohealerContribution]);

  useEffect(() => {
    if (
      filterParams?.users?.length > 0 &&
      !noneIsSelectedInUsers &&
      filterParams?.taggs?.length > 0 &&
      !noneIsSelectedInTaggs &&
      filterParams?.communities?.length > 0 &&
      !noneIsSelectedInCommunities
    ) {
      setNotificationsCount(3);
    } else if (
      (filterParams?.users?.length > 0 &&
        !noneIsSelectedInUsers &&
        filterParams?.taggs?.length > 0 &&
        !noneIsSelectedInTaggs) ||
      (filterParams?.users?.length > 0 &&
        !noneIsSelectedInUsers &&
        filterParams?.communities?.length > 0 &&
        !noneIsSelectedInCommunities) ||
      (filterParams?.taggs?.length > 0 &&
        !noneIsSelectedInTaggs &&
        filterParams?.communities?.length > 0 &&
        !noneIsSelectedInCommunities)
    ) {
      setNotificationsCount(2);
    } else if (
      (filterParams?.users?.length > 0 && !noneIsSelectedInUsers) ||
      (filterParams?.taggs?.length > 0 && !noneIsSelectedInTaggs) ||
      (filterParams?.communities?.length > 0 && !noneIsSelectedInCommunities)
    ) {
      setNotificationsCount(1);
    } else {
      setNotificationsCount(0);
    }
  }, [filterParams]);

  useEffect(() => {
    if (isCoach) {
      getHashTagsIncommunitiesFromContributionsForCoach().then(res => {
        setHashtags(res);
      });
    } else {
      getHashTagsIncommunitiesFromContributionsForClient().then(res => {
        setHashtags(res);
      });
    }
  }, []);

  useEffect(() => {
    if (isCoach) {
      getAllContributionForCommunityPostCoach().then(res => {
        let communities = [];
        for (let c in res) {
          const d = {
            id: c,
            title: res[c],
          };
          communities.push(d);
        }
        setCommunities(communities);
      });
    } else {
      getAllContributionForCommunityPostClient(true).then(res => {
        let communities = [];
        for (let c in res) {
          const d = {
            id: c,
            title: res[c],
          };
          communities.push(d);
        }
        setCommunities(communities);
      });
      getAllContributionForCommunityPostClient(false).then(res => {
        let communities = [];
        for (let c in res) {
          const d = {
            id: c,
            title: res[c],
          };
          communities.push(d);
        }
        setCommunitiesForFilter(communities);
      });
    }
  }, []);

  // const getPostApi = async id => {
  //   try {
  //     let response = await getPost(id);
  //     setPost(response);
  //     setSupportPopup(true);
  //     setIsLoad(true);
  //   } catch {
  //     setSupportPopup(false);
  //     setIsLoad(false);
  //   }
  // };

  const onCrossClick = useCallback(() => {
    setCrossClicked(true);
    setSearchedWord('');
    setSearchLoader(true);
    setShowKeywordMenu(true);
  }, []);

  const onClearFilter = useCallback(() => {
    setClearClicked(true);
    setSearchLoader(true);
    setShowKeywordMenu(true);
  }, []);

  useEffect(() => {
    if (!searchedWord?.length) {
      onCrossClick();
    }
  }, [searchedWord]);

  useEffect(() => {
    setPageNumber(1);
  }, []);

  // useEffect(() => {
  //   if (postId) {
  //     getPostApi(postId);
  //   }
  // }, []);

  useEffect(() => {
    setSearchLoader(true);
    setShowKeywordMenu(true);
    fetchPosts();
  }, []);

  useEffect(() => {
    if ((!searchedWord && crossClicked) || (noneIsSelectedInTaggs && noneIsSelectedInCommunities && clearClicked)) {
      setCrossClicked(false);
      setClearClicked(false);
      setPageNumber(1);
      setSearchLoader(true);
      // fetchPosts();
      setShowKeywordMenu(true);
      onKeywordClick({
        keyword: searchedWord,
        skip: 0,
        params: filterParams,
      });
    }
  }, [searchedWord, crossClicked, filterParams, clearClicked]);

  // useEffect(() => {
  //   fetchPosts();
  //   memberPodsService
  //     .getContributionPods(contId)
  //     .then(x => setPods(x))
  //     .catch(x => setPods([]));
  //   request(`/Contribution/GetCohealerInfoForClient/${contribution.userId}`, 'GET').then(
  //     R.compose(dispatch, setCohealerInfoForClient),
  //   );
  // }, [dispatch, contId]);

  const setFilterData = useCallback(data => {
    const newValues = {
      users: data?.byPeople,
      taggs: data?.byHashtag,
      communities: data?.byCommunity,
    };
    setFilterParams(newValues);
  }, []);

  const Loader = () => {
    return (
      <Grid container justifyContent="center" className="mb-4 mt-4">
        <CircularProgress color="primary" />
      </Grid>
    );
  };

  const onSearchChange = useCallback(
    val => {
      setKeywordsLoaded(false);
      setSearchedWord(val);
      setShowKeywordMenu(true);
      const anyCommunitySelected = filterParams?.communities.length > 0 && !noneIsSelectedInCommunities;
      const selectedCommunities = anyCommunitySelected ? filterParams?.communities : [];
      if (val?.length > 0) {
        try {
          let payload = {
            keywords: val.toLowerCase().trim(),
            selectedContributionIds: selectedCommunities,
          };
          if (selectedCommunities.length === 0) {
            payload = {
              keywords: val.toLowerCase().trim(),
            };
          }
          if (isCoach) {
            getSuggestedSearchOptionsForAllContributionOfCoach(payload).then(res => {
              setKeywords(res);
              setKeywordsLoaded(true);
            });
          } else {
            getSuggestedSearchOptionsForAllContributionOfClient(payload).then(res => {
              setKeywords(res);
              setKeywordsLoaded(true);
            });
          }
        } catch {
          setKeywordsLoaded(true);
        }
      } else {
        setKeywordsLoaded(true);
        setKeywords([]);
      }
    },
    [filterParams],
  );

  const onKeywordClick = useCallback(
    input => {
      const { keyword, skip, params } = input;
      const filters = params || filterParams;
      const noneIsSelectedInUsersFilter = filters?.users?.length == 1 && filters?.users[0].value === 'NONE';
      const noneIsSelectedInTaggsFilter = filters?.taggs?.length == 1 && filters?.taggs?.includes('NONE');
      const noneIsSelectedInCommunitiesFilter =
        filters?.communities?.length == 1 && filters?.communities[0].id === 'NONE';

      if (!(skip > 0)) {
        setSearchLoader(true);
      }
      setSelectedKeyWord(keyword);
      setSearchedWord(keyword);
      setShowKeywordMenu(false);
      let data;

      if (
        keyword &&
        ((filters?.taggs?.length > 0 && !noneIsSelectedInTaggsFilter) ||
          (filters?.communities?.length > 0 && !noneIsSelectedInCommunitiesFilter))
      ) {
        data = {
          // contributionId: contribution?.id,
          keywords: keyword,
          skip: skip,
          take: COUNT_POSTS_BY_PAGE,
        };
        if (filters?.taggs?.length > 0 && !noneIsSelectedInTaggsFilter) {
          data = {
            ...data,
            taggs: filters?.taggs,
          };
        }
        if (filters?.communities?.length > 0 && !noneIsSelectedInCommunitiesFilter) {
          const contIds = filters?.communities?.map(c => c.id) || [];
          data = {
            ...data,
            ContributionId: contIds,
          };
        }
      } else if (keyword) {
        data = {
          keywords: keyword,
          skip: skip,
          take: COUNT_POSTS_BY_PAGE,
        };
      } else if (
        (filters?.taggs?.length > 0 && !noneIsSelectedInTaggsFilter) ||
        (filters?.communities?.length > 0 && !noneIsSelectedInCommunitiesFilter)
      ) {
        data = {
          skip: skip,
          take: COUNT_POSTS_BY_PAGE,
        };
        if (filters?.taggs?.length > 0 && !noneIsSelectedInTaggsFilter) {
          data = {
            ...data,
            taggs: filters?.taggs,
          };
        }

        if (filters?.communities?.length > 0 && !noneIsSelectedInCommunitiesFilter) {
          const contIds = filters?.communities?.map(c => c.id) || [];
          data = {
            ...data,
            ContributionId: contIds,
          };
        }
      }
      fetchPosts(data);
    },
    [filterParams],
  );

  const fetchMoreData = () => {
    if (
      searchedWord ||
      (filterParams?.taggs?.length > 0 && !noneIsSelectedInTaggs) ||
      (filterParams?.communities?.length > 0 && !noneIsSelectedInCommunities)
    ) {
      onKeywordClick({
        keyword: selectedKeyWord,
        skip: communityPosts?.posts?.length,
        params: filterParams,
      });
    } else {
      if (isCoach) {
        dispatch(
          conmmunityActions.getAllCommunityPostsForCoach({
            skip: communityPosts?.posts?.length,
            take: COUNT_POSTS_BY_PAGE,
          }),
        );
      } else {
        dispatch(
          conmmunityActions.getAllCommunityPostsForClient({
            skip: communityPosts?.posts?.length,
            take: COUNT_POSTS_BY_PAGE,
          }),
        );
      }
    }
  };

  const onSubmitFilter = useCallback(values => {
    const filtersDataForUnifiedCommunity = {
      taggs: values.byHashtag,
      communities: values.byCommunity,
    };

    const filtersDataForNormalCommunity = {
      taggs: values.byHashtag,
      users: values.byPeople,
    };

    const filterData = values.unifiedCommunity ? filtersDataForUnifiedCommunity : filtersDataForNormalCommunity;

    setFilterData(values);
    onKeywordClick({
      keyword: selectedKeyWord,
      skip: 0,
      params: filterData,
    });
  }, []);

  const onHashtagClick = useCallback(
    hashtag => {
      const values = {
        byPeople: filterParams.users,
        byHashtag: [hashtag],
        byCommunity: filterParams.communities,
      };
      onSubmitFilter(values);
    },
    [filterParams],
  );

  const reloadPosts = () => {
    setShowKeywordMenu(true);
    onKeywordClick({
      keyword: searchedWord || '',
      skip: 0,
      params: filterParams,
    });
    if (isCoach) {
      getHashTagsIncommunitiesFromContributionsForCoach().then(res => {
        setHashtags(res);
      });
    } else {
      getHashTagsIncommunitiesFromContributionsForClient().then(res => {
        setHashtags(res);
      });
    }
  };

  return (
    <NewsFeedContainer mobileView={mobileView}>
      <Grid container style={{ justifyContent: 'center' }}>
        {searchedWord?.length <= 0 && notificationsCount <= 0 && (
          <Grid container style={{ justifyContent: 'center', marginBottom: '20px' }}>
            <CreatePost hashtags={hashtags} communities={communities} reloadPostsOnUpload={reloadPosts} />
          </Grid>
        )}
        <SearchAndFilterSection
          onSearchChange={onSearchChange}
          keywords={keywords}
          searchedWord={searchedWord}
          onKeywordClick={onKeywordClick}
          setFilterData={setFilterData}
          filterParams={filterParams}
          keywordsLoaded={keywordsLoaded}
          notificationsCount={notificationsCount}
          users={users}
          hashtags={hashtags}
          communities={isCoach ? communities : communitiesForFilter}
          onCrossClick={onCrossClick}
          onClearFilter={onClearFilter}
          onSubmitFilter={onSubmitFilter}
          isKeywordMenuOpen={isKeywordMenuOpen}
          selectedKeyWord={selectedKeyWord}
        />
      </Grid>
      {!isKeywordMenuOpen ? (
        <>
          {searchLoader ? (
            <Loader />
          ) : (
            <InfiniteScroll
              dataLength={communityPosts?.posts?.length || 0}
              next={fetchMoreData}
              pullDownToRefreshThreshold={0}
              hasMore={hasMorePosts}
              loader={<Loader />}
            >
              <StyledWrap mobileView={mobileView} container className="mb-4 mt-4">
                <Grid item xs={12} md={12}>
                  {(!!searchedWord ||
                    !noneIsSelectedInTaggs ||
                    (!isUnifiedCommunity && !noneIsSelectedInUsers) ||
                    (isUnifiedCommunity && !noneIsSelectedInCommunities)) &&
                  communityPosts?.posts?.length === 0 ? (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}
                    >
                      No search results found
                    </div>
                  ) : (
                    <PostList
                      items={communityPosts?.posts}
                      pods={pods}
                      getActiveContribution={getActiveContribution}
                      searchedWord={selectedKeyWord}
                      reloadPostsOnEdit={reloadPosts}
                      onHashtagClick={onHashtagClick}
                    />
                  )}
                </Grid>
              </StyledWrap>
            </InfiniteScroll>
          )}
        </>
      ) : null}
    </NewsFeedContainer>
  );
};

const mapStateToProps = ({ communityPosts, contributions, account }) => ({
  communityPosts,
  contribution: contributions?.activeContribution,
  error: contributions?.error,
  loading: contributions?.loading,
  currentRole: account?.currentRole,
});

const actions = {
  getClientContribution: fetchClientContribution,
  getCohealerContribution: fetchCohealerContribution,
  getContributionTimes: fetchContributionTimes,
};

export default connect(mapStateToProps, actions)(NewsFeed);
