import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import styled, { css } from 'styled-components';
import { useTheme, useMediaQuery, makeStyles, TextField } from '@material-ui/core';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import axiosInstance from 'utils/axiosInstance';
import Dropzone from 'components/FormUI/Dropzone';
import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';
import { UserRoles } from 'helpers/constants';
import { useAccount } from 'hooks';
import useVideoPlayer from 'hooks/useVideoPlayer';
import { useVideoDurationParse } from 'pages/CreateContribution/hooks/useVideoDurationParse';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { checkIsFuture } from 'utils/datesAndMoney';
import { DescriptionOutlined } from '@material-ui/icons';
import { determineColorToUse, getVideoUrl } from 'services/contributions.service';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import { AddIcon } from 'pages/Profile/components/icons/Add';
import UploadThumbnailIcon from 'pages/CreateContribution/components/UploadThumbnailIcon';
import { InputAdornment } from '@material-ui/core';
import ReactPlayer from 'react-player/lazy';
import { PLATFORMS_ALLOWED, TOOLTIP } from '../../constants';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import * as actions from 'actions/contributions';
import Modal from 'components/UI/Modal';
import { colors } from 'utils/styles';
import { addhttp, inAllowedPlatforms } from 'utils/utils';
import classes from './video.module.scss';
import { PlayIcon } from 'pages/ContributionView/ContributionSessions/component/icons';
import { cloneDeep, isArray, isEqual } from 'lodash';

const styles = theme => ({
  loaderWrapper: {
    backgroundColor: 'rgba(255, 255, 255, .5)',
    height: '100%',
    position: 'absolute',
  },

  inputField: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: '10px',
    width: '100%',
    border: '1px solid #f5f0f0',
    marginTop: '5px',
  },

  inputFieldForCard: {
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid #f5f0f0',
    height: '55px',
    padding: '20px 15px',
    outline: '1px solid #f5f0f0',
    border: '0 !important',
    alignSelf: 'center',
  },

  inputFieldForCardDark: {
    backgroundColor: 'rgb(37, 39, 40)',
    color: '#ffffff !important',
    borderRadius: '10px',
    border: '1px solid #f5f0f0',
    height: '55px',
    padding: '20px 15px',
    outline: '1px solid #f5f0f0',
    border: '0 !important',
    alignSelf: 'center',
  },
  nameInputFieldForCard: {
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid #f5f0f0',
    outline: '1px solid #f5f0f0',
    border: '0 !important',
    alignSelf: 'center',
  },
  noBorder: {
    border: 'none',
  },

  link: {
    color: 'white',
    '&:hover': {
      color: props => props.color,
    },
  },
});

const useStyles = makeStyles(styles);

const StyledVideo = styled.video`
  border-radius: 4px;
  display: flex;
  height: ${({ isPreviewMode, isSessionView, mobileView }) =>
    mobileView ? 'auto' : isSessionView ? '465px' : isPreviewMode ? '435px' : '230px'};
  justify-content: center;
  margin: ${({ isSessionView }) => (isSessionView ? '0px' : '10px 0 10px')};
  width: ${({ isPreviewMode, isSessionView, mobileView }) =>
    mobileView ? '100%' : isSessionView ? '100%' : !isPreviewMode && '100% '};
  ${({ shouldDim }) => shouldDim && `filter: brightness(0.5);`};
  ${({ makeFullWidthAndHeight }) =>
    makeFullWidthAndHeight &&
    css`
      height: 100%;
      width: 100%;
    `};
`;

const StyledAudio = styled.audio`
  border-radius: 4px;
  display: flex;
  height: 54px;
  justify-content: center;
  margin: ${({ isSessionView }) => (isSessionView ? '0px' : '10px 0 10px')};
  width: ${({ isPreviewMode, isSessionView }) => (isSessionView ? '100%' : !isPreviewMode && '100% ')};
`;

const StyledUploadThumbnailContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 15px;
  z-index: 10;
  cursor: pointer;
`;
const ReactPlayerWrapper = styled.div`
  cursor: pointer;
  ${({ shouldDim }) =>
    shouldDim &&
    `
    pointer-events: none;
    filter: brightness(0.5);
    // opacity: 0.5;
  `}/* ${({ isSessionView }) =>
    isSessionView &&
    css`
      width: 100%;
      height: 100%;
    `} */
`;
const Image = styled.img`
  background-color: #f5f5f5;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin: 10px 0 10px;
  width: 100%;
  overflow: ${({ isSessionView }) => (isSessionView ? 'hidden' : 'inherit')};
  height: ${({ isSessionView, isPreviewMode, mobileView }) =>
    mobileView ? 'auto' : isSessionView ? 'auto' : isPreviewMode ? '300px' : '230px'};
  ${({ makeFullWidthAndHeight }) =>
    makeFullWidthAndHeight &&
    css`
      height: 100%;
    `};
  object-fit: contain;
  border: solid 1px #dadada;
  margin-bottom: 5px;
`;

const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#][^\s]*\S*$/i;
const plateformsAllowed = [
  'youtube',
  'soundcloud',
  'facebook',
  'vimeo',
  'twitch',
  'streamable',
  'wistia',
  'dailymotion',
  'mixcloud',
  'vidyard',
  'kaltura',
];
const StyledPlayIconWrapper = styled.div`
  svg {
    margin-left: 0.25rem;
  }
`;

export const Video = props => {
  const {
    duration,
    form,
    sessionIndex,
    sessionTimeIndex,
    session,
    isPreviewMode,
    sessionTime,
    shouldBeDisabled = null,
    uploadThumbnail,
    refetch,
    isSessionView = false,
  } = props;
  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const [showVideo, setShowVideo] = useState(false);
  const { currentRole, user } = useAccount();
  console.log('myform>>', form);
  const classNames = useStyles({ color: colorToUse?.AccentColorCode });

  // const placeholder = (
  //   <p style={{ margin: '0px', color: 'gray' }}>
  //     <span style={{ fontWeight: 'bold' }}>+Embed a 3</span> by pasting URL (e.g. https://www.goo)
  //   </p>
  // );
  const boldText = <span style={{ fontWeight: 'bold' }}>+Embed a video link</span>;
  const placeholder = `+Embed a video link by pasting URL (e.g. https://www.goo..`;
  const isCohealer = currentRole === UserRoles.cohealer;
  const embedLinkError =
    form?.errors &&
    form?.errors?.sessions &&
    form?.errors?.sessions[sessionIndex]?.sessionTimes[sessionTimeIndex]?.embeddedVideoUrl;
  const theme = useTheme();
  const mobileView = useMediaQuery('(max-width: 576px)');
  const { uploadVideo, videoSrc, loading, progress, videoError } = useVideoDurationParse();
  console.log('loading>>>>progress', loading, progress);
  const { getPreRecordedVideoLink, link, loading: loadingPreview } = useVideoPlayer({ sessionTime });
  const [isMustWatchPriorSelfPacedRecords, setIsMustWatchPriorSelfPacedRecords] = useState(false);
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
  const [isFetchingVideoDimensions, setIsFetchingVideoDimensions] = useState(false);
  const makeFullWidthAndHeight = useMediaQuery(theme.breakpoints.down(1200));
  const [previewVideo, setPreviewVideo] = useState(null);
  const [showAudio, setShowAudio] = useState(false);
  const [showAudioSection, setShowAudioSection] = useState(false);
  const [timeStamp, setTimeStamp] = useState(0);
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const videoSource = link ?? videoSrc?.[sessionTimeIndex];
  const VideoPreview =
    sessionTime?.prerecordedSession?.extension?.toLowerCase() == '.pdf' ||
    sessionTime?.prerecordedSession?.extension?.toLowerCase() == '.doc' ||
    sessionTime?.prerecordedSession?.extension?.toLowerCase() == '.docx' ||
    sessionTime?.prerecordedSession?.extension?.toLowerCase() == '.xlsx' ||
    sessionTime?.prerecordedSession?.extension?.toLowerCase() == '.xls' ||
    sessionTime?.prerecordedSession?.extension?.toLowerCase() == '.png' ||
    sessionTime?.prerecordedSession?.extension?.toLowerCase() == '.jpg' ||
    sessionTime?.prerecordedSession?.extension?.toLowerCase() == '.jpeg' ||
    sessionTime?.prerecordedSession?.extension?.toLowerCase() == '.txt'
      ? true
      : false;
  //commented for api call
  useEffect(() => {
    if (showVideo || VideoPreview || isSessionView) {
      if (!link && session?.id && !loadingPreview) {
        getPreRecordedVideoLink({ contributionId: contribution.id, sessionId: session.id });
      }
      if (sessionTime?.videoSource?.type?.includes('image')) {
        if (sessionTime?.prerecordedSession?.documentKeyWithExtension) {
          getPreviewVideoUrl();
        }
      }
    }
  }, [contribution.id, getPreRecordedVideoLink, link, loadingPreview, session, showVideo]);

  useEffect(() => {
    getPreRecordedVideoLink({ contributionId: contribution.id, sessionId: session.id });
    setShowVideo(false);
    setShowAudioSection(false);
    setTimeStamp(0);
  }, [sessionTimeIndex, session.id]);

  useEffect(() => {
    setShowAudioSection(true);
  }, [videoSource, link]);

  useEffect(() => {
    if (loading) {
      form.setFieldValue(`sessions[${sessionIndex}].contentIsUploading`, true);
    } else {
      form.setFieldValue(`sessions[${sessionIndex}].contentIsUploading`, false);
    }
  }, [loading]);

  const isShowVideoPreview =
    !videoError && !loading && (link || Object.keys(videoSrc).length) && (link || progress === 100 || !progress);

  const handleUpload = useCallback(
    files => {
      uploadVideo(files, form, contribution, sessionIndex, sessionTimeIndex);
    },
    [sessionTimeIndex],
  );

  const handleChange = e => {
    const data = e.target.value;
    form.setFieldValue(`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].embeddedVideoUrl`, data);
  };

  const setVideoAsWatched = () => {
    return axiosInstance.post(`/Contribution/UserViewedRecording`, {
      contributionId: contribution?.id,
      sessionTimeId: sessionTime?.id,
      userId: user?.id,
    });
    // .then(() => {
    //   refetch();
    //   // const newSessions = cloneDeep(contribution.sessions);
    //   // const newSessionTime = newSessions[sessionIndex].sessionTimes[sessionTimeIndex];
    //   // if (!newSessionTime?.usersWhoViewedRecording?.includes(user?.id)) {
    //   //   newSessionTime.usersWhoViewedRecording.push(user?.id);
    //   //   newSessions[sessionIndex].sessionTimes[sessionTimeIndex] = newSessionTime;
    //   //   console.info('video new session 1', cloneDeep(newSessions));
    //   //   dispatch(actions.updateContributionActions.success({ sessions: newSessions }));
    //   //   refetch();
    //   // }
    // });
  };

  const handleEmbedLinkClick = link => {
    const allowedPlatform = inAllowedPlatforms(link);
    const addHttps = link => {
      return link.includes('http') || link.includes('https') ? link : 'https://'.concat(link);
    };
    if (!allowedPlatform) {
      window.open(addHttps(link), '_blank');
    }
  };

  useEffect(() => {
    if (sessionTime?.mustWatchPriorSelfPacedRecords && sessionTimeIndex) {
      const isPriorRecordsWatched = session.sessionTimes
        .slice(0, sessionTimeIndex)
        .every(el => el.usersWhoViewedRecording.includes(user.id));

      setIsMustWatchPriorSelfPacedRecords(!isPriorRecordsWatched);
    }
  }, [sessionTime, session, user, setIsMustWatchPriorSelfPacedRecords, sessionTimeIndex]);

  const onPlay = e => {
    const video = videoRef.current;
    video.currentTime = timeStamp;
    video.play();
    // if (
    //   !isCohealer // && !sessionTime?.usersWhoViewedRecording.includes(user?.id)
    // ) {
    //   setVideoAsWatched().then(() => refetch(true));
    // }
  };

  const onPause = e => {
    const video = videoRef.current;
    video.pause();
    setTimeStamp(video.duration && video.currentTime === video.duration ? 0 : video.currentTime);
    if (isSessionView && video.duration && video.currentTime === video.duration) {
      video.load();
    }
    if (!isCohealer && !sessionTime?.usersWhoViewedRecording.includes(user?.id)) {
      setVideoAsWatched().then(refetch);
    }
  };
  const handleReady = player => {
    setIsFetchingVideoDimensions(true);
    const internalPlayer = player.getInternalPlayer();
    if (internalPlayer) {
      const {
        playerInfo: { size },
      } = internalPlayer;
      console.info('dimensions:', size?.videoWidth, size?.videoHeight, cloneDeep(internalPlayer));
      setVideoDimensions({ width: size?.videoWidth, height: size?.videoHeight });
    }
    setIsFetchingVideoDimensions(false);
  };

  if (isPreviewMode) {
    // session view side

    const preRecordedSession =
      sessionTime?.prerecordedSession || session?.prerecordedSession || sessionTime?.embeddedVideoUrl;
    const allowedPlatform = inAllowedPlatforms(sessionTime?.embeddedVideoUrl);

    let isDisabledVideoForClient;
    if (shouldBeDisabled != null) {
      isDisabledVideoForClient = !isCohealer && shouldBeDisabled;
    } else {
      isDisabledVideoForClient =
        !isCohealer &&
        preRecordedSession &&
        (isMustWatchPriorSelfPacedRecords ||
          (sessionTime?.ignoreDateAvailable && checkIsFuture(sessionTime?.startTime)));
    }

    // let isDisabledVideoForClient =
    //   !isCohealer &&
    //   preRecordedSession &&
    //   (shouldBeDisabled ||
    //     isMustWatchPriorSelfPacedRecords ||
    //     (sessionTime?.ignoreDateAvailable && checkIsFuture(sessionTime?.startTime)));
    return (
      <Grid container justifyContent="center" style={{ overflow: 'hidden' }}>
        {sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('png') ? (
          <Image crossorigin="anonymous" src={videoSource} isSessionView={isSessionView} />
        ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpg') ? (
          <Image crossorigin="anonymous" src={videoSource} isSessionView={isSessionView} />
        ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpeg') ? (
          <Image crossorigin="anonymous" src={videoSource} isSessionView={isSessionView} />
        ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('pdf') ? (
          <a
            style={{ cursor: 'pointer' }}
            onClick={e => {
              e.preventDefault();
              window.open(videoSource, '_blank');
            }}
          >
            <DescriptionOutlined
              style={{ fontSize: 40, color: isSessionView && (colorToUse?.AccentColorCode || '#116582') }}
            />
          </a>
        ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('doc') ? (
          <a
            style={{ cursor: 'pointer' }}
            onClick={e => {
              e.preventDefault();
              window.open(videoSource, '_blank');
            }}
          >
            <DescriptionOutlined
              style={{ fontSize: 40, color: isSessionView && (colorToUse?.AccentColorCode || '#116582') }}
            />
          </a>
        ) : (isSessionView && sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('doc')) ||
          (isSessionView && sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('docx')) ||
          (isSessionView && sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('txt')) ||
          (isSessionView && sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xls')) ||
          (isSessionView && sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xlsx')) ? (
          <a
            style={{ cursor: 'pointer' }}
            onClick={e => {
              e.preventDefault();
              window.open(videoSource, '_blank');
            }}
          >
            <DescriptionOutlined
              style={{ fontSize: 40, color: isSessionView && (colorToUse?.AccentColorCode || '#116582') }}
            />
          </a>
        ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('mp3') ||
          sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('wav') ||
          sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('m4a') ||
          sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('ogg') ? (
          isSessionView && showAudioSection && (
            <StyledAudio ref={videoRef} isPreviewMode isSessionView controls onPlay={onPlay} onPause={onPause}>
              {videoSource && <source src={videoSource} />}
            </StyledAudio>
          )
        ) : (
          <>
            {sessionTime?.embeddedVideoUrl ? (
              <ReactPlayerWrapper
                onClick={() => {
                  handleEmbedLinkClick(sessionTime?.embeddedVideoUrl);
                }}
                shouldDim={shouldBeDisabled}
              >
                {' '}
                {allowedPlatform ? (
                  <ReactPlayer
                    url={sessionTime?.embeddedVideoUrl}
                    width={mobileView ? 300 : 850}
                    height={mobileView ? 150 : 500}
                    // width={isSessionView && !isFetchingVideoDimensions ? videoDimensions.width : mobileView ? 300 : 850}
                    // height={
                    //   isSessionView && !isFetchingVideoDimensions ? videoDimensions.height : mobileView ? 150 : 500
                    // }
                    controls={true}
                    playing={false}
                    onStart={() => {
                      if (!isCohealer && !sessionTime?.usersWhoViewedRecording.includes(user?.id)) {
                        setVideoAsWatched();
                        refetch();
                      }
                    }}
                    // {...(isSessionView && { onReady: handleReady })}
                  />
                ) : (
                  <div className={classes.notSupportedLink}>
                    <BlockIcon style={{ width: '100px', height: '100px', color: 'white' }} />
                    <span className={classes.text}>
                      This link is not supported.{' '}
                      <a className={classNames.link} href={addhttp(sessionTime.embeddedVideoUrl)} target="_blank">
                        Click here
                      </a>
                    </span>
                  </div>
                )}
              </ReactPlayerWrapper>
            ) : // ) : isSessionView ? (
            //   <>
            //     {' '}
            //     <div className="position-relative w-100">
            //       <Tooltip
            //         title="Thumbnail"
            //         arrow
            //         enterTouchDelay={TOOLTIP.ENTER_DELAY}
            //         leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            //       >
            //         <StyledUploadThumbnailContainer onClick={() => uploadThumbnail(true)}>
            //           <UploadThumbnailIcon style={{ width: '36px', height: '37px' }} />
            //         </StyledUploadThumbnailContainer>
            //       </Tooltip>
            //       <StyledVideo
            //         ref={videoRef}
            //         controls
            //         isPreviewMode
            //         isSessionView
            //         preload="none"
            //         poster={sessionTime?.selfPacedThumbnailUrl || contribution?.previewContentUrls?.at(0)}
            //         onPlay={onPlay}
            //         onPause={onPause}
            //       >
            //         <source src={videoSource} />
            //       </StyledVideo>
            //     </div>
            //   </>
            showVideo === false ? (
              sessionTime?.selfPacedThumbnailUrl != null ? (
                isSessionView ? (
                  isCohealer ? (
                    <div className="position-relative w-100">
                      <Tooltip
                        title="Thumbnail"
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <StyledUploadThumbnailContainer onClick={() => uploadThumbnail(true)}>
                          <UploadThumbnailIcon style={{ width: '36px', height: '37px' }} />
                        </StyledUploadThumbnailContainer>
                      </Tooltip>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          // height: '435px',
                          height: Boolean(sessionTime?.selfPacedThumbnailUrl) ? 'auto' : '435px',
                          backgroundColor: 'black',
                          maxWidth: '100%',
                        }}
                        onClick={() => {
                          setShowVideo(true);
                          setTimeout(() => {
                            onPlay();
                          }, [2000]);
                        }}
                      >
                        <img
                          src={sessionTime?.selfPacedThumbnailUrl}
                          style={{
                            height: mobileView || Boolean(sessionTime?.selfPacedThumbnailUrl) ? 'auto' : '435px',
                            // width: '70%',
                            maxWidth: '100%',
                          }}
                        />
                        <StyledPlayIconWrapper
                          style={{
                            height: '52.045px',
                            width: '52.045px',
                            color: 'gray',
                            position: 'absolute',
                            zIndex: 10,
                            cursor: 'pointer',
                            borderRadius: '46.263px',
                            background: colorToUse?.AccentColorCode,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <PlayIcon
                            style={{
                              height: '24.152px',
                              width: '24.152px',
                              color: '#FFF',
                              position: 'absolute',
                              zIndex: 15,
                              cursor: 'pointer',
                            }}
                          />
                        </StyledPlayIconWrapper>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // height: '435px',
                        height: Boolean(sessionTime?.selfPacedThumbnailUrl) ? 'auto' : '435px',
                        maxWidth: '100%',
                        backgroundColor: 'black',
                      }}
                      onClick={() => {
                        setShowVideo(true);
                        setTimeout(() => {
                          onPlay();
                        }, [2000]);
                      }}
                    >
                      <img
                        src={sessionTime?.selfPacedThumbnailUrl}
                        style={{
                          height: mobileView || Boolean(sessionTime?.selfPacedThumbnailUrl) ? 'auto' : '435px',
                          // width: '70%',
                          maxWidth: '100%',
                        }}
                      />
                      <StyledPlayIconWrapper
                        style={{
                          height: '52.045px',
                          width: '52.045px',
                          color: 'gray',
                          position: 'absolute',
                          zIndex: 10,
                          cursor: 'pointer',
                          borderRadius: '46.263px',
                          background: colorToUse?.AccentColorCode,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <PlayIcon
                          style={{
                            height: '24.152px',
                            width: '24.152px',
                            color: '#FFF',
                            position: 'absolute',
                            zIndex: 15,
                            cursor: 'pointer',
                          }}
                        />
                      </StyledPlayIconWrapper>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '435px',
                      maxWidth: '100%',
                    }}
                    onClick={() => {
                      setShowVideo(true);
                      setTimeout(() => {
                        onPlay();
                      }, [2000]);
                    }}
                  >
                    <img
                      src={sessionTime?.selfPacedThumbnailUrl}
                      style={{
                        height: mobileView ? 'auto' : '435px',
                        maxWidth: '100%',
                      }}
                    />

                    <PlayArrowIcon
                      style={{
                        height: '100px',
                        width: '100px',
                        color: 'gray',
                        position: 'absolute',
                        zIndex: 10,
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                )
              ) : contribution?.previewContentUrls?.at(0) != null ? (
                isSessionView ? (
                  isCohealer ? (
                    <div className="position-relative w-100">
                      <Tooltip
                        title="Thumbnail"
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <StyledUploadThumbnailContainer onClick={() => uploadThumbnail(true)}>
                          <UploadThumbnailIcon style={{ width: '36px', height: '37px' }} />
                        </StyledUploadThumbnailContainer>
                      </Tooltip>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: mobileView || Boolean(contribution?.previewContentUrls?.at(0)) ? 'auto' : '435px',
                          backgroundColor: 'black',
                          width: '100%',
                        }}
                        onClick={() => {
                          setShowVideo(true);
                          setTimeout(() => {
                            onPlay();
                          }, [2000]);
                        }}
                      >
                        <img
                          src={contribution?.previewContentUrls?.at(0)}
                          style={{
                            height: mobileView || Boolean(contribution?.previewContentUrls?.at(0)) ? 'auto' : '435px',
                            // width: '70%',
                            maxWidth: '100%',
                          }}
                        />
                        <StyledPlayIconWrapper
                          style={{
                            height: '52.045px',
                            width: '52.045px',
                            color: 'gray',
                            position: 'absolute',
                            zIndex: 10,
                            cursor: 'pointer',
                            borderRadius: '46.263px',
                            background: colorToUse?.AccentColorCode,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <PlayIcon
                            style={{
                              height: '24.152px',
                              width: '24.152px',
                              color: '#FFF',
                              position: 'absolute',
                              zIndex: 15,
                              cursor: 'pointer',
                            }}
                          />
                        </StyledPlayIconWrapper>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: Boolean(contribution?.previewContentUrls?.at(0)) ? 'auto' : '435px',
                        backgroundColor: 'black',
                        maxWidth: '100%',
                      }}
                      onClick={() => {
                        setShowVideo(true);
                        setTimeout(() => {
                          onPlay();
                        }, [2000]);
                      }}
                    >
                      <img
                        src={contribution?.previewContentUrls?.at(0)}
                        style={{
                          height: mobileView || Boolean(contribution?.previewContentUrls?.at(0)) ? 'auto' : '435px',
                          maxWidth: '100%',
                        }}
                      />
                      <StyledPlayIconWrapper
                        style={{
                          height: '52.045px',
                          width: '52.045px',
                          color: 'gray',
                          position: 'absolute',
                          zIndex: 10,
                          cursor: 'pointer',
                          borderRadius: '46.263px',
                          background: colorToUse?.AccentColorCode,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <PlayIcon
                          style={{
                            height: '24.152px',
                            width: '24.152px',
                            color: '#FFF',
                            position: 'absolute',
                            zIndex: 15,
                            cursor: 'pointer',
                          }}
                        />
                      </StyledPlayIconWrapper>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: mobileView ? 'auto' : '435px',
                      width: '100%',
                      background: 'black',
                    }}
                    onClick={() => {
                      setShowVideo(true);
                      setTimeout(() => {
                        onPlay();
                      }, [2000]);
                    }}
                  >
                    <img
                      src={contribution?.previewContentUrls?.at(0)}
                      style={{
                        height: mobileView ? 'auto' : '435px',
                        maxWidth: '100%',
                      }}
                    />

                    <PlayArrowIcon
                      style={{
                        height: '100px',
                        width: '100px',
                        color: 'gray',
                        position: 'absolute',
                        zIndex: 10,
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                )
              ) : (
                <div
                  onClick={() => {
                    setShowVideo(true);
                    setTimeout(() => {
                      onPlay();
                    }, [2000]);
                  }}
                  style={{
                    backgroundColor: 'black',
                    height: '435px',
                    width: '850px',
                    backgroundColor: 'black',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    cursor: 'pointer',
                    height: '435px',
                    width: '100%',
                  }}
                >
                  <StyledPlayIconWrapper
                    style={{
                      height: '52.045px',
                      width: '52.045px',
                      color: 'gray',
                      position: 'absolute',
                      zIndex: 10,
                      cursor: 'pointer',
                      borderRadius: '46.263px',
                      background: colorToUse?.AccentColorCode,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <PlayIcon
                      style={{
                        height: '24.152px',
                        width: '24.152px',
                        color: '#FFF',
                        position: 'absolute',
                        zIndex: 15,
                        cursor: 'pointer',
                      }}
                    />
                  </StyledPlayIconWrapper>
                </div>
              )
            ) : (
              <>
                {isSessionView ? (
                  isCohealer ? (
                    <>
                      {' '}
                      <div className="position-relative w-100">
                        <Tooltip
                          title="Thumbnail"
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledUploadThumbnailContainer onClick={() => uploadThumbnail(true)}>
                            <UploadThumbnailIcon style={{ width: '36px', height: '37px' }} />
                          </StyledUploadThumbnailContainer>
                        </Tooltip>
                        <StyledVideo
                          ref={videoRef}
                          controls
                          isPreviewMode
                          isSessionView
                          preload="none"
                          poster={sessionTime?.selfPacedThumbnailUrl}
                          onPlay={onPlay}
                          onPause={onPause}
                          mobileView={mobileView}
                        >
                          <source src={videoSource} />
                        </StyledVideo>
                      </div>
                    </>
                  ) : (
                    <>
                      {' '}
                      <StyledVideo
                        ref={videoRef}
                        controls
                        isPreviewMode
                        isSessionView
                        preload="none"
                        poster={sessionTime?.selfPacedThumbnailUrl}
                        onPlay={onPlay}
                        onPause={onPause}
                        mobileView={mobileView}
                      >
                        <source src={videoSource} />
                      </StyledVideo>
                    </>
                  )
                ) : (
                  <StyledVideo
                    ref={videoRef}
                    shouldDim={shouldBeDisabled}
                    makeFullWidthAndHeight={makeFullWidthAndHeight}
                    isPreviewMode
                    isSessionView
                    controls
                    preload="none"
                    poster={sessionTime?.selfPacedThumbnailUrl}
                    onPlay={onPlay}
                    onPause={onPause}
                    mobileView={mobileView}
                  >
                    {videoSource && <source src={videoSource} />}
                  </StyledVideo>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    );
  }

  const getPreviewVideoUrl = async () => {
    getVideoUrl(sessionTime?.prerecordedSession?.documentKeyWithExtension).then(res => {
      setPreviewVideo(res);
    });
  };
  // const source = `${link ?? videoSrc?.[sessionTimeIndex]}#t=0.001`;

  if (sessionTime?.videoSource) {
    return (
      <>
        {VideoPreview ? (
          sessionTime?.videoSource?.type?.includes('image') ? (
            <Image crossorigin="anonymous" src={previewVideo} />
          ) : (
            <DescriptionOutlined style={{ fontSize: 40 }} />
          )
        ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('mp3') ? (
          showAudio ? (
            isSessionView ? (
              <StyledAudio controls>
                <source src={sessionTime.videoSource} />
              </StyledAudio>
            ) : (
              <StyledVideo controls>
                <source src={sessionTime.videoSource} />
              </StyledVideo>
            )
          ) : (
            <AudioFileOutlinedIcon
              onClick={() => {
                setShowAudio(!showAudio);
              }}
              style={{ fontSize: 40 }}
            />
          )
        ) : (
          <div className="position-relative w-100">
            <Tooltip
              title="Thumbnail"
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <StyledUploadThumbnailContainer onClick={() => uploadThumbnail(true)}>
                <UploadThumbnailIcon style={{ width: '36px', height: '37px' }} />
              </StyledUploadThumbnailContainer>
            </Tooltip>
            <StyledVideo
              ref={videoRef}
              controls
              preload="none"
              poster={sessionTime?.selfPacedThumbnailUrl}
              onPlay={onPlay}
              onPause={onPause}
            >
              <source src={sessionTime.videoSource} />
            </StyledVideo>
          </div>
        )}
      </>
    );
  }

  return (
    <Grid container>
      {!sessionTime?.embeddedVideoUrl && (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Grid
            item
            justify="center"
            className="Session-Page-Dropzone"
            style={{ width: '90%', display: 'flex', flexDirection: 'row' }}
          >
            {isShowVideoPreview && sessionTime?.prerecordedSession ? (
              <>
                {VideoPreview ? (
                  sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpg') ? (
                    <Image crossorigin="anonymous" src={sessionTime.videoSource ?? link} />
                  ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('png') ? (
                    <Image crossorigin="anonymous" src={sessionTime.videoSource ?? link} />
                  ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpeg') ? (
                    <Image crossorigin="anonymous" src={sessionTime.videoSource ?? link} />
                  ) : (
                    <DescriptionOutlined style={{ fontSize: 40 }} />
                  )
                ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('mp3') ? (
                  showAudio ? (
                    <StyledVideo controls>
                      <source src={sessionTime.videoSource ?? link} />
                    </StyledVideo>
                  ) : (
                    <AudioFileOutlinedIcon
                      onClick={() => {
                        setShowAudio(!showAudio);
                      }}
                      style={{ fontSize: 40 }}
                    />
                  )
                ) : showVideo ? (
                  <div className="position-relative w-100">
                    <Tooltip
                      title="Thumbnail"
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <StyledUploadThumbnailContainer onClick={() => uploadThumbnail(true)}>
                        <UploadThumbnailIcon style={{ width: '36px', height: '37px' }} />
                      </StyledUploadThumbnailContainer>
                    </Tooltip>
                    <StyledVideo
                      ref={videoRef}
                      controls
                      preload="none"
                      poster={sessionTime?.selfPacedThumbnailUrl}
                      onPlay={onPlay}
                      onPause={onPause}
                    >
                      <source src={sessionTime.videoSource ?? link} />
                    </StyledVideo>
                  </div>
                ) : sessionTime?.selfPacedThumbnailUrl != null ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px',
                      width: '560px',
                    }}
                    onClick={() => {
                      setShowVideo(true);
                      setTimeout(() => {
                        onPlay();
                      }, [2000]);
                    }}
                  >
                    <img
                      src={sessionTime?.selfPacedThumbnailUrl}
                      style={{
                        height: '435px',
                        width: '70%',
                      }}
                    />

                    {/* <LockIcon style={{ height: '100px',
                        width: '100px',
                        color: 'gray',
                        position: 'absolute',zIndex: 10 }} /> */}
                  </div>
                ) : contribution?.previewContentUrls?.at(0) != null ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px',
                      width: '560px',
                    }}
                    onClick={() => {
                      setShowVideo(true);
                      setTimeout(() => {
                        onPlay();
                      }, [2000]);
                    }}
                  >
                    <img
                      src={contribution?.previewContentUrls?.at(0)}
                      style={{
                        height: '435px',
                        width: '70%',
                      }}
                    />

                    <LockIcon
                      style={{ height: '100px', width: '100px', color: 'gray', position: 'absolute', zIndex: 10 }}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setShowVideo(true);
                    }}
                    style={{
                      backgroundColor: 'black',
                      height: '300px',
                      width: '300px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    <LockIcon
                      style={{ height: '50px', width: '50px', color: 'gray', position: 'absolute', zIndex: 10 }}
                    />
                  </div>
                )}
              </>
            ) : (
              <Dropzone
                action={handleUpload}
                isDisabled={Boolean(duration) || !!sessionTime?.embeddedVideoUrl}
                isSessionPage={true}
                icon={
                  duration ? null : (
                    <span className="add-upload-icon">
                      <AddIcon />
                    </span>
                  )
                }
                loading={loading}
                duration={duration}
                sessionTime={sessionTime}
                placeholder={
                  duration ? (
                    <p>Preview is not available</p>
                  ) : (
                    <p>
                      Drag and drop your <span style={{ fontWeight: 'bold' }}>video, audio, pdf or course</span> content
                      here or click this box to select a file to upload. Max file size is 3GB
                    </p>
                  )
                }
                progress={progress}
                type="video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime,video/*,audio/mp3,audio/*,image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,image/jpeg,image/png,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint"
                StyledUploadThumbnailContainer={StyledUploadThumbnailContainer}
                uploadThumbnail={uploadThumbnail}
                StyledVideoContainer={StyledVideo}
                videoRef={videoRef}
                onPlay={onPlay}
                onPause={onPause}
                setShowVideo={setShowVideo}
                showVideo={showVideo}
              />
            )}
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'end', width: '10%' }}>
            <Tooltip
              title='The maximum file size recommended for all media (Photos, Videos, or Audio) to be uploaded to Cohere is 3GB. Compression tools which can be of help are "Clideo" & "Veed” etc. These tools will let you reduce file sizes if your file is larger than 3GB.'
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <InfoIcon style={{ marginTop: '20px' }} htmlColor={colors.lightBrown} />
            </Tooltip>
          </div>
        </div>
      )}
      {!sessionTime?.prerecordedSession && (
        <Grid
          item
          style={{
            width: '100%',
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '90%' }}>
            <TextField
              fullWidth
              className={classNames.inputField}
              name="link"
              placeholder={placeholder}
              variant="outlined"
              onChange={handleChange}
              value={sessionTime?.embeddedVideoUrl}
              type="text"
              InputProps={{
                style: {
                  backgroundColor: '#f5f5f5',
                  borderRadius: '0px',
                },
                classes: { notchedOutline: classNames.noBorder },
              }}
            />
            {sessionTime?.embeddedVideoUrl && embedLinkError && (
              <div>
                <p style={{ margin: '0px', color: '#9164a2' }}>{embedLinkError}</p>
              </div>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'end', width: '10%' }}>
            <Tooltip
              title={`Allowed platforms are: ${PLATFORMS_ALLOWED.join(
                ', ',
              )}. The links other than these platforms might not work as expected.`}
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <InfoIcon htmlColor={colors.lightBrown} />
            </Tooltip>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

Video.propTypes = {
  duration: PropTypes.string,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }),
  isPreviewMode: PropTypes.bool,
  session: PropTypes.shape({
    id: PropTypes.string,
    prerecordedSession: PropTypes.shape({}),
    sessionTimes: PropTypes.arrayOf(
      PropTypes.shape({
        mustWatchPriorSelfPacedRecords: PropTypes.bool,
        usersWhoViewedRecording: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }).isRequired,
  sessionIndex: PropTypes.number,
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
    prerecordedSession: PropTypes.shape({}),
    startTime: PropTypes.string,
    ignoreDateAvailable: PropTypes.bool,
    mustWatchPriorSelfPacedRecords: PropTypes.bool,
  }),
  sessionTimeIndex: PropTypes.number.isRequired,
  uploadThumbnail: PropTypes.func.isRequired,
};

Video.defaultProps = {
  duration: '',
  form: {
    setFieldValue: () => {},
  },
  isPreviewMode: false,
  sessionIndex: -1,
  sessionTime: null,
};
