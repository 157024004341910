import useContribution from 'pages/ContributionView/hooks/useContribution';
import React from 'react';
import CalendarContributionViewForPaymentCard from '../CalendarContributionViewForPaymentCard/CalendarContributionViewForPaymentCard';
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney';
import { connect } from 'react-redux';

const FreeSingleView = ({ contributionTimes }) => {
  const contribution = useContribution();
  return (
    <div style={{ pointerEvents: 'none' }}>
      <CalendarContributionViewForPaymentCard
        isCodeToJoin={false}
        events={[]}
        onDrillDown={() => {}}
        onSelectEvent={() => {}}
        // dateToShow={dateToShow}
        calendarRef={null}
        rescheduleMode={false}
        timeZoneId={contribution?.timeZoneId}
      />
    </div>
  );
};

const mapStateToProps = ({ contributions }) => ({
  contributionTimes: contributions?.contributionTimes,
  isCustomBrandingColorsActive: contributions?.activeContribution?.isCustomBrandingColorsActive,
  isBrandingColor: contributions?.activeContribution?.brandingColors,
});

export default connect(mapStateToProps)(FreeSingleView);
