import React, { useCallback, useState, useEffect } from 'react';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn, FaTiktok } from 'react-icons/fa';
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import { HiGlobeAlt } from 'react-icons/hi';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Icon } from '@mdi/react';
import MenuIcon from '@mui/icons-material/Menu';
import * as paidTierActions from 'actions/paidTierOptions';
import { mdiHelpCircleOutline, mdiHelpCircle } from '@mdi/js';
import * as paidTierService from 'services/paidTierOptions.service';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Modal from 'components/UI/Modal';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import CoachImage from 'assets/chatlogo.png';
import fbImg from '../../assets/facebook2.png';
import linkedImg from '../../assets/linkedin2.png';
import instaImg from '../../assets/instagram2.png';
import youtube from '../../assets/youtube2.png';
import tiktok from '../../assets/tiktok2.png';
import website from '../../assets/website2.png';
import CloseIcon from '@mui/icons-material/Close';
import * as calendarAction from 'actions/calendars';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import * as R from 'ramda';
import * as countryActions from 'actions/country';
import isEmpty from 'lodash/isEmpty';
import { Drawer, IconButton, MenuItem, Select, useMediaQuery, useTheme } from '@material-ui/core';
import * as pageActions from 'actions/page';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import ContributionHeader from 'pages/ContributionView/components/ContributionHeader';
import { setCohereAcademyStatus } from 'actions/update-user';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import usePaidTier from 'hooks/usePaidTier';
import CohereIcon from 'assets/icon.png';
import BackButton from 'components/UI/BackButton';
import { useShowGettingStartedGuide } from 'components/App/GettingStarted/hooks/useShowGettingStartedGuide';
import SelectingPlanModal from 'components/Modals/SelectingPlanModal';
import TypeOfCourse from 'pages/ContributionView/components/TypeOfCourse';
import PurchaseModalsAndButtons from 'pages/ContributionView/components/PurchaseModalsAndButtons/PurchaseModalsAndButtons';
import BurgerMenu from 'assets/BurgerMenu.svg';
import { useAccount, useApplicationStatus, useRouter, useShallowEqualSelector } from 'hooks';
import { ContributionType, SIGNUP_TYPES, UserRoles, ContributionThemedColors } from 'helpers/constants';
import * as userActions from 'actions/user';
import FreeTrialModal from 'pages/Dashboard/components/CohealerDashboard/FreeTrialModal';
import CountryModal from 'pages/Dashboard/components/CohealerDashboard/CountrySelectionModal';
import moment from 'moment';
import ClickDropDown, { StyledDropDownItem } from './ClickDropDown';
import Button from 'components/FormUI/Button';
import Joyride, {
  CallBackProps,
  STATUS,
  EVENTS,
  Step,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import {
  CONTRIBUTION_COLORS,
  PAID_TIER_BUTTON_LABEL,
  PAID_TIER_TITLES,
  ROUTES,
  TOOLTIP,
  JOIN_CONTRIBUTION_STATUS,
} from '../../constants';
import { PageTitle, PageTitleText } from './Text/TextStyles';
import GettingStartedGuide from '../App/GettingStarted';
import { colors } from '../../utils/styles';
import UpgradeButton from './UpgradeButton';
import { getProfile } from 'actions/user';
import Loader from './Loader';
import { TemplateType } from 'helpers/constants/templateType';
import TemplateOne from 'pages/ContributionView/Templates/TemplateOne';
import './Header.scss';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import * as paidTier from 'selectors/paidTier';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';

const StyledHeaderContainer = styled.div`
  background-color: ${props => (props.themedCardBackgroundColor ? props.themedCardBackgroundColor : 'white')};
  box-shadow: 0 0px 4px 0 rgba(157, 157, 157, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 320px;
  ${({ activeTemplaate }) => {
    return activeTemplaate === 'TemplateTwo' ? `padding: 20px 55px 20px;` : `padding: 20px 55px 20px 20px;`;
  }};
  ${({ mobileView }) => {
    return mobileView && `padding: 15px 20px; `;
  }};

  ${({ fixedHeader }) => {
    return (
      fixedHeader &&
      `
      position: sticky;
      top: 0;
      z-index: 100;  
    `
    );
  }}

  ${({ noShadow }) => {
    return noShadow && `box-shadow: none;`;
  }}
`;

const StyledBetaTag = styled.span`
  font-family: Avenir;
  font-size: 13px;
  font-style: italic;
  font-weight: 800;
  line-height: 16px;
  border-radius: 4px;
  padding: 2px 6px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#215c73')};
  color: white;
  margin-left: 5px;
`;

const StyledUserSectionContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ tabletView }) => tabletView && `margin-right: -20px;`};
`;

const StyledFaFacebookF = styled(FaFacebookF)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;
`;
const StyledFaLinkedinIn = styled(FaLinkedinIn)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;
`;
const StyledAiFillInstagram = styled(AiFillInstagram)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;
`;
const StyledAiFillYoutube = styled(AiFillYoutube)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;
`;

const StyledHiGlobeAlt = styled(HiGlobeAlt)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;
`;

const StyledFaTiktok = styled(FaTiktok)`
  color: #6999ca;
  height: 25px;
  width: 25px;
  margin: 0px 5px;
`;

const HeaderIcon = styled.img`
  margin-right: 20px;
  vertical-align: middle;
  object-fit: contain;
  width: 75px;
  height: auto;
  ${({ mobileView }) => {
    return mobileView
      ? css`
          width: 50px;
          min-height: 50px;
          max-height: 50px;
        `
      : css`
          width: 75px;
          min-height: 75px;
          max-height: 75px;
        `;
  }};
`;
const HeaderIconLogo = styled.img`
  margin-right: 20px;
  vertical-align: middle;
  object-fit: contain;
  width: 90px;
  height: auto;
`;

const StyledButton = styled(Button)`
  margin-left: 20px;
  min-width: 128px;
  // max-width: 150px;
  max-width: 140px;

  ${({ mobileView, hideBtn }) =>
    mobileView &&
    hideBtn &&
    `
  margin-left:0px;
  min-width:   246px;
  max-width: 350px ;
  `}
  ${({ backgroundColor, color }) =>
    backgroundColor &&
    `
  color: ${color};
  background-color: ${backgroundColor};
`}
`;

const HyperLink = styled.a.attrs({ target: '_blank' })``;

const TitleAndMobileMenuWrapper = styled.div`
  display: flex;
  ${({ mobileView }) => mobileView && `align-items: baseline;`};
`;

const MobileMenuWrapper = styled.div`
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
`;

const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 24px;
        height: 24px;
        font-size: 0.7rem;
      `
    );
  }}
`;

const StyledIcon = styled(Icon)`
  width: 22.5px;
  height: 22.5px;
  margin-left: 20px;
  color: ${({ color }) => color};
  ${({ mobileView }) => mobileView && `width: 6px; height: 2px;`};
`;
const MockBlock = styled.div`
  width: 200px;
`;

const RightLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const useStyles = makeStyles(theme => ({
  upgradeButton: {
    border: 'none',
    borderRadius: 6,
    width: 125,
    height: 33,
    marginRight: 15,
    color: colors.white,
    fontWeight: 500,
    background: colors.darkOceanBlue,
    transition: 'all 0.25s',
    '&:hover': {
      background: colors.darkBlue,
      textDecoration: 'none',
      color: colors.white,
    },
    [theme.breakpoints.down('sm')]: {
      width: 73,
      marginRight: 0,
      fontSize: 10,
      padding: '0px',
      marginRight: -16,
      '&>svg': {
        width: '0.9rem !important',
        height: '0.9rem !important',
      },
    },
  },
  disableMe: {
    pointerEvents: 'none !important',
    cursor: 'not-allowed !important',
    color: colors.white,
    background: colors.backgroundGrey,
    border: 'none',
    borderRadius: 6,
    width: 125,
    height: 33,
    marginRight: 15,
    fontWeight: 500,
    transition: 'all 0.25s',
  },
}));

const StyledLink = styled(NavLink)`
  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const StyledSupportLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
  font-size: 16px;
  &:hover {
    color: ${colors.darkOceanBlue};
  }
`;

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledAccountBillingLink = styled(NavLink)`
  position: absolute;
  overflow: hidden;
  color: ${colors.gray};
  font-weight: initial;
  font-size: 15px;
  @media (max-width: 1183px) {
    margin-left: 25px;
  }
  @media (max-width: 995px) {
    margin-left: 5px;
  }
  @media (max-width: 560px) {
    margin-left: 7px;
  }
  margin-left: 20px;
  ${({ tabletView }) => tabletView && `font-size: 13px;`};
  ${({ mobileView }) => mobileView && `font-size: 12px;`};

  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const CalendarViewDrawerRow = styled.div`
  font-size: 22px;
  font-weight: 400;
  font-family: Avenir;
  color: #5f5f5f;
  border-radius: 8px;
  padding: 12px;
  margin: 10px;

  &.active {
    background: #eeeeee;
  }
`;

const isPathForContribution = path => path === '/contribution-view/:id' || path === '/apply-contribution-view/:id';
const isPathForContributionSessions = pathname => pathname.includes('sessions');
const isPathForCohealerAbout = path => path === '/cohealer-about/:cohealerId';
const isPathForCreateCampaign = path => path.includes('create-campaign');
const isPathForCreateOrEditCampaign = path => path.includes('create-campaign') || path.includes('edit-campaign');

export const getInitialSymbol = R.compose(R.toUpper, R.head);
const TRIAL_PERIOD_START_DATE = moment('2021-11-01');
const TRIAL_PERIOD_DAYS = 13;
const viewTabButtonsData = ['Day', 'Week', 'Month'];

function Header({
  user,
  title,
  headerTitle,
  noShadow,
  contribution,
  isCustomPrevButton,
  backButtonText,
  handleBackClick,
  children,
  view,
  countryLoading,
  countries,
  fetchCountries,
  isPurchased,
  backLink,
  previewPage,
  thankYouPageClient,
  calendarActiveView,
}) {
  let colorToUse = determineColorToUse(contribution);
  const instagramUrl = contribution?.instagramUrl;
  const linkedInUrl = contribution?.linkedInUrl;
  const youtubeUrl = contribution?.youtubeUrl;
  const facebookUrl = contribution?.facebookUrl;
  const websiteUrl = contribution?.websiteUrl;
  const tiktokUrl = contribution?.tiktokUrl;
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { subdomain, domain } = useRouter();
  const history = useHistory();
  const { pathname } = useRouter();
  const isOneToOne = contribution?.type === ContributionType.contributionOneToOne;
  const dispatch = useDispatch();
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  // const { isLaunchPlan } = usePaidTier();
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  const [openDropdown, setOpenDopdown] = useState(false);

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);
  useEffect(() => {
    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
    });
  }, []);
  const { showCoachDashboard } = useSelector(state => state.page);
  const { applicationRequiredButNotApproved } = useApplicationStatus({ contribution, user });
  const theme = useTheme();
  const { currentRole, user: signupType, loggedInUser, roles } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const isCoachAndClientRolesAvailable = roles.includes(UserRoles.client) && roles.includes(UserRoles.cohealer);
  const activeTemplaate = contribution?.activeTemplate || TemplateType.TemplateThree;

  // const activeTemplaate = "templatetwo";
  const isContributionPurchased = contribution?.isPurchased;
  const { status } = useContribution();
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down(993));
  const xsView = useMediaQuery(theme.breakpoints.down('xs'));
  const tabletView = useMediaQuery(theme.breakpoints.down('md'));
  const mobileView = view || xsView;
  const [supportPopup, setSupportPopup] = useState(false);
  const [run, setRun] = useState(false);
  const [currentCalendarView, setCurrentCalendarView] = useState('');
  let joyRideHelpers;

  useEffect(() => {
    setCurrentCalendarView(calendarActiveView);
  }, [calendarActiveView]);

  const [steps, setSteps] = useState([
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step0">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: CONTRIBUTION_COLORS.PrimaryColorCode,
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Click Account
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Inside your account, you’ll find your profile, services website, sales information, billing, integrations
            and more!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                // updateUserF();
                history.push('/account/profile');
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
                history.push('/account/profile');
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#my-first-step',
    },
  ]);

  const closeDropdown = () => {
    setOpenDopdown(false);
  };

  const updateUserF = async () => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        IsProfileUploaded: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
    });
    joyRideHelpers.skip();
    setSteps([]);
    setRun(false);
  };

  const {
    themedColor,
    themedBackgroundColor,
    themedCardBackgroundColor,
    newThemedBackgroundColor,
    newThemedTextColor,
  } = getThemedColors(contribution);

  const clientPreviewMode = pathname?.includes('clientpreview');
  const coachCanAccess = currentRole === UserRoles.cohealer && !clientPreviewMode;
  const canAccess = coachCanAccess || (currentRole === UserRoles.client && isContributionPurchased);

  const toggleMobilesidebar = () => {
    dispatch(pageActions.toggleMobileSidebar());
  };

  useEffect(() => {
    if ((!countries || countries.length === 0) && !countryLoading) {
      fetchCountries();
    }
  }, [countries, fetchCountries]);

  useEffect(() => {
    if (!!user?.userProgressbarData?.IsProfileUploaded) {
      setSteps([]);
    }
    // if (isCoach && path?.includes('dashboard') && isLaunchPlan == true) {
    //   setRun(true);
    // }
  }, []);
  const onBackClick = () => {
    if (window.location.href.includes('contribution-view')) {
      redirectToAppIfIsCoachDomain(domain, '/dashboard');
    } else if (window.location.href.includes('create-campaign')) {
      history.goBack();
    } else {
      history.goBack();
    }
  };

  const onBackClickForClientPreview = () => {
    if (backLink) {
      redirectToAppIfIsCoachDomain(domain, `${backLink}`);
    } else {
      redirectToAppIfIsCoachDomain(domain, `/contribution-view/${contribution?.id}/about`);
    }
  };

  const { isOpen, onClose, setIsOpen } = useShowGettingStartedGuide();
  const [isOpenStartedGuide, setIsOpenStartedGuide] = useState(false);
  const countrypopup = !user?.countryId;

  // const countrypopup = false;

  const handleOpenGetStartedGuide = useCallback(() => {
    setSupportPopup(false);
    // setIsOpenStartedGuide(true);
    window.open('https://calendly.com/d/4f2-5gx-w4n/1-1-cohere-kickstart-launch');
  }, [setIsOpenStartedGuide]);

  const handlehowToVideos = ({}) => {
    // window.open('https://www.cohere.live/how-to-library/', '_blank');
    window.open('https://cohere2298.zendesk.com/hc/en-us', '_blank');
  };
  const handleSubmit = useCallback(() => {
    setIsOpenStartedGuide(true);
    setIsOpen(false);
  }, [setIsOpenStartedGuide, setIsOpen]);

  const closeHandler = useCallback(() => {
    setIsOpenStartedGuide(false);
    onClose();
  }, [setIsOpenStartedGuide, onClose]);

  const modalButtons =
    currentRole === UserRoles.cohealer
      ? [
          { buttonTitle: 'Book Onboarding', onClick: handleOpenGetStartedGuide },
          { buttonTitle: 'Help Desk Articles', onClick: handlehowToVideos },
        ]
      : [];

  const isDashboardOrAccountPage = useCallback(() => {
    return pathname.includes(ROUTES.ACCOUNT) || ROUTES.DASHBOARD === pathname;
  }, [pathname]);

  const downXs = useMediaQuery(theme.breakpoints.down('xs'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  const shouldHideTitle = !downXs && downSm && downMd && downLg;
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const todayDate = moment(moment());

  const shouldBeDisabled =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_A;
  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };
  console.log('isLaunchPlan------------>', isLaunchPlan);
  return (
    <>
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        // stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: CONTRIBUTION_COLORS.AccentColorCode,
            zIndex: 10000000,
          },
        }}
      />
      <StyledHeaderContainer
        noShadow={noShadow}
        mobileView={isMobileSidebarNeeded}
        fixedHeader={!isCoach || clientPreviewMode}
        themedCardBackgroundColor={
          pathname.includes('/contribution-view') ||
          pathname.includes('/apply-contribution-view') ||
          pathname.includes('/thankyoupreviewpage')
            ? isCoach
              ? themedCardBackgroundColor
              : newThemedBackgroundColor
            : null
        }
      >
        {pathname.includes('/thankyoupreviewpage') === true ? (
          <Helmet>
            <title>Preview Page - Cohere</title>
          </Helmet>
        ) : (
          <Helmet>
            <title>{title} - Cohere</title>
          </Helmet>
        )}
        <div
          style={
            mobileView
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  width: mobileView ? '62%' : '53%',
                  color: isCoach ? themedColor : newThemedTextColor,
                  backgroundColor:
                    isCustomPrevButton || previewPage
                      ? isCoach
                        ? themedCardBackgroundColor
                        : newThemedBackgroundColor
                      : 'white',
                }
              : {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: mobileView ? '62%' : activeTemplaate === TemplateType.TemplateTwo ? '53%' : '62%',
                  color: isCoach ? themedColor : newThemedTextColor,
                  backgroundColor:
                    isCustomPrevButton || previewPage
                      ? isCoach
                        ? themedCardBackgroundColor
                        : newThemedBackgroundColor
                      : 'white',
                }
          }
        >
          <TitleAndMobileMenuWrapper mobileView={mobileView}>
            {isMobileSidebarNeeded &&
              !(isPathForContribution(path) && contribution?.type) &&
              !isPathForCohealerAbout(path) &&
              pathname.includes('/thankyou') === false && (
                <MobileMenuWrapper onClick={toggleMobilesidebar}>
                  <img alt="burger-menu" src={BurgerMenu} />
                </MobileMenuWrapper>
              )}

            {isMobileSidebarNeeded &&
              isPathForContribution(path) &&
              (!isCoach || clientPreviewMode) &&
              activeTemplaate != TemplateType.TemplateThree && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {!isEmpty(user) && !clientPreviewMode && (
                    <>
                      <ArrowBackIosIcon
                        onClick={onBackClick}
                        style={{ color: isCoach ? themedColor : newThemedTextColor }}
                        fontSize="small"
                      />
                    </>
                  )}
                  {!isPurchased && (
                    <MobileMenuWrapper onClick={toggleMobilesidebar}>
                      {/* <img alt="burger-menu" src={BurgerMenu} /> */}
                      <MenuIcon style={{ color: colorToUse.PrimaryColorCode }} />
                    </MobileMenuWrapper>
                  )}
                  {activeTemplaate === TemplateType.TemplateOne && (
                    <HeaderIcon
                      mobileView={mobileView}
                      src={
                        contribution?.customLogo === null || contribution?.customLogo.length === 0
                          ? CohereIcon
                          : contribution.customLogo
                      }
                    />
                  )}
                </div>
              )}
            {/* {!isEmpty(user) && !tabletView && !mobileView && <MockBlock />} */}
            {isCustomPrevButton ? (
              <div className="d-flex align-items-center">
                {/* This View is for coach */}
                {/* This View is also for client with template three */}
                {!mobileView &&
                  !shouldHideTitle &&
                  ((isCoach && !clientPreviewMode) || activeTemplaate === TemplateType.TemplateThree) && (
                    <div className="d-flex align-items-center">
                      {!isEmpty(user) && !clientPreviewMode && (
                        <>
                          <ArrowBackIosIcon
                            fontSize="small"
                            style={{ color: isCoach ? themedColor : newThemedTextColor, cursor: 'pointer' }}
                            onClick={handleBackClick ? handleBackClick : onBackClick}
                          />
                          <div
                            className="h6 mr-3 cursor-pointer"
                            onClick={handleBackClick ? handleBackClick : onBackClick}
                            onKeyPress={null}
                            role="button"
                            tabIndex={0}
                            style={{ color: isCoach ? themedColor : newThemedTextColor }}
                          >
                            {backButtonText}
                          </div>
                        </>
                      )}
                      {!isPathForCreateCampaign(path) &&
                        (contribution?.customLogo === null ||
                        contribution?.customLogo?.length === 0 ||
                        contribution === null ? (
                          <HeaderIcon mobileView={mobileView} src={CohereIcon} />
                        ) : (
                          <HeaderIcon mobileView={mobileView} src={contribution?.customLogo} />
                        ))}

                      <div>
                        <p className="h3 mb-0 font-weight-light d-flex align-items-center">
                          {headerTitle || title}{' '}
                          {isPathForCreateOrEditCampaign(path) ? <StyledBetaTag>Beta</StyledBetaTag> : ''}
                        </p>
                        {isPathForContribution(path) &&
                          currentRole === UserRoles.cohealer &&
                          isLaunchPlan &&
                          trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
                          todayDate.isAfter(trialPeriodEnd) && (
                            <p className="h5 text-muted font-weight-light">
                              Please upgrade your account to invite clients
                            </p>
                          )}
                      </div>
                    </div>
                  )}

                {/* This View is for client with template one */}
                {!mobileView &&
                  !shouldHideTitle &&
                  (!isCoach || clientPreviewMode) &&
                  activeTemplaate === TemplateType.TemplateOne && (
                    <div className="d-flex align-items-center">
                      {!isEmpty(user) && !clientPreviewMode && (
                        <>
                          <ArrowBackIosIcon
                            style={{
                              fontFamily: 'Brandon Text',
                              fontStyle: 'normal',
                              fontWeight: '420',
                              fontSize: '18px',
                              color: isCoach ? themedColor : newThemedTextColor,
                            }}
                            fontSize="small"
                          />
                          <div
                            className="h6 mr-3 cursor-pointer"
                            onClick={onBackClick}
                            onKeyPress={null}
                            role="button"
                            tabIndex={0}
                            style={{
                              fontFamily: 'Brandon Text',
                              fontStyle: 'normal',
                              fontWeight: '420',
                              fontSize: '18px',
                              color: isCoach ? themedColor : newThemedTextColor,
                            }}
                          >
                            {backButtonText}
                          </div>
                        </>
                      )}

                      {contribution?.activeTemplate === 'TemplateThree' ? (
                        <HeaderIcon mobileView={mobileView} src={CohereIcon} />
                      ) : contribution?.customLogo === null || contribution?.customLogo.length === 0 ? (
                        <HeaderIcon mobileView={mobileView} src={CohereIcon} />
                      ) : (
                        <HeaderIcon mobileView={mobileView} src={contribution?.customLogo} />
                      )}

                      <div>
                        {/* <p className="h3 mb-0 font-weight-light">{title}</p> */}
                        <ContributionHeader
                          isCoach={isCoach && !clientPreviewMode}
                          canAccess={canAccess}
                          // onShareButtonClick={handleShareButtonClick} Client can't see the sharebtn so it's not needed
                          status={status}
                        />
                      </div>
                    </div>
                  )}

                {/* This View is for client with template two */}
                {!mobileView &&
                  !shouldHideTitle &&
                  (!isCoach || clientPreviewMode) &&
                  activeTemplaate === TemplateType.TemplateTwo && (
                    <div className="d-flex align-items-center">
                      {!isEmpty(user) && !clientPreviewMode && (
                        <>
                          <ArrowBackIosIcon
                            style={{
                              color: isCoach ? themedColor : newThemedTextColor,
                              fontFamily: 'Brandon Text',
                              fontStyle: 'normal',
                              fontWeight: '420',
                              fontSize: '18px',
                            }}
                            fontSize="small"
                          />
                          <div
                            className="h6 mr-3 cursor-pointer"
                            onClick={onBackClick}
                            onKeyPress={null}
                            role="button"
                            tabIndex={0}
                            style={{
                              color: isCoach ? themedColor : newThemedTextColor,
                              fontFamily: 'Brandon Text',
                              fontStyle: 'normal',
                              fontWeight: '420',
                              fontSize: '18px',
                            }}
                          >
                            {backButtonText}
                          </div>
                        </>
                      )}
                      <ContributionHeader
                        isCoach={isCoach && !clientPreviewMode}
                        canAccess={canAccess}
                        // onShareButtonClick={handleShareButtonClick} Client can't see the sharebtn so it's not needed
                        status={status}
                      />
                      <div>
                        {/* <p className="h3 mb-0 font-weight-light">{title}</p> */}
                        {/* <HeaderIcon mobileView={mobileView}  src={CohereIcon} /> */}
                      </div>
                    </div>
                  )}
              </div>
            ) : (
              <PageTitle
                mobileView={mobileView}
                style={
                  isCustomPrevButton || previewPage
                    ? {
                        color: isCoach ? themedColor : newThemedTextColor,
                      }
                    : {}
                }
              >
                {!mobileView && isPathForContribution(path) && contribution?.type && (
                  <>
                    <BackButton type={contribution.type} color={colorToUse.PrimaryColorCode} />
                    {!isMobileSidebarNeeded && <HeaderIcon mobileView={mobileView} src={CohereIcon} />}
                  </>
                )}
                {/* {pathname.includes('/thankyoupreviewpage') && (
                  <>
                    <ArrowBackIosIcon
                      style={{
                        color: isCoach ? themedColor : newThemedTextColor,
                        fontFamily: 'Brandon Text',
                        fontStyle: 'normal',
                        fontWeight: '420',
                        fontSize: '18px',
                      }}
                      fontSize="small"
                    />
                    <div
                      className="h6 mr-3 cursor-pointer"
                      onClick={onBackClick}
                      onKeyPress={null}
                      role="button"
                      tabIndex={0}
                      style={{
                        color: isCoach ? themedColor : newThemedTextColor,
                        fontFamily: 'Brandon Text',
                        fontStyle: 'normal',
                        fontWeight: '420',
                        fontSize: '18px',
                      }}
                    >
                      Back
                    </div>
                  </>
                )} */}
                <PageTitleText
                  mobileView={mobileView}
                  style={isCustomPrevButton || previewPage ? { color: isCoach ? themedColor : newThemedTextColor } : {}}
                >
                  {pathname.includes('thankyoupreviewpage') === false &&
                    (!(isPathForContribution(path) && contribution?.type) || !isMobileSidebarNeeded) &&
                    title}
                </PageTitleText>
              </PageTitle>
            )}
          </TitleAndMobileMenuWrapper>

          {pathname.includes('calendar/master') && mobileView && (
            <>
              <Select
                variant="outlined"
                displayEmpty
                className="calender-current-view-select"
                value={currentCalendarView}
                MenuProps={{
                  PaperProps: {
                    style: { fontFamily: 'Avenir' },
                  },
                }}
                onChange={() => {}}
              >
                {viewTabButtonsData.map(value => (
                  <MenuItem
                    value={value}
                    onClick={() => {
                      setCurrentCalendarView(value);
                      dispatch(calendarAction.setCurrentCalendarView.request(value));
                    }}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {/* <div className="calender-current-view-select-button" onClick={() => setShowCalendarViewDrawer(true)}>
                <span>{currentCalendarView}</span>
                <svg
                  viewBox="0 0 24 24"
                  role="presentation"
                  style={{
                    verticalAlign: 'centre',
                    width: '1.25rem',
                    height: '1.25rem',
                    marginLeft: '25px',
                    marginTop: '3px',
                  }}
                >
                  <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                </svg>
              </div>
              <Drawer
                className="calendar-drawer"
                anchor="left"
                open={showCalendarViewDrawer}
                onBlur={() => setShowCalendarViewDrawer(false)}
              >
                <div style={{ marginLeft: 'auto', marginBottom: '20px' }}>
                  <IconButton onClick={() => setShowCalendarViewDrawer(false)} className="p-0">
                    <CloseIcon />
                  </IconButton>
                </div>
                <div>
                  {viewTabButtonsData.map(value => (
                    <CalendarViewDrawerRow
                      className={value === currentCalendarView ? 'active' : ''}
                      onClick={() => {
                        setCurrentCalendarView(value);
                        setShowCalendarViewDrawer(false);
                        dispatch(calendarAction.setCurrentCalendarView.request(value));
                      }}
                    >
                      {' '}
                      {value}{' '}
                    </CalendarViewDrawerRow>
                  ))}
                </div>
              </Drawer> */}
            </>
          )}

          {/* Logo in center for client with template two */}
          {pathname.includes('/contribution-view') || pathname.includes('/apply-contribution-view') ? (
            (!isCoach || clientPreviewMode) &&
            activeTemplaate == TemplateType.TemplateTwo &&
            (contribution?.customLogo === null || contribution?.customLogo.length === 0 ? (
              <HeaderIcon mobileView={mobileView} src={CohereIcon} />
            ) : (
              <HeaderIcon mobileView={mobileView} Logo src={contribution.customLogo} />
            ))
          ) : (
            <></>
          )}
          {pathname.includes('/thankyou') === true && pathname.includes('thankyoupreviewpage') === false && (
            <HeaderIcon mobileView={mobileView} src={CohereIcon} />
          )}
          {pathname.includes('/thankyou') === true && pathname.includes('thankyoupreviewpage') === true && (
            <HeaderIcon mobileView={mobileView} src={CohereIcon} />
          )}
        </div>

        <StyledUserSectionContainer
          tabletView={tabletView}
          style={isCustomPrevButton || previewPage ? { color: isCoach ? themedColor : newThemedTextColor } : {}}
        >
          {/* Social Links for template two  */}
          {!isMobileSidebarNeeded &&
            (!isCoach || clientPreviewMode) &&
            activeTemplaate === TemplateType.TemplateTwo &&
            contribution?.type != ContributionType.contributionOneToOne &&
            (facebookUrl || linkedInUrl || instagramUrl || youtubeUrl || websiteUrl || tiktokUrl) &&
            pathname.includes('/contribution-view' || pathname.includes('/apply-contribution-view')) === true && (
              <></>
              // <RightLinks>
              //   {facebookUrl && (
              //     <HyperLink href={facebookUrl}>
              //       {/* <img style={{ padding: '0px 5px' }} src={fbImg}></img> */}
              //       <StyledFaFacebookF />
              //     </HyperLink>
              //   )}
              //   {linkedInUrl && (
              //     <HyperLink href={linkedInUrl}>
              //       {/* <img style={{ padding: '0px 5px' }} src={linkedImg}></img> */}
              //       <StyledFaLinkedinIn />
              //     </HyperLink>
              //   )}
              //   {instagramUrl && (
              //     <HyperLink href={instagramUrl}>
              //       {/* <img style={{ padding: '0px 5px', width: '40px', height: '30px' }} src={instaImg}></img> */}
              //       <StyledAiFillInstagram />
              //     </HyperLink>
              //   )}
              //   {youtubeUrl && (
              //     <HyperLink href={youtubeUrl}>
              //       {/* <img style={{ padding: '0px 5px' }} src={youtube}></img> */}
              //       <StyledAiFillYoutube />
              //     </HyperLink>
              //   )}
              //   {websiteUrl && (
              //     <HyperLink href={websiteUrl}>
              //       {/* <img style={{ padding: '0px 5px' }} src={website}></img> */}
              //       <StyledHiGlobeAlt />
              //     </HyperLink>
              //   )}
              //   {tiktokUrl && (
              //     <HyperLink href={tiktokUrl}>
              //       {/* <img style={{ padding: '0px 5px' }} src={tiktok}></img> */}
              //       <StyledFaTiktok />
              //     </HyperLink>
              //   )}
              // </RightLinks>
            )}

          {isPathForContribution(path) &&
            ((!(isOneToOne && activeTemplaate === TemplateType.TemplateOne) &&
              !(isOneToOne && activeTemplaate === TemplateType.TemplateTwo) &&
              !(isOneToOne && activeTemplaate === TemplateType.TemplateThree && isMobileSidebarNeeded)) ||
              applicationRequiredButNotApproved) && (
              <TypeOfCourse
                contribution={contribution}
                handleUrlModal
                mobileView={mobileView}
                clientPreviewMode={clientPreviewMode}
              />
            )}
          {!isMobileSidebarNeeded &&
            isPathForContribution(path) &&
            (!isPathForContributionSessions(pathname) || contribution.isPurchased) &&
            !(isOneToOne && activeTemplaate === TemplateType.TemplateOne) &&
            !(isOneToOne && activeTemplaate === TemplateType.TemplateTwo) && (
              <PurchaseModalsAndButtons contribution={contribution} clientPreviewMode={clientPreviewMode} />
            )}
          {isPathForContribution(path) && isEmpty(user) && (
            <>
              <AccountCircleIcon style={{ color: '#C7C7C7', fontSize: '50px' }} />
              <ClickDropDown id="already-purchased-dropdown" title={user.firstName} mobileView={mobileView}>
                <StyledDropDownItem
                  style={
                    isCustomPrevButton
                      ? {
                          backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                          color: isCoach ? themedColor : newThemedTextColor,
                        }
                      : {}
                  }
                  onClick={() => {
                    redirectToAppIfIsCoachDomain(domain, pathname, 'isPurchased=true');
                  }}
                >
                  Already a member?
                </StyledDropDownItem>
              </ClickDropDown>
            </>
          )}

          {currentRole === UserRoles.cohealer && isLaunchPlan && isDashboardOrAccountPage() && (
            <div className={shouldBeDisabled && 'disableMe'}>
              <StyledLink className={shouldBeDisabled && 'disableMe'} to="/account/billing/coach">
                <UpgradeButton
                  disable={shouldBeDisabled}
                  buttonLabel={PAID_TIER_BUTTON_LABEL.upgrade}
                  style={shouldBeDisabled ? classes.disableMe : classes.upgradeButton}
                  starIcon
                />
              </StyledLink>
              {/* <div>
              <StyledAccountBillingLink
                className={shouldBeDisabled && 'disableMe'}
                mobileView={mobileView}
                tabletView={tabletView}
                to="/account/billing/coach"
              >
                Trial Account
              </StyledAccountBillingLink>
            </div> */}
            </div>
          )}

          {!isEmpty(user) ? (
            <>
              {children && <div style={{ marginRight: '20px' }}>{children}</div>}
              {(isCoach ||
                activeTemplaate === TemplateType.TemplateThree ||
                contribution?.type === ContributionType.contributionOneToOne) &&
                !clientPreviewMode &&
                !clientPreviewMode && (
                  <Tooltip
                    className="cursor-pointer text-grey"
                    disableHoverListener={shouldBeDisabled}
                    disableClickListener={shouldBeDisabled}
                    title="Help"
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <StyledIcon
                      path={mdiHelpCircle}
                      // size={mobileView ? 0.7 : 1.25}
                      color="#215C73"
                      onClick={() => {
                        if (!shouldBeDisabled) {
                          setSupportPopup(true);
                        }
                      }}
                    />
                  </Tooltip>
                )}
              {!isCoach &&
                !clientPreviewMode &&
                activeTemplaate != TemplateType.TemplateThree &&
                contribution?.type != ContributionType.contributionOneToOne && (
                  <div
                    style={
                      isCustomPrevButton || previewPage ? { color: isCoach ? themedColor : newThemedTextColor } : {}
                    }
                  >
                    Hello! {user.firstName}
                  </div>
                )}
              <StylesProvider injectFirst>
                {!clientPreviewMode ? (
                  <AvatarComponent
                    className="mx-2"
                    alt={`${user.firstName} ${user.lastName}`}
                    src={user.avatarUrl}
                    mobileView={mobileView}
                    onClick={e => {
                      e.stopPropagation();
                      setOpenDopdown(!openDropdown);
                      if (!openDropdown) {
                        window.addEventListener('click', closeDropdown);
                      } else {
                        window.removeEventListener('click', closeDropdown);
                      }
                    }}
                    classes={{
                      root: 'account_img_avatar_root',
                    }}
                  >
                    {`${user.firstName && getInitialSymbol(user.firstName)}${
                      user.lastName && getInitialSymbol(user.lastName)
                    }`}
                  </AvatarComponent>
                ) : (
                  <AccountCircleIcon
                    style={{ color: '#C7C7C7', fontSize: '50px', cursor: 'pointer' }}
                    onClick={e => {
                      e.stopPropagation();
                      setOpenDopdown(!openDropdown);
                      if (!openDropdown) {
                        window.addEventListener('click', closeDropdown);
                      } else {
                        window.removeEventListener('click', closeDropdown);
                      }
                    }}
                  />
                  // <AvatarComponent
                  //
                  //   alt={`${user.firstName} ${user.lastName}`}
                  //   src=""
                  //   mobileView={mobileView}
                  // />
                )}
              </StylesProvider>
              {/* {(isCoach || activeTemplaate === TemplateType.TemplateThree || contribution?.type === ContributionType.contributionOneToOne) && */}
              <ClickDropDown
                id="header-user-dropdown"
                title="Account"
                mobileView={mobileView}
                layover={run}
                style={isCustomPrevButton ? { color: isCoach ? themedColor : newThemedTextColor } : {}}
                isCoach={isCoach}
                isDisable={isLaunchPlan === false}
                clientPreviewMode={clientPreviewMode}
                isPathForContribution={isPathForContribution(path)}
                isOpenNow={openDropdown}
                icon=""
                buttonClassName="account_btn_title"
              >
                {' '}
                {shouldBeDisabled && currentRole === UserRoles.cohealer ? (
                  <StyledDropDownItem
                    style={
                      isCustomPrevButton || previewPage
                        ? {
                            backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                            color: isCoach ? themedColor : newThemedTextColor,
                          }
                        : {}
                    }
                    className={'disableMe'}
                    to={'#'}
                  >
                    Account
                  </StyledDropDownItem>
                ) : null}
                {!shouldBeDisabled || (shouldBeDisabled && currentRole != UserRoles.cohealer) ? (
                  <StyledDropDownItem
                    style={
                      isCustomPrevButton || previewPage
                        ? {
                            backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                            color: isCoach ? themedColor : newThemedTextColor,
                          }
                        : {}
                    }
                    className={
                      ((shouldBeDisabled && currentRole === UserRoles.cohealer) ||
                        (isLaunchPlan && currentRole === UserRoles.cohealer && user.isPartnerCoach === false)) &&
                      'disableMe'
                    }
                    onClick={() => {
                      redirectToAppIfIsCoachDomain(domain, '/account/profile');
                    }}
                  >
                    Account
                  </StyledDropDownItem>
                ) : null}
                {/* {(user?.connectedStripeAccountId || showCoachDashboard) && ( */}
                {isCoachAndClientRolesAvailable === true &&
                isLaunchPlan === false &&
                currentRole === UserRoles.client ? (
                  <StyledDropDownItem
                    style={
                      isCustomPrevButton || previewPage
                        ? {
                            backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                            color: isCoach ? themedColor : newThemedTextColor,
                          }
                        : {}
                    }
                    onClick={() => {
                      redirectToAppIfIsCoachDomain(domain, ROUTES.ROLE_SWITCH);
                    }}
                  >
                    {currentRole === UserRoles.cohealer ? 'Client' : 'Coach'} Dashboard
                  </StyledDropDownItem>
                ) : currentRole === UserRoles.cohealer ? (
                  <StyledDropDownItem
                    style={
                      isCustomPrevButton || previewPage
                        ? {
                            backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                            color: isCoach ? themedColor : newThemedTextColor,
                          }
                        : {}
                    }
                    onClick={() => {
                      redirectToAppIfIsCoachDomain(domain, ROUTES.ROLE_SWITCH);
                    }}
                  >
                    {currentRole === UserRoles.cohealer ? 'Client' : 'Coach'} Dashboard
                  </StyledDropDownItem>
                ) : (
                  <></>
                )}
                {/* )} */}
                <StyledDropDownItem
                  style={
                    isCustomPrevButton || previewPage
                      ? {
                          backgroundColor: isCoach ? themedBackgroundColor : newThemedBackgroundColor,
                          color: isCoach ? themedColor : newThemedTextColor,
                        }
                      : {}
                  }
                  onClick={() => {
                    redirectToAppIfIsCoachDomain(domain, '/logout');
                  }}
                >
                  Logout
                </StyledDropDownItem>
              </ClickDropDown>
              {/* }  */}

              <Modal
                title="We are here for you!"
                applyTheming
                isOpen={supportPopup}
                submitTitle="Contact Support"
                hiddenCancel
                onCancel={() => {
                  setSupportPopup(false);
                }}
                onSubmit={() => {
                  setSupportPopup(false);
                  window.location.href = 'mailto:support@cohere.live';
                }}
                buttons={modalButtons}
                helpModal={true}
                mobile={mobileView}
              >
                <p>
                  Have a service question? Tech problem? If so, we always have your back! Our service representatives
                  respond within 2 business days. When you click the button below it will provide an email address to
                  contact us.
                </p>

                <p>
                  In the subject of your email, please write the type of issue or question. For example: “Tech Issue,”
                  “Service Question,” or “Product Feedback.” If you have a tech issue, please also attach screen shots
                  in the body of your email if possible.
                </p>

                <p>We are committed to ensuring that you have a great experience! Thank you for your partnership.</p>

                <p>The Cohere Team.</p>
                <StyledSupportLink href="mailto:support@cohere.live">support@cohere.live</StyledSupportLink>
              </Modal>
            </>
          ) : (
            !isEmpty(user) && !tabletView && <MockBlock />
          )}
        </StyledUserSectionContainer>
        {/* {isLaunchPlan && trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') ? (
        <>
          <FreeTrialModal
            isOpen={isOpen && !isOpenStartedGuide && !countrypopup}
            handleClose={handleSubmit}
            handleSubmit={handleSubmit}
          />
          <GettingStartedGuide isOpen={isOpenStartedGuide && !isOpen && !countrypopup} closeHandler={closeHandler} />
        </>
      ) : (
        <></>
        <GettingStartedGuide isOpen={(isOpenStartedGuide || isOpen) && !countrypopup} closeHandler={closeHandler} />
      )} */}
        <GettingStartedGuide isOpen={isOpenStartedGuide} closeHandler={closeHandler} />

        {countrypopup &&
          currentRole === UserRoles.cohealer &&
          pathname != '/account/billing/coach' &&
          pathname != '/account/billing/coach/extended' && <CountryModal isOpen={countrypopup} />}
      </StyledHeaderContainer>
    </>
  );
}

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  noShadow: PropTypes.bool,
  // user: PropTypes.shape({
  //   firstName: PropTypes.string,
  //   lastName: PropTypes.string,
  //   avatarUrl: PropTypes.string,
  //   id: PropTypes.number,
  //   createTime: PropTypes.string,
  // }).isRequired,
  contribution: PropTypes.shape(),
  isCustomPrevButton: PropTypes.bool,
  backButtonText: PropTypes.string,
  children: PropTypes.node,
};

Header.defaultProps = {
  noShadow: false,
  contribution: null,
  isCustomPrevButton: false,
  backButtonText: '',
  children: null,
  isDarkThemeEnabled: true,
};

const actions = {
  getStartedToggle: userActions.getStartedToggle,
  fetchCountries: countryActions.fetchCountries,
};

const mapStateToProps = ({ account, page, contributions, country, clientPreviewMode, calendars }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  title: page?.header,
  contribution: contributions.activeContribution,
  countries: country?.countries,
  countryLoading: country?.loading,
  backLink: clientPreviewMode?.backLink,
  calendarActiveView: calendars.currentView,
});

export default connect(mapStateToProps, actions)(Header);
