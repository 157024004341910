import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import Select from 'components/FormUI/Select';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoIcon from '@material-ui/icons/Info';
import Checkbox from '@mui/material/Checkbox';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Input from 'components/FormUI/Input';
import { Tooltip, Avatar } from '@material-ui/core';
import { TOOLTIP } from '../../../constants';
import { useHttp } from 'hooks';
import Modal from 'components/UI/Modal';
import {
  EditNotificationP,
  ClientContriP,
  DraftBtn,
  ClientMainDvi,
  BackarrowMain,
  BtnDiv,
  StyledButton,
  StyledButtonSaved,
  StyledButtonReset,
  TriggerHeading,
  Trigger,
  CkEditorMainDiv,
  useStyles,
  SubjectHeading,
  GropContributionHeading,
  CheckboxHeading,
  EditNotificationMain,
  NotificationMainDiv,
  PurchaseGroupContainer,
  DropDownMainContainer,
} from './components/ContributionCommunicationStyled';
import { colors } from 'utils/styles';
import { useHistory } from 'react-router-dom';
import { resetEmailTemplate, updateEmailTemplate } from 'services/communicationTab.service ';
import styled from 'styled-components';
import { CKEditor, useCKEditor } from 'ckeditor4-react';
import useContribution from '../hooks/useContribution';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import { StyledInput, StyledSlider } from 'components/switch/style';
import Loader from 'components/UI/Loader';

const useBrandingText =
  'On choosing custom your emails will be branded as per your customized contribution colors and logo.';

const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
  color: ${props => props.labelColor};
  label {
    color: ${props => props.labelColor};
  }

  svg {
    color: ${props => props.labelColor};
  }
`;
const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 20px;
`;
export const EmailHeading = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 850;
  font-size: 14px;
  display: flex;
  ${({ mobileView }) =>
    mobileView &&
    `
  margin: 0px;
  `}
`;
export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
`;

const ContributionCommunicationCkEditor = ({ handleOnClick, userObj }) => {
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [modalVisible, setModalVisible] = useState(false);
  const [time, setTime] = React.useState(60);
  const [keywordState, setkeywordState] = useState();
  const [element, setElement] = useState();
  const [isCKEditorVisible, setIsCKEditorVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const resetTooltipText = `Template content will be reset from this button.`;
  const { editor } = useCKEditor({
    element,
    config: {
      allowedContent: true,
      height: '500px',
      removePlugins: 'elementspath',
    },
    dispatchEvent: e => console.log('Displaying dispatch event', e),
    subscribeTo: ['change'],
    initContent: userObj?.emailText,
  });
  const handleChange = event => {
    setTime(event.target.value);
  };
  const handleClosePartnerMadal = () => {
    setModalVisible(false);
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const classes = useStyles();
  const [data, setData] = useState(userObj?.emailText);
  const [EditorDataUpdated, setEditorDataUpdated] = useState(userObj?.emailText);
  const contribution = useContribution();
  const { id: contributionId, isCustomBrandingColorsActive } = contribution;
  const [emailCategory, setEmailCategory] = useState(userObj?.category);
  const [emailTemplateBrandingColor, setEmailTemplateBrandingColor] = useState(userObj?.isCustomBrandingColorsEnabled);
  const [emailSubject, setEmailSubject] = useState(userObj?.emailSubject);
  const [currentColors, setcurrentColors] = useState(userObj.currentColors);
  const [emailText, setEmailText] = useState(userObj?.emailText);
  const [keywordsList, setKeywordsList] = useState(userObj?.uniqueKeyWords);
  let colorToUse = determineColorToUse(contribution);
  const handleEditorChange = event => {
    setEditorDataUpdated(event.editor.getData());
  };
  const [diableButton, setdiableButton] = useState(false);
  const { request } = useHttp();
  const tooltipText = `The listed words placement can be updated but these words can't be changed.`;
  const TOOLTIP_TITLE = 'Copied to clipboard';
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const onCloseTooltip = useCallback(() => setTooltipOpen(false), [setTooltipOpen]);

  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const isDarkModeEnabled = determineDarkThemedColorToUse(contribution);
  useEffect(() => {
    console.log('dataChanged', editor);
  }, [EditorDataUpdated, data]);

  const sendTestNotification = body => {
    if (EditorDataUpdated.length == 0 || emailSubject.length == 0) {
      setModalVisible(true);
    } else {
      setdiableButton(true);
      let testData = {
        ...userObj,
        contributionId: contributionId,
        emailText: EditorDataUpdated,
        emailSubject: emailSubject,
        isCustomBrandingColorsEnabled: emailTemplateBrandingColor,
      };
      request('/Contribution/SendTestCustomEmailNotification', 'POST', testData)
        .then(res => {
          setdiableButton(false);
        })
        .catch(console.dir);
    }
  };

  const updateCustomBranding = e => {
    const params = {
      emailText: EditorDataUpdated,
      contributionId: contributionId,
      name: userObj.name,
      emailType: userObj.emailType,
      emailSubject: emailSubject,
      isEmailEnabled: userObj.isEmailEnabled,
      category: userObj.category,
      uniqueKeyWords: userObj.uniqueKeyWords,
      isCustomBrandingColorsEnabled: e,
      currentColors: currentColors,
      sendEmailAllowed:
        userObj.sendEmailAllowed === null ? 'true' : userObj.sendEmailAllowed === 'false' ? 'false' : 'true',
      isCustomEmailUpdated: true,
    };
    updateEmailTemplate(params).then(res => {
      setEmailText(null);
      setEmailSubject(res?.emailSubject);
      setEmailTemplateBrandingColor(res?.isCustomBrandingColorsEnabled);
      setEditorDataUpdated(res?.emailText);
      setKeywordsList(res?.uniqueKeyWords);
      setEmailCategory(res?.category);
      setData(res?.emailText);
      setIsCKEditorVisible(true);
      setResetModalVisible(false);
      setEmailText(res?.emailText);
      setcurrentColors(res.currentColors);
    });
  };

  return (
    <>
      <Grid container className={classes.EditNotificationMain}>
        <Grid item md={3}>
          <NotificationMainDiv mobileView={mobileView}>
            <BackarrowMain>
              <ArrowBackIosIcon
                onClick={() => handleOnClick()}
                style={{ color: colorToUse.PrimaryColorCode, cursor: 'pointer' }}
                fontSize="small"
              />
            </BackarrowMain>
            <EditNotificationP
              style={{ cursor: 'pointer', color: colorToUse.PrimaryColorCode }}
              onClick={() => handleOnClick()}
            >
              Edit Notification
            </EditNotificationP>
          </NotificationMainDiv>
        </Grid>
      </Grid>
      {/* <Grid item md={10}></Grid> */}
      <Grid item md={12} xs={12}>
        <BtnDiv>
          <StyledButton
            style={{
              marginRight: '10px',
              color: colorToUse.PrimaryColorCode,
              borderColor: colorToUse.PrimaryColorCode,
              backgroundColor: isDarkModeEnabled ? themedCardBackgroundColor : 'white',
            }}
            onClick={() => {
              sendTestNotification(userObj);
            }}
            mobileView={mobileView}
            Width="16px"
            disabled={diableButton}
          >
            Send Test
            <span>
              <InfoIcon
                style={{ paddingLeft: '4px' }}
                htmlColor={diableButton ? 'grey' : colorToUse.PrimaryColorCode}
              />
            </span>
          </StyledButton>
          <StyledButtonSaved
            style={{
              backgroundColor:
                userObj?.emailText != EditorDataUpdated || userObj?.emailSubject != emailSubject
                  ? colorToUse.PrimaryColorCode
                  : '',
              borderWidth: userObj?.emailText != EditorDataUpdated || userObj?.emailSubject != emailSubject ? 0 : '',
            }}
            disabled={userObj?.emailText != EditorDataUpdated || userObj?.emailSubject != emailSubject ? false : true}
            onClick={() => {
              if (EditorDataUpdated.length == 0 || emailSubject.length == 0) {
                setModalVisible(true);
              } else {
                const params = {
                  emailText: EditorDataUpdated,
                  contributionId: contributionId,
                  name: userObj.name,
                  emailType: userObj.emailType,
                  emailSubject: emailSubject,
                  isEmailEnabled: userObj.isEmailEnabled,
                  category: userObj.category,
                  uniqueKeyWords: userObj.uniqueKeyWords,
                  currentColors: userObj.currentColors,
                  isCustomBrandingColorsEnabled: emailTemplateBrandingColor,
                  sendEmailAllowed:
                    userObj.sendEmailAllowed === null
                      ? 'true'
                      : userObj.sendEmailAllowed === 'false'
                      ? 'false'
                      : 'true',
                  isCustomEmailUpdated: true,
                };
                updateEmailTemplate(params)
                  .then(res => {
                    setEmailCategory(res.category);
                    handleOnClick();
                  })
                  .catch(err => {
                    handleOnClick();
                  });
              }
            }}
            mobileView={mobileView}
            Width="16px"
          >
            Save & Exit
          </StyledButtonSaved>
        </BtnDiv>
      </Grid>

      <Grid container style={{ width: '100%', margin: 'auto', color: 'red' }}>
        <Grid item md={4}>
          <ClientMainDvi mobileView={mobileView}>
            <ClientContriP style={{ color: themedColor }}>{userObj?.name}</ClientContriP>
            <DraftBtn>Draft</DraftBtn>
          </ClientMainDvi>
        </Grid>
        <Grid item md={6}></Grid>
      </Grid>

      {/* {userObj.category === 'My Email Notification' && userObj.name.includes('Someone joins a Paid ') && ( */}
      {/* <Grid container style={{ width: '100%', margin: 'auto' }}>
        <Grid item md={10}>
          <Grid container>
            <Grid item md={12} style={{ background: '#dbe7f2', borderRadius: '5px', padding: '12px 14px' }}>
              <Trigger>Trigger:</Trigger>
              This is a welcome email that gets sent to your clients
              <Select
                style={{ margin: '0px 5px', background: 'white', borderRadius: '5px', paddingLeft: '12px' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={time}
                onChange={handleChange}
              >
                <MenuItem value={15}>15 Minutes</MenuItem>
                <MenuItem value={30}>30 Minutes</MenuItem>
                <MenuItem value={45}>45 Minutes</MenuItem>
                <MenuItem value={60}>60 Minutes</MenuItem>
              </Select>
              after they join your contribution to confirm they are in & the amount that they paid!
            </Grid>
          </Grid>
          {/* <TriggerHeading>
            <Trigger>Trigger:</Trigger>{' '}
            <div style={{ display: 'flex' }}>This is a welcome email that gets sent to your clients</div>
            <div style={{ background: 'white', display: 'flex' }}>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" value={time} onChange={handleChange}>
                <MenuItem value={15}>15 Minutes</MenuItem>
                <MenuItem value={30}>30 Minutes</MenuItem>
                <MenuItem value={45}>45 Minutes</MenuItem>
                <MenuItem value={60}>60 Minutes</MenuItem>
              </Select>{' '}
            </div>
            <div style={{ display: 'flex' }}>
              after they join your contribution to confirm they are in & the amount that they paid!
            </div>
          </TriggerHeading> */}
      {/* </Grid> */}
      {/* // <Grid item md={3}></Grid> */}
      {/* // </Grid> */}
      {/* )} */}
      <Grid container className={classes.CkEditorMainDiv}>
        <Grid item md={12} className={classes.CkEditorEmailH}>
          {/* <SubjectHeading style={{ color: themedColor }}>Email Subject:</SubjectHeading> */}
        </Grid>
        <Grid item md={12}>
          <PurchaseGroupContainer mobileView={mobileView}>
            <Grid item md={12} style={{ border: 'none' }} className={classes.CkEditorContainer}>
              <GropContributionHeading style={{ color: themedColor }} mobileView={mobileView}>
                <Formik initialValues={{ emailSubject: '' }}>
                  <Form>
                    <SubjectHeading style={{ color: themedColor }}>Email Subject:</SubjectHeading>
                    <TextField
                      id="outlined-basic"
                      onChange={e => {
                        setEmailSubject(e.target.value);
                      }}
                      inputProps={{
                        style: {
                          color: isDarkModeEnabled ? 'white' : 'black',
                          borderColor: isDarkModeEnabled ? 'white' : 'black',
                        },
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: isDarkModeEnabled ? classes.notchedOutline : '',
                        },
                      }}
                      name="emailSubject"
                      value={emailSubject}
                      fullWidth
                      variant="outlined"
                    />
                    {/* <Input
                      onChange={e => {
                        setEmailSubject(e.target.value);
                      }}
                      InputProps={{
                        style: {
                          color: themedColor,
                        },
                      }}
                      label=""
                      type="text"
                      name="emailSubject"
                      value={emailSubject}
                      fullWidth
                    /> */}
                  </Form>
                </Formik>
              </GropContributionHeading>
            </Grid>
            {/* <Grid item md={4} className={classes.EnableLogoMain}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'green' }}>
                <Checkbox
                  style={{
                    color: themedColor,
                  }}
                  checked={emailTemplateBrandingColor}
                  onChange={({ target: { checked } }) => {
                    if (emailTemplateBrandingColor === true) {
                      setEmailTemplateBrandingColor(false);
                    } else {
                      setEmailTemplateBrandingColor(true);
                    }
                  }}
                />
                <CheckboxHeading
                  style={{
                    color: themedColor,
                  }}
                >
                  Enable custom colors and branding logo
                </CheckboxHeading>
              </div>
            </Grid> */}
          </PurchaseGroupContainer>

          <DropDownMainContainer style={{}} mobileView={mobileView}>
            <Grid item xs={12} container wrap="nowrap" style={{ marginLeft: '50px', justifyContent: 'space-between' }}>
              <Grid item md={4} xs={12}>
                {!loading && (
                  <div>
                    <Formik
                      initialValues={{
                        keywords: null,
                      }}
                    >
                      <Tooltip title={TOOLTIP_TITLE} open={tooltipOpen} onClose={onCloseTooltip} arrow>
                        <StyledSelect
                          label="Keywords"
                          name="keywords"
                          // color={themedColor}
                          labelColor={themedColor}
                          onChange={e => {
                            setkeywordState(e);
                            navigator.clipboard.writeText(e);
                            setTooltipOpen(true);

                            setTimeout(() => {
                              setTooltipOpen(false);
                            }, 1000);
                          }}
                          sx={{
                            width: 100,
                            height: 40,
                            marginRight: 15,
                            border: '1px solid darkgrey',
                            color: 'red',
                            '& .MuiSvgIcon-root': {
                              color: 'white',
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                bgcolor: 'pink',
                                '& .MuiMenuItem-root': {
                                  padding: 2,
                                },
                              },
                            },
                          }}
                          value={keywordState}
                          icon={true}
                          items={keywordsList?.map(data => ({
                            title: data.name,
                            value: data.value,
                            icon: true,
                          }))}
                        />
                      </Tooltip>
                    </Formik>
                    <Tooltip title={tooltipText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                      <InfoIcon
                        style={{ marginTop: '30px', marginLeft: '5px' }}
                        htmlColor={colorToUse.PrimaryColorCode}
                      />
                    </Tooltip>
                  </div>
                )}
              </Grid>
            </Grid>
          </DropDownMainContainer>
        </Grid>
        <Grid container justifyContent="space-between">
          {emailTemplateBrandingColor != null && (
            <Grid item md={4} style={{ margin: '10px 50px' }}>
              <div style={{ display: 'flex' }}>
                <EmailHeading>
                  Use my branding
                  <Switch className="switch" style={{ marginLeft: '8px' }}>
                    <StyledInput
                      defaultChecked={emailTemplateBrandingColor}
                      type="checkbox"
                      onChange={e => {
                        updateCustomBranding(e.target.checked);
                      }}
                    />
                    <StyledSlider className="slider round" />
                  </Switch>
                  <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                    <StyledInfoIcon
                      htmlColor={colorToUse.PrimaryColorCode}
                      style={{ margin: '0px', marginLeft: '8px' }}
                    />
                  </Tooltip>
                </EmailHeading>
                {/* <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <p> {emailTemplateBrandingColor ? 'Custom' : 'Default'}</p>
                  <Switch className="switch" style={{ marginLeft: '6px' }}>
                    <StyledInput
                      defaultChecked={emailTemplateBrandingColor}
                      type="checkbox"
                      onChange={e => {
                        updateCustomBranding(e.target.checked);
                      }}
                    />
                    <StyledSlider className="slider round" />
                  </Switch>
                </div> */}
              </div>
            </Grid>
          )}
          <Grid item md={4} style={{ margin: '10px 50px', display: 'flex', justifyContent: 'flex-end', color: 'gray' }}>
            <p
              style={{ cursor: 'pointer', color: isDarkModeEnabled ? 'white' : '#4A4A4A' }}
              onClick={() => {
                setResetModalVisible(true);
              }}
            >
              Revert to Default
            </p>
          </Grid>
        </Grid>
        <Grid item md={12} className={classes.CkEditorContainer}>
          <div
            className="App"
            style={{ width: '100%' }}
            // ref={setElement}
          >
            {emailText && (
              <CKEditor
                config={{
                  allowedContent: true,
                  height: '500px',
                  removePlugins: 'elementspath,dragdrop',
                }}
                initData={emailText}
                onChange={handleEditorChange}
                onDragOver={e => {
                  e.preventDefault();
                }}
                onDrop={(event, editor) => {
                  event.cancel();
                }}
              />
            )}
            {/* <h1>ckEditor 5</h1> */}
            {/* <CKEditor onChange={handleEditorChange} /> */}
          </div>
        </Grid>
        <Modal
          isOpen={modalVisible}
          applyTheming
          onSubmit={handleClosePartnerMadal}
          title={''}
          hiddenCancel
          onCancel={handleClosePartnerMadal}
        >
          {EditorDataUpdated?.length == 0 && emailSubject?.length == 0 ? (
            <p>Email subject and body can not be empty.</p>
          ) : emailSubject?.length == 0 ? (
            <p>Email subject can not be empty.</p>
          ) : (
            <p>Email body can not be empty.</p>
          )}
        </Modal>
        <Modal
          isOpen={resetModalVisible}
          applyTheming
          onCancel={() => {
            setResetModalVisible(false);
            setLoading(false);
          }}
          onSubmit={() => {
            setIsCKEditorVisible(false);
            setLoading(true);
            const params = {
              emailText: EditorDataUpdated,
              contributionId: contributionId,
              name: userObj.name,
              emailType: userObj.emailType,
              emailSubject: emailSubject,
              isEmailEnabled: userObj.isEmailEnabled,
              category: emailCategory,
              uniqueKeyWords: userObj.uniqueKeyWords,
              isCustomBrandingColorsEnabled: emailTemplateBrandingColor,
            };
            resetEmailTemplate(params)
              .then(res => {
                console.log('Res---->', res.isCustomBrandingColorsEnabled);
                setEmailText(null);
                setEmailSubject(res?.emailSubject);
                setEmailTemplateBrandingColor(null);
                setEditorDataUpdated(res?.emailText);
                setKeywordsList(res?.uniqueKeyWords);
                setEmailCategory(res?.category);
                setData(res?.emailText);
                setIsCKEditorVisible(true);
                setResetModalVisible(false);
                setEmailText(res?.emailText);
                setEmailTemplateBrandingColor(res.isCustomBrandingColorsEnabled);

                setTimeout(() => {
                  setLoading(false);
                }, 500);
              })
              .catch(err => {
                setIsCKEditorVisible(true);
              });
          }}
          title="Reset Template"
          hiddenCancel
        >
          <p>Are you sure you want to reset the template?</p>
        </Modal>
        {loading && <Loader />}
      </Grid>
    </>
  );
};

export default ContributionCommunicationCkEditor;
