import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon } from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { useHttp, useAccount, useVideoChatActions } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Popup from 'components/Popup/Popup';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classes from './CampaignsTable.module.scss';
import Campaign from '../Campaign/Campaign';
import { createCampaignServices } from 'pages/CreateCampaign/Services/CreateCampaign.service';
import { useDispatch } from 'react-redux';
import { setUserContacts } from 'actions/campaign';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);

const BoldTableCellNext = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '30px',
  },
})(TableCell);

const BoldTableCellStatus = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '10px',
  },
})(TableCell);

const BoldTableCellEdit = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '25px',
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);
const StyledIcon = styled(Icon)`
  // margin: 0 10px;
`;

const CampaignsTable = ({
  campaigns = [],
  isChecked,
  onCheckClicked,
  setShowContactsList,
  setSelectedCampaign,
  setIsReadOnlyView,
}) => {
  const [popupText, setPopupText] = useState(null);
  const { request } = useHttp();
  const [campaignWithOpenDropDown, setCampaignWithOpenDropDown] = useState(null);
  const { startVideoChat } = useVideoChatActions();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const setId = id => {
    setCampaignWithOpenDropDown(id);
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = () => {
    createCampaignServices
      .getUserContacts()
      .then(res => {
        dispatch(setUserContacts(res));
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  return (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView} style={{ overflow: 'visible' }}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell>One-Time Email Title</BoldTableCell>
              <BoldTableCellNext>Email</BoldTableCellNext>
              <BoldTableCell align="center">Date Created</BoldTableCell>
              <BoldTableCell align="center">Send Date</BoldTableCell>
              <BoldTableCell align="center">Status</BoldTableCell>
              <BoldTableCell align="center">Contacts</BoldTableCell>
              <BoldTableCell align="center">Actions</BoldTableCell>
              {/* <BoldTableCellEdit>Contacts</BoldTableCellEdit>
              <BoldTableCellEdit>Action</BoldTableCellEdit> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns?.map((row, index) => (
              <Campaign
                key={index}
                index={index}
                row={row}
                isChecked={isChecked}
                onCheckClicked={onCheckClicked}
                campaignWithOpenDropDown={campaignWithOpenDropDown}
                setId={setId}
                totalCampaigns={campaigns}
                setShowContactsList={setShowContactsList}
                setSelectedCampaign={setSelectedCampaign}
                setIsReadOnlyView={setIsReadOnlyView}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

CampaignsTable.propTypes = {};

CampaignsTable.defaultProps = {};

export default CampaignsTable;
