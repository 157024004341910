import React, { useState } from 'react';
import { Grid, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { useZoom } from '../hooks/useZoom';
import Modal from 'components/UI/Modal';

export const ZoomIntegration = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { authorizeAccountHref, isAccountConnected, isLoading, onDisconnect } = useZoom();
  const buttonText = isLoading ? 'Processing...' : '';
  const [zoomPopUpOpen, setZoomPopUpOpen] = useState(false);
  const handlehowToVideos = ({}) => {
    window.open('https://cohere2298.zendesk.com/hc/en-us/articles/22900870664980', '_blank');
  };

  const handleConnectClick = () => {
    setZoomPopUpOpen(true);
  };

  const WarningWrapper = styled.div`
    margin-top: 10px;
  `;
  const CardMainContainer = styled.div`
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 4px;
    padding: 18px;
  `;
  return (
    <CardMainContainer id="integration3">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid
          style={{ borderBottom: '1px solid #E0E0E0', paddingBottom: '20px' }}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid>
            <TableHead>
              <TableRow>
                <Typography variant="h6" style={{ display: 'inline-block' }}>
                  Zoom
                </Typography>
                {isAccountConnected && (
                  <div class="inline-block" style={{ marginLeft: 20 }}>
                    Your Zoom account is currently connected. Click disconnect to uninstall.
                  </div>
                )}
              </TableRow>
            </TableHead>
          </Grid>
          <Grid>
            <Button autoWidth mobileView={mobileView} onClick={handlehowToVideos} style={{ marginRight: 10 }}>
              Help Articles
            </Button>
            {!isAccountConnected && (
              // <Link href={authorizeAccountHref}>
              <Button autoWidth disabled={isLoading} mobileView={mobileView} onClick={handleConnectClick}>
                {isLoading ? buttonText : 'Connect'}
              </Button>
              // </Link>
            )}
            {isAccountConnected && (
              <Button autoWidth disabled={isLoading} mobileView={mobileView} onClick={onDisconnect}>
                {isLoading ? buttonText : 'Disconnect'}
              </Button>
            )}
          </Grid>
        </Grid>
        {!isAccountConnected && (
          <Grid style={{ marginTop: '20px' }}>
            <WarningWrapper>
              You will need a paid version of Zoom to enjoy the full benefits of connecting your account. Full benefits
              include automatic Cohere synchronization of session recordings and chat logs and automatic client
              notifications when recordings are available. Free Zoom accounts will still set up sessions, but other
              features may not work as expected.
            </WarningWrapper>
          </Grid>
        )}
        <Modal
          isOpen={zoomPopUpOpen}
          submitTitle="Never mind"
          onSubmit={() => {
            setZoomPopUpOpen(false);
          }}
          title="Zoom Integration"
          onCancel={() => {
            window.open(authorizeAccountHref);
          }}
          onCloseClick={() => {
            setZoomPopUpOpen(false);
          }}
          closeClick={true}
          cancelTitle="Understood, Connect Zoom"
          shrinkCancelButton={true}
        >
          <p>
            Zoom integration is only compatible with paid zoom accounts, if you have a Free Zoom account please upgrade
            or use Cohere Video Service.
          </p>
        </Modal>
      </Grid>
    </CardMainContainer>
  );
};
