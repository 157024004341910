import React, { useEffect, useRef, useState } from 'react';
import classes from './EditLeadModalForm.module.scss';
import { Grid } from '@material-ui/core';
import TextField from 'components/UI/TextField';
import { Formik, Form } from 'formik';
import { PhoneInput } from 'react-international-phone';
import Modal from 'components/UI/Modal';
import * as Yup from 'yup';
import styled from 'styled-components';
import Select from 'components/UI/Select';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import { ContactsServices } from 'pages/Contacts/Services/Contacts.service';
import { fetchAllContactsAsync } from 'actions/contacts';

const StyledError = styled.div`
  color: red;
  margin-top: 5px;
`;

const EditLeadModalForm = ({ isOpen, onCancel, lead }) => {
  console.log('lead>>>>>', lead);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { isLoading, tags } = useSelector(state => state.contacts.tags);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // useEffect(() => {
  //   if (formRef.current) {
  //     formRef.current.setFieldValue('firstName', lead?.firstName);
  //     formRef.current.setFieldValue('lastName', lead?.lastName);
  //     formRef.current.setFieldValue('email', lead?.email);
  //     formRef.current.setFieldValue('phone', lead?.phone);
  //     formRef.current.setFieldValue('source', lead?.contributionTitle);
  //   }
  // }, [lead]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        firstName: lead?.firstName || '',
        lastName: lead?.lastName || '',
        email: lead?.email || '',
        phone: lead?.phoneNumber || '',
        tagIds: lead?.tags?.map(t => t.id) || [],
        source: lead?.source || '',
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('This is required'),
        lastName: Yup.string().required('This is required'),
        email: Yup.string().email('Enter a valid email').required('This is required'),
        phone: Yup.string().required('This is required'),
        tagIds: Yup.array(),
        source: Yup.string().required('This is required'),
      })}
      onSubmit={values => {
        setIsSubmitting(true);
        console.log('values--->', values);

        if (lead) {
          const data = { ...lead, ...values };
          ContactsServices.editLead(lead?.id, data)
            .then(res => {
              console.log('res>>', res);
              dispatch(fetchAllContactsAsync({}));
              onCancel();
            })
            .catch(err => {
              console.log('err>>', err);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        } else {
          const data = {
            ...values,
          };
          ContactsServices.addLead(data)
            .then(res => {
              console.log('res>>', res);
              dispatch(fetchAllContactsAsync({}));
              onCancel();
            })
            .catch(err => {
              console.log('err>>', err);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }
      }}
    >
      {({ values, setFieldValue, handleSubmit, handleBlur, errors, touched }) => {
        console.log('values>>errors', values, errors);
        return (
          <Modal
            title={lead ? 'Edit a Lead' : 'Add a Lead'}
            isOpen={isOpen}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            disableSubmitClick={isSubmitting}
            submitTitle={lead ? 'Update' : 'Add'}
            reduceCancelButton
          >
            <Form>
              {(isLoading || isSubmitting) && <Loader />}
              <div className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sm={6}>
                    <div className={classes.field_label}>First Name</div>
                    <div className={classes.field_input}>
                      <TextField
                        fullWidth
                        value={values.firstName}
                        onChange={e => {
                          setFieldValue('firstName', e.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                    {touched?.firstName && errors?.firstName && <StyledError>{errors?.firstName}</StyledError>}
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <div className={classes.field_label}>Last Name</div>
                    <div className={classes.field_input}>
                      <TextField
                        fullWidth
                        value={values.lastName}
                        onChange={e => {
                          setFieldValue('lastName', e.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                    {touched?.lastName && errors?.lastName && <StyledError>{errors?.lastName}</StyledError>}
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} className={classes.email_container}>
                    <div className={classes.field_label}>Email</div>
                    <div className={classes.field_input}>
                      <TextField
                        fullWidth
                        value={values.email}
                        onChange={e => {
                          setFieldValue('email', e.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                    {touched?.email && errors?.email && <StyledError>{errors?.email}</StyledError>}
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <div className={classes.field_label}>Phone Number</div>
                    <PhoneInput
                      className={classes.phone_number}
                      value={values.phone}
                      onChange={phone => {
                        setFieldValue('phone', phone);
                      }}
                      placeholder="Phone Number"
                      countrySelectorStyleProps={{
                        dropdownArrowClassName: classes.dropdownArrowClass,
                      }}
                      onBlur={handleBlur}
                    />
                    {touched?.phone && errors?.phone && <StyledError>{errors?.phone}</StyledError>}
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <div className={classes.field_label}>Tags</div>
                    <div className={classes.field_input}>
                      <Select
                        fullWidth
                        value={values.tagIds}
                        onChange={e => {
                          setFieldValue('tagIds', e.target.value);
                        }}
                        items={tags?.map(t => ({ title: t.name, value: t.id }))}
                        onBlur={handleBlur}
                        multiple
                      />
                    </div>
                    {touched?.tagIds && errors?.tagIds && <StyledError>{errors?.tagIds}</StyledError>}
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <div className={classes.field_label}>Source</div>
                    <div className={classes.field_input}>
                      <TextField
                        fullWidth
                        value={values.source}
                        onChange={e => {
                          setFieldValue('source', e.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                    {touched?.source && errors?.source && <StyledError>{errors?.source}</StyledError>}
                  </Grid>
                </Grid>
              </div>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditLeadModalForm;
