// import React from "react";
import * as Yup from 'yup';
import moment from 'moment';

import { EN_MESSAGES, VALIDATION_REGEX } from 'constants.js';
import {
  ACCOUNT_FORM_FIELDS,
  PURCHASE_MODAL_STEPS,
} from 'pages/ContributionView/components/PurchaseModal/PurchaseModal.constants';

const MIN_STRING_LENGTH = 2;
const MAX_STRING_LENGTH = 100;
const MAX_LONG_STRING_LENGTH = 200;

// const getLoginValidationSchema = Translate =>
const getLoginValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .strict(true)
      .trim('Please remove spaces after your password')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.signuppassword)
      // .required(<Translate id="validation.required" />),
      .required(EN_MESSAGES.validation.required),
    email: Yup.string().email('Please enter a valid email address').required(EN_MESSAGES.validation.required),
  });

const getCreateAccountValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .strict(true)
      .trim('Please remove spaces after your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g,
        // message: <Translate id="validation.password" />
        EN_MESSAGES.signuppassword,
      )
      // .required(<Translate id="validation.required" />),
      .required(EN_MESSAGES.validation.required),
    email: Yup.string().email('Please enter a valid email address').required(EN_MESSAGES.validation.required),
  });

// const restorePassExtendedValidationSchema = Translate =>
const restorePassExtendedValidationSchema = () =>
  Yup.object().shape({
    newPassword: Yup.string()
      .strict(true)
      .trim('Please remove spaces after your password')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.validation.password)
      // .required(<Translate id="validation.required" />),
      .required(EN_MESSAGES.validation.required),
    email: Yup.string()
      // .email(<Translate id="validation.email.invalid" />)
      .email('Please enter a valid email address')
      // .required(<Translate id="validation.required" />),
      .required(EN_MESSAGES.validation.required),
  });

// const restorePassValidationSchema = Translate =>
const restorePassValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      // .email(<Translate id="validation.email.invalid" />)
      .email('Please enter a valid email address')
      // .required(<Translate id="validation.required" />),
      .required(EN_MESSAGES.validation.required),
  });

// const getSignUpValidationSchema = Translate =>
const getSignUpValidationSchema = () =>
  Yup.object().shape({
    FirstName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    LastName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    TimeZoneId: Yup.mixed().required(),
    CountryId: Yup.mixed().required(),
    // TimeZoneId: Yup.mixed()
    //   .oneOf([
    //     'America/Los_Angeles',
    //     'America/Denver',
    //     'America/Chicago',
    //     'America/New_York',
    //     'America/Anchorage',
    //     'America/St_Johns',
    //     'Pacific/Honolulu',
    //     'America/Phoenix',
    //     'Australia/Sydney',
    //     'Canada/Atlantic',
    //     'Canada/Central',
    //     'Canada/Eastern',
    //     'Canada/Mountain',
    //     'Canada/Newfoundland',
    //     'Canada/Pacific',
    //     'Canada/Saskatchewan',
    //     'Canada/Yukon',
    //     'Europe/Amsterdam',
    //     'Europe/Andorra',
    //     'Europe/Athens',
    //     'Europe/Belfast',
    //     'Europe/Belgrade',
    //     'Europe/Berlin',
    //     'Europe/Bratislava',
    //     'Europe/Brussels',
    //     'Europe/Bucharest',
    //     'Europe/Budapest',
    //     'Europe/Busingen',
    //     'Europe/Chisinau',
    //     'Europe/Copenhagen',
    //     'Europe/Dublin',
    //     'Europe/Gibraltar',
    //     'Europe/Guernsey',
    //     'Europe/Helsinki',
    //     'Europe/Isle_of_Man',
    //     'Europe/Istanbul',
    //     'Europe/Jersey',
    //     'Europe/Kaliningrad',
    //     'Europe/Lisbon',
    //     'Europe/Ljubljana',
    //     'Europe/London',
    //     'Europe/Luxembourg',
    //     'Europe/Madrid',
    //     'Europe/Malta',
    //     'Europe/Mariehamn',
    //     'Europe/Minsk',
    //     'Europe/Monaco',
    //     'Europe/Moscow',
    //     'Europe/Nicosia',
    //     'Europe/Oslo',
    //     'Europe/Paris',
    //     'Europe/Podgorica',
    //     'Europe/Prague',
    //     'Europe/Riga',
    //     'Europe/Rome',
    //     'Europe/Samara',
    //     'Europe/San_Marino',
    //     'Europe/Sarajevo',
    //     'Europe/Simferopol',
    //     'Europe/Skopje',
    //     'Europe/Sofia',
    //     'Europe/Stockholm',
    //     'Europe/Tallinn',
    //     'Europe/Tirane',
    //     'Europe/Tiraspol',
    //     'Europe/Uzhgorod',
    //     'Europe/Vaduz',
    //     'Europe/Vatican',
    //     'Europe/Vienna',
    //     'Europe/Vilnius',
    //     'Europe/Volgograd',
    //     'Europe/Warsaw',
    //     'Europe/Zagreb',
    //     'Europe/Zaporozhye',
    //     'Europe/Zurich',
    //     'Hongkong',
    //     'Iceland',
    //     'Israel',
    //     'Japan',
    //     'Mexico/BajaNorte',
    //     'Mexico/BajaSur',
    //     'Mexico/General',
    //     'Navajo',
    //     'NZ',
    //     'NZ-CHAT',
    //     'Singapore',
    //   ])
    //   .required(),
    Bio: Yup.string().strict(true).max(500),
  });

const getSignUpClientValidationSchema = () =>
  Yup.object().shape({
    FirstName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    LastName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
  });

const getClientProfileValidationSchema = () =>
  Yup.object().shape({
    FirstName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    LastName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    Bio: Yup.string().strict(true).max(500),
  });

const getPurchaseValidationSchema = (step, usePaymentIntent = false) => {
  if (step === PURCHASE_MODAL_STEPS.memberLoggedIn) {
    return Yup.object().shape({});
  }
  if (step === PURCHASE_MODAL_STEPS.loggedIn) {
    if (usePaymentIntent) {
      return Yup.object().shape({
        Name: Yup.string().required(),
        // cardNumber: Yup.boolean().oneOf([true]).required(),
        // cardExpired: Yup.boolean().oneOf([true]).required(),
        // cardCVC: Yup.boolean().oneOf([true]).required(),
      });
    }
    return Yup.object().shape({});
  }
  if (step === PURCHASE_MODAL_STEPS.init) {
    if (usePaymentIntent) {
      return Yup.object().shape({
        Name: Yup.string().required(),
        [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
      });
    }
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.memberInit) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.join) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.joinLogin) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
      [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        .strict(true)
        .trim('Please remove spaces after your password')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.validation.password)
        .required(EN_MESSAGES.validation.required),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.login) {
    if (usePaymentIntent) {
      return Yup.object().shape({
        Name: Yup.string().required(),
        [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
        [ACCOUNT_FORM_FIELDS.password]: Yup.string()
          .strict(true)
          .trim('Please remove spaces after your password')
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.validation.password)
          .required(EN_MESSAGES.validation.required),
      });
    }
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
      [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        .strict(true)
        .trim('Please remove spaces after your password')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.validation.password)
        .required(EN_MESSAGES.validation.required),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.memberLogin) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
      [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        .strict(true)
        .trim('Please remove spaces after your password')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.validation.password)
        .required(EN_MESSAGES.validation.required),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.memberCreate) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
      [ACCOUNT_FORM_FIELDS.firstName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.lastName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.confirmEmail]: Yup.string()
        .required()
        .test('equal', 'Email addresses do not match. Please re-enter your email address.', function (value) {
          return this.parent[ACCOUNT_FORM_FIELDS.email]?.toLowerCase() === value?.toLowerCase();
        }),
      // [ACCOUNT_FORM_FIELDS.password]: Yup.string()
      //   .strict(true)
      //   .trim()
      //   .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
      //     message: EN_MESSAGES.validation.password,
      //   })
      //   // check why select is showing message only after being clicked, and not when submitting.
      //   .required(EN_MESSAGES.validation.required),
      [ACCOUNT_FORM_FIELDS.timeZoneId]: Yup.mixed().required(),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.create) {
    if (usePaymentIntent) {
      return Yup.object().shape({
        Name: Yup.string().required(),
        [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
        [ACCOUNT_FORM_FIELDS.firstName]: Yup.string().required(),
        [ACCOUNT_FORM_FIELDS.lastName]: Yup.string().required(),
        [ACCOUNT_FORM_FIELDS.confirmEmail]: Yup.string()
          .required()
          .test('equal', 'Email addresses do not match. Please re-enter your email address.', function (value) {
            return this.parent[ACCOUNT_FORM_FIELDS.email]?.toLowerCase() === value?.toLowerCase();
          }),
        // [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        //   .strict(true)
        //   .trim()
        //   .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
        //     message: EN_MESSAGES.validation.password,
        //   })
        //   // check why select is showing message only after being clicked, and not when submitting.
        //   .required(EN_MESSAGES.validation.required),
        [ACCOUNT_FORM_FIELDS.timeZoneId]: Yup.mixed().required(),
      });
    }
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
      [ACCOUNT_FORM_FIELDS.firstName]: Yup.string().required('First Name is a required field'),
      [ACCOUNT_FORM_FIELDS.lastName]: Yup.string().required('Last Name is a required field'),
      [ACCOUNT_FORM_FIELDS.confirmEmail]: Yup.string()
        .required('Confirm email is a required field')
        .test('equal', 'Email addresses do not match. Please re-enter your email address.', function (value) {
          return this.parent[ACCOUNT_FORM_FIELDS.email]?.toLowerCase() === value?.toLowerCase();
        }),
      // [ACCOUNT_FORM_FIELDS.password]: Yup.string()
      //   .strict(true)
      //   .trim()
      //   .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
      //     message: EN_MESSAGES.validation.password,
      //   })
      //   // check why select is showing message only after being clicked, and not when submitting.
      //   .required('Password is a required field'),
      [ACCOUNT_FORM_FIELDS.timeZoneId]: Yup.mixed().required('Timezone is a required field'),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.joinCreate) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email('Please enter a valid email address'),
      [ACCOUNT_FORM_FIELDS.firstName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.lastName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.confirmEmail]: Yup.string()
        .required()
        .test('equal', 'Email addresses do not match. Please re-enter your email address.', function (value) {
          return this.parent[ACCOUNT_FORM_FIELDS.email]?.toLowerCase() === value?.toLowerCase();
        }),
      // [ACCOUNT_FORM_FIELDS.password]: Yup.string()
      //   .strict(true)
      //   .trim()
      //   .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
      //     message: EN_MESSAGES.validation.password,
      //   })
      //   // check why select is showing message only after being clicked, and not when submitting.
      //   .required(EN_MESSAGES.validation.required),
      [ACCOUNT_FORM_FIELDS.timeZoneId]: Yup.mixed().required(),
    });
  }
};

const getCouponValidationSchema = discountType => {
  return Yup.object().shape({
    name: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    percentOff:
      discountType === 'Percentage'
        ? Yup.number()
            .positive()
            .strict(true)
            .min(1, 'Percentage must be greater than or equal to 1')
            .required('Percentage is required')
        : Yup.number(),
    amountOff:
      discountType === 'FixedAmount'
        ? Yup.number()
            .typeError('Please enter a valid number')
            .test('no-period', 'Decimal is not allowed', value => value.toString().indexOf('.') === -1)
            .positive()
            .integer()
            .strict(true)
            .min(1, 'Amount must be greater than or equal to 1')
            .max(100000, 'Amount must be must be less than or equal to 100000')
            .required('Amount is required')
        : Yup.number(),
    couponDiscountType: Yup.string().strict(true).required(),
    maxRedemptions: Yup.number().strict(true).min(1).nullable(),
    redeemBy: Yup.date().nullable(),
  });
};

export {
  getLoginValidationSchema,
  restorePassExtendedValidationSchema,
  getCreateAccountValidationSchema,
  restorePassValidationSchema,
  getSignUpValidationSchema,
  getSignUpClientValidationSchema,
  getClientProfileValidationSchema,
  getPurchaseValidationSchema,
  getCouponValidationSchema,
};
