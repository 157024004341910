import React from 'react';
import FormControl from '@material-ui/core/FormControl';

import { TOOLTIP } from 'pages/ContributionView/ContributionSessions/constants';
import { colors } from 'utils/styles';
import {
  StyledFlexContainer,
  StyledTitle,
  StyledTooltip,
  StyledInfoIcon,
  StyledInput,
  StyledField,
} from './SharedStyledComponents';

const InputWithTitle = props => {
  const {
    title = false,
    toolTip = false,
    children = null,
    textField = null,
    textArea = null,
    disabled = false,
    formprops,
    wordLimit,
    mb = true,
    mainContainerStyles = {},
    placeholder /*= 'Enter text here...' */,
    ...rest
  } = props;
  return (
    <div style={{ ...{ marginBottom: mb ? '14px' : '' }, ...mainContainerStyles }}>
      {(title || toolTip) && (
        <StyledFlexContainer style={{ gap: '0.625rem' }}>
          {title && <StyledTitle>{title}</StyledTitle>}
          {toolTip && (
            <StyledTooltip
              title={toolTip}
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <StyledInfoIcon htmlColor={colors.lightBrown} style={{ margin: 0 }} />
            </StyledTooltip>
          )}
        </StyledFlexContainer>
      )}
      {textField && (
        <StyledInput
          helperText={wordLimit && `${formprops.value.length}/${wordLimit}`}
          fullWidth
          variant="outlined"
          margin={'dense'}
          name={formprops.name}
          value={formprops.value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={e => {
            formprops.onChange(e.target.value);
          }}
          {...rest}
        />
      )}
      {textArea && (
        <StyledInput
          helperText={wordLimit && `${formprops.value.length}/${wordLimit}`}
          fullWidth
          variant="outlined"
          margin="dense"
          multiline
          rows={3}
          maxRows={8}
          name={formprops.name}
          value={formprops.value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={e => {
            formprops.onChange(e.target.value);
          }}
          isTextArea
          style={{ height: '102px' }}
          {...rest}
        />
      )}
    </div>
  );
};

export default InputWithTitle;
