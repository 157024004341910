import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PaymentCard from 'pages/ContributionView/components/PaymentCard';
import './EasyBooking.scss';
import { getOnToOnePaymentInfo } from 'services/purchase.service';
import * as contributionActions from 'actions/contributions';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useDispatch, useSelector } from 'react-redux';
import useRouter from 'hooks/useRouter';
import BookingSummary from './components/BookingSummary';
import { setEasyBookingData } from 'actions/easyBooking';
import { determineColorToUse } from 'services/contributions.service';

moment.locale('ko', {
  week: {
    dow: 1,
    doy: 1,
  },
});

const EasyBooking = ({ clientPreviewMode = false }) => {
  const activeContribution = useContribution();
  const { AccentColorCode = '', PrimaryColorCode = '' } = determineColorToUse(activeContribution);
  const { serviceProviderName = '', durations, coachAvatarUrl = '', isDarkModeEnabled = false } = activeContribution;
  const duration = durations[0];
  const dispatch = useDispatch();
  const {
    match: {
      params: { code },
    },
  } = useRouter();
  const easyBookingData = useSelector(state => state?.easyBooking);

  useEffect(() => {
    dispatch(setEasyBookingData.setData({ ...easyBookingData, code }));
  }, [code]);

  return (
    <div className={`easy-booking-page ${isDarkModeEnabled ? 'cohere-dark-mode' : ''}`}>
      <div className="easy-booking-header" style={{ backgroundColor: AccentColorCode }}>
        Book your next session below.
      </div>
      <div className="easy-booking-container">
        <BookingSummary
          coachAvatarUrl={coachAvatarUrl}
          serviceProviderName={serviceProviderName}
          duration={duration}
          contributionTitle={activeContribution?.title}
          purpose={activeContribution?.purpose}
          accentColorCode={AccentColorCode}
          primaryColorCode={PrimaryColorCode}
          clientPreviewMode={clientPreviewMode}
        />
        <div className="calendar-side">
          <PaymentCard
            contribution={activeContribution}
            type={activeContribution?.type}
            isCustomBrandingColorsActive={false}
            easyBooking={true}
            clientPreviewMode={clientPreviewMode}
          />
        </div>
      </div>
    </div>
  );
};

export default EasyBooking;
