import React, { useRef, useEffect } from 'react';
import classes from './CreateSegmentModal.module.scss';
import Input from 'components/FormUI/Input';
import styled from 'styled-components';
import { useState } from 'react';
import { FieldArray, Formik } from 'formik';
import Modal from 'components/UI/Modal';
import * as Yup from 'yup';
import { mdiHelpCircle } from '@mdi/js';
import { Icon } from '@mdi/react';
import SegmentGroup from './SegmentGroup/SegmentGroup';
import GuideVideoModal from '../GuideVideo/GuideVideoModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTagsAsync } from 'actions/contacts';
import useAccount from 'hooks/useAccount';
import { ContactsServices } from 'pages/Contacts/Services/Contacts.service';
import { setErrorSegment } from 'actions/contributions';

const LabelInput = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #213649;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 5px;
`;

const StyledLable = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #215c73;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
`;

const StyledError = styled.div`
  color: red;
`;

const StyledIcon = styled(Icon)`
  width: 22.5px;
  height: 22.5px;
  margin-left: 20px;
  cursor: pointer;
  color: ${({ color }) => color};
  ${({ mobileView }) => mobileView && `width: 6px; height: 2px;`};
`;

const CreateSegmentModal = ({ isOpen, onClose, editing, setSegmentId }) => {
  const [guidePopUp, setGuidePopUp] = useState(false);
  const [segmentError, setSegmentError] = useState(null);
  const dispatch = useDispatch();
  const { user } = useAccount();
  const formRef = useRef(null);
  const { tags } = useSelector(state => state.contacts.tags);
  const checkForEmptyValues = keyToCheck => {
    const hasEmptyValue = formRef.current.values.segmentConditions.some(item => item[keyToCheck] === '');
    return hasEmptyValue;
  };
  const handleSubmit = values => {
    console.log('Values------>', formRef.current.values);
    const existTags = [];
    const notExistTags = [];
    if (checkForEmptyValues('tagId') === true) {
      dispatch(setErrorSegment(true));
      return;
    } else {
      dispatch(setErrorSegment(false));
    }
    if (checkForEmptyValues('tagName') === true) {
      dispatch(setErrorSegment(true));
      return;
    } else {
      dispatch(setErrorSegment(false));
    }

    formRef.current.values.segmentConditions.forEach(item => {
      const tag = { tagId: item.tagId, name: item.tagName };

      if (item.tagExists === 'Exists') {
        existTags.push(tag);
      } else if (item.tagExists === 'Does Not Exist') {
        notExistTags.push(tag);
      }
    });
    const result = {
      name: formRef.current.values.segmentName,
      condition: formRef.current.values.conditionCheck,
      coachId: user.id,
      existTags: existTags,
      notExistTags: notExistTags,
    };

    console.log('Values------>', result);
    if (editing === null) {
      postSegment(result)
        .then(res => {
          console.log('res', res);
          setSegmentId(res.id);
          onClose();
        })
        .catch(err => {
          console.log('err', err?.response?.data?.message);
          setSegmentError(err?.response?.data?.message);
        });
    } else {
      putSegment({ ...result, id: editing.id })
        .then(() => {
          setSegmentId(editing?.id);
          onClose();
        })
        .catch(err => {
          console.log('err', err?.response?.data?.message);
          setSegmentError(err?.response?.data?.message);
        });
    }
  };
  useEffect(() => {
    dispatch(fetchAllTagsAsync());
  }, []);

  const postSegment = async segment => {
    const response = await ContactsServices.postSegments(segment);
    return response;
  };
  const putSegment = async segment => {
    const response = await ContactsServices.putSegments(segment);
    return response;
  };
  const GuideComponent = () => {
    return <></>; //uncomment this to enable functionality
    return (
      <StyledWrapper>
        <IconWrapper>
          <StyledIcon
            path={mdiHelpCircle}
            color="#215C73"
            onClick={() => {
              setGuidePopUp(true);
            }}
          />
        </IconWrapper>
        <StyledLable
          onClick={() => {
            setGuidePopUp(true);
          }}
        >
          Guide
        </StyledLable>
      </StyledWrapper>
    );
  };
  const transformData = data => {
    const segmentConditions = [];

    data.existTags.forEach(tag => {
      segmentConditions.push({
        tagName: tag.name,
        tagExists: 'Exists',
        tagId: tag.tagId,
      });
    });

    data.notExistTags.forEach(tag => {
      segmentConditions.push({
        tagName: tag.name,
        tagExists: 'Does Not Exist',
        tagId: tag.tagId,
      });
    });

    return {
      conditionCheck: data.condition,
      segmentConditions: segmentConditions,
      segmentName: data.name,
    };
  };

  const outputData = editing != null ? transformData(editing) : null;
  console.log('Output Data------>', outputData, editing);

  useEffect(() => {
    if (editing != null) {
      formRef.current.setFieldValue('conditionCheck', outputData.conditionCheck);
      formRef.current.setFieldValue('segmentConditions', outputData.segmentConditions);
      formRef.current.setFieldValue('segmentName', outputData.segmentName);
    }
  }, [outputData, editing]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        conditionCheck: 'And',
        segmentName: '',
        segmentConditions: [
          {
            tagName: 'Tag',
            tagExists: 'Does Not Exist',
            tagId: '',
          },
        ],
      }}
      validationSchema={Yup.object().shape({
        segmentName: Yup.string().required('This is a required field'),
      })}
      onSubmit={handleSubmit}
    >
      {formProps => (
        <>
          <Modal
            isOpen={isOpen}
            onCancel={onClose}
            onSubmit={() => {
              formRef.current.handleSubmit();
            }}
            submitTitle="Done"
            title="Create a New Segment"
            GuideComponent={<GuideComponent />}
            widthRequired
            disableOverlayClick
          >
            <div className={classes.container}>
              <div className={classes.segment_name}>
                <LabelInput>Segment Name</LabelInput>
                <Input
                  underLineColor="#e7e7e7"
                  type="text"
                  name="segmentName"
                  value={formProps.values?.segmentName}
                  fullWidth
                  redError={true}
                  onChange={e => {
                    formProps.setFieldValue('segmentName', e.target.value);
                    setSegmentError(null);
                  }}
                  placeholder="New Test Segment"
                />
              </div>

              <div className={classes.segment_groups}>
                {formProps.values.segmentConditions.map((segmentGroup, index) => (
                  <SegmentGroup
                    key={index}
                    segmentGroupIndex={index}
                    segmentGroup={segmentGroup}
                    tags={tags}
                    formProps={formProps}
                  />
                ))}
              </div>
              {segmentError && <StyledError>{segmentError}</StyledError>}
              {/* <div className={classes.addNewSgementGroupBtn_container}>
                        <button className={classes.addSegmentGroupBtn} type="button" onClick={addSegmentGroup}>
                          Add New Segment Group
                        </button>
                      </div> */}
            </div>
          </Modal>
          <GuideVideoModal isOpen={guidePopUp} setOpen={setGuidePopUp} />
        </>
      )}
    </Formik>
  );
};

export default CreateSegmentModal;
