import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import TextBlock from './TextBlock';
import { TemplateType } from 'helpers/constants/templateType';

const DATE_VIEW = 'MMM Do YYYY';

const formatTime = date => {
  return moment(date).format(DATE_VIEW);
};

const CustomEnrollmentBlock = ({ enrollment, userNotLoggedIn, themedColor }) => {
  const fromDate = enrollment?.fromDate;
  const toDate = enrollment?.toDate;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const EnrolDateMainDiv = styled.div``;
  const EnroleDateP = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
  `;
  const MainDivDate = styled.div`
    display: flex;
    justify-content: flex-start;
  `;
  const DateStart = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  `;

  const DateEnd = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #282b2b;
  `;
  return (
    <>
      <div
        style={
          userNotLoggedIn
            ? {}
            : { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '4px 20px' }
        }
      >
        <EnroleDateP style={{ color: themedColor }}>Enrollment Dates</EnroleDateP>
        <MainDivDate>
          <DateStart style={{ color: themedColor }}>{`${formatTime(fromDate)} - `} &nbsp;</DateStart>
          <DateEnd style={{ color: themedColor }}>{formatTime(toDate)}</DateEnd>
        </MainDivDate>
      </div>

      {/* <Card mobileView={mobileView} maxHeight>
        <CardHeader mobileView={mobileView}>
          <PageTitleSecond mobileView={mobileView}>Enrollment Dates</PageTitleSecond>
        </CardHeader>
        <CardBody mobileView={mobileView}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <TextBlock mobileView={mobileView} image={null} title="From" text={formatTime(fromDate)} />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextBlock mobileView={mobileView} image={null} title="To" text={formatTime(toDate)} />
            </Grid>
          </Grid>
        </CardBody>
      </Card> */}
    </>
  );
};

CustomEnrollmentBlock.propTypes = {
  enrollment: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }).isRequired,
  themedColor: PropTypes.string.isRequired,
};

export default CustomEnrollmentBlock;
