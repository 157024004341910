import Modal from 'components/UI/Modal';
import PropTypes, { string } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { DeleteIcon } from 'pages/Profile/components/icons/Delete';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import useUploadResources, { FILE_UPLOAD_STATUSES } from 'hooks/useUploadResources';
import { getCommunityGroupResources } from 'services/communitySections.service';
import { updateCurrentGroupData } from 'actions/communitySections';
import ImageDropzone from '../../../../components/FormUI/ImageDropone';
import UploadSvg from './icons/UploadIcon';

function ResourcesModal({
  contributionId,
  title,
  onCancel,
  isOpen,
  darkThemeEnabled,
  onDeleteResourceClick,
  updateCurrentGroup,
  isDarkThemeEnabled,
  themedColors,
}) {
  const [submitTitle, setSubmitTitle] = useState('');
  const [resources, setResources] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { currentGroup } = useSelector(state => state.communitySections);

  const {
    filesInfo,
    handleUploadFile,
    cancelAllRequestsToEndpoint,
    cancelRequestToFileAtIndex,
    filesUploadCompleted,
  } = useUploadResources(contributionId, currentGroup?.id);

  useEffect(() => {
    setSubmitTitle(title?.toLowerCase().includes('create') ? 'Create' : 'Done');
  }, [title]);

  const addFileHandler = (droppedFiles = []) => {
    setDisableSubmit(true);
    setResources(resources.concat(droppedFiles));
    handleUploadFile(droppedFiles);
  };

  useEffect(() => {
    setDisableSubmit(!filesUploadCompleted);
  }, [filesUploadCompleted]);

  const onDelete = index => {
    const newResources = [...resources];
    newResources.splice(index, 1);
    setResources(newResources);
    cancelRequestToFileAtIndex(index);

    // deleting file from backend
    const documentIds = Object.keys(filesInfo);
    const documentId = documentIds[index];
    const isNotCompletedFile = filesInfo[documentId]?.status !== FILE_UPLOAD_STATUSES.completed;
    const fileName = resources[index]?.name || '';
    const lastDotIndex = fileName.lastIndexOf('.');
    const fileExtension = fileName.slice(lastDotIndex + 1)?.toLowerCase();
    onDeleteResourceClick(documentId, isNotCompletedFile, fileExtension);
  };

  const onCloseModal = () => {
    getCommunityGroupResources({ contributionId, groupId: currentGroup?.id }).then(res => {
      updateCurrentGroup({
        group: {
          ...currentGroup,
          resources: res,
        },
      });
    });
    cancelAllRequestsToEndpoint();
    onCancel();
  };

  return (
    <Modal
      className="add-community-resources-popup"
      isOpen={isOpen}
      title="Add Resources"
      applyTheming
      submitTitle={submitTitle}
      hiddenCancel
      onCancel={onCloseModal}
      onSubmit={onCloseModal}
      disableSubmitClick={disableSubmit}
      disableOverlayClick
    >
      <ImageDropzone
        className={`upload-resource-container ${isDarkThemeEnabled ? 'dark-mode' : ''}`}
        style={{ backgroundColor: isDarkThemeEnabled ? themedColors.themedCardBackgroundColor : '' }}
        action={droppedFiles => {
          addFileHandler(droppedFiles);
        }}
        placeholder={
          <div className="upload-group-resource-button">
            <UploadSvg color={isDarkThemeEnabled ? 'white' : 'black'} />
            <p>Drag and drop your file</p>
          </div>
        }
        multiple
      />
      {resources?.map((file, index) => {
        return (
          <div className="community-group-files-container">
            <p>{file?.name}</p>
            <CircularProgressWithLabel
              className="upload-progress"
              value={Object.values(filesInfo)?.length > index && Object.values(filesInfo)[index].progress}
            />
            <span
              className="delete-files-icon"
              tabIndex="0"
              role="button"
              onClick={() => onDelete(index)}
              onKeyPress={() => {}}
            >
              <DeleteIcon color={darkThemeEnabled ? 'white' : 'black'} />
            </span>
          </div>
        );
      })}
    </Modal>
  );
}

const actions = {
  updateCurrentGroup: updateCurrentGroupData,
};

ResourcesModal.propTypes = {
  contributionId: PropTypes.string.isRequired,
  darkThemeEnabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  section: PropTypes.shape({ sectionTitle: string }).isRequired,
  onDeleteResourceClick: PropTypes.func.isRequired,
  updateCurrentGroup: PropTypes.func.isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
  themedColors: PropTypes.shape({ themedCardBackgroundColor: string }).isRequired,
};

export default connect(() => {}, actions)(ResourcesModal);
