import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import { ProfileAvatar } from 'components/ProfileAvatar/ProfileAvatar';
import { ProfileIcon } from '../icons/Profile';
import '../../../../components/ProfileAvatar/ProfileAvatar.scss';
import { EditIcon } from '../icons/Edit';
import ChangeEmailModal from './ChangeEmail';
import Modal from 'components/UI/Modal';
import { TOOLTIP } from '../../../../constants';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import useAccount from 'hooks/useAccount';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';

const PersonalInformation = ({ countries, timeZones, values, setFieldValues, errors }) => {
  const { user } = useAccount();
  const { verifiedSendingAddresses } = useEmailMarketing();
  const showAddressField = user?.isSendGridConnected && verifiedSendingAddresses?.length > 0;
  console.log('values--->', values);
  const Email = (values && values.Email) || '';
  const Address = (values && values.Address) || '';
  const FirstName = (values && values.FirstName) || '';
  const LastName = (values && values.LastName) || '';
  const CountryId = (values && values.CountryId) || '';
  const Profession = (values && values.profession) || 'coach';
  const BirthDate = (values && values.BirthDate) || '';
  const TimeZoneId = (values && values.TimeZoneId) || '';
  const CustomBusinessType = (values && values.CustomBusinessType) || '';
  const Occupation = (values && values.Occupation) || '';
  const Certification = (values && values.Certification) || '';
  const AvatarUrl = (values && values.AvatarUrl) || '';
  const [showDomainPopup, setShowDomainPopup] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const viewTabButtonsData = [
    { title: 'Coach', value: 'coach' },
    { title: 'Healer', value: 'healer' },
    { title: 'Teacher', value: 'teacher' },
    { title: 'Mentor', value: 'mentor' },
    { title: 'Consultant', value: 'consultant' },
    { title: 'Other', value: 'other' },
  ];
  const togglePopup = () => {
    setSuccessPopUp(!successPopUp);
  };
  return (
    <Grid container className="profile-section">
      <Grid item xs={12} className="section-title">
        <ProfileIcon />
        <p>Personal Information</p>
      </Grid>
      <Grid item xs={12} sm={4} md={12} lg={3} xl={2} className="profile-picture">
        <div
          id={'profilePicture'}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}
        >
          <p className="field-title">Profile Picture</p>
          <div style={{ height: '220px' }}>
            <ProfileAvatar avatarUrl={AvatarUrl} updateUserImg={res => setFieldValues('AvatarUrl', res)} isCoach />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={12} lg={9} xl={10}>
        <div className="d-flex">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p className="field-title">First Name</p>
              <TextField
                name="FirstName"
                className="input-field"
                id="outlined-basic"
                margin="normal"
                variant="outlined"
                fullWidth
                value={FirstName}
                error={Boolean(errors.FirstName)}
                helperText={errors.FirstName}
                onChange={e => setFieldValues('FirstName', e.target.value)}
                FormHelperTextProps={{
                  style: {
                    textAlign: 'right',
                    marginRight: 0,
                  },
                }}
                inputProps={{ maxLength: 100 }}
                helperText={`${values.FirstName.length}/100`}
              />
            </Grid>
            <Grid item xs={6}>
              <p className="field-title">Last Name</p>
              <TextField
                className="input-field"
                id="outlined-basic"
                margin="normal"
                variant="outlined"
                fullWidth
                value={LastName}
                error={Boolean(errors.LastName)}
                helperText={errors.LastName}
                onChange={e => setFieldValues('LastName', e.target.value)}
                FormHelperTextProps={{
                  style: {
                    textAlign: 'right',
                    marginRight: 0,
                  },
                }}
                inputProps={{ maxLength: 100 }}
                helperText={`${values.LastName.length}/100`}
              />
            </Grid>
          </Grid>
        </div>
        <div className="d-flex">
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <p className="field-title">Date of Birth</p>
              <TextField
                className="input-field"
                id="outlined-basic"
                margin="normal"
                variant="outlined"
                type="date"
                fullWidth
                value={BirthDate}
                onChange={e => setFieldValues('BirthDate', e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <p className="field-title">Timezone</p>
              <Select
                className="select-field"
                label="Timezone"
                name="TimeZoneId"
                variant="outlined"
                fullWidth
                input={<OutlinedInput margin="dense" />}
                value={TimeZoneId}
                onChange={e => setFieldValues('TimeZoneId', e.target.value)}
              >
                {timeZones?.length > 0 &&
                  timeZones.map(timeZone => {
                    return <MenuItem value={timeZone.countryName}>{timeZone.name}</MenuItem>;
                  })}
              </Select>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={6}>
          <Grid item sm={12} xs={12}>
            <p className="field-title">Country</p>
            <Select
              className="select-field"
              label="Country"
              name="CountryId"
              variant="outlined"
              disabled
              input={<OutlinedInput margin="dense" />}
              fullWidth
              value={CountryId}
              onChange={e => setFieldValues('CountryId', e.target.value)}
            >
              {countries?.length > 0 &&
                countries.map(country => {
                  return <MenuItem value={country.id}>{country.name}</MenuItem>;
                })}
            </Select>
          </Grid>
        </Grid>
        {showAddressField && (
          <Grid>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <p className="field-title">Physical Address</p>
                  <Tooltip
                    title="Businesses must include a physical address in the footer of all email campaigns, as required by laws such as the CAN-SPAM Act in the United States. These laws aim to prevent the sending of unsolicited, fraudulent, or deceptive emails, promoting transparency and reducing spam."
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon style={{ marginTop: '15px', marginLeft: '5px' }} htmlColor={colors.lightBrown} />
                  </Tooltip>
                </div>
                <TextField
                  className="input-field"
                  id="outlined-basic"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  value={Address}
                  onChange={e => setFieldValues('Address', e.target.value)}
                  // error={Boolean(errors.Address)}
                  FormHelperTextProps={{
                    style: {
                      textAlign: 'right',
                      marginRight: 0,
                    },
                  }}
                  inputProps={{ maxLength: 255 }}
                  helperText={`${values?.Address?.length}/255`}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid>
          <Grid container spacing={showAddressField ? 2 : 6}>
            <Grid item sm={12} xs={12}>
              <p className="field-title" style={{ marginTop: '0px' }}>
                Email
              </p>
              <TextField
                className="input-field"
                id="outlined-basic"
                margin="normal"
                variant="outlined"
                fullWidth
                disabled
                value={Email}
                onClick={() => setShowDomainPopup(true)}
                InputProps={{
                  endAdornment: <EditIcon />,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <div className="d-flex">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p className="field-title">Business Type</p>
              <TextField
                className="input-field"
                id="outlined-basic"
                margin="normal"
                variant="outlined"
                fullWidth
                value={CustomBusinessType}
                onChange={e => setFieldValues('CustomBusinessType', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <p className="field-title">Occupation</p>
              <TextField
                className="input-field"
                id="outlined-basic"
                margin="normal"
                variant="outlined"
                fullWidth
                value={Occupation}
                onChange={e => setFieldValues('Occupation', e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={12}>
          <Grid container spacing={6}>
            <Grid item sm={12} xs={12}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p className="field-title">What is your profession</p>
                <Tooltip
                  title="If you are participating in the Cohere Collective, you will be placed in different categories of Service Providers. Please select the category that closely resembles the work you do."
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon style={{ marginTop: '15px', marginLeft: '5px' }} htmlColor={colors.lightBrown} />
                </Tooltip>
              </div>
              <Select
                className="select-field"
                label="Country"
                name="CountryId"
                variant="outlined"
                input={<OutlinedInput margin="dense" />}
                fullWidth
                value={Profession}
                onChange={e => setFieldValues('profession', e.target.value)}
              >
                {viewTabButtonsData.map(value => (
                  <MenuItem value={value.value} onClick={() => {}}>
                    {value.title}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          {/* <Grid item sm={12} md={12} xs={12}>
            <p className="field-title">Certification / Degree</p>
            <TextField
              className="input-field"
              id="outlined-basic"
              margin="normal"
              variant="outlined"
              fullWidth
              placeholder="Insert Url"
              value={Certification}
              onChange={e => setFieldValues('Certification', e.target.value)}
            />
          </Grid> */}
        </Grid>
      </Grid>
      {showDomainPopup && (
        <ChangeEmailModal
          isOpen={true}
          setIsOpen={setShowDomainPopup}
          currentDomain={''}
          setNewDomain={newValue => {}}
          setSuccessPopUp={setSuccessPopUp}
        />
      )}
      <Modal
        title="Email Change Request"
        isOpen={successPopUp}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
      >
        A verification email has been sent to your updated email address. Once verified, your account will be updated.
      </Modal>
    </Grid>
  );
};

PersonalInformation.propTypes = {
  user: PropTypes.shape({
    avatarUrl: PropTypes.string,
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeZones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  values: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    CountryId: PropTypes.string,
    BirthDate: PropTypes.string,
    TimeZoneId: PropTypes.string,
    CustomBusinessType: PropTypes.string,
    Occupation: PropTypes.string,
    AvatarUrl: PropTypes.string,
    Certification: PropTypes.string,
    AvatarUrl: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
  }).isRequired,
  setFieldValues: PropTypes.func.isRequired,
};

export default PersonalInformation;
