import React from 'react';
import { openInNewWindow } from 'services/links';

export const WorkTogetherIcon = props => {
  return (
    <svg
      width="24"
      height="24"
      onClick={() => props.link && openInNewWindow(props.link)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6414 16.3565C21.3779 17.6706 22.5016 19.7935 22.5016 22.2197H7.58789C7.58789 19.6925 8.71152 17.5696 10.448 16.2554"
        stroke="#213649"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.1285 9.27949C19.1285 10.6948 18.4135 12.0089 17.2899 12.7166C16.677 13.1209 15.8598 13.4242 14.9404 13.4242C14.1232 13.4242 13.3061 13.222 12.6932 12.7166C11.5695 12.0089 10.8545 10.6948 10.8545 9.27949C10.8545 6.9544 12.6932 5.13477 15.0426 5.13477C17.2899 5.13477 19.1285 6.9544 19.1285 9.27949Z"
        stroke="#213649"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.5 20.084C1.5 17.5567 2.62363 15.4338 4.36016 14.1196"
        stroke="#213649"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.71035 10.4807C5.58672 9.87417 4.76953 8.55999 4.76953 7.14472C4.76953 4.81963 6.71035 3 8.95762 3"
        stroke="#213649"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
