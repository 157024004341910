import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { VerticalTimelineLockIcon as ContentLockIcon } from '../icons';
import { hexToRgba } from 'utils/utils';
import { useContribution } from 'pages/ContributionView/hooks';
import { getThemedColors } from 'services/contributions.service';
const StyledBody = styled.div`
  display: flex;
  padding: 8.5rem 1.5625rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  flex: 1 0 0;
`;
const StyledText = styled.p`
  color: ${({ textColor }) => textColor || 'rgba(0, 0, 0, 0.87)'};
  font-family: Avenir;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 133.333% */
`;
const StyledIconWrapper = styled.div`
  display: flex;
  width: 3.75rem;
  height: 3.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 2.5625rem;
  background: ${({ backgroundColor }) =>
    backgroundColor ? hexToRgba(backgroundColor, 0.4) : 'var(--Cohere-Bluish-Grey-Tint-06, #f1f8ff)'};
  svg {
    width: 1.61906rem;
    height: 2.125rem;
    flex-shrink: 0;
  }
`;
const SelfPacedLocked = ({ colorToUse, isFuture, date }) => {
  const contribution = useContribution();
  const startTimeMoment = moment(date);
  const { themedColor, newThemedTextColor } = getThemedColors(contribution);
  return (
    <StyledBody>
      <StyledIconWrapper>
        <ContentLockIcon fillColor={colorToUse.AccentColorCode} />
      </StyledIconWrapper>
      <StyledText textColor={contribution?.isDarkModeEnabled ? themedColor : newThemedTextColor}>
        {isFuture ? (
          <>Content will be available on {startTimeMoment.format('MMMM Do YYYY')}</>
        ) : (
          <>Please complete the prior content first.</>
        )}
      </StyledText>
    </StyledBody>
  );
};

export default SelfPacedLocked;
