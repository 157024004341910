import React, { useState } from 'react';
import classes from './ImportFileContent.module.scss';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import TextField from 'components/UI/TextField';
import FileDropZone from '../FileDropZone/FileDropZone';
import Content from './Content/Content';
import Modal from 'components/UI/Modal';
import { ContactsServices } from 'pages/Contacts/Services/Contacts.service';
import { fetchAllContactsAsync } from 'actions/contacts';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import { parse } from 'csv-parse/lib/sync';
import Select from 'components/UI/Select';

const ImportFileContent = ({ isLeadPage, title = 'Import CSV file', isOpen, onCancel }) => {
  const dispatch = useDispatch();
  const { tags } = useSelector(state => state.contacts.tags);
  const [typeOfData, setTypeOfData] = useState(isLeadPage ? 'Leads' : 'Clients');
  const [tagIds, setTagIds] = useState([]);
  const [tagName, setTagName] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [stringFile, setStringFile] = useState(null);

  const onCrossClick = () => {
    setSelectedFile(null);
    setStringFile(null);
  };

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={() => {
        setUploading(true);
        const data = {
          csvFileContent: stringFile,
          isLead: typeOfData === 'Leads' ? true : false,
          tagIds: tagIds,
        };
        ContactsServices.importContacts(data)
          .then(res => {
            console.log('res-->', res);
            setUploading(false);
            onCancel();
            dispatch(fetchAllContactsAsync({}));
          })
          .catch(err => {
            console.log('err', err);
            setUploading(false);
          });
      }}
      submitTitle="Import"
      reduceCancelButton
      widthRequired
    >
      <div className={classes.main}>
        {uploading && <Loader />}
        <div className={classes.heading}>
          You are about to import a CSV file. Confirm whether you are importing clients or leads. Make sure the columns
          are in this order with no spaces in the names (e.g. FirstName, LastName, Email).
        </div>
        <div>
          <RadioGroup
            value={typeOfData}
            onChange={e => {
              setTypeOfData(e.target.value);
            }}
          >
            <div className={classes.radio_buttons}>
              <FormControlLabel value="Leads" label="Leads" control={<Radio color="primary" />} />
              <FormControlLabel value="Clients" label="Clients" control={<Radio color="primary" />} />
            </div>
          </RadioGroup>
        </div>
        <div className={classes.file_dropzone_container}>
          {selectedFile ? (
            <Content file={selectedFile} onCrossClick={onCrossClick} />
          ) : (
            <FileDropZone
              action={files => {
                setLoading(true);
                const file = files[0];
                if (file) {
                  setSelectedFile(file);
                  const reader = new FileReader();
                  reader.onload = e => {
                    const csvData = e.target.result;
                    const records = parse(csvData, {
                      columns: true,
                      skip_empty_lines: true,
                    });
                    let formattedData = 'Email,FirstName,LastName\\r\\n';
                    formattedData += records
                      .map(record => `${record.Email},${record.FirstName},${record.LastName}\\r\\n`)
                      .join('');
                    formattedData = formattedData.trim();
                    setStringFile(formattedData);
                  };
                  reader.readAsText(file);
                }
                setLoading(false);
              }}
              isDisabled={false}
              placeholder={
                <div className={classes.placeholder}>
                  or drop your <strong style={{ margin: '0px 4px' }}>CSV</strong> file here
                </div>
              }
              type=".csv"
              loading={loading}
              progress={progress}
            />
          )}
        </div>
        <div className={classes.tagname_container}>
          <div className={classes.main_container}>
            <div className={classes.heading}>Add Tags</div>
            {/* {tagName?.length > 0 && (
              <div className={classes.tag_container}>
                <div className={classes.tag}>{tagName}</div>
              </div>
            )} */}
            {/* <TextField
              fullWidth
              value={tagName}
              onChange={e => {
                setTagName(e.target.value);
              }}
              inputClasses={{
                underline: classes.input_underline,
              }}
            /> */}
            <Select
              fullWidth
              value={tagIds}
              onChange={e => {
                setTagIds(e.target.value);
              }}
              items={tags?.map(t => ({ title: t.name, value: t.id }))}
              multiple
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImportFileContent;
