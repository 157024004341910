import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useAccount } from 'hooks';

import Banner from 'components/Banner/Banner';

import './BannerContainer.scss';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';

const BannerContainer = ({ closestClassForBanner, coachDeclinedSubscriptions, pendingApplicationsCount }) => {
  const [bannerTypes, setBannerTypes] = useState([]);
  const { user } = useAccount();
  const { verifiedSendingAddresses } = useEmailMarketing();
  const isSendGridConnected = user?.isSendGridConnected;

  const getType = useCallback(() => {
    const types = [];
    if (user.standardAccountTransfersEnabled === false && user.isStandardAccount === true) {
      types.push('standardAccountTransfersEnabled');
    }
    if (!user.isEmailConfirmed) {
      types.push('email');
    }
    if (isSendGridConnected && verifiedSendingAddresses?.length > 0 && !user.address) {
      types.push('address');
    }
    if ((!user.transfersEnabled || !user.payoutsEnabled) && user.connectedStripeAccountId != null) {
      types.push('bankInfo');
    }
    if (!isEmpty(closestClassForBanner)) {
      types.push('closestSession');
    }
    if (user.transfersEnabled) {
      types.push('testVideo');
    }
    // if (!user.isSocialSecurityCheckPassed && user.isFirstAcceptedCourseExists && !user.connectedStripeAccountId) {
    //   types.push('socialSecurityInfo');
    // }
    if (user.payoutsEnabled) {
      types.push('moreInfoRequired');
    }
    if (coachDeclinedSubscriptions && coachDeclinedSubscriptions.length > 0) {
      types.push('coachDeclinedSubscriptions');
    }
    if (pendingApplicationsCount) {
      types.push('pendingApplicationsCount');
    }
    setBannerTypes(types);
  }, [
    user.isEmailConfirmed,
    user.transfersEnabled,
    user.payoutsEnabled,
    user.address,
    closestClassForBanner,
    coachDeclinedSubscriptions,
    user.isSocialSecurityCheckPassed,
    user.standardAccountTransfersEnabled,
    pendingApplicationsCount,
    setBannerTypes,
  ]);

  useEffect(() => {
    getType();
  }, [getType]);

  return (
    <div className="banners-cohealer">
      {bannerTypes.map(type => (
        <Banner
          type={type}
          closestClassForBanner={closestClassForBanner}
          coachDeclinedSubscriptions={coachDeclinedSubscriptions}
        />
      ))}
    </div>
  );
};

BannerContainer.defaultProps = {
  closestClassForBanner: {},
  pendingApplicationsCount: false,
};

BannerContainer.propTypes = {
  closestClassForBanner: PropTypes.shape({
    contributionId: PropTypes.string.isRequired,
    classId: PropTypes.string.isRequired,
    contributionTitle: PropTypes.string.isRequired,
    minutesLeft: PropTypes.number.isRequired,
  }),
  coachDeclinedSubscriptions: PropTypes.shape({}),
  pendingApplicationsCount: PropTypes.bool,
};

export default BannerContainer;
