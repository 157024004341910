import { handleActions } from 'redux-actions';

import * as actions from 'actions/easyBooking';
import moment from 'moment';

const initialState = {
  selectedDate: moment(),
  selectedSlot: null,
  timeZone: '',
  code: null,
};

const easyBooking = handleActions(
  new Map([
    [
      actions.setEasyBookingData.setData,
      (state, action) => ({
        ...state,
        ...action.payload,
      }),
    ],
  ]),
  initialState,
);

export default easyBooking;
