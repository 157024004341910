import React, { useEffect } from 'react';

import { ContributionType } from 'helpers/constants';
import useContribution from '../hooks/useContribution';
import CourseSessions from './CourseSessions';
import OneToOneSessions from './OneToOneSessions';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as contributionActions from 'actions/contributions';
import { useAccount, useChangedProps, useEndVideoChat, useRouter, useShallowEqualSelector } from 'hooks';
import { isNil } from 'ramda';
import { stickHeader } from 'actions/header';

const ContributionSessionsContainer = props => {
  const { user, currentRole } = useAccount();
  const { history, query } = useRouter();
  const contribution = useContribution();
  const dispatch = useDispatch();

  const SESSIONS_MAPPING = {
    [ContributionType.contributionCourse]: (user, currentRole, contribution, props) => (
      <CourseSessions user={user} currentRole={currentRole} contribution={contribution} {...props} />
    ),
    [ContributionType.contributionOneToOne]: (user, currentRole, contribution, props) => (
      <OneToOneSessions user={user} currentRole={currentRole} contribution={contribution} {...props} />
    ),
    [ContributionType.contributionMembership]: (user, currentRole, contribution, props) => (
      <CourseSessions user={user} currentRole={currentRole} contribution={contribution} {...props} />
    ),
    [ContributionType.contributionCommunity]: (user, currentRole, contribution, props) => (
      <CourseSessions user={user} currentRole={currentRole} contribution={contribution} {...props} />
    ),
  };

  // useEffect(() => {
  //   if (!isNil(query?.code)) {
  //     dispatch(contributionActions.setInviteCode(query?.code));
  //   }
  //   // else {
  //   //   dispatch(contributionActions.setInviteCode(null));
  //   // }
  // }, [dispatch, query?.code]);

  useEffect(() => {
    dispatch(stickHeader());
  }, []);

  return SESSIONS_MAPPING[contribution.type] ? (
    SESSIONS_MAPPING[contribution.type](user, currentRole, contribution, props)
  ) : (
    <div>No sessions yet.</div>
  );
};

export default ContributionSessionsContainer;
