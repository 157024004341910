import React, { useEffect, useMemo, useState, useCallback } from 'react';

import { TOOLTIP } from 'pages/ContributionView/ContributionSessions/constants';
import { colors } from 'utils/styles';
import DetailsContainerWithHeader from './DetailsContainerWithHeader';
import useScreenView from 'hooks/useScreenView';
import InputWithTitle from './InputWithTitle';
import { FlexContainer, FlexColumn, StyledBannerTitleRow } from './StyleComponents';
import { StyledTitle, StyledInfoIcon, StyledTooltip, StyledInput } from './SharedStyledComponents';
import { Switch, StyledInput as SwitchInput, StyledSlider } from 'components/switch/style';
import BannerUploader from './BannerUploader';
import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import {
  createAccessCode,
  getAllApprovedContributionsForCoach,
  getContributionFromList,
  getContributions,
} from 'services/contributions.service';
import { ContributionType } from 'helpers/constants';
import styled, { css } from 'styled-components';
import { CrossOutlineIcon } from 'pages/LeadMagnetView/components/Icon';

const StyledCTAValueContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
`;

const StyledOrLabel = styled.div`
  display: inline-block;
  ${({ disabled }) =>
    disabled &&
    css`
      color: rgba(0, 0, 0, 0.38);
    `}
`;

const LeadMagnetAddtionalCall = ({ values, errors, touched, setFieldValue, addFilesToDelete }) => {
  const { isVideoBanner, bannerImage, isCustomCTAEnabled, selectedContributionId } = values?.additionalThankYou;
  // const [addCTA, setAddCTA] = useState(isCustomCTA);
  const { mobileView } = useScreenView();

  // const [oneOneContributions, setOneOneContribution] = useState([]);
  const [contributionsList, setContributionsList] = useState([]);

  useEffect(() => {
    // getContributionFromList('ContributionOneToOne').then(setContributionsList);
    getAllApprovedContributionsForCoach().then(setContributionsList);
  }, []);

  useEffect(() => {
    if (selectedContributionId) {
      createAccessCode(selectedContributionId)
        .then(data => {
          const url = `/contribution-view/${selectedContributionId}/${data?.code}/about`;
          setFieldValue('additionalThankYou.selectedContributionUrl', url);
        })
        .catch(() => {
          setFieldValue('additionalThankYou.selectedContributionUrl', null);
        });
    } else {
      setFieldValue('additionalThankYou.selectedContributionUrl', null);
    }
  }, [selectedContributionId]);

  const oneOneContributions = useMemo(() => {
    return (
      contributionsList
        // .filter(c => c?.type === 'ContributionOneToOne')
        .map(c => ({
          value: c.id,
          title: c.title,
        })) || []
    );
  }, [contributionsList]);

  const getFormProps = fieldName => {
    const fieldPath = `additionalThankYou.${fieldName}`;
    return {
      // value: values?.[fieldPath],
      value: values?.additionalThankYou?.[fieldName],
      name: fieldPath,
      onChange: v => {
        setFieldValue(fieldPath, v);
      },
    };
  };
  const onBannerUploadHander = (filePath, isVideo) => {
    const currentBanner = values?.additionalThankYou?.bannerImage;
    addFilesToDelete(currentBanner);
    setFieldValue('additionalThankYou.bannerImage', filePath);
    setFieldValue('additionalThankYou.isVideoBanner', isVideo);
  };
  const removeBanner = useCallback(() => {
    // deletePublicImage(values?.additionalThankYou?.bannerImage).then(() => {
    //   updateBannerInState('', false);
    // });
    onBannerUploadHander('', false);
  }, [values?.additionalThankYou?.bannerImage]);

  const onChangeSelectedContribution = e => {
    const id = e.target.value;
    setFieldValue('additionalThankYou.selectedContributionId', id);
  };

  return (
    <DetailsContainerWithHeader
      firstHeading="Additional Call to Action"
      isOptional
      headingStyles={
        mobileView
          ? css`
              color: rgba(0, 0, 0, 0.87);
              font-family: Avenir;
              font-size: 1.0625rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            `
          : css`
              color: rgba(0, 0, 0, 0.87);
              font-family: Avenir;
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            `
      }
    >
      <FlexContainer mobileView={mobileView}>
        <FlexColumn>
          <StyledBannerTitleRow>
            <StyledTitle>Banner</StyledTitle>
            {bannerImage && (
              <div
                role="button"
                tabIndex={0}
                style={{ cursor: 'pointer' }}
                onClick={removeBanner}
                onKeyUp={removeBanner}
              >
                <CrossOutlineIcon />
              </div>
            )}
          </StyledBannerTitleRow>
          <div style={{ marginTop: '8px' }}>
            <BannerUploader {...{ onBannerUpload: onBannerUploadHander, isVideoBanner, bannerImage }} />
          </div>
        </FlexColumn>
        <FlexColumn>
          <InputWithTitle
            wordLimit={80}
            title="Header"
            toolTip="This is the header text that displays above the additional CTA section of the Thank You page."
            formprops={getFormProps('header')}
            textField
            placeholder="Want to book a call to learn more?"
          />
          <InputWithTitle
            wordLimit={500}
            title="Description"
            // toolTip="ToolTip"
            formprops={getFormProps('description')}
            textArea
            placeholder="After your clients provide their email on the opt-in page, they’ll be redirected to a thank you page. Please write a short description here that entices your leads to finish accessing your lead magnet!"
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
            <FlexContainer style={{ justifyContent: 'flex-start', gap: '0.625rem' }}>
              <StyledTitle>Add Custom CTA</StyledTitle>
              <StyledTooltip
                title="Enter the button text and link for additional CTA section, these will also display on the Thank You page."
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                style={{ margin: 0 }}
              >
                <StyledInfoIcon htmlColor={colors.lightBrown} />
              </StyledTooltip>
            </FlexContainer>
            <Switch className="switch" style={{ marginBottom: 0 }}>
              <SwitchInput
                type="checkbox"
                onClick={() => {
                  if (isCustomCTAEnabled) {
                    setFieldValue('additionalThankYou.isCustomCTAEnabled', false);
                  } else {
                    setFieldValue('additionalThankYou.isCustomCTAEnabled', true);
                  }
                }}
                checked={isCustomCTAEnabled}
              />
              <StyledSlider className="slider round" />
            </Switch>
          </div>
          <InputWithTitle
            wordLimit={20}
            formprops={getFormProps('ctaButtonText')}
            textField
            disabled={!isCustomCTAEnabled}
            mb={false}
            placeholder="Button Text"
          />
          <StyledCTAValueContainer {...{ mobileView }}>
            <InputWithTitle
              formprops={getFormProps('url')}
              textField
              disabled={!isCustomCTAEnabled}
              mb={false}
              mainContainerStyles={{ width: mobileView ? '100%' : '45%' }}
              placeholder="Enter URL"
            />
            <StyledOrLabel disabled={!isCustomCTAEnabled}>OR</StyledOrLabel>
            <div
              style={{
                minWidth: 120,
                width: mobileView ? '100%' : '45%',
                marginTop: '-4px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                columnGap: '0.5rem',
                ...(mobileView ? { marginTop: '0.5rem' } : {}),
              }}
            >
              <Select
                style={{ overflow: 'hidden' }}
                className="select-field"
                label="selectedContributionId"
                name="additionalThankYou.selectedContributionId"
                variant="outlined"
                disabled={!isCustomCTAEnabled}
                fullWidth
                defaultValue={0}
                input={<OutlinedInput margin="dense" />}
                onChange={onChangeSelectedContribution}
                // value={values?.additionalThankYou?.selectedContributionId || 0}
                // Note: if a contribution is deleted, there will an ID but no contribution selected
                value={selectedContributionId || 0}
              >
                <MenuItem value={0}>Select a contribution</MenuItem>
                {oneOneContributions.map(data => {
                  return <MenuItem value={data?.value}>{data?.title}</MenuItem>;
                })}
              </Select>
              <StyledTooltip
                title="For now, it is only for Free contributions"
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                style={{ margin: '0px', marginTop: '-5px' }}
              >
                <StyledInfoIcon htmlColor={colors.lightBrown} />
              </StyledTooltip>
            </div>
          </StyledCTAValueContainer>
          {/* <StyledInput
            disabled={addCTA}
            fullWidth
            variant="outlined"
            margin="dense"
            name={`additionalThankYou.ctaButtontext`}
            value={values.additionalThankYou.ctaButtontext}
            onChange={e => {
              setFieldValue(values.additionalThankYou.ctaButtontext, e.target.value);
            }}
          />
          <StyledInput
            disabled={addCTA}
            fullWidth
            variant="outlined"
            margin="dense"
            name={`additionalThankYou.url`}
            value={values.additionalThankYou.url}
            onChange={e => {
              setFieldValue(values.additionalThankYou.url, e.target.value);
            }}
          /> */}
        </FlexColumn>
      </FlexContainer>
    </DetailsContainerWithHeader>
  );
};

export default LeadMagnetAddtionalCall;
