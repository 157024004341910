import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHeader, useEndVideoChat } from 'hooks';
import { fetchLeadMagnets, fetchLeadMagnetsActions } from 'actions/leadMagnets';
import Loader from 'components/UI/Loader';
import Banner from 'components/Banner/Banner';
import LeadMagnetsTable from 'components/Tables/LeadMagnets';
import ExportIcon from 'pages/Contacts/Components/Icons/ExportIcon';
import classes from 'pages/Contacts/Components/ContactsButtons/ContactsButtons.module.scss';
import { exportLeadMagnetUsersByLeadMagnetId } from 'services/leadMagnets.service';
import { downloadFile } from 'utils/utils';
import useNotifications from 'hooks/useNotifications';
import styled from 'styled-components';

const StyledExportButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const AllLeadMagnetsPage = ({
  // contributionscount,
  leadMagnets,
  // closestClassForBanner,
  getLeadMagnets,
  loading,
  userId,
}) => {
  const [prevLists, setPrevLists] = useState(leadMagnets);
  const exportBtnClass = classes.exportBtn;
  const { successMessage, infoMessage } = useNotifications();

  useEffect(() => {
    if (!loading) {
      setPrevLists(leadMagnets);
    }
  }, [leadMagnets, loading]);
  const [pagination, setpagination] = useState(0);
  useHeader('My Lead Magnets');

  const handlePageNumber = useCallback(page => {
    setpagination(page);
  }, []);

  const renderList = useCallback(async () => {
    getLeadMagnets();
  }, [getLeadMagnets, userId]);

  // const { Popup } = useEndVideoChat(renderList);

  useEffect(() => {
    renderList();
  }, [renderList]);

  const handleExportLeads = () => {
    exportLeadMagnetUsersByLeadMagnetId()
      .then(res => {
        if (!res) {
          return infoMessage('Info', 'No leads available to export.');
        }
        return downloadFile(res, 'Lead Magnet - All Leads.csv').then(() =>
          successMessage('Success', 'File exported successfully.'),
        );
      })
      .catch(console.dir);
  };

  if ((!prevLists || !prevLists?.length) && !loading) {
    return <div>There is no lead magnet created yet.</div>;
  }

  return (
    <>
      {loading && <Loader />}
      {/* <Banner type="closestSession" closestClassForBanner={closestClassForBanner} /> */}
      <StyledExportButtonRow className={classes.container}>
        <button
          className={exportBtnClass}
          type="button"
          onClick={loading ? null : handleExportLeads}
          disabled={loading}
        >
          <ExportIcon width="16" height="16" />
          Export
        </button>
      </StyledExportButtonRow>
      {prevLists?.length > 0 && (
        <LeadMagnetsTable
          // totalCount={contributionscount}
          rows={loading ? prevLists : leadMagnets}
          childpageNumber={handlePageNumber}
        />
      )}
      {/* <Popup /> */}
    </>
  );
};

const mapStateToProps = ({ leadMagnets: { loading, error, leadMagnetsCollection }, account }) => ({
  userId: account?.user?.id,
  loading,
  error,
  // contributionscount: upcomingCreated?.totalCount || null,
  leadMagnets: leadMagnetsCollection || [],
  // closestClassForBanner: upcomingCreated?.closestClassForBanner || {},
});

const actions = {
  getLeadMagnets: fetchLeadMagnets,
};

AllLeadMagnetsPage.propTypes = {
  userId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  leadMagnets: PropTypes.arrayOf(PropTypes.object).isRequired,
  getLeadMagnets: PropTypes.func.isRequired,
  // closestClassForBanner: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, actions)(AllLeadMagnetsPage);
