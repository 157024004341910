import { SelfPacedEmbeddedLinkContentCard } from './component/cards';
import {
  SelfPacedModuleExpansionCard,
  SelfPacedVideoContentCard,
  SelfPacedDocumentContentCard,
  SelfPacedMusicContentCard,
  SelfPacedQuizContentCard,
  LiveSessionExpansionCard,
  LiveSessionTimeCard,
  SelfPacedImageContentCard,
} from './component/cards';

export const SESSIONS_TYPES = {
  COMPLETED: 'completed',
  UPCOMING: 'upcoming',
  ALL: 'all',
};

export const TOOLTIP = {
  ENTER_DELAY: 0,
  LEAVE_DELAY: 10000,
};
export const FILTER_TABS_OPTIONS = [
  {
    title: 'Upcoming',
    value: SESSIONS_TYPES.UPCOMING,
  },
  {
    title: 'Completed',
    value: SESSIONS_TYPES.COMPLETED,
  },
  {
    title: 'All',
    value: SESSIONS_TYPES.ALL,
  },
];

export const FILTERTYPES = {
  ALL: 'All',
  LIVE: 'Live',
  SELFPACED: 'SelfPaced',
  PAST: 'Past',
  UPCOMING: 'Upcoming',
};

export const SESSION_TYPES = {
  LIVE: 0,
  SELFPACED: 1,
};
export const SELFPACED_CONTENT_TYPES = {
  VIDEO: 1,
  IMAGE: 2,
  DOCUMENT: 3,
  PDF_DOCUMENT: 3.1,
  DOC_DOCUMENT: 3.2,
  XLS_DOCUMENT: 3.3,
  TXT_DOCUMENT: 3.4,
  MUSIC: 4,
  QUIZ: 5,
  EMBEDDED_LINK: 6,
  EMBEDDED_AUDIO_LINK: 6.1,
  EMBEDDED_VIDEO_LINK: 6.2,
};

export const FILTERS = ['Upcoming', 'All', 'Live', 'SelfPaced', 'Past'];

export const FILTERS_OBJECT = Object.fromEntries(FILTERS.map(value => [value, value]));

export const CARDS = {
  [SESSION_TYPES.LIVE]: LiveSessionExpansionCard,
  [SESSION_TYPES.SELFPACED]: SelfPacedModuleExpansionCard,
};
export const ITEM_CARDS = {
  [SESSION_TYPES.LIVE]: LiveSessionTimeCard,
  [SESSION_TYPES.SELFPACED]: {
    [SELFPACED_CONTENT_TYPES.VIDEO]: SelfPacedVideoContentCard,
    [SELFPACED_CONTENT_TYPES.IMAGE]: SelfPacedImageContentCard,
    // [SELFPACED_CONTENT_TYPES.DOCUMENT]: SelfPacedDocumentContentCard,
    [SELFPACED_CONTENT_TYPES.PDF_DOCUMENT]: SelfPacedDocumentContentCard,
    [SELFPACED_CONTENT_TYPES.DOC_DOCUMENT]: SelfPacedDocumentContentCard,
    [SELFPACED_CONTENT_TYPES.XLS_DOCUMENT]: SelfPacedDocumentContentCard,
    [SELFPACED_CONTENT_TYPES.TXT_DOCUMENT]: SelfPacedDocumentContentCard,
    [SELFPACED_CONTENT_TYPES.MUSIC]: SelfPacedMusicContentCard,
    [SELFPACED_CONTENT_TYPES.QUIZ]: SelfPacedQuizContentCard,
    [SELFPACED_CONTENT_TYPES.EMBEDDED_LINK]: SelfPacedEmbeddedLinkContentCard,
    [SELFPACED_CONTENT_TYPES.EMBEDDED_AUDIO_LINK]: SelfPacedEmbeddedLinkContentCard,
    [SELFPACED_CONTENT_TYPES.EMBEDDED_VIDEO_LINK]: SelfPacedEmbeddedLinkContentCard,
  },
};
export const MEDIA_TYPES = {
  '.mp4': SELFPACED_CONTENT_TYPES.VIDEO,
  '.webm': SELFPACED_CONTENT_TYPES.VIDEO,
  '.mov': SELFPACED_CONTENT_TYPES.VIDEO,
  '.png': SELFPACED_CONTENT_TYPES.IMAGE,
  '.jpg': SELFPACED_CONTENT_TYPES.IMAGE,
  '.jpeg': SELFPACED_CONTENT_TYPES.IMAGE,
  '.pdf': SELFPACED_CONTENT_TYPES.PDF_DOCUMENT,
  '.doc': SELFPACED_CONTENT_TYPES.DOC_DOCUMENT,
  '.docx': SELFPACED_CONTENT_TYPES.DOC_DOCUMENT,
  '.xls': SELFPACED_CONTENT_TYPES.XLS_DOCUMENT,
  '.xlsx': SELFPACED_CONTENT_TYPES.XLS_DOCUMENT,
  '.txt': SELFPACED_CONTENT_TYPES.TXT_DOCUMENT,
  '.mp3': SELFPACED_CONTENT_TYPES.MUSIC,
  '.m4a': SELFPACED_CONTENT_TYPES.MUSIC,
  '.wav': SELFPACED_CONTENT_TYPES.MUSIC,
  '.ogg': SELFPACED_CONTENT_TYPES.MUSIC,
  text: SELFPACED_CONTENT_TYPES.PDF_DOCUMENT,
};
// export const BADGE_LABELS = {
//   [SESSION_TYPES.LIVE]: null,
//   [SESSION_TYPES.SELFPACED]: null,
//   ['TODAY']: null
// }

export const DRAGGABLE_TYPES = {
  SESSION: 'SESSION',
  SESSION_CONTENT: 'SESSION-CONTENT',
};

export const ColorForDarkModeDisabled = '#616161';
