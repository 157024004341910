import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useRouter from 'hooks/useRouter';
import List, { Item } from './List';
import {
  AllConversationsUnreadMessagesCount,
  ClientsConversationsUnreadMessagesCount,
  CommunityUnreadMessagesCount,
  DiscussionsConversationsUnreadMessagesCount,
  OpportunitiesConversationsUnreadMessageCount,
} from './conversations/UnreadMessagesCount';
import { PendingApplicationsCount } from './applications/PendingApplicationsCount';
import { getLeadMagnetsCounts, getArchivedLeadMagnetsCount } from 'services/leadMagnets.service';
import { useSelector } from 'react-redux';

function CohealerMenu() {
  const { pathname } = useRouter();
  const prevAllCount = useRef();
  const prevArchivedCount = useRef();
  const { leadMagnets } = useSelector(s => s.leadMagnets?.leadMagnetsCollection || []);
  const [leadMagnetItemsCount, setLeadMagnetItemsCount] = useState(prevAllCount?.current || 0);
  const [archivedItemsCount, setArchivedItemsCount] = useState(prevArchivedCount?.current || 0);
  const [fetchingItems, setFetchingItems] = useState(false);
  const isLeadMagnetsPage = pathname?.includes('lead-magnets');
  /* useLayoutEffect(
    () => {
      // getLeadMagnetsCounts().then(setLeadMagnetItemsCount);
      // getArchivedLeadMagnetsCount().then(setArchivedItemsCount);
      setFetchingItems(true);
      Promise.allSettled([getLeadMagnetsCounts(), getArchivedLeadMagnetsCount()])
        .then(([leadMagnetCount, archivedCount]) => {
          setLeadMagnetItemsCount(leadMagnetCount.value);
          setArchivedItemsCount(archivedCount.value);
          prevAllCount.current = leadMagnetCount.value;
          prevArchivedCount.current = archivedCount.value;
        })
        .finally(() => setFetchingItems(false));
    },
    [
      // leadMagnets
    ],
  ); */
  return (
    <List menubar>
      <Item
        to="/dashboard"
        id="coach-menu-dashboard-link"
        title="Dashboard"
        isActive={pathname?.includes('dashboard')}
      />
      <Item
        title="Contributions"
        id="coach-menu-contributions-link"
        isList
        defaultExpanded={pathname?.includes('contributions')}
      >
        <List menubar>
          <Item
            to="/contributions/all"
            id="coach-menu-contributions-all-link"
            title="All"
            isActive={pathname?.includes('contributions/all')}
            subItem
          />
          <Item
            to="/contributions/group-courses"
            id="coach-menu-contributions-group-courses-link"
            title="Group Courses"
            isActive={pathname?.includes('contributions/group-courses')}
            subItem
          />
          <Item
            to="/contributions/1-to-1-sessions"
            id="coach-menu-contributions-1-to-1-link"
            title="1:1 Sessions"
            isActive={pathname?.includes('contributions/1-to-1-sessions')}
            subItem
          />
          <Item
            to="/contributions/memberships"
            title="Memberships"
            isActive={pathname?.includes('contributions/memberships')}
            subItem
          />
          <Item
            to="/contributions/workshop"
            id="coach-menu-contributions-archived-link"
            title="Workshops"
            isActive={pathname?.includes('contributions/workshop')}
            subItem
          />
          <Item
            to="/contributions/archived"
            id="coach-menu-contributions-archived-link"
            title="Archived"
            isActive={pathname?.includes('contributions/archived')}
            subItem
          />
        </List>
      </Item>
      {
        // fetchingItems ? null :
        // isLeadMagnetsPage &&
        true || fetchingItems || archivedItemsCount ? (
          <Item
            title="Lead Magnets"
            id="coach-menu-lead-magnets-listing-link"
            isList
            isActive={isLeadMagnetsPage && fetchingItems}
            defaultExpanded={/*!fetchingItems && */ pathname?.includes('lead-magnets')}
            isNew
          >
            <List menubar>
              {true || fetchingItems || leadMagnetItemsCount ? (
                <Item
                  to="/lead-magnets/all"
                  id="coach-menu-lead-magnets-all-listing-link"
                  title="All"
                  isActive={pathname?.includes('lead-magnets/all')}
                  subItem
                />
              ) : null}
              <Item
                to="/lead-magnets/archived"
                id="coach-menu-lead-magnets-archived-listing-link"
                title="Archived"
                isActive={pathname?.includes('lead-magnets/archived')}
                subItem
              />
            </List>
          </Item>
        ) : leadMagnetItemsCount ? (
          <Item
            to="/lead-magnets/all"
            id="coach-menu-lead-magnets-all-listing-link"
            title="Lead Magnets"
            isActive={pathname?.includes('lead-magnets/all')}
            isNew
          />
        ) : null
      }
      <Item title="Contacts" id="coach-menu-contacts-link" isList defaultExpanded={pathname?.includes('contacts')}>
        <List menubar>
          <Item
            to="/contacts/all"
            id="coach-menu-contacts-all-link"
            title="All"
            isActive={pathname?.includes('contacts/all')}
            subItem
          />
          <Item
            to="/contacts/clients"
            id="coach-menu-contacts-clients-link"
            title="Clients"
            isActive={pathname?.includes('contacts/clients')}
            subItem
          />
          <Item
            to="/contacts/leads"
            id="coach-menu-contacts-leads-link"
            title="Leads"
            isActive={pathname?.includes('contacts/leads')}
            subItem
          />
          <Item
            to="/contacts/tags"
            id="coach-menu-contacts-tags-link"
            title="Tags"
            isActive={pathname?.includes('contacts/tags')}
            subItem
          />
        </List>
      </Item>
      {/* <Item title="Contacts" id="coach-menu-contacts-link" isList defaultExpanded={pathname?.includes('contacts')}>
        <List menubar>
          <Item
            to="/contacts/all"
            id="coach-menu-contacts-all-link"
            title="All"
            isActive={pathname?.includes('contacts/all')}
            subItem
          />
          <Item
            to="/contacts/clients"
            id="coach-menu-contacts-clients-link"
            title="Clients"
            isActive={pathname?.includes('contacts/clients')}
            subItem
          />
          <Item
            to="/contacts/leads"
            id="coach-menu-contacts-leads-link"
            title="Leads"
            isActive={pathname?.includes('contacts/leads')}
            subItem
          />
          <Item
            to="/contacts/tags"
            id="coach-menu-contacts-tags-link"
            title="Tags"
            isActive={pathname?.includes('contacts/tags')}
            subItem
          />
        </List>
      </Item> */}
      <Item title="Emails" id="coach-menu-email-link" isList defaultExpanded={pathname?.includes('email')} isNew>
        <List menubar>
          <Item
            to="/emails/campaigns"
            id="coach-menu-campaigns-all-link"
            title="Campaigns"
            isActive={pathname?.includes('emails/campaigns')}
            subItem
          />
        </List>
      </Item>
      <Item
        to="/calendar"
        id="coach-menu-contributions-all-link"
        title="Calendar"
        isActive={pathname?.includes('calendar')}
      />
      <Item
        title="Applications"
        id="coach-menu-applications-link"
        isList
        defaultExpanded={pathname.includes('applications')}
      >
        <List menubar>
          <Item
            to="/applications/forms"
            id="coach-menu-applications-forms"
            title="Forms"
            isActive={pathname?.includes('applications/forms')}
            subItem
          />
          <Item
            to="/applications/all"
            id="coach-menu-applications-all"
            title="All Responses"
            endAdornment={<PendingApplicationsCount />}
            isActive={pathname?.includes('applications/all')}
            subItem
          />
          <Item
            to="/applications/pending"
            id="coach-menu-applications-pending"
            title="Pending"
            endAdornment={<PendingApplicationsCount />}
            isActive={pathname?.includes('applications/pending')}
            subItem
          />
          <Item
            to="/applications/approved"
            id="coach-menu-applications-approved"
            title="Approved"
            isActive={pathname?.includes('applications/approved')}
            subItem
          />
          <Item
            to="/applications/rejected"
            id="coach-menu-applications-rejected"
            title="Rejected"
            isActive={pathname?.includes('applications/rejected')}
            subItem
          />
        </List>
      </Item>
      <Item
        id="coach-menu-conversations-all-link"
        // to="/community/newsfeed"
        title="Communities"
        endAdornment={<CommunityUnreadMessagesCount />}
        isList
        defaultExpanded={pathname.includes('community')}
      >
        <List menubar>
          <Item
            to="/community/newsfeed"
            id="coach-menu-myconversations-all-link"
            title="All Posts"
            endAdornment={null}
            isActive={pathname?.includes('community/newsfeed')}
            subItem
          />
          <Item
            to="/community"
            id="coach-menu-myconversations-all-link"
            title="All Communities"
            endAdornment={<CommunityUnreadMessagesCount />}
            isActive={pathname?.includes('community') && !pathname?.includes('newsfeed')}
            subItem
          />
        </List>
      </Item>
      <Item
        title="Conversations"
        id="coach-menu-conversations-link"
        isList
        defaultExpanded={pathname.includes('conversations')}
      >
        <List menubar>
          <Item
            id="coach-menu-conversations-all-link"
            to="/conversations/all"
            title="All"
            endAdornment={<AllConversationsUnreadMessagesCount />}
            isActive={pathname?.includes('conversations/all')}
            subItem
          />
          <Item
            id="coach-menu-conversations-clients-link"
            to="/conversations/clients"
            title="1:1"
            endAdornment={<ClientsConversationsUnreadMessagesCount />}
            isActive={pathname?.includes('conversations/clients')}
            subItem
          />
          <Item
            id="coach-menu-conversations-group-link"
            to="/conversations/discussions"
            title="Group"
            endAdornment={<DiscussionsConversationsUnreadMessagesCount />}
            isActive={pathname?.includes('conversations/discussions')}
            subItem
          />
          <Item
            id="coach-menu-conversations-group-link"
            to="/conversations/opportunities"
            title="Opportunities"
            endAdornment={<OpportunitiesConversationsUnreadMessageCount />}
            isActive={pathname?.includes('conversations/opportunities')}
            subItem
          />
        </List>
      </Item>
    </List>
  );
}

export default CohealerMenu;
