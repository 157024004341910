// TODO: gradual refactoring of session time component
// Used for self-paced sessions
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { mdiMinusCircleOutline } from '@mdi/js';
import { mdiCloseCircleOutline } from '@mdi/js';
import { mdiDelete } from '@mdi/js';
import { CKEditor } from 'ckeditor4-react';
import Select from 'components/FormUI/Select';
import { Icon } from '@mdi/react';
import humanizeDuration from 'humanize-duration';
import { SessionMoreInfo } from '../SessionMoreInfo/SessionMoreInfo';
import { BottomPanel } from 'pages/CreateContribution/components/BottomPanel';
import useContribution from '../../../pages/ContributionView/hooks/useContribution';

import DateTimePicker from 'components/FormUI/DateTimePicker';
import { Video } from 'components/Video/Video';
import { checkIsFuture } from 'utils/datesAndMoney';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { useStyles } from './hooks/useStyles';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP } from '../../../constants';
import { colors } from 'utils/styles';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import moment from 'moment';
import axiosInstance from 'utils/axiosInstance';
import { FieldArray } from 'formik';
import { CleaningServices } from '@mui/icons-material';
import { useRouter } from 'hooks';
import UploadThumbnail from 'pages/CreateContribution/components/UploadThumbnail';
import { values } from 'lodash';
import styled, { css } from 'styled-components';

const MAX_LIMIT = 50;
const StyledEditorWrapper = styled.div`
  .cke {
    ${({ mainBorder }) =>
      mainBorder
        ? css`
            border: ${mainBorder};
          `
        : css``}
  }
  .cke_chrome {
    border-radius: 0 0 4px 4px;
    /* border-bottom: none; */
  }
  .cke_inner {
    .cke_contents {
      border-radius: 0 0 4px 4px;
    }
    .cke_bottom {
      display: none;
    }
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }

  .cke_top {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }

  .cke_reset_all * {
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
  }

  .cke_button_icon {
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
    ${({ isDarkThemeEnabled }) =>
      isDarkThemeEnabled &&
      css`
        filter: invert(100%);
      `}
  }

  .cke_button {
    &:hover {
      .cke_button_icon {
        ${({ isDarkThemeEnabled }) =>
          isDarkThemeEnabled &&
          css`
            filter: none;
          `}
      }
    }
  }

  .cke_combo_off a.cke_combo_button:hover {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }
  .cke_combo_arrow {
    border-top-color: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit'};
  }
  .cke_editable {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
  }
  .cke_bottom {
    /* visibility: hidden; */
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }
`;
export const SessionTimeSelfPaced = props => {
  const { form, isEditMode, remove, session, sessionIndex, sessionTimeIndex, dragging } = props;
  const classNames = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [videoError, setVideoError] = useState([]);
  const contribution = useContribution();
  const contributionId = contribution.id;
  const { query } = useRouter();
  const theme = useTheme();
  const isEditing = Boolean(query.id);
  const [firstTimeDelete, setFirstTimeDelete] = useState(true);
  const [isOpenUploadThumbnail, setIsOpenUploadThumbnail] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [ckEditorLoaded, setCkEditorLoaded] = useState(false);
  const [textValue, setTextValue] = useState(
    form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex].selfPacedContentAsHtml,
  );
  const ref = useRef();

  const handleEditorChange = event => {
    // const editorContent = convertToPlainText(event.editor.getData().trim());
    setTextValue(event.editor.getData());
    form.setFieldValue(
      `sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].selfPacedContentAsHtml`,
      event.editor.getData(),
    );
  };
  const toolbarConfig = useMemo(
    () => [
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
      { name: 'links', items: ['Link', 'Unlink'] },
      { name: 'insert', items: ['Image'] },
      { name: 'undo', items: ['Undo', 'Redo'] },
      { name: 'styles', items: ['Font', 'FontSize', '-'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'indent', items: ['Indent', 'Outdent'] },
      { name: 'lists', items: ['NumberedList', 'BulletedList'] },
      {
        name: 'paragraph',
        items: [
          'Blockquote',
          'CreateDiv',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          '-',
          'BidiLtr',
          'BidiRtl',
          'Language',
        ],
      },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'expand', items: ['Maximize'] },
    ],
    [],
  );
  useEffect(() => {
    if (Array.isArray(form.errors?.sessions)) {
      const modifiedErrors = form.errors.sessions.map(item => item);
      setVideoError(modifiedErrors);
    }
  }, [form]);

  const sessionTime = session.sessionTimes[sessionTimeIndex];
  const sessionTimeAttachments = sessionTime?.attachments || [];
  const status = checkIsFuture(sessionTime?.startTime) ? 'Upcoming' : 'Completed';
  const isMultipleSessionTimes = session?.sessionTimes?.length > 1;
  const duration =
    session.isPrerecorded &&
    sessionTime?.prerecordedSession &&
    humanizeDuration(sessionTime?.prerecordedSession?.duration, { maxDecimalPoints: 0 });

  const handleDatePick = selectedMomentDate => {
    const selectedDate = selectedMomentDate.format();

    if (session.isPrerecorded) {
      let endDate = selectedMomentDate.clone().add(1, 'h');
      form.setFieldValue(`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].startTime`, selectedDate);
      form.setFieldValue(`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].endTime`, endDate.format());
    }
  };

  const onDateAvailableChange = useCallback(
    value => {
      if (session.isPrerecorded) {
        form.setFieldValue(`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].ignoreDateAvailable`, !value);
      }
    },
    [session, sessionIndex, sessionTimeIndex, form],
  );

  const onMustWatchChange = useCallback(
    value => {
      if (session.isPrerecorded) {
        form.setFieldValue(
          `sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].mustWatchPriorSelfPacedRecords`,
          !value,
        );
      }
    },
    [session, sessionIndex, sessionTimeIndex, form],
  );
  function onCrossClick() {
    if (sessionTime?.prerecordedSession) {
      const video = sessionTime?.prerecordedSession;
      const videodata = {
        DocumentKeyWithExtension: video?.documentKeyWithExtension,
        DocumentId: video?.id,
        ContributionId: isEditing ? contributionId : '0',
        SessionId: '0',
      };

      if (isEditing && firstTimeDelete) {
        axiosInstance.post('/Content/DeleteAttachment-Selfpaced?isVideo=true', videodata).then(res => {
          form.setFieldValue(
            `sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].prerecordedSession`,
            undefined,
          );
          form.setFieldValue(`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].videoSource`, undefined);
          form.setFieldValue(`sessions[${sessionIndex}].prerecordedSession`, undefined);
          form.setFieldValue(`sessions[${sessionIndex}].prerecordedSessions`, undefined);
          setFirstTimeDelete(false);
        });
      } else {
        axiosInstance.post('/Content/DeleteSelfpacedVideoOnContributionCreation', videodata).then(res => {
          form.setFieldValue(
            `sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].prerecordedSession`,
            undefined,
          );
          form.setFieldValue(`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].videoSource`, undefined);
          form.setFieldValue(`sessions[${sessionIndex}].prerecordedSession`, undefined);
          form.setFieldValue(`sessions[${sessionIndex}].prerecordedSessions`, undefined);
        });
      }

      if (sessionTime?.selfPacedThumbnailUrl) {
        axiosInstance.post(`/Content/DeletePublicImage?imageLink=${sessionTime?.selfPacedThumbnailUrl}`).then(() => {
          form.setFieldValue(`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].selfPacedThumbnailUrl`, '');
        });
      }
    }
  }

  const handleRemoveSessionTime = useCallback(() => {
    if (sessionTime?.isCompleted) {
      setTooltipOpen(prevState => ({ ...prevState, [sessionTimeIndex]: true }));
    } else {
      remove(sessionTimeIndex);
    }
  }, [remove, sessionTime?.isCompleted, sessionTimeIndex]);

  const handleCloseTooltip = useCallback(() => {
    setTooltipOpen(prevState => ({ ...prevState, [sessionTimeIndex]: false }));
  }, [sessionTimeIndex]);

  const onSubCategoryNameChange = useCallback(
    (event, i) => {
      form.setFieldValue(`sessions[${sessionIndex}].sessionTimes[${i}].subCategoryName`, event?.target?.value);
    },
    [form, sessionIndex],
  );

  const onChangeMoreInfo = useCallback(
    event =>
      form.setFieldValue(`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].moreInfo`, event?.target?.value),
    [form, sessionIndex],
  );
  const isTextData = form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex].selfPacedContentType === 'Text';
  console.log('Video ERRORS-------->', form, videoError);
  return (
    <Grid style={{ justifyContent: isTextData ? 'space-between' : '' }} container className={classNames.gridhandle}>
      {/* SELF-PACED SESSION INFO */}
      {form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex].selfPacedContentType === 'Text' && (
        <Grid item lg={11} md={11} sm={session.isPrerecorded ? 6 : 3} xs={12}>
          <FormControl fullWidth>
            <div style={{ flexDirection: 'row', display: 'flex', width: '100%', marginTop: '10px' }}>
              <TextField
                fullWidth
                label="Optional: add a Sub-category name"
                name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].subCategoryName`}
                onChange={value => {
                  onSubCategoryNameChange(value, sessionTimeIndex);
                }}
                value={sessionTime?.subCategoryName}
                helperText={`${sessionTime?.subCategoryName ? sessionTime?.subCategoryName.length : 0}/${MAX_LIMIT}`}
                inputProps={{ maxLength: { MAX_LIMIT } }}
                type="text"
              />
              {session.isPrerecorded && isMultipleSessionTimes && (
                <Grid className={classNames.deleteButton} item lg={1} md={6} sm={6} xs={12}>
                  <Tooltip
                    arrow
                    className="cursor-pointer"
                    onClose={handleCloseTooltip}
                    open={Boolean(tooltipOpen[sessionTimeIndex])}
                    title="Completed session cannot be deleted"
                  >
                    <Button
                      form="create-contribution-form"
                      onClick={handleRemoveSessionTime}
                      type="button"
                      variant="text"
                    >
                      <Icon path={mdiDelete} size={1} />
                    </Button>
                  </Tooltip>
                </Grid>
              )}
            </div>
            {videoError[sessionIndex]?.sessionTimes[sessionTimeIndex]?.subCategoryName && (
              <CommonErrorMessage
                message={videoError[sessionIndex]?.sessionTimes[sessionTimeIndex]?.subCategoryName}
                align="left"
              />
            )}
          </FormControl>
        </Grid>
      )}
      {form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex].selfPacedContentType === 'Text' ? (
        <Grid
          lg={form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex].selfPacedContentType === 'Text' ? 4 : 6}
        >
          {session.isPrerecorded && (
            <Grid alignItems="center" className={classNames.infoWrapper} container item lg={12} md={12} sm={12} xs={12}>
              {/* CHECK BOXES*/}
              <Grid item md={12} xs={12}>
                <Typography style={{ fontFamily: 'Avenir', fontWeight: '900', fontSize: '16px' }}>
                  Content Type
                </Typography>
                <Select
                  label={''}
                  name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].selfPacedContentType`}
                  labelTop={mobileView ? '35px' : ''}
                  onChange={({ target }) => {
                    form.setFieldValue(target.name, target.value);
                    setCkEditorLoaded(false);
                  }}
                  fullWidth
                  value={
                    form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex].selfPacedContentType || 'Video'
                  }
                  items={[
                    { title: 'Video', value: 'Video' },
                    { title: 'Audio', value: 'Video1' },
                    { title: 'Document', value: 'Video2' },
                    { title: 'Text', value: 'Text' },
                  ]}
                />
              </Grid>
              <Grid className={classNames.datePickerWrapper} item sm={8} xs={6}>
                <FormControlLabel
                  checked={!sessionTime?.ignoreDateAvailable}
                  onChange={() => {}}
                  control={<Checkbox color="primary" />}
                  label="Anytime"
                  name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].anytime`}
                  disabled={!!sessionTime?.ignoreDateAvailable}
                />
                <div style={{ display: 'flex' }}>
                  <FormControlLabel
                    checked={sessionTime?.ignoreDateAvailable}
                    onChange={() => onDateAvailableChange(sessionTime?.ignoreDateAvailable)}
                    control={<Checkbox color="primary" />}
                    label="Date Available"
                    name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].ignoreDateAvailable`}
                  />
                  {session.id && moment(sessionTime?.startTime).isBefore(new Date()) ? (
                    <>
                      {/* <h5>Date Available</h5> */}
                      <p>{moment(sessionTime?.startTime).format('DD MMMM')}</p>
                    </>
                  ) : (
                    <Grid style={{ marginTop: '5px' }} md={7} sm={12}>
                      <DateTimePicker
                        fullWidth
                        isSelfPaced
                        // label={session.isPrerecorded ? 'Date Available' : 'Date'}
                        name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].startTime`}
                        onChange={momentDate => handleDatePick(momentDate)}
                        type="date"
                        disabled={!sessionTime?.ignoreDateAvailable}
                        value={sessionTime?.startTime}
                      />
                    </Grid>
                  )}
                </div>

                <FormControlLabel
                  checked={sessionTime?.mustWatchPriorSelfPacedRecords}
                  onChange={() => onMustWatchChange(sessionTime?.mustWatchPriorSelfPacedRecords)}
                  control={<Checkbox color="primary" />}
                  label="Must Watch Prior Self-Paced First"
                  name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].mustWatchPriorSelfPacedRecords`}
                />
              </Grid>

              {/* DATE */}
              {/* <Grid className={classNames.datePickerWrapper} item sm={0} xs={12}>
            {session.id && moment(sessionTime.startTime).isBefore(new Date()) ? (
              <>
                <h5>Date Available</h5>
                <p>{moment(sessionTime.startTime).format('DD MMMM')}</p>
              </>
            ) : (
              <DateTimePicker
                fullWidth
                isSelfPaced
                label={session.isPrerecorded ? 'Date Available' : 'Date'}
                name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].startTime`}
                onChange={momentDate => handleDatePick(momentDate)}
                type="date"
                disabled={!sessionTime.ignoreDateAvailable}
                value={sessionTime.startTime}
              />
            )}
          </Grid> */}

              {/* STATUS */}
              {!isEditMode && (
                <Grid className={classNames.statusWrapper} direction="column" justify="center" sm={2} xs={12}>
                  <Typography className={classNames.infoTitle}>Status</Typography>
                  <Typography className={classNames.infoValue}>{status}</Typography>
                </Grid>
              )}

              {/* DURATION */}
              {/* {duration && (
                <Grid className={classNames.durationWrapper} direction="column" item justify="center" sm={2} xs={12}>
                  <Typography className={classNames.infoTitle}>Duration</Typography>
                  <Typography className={classNames.infoValue}>{duration}</Typography>
                </Grid>
              )} */}
              {/* DELETE SESSION TIME BUTTON */}
              {/* {isMultipleSessionTimes && (
            <Grid className={classNames.durationWrapper} direction="column" item justify="center" sm={2} xs={12}>
              <Tooltip
                arrow
                className="cursor-pointer"
                onClose={handleCloseTooltip}
                open={Boolean(tooltipOpen[sessionTimeIndex])}
                title="Completed session cannot be deleted"
              >
                <Button form="create-contribution-form" onClick={handleRemoveSessionTime} type="button" variant="text">
                  <Icon path={mdiMinusCircleOutline} size={1} />
                </Button>
              </Tooltip>
            </Grid>
          )} */}
            </Grid>
          )}
        </Grid>
      ) : (
        <>
          <Grid item lg={6} md={6} sm={session.isPrerecorded ? 6 : 3} xs={12}>
            <FormControl style={{ marginTop: '10px' }} fullWidth>
              <TextField
                fullWidth
                label="Optional: add a Sub-category name"
                name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].subCategoryName`}
                onChange={value => {
                  onSubCategoryNameChange(value, sessionTimeIndex);
                }}
                value={sessionTime?.subCategoryName}
                helperText={`${sessionTime?.subCategoryName ? sessionTime?.subCategoryName.length : 0}/${MAX_LIMIT}`}
                inputProps={{ maxLength: { MAX_LIMIT } }}
                type="text"
              />
              {videoError[sessionIndex]?.sessionTimes[sessionTimeIndex]?.subCategoryName && (
                <CommonErrorMessage
                  message={videoError[sessionIndex]?.sessionTimes[sessionTimeIndex]?.subCategoryName}
                  align="left"
                />
              )}
            </FormControl>
          </Grid>
          {session.isPrerecorded && isMultipleSessionTimes && (
            <Grid className={classNames.deleteButton} item lg={1} md={6} sm={6} xs={12}>
              <Tooltip
                arrow
                className="cursor-pointer"
                onClose={handleCloseTooltip}
                open={Boolean(tooltipOpen[sessionTimeIndex])}
                title="Completed session cannot be deleted"
              >
                <Button form="create-contribution-form" onClick={handleRemoveSessionTime} type="button" variant="text">
                  <Icon path={mdiDelete} size={1} />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {session.isPrerecorded && (
            <Grid className={classNames.infoWrapper} container item lg={7} md={11} sm={11} xs={12}>
              {/* CHECK BOXES*/}
              <Grid style={{ marginTop: '20px' }} item md={6} xs={12}>
                <Typography style={{ fontFamily: 'Avenir', fontWeight: '900', fontSize: '16px' }}>
                  Content Type
                </Typography>
                <Select
                  label={''}
                  name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].selfPacedContentType`}
                  labelTop={mobileView ? '35px' : ''}
                  onChange={({ target }) => {
                    form.setFieldValue(target.name, target.value);
                  }}
                  fullWidth
                  value={
                    form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex].selfPacedContentType || 'Video'
                  }
                  items={[
                    { title: 'Video', value: 'Video' },
                    { title: 'Audio', value: 'Video1' },
                    { title: 'Document', value: 'Video2' },
                    { title: 'Text', value: 'Text' },
                  ]}
                />
              </Grid>
              <Grid className={classNames.datePickerWrapper} item sm={8} xs={6}>
                <FormControlLabel
                  checked={!sessionTime?.ignoreDateAvailable}
                  onChange={() => {}}
                  control={<Checkbox color="primary" />}
                  label="Anytime"
                  name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].anytime`}
                  disabled={!!sessionTime?.ignoreDateAvailable}
                />
                <div style={{ display: 'flex' }}>
                  <FormControlLabel
                    checked={sessionTime?.ignoreDateAvailable}
                    onChange={() => onDateAvailableChange(sessionTime?.ignoreDateAvailable)}
                    control={<Checkbox color="primary" />}
                    label="Date Available"
                    name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].ignoreDateAvailable`}
                  />
                  {session.id && moment(sessionTime?.startTime).isBefore(new Date()) ? (
                    <>
                      {/* <h5>Date Available</h5> */}
                      <p>{moment(sessionTime?.startTime).format('DD MMMM')}</p>
                    </>
                  ) : (
                    <Grid style={{ marginTop: '5px' }} md={7} sm={12}>
                      <DateTimePicker
                        fullWidth
                        isSelfPaced
                        // label={session.isPrerecorded ? 'Date Available' : 'Date'}
                        name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].startTime`}
                        onChange={momentDate => handleDatePick(momentDate)}
                        type="date"
                        disabled={!sessionTime?.ignoreDateAvailable}
                        value={sessionTime?.startTime}
                      />
                    </Grid>
                  )}
                </div>

                <FormControlLabel
                  checked={sessionTime?.mustWatchPriorSelfPacedRecords}
                  onChange={() => onMustWatchChange(sessionTime?.mustWatchPriorSelfPacedRecords)}
                  control={<Checkbox color="primary" />}
                  label="Must Watch Prior Self-Paced First"
                  name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].mustWatchPriorSelfPacedRecords`}
                />
              </Grid>

              {/* DATE */}
              {/* <Grid className={classNames.datePickerWrapper} item sm={0} xs={12}>
            {session.id && moment(sessionTime.startTime).isBefore(new Date()) ? (
              <>
                <h5>Date Available</h5>
                <p>{moment(sessionTime.startTime).format('DD MMMM')}</p>
              </>
            ) : (
              <DateTimePicker
                fullWidth
                isSelfPaced
                label={session.isPrerecorded ? 'Date Available' : 'Date'}
                name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].startTime`}
                onChange={momentDate => handleDatePick(momentDate)}
                type="date"
                disabled={!sessionTime.ignoreDateAvailable}
                value={sessionTime.startTime}
              />
            )}
          </Grid> */}

              {/* STATUS */}
              {!isEditMode && (
                <Grid className={classNames.statusWrapper} direction="column" justify="center" sm={2} xs={12}>
                  <Typography className={classNames.infoTitle}>Status</Typography>
                  <Typography className={classNames.infoValue}>{status}</Typography>
                </Grid>
              )}

              {/* DURATION */}
              {duration && (
                <Grid className={classNames.durationWrapper} direction="column" item justify="center" sm={2} xs={12}>
                  <Typography className={classNames.infoTitle}>Duration</Typography>
                  <Typography className={classNames.infoValue}>{duration}</Typography>
                </Grid>
              )}
              {/* DELETE SESSION TIME BUTTON */}
              {/* {isMultipleSessionTimes && (
            <Grid className={classNames.durationWrapper} direction="column" item justify="center" sm={2} xs={12}>
              <Tooltip
                arrow
                className="cursor-pointer"
                onClose={handleCloseTooltip}
                open={Boolean(tooltipOpen[sessionTimeIndex])}
                title="Completed session cannot be deleted"
              >
                <Button form="create-contribution-form" onClick={handleRemoveSessionTime} type="button" variant="text">
                  <Icon path={mdiMinusCircleOutline} size={1} />
                </Button>
              </Tooltip>
            </Grid>
          )} */}
            </Grid>
          )}
        </>
      )}
      {/* DELETE SESSION TIME BUTTON */}
      {/* {isMultipleSessionTimes && (
        <Grid alignItems="center" className={classNames.deleteButtonWrapper} container item justify="center" xs={1}>
          <Tooltip
            arrow
            className="cursor-pointer"
            onClose={handleCloseTooltip}
            open={Boolean(tooltipOpen[sessionTimeIndex])}
            title="Completed session cannot be deleted"
          >
            <Button form="create-contribution-form" onClick={handleRemoveSessionTime} type="button" variant="text">
              <Icon path={mdiMinusCircleOutline} size={1} />
            </Button>
          </Tooltip>
        </Grid>
      )} */}

      {/* SELF-PACED VIDEO */}
      {session.isPrerecorded &&
        (form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex].selfPacedContentType === 'Text' ? (
          <>
            <Grid className={classNames.videoWrapper} item xs={8} lg={7}>
              <Typography style={{ fontFamily: 'Avenir', fontWeight: '900', fontSize: '16px', marginBottom: '10px' }}>
                Text Content
              </Typography>
              <StyledEditorWrapper>
                <CKEditor
                  ref={ref}
                  config={{
                    allowedContent: true,
                    height: '350px',
                    toolbar: toolbarConfig,
                    pasteImage: true,
                    extraPlugins: [
                      'font',
                      'colorbutton',
                      'justify',
                      'colordialog',
                      'uploadimage',
                      'filebrowser',
                      'image2',
                      'selectall',
                    ],
                  }}
                  initData={textValue}
                  onChange={handleEditorChange}
                  onLoaded={({ editor }) => {
                    setCkEditorLoaded(true);
                  }}
                />
              </StyledEditorWrapper>
              {ckEditorLoaded ? <></> : <CircularProgress />}
              {videoError[sessionIndex]?.sessionTimes[sessionTimeIndex]?.selfPacedContentAsHtml &&
                (form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex]?.selfPacedContentAsHtml?.length ===
                  0 ||
                  form.values.sessions[sessionIndex].sessionTimes[sessionTimeIndex]?.selfPacedContentAsHtml ===
                    undefined) &&
                ckEditorLoaded === true && (
                  <CommonErrorMessage
                    message={'Please add text content, or delete this module to proceed.'}
                    align="left"
                  />
                )}
            </Grid>
          </>
        ) : (
          <Grid className={classNames.videoWrapper} item xs={6} lg={5}>
            {sessionTime?.prerecordedSession && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  form="create-contribution-form"
                  onClick={() => {
                    onCrossClick();
                  }}
                  type="button"
                  variant="text"
                >
                  <Icon path={mdiCloseCircleOutline} size={1} />
                </Button>
              </div>
            )}

            {!dragging && (
              <Video
                duration={duration}
                form={form}
                session={session}
                sessionIndex={sessionIndex}
                sessionTimeIndex={sessionTimeIndex}
                sessionTime={sessionTime}
                uploadThumbnail={setIsOpenUploadThumbnail}
              />
            )}
            {isOpenUploadThumbnail && (
              <UploadThumbnail
                setIsOpen={setIsOpenUploadThumbnail}
                isOpen={isOpenUploadThumbnail}
                title="Select a Thumbnail"
                setFieldValue={form.setFieldValue}
                thumbnailValue={
                  form.values?.sessions[sessionIndex]?.sessionTimes[sessionTimeIndex]?.selfPacedThumbnailUrl
                }
                fieldName={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].selfPacedThumbnailUrl`}
              />
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {sessionTime?.prerecordedSession?.duration === null ? (
                  <></>
                ) : (
                  !sessionTime?.prerecordedSession?.duration &&
                  !sessionTime?.embeddedVideoUrl && (
                    <>
                      <CommonErrorMessage
                        message={videoError[sessionIndex]?.sessionTimes[sessionTimeIndex]?.prerecordedSession}
                        align="left"
                      />
                    </>
                  )
                )}
              </div>
              {/* {!sessionTime?.embeddedVideoUrl && (
              // <div>
              //   <Tooltip
              //     title='The maximum file size recommended for all media (Photos, Videos, or Audio) to be uploaded to Cohere is 3GB. Compression tools which can be of help are "Clideo" & "Veed” etc. These tools will let you reduce file sizes if your file is larger than 3GB.'
              //     arrow
              //     enterTouchDelay={TOOLTIP.ENTER_DELAY}
              //     leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              //   >
              //     <InfoIcon htmlColor={colors.lightBrown} />
              //   </Tooltip>
              // </div>
            )} */}
            </div>
          </Grid>
        ))}
      <div style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}>
        {session.isPrerecorded && (
          <SessionMoreInfo
            value={sessionTime?.moreInfo}
            title="About this content"
            onChange={onChangeMoreInfo}
            isSessionTime="true"
          />
        )}
      </div>
      <div style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}>
        <FieldArray name={`sessions[${sessionIndex}].sessionTimes[${sessionTimeIndex}].attachments`}>
          {({ push, remove, form }) => {
            return (
              <BottomPanel
                contributionId={contributionId}
                sessionTimeIndex={sessionTimeIndex}
                sessionIndex={sessionIndex}
                sessionTimeId={sessionTime?.id}
                session={session}
                attachments={sessionTime?.attachments}
                isPrerecorded={session.isPrerecorded}
                push={push}
                remove={remove}
                form={form}
              />
            );
          }}
        </FieldArray>

        {/* <BottomPanel
            contributionId={contributionId}
            sessionTimeIndex={sessionTimeIndex}
            sessionIndex={sessionIndex}
            sessionTimeId={sessionTime.id}
            // attachments={sessionTime.attachments}
            isPrerecorded={session.isPrerecorded}
          /> */}
      </div>
    </Grid>
  );
};

SessionTimeSelfPaced.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    handleSubmit: PropTypes.func,
  }).isRequired,
  isEditMode: PropTypes.bool,
  remove: PropTypes.func.isRequired,
  session: PropTypes.shape({
    isPrerecorded: PropTypes.bool,
    sessionTimes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        isCompleted: PropTypes.bool,
        prerecordedSession: PropTypes.shape({
          duration: PropTypes.number,
        }),
        startTime: PropTypes.string,
        ignoreDateAvailable: PropTypes.bool,
        mustWatchPriorSelfPacedRecords: PropTypes.bool,
        subCategoryName: PropTypes.string,
      }),
    ),
  }).isRequired,
  sessionIndex: PropTypes.number.isRequired,
  sessionTimeIndex: PropTypes.number.isRequired,
};

SessionTimeSelfPaced.defaultProps = {
  isEditMode: false,
};
