import React, { useRef, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { useAccount, useClientPreview, useRouter, useScreenView } from 'hooks';
import { Card as CardUI, CardHeader as CardHeaderUI } from 'components/UI/Card';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors, determineColorToUse } from 'services/contributions.service';
import { createLeadMagnetUser } from 'services/leadMagnets.service';
import { useStyles } from 'pages/ContributionView/components/PurchaseBlock/hooks/useStyles';
import { StyledError, StyledButton } from './StyleComponents';
import { lightOrDark } from 'utils/utils';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import { useLeadMagnet } from '../hooks';
import { isEmpty, isObject } from 'lodash';
import styled, { css } from 'styled-components';

const StyledTextField = styled(TextField)`
  ${({ isDarkModeEnabled }) =>
    isDarkModeEnabled
      ? css`
          &.MuiTextField-root {
            border: 1px solid #4a4a4a;
          }
        `
      : css`
          .MuiInputBase-root {
            border: 1px solid #dbe7f2;
            height: 42px;
          }
        `}
`;
const StyledTypography = styled(Typography)`
  color: var(--Cohere-Primary-Blue, #215c73);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
`;

const DownloadNowButton = styled(StyledButton)`
  min-width: unset;
  width: 180px;
  height: 42px;
  line-height: 11px !important;
`;

const LeadMagnetPurchaseBlock = ({ brandingColors }) => {
  const formRef = useRef(null);
  const contribution = useContribution();
  const { colorToUse, themedColors } = useLeadMagnetTheme();
  const leadMagnet = useLeadMagnet();
  const { pathname, replace } = useRouter();
  const [loading, setLoading] = useState(false);
  const { mobileView } = useScreenView();
  const { isCoach } = useAccount();
  const {
    newThemedBackgroundColor: themedBackgroundColor,
    newThemedTextColor: themedColor,
    newThemedCardColor,
  } = themedColors;
  const styledProps = {
    cardBackgroundColor: newThemedCardColor,
    color: themedColor,
    backgroundColor: themedBackgroundColor,
  };
  const classNames = useStyles(styledProps);
  // let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const PrimaryColor = colorToUse?.PrimaryColorCode;
  const { clientPreviewMode } = useClientPreview();

  const handleSubmit = values => {
    setLoading(true);
    const payload = {
      ...values,
      leadMagnetId: leadMagnet?.id,
    };
    return createLeadMagnetUser(payload)
      .then(createdUser => {
        if (!isEmpty(createdUser) && isObject(createdUser)) {
          // here redirect to the thank you page
          // Note: replacing URL so no trace with browser's back button once redirected
          const url = pathname.replace('landingpage', 'thankyoupage');
          replace(url);
        }
      })
      .catch(console.dir)
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Grid
      id="get-free-access-form"
      style={{ backgroundColor: themedBackgroundColor, marginTop: 0, padding: '0px 20px' }}
      className={classNames.wrapper}
      container
    >
      <Grid item md={12} xs={12}>
        <CardUI
          className={classNames.customSectionWrapper}
          style={{
            borderRadius: '0.25rem',
            paddingBottom: 0,
            ...(leadMagnet?.isDarkModeEnabled ? { boxShadow: 'initial' } : {}),
          }}
        >
          <CardContent
            style={{
              paddingBottom: '16px',
              gap: '10px',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: newThemedCardColor,
              ...(leadMagnet?.isDarkModeEnabled ? { borderRadius: '0.25rem' } : {}),
            }}
          >
            <StyledTypography
              variant="h5"
              style={{
                   fontWeight: '600px',
                   fontSize: '20px',
                   color: brandingColors?.AccentColorCode,
              }}
            >
              Free Access
            </StyledTypography>
            <Formik
              innerRef={formRef}
              initialValues={{ email: '', firstName: '', lastName: '' }}
              validationSchema={yup.object().shape({
                email: yup.string().required('Email is a required field').email('Please enter a valid email address'),
                firstName: yup.string().required('First Name is a required field'),
                lastName: yup.string().required('Last Name is a required field'),
              })}
              onSubmit={handleSubmit}
            >
              {formProps => {
                return (
                  <Form>
                    <Grid
                      container
                      style={{ pointerEvents: (clientPreviewMode || isCoach) && 'none', gap: mobileView && '0.62rem' , flexWrap: !mobileView && 'inherit'}}
                    >
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="flex-start"
                        md={12}
                        xs={12}
                        style={{ marginRight: !mobileView && '10px' }}
                      >
                        <StyledTextField
                          fullWidth
                          className={[classNames.inputField, classNames.themedTextField].join(' ')}
                          name="firstName"
                          placeholder={'First Name'}
                          variant="outlined"
                          type="text"
                          onChange={e => {
                            formProps.setFieldValue('firstName', e.target.value);
                          }}
                          //   ref={emailRef}
                          onBlur={formProps.handleBlur}
                          value={formProps.values.firstName}
                          InputProps={{
                            classes: { notchedOutline: classNames.noBorder },
                            inputProps: {
                              style: {
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                              },
                            },
                          }}
                          isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
                        />
                        {formProps.errors && formProps.errors['firstName'] && formProps.touched['firstName'] && (
                          <StyledError PrimaryColor={PrimaryColor}>{formProps.errors['firstName']}</StyledError>
                        )}
                      </Grid>
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="flex-start"
                        md={12}
                        xs={12}
                        style={{marginRight: !mobileView && '10px' }}
                      >
                        <StyledTextField
                          fullWidth
                          className={[classNames.inputField, classNames.themedTextField].join(' ')}
                          name="lastName"
                          placeholder={'Last Name'}
                          variant="outlined"
                          type="text"
                          onChange={e => {
                            formProps.setFieldValue('lastName', e.target.value);
                          }}
                          //   ref={emailRef}
                          onBlur={formProps.handleBlur}
                          value={formProps.values.lastName}
                          InputProps={{
                            classes: { notchedOutline: classNames.noBorder },
                            inputProps: {
                              style: {
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                              },
                            },
                          }}
                          isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
                        />
                        {formProps.errors && formProps.errors['lastName'] && formProps.touched['lastName'] && (
                          <StyledError PrimaryColor={PrimaryColor}>{formProps.errors['lastName']}</StyledError>
                        )}
                      </Grid>
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="flex-start"
                        md={12}
                        xs={12}
                        style={{ marginRight: !mobileView && '10px' }}
                      >
                        <StyledTextField
                          fullWidth
                          className={[classNames.inputField, classNames.themedTextField].join(' ')}
                          name="email"
                          placeholder={'Email'}
                          variant="outlined"
                          type="text"
                          onChange={e => {
                            formProps.setFieldValue('email', e.target.value);
                          }}
                          //   ref={emailRef}
                          onBlur={formProps.handleBlur}
                          value={formProps.values.email}
                          InputProps={{
                            classes: { notchedOutline: classNames.noBorder },
                            inputProps: {
                              style: {
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                              },
                            },
                          }}
                          isDarkModeEnabled={leadMagnet?.isDarkModeEnabled}
                        />
                        {formProps.errors && formProps.errors['email'] && formProps.touched['email'] && (
                          <StyledError PrimaryColor={PrimaryColor}>{formProps.errors['email']}</StyledError>
                        )}
                      </Grid>
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="center"
                        style={{width: !mobileView && '180px', height: !mobileView &&  '42px'}}
                      >
                        <DownloadNowButton
                          style={{
                            backgroundColor: colorToUse?.PrimaryColorCode,
                            fontFamily: 'Poppins',
                            fontSize: '0.875rem',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            marginTop: !mobileView && '5.9px'
                          }}
                          textColor={textColor}
                          backgroundColor={PrimaryColor}
                          marginTop
                          type="submit"
                          // disabled={applicationRequiredButNotApproved || disableJoinBtn || clientPreviewMode}
                        >
                          Download Now
                        </DownloadNowButton>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </CardContent>
        </CardUI>
      </Grid>
    </Grid>
  );
};

export default LeadMagnetPurchaseBlock;
