import React, { useEffect } from 'react';
import ContactsPage from 'pages/CreateCampaign/Pages/ContactsPage/ContactsPage';
import DesignPage from 'pages/CreateCampaign/Pages/DesignPage/DesignPage';
import SummaryPage from 'pages/CreateCampaign/Pages/SummaryPage/SummaryPage';
import TemplatePage from 'pages/CreateCampaign/Pages/TemplatePage/TemplatePage';
import TitlePage from 'pages/CreateCampaign/Pages/TitlePage/TitlePage';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useHeader } from 'hooks/usePageActions';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchDomains } from 'actions/emailMarketing.actions';
import { resetCampaign } from 'actions/campaign';
import Modal from 'components/UI/Modal';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { fetchTimeZones } from 'actions/timeZone';

const CreateCampaignContainer = ({ match: { path } }) => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const mobileView = useMediaQuery('(max-width: 1200px)');

  useEffect(() => {
    dispatch(fetchDomains());
  }, []);

  useEffect(() => {
    dispatch(fetchTimeZones());
  }, []);

  useEffect(() => {
    dispatch(resetCampaign());
  }, []);

  console.log('location<>history<>match', location, history, match);

  useHeader('Create a Campaign');

  return (
    <>
      <Switch>
        <Route path={`${path}/title`} exact component={TitlePage} />
        <Route path={`${path}/title/:id`} exact component={TitlePage} />
        <Route path={`${path}/template`} exact component={TemplatePage} />
        <Route path={`${path}/contacts`} exact component={ContactsPage} />
        <Route path={`${path}/design`} exact component={DesignPage} />
        <Route path={`${path}/summary`} exact component={SummaryPage} />
        <Redirect to={`${path}/title`} />
      </Switch>
      <Modal
        isOpen={mobileView}
        title="Can't Access"
        reduceCancelButton
        hiddenCancel
        onCloseClick={() => {}}
        onSubmit={() => {
          history.push('/dashboard');
        }}
      >
        For optimal performance and full functionality, we recommend using a browser on a desktop or laptop computer.
      </Modal>
    </>
  );
};

export default CreateCampaignContainer;
