import React from 'react';
import * as leadMagnetActions from 'actions/leadMagnets';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { Grid, TextField, useTheme, useMediaQuery } from '@material-ui/core';
import Select from 'components/FormUI/Select';
import InfoIcon from '@material-ui/icons/Info';
import { Formik, Form } from 'formik';
import { Tooltip } from '@material-ui/core';
import { TOOLTIP } from '../../constants';
import Modal from 'components/UI/Modal';
import { Box } from '@mui/material';
import Button from 'components/FormUI/Button';
import {
  ClientContriP,
  DraftBtn,
  ClientMainDvi,
  BtnDiv,
  StyledButton,
  StyledButtonSaved,
  useStyles,
  SubjectHeading,
  GropLeadMagnetHeading,
  PurchaseGroupContainer,
  DropDownMainContainer,
  StyledGridContent,
} from './components/SharedStyledComponents';
import {
  getCustomTemplateByLeadMagnetId,
  resetEmailTemplate,
  updateEmailTemplate,
  sendTestCustomEmailNotification,
} from 'services/leadMagnets.service';
import styled from 'styled-components';
import { CKEditor, useCKEditor } from 'ckeditor4-react';
import { useLeadMagnet } from './hooks';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import { StyledInput, StyledSlider } from 'components/switch/style';
import Loader from 'components/UI/Loader';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';

const useBrandingText =
  'On choosing custom your emails will be branded as per your customized lead magnet colors and logo.';

const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
  color: ${props => props.labelColor};
  label {
    color: ${props => props.labelColor};
  }

  svg {
    color: ${props => props.labelColor};

    path {
      fill: ${props => props.labelColor} !important;
    }
  }
`;

const StyledSendingAddressSelect = styled(StyledSelect)`
  label {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  .MuiSelect-select {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: ${props => props.labelColor};
  }
`;

const SubmitButton = styled(Button)`
  float: right;
  width: 75px;
  height: 40px;
  line-height: 10px;
  margin-top: 10px;
  opacity: '1';
`;

const EmailForm = styled(Form)`
  width: 250px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 20px;
`;
export const EmailHeading = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 850;
  font-size: 14px;
  display: flex;
  ${({ mobileView }) =>
    mobileView &&
    `
  margin: 0px;
  `}
`;
const EnableEmailHeading = styled(EmailHeading)`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  gap: 16px;
`;
export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
  margin: 0;
`;

const LeadMagnetThankYouEmail = () => {
  const dispatch = useDispatch();
  const [emailEnableDisableStatus, setEmailEnableDisableStatus] = useState(null);
  const [sendingAddressState, setSendingAddressState] = useState();
  const leadMagnet = useLeadMagnet();
  const { isDarkModeEnabled, userId } = leadMagnet;
  const [emailObj, setEmailObj] = useState(null);
  const [responseTemplateObj, setResponseTemplateObj] = useState(null);
  const { user } = useSelector(state => state.account);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [modalVisible, setModalVisible] = useState(false);
  const [keywordState, setkeywordState] = useState();
  const [element, setElement] = useState();
  const [isCKEditorVisible, setIsCKEditorVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const { verifiedSendingAddresses } = useEmailMarketing();
  const classes = useStyles();
  const [data, setData] = useState(emailObj?.emailText);
  const [EditorDataUpdated, setEditorDataUpdated] = useState(emailObj?.emailText);
  const { colorToUse, themedColors, textColor, themedCardBackgroundColor } = useLeadMagnetTheme();
  const [emailCategory, setEmailCategory] = useState(emailObj?.category);
  const { themedColor } = themedColors;
  const [emailTemplateBrandingColor, setEmailTemplateBrandingColor] = useState(emailObj?.isCustomBrandingColorsEnabled);
  const [emailSubject, setEmailSubject] = useState(emailObj?.emailSubject);
  const [currentColors, setCurrentColors] = useState(emailObj?.currentColors);
  const [emailText, setEmailText] = useState(emailObj?.emailText);
  const [keywordsList, setKeywordsList] = useState(emailObj?.uniqueKeyWords);
  const [diableButton, setdiableButton] = useState(false);
  const tooltipText = `The listed words placement can be updated but these words can't be changed.`;
  const TOOLTIP_TITLE = 'Copied to clipboard';
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const onCloseTooltip = useCallback(() => setTooltipOpen(false), [setTooltipOpen]);

  const handleEditorChange = event => {
    setEditorDataUpdated(event.editor.getData());
  };

  const handleClosePartnerMadal = () => {
    setModalVisible(false);
  };

  const { editor } = useCKEditor({
    element,
    config: {
      allowedContent: true,
      height: '500px',
      removePlugins: 'elementspath',
    },
    dispatchEvent: e => console.log('Displaying dispatch event', e),
    subscribeTo: ['change'],
    initContent: emailObj?.emailText,
  });

  useEffect(() => {
    if (leadMagnet?.id) {
      setLoading(true);
      getCustomTemplateByLeadMagnetId(leadMagnet.id)
        .then(res => {
          setLoading(false);
          setResponseTemplateObj(res);
          setEmailObj(res?.customTemplates[0]);
          setEmailEnableDisableStatus(res?.customTemplates[0]?.sendEmailAllowed === 'true');
        })
        .catch(console.dir);
    }
  }, [leadMagnet]);

  useEffect(() => {
    if (emailObj) {
      setData(emailObj.emailText);
      setEditorDataUpdated(emailObj.emailText);
      setEmailCategory(emailObj.category);
      setEmailTemplateBrandingColor(emailObj.isCustomBrandingColorsEnabled);
      setEmailSubject(emailObj.emailSubject);
      setCurrentColors(emailObj.currentColors);
      setEmailText(emailObj.emailText);
      if (responseTemplateObj.attachedDomainEmail === null) {
        setSendingAddressState(verifiedSendingAddresses[0].email);
      } else {
        setSendingAddressState(responseTemplateObj.attachedDomainEmail);
      }
      setKeywordsList(emailObj.uniqueKeyWords);
    }
  }, [emailObj]);

  const sendTestNotification = body => {
    if (EditorDataUpdated.length == 0 || emailSubject.length == 0) {
      setModalVisible(true);
    } else {
      setdiableButton(true);
      let testData = {
        ...emailObj,
        leadMagnetId: leadMagnet.id,
        emailText: EditorDataUpdated,
        emailSubject: emailSubject,
        isCustomBrandingColorsEnabled: emailTemplateBrandingColor,
        attachedDomainEmail: sendingAddressState,
      };
      setLoading(true);
      sendTestCustomEmailNotification(testData)
        .then(res => {
          setdiableButton(false);
          setLoading(false);
        })
        .catch(console.dir);
    }
  };

  const updateCustomBranding = e => {
    const params = {
      attachedDomainEmail: sendingAddressState,
      emailText: EditorDataUpdated,
      contributionId: null,
      leadMagnetId: leadMagnet.id,
      name: emailObj.name,
      emailType: emailObj.emailType,
      emailSubject: emailSubject,
      isEmailEnabled: emailObj.isEmailEnabled,
      category: emailObj.category,
      uniqueKeyWords: emailObj.uniqueKeyWords,
      isCustomBrandingColorsEnabled: e,
      currentColors: currentColors,
      sendEmailAllowed:
        emailObj.sendEmailAllowed === null ? 'true' : emailObj.sendEmailAllowed === 'false' ? 'false' : 'true',
      isCustomEmailUpdated: true,
    };

    setLoading(true);
    updateEmailTemplate(params).then(res => {
      setEmailText(null);
      setEmailSubject(res?.emailSubject);
      setEmailTemplateBrandingColor(res?.isCustomBrandingColorsEnabled);
      setEditorDataUpdated(res?.emailText);
      setKeywordsList(res?.uniqueKeyWords);
      setEmailCategory(res?.category);
      setData(res?.emailText);
      setIsCKEditorVisible(true);
      setResetModalVisible(false);
      setEmailText(res?.emailText);
      setCurrentColors(res.currentColors);
      setLoading(false);
    });
  };

  const enableDisableThankYouEmail = e => {
    const params = {
      attachedDomainEmail: sendingAddressState,
      emailText: EditorDataUpdated,
      contributionId: null,
      leadMagnetId: leadMagnet.id,
      name: emailObj.name,
      emailType: emailObj.emailType,
      emailSubject: emailSubject,
      isEmailEnabled: emailObj.isEmailEnabled,
      category: emailObj.category,
      uniqueKeyWords: emailObj.uniqueKeyWords,
      isCustomBrandingColorsEnabled: emailObj.isCustomBrandingColorsEnabled,
      currentColors: currentColors,
      sendEmailAllowed: e ? 'true' : 'false',
      isCustomEmailUpdated: true,
    };
    setLoading(true);
    setEmailEnableDisableStatus(e);
    updateEmailTemplate(params).then(res => {
      setEmailSubject(res?.emailSubject);
      setEmailTemplateBrandingColor(res?.isCustomBrandingColorsEnabled);
      setEditorDataUpdated(res?.emailText);
      setKeywordsList(res?.uniqueKeyWords);
      setEmailCategory(res?.category);
      setData(res?.emailText);
      setIsCKEditorVisible(true);
      setResetModalVisible(false);
      setEmailText(res?.emailText);
      setCurrentColors(res.currentColors);
      setLoading(false);
      dispatch(leadMagnetActions.setWelcomeEmailEnabled(e));
    });
  };

  return (
    <StyledGridContent>
      <Grid container item md={12} xs={12} justifyContent="space-between" alignItems="center">
        <Grid container item md={12} xs={12} justifyContent="space-between" alignItems="center">
          {emailEnableDisableStatus != null && (
            <Box>
              <EnableEmailHeading>
                Enable Welcome Email
                <Switch className="switch" style={{ marginLeft: '8px', marginTop: '-3px' }}>
                  <StyledInput
                    defaultChecked={emailEnableDisableStatus}
                    type="checkbox"
                    onChange={e => {
                      enableDisableThankYouEmail(e.target.checked);
                    }}
                  />
                  <StyledSlider className="slider round" />
                </Switch>
              </EnableEmailHeading>
            </Box>
          )}
          {emailEnableDisableStatus && (
            <BtnDiv>
              <StyledButton
                style={{
                  marginRight: '10px',
                  color: colorToUse.PrimaryColorCode,
                  borderColor: colorToUse.PrimaryColorCode,
                  backgroundColor: leadMagnet?.isDarkModeEnabled ? themedCardBackgroundColor : 'white',
                }}
                onClick={() => {
                  sendTestNotification(emailObj);
                }}
                mobileView={mobileView}
                Width="16px"
                disabled={diableButton}
              >
                Send Test
                <span>
                  <InfoIcon
                    style={{ paddingLeft: '4px' }}
                    htmlColor={diableButton ? 'grey' : colorToUse.PrimaryColorCode}
                  />
                </span>
              </StyledButton>
              <StyledButtonSaved
                style={{
                  backgroundColor:
                    emailObj?.emailText != EditorDataUpdated || emailObj?.emailSubject != emailSubject
                      ? colorToUse.PrimaryColorCode
                      : '',
                  borderWidth:
                    emailObj?.emailText != EditorDataUpdated || emailObj?.emailSubject != emailSubject ? 0 : '',
                }}
                disabled={
                  emailObj?.emailText != EditorDataUpdated ||
                  emailObj?.emailSubject != emailSubject ||
                  responseTemplateObj?.attachedDomainEmail != sendingAddressState
                    ? false
                    : true
                }
                onClick={() => {
                  if (EditorDataUpdated.length == 0 || emailSubject.length == 0 || sendingAddressState == null) {
                    setModalVisible(true);
                  } else {
                    const params = {
                      emailText: EditorDataUpdated,
                      contributionId: null,
                      leadMagnetId: leadMagnet.id,
                      name: emailObj.name,
                      emailType: emailObj.emailType,
                      emailSubject: emailSubject,
                      isEmailEnabled: emailObj.isEmailEnabled,
                      attachedDomainEmail: sendingAddressState,
                      category: emailObj.category,
                      uniqueKeyWords: emailObj.uniqueKeyWords,
                      currentColors: emailObj.currentColors,
                      isCustomBrandingColorsEnabled: emailTemplateBrandingColor,
                      sendEmailAllowed:
                        emailObj.sendEmailAllowed === null
                          ? 'true'
                          : emailObj.sendEmailAllowed === 'false'
                          ? 'false'
                          : 'true',
                      isCustomEmailUpdated: true,
                    };
                    setLoading(true);
                    updateEmailTemplate(params).then(res => {
                      responseTemplateObj.attachedDomainEmail = sendingAddressState;
                      setEmailCategory(res.category);
                      setEmailObj(res);
                      setLoading(false);
                    });
                  }
                }}
                mobileView={mobileView}
                Width="16px"
              >
                Save
              </StyledButtonSaved>
            </BtnDiv>
          )}
        </Grid>
        {emailEnableDisableStatus && (
          <Formik
            initialValues={{
              sendingAddress: sendingAddressState,
            }}
          >
            {formProps => {
              return (
                <EmailForm>
                  <StyledSendingAddressSelect
                    label="Sending Address"
                    name="sendingAddress"
                    labelColor={themedColor}
                    value={sendingAddressState}
                    fullWidth
                    items={verifiedSendingAddresses?.map(data => ({
                      title: data.email,
                      value: data.email,
                    }))}
                    onChange={e => {
                      setSendingAddressState(e.target.value);
                    }}
                  />
                </EmailForm>
              );
            }}
          </Formik>
        )}
      </Grid>
      {emailEnableDisableStatus && (
        <Grid container style={{ width: '100%', margin: 'auto', color: 'red' }}>
          <Grid item md={4}>
            <ClientMainDvi mobileView={mobileView}>
              <ClientContriP style={{ color: themedColor }}>{emailObj?.name}</ClientContriP>
              <DraftBtn>Draft</DraftBtn>
            </ClientMainDvi>
          </Grid>
          <Grid item md={6}></Grid>
        </Grid>
      )}
      {emailEnableDisableStatus && (
        <Grid container className={classes.CkEditorMainDiv}>
          <Grid item md={12} className={classes.CkEditorEmailH}></Grid>
          <Grid item md={12}>
            <PurchaseGroupContainer mobileView={mobileView}>
              <Grid item md={12} style={{ border: 'none' }} className={classes.CkEditorContainer}>
                <GropLeadMagnetHeading style={{ color: themedColor }} mobileView={mobileView}>
                  <Formik initialValues={{ emailSubject: '' }}>
                    <Form>
                      <SubjectHeading style={{ color: themedColor }}>Email Subject:</SubjectHeading>
                      <TextField
                        id="outlined-basic"
                        onChange={e => {
                          setEmailSubject(e.target.value);
                        }}
                        inputProps={{
                          style: {
                            color: leadMagnet?.isDarkModeEnabled ? 'white' : 'black',
                            borderColor: leadMagnet?.isDarkModeEnabled ? 'white' : 'black',
                          },
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: leadMagnet?.isDarkModeEnabled ? classes.notchedOutline : '',
                          },
                        }}
                        name="emailSubject"
                        value={emailSubject}
                        fullWidth
                        variant="outlined"
                      />
                    </Form>
                  </Formik>
                </GropLeadMagnetHeading>
              </Grid>
            </PurchaseGroupContainer>

            <DropDownMainContainer style={{}} mobileView={mobileView}>
              <Grid
                item
                xs={12}
                container
                wrap="nowrap"
                style={{ marginLeft: '50px', justifyContent: 'space-between' }}
              >
                <Grid item md={4} xs={12}>
                  {!loading && (
                    <div>
                      <Formik
                        initialValues={{
                          keywords: null,
                        }}
                      >
                        <Tooltip title={TOOLTIP_TITLE} open={tooltipOpen} onClose={onCloseTooltip} arrow>
                          <StyledSelect
                            label="Keywords"
                            name="keywords"
                            labelColor={themedColor}
                            onChange={e => {
                              setkeywordState(e);
                              navigator.clipboard.writeText(e);
                              setTooltipOpen(true);

                              setTimeout(() => {
                                setTooltipOpen(false);
                              }, 1000);
                            }}
                            sx={{
                              width: 100,
                              height: 40,
                              marginRight: 15,
                              border: '1px solid darkgrey',
                              color: 'red',
                              '& .MuiSvgIcon-root': {
                                color: 'white',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  bgcolor: 'pink',
                                  '& .MuiMenuItem-root': {
                                    padding: 2,
                                  },
                                },
                              },
                            }}
                            value={keywordState}
                            icon={true}
                            items={keywordsList?.map(data => ({
                              title: data.name,
                              value: data.value,
                              icon: true,
                            }))}
                          />
                        </Tooltip>
                      </Formik>
                      <Tooltip title={tooltipText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                        <InfoIcon
                          style={{ marginTop: '30px', marginLeft: '5px' }}
                          htmlColor={colorToUse.PrimaryColorCode}
                        />
                      </Tooltip>
                    </div>
                  )}
                </Grid>
              </Grid>
            </DropDownMainContainer>
          </Grid>
          <Grid container justifyContent="space-between">
            {emailTemplateBrandingColor != null && (
              <Grid item md={4} style={{ margin: '10px 50px' }}>
                <div style={{ display: 'flex' }}>
                  <EmailHeading>
                    Use my branding
                    <Switch className="switch" style={{ marginLeft: '8px' }}>
                      <StyledInput
                        defaultChecked={emailTemplateBrandingColor}
                        type="checkbox"
                        onChange={e => {
                          updateCustomBranding(e.target.checked);
                        }}
                      />
                      <StyledSlider className="slider round" />
                    </Switch>
                    <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                      <StyledInfoIcon
                        htmlColor={colorToUse.PrimaryColorCode}
                        style={{ margin: '0px', marginLeft: '8px' }}
                      />
                    </Tooltip>
                  </EmailHeading>
                </div>
              </Grid>
            )}
            <Grid
              item
              md={4}
              style={{ margin: '10px 50px', display: 'flex', justifyContent: 'flex-end', color: 'gray' }}
            >
              <p
                style={{ cursor: 'pointer', color: leadMagnet?.isDarkModeEnabled ? 'white' : '#4A4A4A' }}
                onClick={() => {
                  setResetModalVisible(true);
                }}
              >
                Revert to Default
              </p>
            </Grid>
          </Grid>
          <Grid item md={12} className={classes.CkEditorContainer}>
            <div className="App" style={{ width: '100%' }}>
              {emailText && (
                <CKEditor
                  config={{
                    allowedContent: true,
                    height: '500px',
                    removePlugins: 'elementspath,dragdrop',
                  }}
                  initData={emailText}
                  onChange={handleEditorChange}
                  onDragOver={e => {
                    e.preventDefault();
                  }}
                  onDrop={(event, editor) => {
                    event.cancel();
                  }}
                />
              )}
            </div>
          </Grid>
          <Modal
            isOpen={modalVisible}
            minHeight={'170px'}
            className={`${leadMagnet?.isDarkModeEnabled ? 'dark-mode' : ''}`}
            style={leadMagnet?.isDarkModeEnabled ? { backgroundColor: 'rgb(45, 47, 49)' } : {}}
            title={''}
            hiddenCancel
            disableFooter={true}
            onCancel={handleClosePartnerMadal}
          >
            <p style={leadMagnet?.isDarkModeEnabled ? { color: 'white' } : {}}>
              {EditorDataUpdated?.length === 0 && emailSubject?.length === 0 && sendingAddressState == null ? (
                <p>Email subject, body, and sending address cannot be empty.</p>
              ) : EditorDataUpdated?.length === 0 && emailSubject?.length === 0 ? (
                <p>Email subject and body cannot be empty.</p>
              ) : emailSubject?.length === 0 && sendingAddressState == null ? (
                <p>Email subject and sending address cannot be empty.</p>
              ) : EditorDataUpdated?.length === 0 && sendingAddressState == null ? (
                <p>Email body and sending address cannot be empty.</p>
              ) : emailSubject?.length === 0 ? (
                <p>Email subject cannot be empty.</p>
              ) : EditorDataUpdated?.length === 0 ? (
                <p>Email body cannot be empty.</p>
              ) : sendingAddressState == null ? (
                <p>Sending address cannot be empty.</p>
              ) : null}
            </p>
            <SubmitButton
              backgroundColor={colorToUse?.PrimaryColorCode}
              textColor={textColor}
              autoWidth
              {...{ isDarkModeEnabled }}
              onClick={handleClosePartnerMadal}
            >
              OK
            </SubmitButton>
          </Modal>
          <Modal
            isOpen={resetModalVisible}
            hiddenCancel
            minHeight={'170px'}
            disableFooter={true}
            className={`${leadMagnet?.isDarkModeEnabled ? 'dark-mode' : ''}`}
            style={leadMagnet?.isDarkModeEnabled ? { backgroundColor: 'rgb(45, 47, 49)' } : {}}
            onCancel={() => {
              setResetModalVisible(false);
              setLoading(false);
            }}
            title={
              <div style={{ ...(leadMagnet?.isDarkModeEnabled ? { color: 'white' } : {}) }}>
                <div>Reset Template</div>
              </div>
            }
          >
            <p style={leadMagnet?.isDarkModeEnabled ? { color: 'white' } : {}}>
              Are you sure you want to reset the template?
            </p>
            <SubmitButton
              backgroundColor={colorToUse?.PrimaryColorCode}
              textColor={textColor}
              autoWidth
              {...{ isDarkModeEnabled }}
              onClick={() => {
                setIsCKEditorVisible(false);
                setLoading(true);
                const params = {
                  attachedDomainEmail: sendingAddressState,
                  emailText: EditorDataUpdated,
                  contributionId: null,
                  leadMagnetId: leadMagnet.id,
                  name: emailObj.name,
                  emailType: emailObj.emailType,
                  emailSubject: emailSubject,
                  isEmailEnabled: emailObj.isEmailEnabled,
                  category: emailCategory,
                  uniqueKeyWords: emailObj.uniqueKeyWords,
                  isCustomBrandingColorsEnabled: emailTemplateBrandingColor,
                };
                resetEmailTemplate(params)
                  .then(res => {
                    setEmailText(null);
                    setEmailSubject(res?.emailSubject);
                    setSendingAddressState(null);
                    setEmailTemplateBrandingColor(null);
                    setEditorDataUpdated(res?.emailText);
                    setKeywordsList(res?.uniqueKeyWords);
                    setEmailCategory(res?.category);
                    setData(res?.emailText);
                    setIsCKEditorVisible(true);
                    setResetModalVisible(false);
                    setEmailText(res?.emailText);
                    setEmailTemplateBrandingColor(res.isCustomBrandingColorsEnabled);
                    setEmailObj(res);

                    setTimeout(() => {
                      setLoading(false);
                    }, 500);
                  })
                  .catch(err => {
                    setIsCKEditorVisible(true);
                  });
              }}
            >
              OK
            </SubmitButton>
          </Modal>
          {loading && <Loader />}
        </Grid>
      )}
    </StyledGridContent>
  );
};

export default LeadMagnetThankYouEmail;
