import React from 'react';

export const BusinessInfoIcon = () => (
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M7 7C7 5.89543 7.89543 5 9 5H16C17.1046 5 18 5.89543 18 7V21H7V7Z" fill="#213649" />
    <path
      d="M4 3C4 1.89543 4.89543 1 6 1H13C14.1046 1 15 1.89543 15 3V19H4V3Z"
      stroke="#213649"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0026 19.0016V5.84375C20.0026 4.73918 19.1072 3.84375 18.0026 3.84375H15.3359"
      stroke="#213649"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M7 12H8" stroke="#213649" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11 12H12" stroke="#213649" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7 9H8" stroke="#213649" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11 9H12" stroke="#213649" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7 6H8" stroke="#213649" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11 6H12" stroke="#213649" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
