import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const BaseURL = '/EmailMarketing';

const createDraftCampaign = data => axiosInstance.post(`${BaseURL}/Draft`, data).then(get('data'));
const updateDraftCampaign = data => axiosInstance.put(`${BaseURL}/Draft`, data).then(get('data'));
const updateApprovedCampaign = data => axiosInstance.put(`${BaseURL}`, data).then(get('data'));
const submitDraftCampaign = data => axiosInstance.post(`${BaseURL}/Submit`, data).then(get('data'));
const getUserContacts = () => axiosInstance.get(`${BaseURL}/GetUserContacts`).then(get('data'));
const getEmailCampaignById = id => axiosInstance.get(`${BaseURL}/GetEmailCampaignById/${id}`).then(get('data'));
const getUserEmailTemplate = () => axiosInstance.get(`${BaseURL}/GetUserEmailTemplate`).then(get('data'));
const getAllEmailCampaigns = () => axiosInstance.get(`${BaseURL}/GetAllEmailCampaigns`).then(get('data'));
const sendTestEmail = data => {
  return axiosInstance.post(`${BaseURL}/SendTestEmail`, data).then(get('data'));
};
export const getAllCampaignAnalytics = () => axiosInstance.get(`${BaseURL}/GetAllCampaignAnalytics`).then(get('data'));
export const getTotalSentEmails = () => axiosInstance.get(`${BaseURL}/GetTotalSentEmails`).then(get('data'));

export const unsubscribeEmailCampaign = data =>
  axiosInstance.post(`${BaseURL}/UnsubscribeEmailCampaign`, data).then(get('data'));
export const resubscribeEmailCampaign = data =>
  axiosInstance.post(`${BaseURL}/ResubscribeEmailCampaign`, data).then(get('data'));

const saveEmailContentHtml = emailContent =>
  axiosInstance.post(`${BaseURL}/SaveEmailContentHtml`, emailContent).then(get('data'));

export const createCampaignServices = {
  createDraftCampaign,
  updateDraftCampaign,
  updateApprovedCampaign,
  submitDraftCampaign,
  saveEmailContentHtml,
  sendTestEmail,
  getUserContacts,
  getUserEmailTemplate,
  getAllEmailCampaigns,
  getEmailCampaignById,
};
