import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRouter from 'hooks/useRouter';
import Grid from '@material-ui/core/Grid';
import { TextField, Tooltip, Typography, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import InfoIcon from '@material-ui/icons/Info';
import styled from 'styled-components';
import ButtonExample from 'pages/Profile/ButtonExample';
import { AvatarUploader } from 'components/AvatarUploader/AvatarUploader';
import { TOOLTIP } from '../../../../constants';
import { CopyIcon } from '../icons/Copy';

const StyledColorSection = styled.div`
  margin-top: 15px;
`;

const StyledColorSelector = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: -23px;
  top: calc(50% - 0px);
  transform: translateY(-50%);
`;

const StyledColorPicker = styled.div`
  border: 1px solid rgb(178, 202, 224);
  border-radius: 5px;
  display: flex;
  padding: 3px;
  width: 145px;
  height: 32px;
`;

const StyledHeading = styled(Typography)`
  color: '#213649';
`;

const StyledColorCodeWrapper = styled.div`
  display: flex;
  width: 70%;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
`;

const BrandingElement = ({ values, setFieldValue, profileLinkName = '' }) => {
  const [copiedSuccess, setCopiedSuccess] = useState({ copied: '' });
  const { parentDomain } = useRouter();
  const profilePageViewModel = (values && values.profilePageViewModel) || {};
  const brandingColors = (values && values.brandingColors) || {};
  const customLogo = (values && values.customLogo) || '';
  const { isDarkModeEnabled } = profilePageViewModel;
  const primaryColor = brandingColors.PrimaryColorCode || '#CDBA8F';
  const accentColor = brandingColors.AccentColorCode || '#116582';
  const tertiaryColor = brandingColors.TertiaryColorCode || '#F6E8BO';
  const textColor = brandingColors.TextColorCode || '#000000';
  const colorOptions = [
    { value: '#000000', name: 'Black' },
    { value: '#FFFFFF', name: 'White' },
    { value: 'Auto', name: 'Auto' },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopiedSuccess({ copied: '' });
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [copiedSuccess]);

  return (
    <>
      <Grid container item className="branding-container profile-section">
        <Grid item xs={12} style={{ height: '120px' }}>
          <div className="heading">
            <p>Brand Elements</p>
          </div>
          <div>
            <p>Cohere allows you to adjust your custom branding colors</p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className="message-settings"
          style={{ borderBottom: '1px solid #E7E7E7', paddingBottom: '20px' }}
        >
          <p>Choose Style Mode</p>
          <ToggleButtonGroup
            className="toggle-button"
            color="primary"
            value={isDarkModeEnabled ? 'dark' : 'light'}
            exclusive
            onChange={() =>
              setFieldValue('profilePageViewModel', {
                ...profilePageViewModel,
                isDarkModeEnabled: !isDarkModeEnabled,
              })
            }
            aria-label="Platform"
          >
            <ToggleButton value="light">Light</ToggleButton>
            <ToggleButton value="dark">Dark</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item sm={12} md={12} xs={12} className="profile-picture">
          <p className="field-title">Logo</p>
          <AvatarUploader avatarUrl={customLogo} updateImg={val => setFieldValue('customLogo', val)} />
        </Grid>
        <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7', marginTop: '20px' }}>
          <StyledColorSection>
            <StyledColorSelector>
              <StyledTooltipWrap>
                <StyledHeading>Primary color</StyledHeading>
                <StyledTooltip
                  title="This color will be associated with call-to-action (CTA) buttons on your branded pages"
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon htmlColor="#47627B" />
                </StyledTooltip>
              </StyledTooltipWrap>
              <StyledColorPicker>
                <ButtonExample
                  colorCode={primaryColor}
                  onchangevalue={code => {
                    setFieldValue('brandingColors', {
                      ...brandingColors,
                      PrimaryColorCode: code,
                    });
                  }}
                />
                <StyledColorCodeWrapper>
                  <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                  <div style={{ width: '85%' }}>
                    <TextField
                      name="PrimaryColorCode"
                      value={primaryColor?.substring(1)}
                      type="text"
                      onChange={e => {
                        const ccode = '#'.concat(e.target.value);
                        if (ccode.length <= 7) {
                          setFieldValue('brandingColors', {
                            ...brandingColors,
                            PrimaryColorCode: ccode,
                          });
                        }
                      }}
                      fullWidth
                    />
                  </div>
                </StyledColorCodeWrapper>
              </StyledColorPicker>
            </StyledColorSelector>
            <StyledColorSelector>
              <StyledTooltipWrap>
                <StyledHeading>Accent color</StyledHeading>
                <StyledTooltip
                  title="The color will be associated with various tabs, labels, and misc. components on your branded pages."
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon htmlColor="#47627B" />
                </StyledTooltip>
              </StyledTooltipWrap>
              <StyledColorPicker>
                <ButtonExample
                  colorCode={accentColor}
                  onchangevalue={code => {
                    setFieldValue('brandingColors', {
                      ...brandingColors,
                      AccentColorCode: code,
                    });
                  }}
                />
                <StyledColorCodeWrapper>
                  <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                  <div style={{ width: '85%' }}>
                    <TextField
                      name="AccentColorCode"
                      value={accentColor?.substring(1)}
                      type="text"
                      onChange={e => {
                        const ccode = '#'.concat(e.target.value);
                        if (ccode.length <= 7) {
                          setFieldValue('brandingColors', {
                            ...brandingColors,
                            AccentColorCode: ccode,
                          });
                        }
                      }}
                      fullWidth
                    />
                  </div>
                </StyledColorCodeWrapper>
              </StyledColorPicker>
            </StyledColorSelector>
            <StyledColorSelector>
              <StyledTooltipWrap>
                <StyledHeading>Tertiary color</StyledHeading>
                <StyledTooltip
                  title="This color is only used if you have more than two categories on your Contribution."
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon htmlColor="#47627B" />
                </StyledTooltip>
              </StyledTooltipWrap>
              <StyledColorPicker>
                <ButtonExample
                  colorCode={tertiaryColor || '#F6E8BO'}
                  onchangevalue={code => {
                    setFieldValue('brandingColors', {
                      ...brandingColors,
                      TertiaryColorCode: code,
                    });
                  }}
                />
                <StyledColorCodeWrapper>
                  <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                  <div style={{ width: '85%' }}>
                    <TextField
                      name="TertiaryColorCode"
                      value={tertiaryColor?.substring(1)}
                      type="text"
                      onChange={e => {
                        const ccode = '#'.concat(e.target.value);
                        if (ccode.length <= 7) {
                          setFieldValue('brandingColors', {
                            ...brandingColors,
                            TertiaryColorCode: ccode,
                          });
                        }
                      }}
                      fullWidth
                    />
                  </div>
                </StyledColorCodeWrapper>
              </StyledColorPicker>
            </StyledColorSelector>
            <StyledColorSelector>
              <StyledTooltipWrap>
                <StyledHeading>Text color</StyledHeading>
                <StyledTooltip
                  title="This color is used to show text color on your Contribution."
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon htmlColor="#47627B" />
                </StyledTooltip>
              </StyledTooltipWrap>
              <Select
                className="select-color"
                variant="outlined"
                input={<OutlinedInput margin="dense" />}
                value={textColor || 'Auto'}
                onChange={code => {
                  setFieldValue('brandingColors', {
                    ...brandingColors,
                    TextColorCode: code.target.value,
                  });
                }}
              >
                {colorOptions?.length > 0 &&
                  colorOptions.map(timeZone => {
                    return <MenuItem value={timeZone.value}>{timeZone.name}</MenuItem>;
                  })}
              </Select>
            </StyledColorSelector>
          </StyledColorSection>
        </Grid>
        {profileLinkName && (
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <div className="heading">
              <p>Your Page Link</p>
            </div>
          </Grid>
        )}
        {profileLinkName && (
          <Grid item xs={12} className="message-settings">
            <p className="website-url">
              {profileLinkName}.{parentDomain}
            </p>
            <CopyIcon
              onClick={() => {
                window.navigator.clipboard.writeText(profileLinkName.concat('.', parentDomain));
                setCopiedSuccess({ copied: 'Copied!' });
              }}
            />
            <p>{copiedSuccess.copied}</p>
          </Grid>
        )}
      </Grid>
    </>
  );
};

BrandingElement.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    profileLinkName: PropTypes.string,
    customLogo: PropTypes.string,
    brandingColors: {
      PrimaryColorCode: PropTypes.string,
      AccentColorCode: PropTypes.string,
      TertiaryColorCode: PropTypes.string,
      TextColorCode: PropTypes.string,
    },
    profilePageViewModel: {
      isDarkModeEnabled: PropTypes.bool,
      primaryColor: PropTypes.string,
      accentColor: PropTypes.string,
      tertiaryColor: PropTypes.string,
    },
  }).isRequired,
  profileLinkName: PropTypes.string.isRequired,
};

export default BrandingElement;
