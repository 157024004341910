import React from 'react';

export const CrossOutlineIcon = ({ fillColor = '#116582' }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.639893" width="23" height="23" rx="11.5" stroke="black" />
      <path
        d="M19 6.54989L17.59 5.13989L12 10.7299L6.41 5.13989L5 6.54989L10.59 12.1399L5 17.7299L6.41 19.1399L12 13.5499L17.59 19.1399L19 17.7299L13.41 12.1399L19 6.54989Z"
        fill="black"
        fill-opacity="0.87"
      />
    </svg>
  );
};

export const CameraIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
      <ellipse cx="16.5501" cy="16.3074" rx="16" ry="16" fill="#CDBA8F" />
      <path
        d="M24.4119 21.5046C24.4119 22.1762 23.8693 22.7195 23.2033 22.7195H9.89651C9.23055 22.7195 8.68794 22.1762 8.68794 21.5046V13.3375C8.68794 12.6659 9.23055 12.1226 9.89651 12.1226H11.5169C12.4422 12.1226 13.306 11.6592 13.8178 10.8882C14.2295 10.268 14.9244 9.89522 15.6688 9.89522H17.431C18.1754 9.89522 18.8704 10.268 19.2821 10.8882L19.507 10.7389L19.2821 10.8882C19.7938 11.6592 20.6577 12.1226 21.583 12.1226H23.2033C23.8693 12.1226 24.4119 12.6659 24.4119 13.3375V21.5046Z"
        stroke="white"
        stroke-width="0.539945"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.55 14.9866C17.8599 14.9866 18.9219 15.9729 18.9219 17.1895C18.9219 18.406 17.8599 19.3924 16.55 19.3924C15.2402 19.3924 14.1782 18.406 14.1782 17.1895C14.1782 15.9729 15.2402 14.9866 16.55 14.9866Z"
        stroke="white"
        stroke-width="0.539945"
      />
    </svg>
  );
};

export const AudioIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="105" viewBox="0 0 82 105" fill="none">
      <path
        d="M33.5242 91.5367C36.7053 91.5367 39.3925 90.4478 41.5858 88.27C43.7753 86.0844 44.87 83.3972 44.87 80.2083V56.9917H56.3092C58.7898 56.9917 60.8008 54.9807 60.8008 52.5C60.8008 50.0193 58.7898 48.0083 56.3092 48.0083H40.3842V71.8492C39.4664 70.8497 38.4358 70.105 37.2925 69.615C36.1531 69.125 34.9028 68.88 33.5417 68.88C30.3528 68.88 27.6675 69.9689 25.4858 72.1467C23.3042 74.3244 22.2133 77.0058 22.2133 80.1908C22.2133 83.3719 23.3022 86.0592 25.48 88.2525C27.6578 90.4419 30.3392 91.5367 33.5242 91.5367ZM6 105C2.68629 105 0 102.314 0 99V6C0 2.68629 2.68629 0 6 0H52.9314C54.5227 0 56.0488 0.632141 57.174 1.75736L79.9093 24.4926C81.0345 25.6179 81.6667 27.144 81.6667 28.7353V99C81.6667 102.314 78.9804 105 75.6667 105H6ZM58.5 29.1667C55.1863 29.1667 52.5 26.4804 52.5 23.1667V6.83333C52.5 6.28105 52.0523 5.83333 51.5 5.83333H5.83333V97.1667C5.83333 98.2712 6.72876 99.1667 7.83333 99.1667H73.8333C74.9379 99.1667 75.8333 98.2712 75.8333 97.1667V30.1667C75.8333 29.6144 75.3856 29.1667 74.8333 29.1667H58.5Z"
        fill="#215C73"
      />
    </svg>
  );
};

export const PdfIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="106" viewBox="0 0 86 106" fill="none">
      <path
        d="M11.143 0.140265C5.16502 0.140265 0.298828 5.00994 0.298828 10.9877V94.2925C0.298828 100.27 5.16502 105.14 11.143 105.14H74.8435C80.8215 105.14 85.6877 100.27 85.6877 94.2925V25.7927C85.6877 25.7927 85.8582 23.7869 84.9875 21.8351C84.1713 20.0055 82.841 18.7321 82.841 18.7321C82.8378 18.7276 82.8344 18.7231 82.8311 18.7186L67.0268 3.21665C67.0181 3.20781 67.0093 3.19905 67.0005 3.19039C67.0005 3.19039 65.6511 1.90553 63.6509 1.04721C61.2957 0.0365708 58.8682 0.143257 58.8682 0.143257L58.9044 0.139893L11.143 0.140265Z"
        fill="#FF2116"
      />
      <path
        d="M11.143 3.70361H58.9045C58.9165 3.70374 58.9286 3.70374 58.9406 3.70361C58.9406 3.70361 60.8505 3.72212 62.2474 4.32159C63.592 4.89855 64.5453 5.77193 64.5517 5.77778C64.5517 5.7778 64.5583 5.78451 64.5583 5.78451L80.3232 21.2503C80.3232 21.2503 81.273 22.2531 81.7333 23.285C82.1049 24.1179 82.1278 25.6419 82.1278 25.6419C82.1261 25.667 82.125 25.6922 82.1244 25.7174V94.2928C82.1244 98.3587 78.9095 101.577 74.8435 101.577H11.143C7.07701 101.577 3.86206 98.3589 3.86206 94.2928V10.9879C3.86206 6.92178 7.07701 3.70361 11.143 3.70361Z"
        fill="#F5F5F5"
      />
      <path
        d="M21.6198 62.6561C19.1682 60.2045 21.8207 56.8357 29.0191 53.2583L33.5484 51.0075L35.3129 47.1467C36.2834 45.0233 37.7319 41.5585 38.5317 39.4473L39.986 35.6087L38.9838 32.768C37.7516 29.275 37.3122 24.0259 38.094 22.1384C39.153 19.5818 42.6217 19.8441 43.995 22.5846C45.0675 24.725 44.9579 28.6011 43.6865 33.4895L42.6443 37.4971L43.5623 39.0557C44.0673 39.9128 45.5422 41.9482 46.84 43.5786L49.279 46.6126L52.3141 46.2165C61.956 44.9583 65.2584 47.0967 65.2584 50.1627C65.2584 54.0322 57.6875 54.3516 51.3298 49.8863C49.8993 48.8816 48.917 47.8835 48.917 47.8835C48.917 47.8835 44.9341 48.6944 42.9731 49.2229C40.949 49.7685 39.9391 50.1101 36.9741 51.111C36.9741 51.111 35.9338 52.6217 35.2559 53.7201C32.7338 57.8062 29.7889 61.1925 27.6859 62.425C25.3312 63.8049 22.8627 63.899 21.6198 62.6561ZM25.4672 61.2817C26.8453 60.4299 29.6346 57.131 31.5654 54.0692L32.3471 52.8295L28.7876 54.6192C23.2901 57.3833 20.775 59.9885 22.0828 61.5643C22.8175 62.4495 23.6964 62.3761 25.4672 61.2817ZM61.1771 51.2581C62.525 50.314 62.3293 48.4115 60.8055 47.6444C59.6195 47.0474 58.6637 46.9248 55.5829 46.9703C53.6897 47.0993 50.6454 47.4809 50.1294 47.5969C50.1294 47.5969 51.8018 48.7528 52.5443 49.1776C53.5328 49.7422 55.9347 50.7901 57.6884 51.3269C59.4183 51.8564 60.4189 51.8006 61.1771 51.2581ZM46.8186 45.2903C46.0029 44.4328 44.6158 42.6431 43.7362 41.3131C42.5858 39.8044 42.0089 38.74 42.0089 38.74C42.0089 38.74 41.1681 41.4445 40.4783 43.0719L38.3262 48.3915L37.7022 49.5981C37.7022 49.5981 41.0194 48.5104 42.7072 48.0696C44.4948 47.6026 48.1229 46.8892 48.1229 46.8892L46.8186 45.2903ZM42.1914 26.7341C42.3995 24.988 42.4878 23.2445 41.9268 22.3653C40.3707 20.6642 38.4925 22.0828 38.8107 26.1271C38.9177 27.4876 39.2557 29.8134 39.7076 31.2469L40.5292 33.8532L41.1074 31.8902C41.4254 30.8105 41.9133 28.4903 42.1914 26.7341Z"
        fill="#FF2116"
      />
      <path
        d="M25.7889 74.009H29.7692C31.041 74.009 32.0733 74.1307 32.8663 74.3741C33.6592 74.6096 34.3265 75.1395 34.8682 75.9638C35.4099 76.7803 35.6807 77.7655 35.6807 78.9196C35.6807 79.9794 35.4609 80.8901 35.0212 81.6516C34.5816 82.4131 33.9889 82.9626 33.2431 83.3002C32.5051 83.6378 31.3668 83.8066 29.8281 83.8066H28.4503V90.0831H25.7889V74.009ZM28.4503 76.0698V81.6752H29.7692C30.9468 81.6752 31.7593 81.4553 32.2068 81.0157C32.6621 80.576 32.8898 79.8616 32.8898 78.8725C32.8898 78.1345 32.7406 77.5379 32.4423 77.0825C32.144 76.6193 31.8143 76.3367 31.4531 76.2347C31.0999 76.1247 30.5386 76.0697 29.7692 76.0697L28.4503 76.0698ZM37.7179 74.009H41.3331C43.0838 74.009 44.4812 74.3191 45.5254 74.9393C46.5773 75.5595 47.3702 76.482 47.9041 77.7067C48.4458 78.9313 48.7166 80.2895 48.7166 81.7811C48.7166 83.3512 48.4733 84.7526 47.9865 85.9851C47.5076 87.2098 46.7697 88.199 45.7726 88.9526C44.7835 89.7063 43.3704 90.0831 41.5333 90.0831H37.7179V74.009ZM40.3793 76.1405V87.9517H41.4862C43.0328 87.9517 44.1554 87.4178 44.8541 86.3501C45.5528 85.2746 45.9022 83.8419 45.9022 82.052C45.9022 78.111 44.4302 76.1405 41.4862 76.1405H40.3793ZM51.272 74.009H60.1981V76.1405H53.9333V80.945H58.9499V83.0765H53.9333V90.0831H51.272V74.009Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};

export const DownloadIcon = ({ color = '#215C73' }) => {
  return (
    <svg width="1.5rem" height="1.5rem" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 12.75V14.25C3 14.6478 3.15804 15.0294 3.43934 15.3107C3.72064 15.592 4.10218 15.75 4.5 15.75H13.5C13.8978 15.75 14.2794 15.592 14.5607 15.3107C14.842 15.0294 15 14.6478 15 14.25V12.75M5.25 8.25L9 12M9 12L12.75 8.25M9 12V3"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const GoToIcon = ({ color = '#215C73' }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 4.50781H6C5.46957 4.50781 4.96086 4.71853 4.58579 5.0936C4.21071 5.46867 4 5.97738 4 6.50781V18.5078C4 19.0382 4.21071 19.547 4.58579 19.922C4.96086 20.2971 5.46957 20.5078 6 20.5078H18C18.5304 20.5078 19.0391 20.2971 19.4142 19.922C19.7893 19.547 20 19.0382 20 18.5078V14.5078M12 12.5078L20 4.50781M20 4.50781V9.50781M20 4.50781H15"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PictureIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.999 4.3999H3.99899C2.89599 4.3999 1.99899 5.2969 1.99899 6.3999V18.3999C1.99899 19.5029 2.89599 20.3999 3.99899 20.3999H19.999C21.102 20.3999 21.999 19.5029 21.999 18.3999V6.3999C21.999 5.2969 21.102 4.3999 19.999 4.3999ZM6.49899 7.3999C6.89682 7.3999 7.27835 7.55794 7.55965 7.83924C7.84096 8.12055 7.99899 8.50208 7.99899 8.8999C7.99899 9.29773 7.84096 9.67926 7.55965 9.96056C7.27835 10.2419 6.89682 10.3999 6.49899 10.3999C6.10117 10.3999 5.71964 10.2419 5.43833 9.96056C5.15703 9.67926 4.99899 9.29773 4.99899 8.8999C4.99899 8.50208 5.15703 8.12055 5.43833 7.83924C5.71964 7.55794 6.10117 7.3999 6.49899 7.3999ZM11.999 17.3999H4.99899L8.99899 12.3999L10.499 14.3999L13.499 10.3999L18.999 17.3999H11.999Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};
