import React, { useState, useRef } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Loader from 'components/UI/Loader';
import { useSelector } from 'react-redux';
import { determineColorToUse, determineDarkThemedColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import Button from 'components/FormUI/Button';
import { mdiDelete } from '@mdi/js';
import Icon from '../../../../components/UI/Icon';
import CopySvg from '../ShareModal/Content/CopyIcon';
import TextArea from 'components/FormUI/TextArea';
import { updateContributionAffiliate } from '../../../../services/affiliate.service';
import Tooltip from '@material-ui/core/Tooltip';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import EditButton from 'components/UI/ListButtons/EditButton';
import { DialogActions, Box, TextField, Grid } from '@material-ui/core';
import { TOOLTIP } from '../../../../constants';
import { useTheme, useMediaQuery } from '@material-ui/core';
import copyToClipboard from 'utils/copyToClipboard';
import Modal from 'components/UI/Modal';
const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: 0,
  },
})(TableCell);
const MIN_STRING_LENGTH = 3;
const MAX_STRING_LENGTH = 100;
const TOOLTIP_TITLE = 'Link Copied';
export const AffiliateRow = ({ index, row, getContributionAffiliateData }) => {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const [editingField, seteditingField] = useState(null);
  const [editingFieldValue, seteditingFieldValue] = useState(null);
  const [showFreeLinkToolTip, setshowFreeLinkToolTip] = useState(false);
  const [showPaidLinkToolTip, setshowPaidLinkToolTip] = useState(false);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(activeContribution);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  let colorToUse = determineColorToUse(activeContribution);
  const accentColor = colorToUse.AccentColorCode;
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const [loader, setLoader] = useState(false);
  const formRef = useRef(null);
  const initialValues = {
    FirstName: row?.firstName,
    LastName: row?.lastName,
    Email: row?.email,
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Yup.object().shape({
        FirstName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
        LastName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
        Email: Yup.mixed().required(),
      })}
      onSubmit={() => {}}
    >
      {({ setFieldValue, handleChange, values, errors, touched, setTouched, setErrors }) => {
        return (
          <>
            <TableRow key={index}>
              <NarrowTableCell component="th" scope="row">
                <Tooltip
                  title={values.FirstName}
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <p
                    onClick={() => {
                      seteditingField('First Name');
                      seteditingFieldValue(values.FirstName);
                    }}
                    style={{ margin: 0, color: isDarkThemeEnabled ? 'white' : 'black' }}
                  >
                    {mobileView
                      ? values.FirstName?.length > 10
                        ? values.FirstName.substr(0, 4) + '...'
                        : values.FirstName
                      : values.FirstName?.length > 15
                      ? values.FirstName.substr(0, 7) + '...'
                      : values.FirstName}
                    {/* {values.FirstName} */}
                    <EditButton color={accentColor} />
                  </p>
                </Tooltip>
              </NarrowTableCell>
              <NarrowTableCell>
                <Tooltip
                  title={values.LastName}
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <p
                    onClick={() => {
                      seteditingField('Last Name');
                      seteditingFieldValue(values.LastName);
                    }}
                    style={{ margin: 0, color: isDarkThemeEnabled ? 'white' : 'black' }}
                  >
                    {mobileView
                      ? values.LastName?.length > 10
                        ? values.LastName.substr(0, 4) + '...'
                        : values.LastName
                      : values.LastName?.length > 15
                      ? values.LastName.substr(0, 7) + '...'
                      : values.LastName}
                    <EditButton color={accentColor} />
                  </p>
                </Tooltip>
              </NarrowTableCell>
              <NarrowTableCell style={mobileView ? { paddingRight: '5px' } : {}}>
                <Tooltip
                  title={values.Email}
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  {mobileView ? (
                    <p style={{ margin: 0, color: isDarkThemeEnabled ? 'white' : 'black' }}>
                      {values.Email?.length > 30 ? values.Email.substr(0, 10) + '...' : values.Email}
                      {/* {values.Email} */}
                    </p>
                  ) : (
                    <p style={{ margin: 0, color: isDarkThemeEnabled ? 'white' : 'black' }}>
                      {values.Email?.length > 30 ? values.Email.substr(0, 20) + '...' : values.Email}
                      {/* {values.Email} */}
                    </p>
                  )}
                </Tooltip>
              </NarrowTableCell>
              <NarrowTableCell>
                <Tooltip
                  // className="cursor-pointer"
                  title={TOOLTIP_TITLE}
                  arrow
                  open={showFreeLinkToolTip}
                  onOpen={() => {
                    setTimeout(() => {
                      setshowFreeLinkToolTip(false);
                    }, 2000);
                  }}
                >
                  <Button
                    backgroundColor={btnColor}
                    textColor={textColor}
                    style={mobileView ? { padding: '7px 10px' } : { padding: '11px 20px 11px 20px' }}
                    onClick={() => {
                      copyToClipboard(row?.freeAffiliateLink);
                      setshowFreeLinkToolTip(true);
                    }}
                  >
                    <CopySvg color={textColor || 'white'} /> {!mobileView && `Copy Link`}
                  </Button>
                </Tooltip>
              </NarrowTableCell>
              {activeContribution?.paymentInfo?.paymentOptions?.length === 1 &&
              activeContribution?.paymentInfo?.paymentOptions?.includes('Free') ? (
                <NarrowTableCell align="center"></NarrowTableCell>
              ) : (
                <NarrowTableCell>
                  <Tooltip
                    // className="cursor-pointer"
                    title={TOOLTIP_TITLE}
                    arrow
                    open={showPaidLinkToolTip}
                    onOpen={() => {
                      setTimeout(() => {
                        setshowPaidLinkToolTip(false);
                      }, 2000);
                    }}
                  >
                    <Button
                      backgroundColor={btnColor}
                      textColor={textColor}
                      style={mobileView ? { padding: '7px 10px' } : { padding: '11px 20px 11px 20px' }}
                      onClick={() => {
                        copyToClipboard(row?.paidAffiliateLink);
                        setshowPaidLinkToolTip(true);
                      }}
                    >
                      <CopySvg color={textColor || 'white'} /> {!mobileView && `Copy Link`}
                    </Button>
                  </Tooltip>
                </NarrowTableCell>
              )}
              <NarrowTableCell>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await updateContributionAffiliate({ id: row.id, isDeleted: true })
                      .then(() => {
                        getContributionAffiliateData();
                      })
                      .catch(() => {
                        getContributionAffiliateData();
                      });
                  }}
                >
                  <Icon color={colorToUse?.AccentColorCode} path={mdiDelete} right="5" size={0.9} />
                </div>
              </NarrowTableCell>
            </TableRow>
            {loader && <Loader />}
            <Modal
              title={`Editing Affiliate ${editingField}`}
              isOpen={editingField != null}
              submitTitle="Close"
              hiddenCancel
              disableFooter
              onCancel={() => {
                seteditingField(null);
              }}
              dontCancelOnSideClick
              onSubmit={() => {
                seteditingField(null);
              }}
              style={{ zIndex: '1200' }}
              widthRequired="30%"
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span style={{ width: '300px' }}>
                  <TextField
                    className="input-field"
                    id="outlined-basic"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={editingFieldValue}
                    onChange={e => {
                      seteditingFieldValue(e.target.value);
                    }}
                    onBlur={e => {}}
                  />
                </span>
              </Box>
              <Grid container item xs justifyContent="center" direction="column" alignItems="center">
                <DialogActions>
                  <Button
                    style={{ height: '50px', width: '130px' }}
                    onClick={async () => {
                      let params;
                      if (editingField === 'First Name') {
                        params = {
                          firstName: editingFieldValue,
                          // lastName: row.lastName,
                          id: row.id,
                          // email: row.email
                        };
                      } else if (editingField === 'Last Name') {
                        params = {
                          lastName: editingFieldValue,
                          // firstName: row.firstName,
                          id: row.id,
                          // email: row.email
                        };
                      }
                      await updateContributionAffiliate(params)
                        .then(() => {
                          getContributionAffiliateData();
                          seteditingField(null);
                          seteditingFieldValue(null);
                        })
                        .catch(() => {
                          getContributionAffiliateData();
                          seteditingField(null);
                          seteditingFieldValue(null);
                        });
                    }}
                    disabled={editingFieldValue?.length === 0}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Grid>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
};
AffiliateRow.propTypes = {
  index: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    recentContributionId: PropTypes.string.isRequired,
    recentContributionName: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    revenueEarned: PropTypes.string.isRequired,
  }),
  setAffiliateRowId: PropTypes.func.isRequired,
};

AffiliateRow.defaultProps = {
  row: {
    id: '',
    name: '',
    recentContributionId: '',
    recentContributionName: '',
    image: '',
    revenueEarned: {
      usd: '$0.0',
    },
  },
};
