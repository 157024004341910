import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { Icon } from '@mdi/react';
import { mdiLoading, mdiWeatherSunny, mdiTimerSandEmpty, mdiEyeCircleOutline } from '@mdi/js';
import classnames from 'classnames';
import moment from 'moment';
import { useHttp } from 'hooks';
import { toRem, devices } from 'utils/styles';
import { connect, useDispatch } from 'react-redux';
import { getClientProfileValidationSchema } from 'utils/validation';
import { parseDate } from 'utils/datesAndMoney';
import Button from 'components/FormUI/Button';
import TextArea from 'components/FormUI/TextArea';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import MaskedInput from 'components/FormUI/MaskedInput';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import * as userActions from 'actions/user';
import { ProfileAvatar } from 'components/ProfileAvatar/ProfileAvatar';
import Interests from './components/Interests';
import * as timeZoneActions from 'actions/timeZone';
import { getUserProfile } from 'services/user.service';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 550,
  },
  row: {
    '& + &': {
      marginTop: 20,
    },
  },
  item: {
    flexBasis: 'auto',
  },
}));

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 10px 30px;
  @media screen and (${devices.laptop}) {
    flex-direction: row;
  }
`;

const StyledInterestsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (${devices.laptop}) {
    margin-left: 40px;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: ${toRem(24)};
`;

function ClientProfilePage({ user, editProfile, loading, error, timeZoneLoading, timeZones, fetchTimeZones }) {
  const { request } = useHttp();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [preferences, setPreferences] = useState([]);
  const [userData, setUserData] = useState(user);
  const formRef = useRef(null);
  const [userPreferences, setUserPreferences] = useState(
    user.clientPreferences || {
      interests: {},
      curiosities: {},
      experiences: {},
    },
  );

  let savedValues = {};

  const getPreferences = useCallback(() => {
    request('/referencedata/preferences', 'GET').then(setPreferences).catch(console.dir);
  }, [request]);

  const submitHandler = useCallback(
    async values => {
      const userData = {
        ...values,
        id: user.id,
        accountId: user.accountId,
        avatarUrl: user.avatarUrl,
        BirthDate: moment.utc(values.BirthDate).format(),
        clientPreferences: userPreferences,
        profileLinkName: user.profileLinkName,
        BusinessName: user?.businessName,
        BusinessType: user?.businessType,
        CustomBusinessType: user?.customBusinessType || '',
        Certification: user?.certification,
        Occupation: user?.occupation,
        brandingColors: user?.brandingColors,
        customLogo: user?.customLogo || '',
        profilePageViewModel: user?.profilePageViewModel || {
          userId: user.id,
        },
      };

      await editProfile(user.id, userData);
    },
    [editProfile, user.accountId, user.avatarUrl, user.id, userPreferences],
  );

  const onEditPreferencesHandler = useCallback(
    section => prefs => {
      setUserPreferences(up => ({
        ...up,
        [section]: prefs,
      }));
    },
    [],
  );

  useEffect(() => {
    if (user.avatarUrl != null && user.avatarUrl !== userData.avatarUrl) {
      submitHandler(savedValues);
    }
  }, [user.avatarUrl]);

  useEffect(() => {
    getPreferences();
    if ((!timeZones || timeZones.length === 0) && !timeZoneLoading) {
      fetchTimeZones();
    }
  }, [getPreferences, timeZones, fetchTimeZones]);

  useEffect(() => {
    getUserProfile(user.id).then(data => {
      formRef.current.setFieldValue('TimeZoneId', data.timeZoneId);
      setUserData(data);
    });
    userActions.getProfile(user.id, dispatch);
  }, []);

  return (
    <StyledContainer>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        classes={{
          container: classes.container,
        }}
      >
        <Formik
          initialValues={{
            FirstName: user.firstName,
            LastName: user.lastName,
            BirthDate: parseDate(user.birthDate),
            Bio: user.bio || '',
            TimeZoneId: userData.timeZoneId || 'America/Los_Angeles',
            CountryId: user.countryId,
            HasAgreedToTerms: true,
          }}
          innerRef={formRef}
          validationSchema={getClientProfileValidationSchema()}
          onSubmit={submitHandler}
        >
          {({ values }) => {
            savedValues = values;
            return (
              <Form id="signUpFormTwo">
                <div className="d-flex">
                  <ProfileAvatar avatarUrl={user.avatarUrl} editHandler={submitHandler} values={values} />
                  <Grid container spacing={4} direction="column" className={classes.row}>
                    <Grid item lg={12} xs={12} className={classes.item}>
                      <Input label="First Name" type="text" name="FirstName" fullWidth />
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.item}>
                      <Input label="Last Name" type="text" name="LastName" fullWidth />
                    </Grid>
                  </Grid>
                </div>

                <Grid container spacing={4} className={classes.row}>
                  <Grid item sm={12} xs={12}>
                    <Select
                      label="Timezone"
                      name="TimeZoneId"
                      fullWidth
                      items={
                        timeZones?.length > 0
                          ? timeZones.map(timeZone => {
                              return { value: timeZone.countryName, title: timeZone.name };
                            })
                          : []
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4} className={classes.row}>
                  <TextArea profile={true} label="Bio" name="Bio" rows="2" fullWidth optional helperText="optional" />
                </Grid>

                {error?.message && <CommonErrorMessage message={error.message} />}

                <Grid container justify="center">
                  <ButtonsContainer>
                    <Button disabled={loading} type="submit">
                      {loading ? <Icon path={mdiLoading} size={1} spin={1} /> : 'Save Profile'}
                    </Button>
                  </ButtonsContainer>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>

      {/* <StyledInterestsContainer>
        <Interests
          title="My Interests"
          source={preferences}
          buttonTitle="Edit My Interests"
          list={userPreferences?.interests}
          onEdit={onEditPreferencesHandler('interests')}
          icon={<Icon path={mdiWeatherSunny} size={1} />}
          type="Interests"
        />

        <Interests
          title="My Curiosities"
          source={preferences}
          buttonTitle="Edit My Curiosities"
          list={userPreferences?.curiosities}
          onEdit={onEditPreferencesHandler('curiosities')}
          icon={<Icon path={mdiEyeCircleOutline} size={1} />}
          type="Curiosities"
        />

        <Interests
          title="My Experience"
          source={preferences}
          buttonTitle="Edit My Experience"
          list={userPreferences?.experiences}
          onEdit={onEditPreferencesHandler('experiences')}
          icon={<Icon path={mdiTimerSandEmpty} size={1} />}
          type="Experiences"
        />
      </StyledInterestsContainer> */}
    </StyledContainer>
  );
}

ClientProfilePage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    businessType: PropTypes.string,
    occupation: PropTypes.string,
    certification: PropTypes.string,
    customBusinessType: PropTypes.string,
    customLogo: PropTypes.string,
    profileLinkName: PropTypes.string,
    clientPreferences: PropTypes.shape(),
    avatarUrl: PropTypes.string,
    customerLabelPreference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    businessName: PropTypes.string,
    bio: PropTypes.string,
    timeZoneId: PropTypes.string,
    brandingColors: PropTypes.shape({}),
    profilePageViewModel: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  timeZone: PropTypes.shape({
    timeZones: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  timeZoneLoading: PropTypes.bool.isRequired,
  fetchTimeZones: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  editProfile: PropTypes.func.isRequired,

  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

ClientProfilePage.defaultProps = {
  error: null,
  fetchTimeZones: () => null,
};

const mapStateToProps = ({ account, timeZone }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  error: account?.error,
  timeZones: timeZone?.timeZones,
  timeZoneLoading: timeZone?.loading,
});

const actions = {
  editProfile: userActions.editProfile,
  fetchTimeZones: timeZoneActions.fetchTimeZones,
};

export default connect(mapStateToProps, actions)(ClientProfilePage);
