import React from 'react';
import classes from './Step.module.scss';

const Step = ({ stepNumber = 1, description, stepNumberClass }) => {
  let stepNumberClassName = classes.step_number;
  if (stepNumberClass) {
    stepNumberClassName = `${classes.step_number} ${stepNumberClass}`;
  }
  return (
    <>
      <div className={classes.main}>
        <div className={stepNumberClassName}>{stepNumber}</div>
        {description && <div className={classes.step_description}>{description}</div>}
      </div>
      {stepNumber === 2 && (
        <div className={classes.main}>
          <div style={{ fontWeight: 'bold' }}>
            NOTE:{' '}
            <span className={classes.step_description}>
              If DNS records were recently added, wait a few minutes before proceeding to ensure functionality,Also
              don’t refresh page as you will have to add records again
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Step;
