import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';
import { downloadFileByLink, getIpGlobal, wrapLink } from '../utils/utils';
import { LEAD_MAGNET_COLORS } from '../constants';
import { ContributionThemedColors } from 'helpers/constants';
import { isInteger, isUndefined } from 'lodash';
import * as contributionService from 'services/contributions.service';

export const LEAD_MAGNET_ENDPOINTS = {
  GET_LEAD_MAGNETS: '/LeadMagnet/List',
  GET_LEAD_MAGNETS_FOR_PROFILE_PAGE: '/LeadMagnet/GetLeadMagnetsListForProfilePage',
  GET_LEAD_MAGNETS_COUNT: '/LeadMagnet/Count',
  GET_ARCHIVED_LEAD_MAGNETS: '/LeadMagnet/GetCompletedLeadMagnetList',
  GET_ARCHIVED_LEAD_MAGNETS_COUNT: '/LeadMagnet/GetCompletedLeadMagnetCount',
  ARCHIVE_LEAD_MAGNET: '/LeadMagnet/SetAsCompleted',
};

export const getLeadMagnets = () => axiosInstance.get(LEAD_MAGNET_ENDPOINTS.GET_LEAD_MAGNETS).then(get('data'));

export const getLeadMagnetsForProfilePage = () =>
  axiosInstance.get(LEAD_MAGNET_ENDPOINTS.GET_LEAD_MAGNETS_FOR_PROFILE_PAGE).then(get('data'));

export const getLeadMagnetsCounts = () =>
  axiosInstance.get(LEAD_MAGNET_ENDPOINTS.GET_LEAD_MAGNETS_COUNT).then(get('data'));

// const getUpcomingCreated = (id, number) =>
//   axiosInstance
//     .get(
//       `/Contribution/GetUpcomingCreatedByUserId/${id}${number >= 0 ? `?skip=${number * 10}&take=10&orderBy=Desc` : ''}`,
//     )
//     .then(get('data'));

// const getUpcomingCreatedWithType = (id, type) =>
//   axiosInstance.get(`/Contribution/GetUpcomingCreatedByUserId/${id}/${type}`).then(get('data'));

export const getArchivedLeadMagnets = () =>
  axiosInstance.get(`/LeadMagnet/GetCompletedLeadMagnetList`).then(get('data'));

export const getArchivedLeadMagnetsCount = () =>
  axiosInstance.get(`/LeadMagnet/GetCompletedLeadMagnetCount`).then(get('data'));

export const archiveLeadMagnet = id =>
  axiosInstance
    .post(`/LeadMagnet/SetAsCompleted`, null, {
      params: { leadMagnetId: id },
    })
    .then(get('data'));

// const getContribution = id => axiosInstance.get(`/Contribution/GetContribById/${id}`).then(get('data'));

export const getCohealerLeadMagnet = id => axiosInstance.get(`/LeadMagnet/${id}`).then(get('data'));

export const getClientLeadMagnet = async id => {
  const userIp = await getIpGlobal();
  return axiosInstance.get(`/LeadMagnet/GetClientLeadMagnetById/${id}/${userIp}`).then(get('data'));
};
export const getVideoUrl = contributionService.getVideoUrl;

// const getCoachContributionTimes = (id, offset = 0, startDay) =>
//   axiosInstance
//     .post(`/Contribution/${id}/GetSlots`, null, {
//       params: { offset, slotsStartDate: startDay, slotsEndDate: startDay },
//       headers: { 'Content-Type': 'application/json' },
//     })
//     .then(get('data'));

// const getSingleDaySlotsForScheduleClient = (id, offset = 0, startDay) =>
//   axiosInstance
//     .post(`/Contribution/${id}/GetSingleDaySlotsForScheduleClient`, null, {
//       params: { offset, date: startDay },
//       headers: { 'Content-Type': 'application/json' },
//     })
//     .then(get('data'));
// const getClientContributionTimes = (id, offset = 0, startDay) =>
//   axiosInstance
//     .post(`/Contribution/${id}/GetClientSlots`, null, {
//       params: { offset, slotsStartDate: startDay, slotsEndDate: startDay },
//       headers: { 'Content-Type': 'application/json' },
//     })
//     .then(get('data'));

// const getCoachEditContributionTimes = (id, oneToOneSessionDataUi, params = {}) =>
//   axiosInstance.post(`/Contribution/${id}/GetSlots`, oneToOneSessionDataUi, { params }).then(get('data'));

// const calculateSlots = oneToOneSessionDataUi =>
//   axiosInstance.post('/Contribution/CalculateSlots', oneToOneSessionDataUi).then(get('data'));

export const createLeadMagnet = data => axiosInstance.post(`/LeadMagnet/Draft`, data).then(get('data'));

export const updateLeadMagnet = (id, data) => axiosInstance.put(`/LeadMagnet/${id}`, data).then(get('data'));

// const getContributionsAdmin = () => axiosInstance.get('/Contribution/GetForAdmin').then(get('data'));
export const getLatestApproved = () => axiosInstance.get('/LeadMagnet/GetLatestApprovedLeadMagnet').then(get('data'));

// const purchaseCourse = data => axiosInstance.post('/api/purchase', data).then(get('data'));

// const rescheduleSession = data => axiosInstance.post(`/Contribution/RescheduleOneToOneBooking`, data).then(get('data'));

// const rescheduleSessionForClient = data =>
//   axiosInstance.post(`/Contribution/ClientOneToOneRescheduling`, data).then(get('data'));

// const getLastDraftContribution = data =>
//   axiosInstance
//     .get(`/Contribution/Draft`, data)
//     .then(get('data'))
//     .catch(() => ({}));

export const getProfileLinkNameByLeadMagnetId = id =>
  axiosInstance
    .get(`/ProfilePage/GetProfileLinkNameByLeadMagnetId`, {
      params: { LeadMagnetId: id },
    })
    .then(get('data'));

export const createDraftLeadMagnet = data => axiosInstance.post(`/LeadMagnet/Draft`, data).then(get('data'));

export const createDuplicateLeadMagnet = data =>
  axiosInstance.post(`/LeadMagnet/DuplicateLeadMagnet`, data).then(get('data'));

export const updateDraftLeadMagnet = (id, data) =>
  axiosInstance
    .put(`/LeadMagnet/Draft`, {
      ...data,
      // isApplicationFormBannerPreviewed: 'false',
    })
    .then(get('data'));

// const deleteDraftContribution = (id, data) => axiosInstance.delete(`/Contribution/Draft/${id}`, data).then(get('data'));
export const deleteLeadMagnet = (id, data) => axiosInstance.delete(`/LeadMagnet/${id}`, data).then(get('data'));

// const getChatChannelDetails = chatsid =>
//   axiosInstance.get(`/Chat/GetContributionDataByChatId?chatSid=${chatsid}`).then(get('data'));

export const submitDraftLeadMagnet = ({ timeZoneId, ...body }) =>
  updateDraftLeadMagnet(null, {
    ...body,
    instagramUrl: body.instagramUrl ? wrapLink(body.instagramUrl) : '',
    linkedInUrl: body.linkedInUrl ? wrapLink(body.linkedInUrl) : '',
    youtubeUrl: body.youtubeUrl ? wrapLink(body.youtubeUrl) : '',
    facebookUrl: body.facebookUrl ? wrapLink(body.facebookUrl) : '',
    websiteUrl: body.websiteUrl ? wrapLink(body.websiteUrl) : '',
  })
    .then(() => axiosInstance.post(`/LeadMagnet/Draft/${body.id}/Submit`))
    .then(get('data'));

// const shareViaEmail = data => axiosInstance.post(`/Contribution/ShareViaEmail`, data).then(get('data'));

// const getAllBoughtByUserId = userId =>
//   axiosInstance.get(`/Contribution/GetAllBoughtByUserIdUpdated/${userId}`).then(get('data'));

// const GetAllBoughtByUserIdUpdated = userId =>
//   axiosInstance.get(`/Contribution/GetAllBoughtByUserIdUpdated/${userId}`).then(get('data'));

// const getClientPurchases = (clientUserId, count) =>
//   axiosInstance
//     .get(`/Contribution/GetClientPurchases/${clientUserId}${count === false ? `/${count}` : ''}`)
//     .then(get('data'));

// const bookSessionTime = data => axiosInstance.post(`/Contribution/BookSessionTime`, data).then(get('data'));

// const unbookSessionTime = data => axiosInstance.post(`/Contribution/RevokeBookSessionTime`, data).then(get('data'));

// const saveThankYouPage = data =>
//   axiosInstance.post(`/Contribution/SaveThankyouPageInContribution`, data).then(get('data'));

// const updateTimeforSelfBook = data =>
//   axiosInstance.put(`/Contribution/UpdateTimeSoltForSelfBook`, data).then(get('data'));

// const saveCustomWaiver = data =>
//   axiosInstance.post(`/Contribution/SaveThankyouPageInContribution`, data).then(get('data'));

// const markThankYouPageViewed = contributionId =>
//   axiosInstance.post(`/Contribution/MarkThankyouPageViewed?contributionId=${contributionId}`).then(get('data'));

// const getThankYouPage = data =>
//   axiosInstance.get(`/Contribution/GetThankyouPageInContribution/${data}`).then(get('data'));

// const getBoughtByType = type => axiosInstance.get(`/Contribution/GetBoughtByType/${type}`).then(get('data'));

// const joinContribution = data =>
//   axiosInstance.post(`/ContributionAccess/${data.id}/join`, { accessCode: data.accessCode }).then(get('data'));

// const saveSignoffData = (formData, id, ip) =>
//   axiosInstance.post(`Contribution/save-signoff-data?contributionId=${id}&ipAddress=${ip}`, formData).then(get('data'));

// const deleteClient = (contributionId, userId) =>
//   axiosInstance.delete(`/ContributionAccess/${contributionId}/${userId}`).then(get('data'));

// const createAccessCode = data => axiosInstance.post(`/ContributionAccess/${data}/CreateAccessCode`).then(get('data'));
// const getFreeAccessCode = id =>
//   axiosInstance.get(`/ContributionAccess/GetFreeAccessCode?contributionId=${id}`).then(get('data'));

// const getAllContracts = data => axiosInstance.get(`/Contract/AllTemplate`, { params: data }).then(get('data'));

// const getSingleContract = (contributionId, email = ' ') =>
//   axiosInstance.get(`/Contract/GetContract/${contributionId}/${email}`).then(get('data'));

// const createContract = data => axiosInstance.post(`/Contract/Create`, data).then(get('data'));
// const deleteOneToOneSession = data =>
//   axiosInstance.post(`/Contribution/DeleteOneToOneSingleSession`, data).then(get('data'));
// const bookeOneToOneSession = data =>
//   axiosInstance.post(`Contribution/BookOneToOneCreditedSession`, data).then(get('data'));
// const getContributionFromList = (data, contributionId) =>
//   axiosInstance
//     .get(
//       `/Contribution/GetAllContributionForCoachByType?type=${data}${
//         contributionId ? `&contributionId=${contributionId}` : ''
//       }`,
//     )
//     .then(get('data'));

// const saveCustomAvailablilityTime = data =>
//   axiosInstance.post('/Contribution/SaveCustomAvailablilityTime', data).then(get('data'));
// const updateContract = data => axiosInstance.put(`/Contract/Update`, data).then(get('data'));

// const setSignedContract = (file, query) => {
//   axiosInstance.post(`/Contract/SignedOffContract`, file, { params: query }).then(get('data'));
// };

// const calculateSlotsheckIfPurchaseExistsForClient = (email, contributionId) => {
//   return axiosInstance
//     .get(`/Contribution/CheckIfPurchaseExistsForClient?email=${email}&contribId=${contributionId}`)
//     .then(get('data'));
// };

// const completeSelfPacedSessionTime = async data => {
//   const endpoint = '/Contribution/SetSelfPacedClassAsCompleted';
//   const body = {
//     classId: data?.sessionTimeId,
//     contributionId: data?.contributionId,
//   };

//   const response = await axiosInstance.post(endpoint, body);

//   return response.data;
// };

export const determineColorToUse = contributionService.determineColorToUse;

export const determineDarkThemedColorToUse = contributionService.determineDarkThemedColorToUse;

export const getThemedColors = contributionService.getThemedColors;

// const getAllResources = id =>
//   axiosInstance.get(`/Contribution/GetAllResourcessByContributionId/${id}`).then(get('data'));

// const deleteSpecificResources = resourceDataUi =>
//   axiosInstance.post('/Contribution/DeleteFileFromContributionAndResourceFolder', resourceDataUi).then(get('data'));

// const shareSpecificResource = resourceDataUi =>
//   axiosInstance.post('/Contribution/ShareContributionResourceWithUser', resourceDataUi).then(get('data'));

// const renameSpecificResources = resourceDataUi =>
//   axiosInstance.post('/Contribution/RenameFileFromContributionAndResourceFolder', resourceDataUi).then(get('data'));

// const downloadSpecificResources = resourceDataUi =>
//   axiosInstance.post('/Contribution/DownloadFileFromContributionAndResourceFolder', resourceDataUi).then(({ data }) => {
//     downloadFileByLink(data, resourceDataUi?.fileName || 'cohereFile');
//   });

// const getContributionSessionNotes = (contributionId, classId, subClassId, isPrerecorded) => {
//   return axiosInstance
//     .get(`/api/Note/${contributionId}/${classId}${isPrerecorded ? `?subClassId=${subClassId}` : ''}`)
//     .then(get('data'));
// };

// const updateApplicationFormPreviewStatus = id =>
//   axiosInstance
//     .put(`/ApplicationForm/SetApplicationFormBannerPreviewed/${id}?status=true`)
//     .then(get('data'))
//     .catch(error => console.log(error));

// const getCoachClientsWithCredits = id =>
//   axiosInstance
//     .get(`/Contribution/GetClientListOfCoachWithPkgCreditRemaining?contributionId=${id}`)
//     .then(get('data'))
//     .catch(error => console.log(error));

// const saveOneToOneSelfBookClient = data =>
//   axiosInstance
//     .post(`/api/Purchase/SaveOneToOneSelfBookClientData`, data)
//     .then(get('data'))
//     .catch(error => console.log(error));

// const getOneToOneSelfBookClient = contributionId =>
//   axiosInstance.get(`/Contribution/GetOneToOneSelfBookClientData?Id=${contributionId}`).then(get('data'));

// const addToGoogleCalender = data =>
//   axiosInstance.get(`/Contribution/GetGoogleInviteURL/`, { params: data }).then(get('data'));

// const getAllOneToOneSessionBookings = data =>
//   axiosInstance.get(`/Contribution/GetAllOneToOneSessionBookings`, { params: data }).then(get('data'));

// const getSessionBookingsPerContribution = data =>
//   axiosInstance.get(`/Contribution/GetSessionBookingsPerContribution`, { params: data }).then(get('data'));

// const submitEnrollmentForm = data => axiosInstance.post(`/Contribution/SubmitEnrollmentForm/`, data).then(get('data'));

export const createLeadMagnetUser = data =>
  axiosInstance.post(`/LeadMagnetUser/CreateLeadMagnetUser`, data).then(get('data'));

export const exportLeadMagnetUsersByLeadMagnetId = id =>
  axiosInstance
    .get('/LeadMagnetUser/ExportLeadMagnetUsers', {
      params: { leadMagnetId: id },
      // responseType: 'blob',
    })
    .then(get('data'));

export const getCustomTemplateByLeadMagnetId = leadMagnetId =>
  axiosInstance.get(`/LeadMagnet/GetCustomTemplateByLeadMagnetId/?leadMagnetId=${leadMagnetId}`).then(get('data'));

export const resetEmailTemplate = data => axiosInstance.put('/LeadMagnet/ResetEmailTemplate', data).then(get('data'));

export const updateEmailTemplate = data => axiosInstance.put('/LeadMagnet/UpdateEmailTemplate', data).then(get('data'));

export const sendTestCustomEmailNotification = data =>
  axiosInstance.post('/LeadMagnet/SendTestLeadMagnetCustomEmailNotification', data).then(get('data'));

export const getLeadMagnetConversionMetrics = leadMagnetId => {
  return axiosInstance.get(`/LeadMagnet/${leadMagnetId}/GetConversionMetrics`).then(get('data'));
};
