import MailOutlineIcon from '@material-ui/icons/MailOutline';
import IconButton from '@material-ui/core/IconButton';
import IconShare from '@material-ui/icons/Share';
import { SimpleText } from 'components/UI/Text/TextStyles';
import { colors } from 'utils/styles';

import styled from 'styled-components';

export const ShareModalMain = styled.div`
  width: 100%;
  ${({ mobileView }) => !mobileView && `min-width: 425px`}}
  box-sizing: border-box;
  padding: ${({ mobileView }) => (mobileView ? '0px 0px' : '0 0 20px 0')};
`;

export const ShareModalRecordingMain = styled.div`
  height: 405px;
  box-sizing: border-box;
  padding: ${mobileView => (mobileView ? '0px 0px' : '55px 70px')};
`;

export const CopyToClipboardBlockDefault = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 0px;
  background-color: #f4f4f4;
`;
export const CopyToClipboardBlock = styled.div`
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-top: 15px;   
  background-color: #F4F4F4;
  color: ${({ hasPremiumPlan }) => (hasPremiumPlan ? colors.textMain : colors.smokedGray)};
  cursor: ${({ hasPremiumPlan }) => (hasPremiumPlan ? 'pointer' : 'default')};
  ${({ hasPremiumPlan }) => !hasPremiumPlan && `pointer-events: none;`}}
`;

export const SendEmailBlock = styled.div`
  width: 100%;
  display: flex;
`;

export const ShareIcon = styled(IconShare)`
  vertical-align: middle;
  object-fit: contain;
  width: 100%;
  height: auto;
  color: ${({ color }) => color || 'black'};
  cursor: ${({ hasPremiumPlan }) => (hasPremiumPlan ? 'pointer' : 'default')};
  ${({ hasPremiumPlan }) => !hasPremiumPlan && `pointer-events: none;`}
`;

export const ButtonIcon = styled(IconButton)`
  margin-right: 8px !important;
  width: 20px;
  height: 20px;
  padding: 0 !important;
`;

export const BlackIcon = styled.span`
  margin: 10px 8px 0 4px !important;
  display: inline-block;
  margin-top: 18px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  position: relative;
  box-sizing: border-box;
  padding-top: 7px;
  border-radius: 50%;
  text-align: center;
  background-color: ${colors.black} !important;
`;

export const MailIcon = styled(MailOutlineIcon)`
  color: ${colors.white};
  width: 16px !important;
  height: 16px !important;
  position: absolute;
  top: 2px;
  left: 2px;
`;

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LinkWrapp = styled.div`
  ${({ hasPremiumPlan }) => !hasPremiumPlan && `pointer-events: none;`}
  cursor: pointer;
`;

export const Title = styled(SimpleText)`
  margin-bottom: 30px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export const PremiumTitle = styled(Title)`
  color: ${({ hasPremiumPlan }) => (hasPremiumPlan ? colors.textMain : colors.smokedGray)};
  ${({ hasPremiumPlan }) => !hasPremiumPlan && `pointer-events: none;`};
  margin-top: 30px;
`;
