import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Loader from 'components/UI/Loader';
import { Switch, StyledInput, StyledSlider } from 'components/switch/style';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { StyledSlotContainer, StyledTimeSlotValue, StyledTimeSlots } from '../PurchaseModal/SlotsModalForPaymentCard';
import UserSelectionInputFields from './UserSelectionInputFields';
import { DATE_FORMATS } from '../../../../utils/datesAndMoney';
import useRouter from 'hooks/useRouter';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { TIMEZONES } from 'constants.js';
import useAccount from 'hooks/useAccount';
import { useSelector } from 'react-redux';
import {
  determineDarkThemedColorToUse,
  getClientContributionTimes,
  getCoachContributionTimes,
} from 'services/contributions.service';
function SessionForm({
  isExistingClient,
  setIsExistingClient,
  formik,
  onChangeDate,
  onSelectTimeSlot,
  emailsList,
  setEmailsList,
  tempTimeSlots,
  colorToUse,
  selectOptions,
  themedColor,
  isLoading,
  onChangeClient,
  selectedClient,
  filterClient,
  paymentInfo,
  selectedCalDate,
  onOptionSelect,
  closeDropDown,
  setMeetingForm,
  setDisableContributionSelection,
  isMasterCalendar = false,
  setToggleAvailabilitySlots,
  toggleAvailabilitySlots,
}) {
  const { domain } = useRouter();
  const { user } = useAccount();
  const [masterCalendarContributionTimes, setMasterCalendarContributionTimes] = useState([]);
  const [dateSelected, setDateSelected] = useState(selectedCalDate);
  const [minMaxDate, setminMaxDate] = useState([]);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const contributionTimes = useSelector(state => state.contributions?.contributionTimes);

  const isDarkThemeEnabled = isMasterCalendar ? false : determineDarkThemedColorToUse(activeContribution);
  const currentDate = moment();

  // const shouldDisableDate = date => {
  //   const dayOfWeek = moment(date).format('ddd');
  //   const isBlocked = activeContribution.oneToOneSessionDataUi.selectedWeeks[0].days.find(
  //     day => day.value === dayOfWeek,
  //   );
  //   if (!isBlocked) {
  //     return dayOfWeek;
  //   }
  // };

  useEffect(() => {
    if (setDisableContributionSelection) setDisableContributionSelection(false);
  });
  useEffect(() => {
    if (isMasterCalendar === false) {
      const startTimes = contributionTimes?.map(item => item.startTime);
      // Extract date from start times using Moment.js
      const dates = startTimes.map(startTime => moment(startTime).format('YYYY-MM-DD'));
      const formattedDates = dates.map(startTime => moment(startTime).toISOString());
      const filteredDates = startTimes?.filter(date => {
        const dateObj = moment(date);
        return dateObj.isSameOrAfter(currentDate, 'day');
      });
      setminMaxDate([filteredDates[0], formattedDates[formattedDates?.length - 1]]);
      // setDateSelected(filteredDates[0]);
    }
    if (isMasterCalendar) {
      getCoachContributionTimes(activeContribution.id).then(res => {
        setMasterCalendarContributionTimes(res);
        const startTimes = res.filter(item => item.bookedTimes.length === 0).map(item => item.startTime);
        // Extract date from start times using Moment.js
        const dates = startTimes.map(startTime => moment(startTime).format('YYYY-MM-DD'));
        const formattedDates = dates.map(startTime => moment(startTime).toISOString());
        const filteredDates = startTimes?.filter(date => {
          const dateObj = moment(date);
          return dateObj.isSameOrAfter(currentDate, 'day');
        });
        setminMaxDate([filteredDates[0], formattedDates[formattedDates?.length - 1]]);
        setDateSelected(filteredDates[0]);
      });
    }
  }, [activeContribution]);

  const shouldDisableDate = date => {
    if (isMasterCalendar === false) {
      const startTimes = contributionTimes.filter(item => item.bookedTimes.length === 0).map(item => item.startTime);
      const dates = startTimes.map(startTime => moment(startTime).format('YYYY-MM-DD'));
      const formattedDates = dates.map(startTime => moment(startTime).toISOString());
      const formattedDate = moment(date).toISOString();

      return !formattedDates?.some(blockedDate => moment(blockedDate).isSame(formattedDate, 'day'));
    } else {
      const startTimes = masterCalendarContributionTimes
        .filter(item => item.bookedTimes.length === 0)
        .map(item => item.startTime);
      const dates = startTimes.map(startTime => moment(startTime).format('YYYY-MM-DD'));
      const formattedDates = dates.map(startTime => moment(startTime).toISOString());
      const formattedDate = moment(date).toISOString();

      return !formattedDates?.some(blockedDate => moment(blockedDate).isSame(formattedDate, 'day'));
    }
  };
  return (
    <>
      <UserSelectionInputFields
        formik={formik}
        setEmailsList={setEmailsList}
        selectOptions={selectOptions}
        themedColor={themedColor}
        emailsList={emailsList}
        selectedClient={selectedClient}
        onChangeClient={onChangeClient}
        setIsExistingClient={setIsExistingClient}
        isExistingClient={isExistingClient}
        filterClient={filterClient}
        paymentInfo={paymentInfo}
        onOptionSelect={onOptionSelect}
        closeDropDown={closeDropDown}
        isDarkThemeEnabled={isMasterCalendar ? false : isDarkThemeEnabled}
        setMeetingForm={setMeetingForm}
      />
      <Grid container>
        <Grid item xs={12}>
          {!toggleAvailabilitySlots ? (
            <DatePicker
              fullWidth
              label={<span style={{ color: isDarkThemeEnabled ? '#fff' : 'black' }}>Date</span>}
              format={'MMM DD, YYYY'}
              value={moment(dateSelected).isSame(moment(), 'day') === false ? dateSelected : formik.values.date}
              shouldDisableDate={toggleAvailabilitySlots && shouldDisableDate}
              onChange={value => {
                formik.setFieldValue('date', value);
                setDateSelected(value);
                onChangeDate(value._d);
              }}
            />
          ) : (
            <DatePicker
              fullWidth
              label={<span style={{ color: isDarkThemeEnabled ? '#fff' : 'black' }}>Date</span>}
              format={'MMM DD, YYYY'}
              minDate={moment(minMaxDate[0])?.toDate()}
              maxDate={moment(minMaxDate[1])?.toDate()}
              value={moment(dateSelected).isSame(moment(), 'day') === false ? dateSelected : formik.values.date}
              shouldDisableDate={toggleAvailabilitySlots && shouldDisableDate}
              onChange={value => {
                formik.setFieldValue('date', value);
                setDateSelected(value);
                onChangeDate(value._d);
              }}
            />
          )}
        </Grid>
        <span style={{ display: 'flex', marginTop: '20px' }}>
          <Switch className="switch" style={{ marginLeft: 8, marginRight: 8 }}>
            <StyledInput
              type="checkbox"
              onClick={() => {
                setToggleAvailabilitySlots(!toggleAvailabilitySlots);
              }}
              checked={toggleAvailabilitySlots}
            />
            <StyledSlider className="slider round" />
          </Switch>
          <p style={{ color: '#71717a' }}>Show My Availability</p>
        </span>
        <Grid container style={{ marginTop: '15px' }}>
          {isLoading && (
            <Grid item xs={4}>
              <CircularProgress size={30} />{' '}
            </Grid>
          )}
          {!isLoading &&
            tempTimeSlots.map(time => (
              <Grid item xs={4}>
                <StyledSlotContainer>
                  <StyledTimeSlots
                    mobileView={'mobileView'}
                    style={{
                      backgroundColor: isDarkThemeEnabled ? '#2D2F31' : 'white',
                      color: isDarkThemeEnabled ? 'white' : 'black',
                    }}
                    key={time?.id}
                    isBooked={1}
                    onClick={() => {
                      onSelectTimeSlot(time.id);
                    }}
                    className={
                      time.isSelected
                        ? 'active-time-slot coach-empty-slots-button'
                        : 'regular-time-slot coach-empty-slots-button'
                    }
                    isSelected={'selectedSlot && selectedSlot.id === slot.id'}
                    colorForSelected={colorToUse?.PrimaryColorCode}
                    margin="5px 2px"
                  >
                    <StyledTimeSlotValue>
                      {moment(time.startTime, DATE_FORMATS.TIME_12).format(DATE_FORMATS.TIME_12)}
                    </StyledTimeSlotValue>
                  </StyledTimeSlots>
                </StyledSlotContainer>
              </Grid>
            ))}
          {!isLoading && tempTimeSlots.length === 0 && (
            <Grid item xs={12} style={{ color: isDarkThemeEnabled ? '#fff' : 'black' }}>
              No available slots for the selected date.
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        style={
          tempTimeSlots.length != 0
            ? { marginTop: '15px', marginBottom: '25px' }
            : { marginTop: '15px', marginBottom: '25px' }
        }
      >
        <Typography style={{ color: isDarkThemeEnabled ? '#fff' : 'black' }}>
          {TIMEZONES[user?.timeZoneId]}{' '}
          <span
            style={{ color: isMasterCalendar ? '#CDBA8F' : colorToUse.PrimaryColorCode, cursor: 'pointer' }}
            onClick={() => {
              redirectToAppIfIsCoachDomain(domain, '/account/profile');
            }}
          >
            Update My Timezone
          </span>
        </Typography>
      </Grid>
    </>
  );
}

export default SessionForm;
