import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as contributionActions from 'actions/contributions';
import CrossIcon from '@material-ui/icons/Clear';
import Button from 'components/FormUI/Button';
import InfoIcon from '@material-ui/icons/Info';
import { PageTitleSecond, BodyText } from 'components/UI/Text/TextStyles';
import { useAccount, useRouter } from 'hooks';
import { ContributionStatus, ContributionType } from 'helpers/constants';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { useMediaQuery, useTheme } from '@material-ui/core';
import MainContainer from 'components/Containers/MainContainer';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import { useSaveContribution } from 'hooks/useSaveContribution';
import CoachImage from 'assets/chatlogo.png';
import TemplateOne from 'assets/congratulations.png';
import { PAYMENT_OPTIONS } from 'constants.js';
import OneOnOne from './components/OneOnOne';
import Modal from 'components/UI/Modal';
import LiveCourses from './components/LiveCourses';
import Membership from './components/Membership/index';
import Community from './components/Community';
import { toRem, colors } from 'utils/styles';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import WorkShop from './components/WorkShop';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;
const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;
const useStyles = makeStyles({
  row: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  radioGroupRoot: {
    flexDirection: 'row',
  },
  detailsHeader: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
  },
  header: ({ mobileView }) =>
    mobileView
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingBottom: '10px',
        }
      : {},
});
function Payment({ contribution, saveContribution, saveContributionToLS }) {
  const PAYMENT_FORM_MAPPING = {
    [ContributionType.contributionOneToOne]: OneOnOne,
    [ContributionType.contributionCourse]: LiveCourses,
    [ContributionType.contributionMembership]: Membership,
    [ContributionType.contributionCommunity]: Community,
    [ContributionType.contributionWorkshop]: WorkShop,
  };
  const classes = useStyles();
  const { user } = useAccount();
  const dispatch = useDispatch();
  const { query, history } = useRouter();
  const isEditing = Boolean(query.id);
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);
  const [redirectTo, setRedirectTo] = useState(undefined);
  const [optsShouldBeDisabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const saveHandler = isEditing ? saveContribution : saveContributionToLS;
  const [run, setRun] = useState(!user?.userProgressbarData?.FirstContributionDone);
  const errorOccured = useSelector(state => state?.contributions.showError);
  const [stepIndex, setStepIndex] = useState(4);

  let joyRideHelpers;
  useEffect(() => {
    if (isEditing === true) {
      setRun(false);
    }
    if (contribution.status === 'Draft' && isEditing) {
      dispatch(contributionActions.setErrorPopUp(false));
    }
  }, []);
  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>
            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>
            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>
            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Contribution Pricing
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            This is where you create pricing for your clients, it can be Free, fully paid or split payments.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>
            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Price Your Services
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            This is where you can customize the pricing for your contribution and/or determine if you want to provide a
            free access link.You’ll have access to unique paid and free links to share with clients!
          </div>
          <div
            className="buttons"
            style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}
          >
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>
            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                // marginLeft: '10px',
              }}
              onClick={() => {
                updateUserF(true);

                // joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Complete & Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#contributionPayment',
    },
  ]);
  const [contibId, setContibId] = useState(null);
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);
  const updateUserF = async (goToNextStep = false) => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };
    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (goToNextStep) {
        if (res.userProgressbarData?.IsPlanPurchased === false) {
          history.push('/account/payment', { from: 'verify' });
        } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
          history.push('/account/payment', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IntegrationsDone === false) {
          history.push('/account/integrations', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
          history.push('/account/profile');
        } else if (res?.userProgressbarData?.FirstContributionDone === false) {
          dispatch(contributionActions.setPopUp(true));
          dispatch(contributionActions.setShowCompletedGetStart('create'));
          history.push('/dashboard');
        } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
          history.push('/account/profile', { from: 'Manage Your Website Information' });
        } else if (res?.userProgressbarData?.InviteLink === false) {
          contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
        }
      }
    });
    joyRideHelpers.skip();
  };
  useEffect(() => {
    if (!!user?.userProgressbarData?.FirstContributionDone) {
      setSteps([]);
    }
  }, []);
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };
  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;
    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };
  const saveToStorageHandler = useCallback(
    (
      {
        packageDiscount,
        packageSessionDiscountPercentage,
        packageSessionNumbers,
        monthlySessionSubscriptionInfo,
        membershipPackage,
        ...values
      },
      formikHelpers,
      to,
      goBack,
    ) => {
      const isPaid =
        values.paymentOptions.includes('EntireCourse') ||
        values.paymentOptions.includes('PerSession') ||
        values.paymentOptions.includes('SessionsPackage') ||
        values.paymentOptions.includes('SplitPayments') ||
        values.paymentOptions.includes('MonthlySessionSubscription');
      const isFree = values.paymentOptions.includes('Free') || values.paymentOptions.includes('FreeSessionsPackage');
      let { inviteClientType } = contribution;
      if (!(isPaid && isFree)) {
        if (isPaid) inviteClientType = 'Paid';
        if (isFree) inviteClientType = 'Free';
      }
      const data = {
        paymentInfo: {
          ...values,
          paymentOptions: values.paymentOptions || [],
          packageSessionDiscountPercentage,
          packageSessionNumbers,
          monthlySessionSubscriptionInfo,
        },
        isPriceHidden: values?.isPriceHidden,
      };
      if (!goBack && values.paymentOptions.length == 0) {
        return;
      }
      if (!goBack && isPaid) {
        if (
          (values.paymentOptions.includes('EntireCourse') || values.paymentOptions.includes('PerSession')) &&
          contribution.type != 'ContributionCourse' &&
          !values.cost
        ) {
          isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
          return;
        } else if (
          (values.paymentOptions.includes('EntireCourse') ||
            values.paymentOptions.includes('PerSession') ||
            values.paymentOptions.includes('SplitPayments')) &&
          contribution.isWorkshop === false &&
          contribution.type === 'ContributionCourse'
        ) {
          if (values.multiplePriceList.length > 0) {
            let canGoForward = false;
            if (values.paymentOptions.includes('EntireCourse') && values.paymentOptions.includes('SplitPayments')) {
              for (let price of values.multiplePriceList) {
                if (
                  parseFloat(price.cost) == 0 ||
                  price.cost === null ||
                  price.cost.length == 0 ||
                  parseFloat(price.splittedCost) == 0 ||
                  price.splittedCost === null ||
                  price.splittedCost.length == 0 ||
                  parseFloat(price.cost) <= parseFloat(price.splittedCost) ||
                  price == 0
                ) {
                  canGoForward = false;
                  isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
                  return;
                }
              }
            } else if (
              values.paymentOptions.includes('EntireCourse') === true &&
              values.paymentOptions.includes('SplitPayments') === false
            ) {
              for (let price of values.multiplePriceList) {
                if (parseFloat(price.cost) == 0 || price.cost === null || parseFloat(price) == 0) {
                  canGoForward = false;
                  isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
                  return;
                }
              }
            } else if (
              values.paymentOptions.includes('EntireCourse') === false &&
              values.paymentOptions.includes('SplitPayments') === true
            ) {
              for (let price of values.multiplePriceList) {
                if (parseFloat(price.splittedCost) == 0 || price.splittedCost === null || parseFloat(price) == 0) {
                  canGoForward = false;
                  isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
                  return;
                }
              }
            }
            isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
            canGoForward = true;
          }
        } else if (
          (values.paymentOptions.includes('PerSession') || values.paymentOptions.includes('SessionsPackage')) &&
          contribution.type === ContributionType.contributionOneToOne
        ) {
          if (values.multiplePriceList.length > 0) {
            let canGoForward = true; // Initialize to true

            // Check for repeating cost values
            const seenCosts = new Set();
            for (let price of values.multiplePriceList) {
              if (seenCosts.has(price?.cost) && price?.cost != null) {
                canGoForward = false; // Set to false if cost repeats
                dispatch(contributionActions.setRepeatingValue(true));
                return; // Exit loop early if a repetition is found
              }
              if (seenCosts.has(price?.packageCost) && price?.packageCost != null) {
                canGoForward = false; // Set to false if cost repeats
                dispatch(contributionActions.setRepeatingValue(true));
                return; // Exit loop early if a repetition is found
              }
              dispatch(contributionActions.setRepeatingValue(false));
              seenCosts.add(price.cost);
              seenCosts.add(price.packageCost);
            }
            if (values.paymentOptions.includes('SessionsPackage') && values.paymentOptions.includes('PerSession')) {
              for (let price of values.multiplePriceList) {
                if (
                  parseFloat(price.cost) == 0 ||
                  price.cost === null ||
                  price.cost.length == 0 ||
                  parseFloat(price.packageCost) == 0 ||
                  price.packageCost === null ||
                  price.packageCost.length == 0 ||
                  price == 0
                ) {
                  canGoForward = false;
                  isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
                  return;
                }
              }
            } else if (
              values.paymentOptions.includes('PerSession') === true &&
              values.paymentOptions.includes('SessionsPackage') === false
            ) {
              for (let price of values.multiplePriceList) {
                if (parseFloat(price.cost) == 0 || price.cost === null || parseFloat(price) == 0) {
                  canGoForward = false;
                  isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
                  return;
                }
              }
            } else if (
              values.paymentOptions.includes('PerSession') === false &&
              values.paymentOptions.includes('SessionsPackage') === true
            ) {
              for (let price of values.multiplePriceList) {
                if (parseFloat(price.packageCost) == 0 || price.packageCost === null || parseFloat(price) == 0) {
                  canGoForward = false;
                  isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
                  return;
                }
              }
            }
            isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
            canGoForward = true;
          }
        }
        if (
          (values.paymentOptions.includes('EntireCourse') || values.paymentOptions.includes('PerSession')) &&
          contribution.isWorkshop === true &&
          !values.cost
        ) {
          isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
          return;
        }
        if (values.paymentOptions.includes('SessionsPackage') && (!values.packageCost || values.packageCost == 0.0)) {
          isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
          return;
        }
        if (
          values.paymentOptions.includes('MonthlySessionSubscription') &&
          !monthlySessionSubscriptionInfo.monthlyPrice
        ) {
          isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
          return;
        }
      }
      if (
        contribution.type === ContributionType.contributionMembership ||
        contribution.type === ContributionType.contributionCommunity
      ) {
        data.paymentInfo.membershipInfo = {
          costs: {},
          membershipPackage,
        };
        if (values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY)) {
          data.paymentInfo.membershipInfo.costs[PAYMENT_OPTIONS.PER_DAY] = values.perDayCost;
          if (!values.perDayCost || values.perDayCost < 1) {
            isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
            return;
          }
        }
        if (values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK)) {
          data.paymentInfo.membershipInfo.costs[PAYMENT_OPTIONS.PER_WEEK] = values.perWeekCost;
          if (!values.perWeekCost || values.perWeekCost < 1) {
            isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
            return;
          }
        }
        if (values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH)) {
          data.paymentInfo.membershipInfo.costs[PAYMENT_OPTIONS.PER_MONTH] = values.perMonthCost;
          if (!values.perMonthCost || values.perMonthCost < 1) {
            isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
            return;
          }
        }
        if (values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR)) {
          data.paymentInfo.membershipInfo.costs[PAYMENT_OPTIONS.PER_YEAR] = values.perYearCost;
          if (!values.perYearCost || values.perYearCost < 1) {
            isEditing && contribution.status === 'Approved' && dispatch(contributionActions.setErrorPopUp(true));
            return;
          }
        }
      }
      let newContribution = {
        ...contribution,
        ...data,
        inviteClientType,
        defaultCurrency: data?.paymentInfo?.defaultCurrency,
        defaultSymbol: data?.paymentInfo?.defaultSymbol,
        paymentType: values?.paymentType,
        taxType: values?.taxType,
        isInvoiced: values?.isInvoiced,
        isThankyouPageAdded: contribution?.isThankyouPageAdded || data?.isThankyouPageAdded,
        thankyouPage: contribution?.thankyouPage || data?.thankyouPage,
      };
      saveHandler(newContribution);
      onSaveContribution(newContribution);
      history.push(to || redirectTo);
    },
    [contribution, history, saveHandler, redirectTo, onSaveContribution],
  );
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const Course =
    contribution?.type === ContributionType.contributionCourse && contribution.isWorkshop === true
      ? WorkShop
      : PAYMENT_FORM_MAPPING[contribution?.type];
  useEffect(() => {
    if (
      (contribution.type === ContributionType.contributionCourse || contribution.isWorkshop === true) &&
      isEditing === true &&
      contribution.status != 'Draft'
    ) {
      setDisabled(true);
    } else if (
      (contribution?.paymentInfo?.paymentOptions &&
        contribution?.paymentInfo?.paymentOptions.length === 1 &&
        contribution?.paymentInfo?.paymentOptions.includes(PAYMENT_OPTIONS.FREE)) ||
      contribution?.status === ContributionStatus.unfinished
    ) {
      setDisabled(false);
    }
  }, []);
  return (
    <Course
      contribution={contribution}
      shouldBeDisabled={optsShouldBeDisabled}
      saveToStorageHandler={saveToStorageHandler}
    >
      {(formProps, renderCardBody) => {
        const saveData = (event, to, withValidation = false, goBack = false) => {
          if (contribution?.status !== ContributionStatus.unfinished && (isEditing || withValidation)) {
            formProps.handleSubmit(event);
            saveToStorageHandler(formProps.values, formProps, to, goBack);
          } else {
            saveToStorageHandler(formProps.values, formProps, to, goBack);
          }
        };
        return (
          <MainContainer
            sidebarProps={{
              saveHandler: (event, { to }) => {
                setRedirectTo(to);
                saveData(event, to);
              },
              errors: formProps?.errors,
            }}
          >
            <Joyride
              // tooltipComponent={Tooltip}
              callback={handleJoyrideCallback}
              continuous={true}
              getHelpers={getHelpers}
              run={run}
              stepIndex={stepIndex}
              showProgress={false}
              showSkipButton={false}
              steps={steps}
              styles={{
                options: {
                  primaryColor: '#CDBA8F',
                  zIndex: 10000000,
                },
              }}
            />
            <StyledMainSection mobileView={mobileView}>
              <Card id={'contributionPayment'} mobileView={mobileView}>
                <CardHeader mobileView={mobileView}>
                  <PageTitleSecond className={classes.header} mobileView={mobileView}>
                    4. Pricing
                  </PageTitleSecond>
                  {errorOccured && (
                    <div
                      style={{
                        width: '45%',
                        backgroundColor: 'white',
                        position: 'absolute',
                        marginInline: '9%',
                        border: '1px solid #DBE7F2',
                        borderRadius: '4px',
                        zIndex: 1,
                      }}
                    >
                      <div style={{ padding: '15px 10px 10px 15px' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                            <div
                              style={{
                                fontFamily: 'Avenir',
                                fontWeight: '700',
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#1E1E1E',
                                marginInline: '10px',
                              }}
                            >
                              Before you proceed...
                            </div>
                          </div>
                          <StyledCloseIcon
                            onClick={() => {
                              dispatch(contributionActions.setErrorPopUp(false));
                            }}
                            fontSize="10px"
                            style={{ color: 'black' }}
                          />
                        </div>

                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          Please add a valid price in order to proceed with “Save and Exit”
                        </div>
                      </div>
                    </div>
                  )}
                </CardHeader>
                <CardBody mobileView={mobileView}>
                  <>
                    <BodyText mobileView={mobileView}>
                      You are creating a unique payment link once you submit this contribution. Once the contribution is
                      live, the price gets locked in. To change your pricing, duplicate your contribution and set new
                      pricing.
                    </BodyText>
                    {renderCardBody}
                  </>
                </CardBody>
              </Card>
              <StyledButtonContainer>
                <Button
                  invert
                  autoWidth
                  type="button"
                  onClick={event => {
                    const to = query.id ? `/edit-contribution/${query.id}/sessions` : '/create-contribution/sessions';
                    setRedirectTo(to);
                    history.push(to || redirectTo);
                    saveToStorageHandler(formProps.values);
                    if (contribution.status === 'Draft') {
                      dispatch(contributionActions.setErrorPopUp(false));
                    }
                    saveData(event, to, false, true);
                  }}
                >
                  Back
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  autoWidth
                  type="submit"
                  form="create-contribution-form"
                  onClick={event => {
                    const to = query.id ? `/edit-contribution/${query.id}/review` : '/create-contribution/review';
                    setRedirectTo(to);
                    saveData(event, to, true);
                  }}
                >
                  Save and Next
                </Button>
              </StyledButtonContainer>
            </StyledMainSection>
            <Modal
              title=""
              isOpen={showModal}
              onCloseText="OK"
              hiddenCancel
              onSubmit={togglePopup}
              onCancel={togglePopup}
              disableFooter
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '10px' }}>
                <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
                <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
                <div
                  style={{
                    color: '#A58B62',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 450,
                    fontFamily: 'Avenir',
                  }}
                >
                  You are fully set up & ready to transform lives!
                </div>
                <Button
                  autoWidth
                  backgroundColor={'#C9B382'}
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={togglePopup}
                >
                  Let’s do this!
                </Button>
              </div>
            </Modal>
          </MainContainer>
        );
      }}
    </Course>
  );
}
Payment.propTypes = {
  contribution: PropTypes.shape(),
  saveContribution: PropTypes.func.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
};
Payment.defaultProps = {
  contribution: null,
};
const mapStateToProps = ({ contributions }) => ({
  contribution: contributions.activeContribution,
});
const actions = {
  saveContribution: contributionActions.saveContribution,
  saveContributionToLS: contributionActions.saveDraftContribution,
};
export default connect(mapStateToProps, actions)(Payment);
